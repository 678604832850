import { createContext } from 'react';

import useComparisonTableBuilderSliderControl from '../../hooks/useComparisonTableBuilderSliderControl/useComparisonTableBuilderSliderControl';

type TSliderControl = ReturnType<typeof useComparisonTableBuilderSliderControl>;

interface IContext {
  builderId: string;
  tableName: string;
  sliderControl: TSliderControl | null;
}

const defaultValue: IContext = {
  builderId: '',
  tableName: '',
  sliderControl: null,
};

const ComparisonTableBuilderContext = createContext<IContext>(defaultValue);

export default ComparisonTableBuilderContext;
