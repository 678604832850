import React from 'react';
import { Colors } from '@farmlink/farmik-ui';

import { DeleteIcon, DownloadIcon } from '../../components';

const DocumentsActionIconContainer = ({ type }) => {
  if (!type) return null;

  switch (type) {
    case 'download':
      return <DownloadIcon fill={Colors.black} hoverFill={Colors.hoverGreen} />;
    case 'delete':
      return <DeleteIcon fill={Colors.pink} hoverFill={Colors.hoverPink} />;
    default:
      return null;
  }
};

export default DocumentsActionIconContainer;
