import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

const Wrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;

  color: ${ECommonColor.AccentGreen};
`;

const StyledTaskReportViewChecklistAttrValue = {
  Wrapper,
};

export default StyledTaskReportViewChecklistAttrValue;
