import styled from 'styled-components';

interface StyledSvgProps {
  fill: string;
  hoverFill?: string;
}

const filledSvg = styled.svg<StyledSvgProps>`
  transition: fill 0.3s ease;
  cursor: pointer;
  fill: ${props => props.fill};

  &:hover {
    fill: ${props => props.hoverFill || 'red'};
  }
`;

const StyledDocumentsActionsIcon = {
  filledSvg,
};

export default StyledDocumentsActionsIcon;
