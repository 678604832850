import { TApiRoute } from '../../models/system/ApiRoute.model';

type TVerifyAccessTokenReq = {};

type TVerifyAccessTokenRes = {};

const verifyAccessToken: TApiRoute & {
  request: TVerifyAccessTokenReq;
  response: TVerifyAccessTokenRes;
} = {
  url: `/api/da-profile/users/ping`,
  method: 'POST',
  request: {} as TVerifyAccessTokenReq,
  response: {} as TVerifyAccessTokenRes,
  headers: {},
};

export type { TVerifyAccessTokenReq, TVerifyAccessTokenRes };

export { verifyAccessToken };
