import { makeAutoObservable } from 'mobx';
import { AxiosError } from 'axios';

import { provide, lazyInject } from '../../../../../../../../common/utils/helpers/mobx';
import { ExperimentService } from '../../../../../../../../common/mobx/services/as-fields';
import { AccessController } from '../../../../../../../../common/mobx/controllers/AccessController';

@provide.singleton()
export class ExperimentSubsectionsService {
  @lazyInject(ExperimentService)
  protected experimentService: ExperimentService;

  @lazyInject(AccessController)
  protected accessController: AccessController;

  constructor() {
    makeAutoObservable(this);
  }

  fetchExperiment = async (id: string) => {
    const response = await this.experimentService.getExperiment({ id });

    if (response instanceof AxiosError) {
      this.accessController.checkUserResponseError(response);

      return null;
    }

    if (response) {
      return response;
    }
  };

  updateExperiment = async (experimentId: string, attachedFiles: string[]) => {
    await this.experimentService.updateExperimentAttachedFiles({ experimentId, attachedFiles });
  };
}
