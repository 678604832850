import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import {
  EExperimentCultureZoneType,
  IExperimentCultureZone,
} from '../../../../models/as-fields/experiments/ExperimentCultureZone';

type TChangeCultureZoneExperimentReq = {
  id: string;
  experimentId?: string;
  type?: EExperimentCultureZoneType;
  cultureZoneId?: string;
  plannedYield?: number;
  plannedCropPrice?: number;
  previousCultureIds?: string[];
};

type TChangeCultureZoneExperimentRes = IExperimentCultureZone;

const changeCultureZoneExperiment: TApiRoute & {
  request: TChangeCultureZoneExperimentReq;
  response: TChangeCultureZoneExperimentRes;
} = {
  url: ({ id }) => `/api/as-fields/cultureZoneExperiment/${id}`,
  method: 'PUT',
  request: {} as TChangeCultureZoneExperimentReq,
  response: {} as TChangeCultureZoneExperimentRes,
  headers: {},
};

export type { TChangeCultureZoneExperimentReq, TChangeCultureZoneExperimentRes };

export { changeCultureZoneExperiment };
