import { FC, PropsWithChildren, memo, useRef, useEffect, UIEvent } from 'react';

import { useSliderControl } from '../../hooks';

import Styled from './Slider.styles';

interface Props {
  clickedArrow: 'left' | 'right' | null;
  scrollValue: number;
  isNeedToUpdateScroll: boolean;
  scrollValueToChange: number;
  onClearClickedArrow: () => void;
  onClearIsNeedToUpdateScroll: () => void;
  onDisableArrow: ReturnType<typeof useSliderControl>['onDisableArrow'];
  onToggleControlsVisibility: ReturnType<typeof useSliderControl>['onToggleControlsVisibility'];
  /**
   * Если у контейнера есть данный флаг,
   * то он будет автоматически показывать/скрывать стрелки слайдера.
   */
  isControl?: boolean;
}

const Slider: FC<PropsWithChildren<Props>> = ({
  clickedArrow,
  scrollValue,
  isNeedToUpdateScroll,
  scrollValueToChange,
  children,
  onClearClickedArrow,
  onClearIsNeedToUpdateScroll,
  onDisableArrow,
  onToggleControlsVisibility,
  isControl,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const onScroll = (event: UIEvent<HTMLDivElement, globalThis.UIEvent>) => {
    if (
      event.currentTarget.scrollLeft + 16 > 0 &&
      event.currentTarget.scrollLeft + 16 + event.currentTarget.clientWidth <
        event.currentTarget.scrollWidth
    ) {
      onDisableArrow('both');
    }

    if (event.currentTarget.scrollLeft === 0) {
      onDisableArrow('left');
    }

    if (
      event.currentTarget.scrollLeft + 16 > 0 &&
      Math.ceil(event.currentTarget.scrollLeft) + 16 + Math.ceil(event.currentTarget.clientWidth) >=
        event.currentTarget.scrollWidth
    ) {
      onDisableArrow('right');
    }
  };

  /**
   * Обновляет боковой скролл, когда компонент коллапса переходит из свернутого
   * состояния, в раскрытый.
   */
  useEffect(() => {
    if (isNeedToUpdateScroll) {
      /**
       * Данная задержка введена для того, чтобы не возникало багов в Safari и Firefox.
       * Баг [скролл остается неизменным после нажатия на стрелку] возникает из-за анимации
       * после изменения значения скролла.
       */
      setTimeout(() => {
        scrollRef.current.scrollLeft = scrollValue;
      }, 100);

      onClearIsNeedToUpdateScroll();
    }
  }, [isNeedToUpdateScroll]);

  useEffect(() => {
    if (!scrollRef?.current) {
      return;
    }

    if (isControl) {
      // Отвечает за показ и скрытие стрелок слайдера
      if (scrollRef.current.scrollWidth > scrollRef.current.clientWidth) {
        onToggleControlsVisibility(true);
      } else {
        onToggleControlsVisibility(false);
      }
    }

    if (!clickedArrow) {
      return;
    }

    if (clickedArrow === 'left') {
      /**
       * Данная задержка введена для того, чтобы не возникало багов в Safari и Firefox.
       * Баг [скролл остается неизменным после нажатия на стрелку] возникает из-за анимации
       * после изменения значения скролла.
       */
      setTimeout(() => {
        scrollRef.current.scrollLeft -= scrollValueToChange;
      }, 100);
    } else if (clickedArrow === 'right') {
      setTimeout(() => {
        scrollRef.current.scrollLeft += scrollValueToChange;
      }, 100);
    }

    onClearClickedArrow();
  }, [clickedArrow]);

  return (
    <Styled.Wrapper
      ref={scrollRef}
      onScroll={onScroll}
      $isScrollEnabled={isNeedToUpdateScroll || Boolean(clickedArrow)}
    >
      {children}
    </Styled.Wrapper>
  );
};

Slider.displayName = 'Slider';

export default memo(Slider);
