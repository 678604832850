import styled from 'styled-components';

import StyledDynamicTableHead from './components/DynamicTableHead/DynamicTableHead.styles';

const StageWrapper = styled.div`
  padding: 0 12px;
  margin-bottom: 24px;
`;

const Body = styled.div`
  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Wrapper = styled.div<{ $columnHeaderNameMargin: string }>`
  position: relative;

  & ${StyledDynamicTableHead.Wrapper} {
    margin-bottom: ${({ $columnHeaderNameMargin }) =>
      $columnHeaderNameMargin ? $columnHeaderNameMargin : '16px'};
  }
`;

const StyledDynamicTable = {
  StageWrapper,
  Body,
  Wrapper,
};

export default StyledDynamicTable;
