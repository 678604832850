import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

import { provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { IOrganization } from '../../../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import { IEmployee, IUser } from '../../../../../../../../../../../api/models/da-profile/users';
import { ISeason } from '../../../../../../../../../../../api/models/as-fields/seasons';
import { IDictionaryEntity } from '../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { IExperimentContract } from '../../../../../../../../../../../api/models/as-fields/experiments';
import { IRegionEntity } from '../../../../../../../../../../../api/models/da-dictionary/region';
import { ISelectOption } from '../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';

@provide.singleton()
class ExperimentAttributesStore {
  private _selectedContractor: IOrganization | null = null;

  private _currentContractorRegion: IRegionEntity | null = null;

  private _idToContractor: Map<string, IOrganization> = new Map<string, IOrganization>();

  private _idToContract: Map<string, IExperimentContract> = new Map<string, IExperimentContract>();

  private _idToEmployee: Map<string, IEmployee> = new Map<string, IEmployee>();

  private _idToExperimentUser: Map<string, IUser> = new Map<string, IUser>();

  private _yearToSeason: Map<number, ISeason> = new Map<number, ISeason>();

  private _idToCulture: Map<string, IDictionaryEntity> = new Map<string, IDictionaryEntity>();

  private _searchQueries = {
    organizationSearchQuery: '',
    cultureSearchQuery: '',
    assigneeSearchQuery: '',
    regionQuerySearchQuery: '',
    responsibleSearchQuery: '',
    contractSearchQuery: '',
  };

  private _dropdownPageNumbers = {
    organizationCurrentPageNumber: 0,
    organizationTotalPageNumber: 0,
    cultureCurrentPageNumber: 0,
    cultureTotalPageNumber: 0,
    regionCurrentPageNumber: 0,
    regionTotalPageNumber: 0,
    responsibleCurrentPageNumber: 0,
    responsibleTotalPageNumber: 0,
    assigneeCurrentPageNumber: 0,
    assigneeTotalPageNumber: 0,
    contractCurrentPageNumber: 0,
    contractTotalPageNumber: 0,
  };

  private _organizationOptionList: Array<ISelectOption> = [];

  private _cultureOptionList: Array<ISelectOption> = [];

  private _regionOptionList: Array<ISelectOption> = [];

  private _responsibleOptionList: Array<ISelectOption> = [];

  private _assigneeOptionList: Array<ISelectOption> = [];

  private _contractOptionList: Array<ISelectOption> = [];

  constructor() {
    makeAutoObservable(this);
  }

  get selectedContractor() {
    return this._selectedContractor;
  }

  get currentContractorRegion() {
    return this._currentContractorRegion;
  }

  get searchQueries() {
    return this._searchQueries;
  }

  get dropdownPageNumbers() {
    return this._dropdownPageNumbers;
  }

  get organizationOptionList() {
    return this._organizationOptionList;
  }

  get cultureOptionList() {
    return this._cultureOptionList;
  }

  get regionOptionList() {
    return this._regionOptionList;
  }

  get responsibleOptionList() {
    return this._responsibleOptionList;
  }

  get assigneeOptionList() {
    return this._assigneeOptionList;
  }

  get contractOptionList() {
    return this._contractOptionList;
  }

  getContractor = (organizationId: string): IOrganization => {
    return this._idToContractor.get(organizationId);
  };

  setSelectedContractor = (contractor: IOrganization): void => {
    this._selectedContractor = contractor;
  };

  setCurrentContractorRegion = (region: IRegionEntity): void => {
    this._currentContractorRegion = region;
  };

  setContractor = (contractor: IOrganization): void => {
    this._idToContractor.set(contractor.organizationId, contractor);
  };

  setContractorList = (contractorList: IOrganization[]): void => {
    const newIdToContractor: Map<string, IOrganization> = new Map<string, IOrganization>();

    if (_.isArray(contractorList)) {
      contractorList.forEach(contractor => {
        newIdToContractor.set(contractor.organizationId, contractor);
      });
    }

    this._idToContractor = newIdToContractor;
  };

  setContractList = (contractList: IExperimentContract[]): void => {
    const newIdToContract: Map<string, IExperimentContract> = new Map<
      string,
      IExperimentContract
    >();

    if (_.isArray(contractList)) {
      contractList.forEach(contract => {
        newIdToContract.set(contract.id, contract);
      });
    }

    this._idToContract = newIdToContract;
  };

  setEmployeeList = (employeeList: IEmployee[]): void => {
    const newIdToEmployee: Map<string, IEmployee> = new Map<string, IEmployee>();

    if (_.isArray(employeeList)) {
      employeeList.forEach(employee => {
        newIdToEmployee.set(employee.id, employee);
      });
    }

    this._idToEmployee = newIdToEmployee;
  };

  setExperimentUserList = (userList: IUser[]): void => {
    const newIdToExperimentUser: Map<string, IUser> = new Map<string, IUser>();

    if (_.isArray(userList)) {
      userList.forEach(user => {
        newIdToExperimentUser.set(user.id, user);
      });
    }

    this._idToExperimentUser = newIdToExperimentUser;
  };

  setSeasonList = (seasonList: ISeason[]): void => {
    const newYearToSeason: Map<number, ISeason> = new Map<number, ISeason>();

    if (_.isArray(seasonList)) {
      seasonList.forEach(season => {
        newYearToSeason.set(season.year, season);
      });
    }

    this._yearToSeason = newYearToSeason;
  };

  setCultureList = (cultureList: IDictionaryEntity[]): void => {
    const newIdToCulture: Map<string, IDictionaryEntity> = new Map<string, IDictionaryEntity>();

    if (_.isArray(cultureList)) {
      cultureList.forEach(culture => {
        newIdToCulture.set(culture.id, culture);
      });
    }

    this._idToCulture = newIdToCulture;
  };

  setDropdownPageNumbers = (dropdownName: string, pageNumber: number): void => {
    this._dropdownPageNumbers[dropdownName] = pageNumber;
  };

  setDropdownSearchQuery = (dropdownName: string, query: string): void => {
    this._searchQueries[dropdownName] = query;
  };

  setOrganizationOptionList = (optionList: ISelectOption[]): void => {
    this._organizationOptionList = optionList;
  };

  setCultureOptionList = (optionList: ISelectOption[]): void => {
    this._cultureOptionList = optionList;
  };

  setRegionOptionList = (optionList: ISelectOption[]): void => {
    this._regionOptionList = optionList;
  };

  setResponsibleOptionList = (optionList: ISelectOption[]): void => {
    this._responsibleOptionList = optionList;
  };

  setAssigneeOptionList = (optionList: ISelectOption[]): void => {
    this._assigneeOptionList = optionList;
  };

  setContractOptionList = (optionList: ISelectOption[]): void => {
    this._contractOptionList = optionList;
  };

  clearSelectedContractor = (): void => {
    this._selectedContractor = null;
    this._currentContractorRegion = null;
  };

  clearCurrentContractorRegion = (): void => {
    this._currentContractorRegion = null;
  };

  clearIdToContractor = (): void => {
    this._idToContractor.clear();
  };

  clearIdToContract = (): void => {
    this._idToContract.clear();
  };

  clearIdToEmployee = (): void => {
    this._idToEmployee.clear();
  };

  clearIdToExperimentUser = (): void => {
    this._idToExperimentUser.clear();
  };

  clearYearToSeason = (): void => {
    this._yearToSeason.clear();
  };

  clearIdToCulture = (): void => {
    this._idToCulture.clear();
  };

  clearDropdownPageNumbers = (): void => {
    this._dropdownPageNumbers = {
      organizationCurrentPageNumber: 0,
      organizationTotalPageNumber: 0,
      cultureCurrentPageNumber: 0,
      cultureTotalPageNumber: 0,
      regionCurrentPageNumber: 0,
      regionTotalPageNumber: 0,
      responsibleCurrentPageNumber: 0,
      responsibleTotalPageNumber: 0,
      assigneeCurrentPageNumber: 0,
      assigneeTotalPageNumber: 0,
      contractCurrentPageNumber: 0,
      contractTotalPageNumber: 0,
    };
  };

  clearDropdownSearchQuery = (): void => {
    this._searchQueries = {
      organizationSearchQuery: '',
      cultureSearchQuery: '',
      assigneeSearchQuery: '',
      regionQuerySearchQuery: '',
      responsibleSearchQuery: '',
      contractSearchQuery: '',
    };
  };

  clearOrganizationOptionList = (): void => {
    this._organizationOptionList = [];
  };

  clearCultureOptionList = (): void => {
    this._cultureOptionList = [];
  };

  clearRegionOptionList = (): void => {
    this._regionOptionList = [];
  };

  clearResponsibleOptionList = (): void => {
    this._responsibleOptionList = [];
  };

  clearAssigneeOptionList = (): void => {
    this._assigneeOptionList = [];
  };

  clearContractOptionList = (): void => {
    this._contractOptionList = [];
  };

  clearStore = (): void => {
    this.clearSelectedContractor();

    this.clearIdToContractor();
    this.clearIdToContract();
    this.clearIdToEmployee();
    this.clearIdToExperimentUser();
    this.clearYearToSeason();
    this.clearIdToCulture();
    this.clearCurrentContractorRegion();
    this.clearDropdownPageNumbers();
    this.clearOrganizationOptionList();
    this.clearCultureOptionList();
    this.clearRegionOptionList();
    this.clearResponsibleOptionList();
    this.clearAssigneeOptionList();
    this.clearContractOptionList();
    this.clearDropdownSearchQuery();
  };
}

export default ExperimentAttributesStore;
