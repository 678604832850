import { IUpdateNutritionHistoryItemDto } from '../../../../models/as-fields/plan/NutrationHistory';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TUpdateNutritionHistoryItemReq = IUpdateNutritionHistoryItemDto & {
  nutritionHistoryItemId: string;
};
type TUpdateNutritionHistoryItemRes = {} & { id: string };

const updateNutritionHistoryItem: TApiRoute & {
  request: TUpdateNutritionHistoryItemReq;
  response: TUpdateNutritionHistoryItemRes;
} = {
  url: ({ nutritionHistoryItemId }) =>
    `/api/as-fields/nutrition-history-item/${nutritionHistoryItemId}`,
  method: 'PUT',
  request: {} as TUpdateNutritionHistoryItemReq,
  response: {} as TUpdateNutritionHistoryItemRes,
  headers: {},
};

export { updateNutritionHistoryItem };
export type { TUpdateNutritionHistoryItemReq, TUpdateNutritionHistoryItemRes };
