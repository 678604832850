import { sortBy } from 'lodash';
import { useMemo } from 'react';

import { _Instance } from '../../../models';
import { useStore } from '../../../../../utils/helpers/mobx';
import { DynamicTableStore } from '../../../mobx/stores';

const useOrderedInstanceList = (tableId: string): _Instance[] => {
  const { getInstanceList } = useStore(DynamicTableStore);

  const instList = getInstanceList(tableId);

  return useMemo(() => sortBy(instList, 'order'), [instList]);
};

export default useOrderedInstanceList;
