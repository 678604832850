import { IExperimentStep } from '../../../../../api/models/as-fields/experiments';
import { INutritionHistory } from '../../../../../api/models/as-fields/plan/NutrationHistory';
import { IEconomyData } from '../model/IEconomyData.model';

const createEconomyTableBuilderData = (
  experimentSteps: IExperimentStep[],
  nutritionHistories: INutritionHistory[]
): IEconomyData[] => {
  const experimentStepWithInventoryValues = experimentSteps.map(experimentStep => ({
    ...experimentStep,
    itemsList: nutritionHistories.reduce((acc, nutritionHistory) => {
      if (nutritionHistory.experimentStep.id === experimentStep.id) {
        acc.push(...nutritionHistory.items);
      }

      return acc;
    }, []),
    protectionItemsList: nutritionHistories.reduce((acc, nutritionHistory) => {
      if (nutritionHistory.experimentStep.id === experimentStep.id) {
        acc.push(...nutritionHistory.protectionItems);
      }

      return acc;
    }, []),
  }));

  const sortedExperimentStep = experimentStepWithInventoryValues.sort(
    (prev, next) => prev.order - next.order
  );

  const rows = sortedExperimentStep.map((experimentStep, index) => ({
    id: experimentStep.id,
    number: index + 1,
    name: experimentStep.name,
    method: experimentStep?.usageMethodType?.name,
    jobCost: experimentStep?.operation?.servicePricePerArea,
    inventoryValue: '—',
    consumption: '—',
    price: '—',
    cost: '—',
    itemsList: experimentStep.itemsList,
    protectionItemsList: experimentStep.protectionItemsList,
    actions: null,
  }));

  return rows;
};

export { createEconomyTableBuilderData };
