import { FC, memo } from 'react';

import { IActiveSubstancesData } from '../../../model/IActiveSubstancesData.model';
import { IWhatTypeValueFlags } from '../../../model/IWhatTypeValueFlags.model';
import { ActionCell as Cell } from '../content';

import Styled from './ActionCell.styles';
type IRow = Pick<IActiveSubstancesData, 'id' | 'itemsList' | 'name' | 'protectionItemsList'>;

interface IActionCellComponent {
  row: IRow;
  onClickDeleteTechoperation: (row: IRow) => void;
  onClickEditTechoperation: (row: IRow) => void;
  onClickDeleteInventoryValue: (
    row: IRow,
    fertilizeId: string,
    whatTypeValueFlags: IWhatTypeValueFlags
  ) => void;
  onClickEditInventoryValue: (
    fertilizeId: string,
    whatTypeValueFlags: IWhatTypeValueFlags,
    stepId: string
  ) => void;
}

const ActionCellComponent: FC<IActionCellComponent> = ({
  row,
  onClickDeleteTechoperation,
  onClickEditTechoperation,
  onClickDeleteInventoryValue,
  onClickEditInventoryValue,
}) => {
  const deleteTechoperationHandler = () => {
    onClickDeleteTechoperation(row);
  };

  const editTechoperationHandler = () => {
    onClickEditTechoperation(row);
  };

  const deleteInventoryValueHandler = (
    fertilizerId: string,
    whatTypeValueFlags: IWhatTypeValueFlags
  ) => {
    onClickDeleteInventoryValue(row, fertilizerId, whatTypeValueFlags);
  };

  const editInventoryValueHandler = (
    fertilizerId: string,
    whatTypeValueFlags: IWhatTypeValueFlags,
    stepId: string
  ) => {
    onClickEditInventoryValue(fertilizerId, whatTypeValueFlags, stepId);
  };

  return (
    <Styled.Wrapper data-test-id={'techoperation-action-cell-main-wrapper'}>
      <Cell deleteHandler={deleteTechoperationHandler} editHandler={editTechoperationHandler} />
      {row?.itemsList?.map(item => (
        <Cell
          key={item.id}
          dataTestId={'fertilizer'}
          deleteHandler={() =>
            deleteInventoryValueHandler(item.fertilizer.id, { isFertilizer: true })
          }
          editHandler={() => editInventoryValueHandler(item.id, { isFertilizer: true }, row.id)}
        />
      ))}
      {row?.protectionItemsList?.map(item => (
        <Cell
          key={item.id}
          dataTestId={'protection'}
          deleteHandler={() =>
            deleteInventoryValueHandler(item.protection.id, { isProtection: true })
          }
          editHandler={() => editInventoryValueHandler(item.id, { isProtection: true }, row.id)}
        />
      ))}
    </Styled.Wrapper>
  );
};

ActionCellComponent.displayName = 'ActionCellComponent';

export default memo(ActionCellComponent);
