import { Plug } from '../../../../../../../../../common/components/ui';
import applicationTime from '../../../../../../../../../common/assets/icons/application_time.svg';

import Styled from './AuditReportPlug.styles';

export const AuditReportPlug = () => {
  return (
    <Styled.Wrapper>
      <Plug icon={applicationTime} title="Данные не внесены, нет данных к сравнению" />
    </Styled.Wrapper>
  );
};

AuditReportPlug.displayName = 'AuditReportPlug';
