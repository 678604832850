import { makeAutoObservable } from 'mobx';

import {
  TGetCultureZoneExperimentListReq,
  TGetCultureZoneExperimentListRes,
  TGetCultureZoneListReq,
} from '../../../../../../../../../api';
import { IExperimentCultureZone } from '../../../../../../../../../api/models/as-fields/experiments';
import { ICultureZone } from '../../../../../../../../../api/models/as-fields/fields/CultureZone';
import { AxiosService } from '../../../../../../../../common/mobx/services/axios/AxiosService';
import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
export class CreateExperimentService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  constructor() {
    makeAutoObservable(this);
  }

  getCultureZoneExperimentList = async (
    payload: TGetCultureZoneExperimentListReq
  ): Promise<TGetCultureZoneExperimentListRes> => {
    const { getCultureZoneExperimentList } = this.axiosService.api;

    try {
      const fetchedCultureZoneList = await getCultureZoneExperimentList(payload, {
        omit: ['experimentId'],
      });

      return fetchedCultureZoneList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getCultureZoneList = async (payload: TGetCultureZoneListReq): Promise<ICultureZone[]> => {
    const { getCultureZoneList } = this.axiosService.api;

    try {
      const { content } = await getCultureZoneList(payload);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getAllCultureZoneList = async (
    expPayload: TGetCultureZoneExperimentListReq,
    cultureZoneListPayload: TGetCultureZoneListReq
  ): Promise<[IExperimentCultureZone[], ICultureZone[]]> => {
    try {
      const data = await Promise.all([
        this.getCultureZoneExperimentList(expPayload),
        this.getCultureZoneList(cultureZoneListPayload),
      ]);

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}
