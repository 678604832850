import { observer } from 'mobx-react';

import { PageNotFound } from '../../../../../common/features/UI';
import { EErrorStatusName } from '../../../../../common/utils/constants/errorStatus';
import { useStore } from '../../../../../common/utils/helpers/mobx';
import { AccessStore } from '../../../../../common/mobx/stores/AccessStore';
import { systemHelperCollection } from '../../../../../common/utils/helpers/system';

export const withAccessToExperiments = Component =>
  observer(props => {
    const accessStore = useStore(AccessStore);
    const { isAllowToWatchExperiments } = accessStore;
    const { getProfileUrl } = systemHelperCollection;

    const onButtonClick = () => {
      window.location.href = getProfileUrl();
    };

    return isAllowToWatchExperiments === false ? (
      <PageNotFound type={EErrorStatusName.NotEnoughRights} onButtonClick={onButtonClick} />
    ) : (
      <Component {...props} />
    );
  });
