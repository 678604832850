import { FC, memo, useContext } from 'react';

import { StatusPill as StyledStatusPill } from '../StatusPill/StatusPill.styles';
import { EMessageStyleType } from '../../../../../common/features/ChatJournal/models/common';
import { StatusDropdownContext } from '../../StatusDropdown';

const StatusPillApproved: FC<{ children; isSelected?: boolean }> = ({ children, isSelected }) => {
  const isAudit = useContext(StatusDropdownContext);

  const statusPillContent = isAudit ? `Согласован` : `ТЗ согласовано`;

  return (
    <StyledStatusPill $colorScheme={EMessageStyleType.Info} $isSelected={isSelected}>
      {statusPillContent}
      {children}
    </StyledStatusPill>
  );
};

export default memo(StatusPillApproved);
