import { v4 as uuid } from 'uuid';

import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import {
  IComparisonTableBuilderConfig,
  IComparisonTableBuilderRowsGroupConfig,
} from '../../../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import {
  ExecutionTableAddFertilizerRow,
  ExecutionTableRowsGroup,
  ExecutionTableTitleRow,
} from '../../../../../components';
import {
  EExperimentFactTableStepAttributeId as StepAttributeId,
  IExperimentFactTableAttribute,
  IExperimentFactTableRowsGroupOptions,
  IExperimentFactTableStage,
  IExperimentFactTableValue,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { TApiRequest } from '../../../../../../../../../../../../common/mobx/services/axios/AxiosService/Axios.service.types';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { ExecutionColumnConfigsService } from '../../../../../mobx/services/ExecutionColumnConfigsService';
import {
  createExecutionTableFertilizerRowsGroupId as createFertilizerRowsGroupId,
  createExecutionTableProtectionRowsGroupId as createProtectionRowsGroupId,
  createExecutionTableRowId as createRowId,
  getExecutionTableZoneIdByBuilderId,
} from '../../../../../helpers';
import { EComparisonTableName as ETableName } from '../../../../../../../../../../../constants/features';
import { ExecutionStepsCellConfigsService } from '../ExecutionStepsCellConfigsService';
import {
  TExecutionStepsCellConfig as TCellConfig,
  TExecutionStepsRowConfig as TRowConfig,
} from '../../../types';
import { IExperimentStep } from '../../../../../../../../../../../../../api/models/as-fields/experiments';
import { ExecutionStore } from '../../../../../mobx/stores';

type TItemDataForCreate = {
  itemId: string;
  selectedItem: ISelectOption;
  isSkipValues?: boolean;
} & TApiRequest<'createNutritionHistoryItem'>;

type TProtectionItemDataForCreate = {
  itemId: string;
  selectedItem: ISelectOption;
  isSkipValues?: boolean;
} & TApiRequest<'createNutritionHistoryProtectionItem'>;

type TStepDataForCreate = {
  positionNumber: number;
  isSkipValues?: boolean;
} & IExperimentStep;

@provide.transient()
class ExecutionStepsConfigsService {
  @lazyInject(ExecutionColumnConfigsService)
  protected columnConfigService: ExecutionColumnConfigsService;

  @lazyInject(ExecutionStepsCellConfigsService)
  protected cellConfigService: ExecutionStepsCellConfigsService;

  @lazyInject(ExecutionStore)
  protected executionStore: ExecutionStore;

  /**
   * Коллекция формата:
   * идентификатор ряда к списку моделей-конфигов ячеек.
   */
  protected cellConfigsByColumnIdByRowI: Map<string, TCellConfig[]> = new Map();

  createTableConfig = (
    builderId: string,
    stageList: IExperimentFactTableStage[]
  ): IComparisonTableBuilderConfig => {
    this.cellConfigsByColumnIdByRowI = new Map();

    const config: IComparisonTableBuilderConfig = {
      id: builderId,
      name: ETableName.ExecutionSteps,
      headerConfig: {
        autoRenderConfig: {
          preset: 'dashed',
        },
      },
      columnConfigList: this.columnConfigService.createColumnConfigList(),
      rowsGroupConfigList: this.createCollapsingRowsGroupConfigList(stageList, builderId),
    };

    config.cellConfigs = this.getCellConfigs();

    return config;
  };

  createNewNutritionItemConfig = (itemData: TItemDataForCreate) => {
    this.cellConfigsByColumnIdByRowI = new Map();

    const nutritionItemStage = this.createNutritionItemStage(itemData);

    const rowsGroupConfig = this.createNestedRowsGroupConfig(
      nutritionItemStage,
      itemData.experimentStepId
    );

    const cellConfigs = this.getCellConfigs();

    return {
      rowsGroupConfig,
      cellConfigs,
    };
  };

  createNewNutritionProtectionItemConfig = (itemData: TProtectionItemDataForCreate) => {
    this.cellConfigsByColumnIdByRowI = new Map();

    const nutritionProtectionItemStage = this.createNutritionProtectionItemStage(itemData);

    const rowsGroupConfig = this.createNestedRowsGroupConfig(
      nutritionProtectionItemStage,
      itemData.experimentStepId
    );

    const cellConfigs = this.getCellConfigs();

    return {
      rowsGroupConfig,
      cellConfigs,
    };
  };

  protected getCellConfigs = (): IComparisonTableBuilderConfig['cellConfigs'] => {
    return Object.fromEntries([...this.cellConfigsByColumnIdByRowI.entries()]);
  };

  protected createNutritionItemStage = (
    itemData: TItemDataForCreate
  ): IExperimentFactTableStage => {
    return {
      id: createFertilizerRowsGroupId(itemData.experimentStepId, itemData.fertilizerId),
      name: itemData.fertilizerId,
      order: 1,
      attributes: this.createNutritionItemAttributeList(itemData),
      deletable: true,
      isFertilizer: true,
    };
  };

  protected createNutritionProtectionItemStage = (
    itemData: TItemDataForCreate
  ): IExperimentFactTableStage => {
    return {
      id: createProtectionRowsGroupId(itemData.experimentStepId, itemData.protectionId),
      name: itemData.protectionId,
      order: 1,
      attributes: this.createNutritionProtectionItemAttributeList(itemData),
      deletable: true,
      isProtection: true,
    };
  };

  protected createNutritionItemAttributeList = (
    itemData: TItemDataForCreate
  ): IExperimentFactTableAttribute[] => {
    return [
      {
        id: 'fertilizer',
        name: 'Наименование',
        isTitle: true,
        type: 'dictionary',
        editable: false,
        values: this.createNutritionItemFertilizerValueList(itemData),
      },
      {
        id: 'concentration',
        name: 'Дозировка, кг/га',
        type: 'double',
        editable: true,
        values: this.createNutritionItemConcentrationValueList(itemData),
      },
      {
        id: 'pricePerUnit',
        name: 'Цена, Р/кг',
        type: 'double',
        editable: true,
        values: this.createNutritionItemPricePerUnitValueList(itemData),
      },
    ];
  };

  protected createNutritionProtectionItemAttributeList = (
    itemData: TProtectionItemDataForCreate
  ): IExperimentFactTableAttribute[] => {
    const measureInfo = this.executionStore.measureList.find(
      measure => measure.id === itemData.unitOfMeasureId
    );

    const defaultMeasureKG = 'кг';

    const measure = measureInfo ? measureInfo.name : defaultMeasureKG;

    return [
      {
        id: 'protection',
        name: 'Наименование',
        isTitle: true,
        type: 'dictionary',
        editable: false,
        values: this.createNutritionItemProtectionValueList(itemData),
      },
      {
        id: 'concentration',
        name: `Дозировка, ${measure}/га`,
        type: 'double',
        editable: true,
        values: this.createNutritionItemConcentrationValueList(itemData),
      },
      {
        id: 'pricePerUnit',
        name: `Цена, Р/${measure}`,
        type: 'double',
        editable: true,
        values: this.createNutritionItemPricePerUnitValueList(itemData),
      },
    ];
  };

  protected createNutritionItemFertilizerValueList = ({
    itemId,
    selectedItem,
  }: TItemDataForCreate): IExperimentFactTableValue[] => {
    return [
      {
        skip: true,
        editable: false,
      },
      {
        editable: false,
        entityId: itemId,
        dictionaryValues: [{ name: selectedItem.label, id: selectedItem.value as string }],
      },
    ];
  };

  protected createNutritionItemProtectionValueList = ({
    itemId,
    selectedItem,
  }: TProtectionItemDataForCreate): IExperimentFactTableValue[] => {
    return [
      {
        skip: true,
        editable: false,
      },
      {
        editable: false,
        entityId: itemId,
        dictionaryValues: [{ name: selectedItem.label, id: selectedItem.value as string }],
      },
    ];
  };

  protected createNutritionItemPricePerUnitValueList = ({
    itemId,
    isSkipValues,
    pricePerUnit,
  }: TItemDataForCreate): IExperimentFactTableValue[] => {
    const planValue: IExperimentFactTableValue = {
      skip: true,
      editable: false,
    };

    const factValue: IExperimentFactTableValue = {
      editable: true,
      entityId: itemId,
    };

    if (!isSkipValues) {
      factValue.doubleValue = pricePerUnit;
    }

    return [planValue, factValue];
  };

  protected createNutritionItemConcentrationValueList = ({
    itemId,
    isSkipValues,
    concentration,
  }: TItemDataForCreate): IExperimentFactTableValue[] => {
    const planValue: IExperimentFactTableValue = {
      skip: true,
      editable: false,
    };

    const factValue: IExperimentFactTableValue = {
      editable: true,
      entityId: itemId,
    };

    if (!isSkipValues) {
      factValue.doubleValue = concentration;
    }

    return [planValue, factValue];
  };

  createStepConfig = (stepData: TStepDataForCreate, builderId) => {
    this.cellConfigsByColumnIdByRowI = new Map();

    const stepStage = this.createStepStage(stepData);

    const rowsGroupConfig = this.createCollapsingRowsGroupConfig(stepStage, builderId);

    const cellConfigs = this.getCellConfigs();

    return {
      rowsGroupConfig,
      cellConfigs,
    };
  };

  protected createStepStage = (stepData: TStepDataForCreate): IExperimentFactTableStage => {
    return {
      id: stepData.id,
      name: `${stepData.positionNumber}. ${stepData.name}`,
      order: stepData.positionNumber,
      attributes: this.createStepAttributeList(stepData),
      deletable: true,
      audits: [],
    };
  };

  protected createStepAttributeList = (
    stepData: TStepDataForCreate
  ): IExperimentFactTableAttribute[] => {
    return [
      {
        id: StepAttributeId.StartDate,
        name: 'Дата начала',
        type: 'date',
        editable: true,
        values: this.createStepStartDateValueList(stepData),
      },
      {
        id: StepAttributeId.EndDate,
        name: 'Дата окончания',
        type: 'date',
        editable: true,
        values: this.createStepEndDateValueList(stepData),
      },
      {
        id: StepAttributeId.Phenophase,
        name: 'Фенофаза (BBCH) от',
        type: 'dictionary',
        editable: true,
        values: this.createStepPhenophaseValueList(stepData),
      },
      {
        id: StepAttributeId.PhenophaseEnd,
        name: 'Фенофаза (BBCH) до',
        type: 'dictionary',
        editable: true,
        values: this.createStepPhenophaseEndValueList(stepData),
      },
      {
        id: StepAttributeId.ServicePricePerArea,
        name: 'Стоимость работ, Р/га',
        type: 'double',
        editable: true,
        values: this.createStepServicePricePerAreaValueList(stepData),
      },
      {
        id: StepAttributeId.Fertilizers,
        name: 'ТМЦ',
        type: 'nested',
        editable: true,
        values: [],
      },
      {
        id: StepAttributeId.Protections,
        name: 'ТМЦ',
        type: 'nested',
        editable: true,
        values: [],
      },
    ];
  };

  protected createStepStartDateValueList = (
    stepData: TStepDataForCreate
  ): IExperimentFactTableValue[] => {
    const planValue: IExperimentFactTableValue = {
      editable: false,
      skip: true,
    };

    const factValue: IExperimentFactTableValue = {
      editable: true,
    };

    if (!stepData.isSkipValues && stepData.startDate) {
      factValue.dateValue = stepData.startDate;
    } else {
      factValue.skip = true;
    }

    return [planValue, factValue];
  };

  protected createStepEndDateValueList = (
    stepData: TStepDataForCreate
  ): IExperimentFactTableValue[] => {
    const planValue: IExperimentFactTableValue = {
      editable: false,
      skip: true,
    };

    const factValue: IExperimentFactTableValue = {
      editable: true,
    };

    if (!stepData.isSkipValues && stepData.endDate) {
      factValue.dateValue = stepData.endDate;
    } else {
      factValue.skip = true;
    }

    return [planValue, factValue];
  };

  protected createStepPhenophaseValueList = (
    stepData: TStepDataForCreate
  ): IExperimentFactTableValue[] => {
    const planValue: IExperimentFactTableValue = {
      editable: false,
      skip: true,
    };

    const factValue: IExperimentFactTableValue = {
      editable: true,
    };

    if (!stepData.isSkipValues && stepData.phenophase) {
      factValue.dictionaryValues = [
        {
          name: stepData.phenophase?.name,
          code: stepData.phenophase?.code,
          description: stepData.phenophase?.description,
          id: stepData.phenophase?.id,
        },
      ];
    } else {
      factValue.skip = true;
    }

    return [planValue, factValue];
  };

  protected createStepPhenophaseEndValueList = (
    stepData: TStepDataForCreate
  ): IExperimentFactTableValue[] => {
    const planValue: IExperimentFactTableValue = {
      editable: false,
      skip: true,
    };

    const factValue: IExperimentFactTableValue = {
      editable: true,
    };

    if (!stepData.isSkipValues && stepData.phenophaseEnd) {
      factValue.dictionaryValues = [
        {
          name: stepData.phenophaseEnd?.name,
          code: stepData.phenophaseEnd?.code,
          description: stepData.phenophaseEnd?.description,
          id: stepData.phenophaseEnd?.id,
        },
      ];
    } else {
      factValue.skip = true;
    }

    return [planValue, factValue];
  };

  protected createStepServicePricePerAreaValueList = (
    stepData: TStepDataForCreate
  ): IExperimentFactTableValue[] => {
    const planValue: IExperimentFactTableValue = {
      editable: false,
      skip: true,
    };

    const factValue: IExperimentFactTableValue = {
      editable: true,
    };

    if (
      !stepData.isSkipValues &&
      (stepData.servicePricePerArea || stepData.servicePricePerArea === 0)
    ) {
      factValue.doubleValue = stepData.servicePricePerArea;
    } else {
      factValue.skip = true;
    }

    return [planValue, factValue];
  };

  protected createCollapsingRowsGroupConfigList = (
    stageList: IExperimentFactTableStage[],
    builderId: string
  ): IComparisonTableBuilderRowsGroupConfig[] => {
    return stageList.map(stage => this.createCollapsingRowsGroupConfig(stage, builderId));
  };

  protected createCollapsingRowsGroupConfig = (
    stage: IExperimentFactTableStage,
    builderId: string
  ): IComparisonTableBuilderRowsGroupConfig => {
    const { id, name, audits, attributes, deletable } = stage;

    const zoneId = getExecutionTableZoneIdByBuilderId(builderId);

    return {
      id,
      rowConfigList: [
        ...this.createRowConfigList(attributes, id, id),
        this.createAddFertilizerRowConfig(id, attributes),
      ],
      customRenderConfig: {
        render: (rowsGroup, rowsChildren, dataTestId) => (
          <ExecutionTableRowsGroup
            title={name}
            name={name}
            zoneId={zoneId}
            rowsChildren={rowsChildren}
            isShowDeleteIcon={deletable}
            stepId={id}
            dataTestId={dataTestId}
            audits={audits}
          />
        ),
      },
    };
  };

  protected createNestedRowsGroupConfigList = (
    stageList: IExperimentFactTableStage[],
    stepId: string
  ): IComparisonTableBuilderRowsGroupConfig[] => {
    return stageList.map(stage => this.createNestedRowsGroupConfig(stage, stepId));
  };

  protected createNestedRowsGroupConfig = (
    stage: IExperimentFactTableStage,
    stepId: string
  ): IComparisonTableBuilderRowsGroupConfig => {
    const { attributes, deletable, isProtection } = stage;

    if (isProtection || attributes?.[0]?.id === StepAttributeId.Protection) {
      const protectionId = this.getProtectionId(attributes[0]);
      const id = createProtectionRowsGroupId(stepId, protectionId);

      return {
        id,
        rowConfigList: [
          ...this.createRowConfigList(attributes, id, stepId, {
            protectionId,
            deletable,
            isProtection: true,
          }),
        ],
        autoRenderConfig: {
          preset: 'cleared',
        },
      };
    } else {
      const fertilizerId = this.getFertilizerId(attributes[0]);
      const id = createFertilizerRowsGroupId(stepId, fertilizerId);

      return {
        id,
        rowConfigList: [
          ...this.createRowConfigList(attributes, id, stepId, {
            fertilizerId,
            deletable,
            isFertilizer: true,
          }),
        ],
        autoRenderConfig: {
          preset: 'cleared',
        },
      };
    }
  };

  protected createRowConfigList = (
    attributeList: IExperimentFactTableAttribute[],
    stageId: string,
    stepId: string,
    nestedRowsGroupOptions?: IExperimentFactTableRowsGroupOptions
  ): TRowConfig[] => {
    return attributeList.map(attribute => {
      if (attribute.isTitle) {
        return this.createTitleRowConfig(attribute, stageId, stepId, {
          ...nestedRowsGroupOptions,
          isFertilizer: attribute.id === StepAttributeId.Fertilizer,
          isProtection: attribute.id === StepAttributeId.Protection,
        });
      }

      if (attribute.type === 'nested') {
        return this.createNestedRowConfig(attribute, stageId, stepId, {
          ...nestedRowsGroupOptions,
          isFertilizer: attribute.id === StepAttributeId.Fertilizer,
          isProtection: attribute.id === StepAttributeId.Protection,
        });
      }

      return this.createDefaultRowConfig(attribute, stageId, stepId, {
        ...nestedRowsGroupOptions,
        isFertilizer: attribute.id === StepAttributeId.Fertilizers,
        isProtection: attribute.id === StepAttributeId.Protections,
      });
    });
  };

  protected createDefaultRowConfig = (
    attribute: IExperimentFactTableAttribute,
    stageId: string,
    stepId: string,
    nestedRowsGroupOptions?: IExperimentFactTableRowsGroupOptions
  ): TRowConfig => {
    const { id, name, values, isTitle } = attribute;

    const rowConfig: TRowConfig = {
      isWithoutCells: isTitle,
      id: createRowId(stageId, id),
      autoRenderConfig: {
        preset: 'default',
        name,
      },
      initialModel: {
        ...attribute,
        ...nestedRowsGroupOptions,
        stepId,
      },
    };

    const cellConfigList = this.cellConfigService.createCellConfigList(rowConfig, values);

    this.cellConfigsByColumnIdByRowI.set(rowConfig.id, cellConfigList);

    return rowConfig;
  };

  protected createTitleRowConfig = (
    attribute: IExperimentFactTableAttribute,
    stageId: string,
    stepId: string,
    nestedRowsGroupOptions?: IExperimentFactTableRowsGroupOptions
  ): TRowConfig => {
    const { id } = attribute;

    const rowConfig: TRowConfig = {
      isWithoutCells: true,
      id: createRowId(stageId, id),
      initialModel: {
        ...attribute,
        ...nestedRowsGroupOptions,
        stepId,
      },
    };

    rowConfig.customRenderConfig = {
      render: (_row, dataTestId) => (
        <ExecutionTableTitleRow
          rowsGroupId={stageId}
          name={this.createNameOfTitleRow(attribute)}
          rowsGroupOptions={nestedRowsGroupOptions}
          stepId={stepId}
          dataTestId={dataTestId}
        />
      ),
    };

    return rowConfig;
  };

  protected createNestedRowConfig = (
    attribute: IExperimentFactTableAttribute,
    stageId: string,
    stepId: string,
    nestedRowsGroupOptions?: IExperimentFactTableRowsGroupOptions
  ): TRowConfig => {
    const { id, values, isTitle } = attribute;

    const nestedStageList = values.flatMap(value => value.stages);

    return {
      isWithoutCells: isTitle,
      id: createRowId(stageId, id),
      rowsGroupConfigList: this.createNestedRowsGroupConfigList(nestedStageList, stepId),
      initialModel: {
        ...attribute,
        ...nestedRowsGroupOptions,
        stepId,
      },
    };
  };

  protected createNameOfTitleRow = (reportAttribute: IExperimentFactTableAttribute): string => {
    switch (reportAttribute.type) {
      case 'dictionary': {
        const firstDictionaryValueList = reportAttribute.values.find(
          value => value?.dictionaryValues?.length
        )?.dictionaryValues;

        if (firstDictionaryValueList) {
          return firstDictionaryValueList.find(value => value.name).name;
        }

        return reportAttribute.name;
      }

      default:
        return reportAttribute.name;
    }
  };

  protected getFertilizerId = (attribute: IExperimentFactTableAttribute): string => {
    const dictionaryValueList = attribute.values.find(
      value => value.dictionaryValues
    )?.dictionaryValues;

    if (dictionaryValueList) {
      const [firstValue] = dictionaryValueList;

      return firstValue.id;
    }
  };

  protected getProtectionId = (attribute: IExperimentFactTableAttribute): string => {
    const dictionaryValueList = attribute.values.find(
      value => value.dictionaryValues
    )?.dictionaryValues;

    if (dictionaryValueList) {
      const [firstValue] = dictionaryValueList;

      return firstValue.id;
    }
  };

  protected createAddFertilizerRowConfig = (
    stageId: string,
    attributeList: IExperimentFactTableAttribute[]
  ): TRowConfig => {
    return {
      id: createRowId(stageId, uuid()),
      isWithoutCells: true,
      customRenderConfig: {
        render: (_row, dataTestId) => (
          <ExecutionTableAddFertilizerRow
            rootRowId={createRowId(stageId, 'fertilizers')}
            stepId={stageId}
            initialFertilizerIdList={this.getInitialFertilizerIdList(attributeList)}
            initialProtectionIdList={this.getInitialProtectionIdList(attributeList)}
            dataTestId={dataTestId}
          />
        ),
      },
    };
  };

  protected getInitialFertilizerIdList = (
    attributeList: IExperimentFactTableAttribute[]
  ): string[] => {
    const fertilizeAttribute = attributeList.find(
      attribute => attribute.id === StepAttributeId.Fertilizers
    );

    const fertilizerIdList = fertilizeAttribute.values.flatMap(value =>
      value.stages.flatMap(stage => stage.attributes.flatMap(this.getFertilizerId))
    );

    return fertilizerIdList.filter(fertilizerId => fertilizerId);
  };

  protected getInitialProtectionIdList = (
    attributeList: IExperimentFactTableAttribute[]
  ): string[] => {
    const nestedAttribute = attributeList.find(
      attribute => attribute.id === StepAttributeId.Protections
    );

    const protectionIdList = nestedAttribute.values.flatMap(value =>
      value.stages.flatMap(stage => stage.attributes.flatMap(this.getProtectionId))
    );

    return protectionIdList.filter(protectionId => protectionId);
  };
}

export default ExecutionStepsConfigsService;
