import styled, { css } from 'styled-components';

import { TComparisonTableBuilderCellPreset as TCellPreset } from '../../../models/types/cells';

const getFontPreset = (preset: TCellPreset) => {
  switch (preset) {
    case 'bold':
      return css`
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      `;

    default:
      return css`
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      `;
  }
};

const Wrapper = styled.div<{ $preset: TCellPreset; $padding: string }>`
  width: 100%;

  ${({ $preset }) => getFontPreset($preset)}

  padding: ${({ $padding }) => ($padding ? $padding : '12px 0')};

  color: #151f32;
`;

const CellContent = styled.div<{ $isMainRowCell: boolean }>`
  width: fit-content;
  max-width: 180px;

  ${({ $isMainRowCell }) =>
    $isMainRowCell
      ? css``
      : css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
`;

const StyledComparisonTableBuilderClearedRowsGroup = {
  Wrapper,
  CellContent,
};

export default StyledComparisonTableBuilderClearedRowsGroup;
