import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../../models/common/response';
import { ICultureZone } from '../../../../models/as-fields/fields/CultureZone';

type TGetCultureZoneListReq = {
  size?: number;
  page?: number;
  withGeometry?: boolean;
  organizationId?: string;
  viewportCoordinates?: number[];
  seasonYear?: number;
  technologyId?: string;
  experimentReady?: boolean;
  onlyExperimentId?: string;
  readyForExperimentId?: string;
  onlyWithoutExperiment?: boolean;
  cultureId?: string;
  cultureZoneId?: string;
  sort?: string;
};

type TGetCultureZoneListRes = TResponseList<ICultureZone>;

const getCultureZoneList: TApiRoute & {
  request: TGetCultureZoneListReq;
  response: TGetCultureZoneListRes;
} = {
  url: () => `/api/as-fields/fields/cultureZones/list`,
  method: 'GET',
  request: {} as TGetCultureZoneListReq,
  response: {} as TGetCultureZoneListRes,
  headers: {},
};

export type { TGetCultureZoneListReq, TGetCultureZoneListRes };

export { getCultureZoneList };
