import React, { FC, useMemo } from 'react';
import { IconNames } from '@farmlink/farmik-ui/dist/Icon';

import { useDataTestIdV2 } from '../../../utils/hooks/locators';

import Styled from './ActionsMenu.styles';

export interface IMenuItem {
  icon: IconNames;
  onClick: () => void;
  colorScheme: {
    default: {
      background: string | null;
      icon: string | null;
    };
    hover: {
      background: string | null;
      icon: string | null;
    };
  };
}

interface IActionsMenuProps {
  menuConfig: IMenuItem[];
  dataId: string;
  dataTestId: string;
}

const ActionsMenu: FC<IActionsMenuProps> = ({ menuConfig, dataId, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__actions-menu`);

  return (
    <>
      {dataId
        ? menuConfig.map(menuItem => (
            <Styled.ActionButton
              dataTestId={getDataTestId('button')['data-test-id']}
              key={`${menuItem.icon}__${dataId}`}
              size={32}
              onClick={e => {
                e.stopPropagation();
                menuItem.onClick();
              }}
              colorScheme={menuItem.colorScheme}
              icon={menuItem.icon}
              $padding={'8px'}
            />
          ))
        : null}
    </>
  );
};

ActionsMenu.displayName = 'Menu';

export default ActionsMenu;
