import styled from 'styled-components';

interface IItem {
  $isTitleRow: boolean;
}

const Item = styled.span<IItem>`
  font-weight: ${({ $isTitleRow }) => ($isTitleRow ? 600 : 400)};
  font-size: 14px;
  line-height: 20px;
  min-width: 48px;
`;

const StyledActiveSubstancesCell = {
  Item,
};

export default StyledActiveSubstancesCell;
