import { FC, memo, MouseEvent, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { Container } from '../../../../../../../common/features/UI';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { AuditStore } from '../../mobx/store';
import { DynamicTable } from '../../../../../../../common/features/DynamicTable';
import {
  useCheckChangedForm,
  useForm,
} from '../../../../../../../common/features/form/utils/hooks';
import { ContentLoader } from '../../../../../../../common/features/UI/loaders/ContentLoader';
import { useWarningBeforeLeavingThePage } from '../../../../../../../common/utils/hooks';
import { usePageContextualHelp } from '../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../common/constants/contextualPath';

import Styled from './AuditReport.styles';
import AuditReportController from './mobx/controller/AuditReport.controller';
import { AuditReportCommentSection, AuditReportStepFooterContainer } from './containers';
import {
  AUDIT_REPORT_FORM_KEY,
  createAuditReportFormConfig,
  IAuditReportForm,
} from './containers/AuditReportCommentSection/forms/config/auditReportFormConfig';
import { AuditReportHeaderActions, AuditReportPlug } from './components';
import AuditReportStore from './mobx/stores/AuditReport.store';

const AuditReport: FC = () => {
  const { selectedAudit, selectedExp } = useStore(AuditStore);
  const { dynamicTableConfig, clearStore, setIsLoading, isLoading } = useStore(AuditReportStore);
  const {
    getDynamicTableConfig,
    updateExperimentStep,
    fetchExperimentStepData,
    getAvailibleTabList,
  } = useStore(AuditReportController);

  const { isFormChanged, focusChangedElement, setIsFormChanged } = useCheckChangedForm();
  useWarningBeforeLeavingThePage(isFormChanged, focusChangedElement);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  const contextualParentPath = EContextualParentPath.AuditsAuditReportAudit;
  usePageContextualHelp(contextualParentPath);

  const helpActions = useContextualHelpActions();

  const СomparisonContextualHelpIcon = helpActions.getContextualHelpIcon(
    contextualParentPath,
    ContextualPaths.AuditsAuditReportAuditComparisonTableTitle
  );

  const CommentContextualHelpIcon = helpActions.getContextualHelpIcon(
    contextualParentPath,
    ContextualPaths.AuditsAuditReportAuditCommentTitle
  );

  useEffect(() => {
    if (!selectedExp || !selectedAudit) {
      return;
    }

    getAvailibleTabList();

    getDynamicTableConfig().finally(() => setIsLoading(false));
  }, [selectedAudit, selectedExp]);

  const { elements, registerForm, changeListOfFormValue, submitForm } =
    useForm<IAuditReportForm>(AUDIT_REPORT_FORM_KEY);

  useEffect(() => {
    registerForm(createAuditReportFormConfig());
  }, []);

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();

    await submitForm(async validatedData => {
      await updateExperimentStep(selectedAudit.id, validatedData);

      setIsFormChanged(false);
    });
  };

  useEffect(() => {
    if (selectedAudit?.id) {
      (async () => {
        const stepData = await fetchExperimentStepData(selectedAudit.id);

        changeListOfFormValue({
          specialNotes: stepData.specialNotes || '',
          technologicalDeviation: stepData.technologicalDeviation || '',
          generalComment: stepData.generalComment || '',
        });
      })();
    }
  }, [selectedAudit?.id]);

  return (
    <Styled.FormWrapper>
      <Container
        headerChildren={<AuditReportHeaderActions />}
        ContextualHelpIcon={СomparisonContextualHelpIcon}
      >
        {isLoading ? (
          <ContentLoader
            overlayStyles={{
              position: 'relative',
              height: '184px',
              width: '100%',
            }}
          />
        ) : dynamicTableConfig?.instances.length > 0 ? (
          <DynamicTable config={dynamicTableConfig} />
        ) : (
          <AuditReportPlug />
        )}
      </Container>

      <Container title="Комментарии" ContextualHelpIcon={CommentContextualHelpIcon}>
        <AuditReportCommentSection elements={elements} />
      </Container>

      <AuditReportStepFooterContainer onSubmit={handleSubmit} />
    </Styled.FormWrapper>
  );
};

AuditReport.displayName = 'AuditReport';

export default memo(observer(AuditReport));
