import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { priceType } from '../../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/calculation/containers/Calculation/constants';
import { formatNumValue } from '../../../../../../utils/helpers/numbers';
import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './CostCell.style';

interface ICostCellProps {
  cost: number;
  dataTestId?: string;
}

const CostCell: FC<ICostCellProps> = ({ cost, dataTestId }) => {
  const { ref, showTooltip } = useShowTooltip();

  const valueToDisplay = `${formatNumValue(cost)} ${priceType.rublesOnGa}`;

  return (
    <AutoTooltip content={`${cost} ${priceType.rublesOnGa}`} position="top" disabled={!showTooltip}>
      <Styled.Cost data-test-id={dataTestId} ref={ref}>
        {valueToDisplay}
      </Styled.Cost>
    </AutoTooltip>
  );
};

CostCell.displayName = 'CostCell';

export default memo(CostCell);
