import { ECommonColor } from '../../../assets/styles/colors';
import { EMessageStyleType, _MessageColorSchemeItem } from '../models/common';

type TMessageColorItem = Record<EMessageStyleType, _MessageColorSchemeItem>;

const MessageColorsScheme: TMessageColorItem = {
  default: {
    messageBackgroundColor: ECommonColor.SecondaryGray,
    notificationBackgroundColor: ECommonColor.SecondaryGray,
    borderColor: ECommonColor.GeneralBlack,
    textColor: ECommonColor.GeneralBlack,
  },
  info: {
    messageBackgroundColor: ECommonColor.SecondaryGray,
    notificationBackgroundColor: ECommonColor.LightSky,
    borderColor: ECommonColor.AddSky,
    textColor: ECommonColor.AddSky,
  },
  success: {
    messageBackgroundColor: ECommonColor.SecondaryGray,
    notificationBackgroundColor: ECommonColor.LightGreen,
    borderColor: ECommonColor.AccentGreen,
    textColor: ECommonColor.AccentGreen,
  },
  warning: {
    messageBackgroundColor: ECommonColor.SecondaryGray,
    notificationBackgroundColor: ECommonColor.LightOrange,
    borderColor: ECommonColor.AccentOrange,
    textColor: ECommonColor.AddOrange,
  },
  error: {
    messageBackgroundColor: ECommonColor.SecondaryGray,
    notificationBackgroundColor: ECommonColor.LightPink,
    borderColor: ECommonColor.AccentPink,
    textColor: ECommonColor.AccentPink,
  },
  draft: {
    messageBackgroundColor: ECommonColor.SecondaryGray,
    notificationBackgroundColor: ECommonColor.SecondaryGray,
    borderColor: ECommonColor.SecondaryDarkGray,
    textColor: ECommonColor.SecondaryDarkGray,
  },
  fullSuccess: {
    messageBackgroundColor: ECommonColor.LightAqua,
    notificationBackgroundColor: ECommonColor.LightAqua,
    borderColor: ECommonColor.AccentAqua,
    textColor: ECommonColor.AccentAqua,
  },
};

export default MessageColorsScheme;
export type { TMessageColorItem };
