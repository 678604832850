import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../assets/styles/colors';

import horizontalDefaultIconSvg from './assets/icons/horizontal-orientation-icon-default-24x24.svg';
import horizontalSelectedIconSvg from './assets/icons/horizontal-orientation-icon-selected-24x24.svg';
import verticalDefaultIconSvg from './assets/icons/vertical-orientation-icon-default-24x24.svg';
import verticalSelectedIconSvg from './assets/icons/vertical-orientation-icon-selected-24x24.svg';
import horizontalDefaultBlockedIconSvg from './assets/icons/horizontal-orientation-icon-default-blocked-24x24.svg';
import horizontalSelectedBlockedIconSvg from './assets/icons/horizontal-orientation-icon-selected-blocked-24x24.svg';
import verticalDefaultBlockedIconSvg from './assets/icons/vertical-orientation-icon-default-blocked-24x24.svg';
import verticalSelectedBlockedIconSvg from './assets/icons/vertical-orientation-icon-selected-blocked-24x24.svg';

const HorizontalOrientationButton = styled.div<{ $isSelected: boolean; $isEnableEditing: boolean }>`
  width: 24px;
  height: 24px;
  
  cursor: ${({ $isEnableEditing }) => ($isEnableEditing ? 'pointer' : 'default')};
  
  ${({ $isSelected, $isEnableEditing }) =>
    $isSelected
      ? css`
          background: url(${$isEnableEditing
            ? horizontalSelectedIconSvg
            : horizontalSelectedBlockedIconSvg});
        `
      : css`
          background: url(${$isEnableEditing
            ? horizontalDefaultIconSvg
            : horizontalDefaultBlockedIconSvg});
        `}}
`;

const VerticalOrientationButton = styled(HorizontalOrientationButton)`
  ${({ $isSelected, $isEnableEditing }) =>
    $isSelected
      ? css`
          background: url(${$isEnableEditing
            ? verticalSelectedIconSvg
            : verticalSelectedBlockedIconSvg});
        `
      : css`
          background: url(${$isEnableEditing
            ? verticalDefaultIconSvg
            : verticalDefaultBlockedIconSvg});
        `}}
`;

const OrientationLabel = styled.div`
  font-size: 14px;
  line-height: 20px;

  margin-right: 12px;
  width: 144px;

  color: ${ECommonColor.GeneralBlack};
`;

const Name = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  width: 570px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 14px;

  color: ${ECommonColor.GeneralBlack};
`;

const FlexWrapper = styled.div<{ $isHidden?: boolean }>`
  display: flex;
  align-items: center;

  gap: 8px;

  ${({ $isHidden }) =>
    $isHidden
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 12px;
  margin-bottom: 12px;

  border-bottom: 1px solid ${ECommonColor.BorderGray};
`;

const Body = styled.div<{ $isHidden?: boolean }>`
  display: flex;
  flex-wrap: wrap;

  ${({ $isHidden }) =>
    $isHidden
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
`;

const Wrapper = styled.div``;

const StyledComparisonTableImagesStage = {
  HorizontalOrientationButton,
  VerticalOrientationButton,
  OrientationLabel,
  Name,
  FlexWrapper,
  Header,
  Body,
  Wrapper,
};

export default StyledComparisonTableImagesStage;
