import { FC, memo } from 'react';

import { IComparisonTableBuilderRow } from '../../../models/data';
import { ComparisonTableBuilderRowAutoRenderContainer as RowAutoRenderContainer } from '../ComparisonTableBuilderRowAutoRenderContainer';
import { ComparisonTableBuilderRowCustomRenderContainer as RowCustomRenderContainer } from '../ComparisonTableBuilderRowCustomRenderContainer';
import { ComparisonTableBuilderRowNestedRenderContainer as RowNestedRenderContainer } from '../ComparisonTableBuilderRowNestedRenderContainer';

interface IProps {
  row: IComparisonTableBuilderRow;
}

const ComparisonTableBuilderRowContainer: FC<IProps> = ({ row }) => {
  switch (row.renderType) {
    case 'auto':
      return <RowAutoRenderContainer row={row} />;

    case 'custom':
      return <RowCustomRenderContainer row={row} />;

    case 'nested':
      return <RowNestedRenderContainer row={row} />;

    default:
      return <></>;
  }
};

ComparisonTableBuilderRowContainer.displayName = 'ComparisonTableBuilderRowContainer';

export default memo(ComparisonTableBuilderRowContainer);
