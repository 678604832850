import { FC, PropsWithChildren } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './ComparisonSummaryRowsGroup.styles';

interface IProps {
  dataTestId: string;
}

const ComparisonSummaryRowsGroup: FC<PropsWithChildren<IProps>> = ({ children, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return <Styled.Wrapper {...getDataTestId()}>{children}</Styled.Wrapper>;
};

ComparisonSummaryRowsGroup.displayName = 'ComparisonSummaryRowsGroup';

export default ComparisonSummaryRowsGroup;
