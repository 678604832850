import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { IExperimentContract } from '../../../../models/as-fields/experiments';

type TChangeExperimentContractReq = {
  id: string;
  name?: string;
  contractDate?: string;
  attachedFiles?: string[];
  organizationId?: string;
};

type TChangeExperimentContractRes = IExperimentContract;

const changeExperimentContract: TApiRoute & {
  request: TChangeExperimentContractReq;
  response: TChangeExperimentContractRes;
} = {
  url: ({ id }) => `/api/as-fields/experiment/contract/${id}`,
  method: 'PUT',
  request: {} as TChangeExperimentContractReq,
  response: {} as TChangeExperimentContractRes,
  headers: {},
};

export type { TChangeExperimentContractReq, TChangeExperimentContractRes };

export { changeExperimentContract };
