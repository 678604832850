import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { IOperation } from '../../../models/as-fields/operation/Operation/Operation.model';

type TGetOperationListReq = {
  seasonYear: number;
  technologyId?: string;
  cultureId?: string;
  noCulture?: boolean;
  organizationId?: string;
  experimentId?: string;
  experimentStepId?: string;
};

type TGetOperationListRes = TResponseList<IOperation>;

const getOperationList: TApiRoute & {
  request: TGetOperationListReq;
  response: TGetOperationListRes;
} = {
  url: () => `/api/as-fields/operation/list`,
  method: 'GET',
  request: {} as TGetOperationListReq,
  response: {} as TGetOperationListRes,
  headers: {},
};

export type { TGetOperationListReq, TGetOperationListRes };

export { getOperationList };
