import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IField } from '../../../models/as-fields/fields/Field.model';

type TGetFieldReq = {
  id: string;
  seasonYear?: number;
};

type TGetFieldRes = IField;

const getField: TApiRoute & {
  request: TGetFieldReq;
  response: TGetFieldRes;
} = {
  url: ({ id }) => `/api/as-fields/fields/${id}`,
  method: 'GET',
  request: {} as TGetFieldReq,
  response: {} as TGetFieldRes,
  headers: {},
};

export type { TGetFieldReq, TGetFieldRes };

export { getField };
