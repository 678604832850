import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IExperiment } from '../../../models/as-fields/experiments';

export type TUpdateExperimentFilesReq = { experimentId: string; attachedFiles: string[] };

export type TUpdateExperimentFilesRes = IExperiment;

const updateExperimentFiles: TApiRoute & {
  request: TUpdateExperimentFilesReq;
  response: TUpdateExperimentFilesRes;
} = {
  url: ({ experimentId }) => `/api/as-fields/experiment/${experimentId}/attachedFiles`,
  method: 'PUT',
  request: {} as TUpdateExperimentFilesReq,
  response: {} as TUpdateExperimentFilesRes,
  headers: {},
};

export { updateExperimentFiles };
