import { FC } from 'react';
import { observer } from 'mobx-react';

import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { ITableBuilderCell as ICell, ITableBuilderRow as IRow } from '../../../models/data';
import { TableBuilderCell as Cell } from '../../../components/content';

interface IProps {
  cell: ICell;
  row: IRow;
}

const TableBuilderCellAutoRenderContainer: FC<IProps> = ({ cell, row }) => {
  const getDataTestId = useDataTestId();

  const dataTestId = getDataTestId(`cell-with-key-${cell.id}`)['data-test-id'];

  const { renderValue, alighnItems, isTitle, height, isWrap } = cell.autoRenderConfig;
  const value = renderValue(row);
  const alignItems = alighnItems;
  const isTitleCell = isTitle;

  return (
    <Cell
      key={cell.id}
      value={value}
      dataTestId={dataTestId}
      alignItems={alignItems}
      height={height}
      isWrap={isWrap}
      isTitle={isTitleCell}
    />
  );
};

TableBuilderCellAutoRenderContainer.displayName = 'TableBuilderCellAutoRenderContainer';

export default observer(TableBuilderCellAutoRenderContainer);
