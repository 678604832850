import { ICreateNutritionHistoryDto } from '../../../../models/as-fields/plan/NutrationHistory';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TCreateNutritionHistoryReg = {} & ICreateNutritionHistoryDto;
type TCreateNutritionHistoryRes = {} & { id: string };

const createNutritionHistory: TApiRoute & {
  request: TCreateNutritionHistoryReg;
  response: TCreateNutritionHistoryRes;
} = {
  url: () => `/api/as-fields/nutrition-history`,
  method: 'POST',
  request: {} as TCreateNutritionHistoryReg,
  response: {} as TCreateNutritionHistoryRes,
  headers: {},
};

export { createNutritionHistory };
export type { TCreateNutritionHistoryReg, TCreateNutritionHistoryRes };
