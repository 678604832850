import { FC, memo, useMemo } from 'react';

import { IMessageProps } from '../../models';
import { EMessageStyleType } from '../../models/common';

import Styled from './Message.styles';

const Message: FC<IMessageProps> = ({ author, date, message, style, isAuthor }) => {
  const colorScheme = useMemo(() => (isAuthor ? EMessageStyleType.Info : style), [style, isAuthor]);

  return (
    <Styled.MessageContainer
      data-test-id="chat-comment"
      data-is-author={isAuthor}
      $colorScheme={colorScheme}
      $isRightSide={isAuthor}
    >
      <Styled.MessageInfoWrapper>
        <Styled.MessageAuthor data-test-id="chat-comment-author" $colorScheme={colorScheme}>
          {author}
        </Styled.MessageAuthor>
        <Styled.MessageDate data-test-id="chat-comment-date" $colorScheme={colorScheme}>
          {date}
        </Styled.MessageDate>
      </Styled.MessageInfoWrapper>
      <Styled.MessageContent data-test-id="chat-comment-content" $colorScheme={colorScheme}>
        {message}
      </Styled.MessageContent>
    </Styled.MessageContainer>
  );
};

Message.displayName = 'Message';

export default memo(Message);
