import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { IEmployee } from '../../../models/da-profile/users';

type TGetOrganizationUserListReq = {
  organizationID: string;
  status?: 'ACTIVE' | 'FIRED';
  role?: 'OWNER' | 'EMPLOYEE' | 'MANAGER';
  roleId?: string;
  notRoleId?: string;
  isCurrentUser?: boolean;
  isOwner?: boolean;
  isScout?: boolean;
  notCurrentUser?: boolean;
  fullName?: string;
  context?: string;
  size?: number;
  page?: number;
};

type TGetOrganizationUserListRes = {} & TResponseList<IEmployee>;

const getOrganizationUserList: TApiRoute & {
  request: TGetOrganizationUserListReq;
  response: TGetOrganizationUserListRes;
} = {
  url: () => `/api/da-profile/organizations/userList`,
  method: 'GET',
  request: {} as TGetOrganizationUserListReq,
  response: {} as TGetOrganizationUserListRes,
  headers: {},
};

export type { TGetOrganizationUserListReq, TGetOrganizationUserListRes };

export { getOrganizationUserList };
