import styled from 'styled-components';

const Content = styled.div<{ $isContentHide: boolean }>`
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: ${({ $isContentHide }) => ($isContentHide ? 'none' : 'block')};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCellComponentWithTooltip = {
  Content,
  ContentWrapper,
};

export default StyledCellComponentWithTooltip;
