import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 16px;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

const Name = styled.span`
  display: inline-block;
`;

const StyledExecutionTableSlider = { Wrapper, Title, Name };

export default StyledExecutionTableSlider;
