import L from 'leaflet';

const FIELD_TOOLTIP_ON_THE_MAP = 'field-tooltip-on-the-map';

const EXPERIMENT_STYLE: L.PathOptions = {
  fillColor: '#DF2E22',
  fillOpacity: 0.3,
  color: '#DF2E22',
};

const CONTROL_STYLE: L.PathOptions = {
  fillColor: '#0770E3',
  fillOpacity: 0.2,
  color: '#0770E3',
};

const READY_TO_EXPERIMENT: L.PathOptions = {
  color: '#FFD765',
  fillOpacity: 0,
};

const OTHER: L.PathOptions = {
  color: '#D9D7D6',
  fillOpacity: 0,
};

const useMapConstants = {
  FIELD_TOOLTIP_ON_THE_MAP,
  EXPERIMENT_STYLE,
  CONTROL_STYLE,
  READY_TO_EXPERIMENT,
  OTHER,
};

export default useMapConstants;
