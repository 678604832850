import {
  EExperimentStepEventType,
  IExperimentComment,
} from '../../../../models/as-fields/experiments/ExperimentComment';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TPostExperimentStepCommentsReq = {
  id: string;
  comment: string;
};

type TPostExperimentStepCommentsRes = IExperimentComment<EExperimentStepEventType>[];

const postExperimentStepComment: TApiRoute & {
  request: TPostExperimentStepCommentsReq;
  response: TPostExperimentStepCommentsRes;
} = {
  url: ({ id }) => `/api/as-fields/experimentSteps/${id}/comment`,
  method: 'POST',
  request: {} as TPostExperimentStepCommentsReq,
  response: {} as TPostExperimentStepCommentsRes,
  headers: {},
};

export type { TPostExperimentStepCommentsReq, TPostExperimentStepCommentsRes };

export { postExperimentStepComment };
