import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CostCellStyled = {
  Wrapper,
};

export default CostCellStyled;
