import { ICreateExperimentStepDto } from '../../../../models/as-fields/experiments';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TCreateExperimentStepReq = {} & ICreateExperimentStepDto;

type TCreateExperimentStepRes = {} & { id: string };

const createExperimentStep: TApiRoute & {
  request: TCreateExperimentStepReq;
  response: TCreateExperimentStepRes;
} = {
  url: () => `/api/as-fields/experimentSteps`,
  method: 'POST',
  request: {} as TCreateExperimentStepReq,
  response: {} as TCreateExperimentStepRes,
  headers: {},
};

export type { TCreateExperimentStepRes, TCreateExperimentStepReq };

export { createExperimentStep };
