import { makeAutoObservable } from 'mobx';

import {
  ITableBuilderCell as ICell,
  ITableBuilderColumn as IColumn,
  ITableBuilderRow as IRow,
  ITableBuilderRowsGroup as IRowsGroup,
} from '../../../models/data';
import {
  ITableBuilderPaginationConfig as IPaginationConfig,
  ITableBuilderRowConfig as IRowConfig,
} from '../../../models/configs';
import { provide } from '../../../../../utils/helpers/mobx';

interface ISetElementListOptions {
  isClearPreviousList?: boolean;
}

@provide.singleton()
class TableBuilderStore {
  /**
   * Коллекция колонок таблиц.
   */
  private _columnsByBuilderId: Map<string, Map<string, IColumn>> = new Map();

  /**
   * Коллекция группы рядов.
   */
  private _rowsGroupByBuilderId: Map<string, Map<string, IRowsGroup>> = new Map();

  /**
   * Коллекция значений рядов, т.е. тех объектов, которые используются в качестве ряда [E].
   */
  private _rowsByBuilderId: Map<string, Map<string, IRow>> = new Map();

  /**
   * Коллекция ячеек.
   */
  private _cellsByBuilderId: Map<string, Map<string, ICell>> = new Map();

  /**
   * Коллекция настроек [конфигов] для ряда.
   */
  private _rowConfigsByBuilderId: Map<string, IRowConfig> = new Map();

  /**
   * Коллекция конфигов динамической пагинации.
   */
  private _paginationConfigsByBuilderId: Map<string, IPaginationConfig> = new Map();

  /**
   * Коллекция текущих страниц.
   */
  private _currentPagesByBuilderId: Map<string, number> = new Map();

  /**
   * Коллекция всего страниц.
   */
  private _totalPagesByBuilderId: Map<string, number> = new Map();

  /**
   * Коллекция состояний загрузки элементов.
   */
  private _isFetchingElementsByBuilderId: Map<string, boolean> = new Map();

  /**
   * Флаг для отображения заглушки, когда таблица была только создана
   * и не было выполнено еще ни одного запроса за данными.
   */
  private _isShowDefaultPlug: Map<string, boolean> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  getColumnList = (builderId: string): IColumn[] => {
    const columns = this._columnsByBuilderId.get(builderId);

    if (columns) {
      return [...columns.values()];
    }

    return [];
  };

  getRowList = (
    builderId: string,
    options?: {
      byRowsGroupId: string;
    }
  ): IRow[] => {
    const rows = this._rowsByBuilderId.get(builderId);

    if (rows) {
      const rowList = [...rows.values()];

      if (options?.byRowsGroupId) {
        return rowList.filter(({ rowsGroupId }) => rowsGroupId === options.byRowsGroupId);
      }

      return rowList;
    }

    return [];
  };

  getCellList = (builderId: string): ICell[] => {
    const cells = this._cellsByBuilderId.get(builderId);

    if (cells) {
      return [...cells.values()];
    }

    return [];
  };

  getRowsGroupList = (
    builderId: string,
    options?: {
      byRootRowId?: string;
    }
  ): IRowsGroup[] => {
    const rowGroups = this._rowsGroupByBuilderId.get(builderId);

    if (!rowGroups) {
      return [];
    }

    const rowGroupList = [...rowGroups.values()];

    if (options?.byRootRowId) {
      return rowGroupList.filter(({ rootRowId }) => rootRowId === options.byRootRowId);
    }

    return rowGroupList.filter(({ rootRowId }) => !rootRowId);
  };

  getRowsGroup = (builderId: string, rowsGroupId: string): IRowsGroup => {
    return this._rowsGroupByBuilderId.get(builderId)?.get?.(rowsGroupId);
  };

  getRowConfig = (builderId: string): IRowConfig => {
    const rowConfig = this._rowConfigsByBuilderId.get(builderId);

    return rowConfig ?? {};
  };

  getPaginationConfig = (builderId: string): IPaginationConfig => {
    const paginationConfig = this._paginationConfigsByBuilderId.get(builderId);

    return paginationConfig ?? {};
  };

  getCurrentPage = (builderId: string): number => {
    const currentPage = this._currentPagesByBuilderId.get(builderId);

    return currentPage ?? 0;
  };

  getTotalPages = (builderId: string): number => {
    const totalPages = this._totalPagesByBuilderId.get(builderId);

    return totalPages ?? 0;
  };

  getIsFetchingElements = (builderId: string): boolean => {
    const isFetchingElements = this._isFetchingElementsByBuilderId.get(builderId);

    return isFetchingElements;
  };

  getIsShowDefaultPlug = (builderId: string): boolean => {
    const isShowDefaultPlug = this._isShowDefaultPlug.get(builderId);

    return isShowDefaultPlug;
  };

  setColumnList = (
    builderId: string,
    columnList: IColumn[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._columnsByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<string, IColumn>(previousEntryList);

    columnList.forEach(column => {
      newCollection.set(column.id, column);
    });

    this._columnsByBuilderId.set(builderId, newCollection);
  };

  setRowsGroupList = (
    builderId: string,
    rowsGroupList: IRowsGroup[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._rowsGroupByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<string, IRowsGroup>(previousEntryList);

    rowsGroupList.forEach(rowsGroup => {
      newCollection.set(rowsGroup.id, rowsGroup);
    });

    this._rowsGroupByBuilderId.set(builderId, newCollection);
  };

  setRowList = (builderId: string, rowList: IRow[], options?: ISetElementListOptions): void => {
    const collection = this._rowsByBuilderId.get(builderId);

    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(collection?.entries?.() || [])];

    const newCollection = new Map<string, IRow>(previousEntryList);

    rowList.forEach(row => {
      newCollection.set(row.id, row);
    });

    this._rowsByBuilderId.set(builderId, newCollection);
  };

  setCellList = (builderId: string, cellList: ICell[], options?: ISetElementListOptions): void => {
    const collection = this._cellsByBuilderId.get(builderId);

    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(collection?.entries?.() || [])];

    const newCollection = new Map<string, ICell>(previousEntryList);

    cellList.forEach(cell => {
      newCollection.set(cell.id, cell);
    });

    this._cellsByBuilderId.set(builderId, newCollection);
  };

  setPartialPaginationConfig = (
    builderId: string,
    partialConfig: Partial<IPaginationConfig>
  ): void => {
    const paginationConfig = this._paginationConfigsByBuilderId.get(builderId) ?? {};

    this._paginationConfigsByBuilderId.set(builderId, { ...paginationConfig, ...partialConfig });
  };

  setCurrentPage = (builderId: string, currentPage: number): void => {
    this._currentPagesByBuilderId.set(builderId, currentPage);
  };

  setTotalPages = (builderId: string, totalPages: number): void => {
    this._totalPagesByBuilderId.set(builderId, totalPages);
  };

  setIsFetchingElements = (builderId: string, value: boolean): void => {
    this._isFetchingElementsByBuilderId.set(builderId, value);
  };

  setIsShowDefaultPlug = (builderId: string, value: boolean): void => {
    this._isShowDefaultPlug.set(builderId, value);
  };

  setPartialRowConfig = (builderId: string, partialConfig: Partial<IRowConfig>): void => {
    const rowConfig = this._rowConfigsByBuilderId.get(builderId) ?? {};

    this._rowConfigsByBuilderId.set(builderId, { ...rowConfig, ...partialConfig });
  };

  deleteColumnList = (builderId: string): void => {
    this._columnsByBuilderId.delete(builderId);
  };

  deleteRowsGroups = (builderId: string): void => {
    this._rowsGroupByBuilderId.delete(builderId);
  };

  deleteRowsGroup = (builderId: string, rowsGroupId: string): void => {
    this._rowsGroupByBuilderId.get(builderId)?.delete(rowsGroupId);
  };

  deleteRowsGroupList = (builderId: string, rowsGroupIdList: string[]): void => {
    const rowsGroups = this._rowsGroupByBuilderId.get(builderId);

    if (!rowsGroups) {
      return;
    }

    rowsGroupIdList.forEach(id => rowsGroups.delete(id));
  };

  deleteRowList = (builderId: string): void => {
    this._rowsByBuilderId.delete(builderId);
  };

  deleteCellList = (builderId: string): void => {
    this._cellsByBuilderId.delete(builderId);
  };

  deleteRowConfig = (builderId: string): void => {
    this._rowConfigsByBuilderId.delete(builderId);
  };

  deletePaginationConfig = (builderId: string): void => {
    this._paginationConfigsByBuilderId.delete(builderId);
  };

  deleteCurrentPage = (builderId: string): void => {
    this._currentPagesByBuilderId.delete(builderId);
  };

  deleteTotalPages = (builderId: string): void => {
    this._totalPagesByBuilderId.delete(builderId);
  };

  deleteIsFetchingElements = (builderId: string): void => {
    this._isFetchingElementsByBuilderId.delete(builderId);
  };

  deleteIsShowDefaultPlug = (builderId: string): void => {
    this._isShowDefaultPlug.delete(builderId);
  };
}

export default TableBuilderStore;
