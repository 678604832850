import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';
import { IOrganization } from '../../../../../../api/models/da-profile/organizations/Organization.model';

const createOrganizationSelectOption = (organization: IOrganization): ISelectOption => {
  return { label: organization.name, value: organization.organizationId };
};

const createOrganizationSelectOptionList = (organizationList: IOrganization[]): ISelectOption[] => {
  return organizationList.map<ISelectOption>(organization =>
    createOrganizationSelectOption(organization)
  );
};

export { createOrganizationSelectOption, createOrganizationSelectOptionList };
