import { FC, memo } from 'react';

import { useDataTestId } from '../../../../../../../common/utils/hooks/locators';

import Styled from './StatusCellComponent.style';
import { TStatusCellComponentProps } from './StatusCellComponent.types';
import { getStatusCellComponentColors } from './utils/helpers';

const StatusCellComponent: FC<TStatusCellComponentProps> = ({ status }) => {
  const { bgColor, color, statusName } = getStatusCellComponentColors(status);

  const getDataTestId = useDataTestId('experiments-table-cell-with-key-status');

  return (
    <Styled.Wrapper $bgColor={bgColor} $color={color} {...getDataTestId()}>
      {statusName}
    </Styled.Wrapper>
  );
};

export default memo(StatusCellComponent);
