import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { IDictionaryEntity } from '../../../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { DictionaryService } from '../../../../../../../../../../../../common/mobx/services/da-dictionary';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { createDictionaryEntitySelectOptionList } from '../../../../../../../../../../../../common/utils/helpers/selectOptions';

@provide.transient()
class ExecutionStepDictionaryService {
  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  fetchPhenophaseBbchDictionaryOptionList = async (
    cultureId: string
  ): Promise<ISelectOption<IDictionaryEntity>[]> => {
    const { content } = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'bbch',
        dependencyName: 'culture',
        dependencyRecordId: cultureId,
        level: 3,
      },
      {
        sort: 'path,asc',
      }
    );

    const entityOptionList = createDictionaryEntitySelectOptionList(content, {
      nameAdditionalKey: 'code',
      isAddInitialModel: true,
      isNeedBrackets: true,
    });

    return entityOptionList;
  };
}

export default ExecutionStepDictionaryService;
