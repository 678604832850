import styled from 'styled-components';
import { Button } from '@farmlink/farmik-ui';

import { Colors } from '../../../styles/colors';

const DescriptionWrapper = styled.pre`
  margin: 0;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${Colors.darkGray};
  margin: 12px 0 0;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: center;
  margin: 4px 0 0;
`;

const Icon = styled.img`
  width: 64px;
  margin: 0;
  height: 64px;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  flex: 1;
  padding: 0 24px 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 20px;
`;

const PrimaryButton = styled(Button)`
  height: 40px;
  border-radius: 12px;
  min-width: 200px;
`;

const SecondaryButton = styled(Button)`
  height: 40px;
  border-radius: 12px;
  min-width: 200px;
`;

const StyledPlug = {
  Description,
  Title,
  Icon,
  Wrapper,
  ButtonWrapper,
  PrimaryButton,
  SecondaryButton,
  DescriptionWrapper,
};

export default StyledPlug;
