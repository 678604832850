import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import {
  ExperimentsFiltersConfigsService as ConfigsService,
  ExperimentsFiltersOptionsService as OptionsService,
} from '../../services';
import { TableFiltersBuilderController } from '../../../../../../../../common/features/TableFiltersBuilder/mobx/controllers';
import { IExperimentsFilters } from '../../../models';
import { EExperimentsFiltersBuilderId } from '../../../../../constants/configs';

@provide.transient()
class ExperimentsFiltersController {
  @lazyInject(ConfigsService)
  protected configsService: ConfigsService;

  @lazyInject(OptionsService)
  protected optionsService: OptionsService;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<IExperimentsFilters>;

  initiateConfig = (onActionButtonClick: () => void): void => {
    const config = this.configsService.createConfig(
      onActionButtonClick,
      this.optionsService.optionsQueryScheme
    );

    this.tableFiltersBuilderController.initiateConfig(config);
  };

  addOptionList = async (): Promise<void> => {
    const [
      seasonOptionList,
      organizationOptionList,
      typeOptionList,
      cultureOptionList,
      statusOptionList,
      regionOptionList,
      districtOptionList,
      assigneeOptionList,
    ] = await Promise.all([
      this.optionsService.createSeasonYearOptionList(),
      this.optionsService.createOrganizationOptionList(),
      this.optionsService.createTypeOptionList(),
      this.optionsService.createCultureOptionList(),
      this.optionsService.createStatusOptionList(),
      this.optionsService.createRegionOptionList(),
      this.optionsService.createDistrictOptionList(),
      this.optionsService.createAssigneeOptionList(),
    ]);

    this.tableFiltersBuilderController.addSelectOptionList(
      EExperimentsFiltersBuilderId.Experiments,
      'seasonYearIn',
      seasonOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EExperimentsFiltersBuilderId.Experiments,
      'organizationIdIn',
      organizationOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EExperimentsFiltersBuilderId.Experiments,
      'typeIn',
      typeOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EExperimentsFiltersBuilderId.Experiments,
      'cultureIdIn',
      cultureOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EExperimentsFiltersBuilderId.Experiments,
      'statusIn',
      statusOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EExperimentsFiltersBuilderId.Experiments,
      'regionIdIn',
      regionOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EExperimentsFiltersBuilderId.Experiments,
      'districtIdIn',
      districtOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EExperimentsFiltersBuilderId.Experiments,
      'assigneeIdIn',
      assigneeOptionList.optionList
    );
  };
}

export default ExperimentsFiltersController;
