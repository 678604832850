import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../hooks';
import { useStore } from '../../../../utils/helpers/mobx';
import { ComparisonTableBuilderController as Controller } from '../../mobx/controllers';
import { useComparisonTableBuilderContext as useContext } from '../../context';
import { ComparisonTableBuilderSlider as Slider } from '../../components/sliders/ComparisonTableBuilderSlider';
import { ComparisonTableBuilderSliderControls as SliderControls } from '../../components/sliders';
import { createComparisonTableBuilderRowClassName as createRowClassName } from '../../helpers';

import Styled from './ComparisonTableBuilderGrid.styles';

interface IProps {
  fixedChildren?: ReactNode;
  scrollableChildren?: ReactNode;
  isHeader?: boolean;
  rowName?: string;
}

const ComparisonTableBuilderGrid: FC<IProps> = ({
  fixedChildren,
  scrollableChildren,
  isHeader,
  rowName,
}) => {
  const controller = useStore(Controller);

  const { builderId, tableName } = useContext();

  const fixedColumnsWidth = controller.getFixedColumnWidth(builderId);
  const scrollableColumnsData = controller.getScrollableColumnsData(builderId);

  const hasScrollableChildren = scrollableChildren && scrollableColumnsData?.totalColumns;

  const getDataTestId = useDataTestId({ componentName: 'grid' });

  return (
    <Styled.Wrapper {...getDataTestId()} className={createRowClassName(tableName, rowName)}>
      {fixedChildren && fixedColumnsWidth ? (
        <Styled.FixedWrapper
          $columnsWidth={fixedColumnsWidth}
          $hasFullContainerWidth={!hasScrollableChildren}
          {...getDataTestId('fixed-wrapper')}
        >
          {fixedChildren}
        </Styled.FixedWrapper>
      ) : null}

      {hasScrollableChildren ? (
        <>
          {isHeader ? (
            <SliderControls>
              <Slider isHeader={isHeader}>{scrollableChildren}</Slider>
            </SliderControls>
          ) : (
            <Slider>{scrollableChildren}</Slider>
          )}
        </>
      ) : null}
    </Styled.Wrapper>
  );
};

ComparisonTableBuilderGrid.displayName = 'ComparisonTableBuilderGrid';

export default observer(ComparisonTableBuilderGrid);
