import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IExperiment } from '../../../models/as-fields/experiments';

export type TUpdateExperimentNotesReq = {
  planDeviation: string;
  technologicalDeviation: string;
  specialNotes: string;
  experimentId: string;
};

export type TUpdateExperimentNotesRes = IExperiment;

const updateExperimentNotes: TApiRoute & {
  request: TUpdateExperimentNotesReq;
  response: TUpdateExperimentNotesRes;
} = {
  url: ({ experimentId }) => `/api/as-fields/experiment/${experimentId}/notes`,
  method: 'PUT',
  request: {} as TUpdateExperimentNotesReq,
  response: {} as TUpdateExperimentNotesRes,
  headers: {},
};

export { updateExperimentNotes };
