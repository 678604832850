import styled from 'styled-components';

const SidebarWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`;

const StyledProgressBarItem = { SidebarWrapper };

export default StyledProgressBarItem;
