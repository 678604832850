import { AxiosError } from 'axios';

import { TGetCultureZoneExperimentListReq } from '../../../../../../../../../api';
import { IExperiment } from '../../../../../../../../../api/models/as-fields/experiments';
import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { ExperimentService } from '../../../../../../../../common/mobx/services/as-fields';
import { AccessController } from '../../../../../../../../common/mobx/controllers/AccessController';
import { CreateExperimentStore } from '../../stores';
import { CreateExperimentService } from '../../services';

@provide.singleton()
class CreateExperimentController {
  @lazyInject(CreateExperimentStore)
  protected createExperimentStore: CreateExperimentStore;

  @lazyInject(CreateExperimentService)
  protected createExperimentService: CreateExperimentService;

  @lazyInject(ExperimentService)
  protected experimentService: ExperimentService;

  @lazyInject(AccessController)
  protected accessController: AccessController;

  fetchExp = async (id: string): Promise<IExperiment> => {
    const { setSelectedExp, setIsLoadingExperimentInfo } = this.createExperimentStore;
    const { getExperiment } = this.experimentService;
    const { checkUserResponseError } = this.accessController;

    setIsLoadingExperimentInfo(true);

    const fetchedExp = await getExperiment({ id });

    if (fetchedExp instanceof AxiosError) {
      checkUserResponseError(fetchedExp);

      setIsLoadingExperimentInfo(false);

      return;
    }

    if (fetchedExp) {
      setSelectedExp(fetchedExp);

      setIsLoadingExperimentInfo(false);

      return fetchedExp;
    }

    setIsLoadingExperimentInfo(false);
  };

  fetchAllCultureZoneList = async (expPayload: TGetCultureZoneExperimentListReq) => {
    const { selectedExp } = this.createExperimentStore;

    if (selectedExp) {
      const cultureZoneListPayload = {
        organizationId: selectedExp?.organization?.id,
        seasonYear: selectedExp?.seasonYear,
        size: 2000,
        cultureId: selectedExp?.culture.id,
        onlyWithoutExperiment: true,
      };

      const [experimentCultureZones, cultureZones] = await Promise.all([
        this.createExperimentService.getCultureZoneExperimentList(expPayload),
        this.createExperimentService.getCultureZoneList(cultureZoneListPayload),
      ]);

      this.createExperimentStore.setCultureZones(cultureZones);
      this.createExperimentStore.setExperimentCultureZones(experimentCultureZones);

      const hasZonesToWork = cultureZones.some(zone => zone?.experimentReady);
      const hasExperimentsZonesToWork = experimentCultureZones.some(zone => zone?.type);

      if (hasZonesToWork || hasExperimentsZonesToWork) {
        this.createExperimentStore.setIsAllowMapAccess(true);
      }
    }
  };
}

export default CreateExperimentController;
