import { observer } from 'mobx-react';
import { FC, memo } from 'react';

import { ITableBuilderColumn as IColumn } from '../../../models/data';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { TableBuilderColumn as Column } from '../../../components/header';

interface IProps {
  column: IColumn;
}

const TableBuilderColumn: FC<IProps> = ({ column }) => {
  const getDataTestId = useDataTestId();

  const dataTestId = getDataTestId(`cell-with-key-${column.id}`)['data-test-id'];

  switch (column.renderType) {
    case 'custom-render': {
      return column.customRenderConfig.renderColumn(column);
    }

    default:
      return (
        <Column
          key={column.id}
          name={column.name}
          padding={column.padding}
          dataTestId={dataTestId}
        />
      );
  }
};

TableBuilderColumn.displayName = 'TableBuilderColumn';

export default memo(observer(TableBuilderColumn));
