import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { TableBuilder } from '../../../../../../../../../common/features/TableBuilder';
import { TableBuilderController } from '../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { EContractsTableBuilderId } from '../../../../../../constants/configs';
import { ContractSidePanelStore } from '../../../../mobx/stores';

import { DocumentsTableController } from './mobx/controllers';
import Styled from './DocumentsTable.styles';
import { DocumentsTableStore } from './mobx/stores';

export const DocumentsTable: FC = observer(() => {
  const tableBuilderController = useStore(TableBuilderController);

  const { initiateTable } = useStore(DocumentsTableController);

  const { fetchAttachedFiles } = useStore(DocumentsTableController);

  const { attachedFiles } = useStore(DocumentsTableStore);

  const { selectedContract } = useStore(ContractSidePanelStore);

  useEffect(() => {
    fetchAttachedFiles(selectedContract?.id);
  }, [selectedContract?.id]);

  useEffect(() => {
    tableBuilderController.addRowsToRowsGroupListById(
      EContractsTableBuilderId.Documents,
      EContractsTableBuilderId.Documents,
      attachedFiles || [],
      {
        isClearPreviousList: true,
      }
    );
  }, [attachedFiles]);

  useEffect(() => {
    initiateTable();
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <TableBuilder
          builderId={EContractsTableBuilderId.Documents}
          stylePreset="cleared"
          scrollPadding={'0px'}
        />
      </Styled.Content>
    </Styled.Wrapper>
  );
});
