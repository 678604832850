import { EObservationStatus } from '../../../../../../../../../../../../common/utils/constants/observation';
import { ETaskStatus } from '../../../../../../../../../../../../common/utils/constants/task';

export const getAuditStatusColors = (status: EObservationStatus): any => {
  let bgColor = '#FAF9FD';
  let color = '#151F32';
  let statusName = '';

  switch (status) {
    case EObservationStatus.Draft:
      bgColor = '#FAF9FD';
      color = '#7E8B9D';
      statusName = 'Черновик';
      break;
    case EObservationStatus.InApproval:
      bgColor = '#FFEDB8';
      color = '#FF7527';
      statusName = 'На согласовании';
      break;
    case EObservationStatus.Approved:
      bgColor = '#E7F1FD';
      color = '#0770E3';
      statusName = 'Согласован';
      break;
    default:
      break;
  }

  return { bgColor, color, statusName };
};

export const getTaskStatusColors = (status: ETaskStatus) => {
  let bgColor = '#FAF9FD';
  let color = '#151F32';
  let statusName = '';

  switch (status) {
    case ETaskStatus.New:
      bgColor = '#E7F1FD';
      color = '#0770E3';
      statusName = 'Новая';
      break;
    case ETaskStatus.InWork:
      bgColor = '#ECF8EF';
      color = '#3FB65F';
      statusName = 'В работе';
      break;
    case ETaskStatus.Canceled:
      bgColor = '#FEF5F5';
      color = '#DF2E22';
      statusName = 'Отменена';
      break;
    case ETaskStatus.Completed:
      statusName = 'Завершена';
      break;
    default:
      break;
  }

  return { bgColor, color, statusName };
};
