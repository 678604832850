import styled, { css } from 'styled-components';

type TStyledInputFieldErrorWrapperProps = {
  $maxWidth?: string;
};

const addMaxWidthToWrapper = $maxWidth => {
  return (
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `
  );
};

const Wrapper = styled.div<TStyledInputFieldErrorWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${({ $maxWidth }) => addMaxWidthToWrapper($maxWidth)}
`;

const StyledInputFieldErrorList = {
  Wrapper,
};

export default StyledInputFieldErrorList;
