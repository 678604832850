import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { IExperimentTotalComparisonHeader as IHeader } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentTotalComparisonTable/ExperimentTotalComparisonTable';

@provide.singleton()
class TotalComparisonStore {
  private _isPageLoading = true;

  private _headersByPosition: Map<number, IHeader> = new Map();

  private _noData = false;

  private _noTechOperations = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isPageLoading() {
    return this._isPageLoading;
  }

  get noData() {
    return this._noData;
  }

  get noTechOperations() {
    return this._noTechOperations;
  }

  getHeaderByPosition = (position: number): IHeader => {
    return this._headersByPosition.get(position);
  };

  setIsPageLoading = (value: boolean): void => {
    this._isPageLoading = value;
  };

  setHeaderList = (headerList: IHeader[]): void => {
    headerList.forEach((header, i) => {
      this._headersByPosition.set(i, header);
    });
  };

  setNoData = (hasData: boolean) => {
    this._noData = hasData;
  };

  setNoTechoperations = (hasTechoperations: boolean) => {
    this._noTechOperations = hasTechoperations;
  };

  clearIsPageLoading = (): void => {
    this._isPageLoading = true;
  };

  clearHeadersByPosition = (): void => {
    this._headersByPosition.clear();
  };

  clearNoData = () => {
    this._noData = false;
  };

  clearNoTechoperations = () => {
    this._noTechOperations = false;
  };
}

export default TotalComparisonStore;
