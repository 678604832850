import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../assets/styles/colors';

// @ts-ignore
import arrowRight from './assets/images/arrow-black-right-14x8.svg';
// @ts-ignore
import arrowLeft from './assets/images/arrow-black-left-14x8.svg';

const { SecondaryGray } = ECommonColor;

const disableArrow = (isDisabled: boolean) => {
  if (isDisabled) {
    return css`
      opacity: 0.5;
      pointer-events: none;
      cursor: auto;
    `;
  }
};

const ArrowRight = styled.div<{ $isRightArrowDisabled: boolean }>`
  width: 7.64px;
  height: 13.06px;

  background: url(${arrowRight});
`;

const ArrowLeft = styled.div<{ $isLeftArrowDisabled: boolean }>`
  width: 7.64px;
  height: 13.06px;

  background: url(${arrowLeft});
`;

const ArrowWrapper = styled.div<{ $isDisabled: boolean }>`
  width: 40px;
  height: 40px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  border-radius: 12px;

  cursor: pointer;

  background-color: ${SecondaryGray};

  ${({ $isDisabled }) => disableArrow($isDisabled)}
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  position: absolute;
  top: 0;
  right: 0;

  & > :not(:last-child) {
    margin-right: 16px;
  }

  background-color: white;
`;

const StyledSliderControls = {
  ArrowRight,
  ArrowLeft,
  ArrowWrapper,
  Wrapper,
};

export default StyledSliderControls;
