import styled from 'styled-components';
import { NewScrollbar } from '@farmlink/farmik-ui';

const ScrollWrapper = styled(NewScrollbar)<{ $borderType: string; $scrollPadding: string }>`
  width: 100%;
  height: 100%;
  padding: ${({ $scrollPadding }) => ($scrollPadding ? $scrollPadding : '0 24px 20px 24px')};

  & > :not(:last-child) {
    border-bottom: ${({ $borderType }) => `1px ${$borderType ?? 'solid'} #e9e6f0`};
  }
`;

const Wrapper = styled.div<{ $borderType: string }>`
  width: 100%;

  & > :not(:last-child) {
    border-bottom: ${({ $borderType }) => `1px ${$borderType ?? 'solid'} #e9e6f0`};
  }
`;

const StyledComparisonTableBuilderRowsContainer = {
  ScrollWrapper,
  Wrapper,
};

export default StyledComparisonTableBuilderRowsContainer;
