import { FC, memo, useEffect, useRef, useState } from 'react';

import { SliderContainer, SliderControlsContainer } from '../../containers';
import { _Instance } from '../../models';

import Styled from './DynamicTableHead.styles';
import { HeadTitle } from './components';

interface IDynamicTableHeadProps {
  headColumnWidth: string;
  headColumnTitle: string;
  headColumnPadding: string;
  headColumnMargin: string;
  instanceList: _Instance[];
  width: number;
  stickyTopIndent?: number;
  parentScrollTop?: number;
}

const DynamicTableHead: FC<IDynamicTableHeadProps> = ({
  headColumnWidth,
  headColumnTitle,
  headColumnPadding,
  headColumnMargin,
  instanceList,
  width,
  stickyTopIndent,
  parentScrollTop,
}) => {
  const wrapRef = useRef<HTMLDivElement | null>(null);

  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [headerTopPos, setHeaderTopPos] = useState<number>(0);

  useEffect(() => {
    if (headerTopPos || !wrapRef.current) {
      return;
    }

    const topPos = wrapRef.current.getBoundingClientRect().top;
    setHeaderTopPos(topPos);
  }, [wrapRef.current]);

  useEffect(() => {
    if (!wrapRef.current) {
      return;
    }

    setIsFixed(parentScrollTop > headerTopPos);
  }, [parentScrollTop]);

  return (
    <Styled.Wrapper
      ref={wrapRef}
      $width={width}
      $isFixed={isFixed}
      $stickyTopIndent={stickyTopIndent}
      $headColumnWidth={headColumnWidth}
    >
      <HeadTitle headColumnPadding={headColumnPadding} headColumnMargin={headColumnMargin}>
        {headColumnTitle}
      </HeadTitle>

      <Styled.InstancesNameWrapper>
        {instanceList.length ? (
          <SliderContainer isControl>
            {instanceList.map(inst => (
              <HeadTitle key={inst.id} headColumnPadding={headColumnPadding}>
                {inst.Component ? <inst.Component instance={inst} /> : inst.name ?? ''}
              </HeadTitle>
            ))}
          </SliderContainer>
        ) : null}

        <SliderControlsContainer />
      </Styled.InstancesNameWrapper>
    </Styled.Wrapper>
  );
};

DynamicTableHead.displayName = 'DynamicTableHead';

export default memo(DynamicTableHead);
