import { IFormConfig } from '../../../../../../../../../../../common/features/form/models';

const TECH_OPERATION_FORM_KEY = 'addTechOperation';

const REQUIRED_TEXT = 'Это поле не может быть пустым';

interface ITechOperationForm {
  name: string;
  typeOperation: string;
  startDate: Date | null;
  endDate: Date | null;
  price: string;
  usageMethodType: string;
  bbshFrom: string;
  bbshTo: string;
  phenophaseFrom: string;
  phenophaseTo: string;
}

const techOperationFormConfig: IFormConfig<ITechOperationForm> = {
  formKey: TECH_OPERATION_FORM_KEY,
  form: {
    name: '',
    typeOperation: '',
    price: '',
    usageMethodType: '',
    startDate: null,
    endDate: null,
    phenophaseFrom: '',
    bbshFrom: '',
    phenophaseTo: '',
    bbshTo: '',
  },
  elements: {
    name: {
      type: 'input',
      order: 1,
      name: 'name',
      label: 'Название операции',
      isRequired: true,
      placeholder: 'Введите название',
      inputOptions: {
        maxLength: 50,
      },
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
    },
    typeOperation: {
      type: 'select',
      name: 'typeOperation',
      order: 2,
      label: 'Тип операции',
      isRequired: true,
      isLoading: true,
      placeholder: 'Выберите операцию',
      selectOptions: {
        optionList: [],
        search: {},
      },
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
    },
    usageMethodType: {
      type: 'select',
      name: 'usageMethodType',
      order: 3,
      label: 'Способ внесения ТМЦ',
      isRequired: false,
      isLoading: true,
      placeholder: 'Выберите способ внесения',
      selectOptions: {
        optionList: [],
        search: {},
      },
    },
    price: {
      type: 'input',
      name: 'price',
      placeholder: 'Укажите значение',
      order: 4,
      label: 'Стоимость работ, руб/га',
      isRequired: true,
      nativeType: 'number',
      validation: {
        onChange: {
          regExp: {
            expression: new RegExp(/^\d+(,|\.)?(\d{1,2})?$/),
            errorTitle: 'Ошибка валидации',
          },
        },
      },
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
    },
    startDate: {
      type: 'date',
      name: 'startDate',
      order: 5,
      label: 'Дата начала',
      isRequired: true,
      placeholder: 'Выберите дату',
      datePickerOptions: {
        placement: 'bottom-start',
      },
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
    },
    endDate: {
      type: 'date',
      name: 'endDate',
      order: 6,
      label: 'Дата окончания',
      isRequired: true,
      placeholder: 'Выберите дату',
      datePickerOptions: {
        placement: 'bottom-start',
      },
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
    },
    phenophaseFrom: {
      type: 'select',
      name: 'phenophaseFrom',
      order: 7,
      label: 'Фенофаза от',
      isRequired: false,
      isLoading: true,
      placeholder: 'Выберите значение',
      selectOptions: {
        optionList: [],
        search: {},
      },
    },
    bbshFrom: {
      type: 'select',
      name: 'bbshFrom',
      order: 8,
      label: 'BBCH от',
      isRequired: false,
      dependencyName: 'phenophaseFrom',
      isLoading: true,
      placeholder: 'Выберите значение',
      selectOptions: {
        optionList: [],
        visual: {
          bodyPlacement: 'top',
        },
        search: {},
      },
    },
    phenophaseTo: {
      type: 'select',
      name: 'phenophaseTo',
      order: 9,
      label: 'Фенофаза до',
      isRequired: false,
      isLoading: true,
      placeholder: 'Выберите значение',
      selectOptions: {
        optionList: [],
        search: {},
      },
    },
    bbshTo: {
      type: 'select',
      name: 'bbshTo',
      order: 10,
      label: 'BBCH до',
      isRequired: false,
      dependencyName: 'phenophaseTo',
      isLoading: true,
      placeholder: 'Выберите значение',
      selectOptions: {
        optionList: [],
        visual: {
          bodyPlacement: 'top',
        },
        search: {},
      },
    },
  },
};
export type { ITechOperationForm };

export { techOperationFormConfig };
