import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  gap: 8px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 4px;
`;

const Icon = styled.div<{ $url?: string }>`
  width: 16px;
  height: 16px;

  flex: 0 0 16px;

  background: url(${({ $url }) => $url});
`;

export default {
  Wrapper,
  IconWrapper,
  Icon,
};
