import styled from 'styled-components';

import { ECommonColor } from '../../../../../common/assets/styles/colors';

import { ReactComponent as CloseIconSvg } from './assets/icons/close-icon.svg';
import { ReactComponent as OptionsIconSvg } from './assets/icons/options-icon.svg';

const { White, AccentGreen, LightGreen, GeneralDark, SecondaryDarkGray } = ECommonColor;

const headerHeight = '155px';
const buttonHeight = '20px';
const wrapperPaddBottom = '24px';
const tableWrapperMargin = '24px';

const Wrapper = styled.div`
  padding: 16px 24px ${wrapperPaddBottom};
  height: 100%;
  background-color: ${White};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
`;

const CloseIcon = styled(CloseIconSvg)`
  cursor: pointer;
`;

const OptionsIcon = styled(OptionsIconSvg)`
  cursor: pointer;
`;

const ContractNumber = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: ${GeneralDark};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

const SubHeaderWrapper = styled.div`
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.1px;
  color: ${SecondaryDarkGray};
  margin-bottom: 20px;
  position: relative;
  display: grid;
  grid-template-columns: fit-content(450px) max-content max-content;
  column-gap: 15px;
`;

const Delimiter = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${SecondaryDarkGray};
`

const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  letter-spacing: -0.15px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${AccentGreen};
  height: ${buttonHeight};
`;

const TableWrapper = styled.div`
  max-height: calc(
    100vh - ${headerHeight} - ${buttonHeight} - ${wrapperPaddBottom} - ${tableWrapperMargin}
  );
  overflow: auto;
  margin: ${tableWrapperMargin} 0;
`;

const FileInput = styled.input`
  display: none;
`;

const StyledContractSidePanel = {
  Wrapper,
  Header,
  ActionsWrapper,
  CloseIcon,
  OptionsIcon,
  ContractNumber,
  SubHeaderWrapper,
  Button,
  TableWrapper,
  FileInput,
  Delimiter,
};

export default StyledContractSidePanel;
