import { FC } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './TotalComparisonComparableCell.styles';

interface IProps {
  factValue: string;
  planValue: string;
  dataTestId: string;
  factTooltipValue?: number | string;
  planTooltipValue?: number | string;
  isForceShowTooltip?: boolean;
  isBold?: boolean;
}

const TotalComparisonComparableCell: FC<IProps> = ({
  factValue,
  factTooltipValue,
  planValue,
  planTooltipValue,
  dataTestId,
  isBold,
  isForceShowTooltip,
}) => {
  const { ref: factValueRef, isShowTooltip: isShowFactValueTooltip } =
    useShowTooltip<HTMLDivElement>();

  const { ref: planValueRef, isShowTooltip: isShowPlanValueTooltip } =
    useShowTooltip<HTMLDivElement>();

  const getDataTestId = useDataTestIdV2(dataTestId);

  const isForceShowDisabledForFactValue =
    typeof isForceShowTooltip === 'undefined' ? !isShowFactValueTooltip : !isForceShowTooltip;

  const isForceShowDisabledForPlanValue =
    typeof isForceShowTooltip === 'undefined' ? !isShowPlanValueTooltip : !isForceShowTooltip;

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <AutoTooltip
        content={factTooltipValue ?? factValue}
        disabled={isForceShowDisabledForFactValue}
      >
        <Styled.FactLabel ref={factValueRef} $isBold={isBold} {...getDataTestId('fact-value')}>
          {factValue}
        </Styled.FactLabel>
      </AutoTooltip>

      <AutoTooltip
        content={planTooltipValue ?? planValue}
        disabled={isForceShowDisabledForPlanValue}
      >
        <Styled.PlanLabel ref={planValueRef} $isBold={isBold} {...getDataTestId('plan-value')}>
          ({planValue})
        </Styled.PlanLabel>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

TotalComparisonComparableCell.displayName = 'TotalComparisonComparableCell';

export default TotalComparisonComparableCell;
