import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { StepFooter } from '../../../../../../../common/components/ui/StepFooter';
import { IStepFooterProps } from '../../../../../../../common/components/ui/StepFooter/StepFooter.types';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { NavigationTabsController } from '../../../../../../../common/features/NavigationTabs/mobx';

type Props = Omit<IStepFooterProps, 'onReturnToPreviousStepClick'>;

const ExperimentStepFooterContainer: FC<Props> = props => {
  const { goToPrevTab } = useStore(NavigationTabsController);

  const onReturnToPreviousStepClick = useCallback(() => {
    goToPrevTab();
  }, [goToPrevTab]);

  return <StepFooter onReturnToPreviousStepClick={onReturnToPreviousStepClick} {...props} />;
};

ExperimentStepFooterContainer.displayName = 'ExperimentStepFooterContainer';

export default observer(ExperimentStepFooterContainer);
