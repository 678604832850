import styled, { css } from 'styled-components';

// @ts-ignore
import topArrow from './assets/images/top-arrow-black-14x8.svg';
// @ts-ignore
import bottomArrow from './assets/images/bottom-arrow-black-14x8.svg';

const Wrapper = styled.div<{ $isDropped: boolean }>`
  width: 13.06px;
  height: 7.64px;

  cursor: pointer;

  ${({ $isDropped }) => css`
    background: url(${$isDropped ? topArrow : bottomArrow});
  `};
`;

const StyledDropArrow = {
  Wrapper,
};

export default StyledDropArrow;
