import { FC, memo, useMemo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { useDataTestId } from '../../../../../../../common/utils/hooks/locators';

import { TTypeCellComponentProps } from './TypeCellComponent.type';
import getTypeCellComponentName from './utils/helpers/getTypeCellComponentName';
import Styled from './TypeCellComponent.styles';

const TypeCellComponent: FC<TTypeCellComponentProps> = ({ rowData }) => {
  const type = useMemo(() => rowData.type, [rowData.type]);

  const { typeName, fullTypeName } = getTypeCellComponentName(type);

  const getDataTestId = useDataTestId('experiments-table-cell-with-key-type');

  return (
    <AutoTooltip position="top" content={fullTypeName}>
      <Styled.Wrapper {...getDataTestId()}>{typeName}</Styled.Wrapper>
    </AutoTooltip>
  );
};

export default memo(TypeCellComponent);
