import styled, { css } from 'styled-components';

import { IExecutionDeviationsCellFormattedValue as IDeviationsFormattedValue } from './ExecutionDeviationsCell.types';

const getColor = (color: IDeviationsFormattedValue['color']) => {
  switch (color) {
    case 'default':
      return css`
        color: #151f32;
      `;

    case 'red':
      return css`
        color: #df2e22;
      `;

    default:
  }
};

const Wrapper = styled.div<{ $color: IDeviationsFormattedValue['color'] }>`
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  padding: 12px 0;

  ${({ $color }) => getColor($color)}
`;

const StyledDeviationsCell = {
  Wrapper,
};

export default StyledDeviationsCell;
