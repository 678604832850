import { FC, memo } from 'react';

import { Plug } from '../../../../../../../../../../../common/components/ui';
import applicationTimeIcon from '../../../../../../assets/icons/application_time.svg';

export interface ITablePlugProps {
  onClickAdd(): void;
}

const TablePlug: FC<ITablePlugProps> = ({ onClickAdd }) => {
  return (
    <Plug
      icon={applicationTimeIcon}
      title="Внесите для каждого участка данные по запланированным техоперациям, и в этом блоке появится таблица с разбивкой информации на вкладки «Техоперации», «Действующие вещества» и «Экономика»"
      description="Данные можно внести вручную или воспользоваться функцией «скопировать с другого участка»"
      primaryButtonTitle="Добавить техоперацию"
      onPrimaryButtonClick={onClickAdd}
      buttonExist
    />
  );
};

export default memo(TablePlug);
