import { makeAutoObservable } from 'mobx';

import { IExperimentStep } from '../../../../../../../../../../../api/models/as-fields/experiments';
import { provide } from '../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
class RemarksStore {
  private _mapIdToExperimentStep: Map<string, IExperimentStep> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  setExperimentSteps = (experimentSteps: IExperimentStep[]) => {
    const map = new Map<string, IExperimentStep>();

    for (const experimentStep of experimentSteps) {
      map.set(experimentStep.id, experimentStep);
    }
    this._mapIdToExperimentStep = map;
  };

  get experimentStepList() {
    return [...this._mapIdToExperimentStep.values()];
  }

  clearExperimentSteps = () => {
    this._mapIdToExperimentStep.clear();
  };

  clearRemarksStore = () => {
    this.clearExperimentSteps();
  };
}

export default RemarksStore;
