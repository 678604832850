import { FC, memo, useCallback } from 'react';

import { _ICollapsingStage } from '../../models';
import { DropArrow } from '../../../UI';
import { StageContainer } from '../../containers';
import { DynamicTableController } from '../../mobx/controllers';

import Styled from './CollapsingStage.styles';

interface ICollapsingStageProps {
  collapsingStage: _ICollapsingStage;
  onToggleCollapsingStage: DynamicTableController['onToggleCollapsingStage'];
  cellPadding: string;
}

const CollapsingStage: FC<ICollapsingStageProps> = ({
  collapsingStage: { tableId, name, stageIds, id, isCollapsed },
  onToggleCollapsingStage,
  cellPadding,
}) => {
  const handleChange = useCallback((value: boolean) => {
    onToggleCollapsingStage(tableId, id, !value);
  }, []);

  return (
    <Styled.Wrapper $isCollapsed={isCollapsed}>
      <Styled.Header>
        <Styled.Title>{name}</Styled.Title>
        <Styled.DropIcon>
          <DropArrow defaultValue={!isCollapsed} onChange={handleChange} />
        </Styled.DropIcon>
      </Styled.Header>
      <Styled.Body>
        {stageIds.map(stageId => (
          <StageContainer key={stageId} tableId={tableId} id={stageId} cellPadding={cellPadding} />
        ))}
      </Styled.Body>
    </Styled.Wrapper>
  );
};

CollapsingStage.displayName = 'CollapsingStage';

export default memo(CollapsingStage);
