import { FC, memo, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Slider from '../../components/Slider/Slider';
import { SliderContext } from '../../context';

interface ISliderContainerProps {
  isNeedToUpdate?: boolean;
  isControl?: boolean;
}

const SliderContainer: FC<PropsWithChildren<ISliderContainerProps>> = ({
  children,
  isControl,
  isNeedToUpdate,
}) => {
  const {
    clickedArrow,
    scrollValue,
    onClearClickedArrow,
    onDisableArrow,
    onToggleControlsVisibility,
  } = useContext(SliderContext);

  const [isNeedToUpdateScroll, setIsNeedToUpdateScroll] = useState<boolean>(false);

  useEffect(() => {
    setIsNeedToUpdateScroll(true);
  }, [isNeedToUpdate]);

  const handleClearIsNeedToUpdateScroll = useCallback(() => {
    setIsNeedToUpdateScroll(false);
  }, []);

  return (
    <Slider
      clickedArrow={clickedArrow}
      scrollValue={scrollValue}
      scrollValueToChange={48}
      isNeedToUpdateScroll={isNeedToUpdateScroll}
      onClearClickedArrow={onClearClickedArrow}
      onClearIsNeedToUpdateScroll={handleClearIsNeedToUpdateScroll}
      onDisableArrow={onDisableArrow}
      onToggleControlsVisibility={onToggleControlsVisibility}
      isControl={isControl}
    >
      {children}
    </Slider>
  );
};

SliderContainer.displayName = 'SliderContainer';

export default memo(observer(SliderContainer));
