import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../../../common/assets/styles/colors';

const { BorderGray } = ECommonColor;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const Header = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${BorderGray};
`;

const Wrapper = styled.div``;

const TableWrapper = styled.div`
  max-height: 250px;
  overflow-y: auto;
`;

const StyledZonesList = {
  Title,
  Header,
  Wrapper,
  TableWrapper,
};

export default StyledZonesList;
