import { FC } from 'react';
import { observer } from 'mobx-react';

import { IComparisonTableBuilderCell as ICell } from '../../../models/data';
import { useComparisonTableBuilderDataTestId } from '../../../hooks';

interface IProps {
  cell: ICell;
}

const ComparisonTableBuilderCellCustomRenderContainer: FC<IProps> = ({ cell }) => {
  const getDataTestId = useComparisonTableBuilderDataTestId({ componentName: 'custom-cell' });

  return <>{cell.customRenderConfig.render(cell, getDataTestId()['data-test-id'])}</>;
};

ComparisonTableBuilderCellCustomRenderContainer.displayName =
  'ComparisonTableBuilderCellCustomRenderContainer';

export default observer(ComparisonTableBuilderCellCustomRenderContainer);
