import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import {
  TGetExperimentOrganizationListReq,
  TGetExperimentOrganizationListRes,
  TGetOrganizationReq,
  TGetOrganizationUserReq,
} from '../../../../../../api';
import { IOrganization } from '../../../../../../api/models/da-profile/organizations/Organization.model';
import { IServiceOptions, IServiceReturnData } from '../../../../models/service';
import {
  createEmployeeSelectOption,
  createOrganizationSelectOption,
} from '../../../../utils/helpers/selectOptions';
import { IEmployee } from '../../../../../../api/models/da-profile/users';

@provide.singleton()
class OrganizationsService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getExperimentOrganizationList = async (
    payload: TGetExperimentOrganizationListReq
  ): Promise<TGetExperimentOrganizationListRes> => {
    try {
      const response = await this.axiosService.api.getExperimentOrganizationList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getOrganization = async (
    payload: TGetOrganizationReq,
    options?: IServiceOptions
  ): Promise<IServiceReturnData<IOrganization>> => {
    const { getOrganization } = this.axiosService.api;

    try {
      const fetchedOrganization = await getOrganization(payload, { omit: ['organizationId'] });

      const data: IServiceReturnData<IOrganization> = {
        data: fetchedOrganization,
      };

      if (options?.isCreateSelectOptions) {
        data.selectOptionList = [createOrganizationSelectOption(fetchedOrganization)];
      }

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getEmployee = async (
    payload: TGetOrganizationUserReq,
    options?: IServiceOptions
  ): Promise<IServiceReturnData<IEmployee>> => {
    const { getOrganizationUser } = this.axiosService.api;

    try {
      const fetchedEmployee = await getOrganizationUser(payload, {
        omit: ['organizationId', 'userId'],
      });

      const data: IServiceReturnData<IEmployee> = {
        data: fetchedEmployee,
      };

      if (options?.isCreateSelectOptions) {
        data.selectOptionList = [createEmployeeSelectOption(fetchedEmployee)];
      }

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default OrganizationsService;
