import { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../common/utils/helpers/mobx';
import { TableBuilder } from '../../../../../common/features/TableBuilder';
import { TableBuilderController } from '../../../../../common/features/TableBuilder/mobx/controllers';
import { IExperimentContract } from '../../../../../../api/models/as-fields/experiments';
import { ITableBuilderRowConfig } from '../../../../../common/features/TableBuilder/models/configs';
import { EContractsTableBuilderId } from '../../constants/configs';
import { ContractSidePanelStore } from '../ContractSidePanel/mobx/stores';
import { ContractSidePanel } from '../ContractSidePanel';

import {
  ContractsListDefaultPlug as DefaultPlug,
  ContractsListNoDataPlug as NoDataPlug,
} from './containers/plugs';
import { ContractsListController } from './mobx/controllers';

type TOnRowClick = ITableBuilderRowConfig<IExperimentContract>['onRowClick'];

const ContractsList: FC = () => {
  const contractsListController = useStore(ContractsListController);
  const tableBuilderController = useStore(TableBuilderController);
  const contractSidePanelStore = useStore(ContractSidePanelStore);

  const handleCloseSidePanel = useCallback(() => {
    contractSidePanelStore.setSelectedContract(null);
  }, []);

  const handleRowClick = useCallback<TOnRowClick>(row => {
    contractSidePanelStore.setSelectedContract(row);
  }, []);

  useEffect(() => {
    contractsListController.initiateTable();

    tableBuilderController.addRowClickEvent(EContractsTableBuilderId.Contracts, handleRowClick);
    tableBuilderController.showDefaultPlug(EContractsTableBuilderId.Contracts);
    tableBuilderController.showLoader(EContractsTableBuilderId.Contracts);
  }, []);

  return (
    <>
      <TableBuilder
        builderId={EContractsTableBuilderId.Contracts}
        renderDefaultPlug={() => <DefaultPlug />}
        renderNoDataPlug={() => <NoDataPlug />}
      />
      {contractSidePanelStore.selectedContract ? (
        <ContractSidePanel onClose={handleCloseSidePanel} />
      ) : null}
    </>
  );
};

ContractsList.displayName = 'ContractsList';

export default observer(ContractsList);
