import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { ComparisonTableBuilderStore as Store } from '../../stores';
import { IComparisonTableBuilderCellConfig as ICellConfig } from '../../../models/configs';
import { IComparisonTableBuilderCell as ICell } from '../../../models/data';

@provide.transient()
class ComparisonTableBuilderCellsService {
  @lazyInject(Store)
  protected store: Store;

  addCellList = (builderId: string, rowId: string, configList: ICellConfig[]): void => {
    const cellList = this.createCellList(builderId, configList);

    this.store.setCellList(builderId, rowId, cellList);
  };

  protected createCellList = (builderId: string, configList: ICellConfig[]): ICell[] => {
    const cellList = configList.map(config => this.createCell(builderId, config));

    return cellList;
  };

  protected createCell = (builderId: string, config: ICellConfig): ICell => {
    const cell: ICell = {
      ...config,
      builderId,
      renderType: null,
    };

    if (config?.autoRenderConfig) {
      cell.renderType = 'auto';
    }

    if (config?.customRenderConfig) {
      cell.renderType = 'custom';
    }

    return cell;
  };
}

export default ComparisonTableBuilderCellsService;
