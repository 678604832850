import { IUpdateNutritionHistoryItemDto } from '../../../../models/as-fields/plan/NutrationHistory';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TUpdateNutritionHistoryProtectionItemReq = IUpdateNutritionHistoryItemDto & {
  nutritionHistoryProtectionItemId: string;
};
type TUpdateNutritionHistoryProtectionItemRes = {} & { id: string };

const updateNutritionHistoryProtectionItem: TApiRoute & {
  request: TUpdateNutritionHistoryProtectionItemReq;
  response: TUpdateNutritionHistoryProtectionItemRes;
} = {
  url: ({ nutritionHistoryProtectionItemId }) =>
    `/api/as-fields/protection-item/${nutritionHistoryProtectionItemId}`,
  method: 'PUT',
  request: {} as TUpdateNutritionHistoryProtectionItemReq,
  response: {} as TUpdateNutritionHistoryProtectionItemRes,
  headers: {},
};

export { updateNutritionHistoryProtectionItem };
export type { TUpdateNutritionHistoryProtectionItemReq, TUpdateNutritionHistoryProtectionItemRes };
