import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';
import { FC, ReactNode } from 'react';

import { IExperimentStep } from '../../../../../../../../api/models/as-fields/experiments';
import { StatusCellComponent } from '../../../../containers/Observations/components';

import Styled from './BackButtonContent.style';

interface IBackButtonContentProps {
  selectedAudit: IExperimentStep;
  /**
   * Иконка контекстной справки.
   */
  ContextualHelpIcon?: ReactNode;
  /**
   * Текст названия, если нету данных для отображения.
   */
  backButtonText?: string;
  /**
   * Отображать текст кнопки назад, вместо названия наблюдения.
   */
  isBackButtonTextTitleOnAuditName?: boolean;
}

const BackButtonContent: FC<IBackButtonContentProps> = ({
  selectedAudit,
  ContextualHelpIcon,
  backButtonText,
  isBackButtonTextTitleOnAuditName,
}) => {
  const { ref: AuditNameRef, isShowTooltip: isShowAuditNameTooltip } =
    useShowTooltip<HTMLDivElement>(selectedAudit);

  const { ref: additionalInfoRef, isShowTooltip: isShowAdditionalInfoTooltip } =
    useShowTooltip<HTMLDivElement>(selectedAudit);

  const auditInfo = `${selectedAudit?.organization?.name} – ${selectedAudit?.culture.name} – ${
    selectedAudit?.experiment.seasonYear - 1
  }-${selectedAudit?.experiment.seasonYear}`;

  return (
    <>
      {selectedAudit ? (
        <Styled.Wrapper>
          <Styled.MainInfoWrapper>
            <AutoTooltip
              content={isBackButtonTextTitleOnAuditName ? backButtonText : selectedAudit?.name}
              position="bottom"
              disabled={!isShowAuditNameTooltip}
            >
              <Styled.AuditName variant="h4" ref={AuditNameRef}>
                {isBackButtonTextTitleOnAuditName ? backButtonText : selectedAudit?.name}
              </Styled.AuditName>
            </AutoTooltip>

            {ContextualHelpIcon ? (
              <Styled.ContextualIconWrapper>{ContextualHelpIcon}</Styled.ContextualIconWrapper>
            ) : null}

            <Styled.StatusComponentWrapper>
              <StatusCellComponent status={selectedAudit?.status} />
            </Styled.StatusComponentWrapper>
          </Styled.MainInfoWrapper>
          <AutoTooltip
            content={auditInfo}
            position="bottom"
            disabled={!isShowAdditionalInfoTooltip}
          >
            <Styled.AdditionalInfo variant="body" ref={additionalInfoRef}>
              {auditInfo}
            </Styled.AdditionalInfo>
          </AutoTooltip>
        </Styled.Wrapper>
      ) : (
        <Styled.Title data-test-id={`header-title`}>{backButtonText}</Styled.Title>
      )}
    </>
  );
};

BackButtonContent.displayName = 'BackButtonContent';

export default BackButtonContent;
