import { FC, memo } from 'react';

import { StatusPill as StyledStatusPill } from '../StatusPill/StatusPill.styles';
import { EMessageStyleType } from '../../../../../common/features/ChatJournal/models/common';

const StatusPillInWork: FC<{ children; isSelected?: boolean }> = ({ children, isSelected }) => {
  return (
    <StyledStatusPill $colorScheme={EMessageStyleType.Success} $isSelected={isSelected}>
      В работе {children}
    </StyledStatusPill>
  );
};

export default memo(StatusPillInWork);
