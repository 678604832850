import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

const Name = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  margin-left: 14px;

  color: ${ECommonColor.GeneralBlack};
`;

const InnerHeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding-bottom: 12px;

  border-bottom: 1px solid ${ECommonColor.BorderGray};
`;

const Wrapper = styled.div``;

const StyledAuditPhotosStage = {
  Name,
  InnerHeaderWrapper,
  Header,
  Wrapper,
};

export default StyledAuditPhotosStage;
