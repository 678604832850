import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type TAttributeProps = {
  $padding?: string;
};

const Attribute = styled.div<TAttributeProps>`
  padding: ${({ $padding }) => $padding ?? 0};
`;

const StyledAuditReportForm = {
  Wrapper,
  Attribute,
};

export default StyledAuditReportForm;
