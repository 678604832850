import { IShortChecklistInstance } from '../../../../models/as-fields/checklists/Instance/ChecklistInstance.model';
import { TResponseList } from '../../../../models/common/response';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetChecklistInstanceListByTaskIdReq = {
  taskId: string;
  isActive?: boolean;
};

type TGetChecklistInstanceListByTaskIdRes = TResponseList<IShortChecklistInstance>;

const getChecklistInstanceListByTaskId: TApiRoute & {
  request: TGetChecklistInstanceListByTaskIdReq;
  response: TGetChecklistInstanceListByTaskIdRes;
} = {
  url: ({ taskId }) => `/api/as-fields/checklist/instances/byTaskId/${taskId}`,
  method: 'GET',
  request: {} as TGetChecklistInstanceListByTaskIdReq,
  response: {} as TGetChecklistInstanceListByTaskIdRes,
  headers: {},
};

export type { TGetChecklistInstanceListByTaskIdReq, TGetChecklistInstanceListByTaskIdRes };

export { getChecklistInstanceListByTaskId };
