import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import {
  IExperimentPlanDictionaryValue,
  IExperimentPlanValue as IValue,
} from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentPlanTable/ExperimentPlanTable.model';
import { IComparisonTableBuilderCellConfig as ICellConfig } from '../../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import { ComparisonStore } from '../../../stores';
import { TComparisonRowConfig as TRowConfig } from '../../../../types';
import { ComparisonComparableCell as ComparableCell } from '../../../../components';
import {
  formatBooleanValueList,
  formatDateValueList,
} from '../../../../../../../../../../../common/utils/helpers/formatValuesList';

@provide.transient()
class PairWiseComparisonCellsService {
  @lazyInject(ComparisonStore)
  protected comparisonStore: ComparisonStore;

  createCellConfigList = (
    rowConfig: TRowConfig,
    valueList: IValue[],
    newSelectedFields?: Array<{ id: string; name: string; position: number }>
  ): ICellConfig[] => {
    if (newSelectedFields) {
      const cellConfigList = newSelectedFields.map(field => {
        const selectedFieldValue = valueList?.[field?.position];

        const positionOfValue = field?.position;

        return this.createCellConfigByAttributeType(rowConfig, selectedFieldValue, positionOfValue);
      });

      if (
        rowConfig.initialModel.type === 'double' ||
        rowConfig.initialModel.type === 'formula' ||
        rowConfig.initialModel.type === 'summary'
      ) {
        const isBold = rowConfig.initialModel.type === 'summary';
        const padding = rowConfig.initialModel.type === 'summary' ? '0' : '0 0 12px';
        const margin = rowConfig.initialModel.type === 'summary' ? '0' : '0 0 auto';

        cellConfigList.push(
          this.createDifferenceCellConfig(
            rowConfig,
            valueList,
            newSelectedFields,
            padding,
            margin,
            isBold
          )
        );
      } else {
        cellConfigList.push(
          this.createDifferenceCellConfig(
            rowConfig,
            valueList,
            newSelectedFields,
            '',
            '',
            false,
            true
          )
        );
      }

      return cellConfigList;
    }
  };

  createDifferenceCellConfig = (
    rowConfig: TRowConfig,
    valueList: IValue[],
    newSelectedFields?: Array<{ id: string; name: string; position: number }>,
    padding?: string,
    margin?: string,
    isBold?: boolean,
    isEmptyValue?: boolean
  ): ICellConfig => {
    const cellConfig: ICellConfig = {
      columnId: 'difference',
      rowId: rowConfig.id,
    };

    if (!isEmptyValue) {
      const cellValue =
        Number(valueList?.[newSelectedFields[0].position]?.doubleValue ?? 0) -
        Number(valueList?.[newSelectedFields[1].position]?.doubleValue ?? 0);

      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          return (
            <ComparableCell
              factValue={cellValue === 0 ? 'Нет различий' : cellValue}
              isBold={isBold}
              padding={padding}
              margin={margin}
              dataTestId={dataTestId}
            />
          );
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: 'default',
      cellPadding: '0 0 12px',
      value: '—',
    };

    return cellConfig;
  };

  createCellConfigByAttributeType = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    switch (rowConfig.initialModel.type) {
      case 'string':
        return this.createStringCellConfig(rowConfig, value, position);

      case 'double':
        return this.createDoubleCellConfig(rowConfig, value, position);

      case 'boolean':
        return this.createBooleanCellConfig(rowConfig, value, position);

      case 'date':
        return this.createDateCellConfig(rowConfig, value, position);

      case 'dictionary':
        return this.createDictionaryCellConfig(rowConfig, value, position);

      case 'formula':
        return this.createDoubleCellConfig(rowConfig, value, position);

      case 'summary':
        return this.createSummaryCellConfig(rowConfig, value, position);

      default:
    }
  };

  createStringCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    if (value.tooltip) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          return <ComparableCell factValue={value.stringValue} dataTestId={dataTestId} />;
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: 'default',
      cellPadding: '0 0 12px',
      value: value.skip ? '—' : value.stringValue,
    };

    return cellConfig;
  };

  createBooleanCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    cellConfig.autoRenderConfig = {
      preset: 'default',
      cellPadding: '0 0 12px',
      value: value.skip ? '—' : formatBooleanValueList(value.booleanValue),
    };

    return cellConfig;
  };

  createDateCellConfig = (rowConfig: TRowConfig, value: IValue, position: number): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    cellConfig.autoRenderConfig = {
      preset: 'default',
      cellPadding: '0 0 12px',
      value: value.skip ? '—' : formatDateValueList(value.dateValue),
    };

    return cellConfig;
  };

  createDoubleCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    cellConfig.customRenderConfig = {
      render: (cell, dataTestId) => {
        return <ComparableCell factValue={value?.doubleValue} dataTestId={dataTestId} />;
      },
    };

    return cellConfig;
  };

  createDictionaryCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    if (value.tooltip) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          return (
            <ComparableCell
              factValue={this.createFullDictionaryLabel(value.dictionaryValues?.[0])}
              dataTestId={dataTestId}
            />
          );
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: 'default',
      cellPadding: '0 0 12px',
      value: value.skip ? '—' : this.createFullDictionaryLabel(value.dictionaryValues[0]),
    };

    return cellConfig;
  };

  createSummaryCellConfig = (
    rowConfig: TRowConfig,
    value: IValue,
    position: number
  ): ICellConfig => {
    const header = this.comparisonStore.getHeaderByPosition(position);

    const cellConfig: ICellConfig = {
      columnId: header.id,
      rowId: rowConfig.id,
    };

    cellConfig.customRenderConfig = {
      render: (cell, dataTestId) => {
        return (
          <ComparableCell
            factValue={value.doubleValue}
            dataTestId={dataTestId}
            padding={'12px 0'}
            isBold
          />
        );
      },
    };

    return cellConfig;
  };

  protected createFullDictionaryLabel = (value: IExperimentPlanDictionaryValue): string => {
    if (value?.description) {
      return `${value?.name} (${value?.code})`;
    }

    return value?.name;
  };
}

export default PairWiseComparisonCellsService;
