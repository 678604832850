import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  height: 128px;
`;

const Wrapper = styled.div``;

const StyledExecution = {
  Content,
  LoaderWrapper,
  Wrapper,
};

export default StyledExecution;
