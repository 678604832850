import { observer } from 'mobx-react';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { useStore } from '../../../../../utils/helpers/mobx';
import { FormController } from '../../../mobx/controllers';
import { FormStore } from '../../../mobx/stores';

import { IDatePickerContainerProps } from './DatePickerContainer.types';
import Styled from './DatePickerContainer.style';

const DatePickerContainer: FC<IDatePickerContainerProps> = ({
  formKey,
  elementName,
  id,
  element,
}) => {
  const formStore = useStore(FormStore);
  const { getFormValue } = formStore;

  const formController = useStore(FormController);
  const { onFormValueChange } = formController;

  const value = getFormValue(formKey, elementName) as string;
  const { schema } = element;

  const dependencyName = element?.dependencyName as never;
  const dependencyValue = getFormValue(formKey, dependencyName);
  const isDependencyEmpty = !dependencyName ? false : !Boolean(dependencyValue);

  useEffect(() => {
    const validValue = value ? moment(value).format('DD.MM.YYYY') : '';

    setInputValue(validValue);
  }, [value]);

  const [inputValue, setInputValue] = useState<string>(
    value ? moment(value).format('DD.MM.YYYY') : ''
  );

  const debounceOnChange = useRef(
    _.debounce((newValue: Date): void => handleFormValueChange(newValue), 500)
  );

  const handleFormValueChange = useCallback((newValue: Date): void => {
    onFormValueChange(formKey, elementName, { [elementName]: newValue });
  }, []);

  const handleInputChange = useCallback((date: Date): void => {
    const correctDateFormat = moment(date).format('DD.MM.YYYY');

    setInputValue(correctDateFormat);

    debounceOnChange.current(date);
  }, []);

  return (
    <Styled.DatePickerContainer
      dataTestId={id}
      value={inputValue}
      label={element?.label}
      readOnly={element?.isBlocked || element?.isDisabled || isDependencyEmpty}
      isBlocked={element?.isBlocked || isDependencyEmpty}
      isDisabled={element?.isDisabled}
      error={!schema?.isShownError ? '' : schema?.errorTitle}
      onChange={handleInputChange}
      datePickerOptions={element?.datePickerOptions}
      placeholder={element?.placeholder}
      $isBlocked={element?.isBlocked}
    />
  );
};

DatePickerContainer.displayName = 'DatePickerContainer';

export default memo(observer(DatePickerContainer));
