import { useCallback } from 'react';

const getDataTestId = (main: string) => (secondary?: string) => ({
  'data-test-id': secondary ? `${main}__${secondary}` : `${main}`,
});

const useDataTestIdV2 = (dataTestId: string) => {
  const getSecondary = useCallback(getDataTestId(dataTestId || ''), [dataTestId]);

  return getSecondary;
};

export default useDataTestIdV2;
