import { Icon } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FieldName = styled.span`
  margin: auto 0;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FieldTypeSvg = styled.img`
  margin-right: -35px;
`;

const FieldIcon = styled.span`
  min-width: 16px;
`;

const StyledFieldCell = {
  FieldTypeSvg,
  Wrapper,
  FieldName,
  FieldIcon,
};

export default StyledFieldCell;
