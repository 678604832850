import { IValidationScheme } from '../../../models/FormConfig/Form.model';

type TInputValue = string | number;

const validateElementByScheme = (value: TInputValue, scheme: IValidationScheme) => {
  if (scheme.regExp) {
    if (!validateRegExp(value, scheme.regExp)) {
      return Error(scheme.regExp.errorTitle);
    }
  }

  return true;
};

const validateRegExp = (value: TInputValue, scheme: IValidationScheme['regExp']) => {
  if (!value) {
    return true;
  } else {
    const regex = new RegExp(scheme.expression);

    return regex.test(String(value));
  }
};

const ValidationCollection = {
  validateElementByScheme,
  validateRegExp,
};

export default ValidationCollection;
