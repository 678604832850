import styled from 'styled-components';

import { TABLE_FILTERS_BUILDER_CONTROLS_KEY as CONTROLS_KEY } from '../../../constants';

const Controls = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;

  grid-area: ${CONTROLS_KEY};
`;

const StyledTableFiltersBuilderControls = {
  Controls,
};

export default StyledTableFiltersBuilderControls;
