import styled, { css } from 'styled-components';

const Wrapper = styled.div<{
  $width: number;
  $amount: number;
  $isScrollEnabled: boolean;
  $isHeader: boolean;
}>`
  display: grid;

  grid-template-columns: ${({ $width, $amount }) => `repeat(${$amount}, ${$width}px)`};

  & > :not(:last-child) {
    padding-right: 16px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  scroll-behavior: smooth;

  ${({ $isScrollEnabled }) =>
    $isScrollEnabled
      ? css`
          overflow-x: scroll;
        `
      : css`
          overflow-x: hidden;
        `}

  ${({ $isHeader }) =>
    $isHeader
      ? css`
          height: 72px;
          align-items: center;
        `
      : css``}
`;

const StyledComparisonTableBuilderSlider = {
  Wrapper,
};

export default StyledComparisonTableBuilderSlider;
