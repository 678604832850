import { FC, memo, MouseEvent, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { StepFooter } from '../../../../../../../../../common/components/ui/StepFooter';
import { TAuditsParams } from '../../../../../../models/params/AuditsParams.model';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { NavigationTabsController } from '../../../../../../../../../common/features/NavigationTabs/mobx';

interface IAuditReportStepFooterContainerProps {
  onSubmit?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>;
}

const AuditReportStepFooterContainer: FC<IAuditReportStepFooterContainerProps> = ({ onSubmit }) => {
  const { auditId } = useParams<TAuditsParams>();

  const { goToNextTab, goToPrevTab } = useStore(NavigationTabsController);

  const onContinueClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (onSubmit) {
        await onSubmit(e).then(() => {
          goToNextTab();
        });
      }
    },
    [auditId, goToNextTab]
  );

  return (
    <StepFooter
      stepName={'audit-report'}
      successTitle={'Сохранить и продолжить'}
      onReturnToPreviousStepClick={goToPrevTab}
      onContinueClick={onContinueClick}
    />
  );
};

AuditReportStepFooterContainer.displayName = 'AuditReportStepFooterContainer';

export default memo(AuditReportStepFooterContainer);
