import moment from 'moment';

import { provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../../../../common/features/TableBuilder/models/configs';
import { EContractsTableBuilderId } from '../../../../../../../../constants/configs';
import { DocumentOptionsCell } from '../../components';

@provide.transient()
class DocumentsTableConfigService {
  createConfig = (): IBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList();
    const rowsGroupList = this.createRowsGroupList();

    return {
      id: EContractsTableBuilderId.Documents,
      columnConfigList: columnList,
      cellConfigList: cellList,
      rowsGroupConfigList: rowsGroupList,
    };
  };

  createColumnList = (): IColumnConfig[] => {
    const fileName = this.createFileNameColumn();
    const date = this.createDateColumn();
    const userName = this.createUserNameColumn();
    const actions = this.createActionsColumn();

    return [fileName, date, userName, actions];
  };

  createFileNameColumn = (): IColumnConfig => {
    return {
      id: 'fileName',
      name: 'Название файла',
      width: {
        default: '32%',
      },
    };
  };

  createDateColumn = (): IColumnConfig => {
    return {
      id: 'fileUploadDate',
      name: 'Дата проведения',
      width: {
        default: '32%',
      },
    };
  };

  createUserNameColumn = (): IColumnConfig => {
    return {
      id: 'fileUploadUser',
      name: 'Загрузил',
      width: {
        default: '21%',
      },
    };
  };

  createActionsColumn = (): IColumnConfig => {
    return {
      id: 'actions',
      name: '',
      width: {
        default: '14%',
      },
    };
  };

  createCellList = (): ICellConfig[] => {
    const fileName = this.createFileNameCell();
    const date = this.createDateCell();
    const userName = this.createUserNameCell();
    const actions = this.createActionsCell();

    return [fileName, date, userName, actions];
  };

  createFileNameCell = (): ICellConfig => {
    return {
      id: 'fileName',
      autoRenderConfig: {
        renderValue: row => row.fileName,
      },
    };
  };

  createDateCell = (): ICellConfig => {
    return {
      id: 'fileUploadDate',
      autoRenderConfig: {
        renderValue: row => moment(row.fileUploadDate).format('DD.MM.YYYY'),
      },
    };
  };

  createUserNameCell = (): ICellConfig => {
    return {
      id: 'fileUploadUser',
      autoRenderConfig: {
        renderValue: row => row.fileUploadUser.fullName,
      },
    };
  };

  createActionsCell = (): ICellConfig => {
    return {
      id: 'actions',
      customRenderConfig: {
        renderCell: row => <DocumentOptionsCell rowData={row} />,
      },
    };
  };

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EContractsTableBuilderId.Documents,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default DocumentsTableConfigService;
