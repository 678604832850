import { PageNotFound } from '../../../../../../../../../common/features/UI';
import { AccessStore } from '../../../../../../../../../common/mobx/stores/AccessStore';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { EDashboardRoute } from '../../../../../../../../routes/DashboardRoute';

const useCreateExperimentError = () => {
  const accessStore = useStore(AccessStore);
  const { responseError, setResponseError } = accessStore;

  const clearErrorState = () => setResponseError(null);

  if (responseError) {
    return (
      <PageNotFound
        type={responseError}
        link={`/${EDashboardRoute.Experiments}`}
        onButtonClick={clearErrorState}
      />
    );
  } else {
    return null;
  }
};

export default useCreateExperimentError;
