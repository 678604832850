import styled from 'styled-components';

const Wrapper = styled.div<{ $paddingBottom: number }>`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-bottom: ${({ $paddingBottom }) => ($paddingBottom ? `${$paddingBottom}px` : 0)};
`;

const Item = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  min-width: 48px;
`;

const StyledActiveSubstancesRow = {
  Wrapper,
  Item,
};

export default StyledActiveSubstancesRow;
