import { ITableFiltersBuilderValue as IValue } from '../../../models/data';
import { provide } from '../../../../../utils/helpers/mobx';

type TAppliedValues<F> = Record<string, IValue<F>[]>;
type TSelectedPresetIds = Record<string, string>;

const APPLIED_VALUES_KEY = 'appliedTableFiltersBuilderValues';
const SELECTED_PRESET_IDS_KEY = 'selectedTableFiltersBuilderPresetIds';

@provide.singleton()
class TableFiltersBuilderSessionStorageService<F = any> {
  getAppliedValues = (): TAppliedValues<F> => {
    const storedValues = sessionStorage.getItem(APPLIED_VALUES_KEY) || '{}';

    const values = JSON.parse(storedValues) as TAppliedValues<F>;

    return values;
  };

  getSelectedPresetIds = (): TSelectedPresetIds => {
    const storedPresetIds = sessionStorage.getItem(SELECTED_PRESET_IDS_KEY) || '{}';

    const presetIds = JSON.parse(storedPresetIds) as TSelectedPresetIds;

    return presetIds;
  };

  getAppliedValueList = (builderId: string): IValue<F>[] => {
    const values = this.getAppliedValues();

    const valueList = values[builderId] || ([] as IValue<F>[]);

    return valueList;
  };

  getSelectedPresetId = (builderId: string): string => {
    const presetIds = this.getSelectedPresetIds();

    const presetId = presetIds[builderId];

    return presetId;
  };

  setAppliedValueList = (builderId: string, valueList: IValue<F>[]): void => {
    const values = this.getAppliedValues();

    const updatedValues: TAppliedValues<F> = {
      ...values,
      [builderId]: valueList,
    };

    sessionStorage.setItem(APPLIED_VALUES_KEY, JSON.stringify(updatedValues));
  };

  deleteAppliedValueList = (builderId: string): void => {
    const values = this.getAppliedValues();

    delete values[builderId];

    sessionStorage.setItem(APPLIED_VALUES_KEY, JSON.stringify(values));
  };

  setSelectedPresetId = (builderId: string, presetId: string): void => {
    const presetIds = this.getSelectedPresetIds();

    const updatedPresetIds: TSelectedPresetIds = {
      ...presetIds,
      [builderId]: presetId,
    };

    sessionStorage.setItem(SELECTED_PRESET_IDS_KEY, JSON.stringify(updatedPresetIds));
  };

  deleteSelectedPresetId = (builderId: string): void => {
    const presetIds = this.getSelectedPresetIds();

    delete presetIds[builderId];

    sessionStorage.setItem(SELECTED_PRESET_IDS_KEY, JSON.stringify(presetIds));
  };
}

export default TableFiltersBuilderSessionStorageService;
