import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';
import { IEmployee } from '../../../../../../api/models/da-profile/users';

const createEmployeeSelectOption = (employee: IEmployee): ISelectOption => {
  return { label: employee.userName, value: employee.employeeId };
};

const createEmployeeSelectOptionList = (employeeList: IEmployee[]): ISelectOption[] => {
  return employeeList.map<ISelectOption>(employee => createEmployeeSelectOption(employee));
};

export { createEmployeeSelectOption, createEmployeeSelectOptionList };
