import { FC, memo } from 'react';

import { IAttributeComponentProps } from '../../../../../../../../../common/features/DynamicTable/models/Attribute/Attribute.model';

import { ActiveSubstanceAttrHelpers } from './utils/helpers';

const { createAnalyzedLetterList } = ActiveSubstanceAttrHelpers;

const ActiveSubstanceAttr: FC<IAttributeComponentProps> = ({ attribute }) => {
  return (
    <span>
      {createAnalyzedLetterList(attribute.name).map(({ id, tag, val }) => {
        if (tag === 'sub') {
          return <sub key={id}>{val}</sub>;
        } else {
          return <span key={id}>{val}</span>;
        }
      })}
    </span>
  );
};

ActiveSubstanceAttr.displayName = 'ActiveSubstanceAttr';

export default memo(ActiveSubstanceAttr);
