import { EExperimentStatus } from '../../../../../../../../../../api/models/as-fields/experiments';

const getStatusCellComponentColors = status => {
  let statusName = status;
  let color = '#151F32';
  let bgColor = '#FAF9FD';

  switch (status) {
    case EExperimentStatus.InProgress:
      statusName = 'В работе';
      color = '#3FB65F';
      bgColor = '#ECF8EF';
      break;
    case EExperimentStatus.InApproval:
      statusName = 'ТЗ на согласовании';
      color = '#EF6517';
      bgColor = '#FFF5E4';
      break;
    case EExperimentStatus.Draft:
      statusName = 'Черновик';
      color = '#7E8B9D';
      bgColor = '#FAF9FD';
      break;
    case EExperimentStatus.Completed:
      statusName = 'Завершен';
      color = '#151F32';
      bgColor = '#FAF9FD';
      break;
    case EExperimentStatus.Approved:
      statusName = 'ТЗ согласовано';
      color = '#0770E3';
      bgColor = '#E7F1FD';
      break;
    case EExperimentStatus.FactAppruved:
      statusName = 'Отчет согласован';
      color = '#0770E3';
      bgColor = '#E7F1FD';
      break;
    case EExperimentStatus.FullCompleted:
      statusName = 'Закрыт';
      color = '#129AA3';
      bgColor = '#EEFFFE';
      break;
    case EExperimentStatus.Canceled:
      statusName = 'Отменен';
      color = '#DF2E22';
      bgColor = '#FEF5F5';
      break;
    default:
      break;
  }

  return { bgColor, color, statusName };
};

export default getStatusCellComponentColors;
