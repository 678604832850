import { FC } from 'react';

import { Input } from '../../../../../../../../../../../common/components/form';
import { formatNumValue } from '../../../../../../../../../../../common/utils/helpers/numbers';

import Styled from './CalculationForm.style';

export interface ICalculationFormProps {
  plannedCropPrice: string;
  plannedYield: string;
  revenue: number;
  isViewMode?: boolean;
  onChangePlannedCropPrice(value: string): void;
  onChangePlannedYield(value: string): void;
}

const CalculationForm: FC<ICalculationFormProps> = ({
  plannedCropPrice,
  plannedYield,
  revenue,
  onChangePlannedCropPrice,
  onChangePlannedYield,
  isViewMode,
}) => {
  return (
    <Styled.FormWrapper>
      <Styled.InputWrapper key={'plannedCropPrice'}>
        <Input
          label="Цена готовой продукции, ₽/т*"
          isBlocked={isViewMode}
          value={plannedCropPrice}
          onChange={onChangePlannedCropPrice}
          data-test-id={'calculation-current-section-plannedCropPrice'}
          placeholder={'Укажите значение'}
        />
      </Styled.InputWrapper>
      <Styled.InputWrapper key={'plannedYield'}>
        <Input
          label="Плановая урожайность, т/га*"
          isBlocked={isViewMode}
          value={plannedYield}
          onChange={onChangePlannedYield}
          data-test-id={'calculation-current-section-plannedYield'}
          placeholder={'Укажите значение'}
        />
      </Styled.InputWrapper>
      <Styled.InputWrapper key={'totalPrice'}>
        <Input
          label="Выручка, ₽/га"
          isBlocked
          value={formatNumValue(revenue)}
          data-test-id={'calculation-current-section-total'}
          placeholder={'Не рассчитано'}
        />
      </Styled.InputWrapper>
    </Styled.FormWrapper>
  );
};

export default CalculationForm;
