import { FC } from 'react';
import { observer } from 'mobx-react';

import { IComparisonTableBuilderRowsGroup as IRowsGroup } from '../../../models/data/ComparisonTableBuilderRowsGroup';
import { ComparisonTableBuilderRowsContainer as RowsContainer } from '../../rows/ComparisonTableBuilderRowsContainer';
import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';

interface IProps {
  rowsGroup: IRowsGroup;
}

const ComparisonTableBuilderRowsGroupCustomRenderContainer: FC<IProps> = ({ rowsGroup }) => {
  const getDataTestId = useDataTestId({ componentName: 'custom-rows-group' });

  return (
    <>
      {rowsGroup.customRenderConfig.render(
        rowsGroup,
        <RowsContainer rowsGroupId={rowsGroup.id} rowIdList={rowsGroup.rowIdList} />,
        getDataTestId()['data-test-id']
      )}
    </>
  );
};

ComparisonTableBuilderRowsGroupCustomRenderContainer.displayName =
  'ComparisonTableBuilderRowsGroupCustomRenderContainer';

export default observer(ComparisonTableBuilderRowsGroupCustomRenderContainer);
