import { FC, memo } from 'react';

import { Plug } from '../../../../../../../../../../../common/components/ui';
import applicationTimeIcon from '../../../../../../assets/icons/application_time.svg';

export interface ITablePlugProps {
  onClickAdd(): void;
}

const TablePlug: FC<ITablePlugProps> = ({ onClickAdd }) => {
  return (
    <Plug
      icon={applicationTimeIcon}
      title="Внесите для каждого участка техоперации и действующие вещества, которые применялись на нём в предыдущем сезоне"
      primaryButtonTitle="Добавить техоперацию"
      onPrimaryButtonClick={onClickAdd}
      buttonExist
    />
  );
};

export default memo(TablePlug);
