import { FC, memo } from 'react';
import { observer } from 'mobx-react';

import { DynamicTableHead } from '../../components';
import { IDynamicTableConfig } from '../../models';
import { useOrderedInstanceList } from '../../private';

interface Props {
  config: IDynamicTableConfig;
  width: number;
  stickyTopIndent?: number;
  parentScrollTop?: number;
}

const DynamicTableHeadContainer: FC<Props> = ({
  config: { id, headColumn },
  width,
  stickyTopIndent,
  parentScrollTop,
}) => {
  const instList = useOrderedInstanceList(id);

  return (
    <DynamicTableHead
      headColumnWidth={headColumn.width}
      headColumnTitle={headColumn.name}
      headColumnPadding={headColumn.padding}
      headColumnMargin={headColumn.margin}
      instanceList={instList}
      width={width}
      stickyTopIndent={stickyTopIndent}
      parentScrollTop={parentScrollTop}
    />
  );
};

DynamicTableHeadContainer.displayName = 'DynamicTableHeadContainer';

export default memo(observer(DynamicTableHeadContainer));
