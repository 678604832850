import { isNaN } from 'lodash';

interface IAnalyzedLetter {
  id: number;
  tag: 'sub' | 'span';
  val: string;
}

const createAnalyzedLetterList = (word: string): IAnalyzedLetter[] => {
  return word.split('').map<IAnalyzedLetter>((letter, index) => {
    const number = Number(letter);

    return {
      id: index,
      tag: isNaN(number) ? 'span' : 'sub',
      val: letter,
    };
  });
};

const ActiveSubstanceAttrHelpers = { createAnalyzedLetterList };

export default ActiveSubstanceAttrHelpers;
