import { makeAutoObservable } from 'mobx';

import { IExperimentStep } from '../../../../../../../../../api/models/as-fields/experiments';
import { provide } from '../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
class ObservationStore {
  private _auditsById: Map<string, IExperimentStep> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  private _totalPageNumber = 0;
  private _currentPageNumber = 0;
  private _isLoading = false;

  private _filterPageNumbers = {
    counterpartyCurrentPageNumber: 0,
    counterpartyTotalPageNumber: 0,
    executorCurrentPageNumber: 0,
    executorTotalPageNumber: 0,
    numberCurrentPageNumber: 0,
    numberTotalPageNumber: 0,
  };

  get auditList() {
    return [...this._auditsById.values()];
  }

  get totalPageNumber() {
    return this._totalPageNumber;
  }

  get currentPageNumber() {
    return this._currentPageNumber;
  }

  get isLoading() {
    return this._isLoading;
  }

  setAuditList = (auditList: IExperimentStep[]) => {
    const map = new Map<string, IExperimentStep>();

    auditList.forEach(audit => {
      map.set(audit.id, audit);
    });

    this._auditsById = map;
  };

  deleteAudit = (id: string): void => {
    this._auditsById?.delete?.(id);
  };

  setTotalPageNumber = (pageNumber: number): void => {
    this._totalPageNumber = pageNumber;
  };

  setCurrentPageNumber = (pageNumber: number): void => {
    this._currentPageNumber = pageNumber;
  };

  setIsLoading = (isLoading): void => {
    this._isLoading = isLoading;
  };

  clearTotalPageNumber = (): void => {
    this._totalPageNumber = 0;
  };

  clearCurrentPageNumber = (): void => {
    this._currentPageNumber = 0;
  };

  clearAuditsById = (): void => {
    this._auditsById.clear();
  };

  clearStore = (): void => {
    this.clearAuditsById();
    this.clearTotalPageNumber();
    this.clearCurrentPageNumber();
  };
}

export default ObservationStore;
