import { Button as FarmikButton } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';
import { ReactComponent as Copy } from '../../../history/containers/History/assets/icons/copy.svg';
import { ReactComponent as Info } from '../../../history/containers/History/assets/icons/info.svg';

const { White } = ECommonColor;

const Button = styled(FarmikButton)`
  justify-content: center;
  width: 300px;
  margin-top: 40px;
`;

const AddTechOperationButton = styled(FarmikButton)`
  width: 200px;
  height: 40px;
  border-radius: 12px;
  margin: 0 auto;
`;

const Container = styled.div`
  background-color: ${White};
  border-radius: 16px;
  padding: 24px 24px 32px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #151f32;
  margin-bottom: 12px;
  letter-spacing: -0.5px;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const SubTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #151f32;
  margin-bottom: 14px;
  letter-spacing: -0.15px;
  display: block;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 24px;

  :last-child {
    padding-bottom: 100px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const TabsWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e6f0;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e6f0;
  margin-bottom: 20px;
`;

const CopyIcon = styled(Copy)`
  margin: 0 4px -3px;
`;

const InfoIcon = styled(Info)`
  margin: 0 0 -1px 12px;
`;

const StyledCalculation = {
  Container,
  Title,
  Wrapper,
  ButtonWrapper,
  Button,
  AddTechOperationButton,
  TabsWrapper,
  TitleWrapper,
  SubTitleWrapper,
  SubTitle,
  CopyIcon,
  InfoIcon,
};

export default StyledCalculation;
