import { FC, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { TablePlugBuilder } from '../../../../../../../../common/features/TablePlugBuilder';
import { useDataTestId } from '../../../../../../../../common/utils/hooks/locators';
import { EExperimentsRoute } from '../../../../../routes';
import { ECreateExperimentRoute } from '../../../../../modules/createExperiment/routes';

import Styled from './ExperimentsListDefaultPlug.styles';

const ExperimentsListDefaultPlug: FC = () => {
  const navigate = useNavigate();

  const handleAddButtonClick = useCallback(() => {
    navigate(
      generatePath(`${EExperimentsRoute.Experiment}/${ECreateExperimentRoute.Attributes}`, {
        experimentId: 'create',
      })
    );
  }, []);

  const getDataTestId = useDataTestId('experiments-table');
  const noDataPlugDataTestId = getDataTestId('no-data-plug')['data-test-id'];

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'file' },
        content: {
          title: 'У вас еще нет опытов',
          description: 'Добавьте хотя бы один опыт, чтобы начать работу',
        },
        footer: {
          renderButton: () => (
            <Styled.Button
              color={'primary'}
              onClick={handleAddButtonClick}
              alignment={'center'}
              dataTestId={getDataTestId('add-experiment-plug-button')['data-test-id']}
            >
              Добавить
            </Styled.Button>
          ),
        },
      }}
      dataTestId={noDataPlugDataTestId}
    />
  );
};

ExperimentsListDefaultPlug.displayName = 'ExperimentsListDefaultPlug';

export default ExperimentsListDefaultPlug;
