import { FC, memo, useContext } from 'react';

import { SliderContext } from '../../context';

import Styled from './SliderControls.styles';

interface ISliderControlsProps {
  isLeftArrowDisabled: boolean;
  isRightArrowDisabled: boolean;
}

const SliderControls: FC<ISliderControlsProps> = ({
  isLeftArrowDisabled,
  isRightArrowDisabled,
}) => {
  const { onArrowClick } = useContext(SliderContext);

  const handleArrowClick = (position: 'left' | 'right') => {
    if (position === 'right') {
      onArrowClick(position);
    } else {
      onArrowClick(position);
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.ArrowWrapper
        onClick={() => handleArrowClick('left')}
        $isDisabled={isLeftArrowDisabled}
      >
        <Styled.ArrowLeft $isLeftArrowDisabled={isLeftArrowDisabled} />
      </Styled.ArrowWrapper>
      <Styled.ArrowWrapper
        onClick={() => handleArrowClick('right')}
        $isDisabled={isRightArrowDisabled}
      >
        <Styled.ArrowRight $isRightArrowDisabled={isRightArrowDisabled} />
      </Styled.ArrowWrapper>
    </Styled.Wrapper>
  );
};

SliderControls.displayName = 'SliderControls';

export default memo(SliderControls);
