import styled, { css } from 'styled-components';

import MessageColorsScheme, { TMessageColorItem } from '../../configs/colors.config';

const MessageContainer = styled.div<{
  $colorScheme: keyof TMessageColorItem;
  $isRightSide?: boolean;
}>`
  max-width: 750px;
  width: 100%;
  border-radius: 6px 12px 12px 6px;
  padding: 16px 24px 16px 24px;
  position: relative;

  background-color: ${({ $colorScheme }) =>
    MessageColorsScheme[$colorScheme].messageBackgroundColor};

  ${({ $isRightSide }) =>
    $isRightSide &&
    css`
      place-self: end;
    `}

  ::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    border-radius: 12px 0 0 12px;
    background-color: ${({ $colorScheme }) => MessageColorsScheme[$colorScheme].borderColor};
  }
`;

const MessageInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const MessageAuthor = styled.p<{ $colorScheme: keyof TMessageColorItem }>`
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ $colorScheme }) => MessageColorsScheme[$colorScheme].textColor};
`;

const MessageContent = styled.p<{ $colorScheme: keyof TMessageColorItem }>`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  white-space: pre-wrap;
  color: ${({ $colorScheme }) => MessageColorsScheme[$colorScheme].textColor};
`;

const MessageDate = styled.p<{ $colorScheme: keyof TMessageColorItem }>`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${({ $colorScheme }) => MessageColorsScheme[$colorScheme].textColor};
`;

const Styled = {
  MessageContainer,
  MessageInfoWrapper,
  MessageAuthor,
  MessageContent,
  MessageDate,
};

export default Styled;
