import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IGeoUnit } from '../../../models/as-fields/geo-unit';

type TGetGeoUnitsReq = { id: string };

type TGetGeoUnitsRes = IGeoUnit;

const getGeoUnits: TApiRoute & {
  request: TGetGeoUnitsReq;
  response: TGetGeoUnitsRes;
} = {
  url: ({ id }) => `/api/as-fields/geo-units/${id}`,
  method: 'GET',
  request: {} as TGetGeoUnitsReq,
  response: {} as TGetGeoUnitsRes,
  headers: {},
};

export type { TGetGeoUnitsReq, TGetGeoUnitsRes };

export { getGeoUnits };
