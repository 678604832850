import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useNavigate } from 'react-router-dom';

import { TableBuilder } from '../../../../../common/features/TableBuilder';
import { EExperimentsRoute } from '../../routes';
import { useStore } from '../../../../../common/utils/helpers/mobx';
import { TableBuilderController } from '../../../../../common/features/TableBuilder/mobx/controllers';
import { ITableBuilderRowConfig } from '../../../../../common/features/TableBuilder/models/configs';
import { IExperiment } from '../../../../../../api/models/as-fields/experiments';
import { EExperimentsTableBuilderId } from '../../constants/configs';
import { ECreateExperimentRoute } from '../../modules/createExperiment/routes';

import { ExperimentsListController } from './mobx/controllers';
import {
  ExperimentsListDefaultPlug as DefaultPlug,
  ExperimentsListNoDataPlug as NoDataPlug,
} from './components/plugs';

type TOnRowClick = ITableBuilderRowConfig<IExperiment>['onRowClick'];

const ExperimentsList: FC = () => {
  const experimentsListController = useStore(ExperimentsListController);
  const tableBuilderController = useStore(TableBuilderController);

  const navigate = useNavigate();

  const handleRowClick = useCallback<TOnRowClick>(({ id }) => {
    navigate(
      generatePath(`${EExperimentsRoute.Experiment}/${ECreateExperimentRoute.Execution}`, {
        experimentId: id,
      })
    );
  }, []);

  useEffect(() => {
    experimentsListController.initiateTable();

    tableBuilderController.addRowClickEvent(EExperimentsTableBuilderId.Experiments, handleRowClick);
    tableBuilderController.showDefaultPlug(EExperimentsTableBuilderId.Experiments);
    tableBuilderController.showLoader(EExperimentsTableBuilderId.Experiments);
  }, []);

  return (
    <TableBuilder
      builderId={EExperimentsTableBuilderId.Experiments}
      renderNoDataPlug={() => <NoDataPlug />}
      renderDefaultPlug={() => <DefaultPlug />}
    />
  );
};

ExperimentsList.displayName = 'AuditsList';

export default observer(ExperimentsList);
