import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledComparisonTableBuilderRowsContainer = {
  Wrapper,
};

export default StyledComparisonTableBuilderRowsContainer;
