import { makeAutoObservable } from 'mobx';

import { AxiosService } from '../../../../../mobx/services/axios/AxiosService';
import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { TFormValue } from '../../../models';

@provide.singleton()
class FormService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  constructor() {
    makeAutoObservable(this);
  }

  submitForm = async <F extends Record<keyof F, TFormValue>>(
    form: F,
    handler: (form: F) => Promise<boolean>
  ): Promise<boolean | void> => {
    try {
      const isSuccess = await handler?.(form);

      return isSuccess;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default FormService;
