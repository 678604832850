import { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../common/utils/hooks/locators';

import Styled from './ExecutionDeviationsCell.styles';
import { IExecutionDeviationsCellFormattedValue as IDeviationsFormattedValue } from './ExecutionDeviationsCell.types';

interface IProps extends IDeviationsFormattedValue {
  dataTestId: string;
}

const ExecutionDeviationsCell: FC<IProps> = ({ color, value, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}-deviations-cell`);

  return (
    <Styled.Wrapper $color={color ?? 'default'} {...getDataTestId()}>
      {value}
    </Styled.Wrapper>
  );
};

ExecutionDeviationsCell.displayName = 'ExecutionDeviationsCell';

export default memo(ExecutionDeviationsCell);
