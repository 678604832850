import { FC } from 'react';

import { ETaskStatus } from '../../../../../../../../../../../common/utils/constants/task';
import { getTaskStatusColors } from '../../utils/helpers';

import Styled from './SliderObservationStatus.styles';

interface IProps {
  status: ETaskStatus;
}

export const SliderTaskStatus: FC<IProps> = ({ status }) => {
  const { bgColor, color, statusName } = getTaskStatusColors(status);

  return (
    <Styled.StatusWrapper
      $bgColor={bgColor}
      $color={color}
      data-test-id="status-cell-component-wrapper"
    >
      {statusName}
    </Styled.StatusWrapper>
  );
};
