import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';

import { useStore } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionDoubleCell } from '../../../../../components/cells';
import { ExecutionStore } from '../../../../../mobx/stores';
import { ExecutionController } from '../../../../../mobx/controllers';
import { getExecutionTableAttributeIdByRowId as getAttributeIdByRowId } from '../../../../../helpers';

interface IProps {
  rowId: string;
  initialValue: string | number;
  dataTestId: string;
}

const ExecutionCalculationsDoubleCell: FC<IProps> = ({ rowId, initialValue, dataTestId }) => {
  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);

  const attributeId = useMemo(() => getAttributeIdByRowId(rowId), []);

  const updatedValue = executionStore.getZoneForUpdate(executionStore.selectedZoneId)?.[
    attributeId
  ] as string;

  const handleChange = useCallback((newValue: string) => {
    executionController.changeZone({ [attributeId]: newValue });
  }, []);

  return (
    <ExecutionDoubleCell
      initialValue={initialValue}
      updatedValue={updatedValue}
      onChange={handleChange}
      dataTestId={dataTestId}
    />
  );
};

ExecutionCalculationsDoubleCell.displayName = 'ExecutionCalculationsDoubleCell';

export default observer(ExecutionCalculationsDoubleCell);
