import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { IUser } from '../../../models/da-profile/users';

type TGetScoutUserListReq = {
  fullName?: string;
  size?: number;
  page?: number;
  notCurrentUser?: boolean;
  notRoleId?: string;
  notOwnerOrganizationId?: string;
};

type TGetScoutUserListRes = {} & TResponseList<IUser>;

const getScoutUserList: TApiRoute & {
  request: TGetScoutUserListReq;
  response: TGetScoutUserListRes;
} = {
  url: () => `/api/da-profile/users/scouts`,
  method: 'GET',
  request: {} as TGetScoutUserListReq,
  response: {} as TGetScoutUserListRes,
  headers: {},
};

export type { TGetScoutUserListReq, TGetScoutUserListRes };

export { getScoutUserList };
