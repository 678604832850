import { FC, PropsWithChildren, UIEvent, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { useStore } from '../../../../../utils/helpers/mobx';
import { ComparisonTableBuilderController as Controller } from '../../../mobx/controllers';
import { useComparisonTableBuilderContext } from '../../../context';
import { PageContentContext } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/containers/CreateExperiment/context';

import Styled from './ComparisonTableBuilderSlider.styles';

const ComparisonTableBuilderSlider: FC<PropsWithChildren<{ isHeader?: boolean }>> = ({
  children,
  isHeader,
}) => {
  const controller = useStore(Controller);

  const { builderId, sliderControl } = useComparisonTableBuilderContext();

  const { pageScrollHeight } = useContext(PageContentContext);

  const scrollableColumnsData = controller.getScrollableColumnsData(builderId);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    sliderControl.setAddingScrollValue(scrollableColumnsData.columnWidth);
  }, [JSON.stringify(scrollableColumnsData)]);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    const { scrollWidth, clientWidth } = scrollRef.current;

    if (scrollWidth === clientWidth) {
      return;
    }

    sliderControl.onToggleControlsVisibility(true);
  }, [scrollRef.current?.scrollWidth]);

  useEffect(() => {
    sliderControl.onToggleScrollUpdate(true);
  }, [pageScrollHeight]);

  const onScroll = ({
    currentTarget: { scrollLeft, scrollWidth, clientWidth },
  }: UIEvent<HTMLDivElement, globalThis.UIEvent>) => {
    if (scrollLeft + clientWidth < scrollWidth) {
      sliderControl.onDisableArrow('both');
    }

    if (scrollLeft === 0) {
      sliderControl.onDisableArrow('left');
    }

    if (Math.ceil(scrollLeft) + Math.ceil(clientWidth) >= scrollWidth) {
      sliderControl.onDisableArrow('right');
    }
  };

  /**
   * Обновляет боковой скролл, когда компонент коллапса переходит из свернутого
   * состояния, в раскрытый.
   */
  useEffect(() => {
    if (sliderControl.isNeedToUpdateScroll) {
      /**
       * Данная задержка введена для того, чтобы не возникало багов в Safari и Firefox.
       * Баг [скролл остается неизменным после нажатия на стрелку] возникает из-за анимации
       * после изменения значения скролла.
       */
      setTimeout(() => {
        scrollRef.current.scrollLeft = sliderControl.scrollValue;
      }, 100);

      sliderControl.onToggleScrollUpdate(false);
    }
  }, [sliderControl.isNeedToUpdateScroll]);

  useEffect(() => {
    if (!scrollRef?.current) {
      return;
    }

    if (!sliderControl.clickedArrow) {
      return;
    }

    if (sliderControl.clickedArrow === 'left') {
      /**
       * Данная задержка введена для того, чтобы не возникало багов в Safari и Firefox.
       * Баг [скролл остается неизменным после нажатия на стрелку] возникает из-за анимации
       * после изменения значения скролла.
       */
      setTimeout(() => {
        scrollRef.current.scrollLeft -= sliderControl.addingScrollValue;
      }, 100);
    } else if (sliderControl.clickedArrow === 'right') {
      setTimeout(() => {
        scrollRef.current.scrollLeft += sliderControl.addingScrollValue;
      }, 100);
    }

    sliderControl.onClearClickedArrow();
  }, [sliderControl.clickedArrow]);

  const getDataTestId = useDataTestId({ componentName: 'grid' });

  return (
    <Styled.Wrapper
      ref={scrollRef}
      onScroll={onScroll}
      $width={scrollableColumnsData.columnWidth}
      $isHeader={isHeader}
      $amount={scrollableColumnsData.totalColumns}
      $isScrollEnabled={sliderControl.isNeedToUpdateScroll || Boolean(sliderControl.clickedArrow)}
      {...getDataTestId()}
    >
      {children}
    </Styled.Wrapper>
  );
};

ComparisonTableBuilderSlider.displayName = 'ComparisonTableBuilderSlider';

export default observer(ComparisonTableBuilderSlider);
