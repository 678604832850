import { FC, memo } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import getOperstionTypeSvg from '../../../../../../../../../../../../common/utils/helpers/getOperationTypeSvg/getOperationTypeSvg';
import { useShowTooltip } from '../../../../../../../../../../../../common/utils/hooks';

import Styled from './OperationTypeCell.style';

interface IProps {
  rowData: any;
}

const OperationTypeCell: FC<IProps> = ({ rowData }) => {
  const { ref, showTooltip } = useShowTooltip();

  return (
    <Styled.Wrapper>
      <Styled.TaskTypeSvg
        src={getOperstionTypeSvg(rowData?.operationInfo?.operationTypeInfo?.id)}
      />
      <AutoTooltip
        content={rowData?.operationInfo?.operationTypeInfo?.name}
        position="top"
        disabled={!showTooltip}
      >
        <Styled.OperationInfo ref={ref}>
          {rowData?.operationInfo?.operationTypeInfo?.name}
        </Styled.OperationInfo>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

OperationTypeCell.displayName = 'OperationTypeCell';

export default memo(observer(OperationTypeCell));
