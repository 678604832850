import { createContext, useContext } from 'react';

interface IUIContext {
  builderId: string;
}

const defaultValue: IUIContext = {
  builderId: '',
};

const TableFiltersBuilderUIContext = createContext<IUIContext>(defaultValue);

export const useTableFiltersBuilderUIContext = () => {
  const UIContext = useContext(TableFiltersBuilderUIContext);

  return UIContext;
};

export default TableFiltersBuilderUIContext;
