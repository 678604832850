import { IEmployee } from '../../../../../../../../../../../../api/models/da-profile/users';

const createOptionListFromUsers = (users: IEmployee[]) => {
  return users.map(user => ({
    label: user.userName,
    value: user.userId,
  }));
};

export default createOptionListFromUsers;
