import { NewDropdown } from '@farmlink/farmik-ui';

import { provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { IComparisonTableBuilderColumnConfig as IColumnConfig } from '../../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import { IExperimentPlanHeader as IHeader } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentPlanTable/ExperimentPlanTable.model';
import { ComparisonColumn as Column } from '../../../../components';

import Styled from './PairWiseComparisonColumnsService.style';

@provide.transient()
class PairWiseComparisonColumnsService {
  createColumnConfigList = (
    headerList: IHeader[],
    newSelectedFields?: Array<{ id: string; name: string; position: number }>,
    changeTableRows?: (value) => void
  ): IColumnConfig[] => {
    const initialList: IColumnConfig[] = [
      this.createMainColumnConfig(),
      this.createDifferenceColumnConfig(),
      this.createFirstFieldColumnConfig(headerList, newSelectedFields, changeTableRows),
      this.createSecondFieldColumnConfig(headerList, newSelectedFields, changeTableRows),
    ];

    return initialList;
  };

  protected createMainColumnConfig = (): IColumnConfig => {
    return {
      id: 'main',
      width: '248px',
      isFixed: true,
      customRenderConfig: {
        render: (_column, dataTestId) => (
          <Column name={'Показатели'} dataTestId={dataTestId} isMain />
        ),
      },
    };
  };

  protected createDifferenceColumnConfig = (): IColumnConfig => {
    return {
      id: 'difference',
      width: '173px',
      isFixed: true,
      customRenderConfig: {
        render: (_column, dataTestId) => (
          <Column name={'Разница между участками'} dataTestId={dataTestId} />
        ),
      },
    };
  };

  protected createFirstFieldColumnConfig = (
    headerList,
    newSelectedFields,
    changeTableRows
  ): IColumnConfig => {
    const dropdownList = headerList.map((list, i) => {
      list.label = list.name;
      list.value = list.id;
      list.position = i;

      return list;
    });

    const dropdownConfig = {
      id: newSelectedFields[0]?.id,
      body: { optionList: dropdownList },
      field: {
        onChange: (_value, fullInfo) => {
          const newFieldInfo = {
            id: fullInfo.option.value,
            name: fullInfo.option.label,
            position: fullInfo.option.position,
          };

          /**
           * Проверка на то, что юзер выбрал 2 одинаковых участка.
           */
          if (fullInfo.option.label === newSelectedFields[1].name) {
            changeTableRows([newFieldInfo, { ...newSelectedFields[0] }]);
          } else {
            changeTableRows([newFieldInfo, newSelectedFields[1]]);
          }
        },
        placeholder: `Участок 1`,
        defaultValue: {
          label: newSelectedFields[0]?.name,
          value: newSelectedFields[0]?.id,
          position: newSelectedFields[0]?.position,
        },
        type: {
          search: {
            options: {
              isAllowNewValueToBeAdded: false,
            },
          },
        },
      },
      visual: {
        isPreventCloseAfterSelect: true,
        isRounded: true,
      },
      styles: {
        fieldPadding: '6px 12px',
        borderRadius: '8px',
      },
      other: { dataTestId: `field1` },
    };

    return {
      id: newSelectedFields[0]?.id,
      width: '165px',
      customRenderConfig: {
        render: (_column, dataTestId) => (
          <Styled.FirstDropdownWrapper>
            <NewDropdown config={dropdownConfig} data-test-id={dataTestId} />
          </Styled.FirstDropdownWrapper>
        ),
      },
    };
  };

  protected createSecondFieldColumnConfig = (
    headerList,
    newSelectedFields,
    changeTableRows
  ): IColumnConfig => {
    const dropdownList = headerList.map((list, i) => {
      list.label = list.name;
      list.value = list.id;
      list.position = i;

      return list;
    });

    const dropdownConfig = {
      id: newSelectedFields[1]?.id,
      body: { optionList: dropdownList },
      field: {
        onChange: (_value, fullInfo) => {
          const newFieldInfo = {
            id: fullInfo.option.value,
            name: fullInfo.option.label,
            position: fullInfo.option.position,
          };

          /**
           * Проверка на то, что юзер выбрал 2 одинаковых участка.
           */
          if (fullInfo.option.label === newSelectedFields[0].name) {
            changeTableRows([{ ...newSelectedFields[1] }, newFieldInfo]);
          } else {
            changeTableRows([newSelectedFields[0], newFieldInfo]);
          }
        },
        placeholder: `Участок 2`,
        defaultValue: {
          label: newSelectedFields[1]?.name,
          value: newSelectedFields[1]?.id,
          position: newSelectedFields[1]?.position,
        },
        type: {
          search: {
            options: {
              isAllowNewValueToBeAdded: false,
            },
          },
        },
      },
      visual: {
        isPreventCloseAfterSelect: true,
        isRounded: true,
      },
      styles: {
        fieldPadding: '6px 12px',
        borderRadius: '8px',
      },
      other: { dataTestId: `field2` },
    };

    return {
      id: newSelectedFields[1]?.id,
      width: '200px',
      customRenderConfig: {
        render: (_column, dataTestId) => (
          <Styled.SecondDropdownWrapper>
            <NewDropdown config={dropdownConfig} data-test-id={dataTestId} />
          </Styled.SecondDropdownWrapper>
        ),
      },
    };
  };
}

export default PairWiseComparisonColumnsService;
