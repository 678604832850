import { FC, memo } from 'react';

import { ContentLoader } from '../../../../../../../../../common/features/UI/loaders/ContentLoader';
import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './ExecutionContentLoader.styles';

const ExecutionContentLoader: FC = () => {
  const getDataTestId = useDataTestIdV2('execution');

  return (
    <Styled.Wrapper {...getDataTestId('content-loader-wrapper')}>
      <ContentLoader
        overlayStyles={{
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
        dataTestId={getDataTestId('content-loader')['data-test-id']}
      />
    </Styled.Wrapper>
  );
};

ExecutionContentLoader.displayName = 'ExecutionContentLoader';

export default memo(ExecutionContentLoader);
