import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { ChatJournal } from '../../../../../../../../../common/features/ChatJournal';
import { SpinnerLoader } from '../../../../../../../../../common/components/ui/loaders';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { ChatJournalController } from '../../../../../../../../../common/features/ChatJournal/mobx/controllers';
import {
  EMessageStyleType,
  EMessageType,
} from '../../../../../../../../../common/features/ChatJournal/models/common';
import { ProfileStore } from '../../../../../../../../../common/mobx/stores/ProfileStore';
import { useChat } from '../../../../../../../../../common/features/ChatJournal/hooks';
import { StatusDropdown } from '../../components';
import {
  EExperimentStatus,
  EExperimentStepStatus,
} from '../../../../../../../../../../api/models/as-fields/experiments';
import { IExperimentChangeStatusAvailability } from '../../../../../../../../../../api/models/as-fields/experiments/Experiment';
import { IChatMessage } from '../../../../../../../../../common/features/ChatJournal/models';
import { EExperimentApprovalStatus } from '../../../../../../../../../../api/models/as-fields/experiments/Experiment/Experiment.model';
import { TApiResponse } from '../../../../../../../../../common/mobx/services/axios/AxiosService/Axios.service.types';
import { EExperimentStepApprovalStatus } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentComment';
import { AccessStore } from '../../../../../../../../../common/mobx/stores/AccessStore';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';
import { EContextualParentPath } from '../../../../../../../../../common/constants/contextualPath';

import Styled from './Approval.styles';

type TDataFetchGeneric = 'getExperimentComments' | 'getExperimentStepEventList';
type TSendMessageGeneric = 'postExperimentComment' | 'postExperimentStepComment';
type TProps<T extends TDataFetchGeneric, K extends TSendMessageGeneric> = PropsWithChildren<
  IApprovalProps<T, K>
>;

interface IApprovalProps<T extends TDataFetchGeneric, K extends TSendMessageGeneric> {
  entityApproveId: string;
  entityApproveStatus: EExperimentStatus | EExperimentStepStatus;
  entityAvailabilityScheme: Partial<Record<keyof IExperimentChangeStatusAvailability, boolean>>;
  statusScheme: 'experiment' | 'observer';
  contextHelpIconConfig: {
    parentPath: string;
    path: string;
  };

  sendMessageHandler: (entityId: string, message: string) => Promise<TApiResponse<K>>;
  fetchMessageListHandler: (entityId: string, typeEvent?: any) => Promise<TApiResponse<T>>;
  parseEntityToMessageHandler: (entity: any, isAuthor?: boolean, isAudit?: boolean) => IChatMessage;
  changeStatusHandler: (
    entityId: string,
    status: EExperimentApprovalStatus | EExperimentStepApprovalStatus
  ) => Promise<any>;
  /**
   * Установить данные сущности.
   *
   * Если `changeStatusHandler` возвращает новый entity, его можно установить с помощью этого метода.
   */
  setOnChangeEntityData?: (any) => void;
}

const Approval = <T extends TDataFetchGeneric, K extends TSendMessageGeneric>({
  entityApproveId,
  entityApproveStatus,
  entityAvailabilityScheme,
  contextHelpIconConfig,
  statusScheme,
  changeStatusHandler,
  fetchMessageListHandler,
  parseEntityToMessageHandler,
  sendMessageHandler,
  setOnChangeEntityData,
}: TProps<T, K>) => {
  const accessStore = useStore(AccessStore);
  const { user, fetchProfile } = useStore(ProfileStore);
  const { addMessage } = useChat();
  const { addMessageListToChat } = useStore(ChatJournalController);

  usePageContextualHelp(contextHelpIconConfig.parentPath as EContextualParentPath);
  const helpActions = useContextualHelpActions();

  const [isLoading, setIsLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<EExperimentStatus | EExperimentStepStatus>(
    EExperimentStatus.InApproval
  );

  const onSubmit = useCallback(
    (value: string) => {
      sendMessageHandler(entityApproveId, value.trim()).then(() => {
        addMessage(entityApproveId, [
          {
            author: user.fullName || `${user?.firstName} ${user?.lastName}`,
            message: value.trim(),
            messageStyle: EMessageStyleType.Info,
            messageType: EMessageType.Message,
            sendingDate: new Date(),
            isAuthor: true,
          },
        ]);
      });

      return false;
    },
    [entityApproveId, user?.id]
  );

  const getEntityToMessage = (event, isAuthor) => {
    const isAudit = statusScheme === 'observer';

    return parseEntityToMessageHandler(event, isAuthor, isAudit);
  };

  const fetchComments = useCallback((experimentId, userId) => {
    fetchMessageListHandler(experimentId)
      .then(res => {
        addMessageListToChat(
          experimentId,
          res.map(event => getEntityToMessage(event, event.createdBy.id === userId))
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!user) {
      fetchProfile();
    }

    if (entityApproveId && user) {
      setCurrentStatus(entityApproveStatus);
      setIsLoading(true);

      fetchComments(entityApproveId, user.id);
    }
  }, [entityApproveId, user?.id]);

  const onChangeStatus = useCallback(
    (value, eventType) => {
      if (entityApproveId && eventType) {
        return changeStatusHandler(entityApproveId, eventType)
          .then(res => {
            setOnChangeEntityData(res);
            setCurrentStatus(value);

            fetchComments(entityApproveId, user.id);
          })
          .catch(() => setCurrentStatus(currentStatus));
      }
    },
    [entityApproveId, user?.id, currentStatus]
  );

  const isChatJournalDisabled =
    currentStatus === EExperimentStatus.FullCompleted || !accessStore?.isAllowToEditExperiments;

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    contextHelpIconConfig.parentPath,
    contextHelpIconConfig.path
  );

  return (
    <Styled.Container
      headerChildren={
        <Styled.HeaderWrapper>
          <Styled.Title>Согласование</Styled.Title>
          {ContextualHelpIcon ? ContextualHelpIcon : null}

          <StatusDropdown
            value={currentStatus}
            onChange={onChangeStatus}
            availabilityScheme={entityAvailabilityScheme}
            statusScheme={statusScheme}
          />
        </Styled.HeaderWrapper>
      }
      contentHeight={'88%'}
      wrapperPadding={'24px 24px 0'}
    >
      {isLoading ? (
        <SpinnerLoader needToHideContent={false} />
      ) : (
        <ChatJournal
          chatInstanceId={entityApproveId}
          onSubmit={onSubmit}
          isReadOnly={isChatJournalDisabled}
        />
      )}
    </Styled.Container>
  );
};

Approval.displayName = 'Approval';

export default observer(Approval);
