import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { IComparisonTableBuilderConfig } from '../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import { EComparisonTableName } from '../../../../../../../../../constants/features';
import { ComparisonHeader as Header } from '../../../components';
import { CommonComparisonRowsService, CommonComparisonColumnsService } from '../common';
import { PairWiseComparisonColumnsService, PairWiseComparisonRowsService } from '../pairWise';
import { IExperimentPlanTable } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentPlanTable';
import { TComparisonTabs } from '../../../models';
import { createComparisonTableBuilderId as createTableBuilderId } from '../../../utils/helpers/createComparisonTableBuilderId';

@provide.transient()
class ComparisonService {
  @lazyInject(CommonComparisonColumnsService)
  protected commonColumnsService: CommonComparisonColumnsService;

  @lazyInject(CommonComparisonRowsService)
  protected commonRowsService: CommonComparisonRowsService;

  @lazyInject(PairWiseComparisonColumnsService)
  protected pairWiseColumnsService: PairWiseComparisonColumnsService;

  @lazyInject(PairWiseComparisonRowsService)
  protected pairWiseRowsService: PairWiseComparisonRowsService;

  createTableConfig = (
    { headers, comparisonTables }: IExperimentPlanTable,
    activeTab: TComparisonTabs,
    changeTableRows?: (value) => void,
    newSelectedFields?: Array<{ id: string; name: string; position: number }>
  ): IComparisonTableBuilderConfig => {
    const config: IComparisonTableBuilderConfig = {
      id: createTableBuilderId(activeTab, EComparisonTableName.Comparison),
      name: createTableBuilderId(activeTab, EComparisonTableName.Comparison),
      headerConfig: {
        customRenderConfig: {
          render: (header, columnsChildren, dataTestId) => (
            <Header dataTestId={dataTestId}>{columnsChildren}</Header>
          ),
        },
      },
      columnConfigList:
        activeTab === 'common'
          ? this.commonColumnsService.createColumnConfigList(headers)
          : this.pairWiseColumnsService.createColumnConfigList(
              headers,
              newSelectedFields,
              changeTableRows
            ),
      rowsGroupConfigList:
        activeTab === 'common'
          ? this.commonRowsService.createBlockRowsGroupConfigList(comparisonTables)
          : this.pairWiseRowsService.createBlockRowsGroupConfigList(
              comparisonTables,
              newSelectedFields
            ),
    };

    config.cellConfigs =
      activeTab === 'common'
        ? this.commonRowsService.cellConfigs
        : this.pairWiseRowsService.cellConfigs;

    return config;
  };
}

export default ComparisonService;
