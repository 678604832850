import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../utils/helpers/mobx';
import { IShowErrorModal } from '../../../../utils/hooks/useErrorModal';

@provide.singleton()
class GlobalService {
  private _showErrorModalHandler: IShowErrorModal;

  constructor() {
    makeAutoObservable(this);
  }

  get showErrorModalHandler() {
    return this._showErrorModalHandler;
  }

  setShowErrorModalHandler = (handler: IShowErrorModal) => {
    this._showErrorModalHandler = handler;
  };
}

export default GlobalService;
