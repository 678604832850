import { observer } from 'mobx-react';

import { useStore } from '../../../utils/helpers/mobx';
import { UiStore } from '../../../../dashboard/mobx/store/ui.store';

import Styled from './DeviceScreenDummy.style';
// @ts-ignore
import dummy from './assets/tablets.png';

const DeviceScreenDummy = observer(() => {
  const ui = useStore(UiStore);

  if (ui.pageWidth > 1023) {
    return null;
  }

  return (
    <Styled.DeviceScreenDummyElem data-test-id="device-screen-dummy">
      <img src={dummy} alt="" />
      Для продолжения работы
      <br />
      поверните устройство
      <br />в горизонтальную ориентацию
    </Styled.DeviceScreenDummyElem>
  );
});

export default DeviceScreenDummy;
