import { IExperimentStep } from '../../../models/as-fields/experiments';
import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';

type TGetExperimentStepReq = {
  techOperationTypeIdIn?: string[];
  statusIn?: string[];
  experimentIdIn?: string[];
  assigneeIdIn?: string[];
  organizationIdIn?: string[];
  type?: string;
};

type TGetExperimentStepRes = TResponseList<IExperimentStep>;

export const getExperimentStepCompleteList: TApiRoute & {
  request: TGetExperimentStepReq;
  response: TGetExperimentStepRes;
} = {
  url: () => `/api/as-fields/experimentSteps/completeList`,
  method: 'POST',
  request: {} as TGetExperimentStepReq,
  response: {} as TGetExperimentStepRes,
  headers: {},
};
