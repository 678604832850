import { forwardRef } from 'react';
import { observer } from 'mobx-react';

import { ITableBuilderRowsGroup as IRowsGroup } from '../../../models/data/TableBuilderRowsGroup';
import { TableBuilderRowsGroupAutoRenderContainer as RowsGroupAutoRenderContainer } from '../TableBuilderRowsGroupAutoRenderContainer';
import { TableBuilderRowsGroupCustomRenderContainer as RowsGroupCustomRenderContainer } from '../TableBuilderRowsGroupCustomRenderContainer';

interface IProps {
  rowsGroup: IRowsGroup;
  borderType: string;
  scrollPadding?: string;
  isHideScroll?: boolean;
}

const TableBuilderRowsGroupContainer = forwardRef<HTMLDivElement, IProps>(
  ({ rowsGroup, borderType, scrollPadding, isHideScroll }, ref) => {
    switch (rowsGroup.renderType || 'auto') {
      case 'auto':
        return (
          <RowsGroupAutoRenderContainer
            ref={ref}
            rowsGroup={rowsGroup}
            borderType={borderType}
            scrollPadding={scrollPadding}
            isHideScroll={isHideScroll}
          />
        );

      case 'custom':
        return (
          <RowsGroupCustomRenderContainer
            ref={ref}
            rowsGroup={rowsGroup}
            borderType={borderType}
            scrollPadding={scrollPadding}
            isHideScroll={isHideScroll}
          />
        );

      default:
        return <></>;
    }
  }
);

TableBuilderRowsGroupContainer.displayName = 'TableBuilderRowsGroupContainer';

export default observer(TableBuilderRowsGroupContainer);
