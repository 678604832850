import { useEffect } from 'react';
import { useSidebar } from '@farmlink/farmik-ui';

import { TableFiltersBuilderController } from '../../mobx/controllers';
import { useStore } from '../../../../utils/helpers/mobx';

/**
 * Дополнительный конфиг для настройки хука.
 */
interface IConfig {
  /**
   * Следит за сайдбаром.
   */
  isNeedToCheckSidebar?: boolean;
}

const useTableFiltersResize = (builderId: string, config?: IConfig): void => {
  const controller = useStore(TableFiltersBuilderController);

  const sidebarActions = useSidebar();

  useEffect(() => {
    if (config?.isNeedToCheckSidebar) {
      controller.activateResizeAnimation(builderId);

      setTimeout(() => {
        controller.resizeFilters(builderId);
        // 300 — длительность анимации сайдбара.
      }, 300);
    }
  }, [builderId, sidebarActions.expanded, config]);
};

export default useTableFiltersResize;
