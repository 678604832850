import { EExperimentStepType } from '../../../../models/as-fields/experiments/ExperimentStep/ExperimentStep.model';
import { INutritionHistory } from '../../../../models/as-fields/plan/NutrationHistory';
import { TResponseList } from '../../../../models/common/response';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetNutritionHistoryListReg = {} & {
  cultureZoneId?: string;
  cultureZoneExperimentId?: string;
  experimentId?: string;
  experimentStepType?: EExperimentStepType.NutritionHistory | EExperimentStepType.Plan;
};
type TGetNutritionHistoryListRes = {} & TResponseList<INutritionHistory>;

const getNutritionHistoryList: TApiRoute & {
  request: TGetNutritionHistoryListReg;
  response: TGetNutritionHistoryListRes;
} = {
  url: () => `/api/as-fields/nutrition-history/list`,
  method: 'GET',
  request: {} as TGetNutritionHistoryListReg,
  response: {} as TGetNutritionHistoryListRes,
  headers: {},
};

export { getNutritionHistoryList };
export type { TGetNutritionHistoryListReg, TGetNutritionHistoryListRes };
