import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import { TApiRequest, TApiResponse } from '../../axios/AxiosService/Axios.service.types';

@provide.singleton()
class ReferencesService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getReferenceList = async (
    payload: TApiRequest<'getReferenceList'>
  ): Promise<TApiResponse<'getReferenceList'>> => {
    try {
      const response = await this.axiosService.api.getReferenceList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default ReferencesService;
