import { FC, useMemo } from 'react';
import { AutoTooltip, useContextualHelpActions, useShowTooltip } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';

import Styled from './TotalComparisonColumn.styles';

interface IProps {
  name: string;
  dataTestId: string;
  isMain?: boolean;
}

const TotalComparisonColumn: FC<IProps> = ({ name, dataTestId, isMain }) => {
  const { ref: nameRef, isShowTooltip: isShowNameTooltip } = useShowTooltip<HTMLDivElement>();

  const getDataTestId = useDataTestIdV2(dataTestId);

  const referenceList = usePageContextualHelp(
    EContextualParentPath.ExperimentsExperimentTotalComparison
  );
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = useMemo(() => {
    if (name === 'Показатели') {
      return helpActions.getContextualHelpIcon(
        EContextualParentPath.ExperimentsExperimentTotalComparison,
        ContextualPaths.ExperimentsExperimentTotalComparisonStepsTableIndicators
      );
    }
  }, [name, referenceList, helpActions]);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.FirstTitleWrapper>
        <AutoTooltip content={name} disabled={!isShowNameTooltip}>
          <Styled.Name ref={nameRef} {...getDataTestId('name')}>
            {name}
          </Styled.Name>
        </AutoTooltip>
        {ContextualHelpIcon}
      </Styled.FirstTitleWrapper>
      {!isMain ? (
        <Styled.Description {...getDataTestId('description')}>
          <Styled.FactLabel {...getDataTestId('description__fact')}>факт</Styled.FactLabel>

          <Styled.PlanLabel {...getDataTestId('description__plan')}>(план)</Styled.PlanLabel>
        </Styled.Description>
      ) : null}
    </Styled.Wrapper>
  );
};

TotalComparisonColumn.displayName = 'TotalComparisonColumn';

export default TotalComparisonColumn;
