import { AxiosError } from 'axios';

import { lazyInject, provide } from '../../../../../../../common/utils/helpers/mobx';
import { AuditStore } from '../store';
import {
  IExperiment,
  IExperimentStep,
} from '../../../../../../../../api/models/as-fields/experiments';
import {
  ExperimentService,
  ExperimentStepsService,
} from '../../../../../../../common/mobx/services/as-fields';
import {
  EExperimentStepApprovalStatus,
  EExperimentStepEventType,
} from '../../../../../../../../api/models/as-fields/experiments/ExperimentComment';
import {
  TUpdateExperimentStepReq,
  TUpdateExperimentStepFilesReq,
} from '../../../../../../../../api';

@provide.singleton()
class AuditController {
  @lazyInject(AuditStore)
  protected auditStore: AuditStore;

  @lazyInject(ExperimentService)
  expService: ExperimentService;

  @lazyInject(ExperimentStepsService)
  protected expStepsService: ExperimentStepsService;

  fetchAudit = async (id: string): Promise<IExperimentStep> => {
    const { setSelectedAudit } = this.auditStore;
    const { getExperimentStep } = this.expStepsService;

    const fetchedAudit = await getExperimentStep({ id });

    if (fetchedAudit) {
      setSelectedAudit(fetchedAudit);

      return fetchedAudit;
    }
  };

  fetchExp = async (id: string): Promise<IExperiment> => {
    const { setSelectedExp } = this.auditStore;
    const { getExperiment } = this.expService;

    const fetchedExp = await getExperiment({ id });

    if (fetchedExp instanceof AxiosError) {
      return undefined;
    } else if (fetchedExp) {
      setSelectedExp(fetchedExp);

      return fetchedExp;
    }
  };

  fetchAuditAndExp = async (auditId: string): Promise<void> => {
    if (auditId === 'create') {
      return;
    }

    const fetchedAudit = await this.fetchAudit(auditId);

    if (fetchedAudit) {
      await this.fetchExp(fetchedAudit.experiment.id);
    }
  };

  fetchAuditEvents = (stepId: string, typeEvent: EExperimentStepEventType) => {
    return this.expStepsService.fetchChecklistCommentList(stepId, typeEvent);
  };

  postAuditApprovalComment = (stepId: string, message: string) => {
    return this.expStepsService.postComment(stepId, message);
  };

  changeExperimentStepApprovalStatus = (stepId: string, status: EExperimentStepApprovalStatus) => {
    return this.expStepsService.changeStepStatus(stepId, status);
  };

  updateExperimentStep = (payload: TUpdateExperimentStepReq, returnFullData?: boolean) => {
    return this.expStepsService.updateExperimentStep(payload, returnFullData);
  };

  updateExperimentStepFiles = (payload: TUpdateExperimentStepFilesReq) => {
    return this.expStepsService.updateExperimentStepFiles(payload);
  };
}

export default AuditController;
