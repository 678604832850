import styled, { css } from 'styled-components';

import { TMenuPosition } from './ActionButtonsComponent.type';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type TStyledDropdownIconsArrowProps = {
  $isDropped: boolean;
  $arrowDownDefaultSvg: string;
  $arrowDownHoverSvg: string;
  $arrowTopHoverSvg: string;
};

const ArrowIcon = styled.div<TStyledDropdownIconsArrowProps>`
  width: 16px;
  height: 16px;
  cursor: pointer;

  ${({ $isDropped, $arrowTopHoverSvg, $arrowDownDefaultSvg, $arrowDownHoverSvg }) =>
    $isDropped
      ? css`
          background: url(${$arrowTopHoverSvg});
        `
      : css`
          background: url(${$arrowDownDefaultSvg});

          :hover {
            background: url(${$arrowDownHoverSvg});
          }
        `}
`;

type TStyledMenuIconProps = {
  $isOpen: boolean;
  $defaultMenuIconSvg: string;
  $activeMenuIconSvg: string;
};

const MenuIcon = styled.div<TStyledMenuIconProps>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 16px;

  ${({ $isOpen, $defaultMenuIconSvg, $activeMenuIconSvg }) =>
    $isOpen
      ? css`
          background: url(${$activeMenuIconSvg});
        `
      : css`
          background: url(${$defaultMenuIconSvg});

          :hover {
            background: url(${$activeMenuIconSvg});
          }
        `}
`;

type TMenuProps = {
  $isOpen: boolean;
  $position: TMenuPosition;
};

const Menu = styled.div<TMenuProps>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  overflow: auto;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15)) drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
  z-index: 99999;
  border-radius: 16px;
  ${({ $position }) =>
    $position &&
    css`
      left: ${$position[0]}px;
      top: ${$position[1]}px;
    `}
  margin-top: 37px;
  margin-left: -160px;
`;

const OptionWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #e9e6f0;
  margin: 0 16px;
  cursor: pointer;
`;

const MainWrapper = styled.div`
  &:hover {
    background: #faf9fd;
  }
`;

type TOptionTextProps = {
  $textColor: string;
};

const OptionText = styled.div<TOptionTextProps>`
  color: ${({ $textColor }) => $textColor};
  padding: 16px 16px 16px 13px;
  display: block;
`;

type TOptionIconProps = {
  $optionIconSvg: string;
};

const OptionIcon = styled.div<TOptionIconProps>`
  width: 16px;
  height: 16px;
  margin-top: 19px;
  ${({ $optionIconSvg }) =>
    css`
      background: url(${$optionIconSvg});
    `};
`;

type TAddIconProps = {
  $addIconSvg: string;
};

const AddIcon = styled.div<TAddIconProps>`
  width: 16px;
  height: 16px;
  margin-right: 16px;
  cursor: pointer;
  ${({ $addIconSvg }) =>
    css`
      background: url(${$addIconSvg});
    `};
`;

const StyledActionButtonsComponent = {
  ButtonsWrapper,
  ArrowIcon,
  MenuIcon,
  Menu,
  OptionText,
  OptionIcon,
  OptionWrapper,
  MainWrapper,
  AddIcon,
};

export default StyledActionButtonsComponent;
