import styled, { css } from 'styled-components';

const textFormatNormal = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
`;

const textFormatBold = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

const textDisplay = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  max-width: 50%;
`;

const PlanLabel = styled.div<{ $isBold: boolean }>`
  ${({ $isBold }) => ($isBold ? textFormatBold : textFormatNormal)};

  ${textDisplay};

  color: #7e8b9d;
`;

const FactLabel = styled.div<{ $isBold: boolean }>`
  ${({ $isBold }) => ($isBold ? textFormatBold : textFormatNormal)};

  ${textDisplay};

  color: #151f32;
`;

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 4px;

  padding: 12px 0;
`;

const StyledTotalComparisonComparableCell = {
  PlanLabel,
  FactLabel,
  Wrapper,
};

export default StyledTotalComparisonComparableCell;
