import React, { FC, MouseEvent, useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { isUndefined } from 'lodash';
import { ButtonLink, TButtonLinkOnClick } from '@farmlink/farmik-ui';

import { TableFiltersBuilderController as Controller } from '../../../mobx/controllers';
import { useTableFiltersBuilderTagsUIState as useUIState } from '../../../utils/hooks/states';
import { TableFiltersBuilderTagContainer as TagContainer } from '../TableFiltersBuilderTagContainer';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { useTableFiltersBuilderUIContext as useUIContext } from '../../../context/UI/TableFiltersBuilderUIContext/TableFiltersBuilderUI.context';
import { useStore } from '../../../../../utils/helpers/mobx';

import Styled from './TableFiltersBuilderTags.styles';

const TableFiltersBuilderTags: FC = () => {
  const controller = useStore(Controller);

  const UIContext = useUIContext();
  const UIState = useUIState(UIContext.builderId);

  const tagsWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const tagsOffsetWidth = tagsWrapperRef?.current?.offsetWidth;

    if (!isUndefined(tagsOffsetWidth)) {
      UIState.setTagsWrapperWidth(tagsOffsetWidth);
    }
  }, [tagsWrapperRef?.current?.offsetWidth]);

  const handleClearAllButtonClick = useCallback<TButtonLinkOnClick>(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      controller.removeAllValues(UIContext.builderId);
    },
    []
  );

  const handleCollapseButtonClick = useCallback<TButtonLinkOnClick>(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      UIState.setIsShowHiddenTags(false);
    },
    []
  );

  const handleShowHiddenTagsButtonClick = useCallback<TButtonLinkOnClick>(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      UIState.setIsShowHiddenTags(true);
    },
    []
  );

  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper {...getDataTestId('tags')}>
      <Styled.TagList
        id={'tags-wrapper'}
        ref={tagsWrapperRef}
        $isShowHiddenTags={UIState.isShowHiddenTags}
        {...getDataTestId('tag-list')}
      >
        {UIState.availableTagList.map(tag => {
          const isTheLastElement =
            UIState.availableTagList[UIState.availableTagList.length - 1].id === tag.id;

          const isShowHiddenTagsButton =
            isTheLastElement && !UIState.isShowHiddenTags && Boolean(UIState.tagListToHide.length);

          return (
            <TagContainer
              key={tag.id}
              tag={tag}
              isShowHiddenTagsButton={isShowHiddenTagsButton}
              showHiddenTagsButtonText={String(UIState.tagListToHide.length)}
              onShowHiddenTagsButtonClick={handleShowHiddenTagsButtonClick}
            />
          );
        })}
      </Styled.TagList>

      <Styled.ButtonsWrapper {...getDataTestId('buttons-wrapper')}>
        {UIState.isShowHiddenTags && UIState.tagListToHide.length ? (
          <ButtonLink
            color={'secondary'}
            onClick={handleCollapseButtonClick}
            {...getDataTestId('hide-tags-button')}
          >
            Свернуть
          </ButtonLink>
        ) : null}

        <ButtonLink
          color={'critical'}
          onClick={handleClearAllButtonClick}
          dataTestId={`${UIContext.builderId}-table-filters-clear-filters-button`}
        >
          Очистить
        </ButtonLink>
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  );
};

TableFiltersBuilderTags.displayName = 'TableFiltersBuilderTags';

export default observer(TableFiltersBuilderTags);
