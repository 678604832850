import ETaskStatus from './taskStatusList.enum';

const tasksStatusListOptions = [
  { label: 'Новая', value: ETaskStatus.New, type: 'status' },
  { label: 'В работе', value: ETaskStatus.InWork, type: 'status' },
  { label: 'Завершена', value: ETaskStatus.Completed, type: 'status' },
  { label: 'Отменена', value: ETaskStatus.Canceled, type: 'status' },
];

export default tasksStatusListOptions;
