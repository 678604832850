import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../../models/common/response';
import { IExperimentContract } from '../../../../models/as-fields/experiments';

type TGetExperimentContractFullListReq = {
  name?: string;
  organizationId?: string;
  responsibleCustomerId?: string;
  responsibleContractorId?: string;
  seasonYear?: number;
  startDateFrom?: string;
  startDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  size?: number;
  page?: number;
};

type TGetExperimentContractFullListRes = TResponseList<IExperimentContract>;

const getExperimentContractFullList: TApiRoute & {
  request: TGetExperimentContractFullListReq;
  response: TGetExperimentContractFullListRes;
} = {
  url: () => `/api/as-fields/experiment/contract/list`,
  method: 'GET',
  request: {} as TGetExperimentContractFullListReq,
  response: {} as TGetExperimentContractFullListRes,
  headers: {},
};

export type { TGetExperimentContractFullListReq, TGetExperimentContractFullListRes };

export { getExperimentContractFullList };
