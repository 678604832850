import { FC, memo } from 'react';

import Styled from './TableHeader.styles';
import { TTableHeaderProps } from './TableHeader.types';

const TableHeaderComponent: FC<TTableHeaderProps> = ({ tableHeadData, existHeaderBorder }) => {
  return (
    <Styled.HeaderRowWrapper
      existHeaderBorder={existHeaderBorder}
      data-test-id="table-header-row-wrapper"
    >
      {tableHeadData.map(headItem => (
        <Styled.HeaderCellWrapper
          existHeaderBorder={existHeaderBorder}
          key={headItem.field}
          title={headItem.headerName}
          $columnWidth={headItem.width}
          data-test-id={`table-header-cell-wrapper-${headItem.field}`}
        >
          {headItem.headerName}
        </Styled.HeaderCellWrapper>
      ))}
    </Styled.HeaderRowWrapper>
  );
};

const TableHeader = memo(TableHeaderComponent);

TableHeader.displayName = 'TableHeader';

export default TableHeader;
