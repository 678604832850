import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../../models/common/response';
import { IExperimentContract } from '../../../../models/as-fields/experiments';

type TRequest = {
  organizationIdIn?: string[];
};

type TResponse = TResponseList<IExperimentContract>;

/**
 * Новый метод, которые в последствии должен стать основным.
 */
export const newGetExperimentContractList: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/experiment/contract/list`,
  method: 'POST',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};
