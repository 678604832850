import { FC } from 'react';
import { observer } from 'mobx-react';

import { AttributeValueComponentProps } from '../../../../../../../../../common/features/DynamicTable/models/AttributeValue/AttributeValue.model';
import { CellComponentWithTooltip } from '../../../../../../../../../common/components/ui';
import { formatNumValue } from '../../../../../../../../../common/utils/helpers/numbers';

const ActiveSubstanceAttrVal: FC<AttributeValueComponentProps> = ({ attributeValue }) => {
  const { instanceId, value } = attributeValue;

  return (
    <CellComponentWithTooltip
      id={instanceId}
      cellInfo={value.toString()}
      showOnlyChildren={true}
      forceShowTooltip={true}
    >
      {formatNumValue(value)}
    </CellComponentWithTooltip>
  );
};

ActiveSubstanceAttrVal.displayName = 'ActiveSubstanceAttrVal';

export default observer(ActiveSubstanceAttrVal);
