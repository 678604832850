import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../assets/styles/colors';
import StyledAttribute from '../Attribute/Attribute.styles';

const { GeneralBlack } = ECommonColor;

const titleStageNamePreset = css`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const nestedStageNamePreset = css`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const titleStageHeaderPreset = css`
  padding-bottom: 16px;
  border-bottom: 1px solid ${GeneralBlack};
  margin-bottom: 16px;
`;

const nestedStageHeaderPreset = css`
  padding-bottom: 12px;
  border: none;
  margin: 0;
`;

const Name = styled.div`
  color: ${GeneralBlack};
`;

const Header = styled.div<{ $cellPadding: string }>`
  padding: ${({ $cellPadding }) => ($cellPadding ? $cellPadding : 0)};
`;

const Wrapper = styled.div<{ $isNested: boolean }>`
  ${({ $isNested }) => css`
    & ${Name} {
      ${$isNested ? nestedStageNamePreset : titleStageNamePreset}
    }

    & ${Header} {
      ${$isNested ? nestedStageHeaderPreset : titleStageHeaderPreset}
    }
  `}
`;

const Body = styled.div`
  & ${StyledAttribute.Wrapper}:not(:last-child) {
    margin-bottom: 12px;
  }

  & ${Wrapper}:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const StyledStage = {
  Name,
  Header,
  Body,
  Wrapper,
};

export default StyledStage;
