import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { IExaminationAttributeMethod } from '../../../models/aho/examination-attribute-method/ExaminationAttributeMethod/ExaminationAttributeMethod.model';

type TGetExaminationAttributeMethodsReq = {
  examinationAttributeId: string;
  name?: string;
};

type TGetExaminationAttributeMethodsRes = {} & TResponseList<IExaminationAttributeMethod>;

const getExaminationAttributeMethods: TApiRoute & {
  request: TGetExaminationAttributeMethodsReq;
  response: TGetExaminationAttributeMethodsRes;
} = {
  url: () => `/api/as-fields/aho/examination-attribute-methods/list`,
  method: 'POST',
  request: {} as TGetExaminationAttributeMethodsReq,
  response: {} as TGetExaminationAttributeMethodsRes,
  headers: {},
};

export type { TGetExaminationAttributeMethodsReq, TGetExaminationAttributeMethodsRes };

export { getExaminationAttributeMethods };
