import { Button } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { Container } from '../../../../../../../../../common/features/UI';

const Wrapper = styled.div`
  & > :last-child {
    margin-bottom: 100px;
  }
`;

const StyledContainer = styled(Container)`
  width: 912px;
  margin: 24px auto 0;
`;

const ButtonWrapper = styled(Button)`
  margin-top: 40px;
  width: 300px;
  display: flex;
  justify-content: center;
`;

const StyledZones = {
  ButtonWrapper,
  Container: StyledContainer,
  Wrapper,
};

export default StyledZones;
