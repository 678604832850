import styled from 'styled-components';

const PageContentWrapper = styled.div`
  width: 100%;
`;

const StyledPageContent = {
  PageContentWrapper,
};

export default StyledPageContent;
