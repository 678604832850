import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const StyledTaskReportInstanceHeader = {
  Wrapper,
};

export default StyledTaskReportInstanceHeader;
