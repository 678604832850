import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TDeleteNutritionHistoryProtectionItemReq = { experimentStepId: string; protectionId: string };
type TDeleteNutritionHistoryProtectionItemRes = {} & { id: string };

const deleteNutritionHistoryProtectionItem: TApiRoute & {
  request: TDeleteNutritionHistoryProtectionItemReq;
  response: TDeleteNutritionHistoryProtectionItemRes;
} = {
  url: ({ protectionId, experimentStepId }) =>
    `/api/as-fields/protection-item/experimentStep/${experimentStepId}/protection/${protectionId}`,
  method: 'DELETE',
  request: {} as TDeleteNutritionHistoryProtectionItemReq,
  response: {} as TDeleteNutritionHistoryProtectionItemRes,
  headers: {},
};

export { deleteNutritionHistoryProtectionItem };
export type { TDeleteNutritionHistoryProtectionItemReq, TDeleteNutritionHistoryProtectionItemRes };
