import { observer } from 'mobx-react';
import { FC, MouseEvent, memo, useCallback } from 'react';

import { useTableBuilderUIContext as useContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { TableBuilderGrid as Grid } from '../../TableBuilderGrid';
import { TableBuilderStore as Store } from '../../../mobx/stores';
import { ITableBuilderRow as IRow } from '../../../models/data';
import { TableBuilderCellContainer as CellContainer } from '../../cells/TableBuilderCellContainer';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { useStore } from '../../../../../utils/helpers/mobx';
import { useSortBy } from '../../../../../utils/hooks/useSortBy';

import Styled from './TableBuilderRowAutoRenderContainer.styles';

interface IProps {
  row: IRow;
}

const TableBuilderRowAutoRenderContainer: FC<IProps> = ({ row }) => {
  const store = useStore(Store);

  const context = useContext();

  const cellList = useSortBy(store.getCellList(context.builderId));

  const rowConfig = store.getRowConfig(context.builderId);

  const handleRowClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      rowConfig?.onRowClick?.(row, event);
    },
    [rowConfig?.onRowClick, row]
  );

  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper
      onClick={handleRowClick}
      $hasClickEvent={Boolean(rowConfig?.onRowClick)}
      {...getDataTestId('auto-row')}
      $preset={row?.autoRenderConfig?.preset}
    >
      <Grid>
        {cellList.map(cell => (
          <CellContainer key={cell.id} cell={cell} row={row} />
        ))}
      </Grid>
    </Styled.Wrapper>
  );
};

TableBuilderRowAutoRenderContainer.displayName = 'TableBuilderRow';

export default memo(observer(TableBuilderRowAutoRenderContainer));
