import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { ExperimentService } from '../../../../../../../../../../common/mobx/services/as-fields';
import { TotalComparisonStore } from '../../stores';
import { TotalComparisonService } from '../../services';
import { ComparisonTableBuilderController } from '../../../../../../../../../../common/features/ComparisonTableBuilder/mobx/controllers';

@provide.transient()
class TotalComparisonController {
  @lazyInject(TotalComparisonStore)
  protected totalComparisonStore: TotalComparisonStore;

  @lazyInject(ExperimentService)
  protected experimentsService: ExperimentService;

  @lazyInject(TotalComparisonService)
  protected totalComparisonService: TotalComparisonService;

  @lazyInject(ComparisonTableBuilderController)
  protected comparisonTableController: ComparisonTableBuilderController;

  initiateTables = async (experimentId: string): Promise<void> => {
    this.enablePageLoading();
    this.totalComparisonStore.clearNoData();
    const data = await this.experimentsService.getTotalComparisonTable({
      id: experimentId,
    });

    if (data) {
      this.totalComparisonStore.setHeaderList(data.headers);

      const config = this.totalComparisonService.createTableConfig(data);
      this.comparisonTableController.initiateTable(config);
    }

    this.totalComparisonStore.setNoData(!data);

    this.totalComparisonStore.setNoTechoperations(
      data?.comparisonTables?.filter(dataInfo => !dataInfo?.isEmpty)?.length <= 2
    );

    this.disablePageLoading();
  };

  enablePageLoading = (): void => {
    this.totalComparisonStore.setIsPageLoading(true);
  };

  disablePageLoading = (): void => {
    this.totalComparisonStore.setIsPageLoading(false);
  };

  clearStore = (): void => {
    this.totalComparisonStore.clearIsPageLoading();
    this.totalComparisonStore.clearHeadersByPosition();
  };
}

export default TotalComparisonController;
