import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const headerHeight = '155px';
const buttonHeight = '20px';
const wrapperPaddingBottom = '24px';
const tableWrapperMargin = '24px';

const Wrapper = styled.div`
  max-height: calc(
    100vh - ${headerHeight} - ${buttonHeight} - ${wrapperPaddingBottom} - ${tableWrapperMargin}
  );
  overflow: auto;
  margin: ${tableWrapperMargin} 0;
`;

const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  letter-spacing: -0.15px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.accentGreen};
  height: ${buttonHeight};
`;

const StyledDocumentsTab = {
  Wrapper,
  Button,
};

export default StyledDocumentsTab;
