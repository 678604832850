import styled, { css } from 'styled-components';

const getAlignStyle = $align => {
  let contentAlign = '0';

  if ($align) {
    switch ($align) {
      case 'left':
        contentAlign = 'start';
        break;
      case 'right':
        contentAlign = 'end';
        break;
      case 'center':
        contentAlign = 'center';
        break;
      default:
        break;
    }
  }

  return contentAlign;
};

interface IRowCellWrapper {
  $isNoOverFlow?: boolean;
  $align?: string;
  padding?: string;
  $removeRightMargin?: boolean;
}

const RowCellWrapper = styled.div<IRowCellWrapper>`
  padding: ${({ padding }) => padding ?? '16px 0 12px 0'};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - ${({ $removeRightMargin }) => ($removeRightMargin ? '0' : '16px')});

  ${({ $isNoOverFlow }) =>
    !$isNoOverFlow &&
    css`
      overflow: hidden;
    `}

  ${({ $align }) =>
    $align &&
    css`
      display: flex;
      justify-content: ${getAlignStyle($align)};
    `};

  div[data-tip='true'] {
    display: inline !important;
  }
`;

const TableCell = styled.td`
  &:last-child {
    ${RowCellWrapper} {
      margin: 0;
    }
  }
`;

const StyledTableBodyRow = {
  RowCellWrapper,
  TableCell,
};

export default StyledTableBodyRow;
