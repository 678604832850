import { FC, memo, useMemo } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, Icon } from '@farmlink/farmik-ui';

import { useShowTooltip } from '../../../../utils/hooks';
import { ITask } from '../../../../../../api/models/as-fields/task/Task';
import { EExperimentCultureZoneType } from '../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';

import Styled from './FieldCell.style';

type IFieldCellProps = {
  rowData: ITask;
};

const mapIconBySectionType = {
  [EExperimentCultureZoneType.Control]: 'controlField',
  [EExperimentCultureZoneType.Experiment]: 'experimentField',
};

const fieldTypeNameEnum = {
  [EExperimentCultureZoneType.Control]: 'Контрольный',
  [EExperimentCultureZoneType.Experiment]: 'Опытный',
};

const FieldCell: FC<IFieldCellProps> = ({ rowData }) => {
  const { ref, showTooltip } = useShowTooltip();

  const sectionIcon = useMemo(() => mapIconBySectionType[rowData?.cultureZoneExperiment?.type], []);
  const fieldTypeName = useMemo(() => fieldTypeNameEnum[rowData?.cultureZoneExperiment?.type], []);

  return (
    <Styled.Wrapper>
      {sectionIcon && (
        <AutoTooltip content={fieldTypeName} position="bottom">
          <Styled.FieldIcon>
            <Icon icon={sectionIcon} size={16} />
          </Styled.FieldIcon>
        </AutoTooltip>
      )}

      <AutoTooltip content={rowData?.field?.name} position="bottom" disabled={!showTooltip}>
        <Styled.FieldName ref={ref}>{rowData?.field?.name}</Styled.FieldName>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

FieldCell.displayName = 'FieldCell';

export default memo(observer(FieldCell));
