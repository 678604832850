import { FC, memo } from 'react';
import {toJS} from "mobx";

import { _Attribute, _AttributeValue } from '../../models';
import { SliderContainer, StageContainer } from '../../containers';

import Styled from './Attribute.styles';
import { AttributeName } from './components';


interface IAttributeProps {
  attribute: _Attribute;
  attributeValueList: _AttributeValue[];
  cellPadding: string;
}

const Attribute: FC<IAttributeProps> = ({ attribute, attributeValueList, cellPadding }) => {
  const { tableId, collapsingStageId, name, isTitle, Component, isHidden } = attribute;

  if (attribute?.stageIds) {
    return (
      <>
        {attribute.stageIds.map(id => (
          <StageContainer key={id} tableId={attribute.tableId} id={id} cellPadding={cellPadding} />
        ))}
      </>
    );
  }

  return (
    <Styled.Wrapper $isTitle={isTitle}>
      {!isHidden ? (
        <AttributeName isTitle={isTitle} cellPadding={cellPadding} showFullText isFirstColumn={true}>
          {Component ? <Component attribute={attribute} /> : name ?? ''}
        </AttributeName>
      ) : null}

      <Styled.InstanceValueWrapper>
        <SliderContainer tableId={tableId} collapsingStageId={collapsingStageId}>
          {attributeValueList.map(attrVal => (
            <AttributeName
              key={`${attrVal.instanceId}-${attrVal.attributeId}`}
              isTitle={isTitle}
              cellPadding={cellPadding}
            >
              {attrVal?.Component ? (
                <attrVal.Component attributeValue={attrVal} />
              ) : (
                attrVal?.value ?? ''
              )}
            </AttributeName>
          ))}
        </SliderContainer>
      </Styled.InstanceValueWrapper>
    </Styled.Wrapper>
  );
};

Attribute.displayName = 'Attribute';

export default memo(Attribute);
