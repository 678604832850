import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  padding: 12px 0;
`;

const StyledExecutionTableAddFertilizerRow = {
  Wrapper,
};

export default StyledExecutionTableAddFertilizerRow;
