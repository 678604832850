import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  padding: 12px 0;

  .react-datepicker__close-icon {
    margin-right: -3px;
    height: 20px;
  }
`;

const StyledExecutionTableDateCell = {
  Wrapper,
};

export default StyledExecutionTableDateCell;
