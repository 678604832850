import styled from 'styled-components';

const Header = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  padding-bottom: 32px;
`;

const Item = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 24px;
`;

const StyledInventoryValueCellTitle = {
  Header,
  Item,
};

export default StyledInventoryValueCellTitle;
