import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import Styled from './PhenophaseCellComponent.styles';

interface IPhenophaseCellComponent {
  phenophase: string;
  phenophaseEnd: string;
  rowId: string;
  dataTestId?: string;
  isDisableTooltip?: boolean;
}

const PhenophaseCellComponent: FC<IPhenophaseCellComponent> = ({
  phenophase,
  phenophaseEnd,
  rowId,
  dataTestId,
  isDisableTooltip,
}) => {
  const isBothPhenophaseExistAndNotEqual =
    phenophase && phenophaseEnd && phenophase !== phenophaseEnd;
  const isOnePhenophaseExist = phenophase || phenophaseEnd;

  const testId = dataTestId ?? 'phenophase';

  return (
    <Styled.Wrapper data-test-id={`${testId}-cell-wrapper-${rowId}`}>
      {isBothPhenophaseExistAndNotEqual ? (
        <>
          <AutoTooltip content={phenophase} position="top" disabled={isDisableTooltip}>
            <Styled.Item data-test-id={`both-${testId}-cell-item-${rowId}-start`}>
              {phenophase}
            </Styled.Item>
          </AutoTooltip>
          <AutoTooltip content={phenophaseEnd} position="top" disabled={isDisableTooltip}>
            <Styled.Item data-test-id={`both-${testId}-cell-item-${rowId}-end`}>
              {phenophaseEnd}
            </Styled.Item>
          </AutoTooltip>
        </>
      ) : isOnePhenophaseExist ? (
        <AutoTooltip content={isOnePhenophaseExist} position="top" disabled={isDisableTooltip}>
          <Styled.Item data-test-id={`one-${testId}-cell-item-${rowId}`}>
            {isOnePhenophaseExist}
          </Styled.Item>
        </AutoTooltip>
      ) : (
        <Styled.Item data-test-id={`no-${testId}-cell-item-${rowId}`}>—</Styled.Item>
      )}
    </Styled.Wrapper>
  );
};

PhenophaseCellComponent.displayName = 'PhenophaseCellComponent';

export default memo(PhenophaseCellComponent);
