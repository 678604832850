import { FC, memo, MouseEvent, useCallback } from 'react';

import Styled from './StepFooter.styles';
import { IStepFooterProps } from './StepFooter.types';

const StepFooter: FC<IStepFooterProps> = ({
  stepName,
  continueButtonType,
  isContinueButtonDisabled,
  successTitle = 'Продолжить',
  onReturnToPreviousStepClick,
  onContinueClick,
  isShowBackButton = false,
}) => {
  const handleOnReturnToPreviousStepClick = useCallback(() => {
    onReturnToPreviousStepClick?.();
  }, [onReturnToPreviousStepClick]);

  const handleContinueClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onContinueClick?.(e);
    },
    [onContinueClick]
  );

  return (
    <Styled.Footer data-test-id={`${stepName}-footer`}>
      {isShowBackButton ? (
        <Styled.BackButton
          color="default"
          type="button"
          onClick={handleOnReturnToPreviousStepClick}
          data-test-id={`${stepName}-return-to-previous-step-button`}
        >
          К предыдущему шагу
        </Styled.BackButton>
      ) : null}

      <Styled.ContinueButton
        color="primary"
        type={continueButtonType}
        disabled={isContinueButtonDisabled}
        onClick={handleContinueClick}
        data-test-id={`${stepName}-continue-button`}
      >
        {successTitle}
      </Styled.ContinueButton>
    </Styled.Footer>
  );
};

StepFooter.displayName = 'StepFooter';

export default memo(StepFooter);
