import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import { useStore } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionController } from '../../../../../mobx/controllers';
import { ComparisonTableBuilderCell } from '../../../../../../../../../../../../common/features/ComparisonTableBuilder/components/cells';
import { EExecutionTableColumnId } from '../../../../../types';
import { IExperimentFactTableValue } from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { IComparisonTableBuilderCell } from '../../../../../../../../../../../../common/features/ComparisonTableBuilder/models/data';

interface IProps {
  cell: IComparisonTableBuilderCell<IExperimentFactTableValue>;
}

const ExecutionCalculationsTotalFertilizerPriceCell: FC<IProps> = ({ cell }) => {
  const executionController = useStore(ExecutionController);

  const value = executionController.getCalculationsFactTotalFertilizerPriceValue();

  useEffect(() => {
    if (cell.initialModel.doubleValue === value) {
      return;
    }

    executionController.updateCell<IExperimentFactTableValue>(
      cell.builderId,
      cell.rowId,
      EExecutionTableColumnId.Fact,
      {
        initialModel: {
          ...cell.initialModel,
          doubleValue: value,
        },
      }
    );
  }, [cell.initialModel.doubleValue, value]);

  return <ComparisonTableBuilderCell value={value} preset={'default'} />;
};

ExecutionCalculationsTotalFertilizerPriceCell.displayName =
  'ExecutionCalculationsTotalFertilizerPriceCell';

export default observer(ExecutionCalculationsTotalFertilizerPriceCell);
