import { Colors } from '@farmlink/farmik-ui';

import { ETaskStatus } from '../../../../../../../../../../../../../../common/utils/constants/task';

const getStatusCellComponentColors = status => {
  let bgColor = Colors.lightGrey;
  let color = Colors.black;
  let statusName = status;

  switch (status) {
    case ETaskStatus.New:
      bgColor = Colors.lightBlue;
      color = Colors.blue;
      statusName = 'Новая';
      break;
    case ETaskStatus.InWork:
      bgColor = Colors.lightGreen;
      color = Colors.accentGreen;
      statusName = 'В работе';
      break;
    case ETaskStatus.Canceled:
      bgColor = Colors.lightPink;
      color = Colors.red;
      statusName = 'Отменена';
      break;
    case ETaskStatus.Completed:
      statusName = 'Завершена';
      break;
    default:
      break;
  }

  return { bgColor, color, statusName };
};

export default getStatusCellComponentColors;
