import styled from 'styled-components';

const RowWrapper = styled.div`
  padding: 52px 0;
`;

const StyledTotalComparisonRows = {
  RowWrapper,
};

export default StyledTotalComparisonRows;
