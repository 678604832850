import styled from 'styled-components';

const Consumption = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  padding-bottom: 24px;
  line-height: 20px;
`;

const ConsumptionCellStyled = {
  Consumption,
};

export default ConsumptionCellStyled;
