import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { TChangeExperimentContractReq } from '../../../../../../../../../../../../api';
import {
  IExperiment,
  IExperimentContract,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { AxiosService } from '../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { GlobalService } from '../../../../../../../../../../../common/mobx/services/system';
import { TResponseList } from '../../../../../../../../../../../../api/models/common/response';

@provide.transient()
class ExperimentsTableService {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  @lazyInject(GlobalService)
  globalService: GlobalService;

  getOrganizationExperimentList = async (
    page,
    organizationId: string,
    contractId: string
  ): Promise<TResponseList<IExperiment>> => {
    const { getOrganizationExperimentsList } = this.axiosService.api;

    try {
      const response = await getOrganizationExperimentsList({
        page,
        size: 25,
        organizationId,
        contractId,
      });

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default ExperimentsTableService;
