import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ButtonLink } from '@farmlink/farmik-ui';

import { EDashboardRoute } from '../../../../../../routes/DashboardRoute';

import { ExperimentsTable } from './components';
import Styled from './ExperimentsTab.styles';

export const ExperimentsTab: FC = observer(() => {
  const navigate = useNavigate();

  return (
    <>
      <Styled.Wrapper>
        <ExperimentsTable />
      </Styled.Wrapper>

      <ButtonLink
        color={'accent'}
        onClick={() => {
          navigate(`/${EDashboardRoute.Experiments}/create/attributes`);
        }}
      >
        Добавить опыт
      </ButtonLink>
    </>
  );
});
