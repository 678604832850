import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { NavigationTabsStore } from '../stores';

@provide.transient()
class NavigationTabsController {
  @lazyInject(NavigationTabsStore)
  protected navigationTabsStore: NavigationTabsStore;

  goToNextTab = () => {
    const { selectedTab, tabs, setSelectedTab } = this.navigationTabsStore;
    const nextTab = tabs.find(({ id }) => id === selectedTab.id + 1);

    if (nextTab) {
      setSelectedTab(nextTab);
    }
  };

  goToPrevTab = () => {
    const { selectedTab, tabs, setSelectedTab } = this.navigationTabsStore;
    const prevTab = tabs.find(({ id }) => id === selectedTab.id - 1);

    if (prevTab) {
      setSelectedTab(prevTab);
    }
  };

  setSelectedTabByPath = (location: string, param: string, pathKey: string) => {
    const { tabs, setSelectedTab } = this.navigationTabsStore;

    const searchPath = location.replace(param, `:${pathKey}`);

    const tab = tabs.find(({ path }) => path === searchPath);

    if (tab) {
      setSelectedTab(tab);
    }
  };
}

export default NavigationTabsController;
