import { useCallback, useEffect, useMemo, useState } from 'react';

import { TableFiltersBuilderController as Controller } from '../../../../mobx/controllers';
import { TableFiltersBuilderStore as Store } from '../../../../mobx/stores';
import { ITableFiltersBuilderTag as ITag } from '../../../../models/data';
import { useStore } from '../../../../../../utils/helpers/mobx';
import { useHandlerByScreenResize } from '../../../../../hooks';

const useTableFiltersBuilderTagsUIState = (builderId: string) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const [tagsWrapperWidth, setTagsWrapperWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [isShowHiddenTags, setIsShowHiddenTags] = useState(false);

  const [tagList, setTagList] = useState<ITag[]>([]);
  const [tagListToShow, setTagListToShow] = useState<ITag[]>([]);
  const [tagListToHide, setTagListToHide] = useState<ITag[]>([]);

  const isNeedToResizeFilters = store.getIsNeedToResizeFilters(builderId);
  const _tagList = controller.getTagList(builderId);

  /**
   * Эффект сугубо для оптимизации.
   * Необходим для того, чтобы не вызывать лишний раз пересчет тегов.
   */
  useEffect(() => {
    setTagList(_tagList);
  }, [JSON.stringify(_tagList)]);

  const handleUpdateTagsWrapper = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useHandlerByScreenResize(handleUpdateTagsWrapper);

  useEffect(() => {
    setTagListToShow([]);
    setTagListToHide([]);

    const tempTagListToShow: ITag[] = [];
    const tempTagListToHide: ITag[] = [];

    let calculatedTagsWidth = 0;

    tagList.forEach(tag => {
      /**
       * Рассчитываем допустимую длину для каждого тега, где числа (всё указано в пикселях, кроме длины):
       * количество символов [tag.label.length] + максимальная ширина буквы [8] + padding [24] +
       * иконка крестика [12] + расстояние между лейблом и иконкой крестика [4] +
       * расстояние между тегами [8].
       */
      const tagWidth = tag.label.length * 8 + 24 + 12 + 4 + 8;
      const formattedTagWidth = tagWidth > 200 ? 200 : tagWidth;

      const afterCalculated = calculatedTagsWidth + formattedTagWidth;

      /**
       * 55 [px] — это максимальная "логичная" ширина кнопки "раскрыть все теги".
       */
      if (afterCalculated <= tagsWrapperWidth - 55) {
        calculatedTagsWidth += formattedTagWidth;
        tempTagListToShow.push(tag);
      } else {
        tempTagListToHide.push(tag);
      }
    });

    setTagListToHide(tempTagListToHide);
    setTagListToShow(tempTagListToShow);

    controller.removeResizeFlag(builderId);
    controller.removeResizeAnimationFlag(builderId);
  }, [tagList, windowWidth, isNeedToResizeFilters]);

  const availableTagList = useMemo(() => {
    if (isShowHiddenTags) {
      return tagList;
    }

    return tagListToShow;
  }, [tagList, tagListToShow, isShowHiddenTags]);

  return {
    isShowHiddenTags,
    availableTagList,
    tagListToHide,
    setTagsWrapperWidth,
    setIsShowHiddenTags,
  };
};

export default useTableFiltersBuilderTagsUIState;
