import { ICreateNutritionHistoryItemDto } from '../../../../models/as-fields/plan/NutrationHistory';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TCreateNutritionHistoryItemReq = ICreateNutritionHistoryItemDto;
type TCreateNutritionHistoryItemRes = {} & { id: string };

const createNutritionHistoryItem: TApiRoute & {
  request: TCreateNutritionHistoryItemReq;
  response: TCreateNutritionHistoryItemRes;
} = {
  url: () => `/api/as-fields/nutrition-history-item/create`,
  method: 'POST',
  request: {} as TCreateNutritionHistoryItemReq,
  response: {} as TCreateNutritionHistoryItemRes,
  headers: {},
};

export { createNutritionHistoryItem };
export type { TCreateNutritionHistoryItemReq, TCreateNutritionHistoryItemRes };
