import { makeAutoObservable } from 'mobx';
import { ITab } from '@farmlink/farmik-ui/dist/Tabs/types';

import { provide } from '../../../../../../../../../common/utils/helpers/mobx';
import { IDynamicTableConfig } from '../../../../../../../../../common/features/DynamicTable/models';
import { EChecklistType } from '../../../../../../../../../../api/models/as-fields/checklists/Checklist/Checklist.model';

@provide.singleton()
class AuditReportStore {
  private _dynamicTableConfig: IDynamicTableConfig | null = null;

  private _selectedTabId: EChecklistType | null = null;

  private _availableTabList: ITab[] = [];

  private _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get dynamicTableConfig() {
    return this._dynamicTableConfig;
  }

  get selectedTabId() {
    return this._selectedTabId;
  }

  get availableTabList() {
    return this._availableTabList;
  }

  get isLoading() {
    return this._isLoading;
  }

  setDynamicTableConfig = (config: IDynamicTableConfig): void => {
    this._dynamicTableConfig = config;
  };

  setIsLoading = (isLoading: boolean): void => {
    this._isLoading = isLoading;
  };

  clearDynamicTableConfig = (): void => {
    this._dynamicTableConfig = null;
  };

  setSelectedTabId = (tab: EChecklistType): void => {
    this._selectedTabId = tab;
  };

  setAvailableTabList = (tabList: ITab[]): void => {
    this._availableTabList = tabList;
  };

  clearSelectedTabId = (): void => {
    this._selectedTabId = null;
  };

  clearAvailableTabList = (): void => {
    this._availableTabList = [];
  };

  clearIsLoading = (): void => {
    this._isLoading = false;
  };

  clearStore = (): void => {
    this.clearDynamicTableConfig();

    this.clearSelectedTabId();
    this.clearAvailableTabList();
    this.clearIsLoading();
  };
}

export default AuditReportStore;
