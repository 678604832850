import { IExaminationAttributeValue } from '../../../../../../../../../../../api/models/aho/examination-attribute-values/ExaminationAttributeValue.model';
import { IExamination } from '../../../../../../../../../../../api/models/aho/examination/Examination.model';

const mapIdToExaminationTypeOptions: Record<string, { type: string; valueType: string }> = {
  'fd1f9730-451d-4499-8a47-a4b03f3ee35c': {
    type: 'type',
    valueType: 'dictionaryValue',
  },
  '975b0fac-eccd-4819-b3e1-c83762cbb3f6': {
    type: 'mechanicalComposition',
    valueType: 'dictionaryValue',
  },
};

const mapIdToExaminationType: Record<string, { type: string; valueType: string }> = {
  ...mapIdToExaminationTypeOptions,
  'e4a14583-3909-4cad-b63a-60bd7dcd76b1': {
    type: 'ph',
    valueType: 'doubleValue',
  },
  '47dab72f-ffe3-4a39-91fb-3ebef5241fea': {
    type: 'humus',
    valueType: 'doubleValue',
  },
  '7467bcac-17eb-4b36-8d3f-88cb6b00d740': {
    type: 'ko',
    valueType: 'doubleValue',
  },
  '8f3d652c-50c7-4567-95f2-030877cb5662': {
    type: 'po',
    valueType: 'doubleValue',
  },
};

export const getAgrochemicalForm = (
  currentExamination: IExamination,
  examinationAttributeValues: IExaminationAttributeValue[]
) => {
  if (!currentExamination) {
    return null;
  }

  const form = examinationAttributeValues.reduce((acc, examinationAttributeValue) => {
    const { valueType, type: typed } =
      mapIdToExaminationType[examinationAttributeValue.examinationMethod.examinationAttribute.id];

    const value = examinationAttributeValue[valueType];

    const isNumberValue = typeof value === 'number';

    acc[typed] = isNumberValue ? value : value.id;
    acc[`${typed}Method`] = examinationAttributeValue.examinationMethod.id;

    return acc;
  }, {} as any);
  form.id = 'agrochemicalTable';
  form.date = new Date(currentExamination.sampleDate);

  return form;
};

export const getAgrochemicalOtions = (
  currentExamination: IExamination,
  examinationAttributeValues: IExaminationAttributeValue[]
) => {
  if (!currentExamination) {
    return null;
  }

  const form = examinationAttributeValues.reduce((acc, examinationAttributeValue) => {
    const examinationTypeOption =
      mapIdToExaminationTypeOptions[
        examinationAttributeValue.examinationMethod.examinationAttribute.id
      ];

    if (examinationTypeOption) {
      const { valueType, type: typed } = examinationTypeOption;

      const value = examinationAttributeValue[valueType];

      acc[typed] = { id: value.id, name: value.name };
      acc[`${typed}Method`] = {
        id: examinationAttributeValue.examinationMethod.id,
        name: examinationAttributeValue.examinationMethod.name,
      };
    }

    return acc;
  }, {} as any);

  return form;
};
