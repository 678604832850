import { FC, useEffect, useMemo } from 'react';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useForm } from '../../../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { createSelectOptionsInCultureZones } from '../../helpers/createSelectOptions';
import { CalculationStore } from '../../mobx/store/Calculation/Calculation.store';
import { CalculationController } from '../../mobx/controllers/Calculation/Calculation.controller';
import { copyFieldFormConfig, ICopyFieldForm } from '../../config/forms/copyFieldForm';

import Styled from './CopyAhoForm.styles';

const CopyAhoForm: FC = observer(() => {
  const form = useForm<ICopyFieldForm>(copyFieldFormConfig.formKey);
  const { elements, registerForm, addOptionList, submitForm } = form;

  const { activeCultureZone, cultureZones } = useStore(CalculationStore);
  const { copyAho } = useStore(CalculationController);

  const { closeModal } = useModal();

  const { fieldId: FieldComponent } = elements;

  const fields = useMemo(() => {
    return createSelectOptionsInCultureZones(
      cultureZones.filter(
        item =>
          item.id !== activeCultureZone.id &&
          item.cultureZone.culture.id === activeCultureZone.cultureZone.culture.id &&
          item.aho
      )
    );
  }, [cultureZones, activeCultureZone]);

  useEffect(() => {
    registerForm(copyFieldFormConfig);
    addOptionList('fieldId', fields);
  }, []);

  const handleSubmit = () => {
    submitForm(async forms => {
      await copyAho(forms, activeCultureZone);
      closeModal();
    });
  };

  const successButton = useMemo(
    () => ({
      title: 'Скопировать',
      handler: handleSubmit,
    }),
    [handleSubmit]
  );

  const denyButton = useMemo(
    () => ({
      title: 'Отменить',
      handler: closeModal,
    }),
    []
  );

  return (
    <>
      <Styled.Wrapper>
        <Styled.Title>Скопировать с участка:</Styled.Title>
        <Styled.FieldBlock>{FieldComponent ? <FieldComponent /> : null}</Styled.FieldBlock>
        <Styled.Description>
          Все имеющиеся данные на текущем участке будут потеряны
        </Styled.Description>
      </Styled.Wrapper>
      <ModalFooter successButton={successButton} denyButton={denyButton} />
    </>
  );
});

CopyAhoForm.displayName = 'CopyAhoForm';

export default CopyAhoForm;
