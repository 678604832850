import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TChangeExperimentContractByIdReq = {
  experimentId: string;
};

type TChangeExperimentContractByIdRes = {};

const changeExperimentContractById: TApiRoute & {
  request: TChangeExperimentContractByIdReq;
  response: TChangeExperimentContractByIdRes;
} = {
  url: ({ experimentId }) => `/api/as-fields/experiment/${experimentId}/contract`,
  method: 'PUT',
  request: {} as TChangeExperimentContractByIdReq,
  response: {} as TChangeExperimentContractByIdRes,
  headers: {},
};

export type { TChangeExperimentContractByIdReq, TChangeExperimentContractByIdRes };

export { changeExperimentContractById };
