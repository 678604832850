import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { IExperimentContract } from '../../../../models/as-fields/experiments';
import { IContractForm } from '../../../../../modules/dashboard/modules/contracts/modules/CreateEditContract/forms/config/contractFormConfig';

type TCreateExperimentContractReq = Pick<IContractForm, 'contractDate' | 'organizationId'> & {
  name?: string;
};

type TCreateExperimentContractRes = IExperimentContract;

const createExperimentContract: TApiRoute & {
  request: TCreateExperimentContractReq;
  response: TCreateExperimentContractRes;
} = {
  url: '/api/as-fields/experiment/contract',
  method: 'POST',
  request: {} as TCreateExperimentContractReq,
  response: {} as TCreateExperimentContractRes,
  headers: {},
};

export type { TCreateExperimentContractReq, TCreateExperimentContractRes };

export { createExperimentContract };
