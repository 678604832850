import { FC, memo } from 'react';

import { Plug } from '../../../../../../../../../../../common/components/ui';
import applicationTimeIcon from '../../../../../../assets/icons/application_time.svg';

export interface ITablePlugProps {
  onClickAdd(): void;
  isViewMode?: boolean;
  noSoilParameters?: boolean;
}

const AgrochemicalPlug: FC<ITablePlugProps> = ({ onClickAdd, isViewMode, noSoilParameters }) => {
  return (
    <Plug
      icon={applicationTimeIcon}
      title={
        noSoilParameters
          ? 'Результаты агрохимического обследования почвы не внесены'
          : 'Внесите результаты агрохимического обследования почвы'
      }
      primaryButtonTitle="Добавить АХО"
      onPrimaryButtonClick={onClickAdd}
      buttonExist={!isViewMode}
    />
  );
};

export default memo(AgrochemicalPlug);
