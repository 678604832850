import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const KEY = 'responseErrorModalList';

enum EModalName {
  CommonError = 'commonError',
}

const COMMON_ERROR_CONFIG: TModalConfig = {
  name: EModalName.CommonError,
  title: 'Кажется, что-то пошло не так. Попробуйте ещё раз',
  type: EModalType.Warning,
  id: EModalName.CommonError,
  dataTestId: 'common-response-error-modal',
  successButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};

const CONFIG_LIST = [COMMON_ERROR_CONFIG];

const responseErrorModalConfig = {
  key: KEY,
  modalName: EModalName,
  configList: CONFIG_LIST,
};

export default responseErrorModalConfig;
