import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const StyledActionCell = {
  Wrapper,
};

export default StyledActionCell;
