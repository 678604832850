import { observer } from 'mobx-react';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { ENotificationType, ENotificatorTheme, useNotificator } from '@farmlink/farmik-ui';

import { CreateObservationController } from '../../containers/CreateObservation/mobx/controllers';
import { CreateObservationStore } from '../../containers/CreateObservation/mobx/stores';
import {
  useCheckChangedForm,
  useForm,
} from '../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import {
  REQUIRED_TEXT,
  START_DATE_NOT_AFTER_END_DATE_ERROR_TEXT,
} from '../../../../../../../../../common/utils/constants/dateValidation';
import { formatDateToISO } from '../../../../../../../../../common/utils/helpers/dates';
import { ISeason } from '../../../../../../../../../../api/models/as-fields/seasons';
import { TechOperationController } from '../../../../../../../experiments/modules/createExperiment/modules/history/containers/History/mobx/controllers/TechOperation.controller';
import { AuditInfo } from '../../../Info/modules/auditInfo';
import { EDashboardRoute } from '../../../../../../../../routes/DashboardRoute';
import { EAuditsRoute } from '../../../../../../routes';
import { EAuditRoute } from '../../../../routes';
import { useWarningBeforeLeavingThePage } from '../../../../../../../../../common/utils/hooks';

import {
  COMMON_INFORMATION_FORM_KEY,
  createCommonInformationFormConfig,
  ICommonInformationForm,
} from './forms/config/commonInformationFormConfig';

const CommonInformation: FC = observer(() => {
  const {
    elements,
    formData,
    registerForm,
    addOptionList,
    isSubmitDisabled,
    submitForm,
    addDatePickerParams,
    setElementErrorShow,
    changeListOfFormValue,
    addPaginationConfig,
  } = useForm<ICommonInformationForm>(COMMON_INFORMATION_FORM_KEY);

  const {
    fetchAudit,
    saveExperimentStep,
    organizationsSearchQueryHandler,
    experimentSearchQueryHandler,
    usersSearchQueryHandler,
    operationTypesSearchQueryHandler,
    experimentStepsSearchQueryHandler,
    changeDropdownPageNumber,
    onOrganizationListScroll,
    onExperimentListScroll,
    onExperimentStepListScroll,
    onTechOperationListScroll,
    onAssigneeListScroll,
  } = useStore(CreateObservationController);

  const {
    setExperimentId,
    setOrganizationId,
    getExperimentInfoById,
    searchQueries,
    dropdownPageNumbers,
    clearStore,
    clearAfterOrganizationChange,
    clearAfterExperimentChange,
    getExpStepById,
  } = useStore(CreateObservationStore);

  const { getCurrentSeason } = useStore(TechOperationController);

  const [currentSeason, setCurrentSeason] = useState<ISeason | null>(null);

  const navigate = useNavigate();

  const {
    state,
  }: {
    state: {
      taskId?: string;
      organizationName?: string;
      organizationId?: string;
      experimentName?: string;
      experimentId?: string;
      experimentStepName?: string;
      experimentStepId?: string;
    };
  } = useLocation();

  const [initialValues, setInitialValues] = useState({
    ...state,
    experimentStepName: state?.experimentStepName?.substring(
      state?.experimentStepName?.indexOf(' ') + 1
    ),
  });

  const { isFormChanged, focusChangedElement } = useCheckChangedForm();
  const { setNotification } = useNotificator();

  useWarningBeforeLeavingThePage(isFormChanged, focusChangedElement);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    registerForm(
      createCommonInformationFormConfig({
        organizationsSearchQueryHandler,
        experimentSearchQueryHandler,
        usersSearchQueryHandler,
        operationTypesSearchQueryHandler,
        experimentStepsSearchQueryHandler,
      })
    );
  }, []);

  useEffect(() => {
    if (state?.organizationId) {
      addOptionList('organizationId', [
        { label: state?.organizationName, value: state?.organizationId },
      ]);

      changeListOfFormValue({
        organizationId: state?.organizationId,
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (formData?.organizationId) {
        if (initialValues?.experimentName) {
          setOrganizationId(formData?.organizationId);

          await experimentSearchQueryHandler(initialValues?.experimentName);

          addOptionList('experimentId', [
            { label: initialValues?.experimentName, value: initialValues?.experimentId },
          ]);

          changeListOfFormValue({
            experimentId: initialValues?.experimentId,
          });

          setInitialValues({ ...initialValues, experimentId: null, experimentName: null });
        } else {
          setOrganizationId(formData?.organizationId);

          experimentSearchQueryHandler('');
          experimentStepsSearchQueryHandler('');
          operationTypesSearchQueryHandler('');
          usersSearchQueryHandler('');
          clearAfterOrganizationChange();
        }
      }
    })();

    if (!initialValues?.experimentId) {
      changeListOfFormValue({
        experimentId: '',
        planStartDate: '',
        planEndDate: '',
        parentStepId: '',
        techOperationTypeId: '',
        assigneeId: '',
        name: '',
      });

      setCurrentSeason(null);
    }
  }, [formData?.organizationId]);

  useEffect(() => {
    (async () => {
      if (formData?.experimentId) {
        if (initialValues?.experimentStepName) {
          experimentStepsSearchQueryHandler(initialValues?.experimentStepName);
          operationTypesSearchQueryHandler('');
          usersSearchQueryHandler('');

          setExperimentId(formData?.experimentId);

          const experimentInfo = getExperimentInfoById(formData?.experimentId);

          const seasonInfo = await getCurrentSeason(
            formData.organizationId,
            experimentInfo.seasonYear
          );

          setCurrentSeason(seasonInfo);

          addOptionList('parentStepId', [
            { label: initialValues?.experimentStepName, value: initialValues?.experimentStepId },
          ]);

          changeListOfFormValue({
            parentStepId: initialValues?.experimentStepId,
          });

          setInitialValues({
            ...initialValues,
            experimentStepId: null,
            experimentStepName: null,
          });
        } else {
          experimentStepsSearchQueryHandler('');
          operationTypesSearchQueryHandler('');
          usersSearchQueryHandler('');
          clearAfterExperimentChange();

          setExperimentId(formData?.experimentId);

          const experimentInfo = getExperimentInfoById(formData?.experimentId);

          const seasonInfo = await getCurrentSeason(
            formData.organizationId,
            experimentInfo.seasonYear
          );

          setCurrentSeason(seasonInfo);
        }
      }
    })();

    if (!initialValues?.experimentStepId) {
      changeListOfFormValue({
        planStartDate: '',
        planEndDate: '',
        parentStepId: '',
        techOperationTypeId: '',
        assigneeId: '',
        name: '',
      });
    }
  }, [formData?.experimentId]);

  useEffect(() => {
    if (currentSeason) {
      const { startDate, endDate } = currentSeason;

      const minDate = new Date(startDate);
      const maxDate = new Date(endDate);

      const dateRangeParams = {
        minDate,
        maxDate,
      };

      addDatePickerParams('planStartDate', {
        startDate: minDate,
        dateRange: dateRangeParams,
      });

      addDatePickerParams('planEndDate', {
        startDate: maxDate,
        dateRange: dateRangeParams,
      });

      changeListOfFormValue({
        planStartDate: startDate,
        planEndDate: endDate,
      });

      experimentStepsSearchQueryHandler('');
      operationTypesSearchQueryHandler('');
      usersSearchQueryHandler('');
    }
  }, [currentSeason]);

  useEffect(() => {
    if (formData?.planEndDate && formData?.planStartDate) {
      const startDate = new Date(formData.planStartDate);
      const endDate = new Date(formData.planEndDate);

      if (startDate.getTime() > endDate.getTime()) {
        setElementErrorShow('planEndDate', true, START_DATE_NOT_AFTER_END_DATE_ERROR_TEXT);
      } else {
        setElementErrorShow('planEndDate', false, REQUIRED_TEXT);
      }
    }
  }, [formData?.planEndDate, formData?.planStartDate]);

  useEffect(() => {
    if (formData?.parentStepId) {
      const expStep = getExpStepById(formData.parentStepId);

      if (expStep?.techOperationType) {
        const presetOption = {
          value: expStep.techOperationType.id,
          label: expStep.techOperationType.name,
        };

        addOptionList('techOperationTypeId', [presetOption]);
        changeListOfFormValue({ techOperationTypeId: expStep.techOperationType.id }, true);
      }
    }
  }, [formData?.parentStepId]);

  const onOrganizationScroll = async () => {
    const newOrganizationOptionList = await onOrganizationListScroll(
      searchQueries.organizationSearchQuery
    );

    addOptionList('organizationId', newOrganizationOptionList);
  };

  useEffect(() => {
    addPaginationConfig('organizationId', {
      currentPage: dropdownPageNumbers.organizationCurrentPageNumber,
      totalPages: dropdownPageNumbers.organizationTotalPageNumber,
      onScroll: onOrganizationScroll,
      onPageChange: () => changeDropdownPageNumber('organizationCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.organizationCurrentPageNumber,
    dropdownPageNumbers.organizationTotalPageNumber,
    onOrganizationScroll,
    changeDropdownPageNumber,
  ]);

  const onExperimentScroll = async () => {
    const newExperimentOptionList = await onExperimentListScroll(
      searchQueries.experimentSearchQuery
    );

    addOptionList('experimentId', newExperimentOptionList);
  };

  useEffect(() => {
    addPaginationConfig('experimentId', {
      currentPage: dropdownPageNumbers.experimentCurrentPageNumber,
      totalPages: dropdownPageNumbers.experimentTotalPageNumber,
      onScroll: onExperimentScroll,
      onPageChange: () => changeDropdownPageNumber('experimentCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.experimentCurrentPageNumber,
    dropdownPageNumbers.experimentTotalPageNumber,
    onExperimentScroll,
    changeDropdownPageNumber,
  ]);

  const onExperimentStepScroll = async () => {
    const newExperimentStepOptionList = await onExperimentStepListScroll(
      searchQueries.experimentStepSearchQuery
    );

    addOptionList('parentStepId', newExperimentStepOptionList);
  };

  useEffect(() => {
    addPaginationConfig('parentStepId', {
      currentPage: dropdownPageNumbers.experimentStepCurrentPageNumber,
      totalPages: dropdownPageNumbers.experimentStepTotalPageNumber,
      onScroll: onExperimentStepScroll,
      onPageChange: () => changeDropdownPageNumber('experimentStepCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.experimentStepCurrentPageNumber,
    dropdownPageNumbers.experimentStepTotalPageNumber,
    onExperimentStepScroll,
    changeDropdownPageNumber,
  ]);

  const onTechOperationScroll = async () => {
    const newTechOperationOptionList = await onTechOperationListScroll(
      searchQueries.techOperationSearchQuery
    );

    addOptionList('techOperationTypeId', newTechOperationOptionList);
  };

  useEffect(() => {
    addPaginationConfig('techOperationTypeId', {
      currentPage: dropdownPageNumbers.techOperationCurrentPageNumber,
      totalPages: dropdownPageNumbers.techOperationTotalPageNumber,
      onScroll: onTechOperationScroll,
      onPageChange: () => changeDropdownPageNumber('techOperationCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.techOperationCurrentPageNumber,
    dropdownPageNumbers.techOperationTotalPageNumber,
    onTechOperationScroll,
    changeDropdownPageNumber,
  ]);

  const onAssigneeScroll = async () => {
    const newAssigneeOptionList = await onAssigneeListScroll(searchQueries.assigneeSearchQuery);

    addOptionList('assigneeId', newAssigneeOptionList);
  };

  useEffect(() => {
    addPaginationConfig('assigneeId', {
      currentPage: dropdownPageNumbers.assigneeCurrentPageNumber,
      totalPages: dropdownPageNumbers.assigneeTotalPageNumber,
      onScroll: onAssigneeScroll,
      onPageChange: () => changeDropdownPageNumber('assigneeCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.assigneeCurrentPageNumber,
    dropdownPageNumbers.assigneeTotalPageNumber,
    onAssigneeScroll,
    changeDropdownPageNumber,
  ]);

  const handleReadyClick = useCallback(async () => {
    await submitForm(async valideedData => {
      valideedData.planEndDate = formatDateToISO(valideedData.planEndDate);
      valideedData.planStartDate = formatDateToISO(valideedData.planStartDate);

      const experimentStepInfo = await saveExperimentStep(valideedData);

      if (experimentStepInfo?.id) {
        setNotification({
          message: 'Наблюдение создано',
          style: {
            placement: 'top-center',
            type: ENotificationType.Success,
            theme: ENotificatorTheme.Dark,
          },
        });

        const audit = await fetchAudit(experimentStepInfo.id);
        navigate(
          generatePath(`/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${EAuditRoute.Info}`, {
            auditId: audit.id,
          })
        );
      }
    });
  }, []);

  return (
    <AuditInfo
      formElements={elements}
      handleReadyClick={handleReadyClick}
      titleName={'Общие данные'}
      buttonName={'Создать наблюдение'}
      isSubmitDisabled={isSubmitDisabled}
      isShowSubmit
      mode={'Create'}
    />
  );
});

export default memo(CommonInformation);
