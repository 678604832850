import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TablePlugBuilder } from '../../../../../../../common/features/TablePlugBuilder';
import { useDataTestId } from '../../../../../../../common/utils/hooks/locators';
import { generateAuditsPath } from '../../../../utils/helpers';

import Styled from './AuditsListDefaultPlug.styles';

const AuditsListDefaultPlug: FC = () => {
  const navigate = useNavigate();

  const handleAddButtonClick = useCallback(() => {
    navigate(
      generateAuditsPath('createAudit', {
        auditId: 'create',
      })
    );
  }, []);

  const getDataTestId = useDataTestId('audits-table');
  const noDataPlugDataTestId = getDataTestId('no-data-plug')['data-test-id'];

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'application_time' },
        content: {
          title: 'У вас еще нет наблюдений',
          description: 'В этом разделе будут храниться ваши результаты осмотров по участкам',
        },
        footer: {
          renderButton: () => (
            <Styled.Button
              color={'primary'}
              onClick={handleAddButtonClick}
              alignment={'center'}
              dataTestId={getDataTestId('add-audit-plug-button')['data-test-id']}
            >
              Добавить
            </Styled.Button>
          ),
        },
      }}
      dataTestId={noDataPlugDataTestId}
    />
  );
};

AuditsListDefaultPlug.displayName = 'ExperimentsListDefaultPlug';

export default AuditsListDefaultPlug;
