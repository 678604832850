import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TCopyPlanNutritionHistoryReg = { donorId: string; recipientId: string };
type TCopyPlanNutritionHistoryRes = {};

const copyPlanNutritionHistory: TApiRoute & {
  request: TCopyPlanNutritionHistoryReg;
  response: TCopyPlanNutritionHistoryRes;
} = {
  url: ({ donorId, recipientId }) =>
    `/api/as-fields/cultureZoneExperiment/copyPlan/from/${donorId}/to/${recipientId}`,
  method: 'POST',
  request: {} as TCopyPlanNutritionHistoryReg,
  response: {} as TCopyPlanNutritionHistoryRes,
  headers: {},
};

export { copyPlanNutritionHistory };
export type { TCopyPlanNutritionHistoryReg, TCopyPlanNutritionHistoryRes };
