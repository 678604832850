import styled from 'styled-components';

const Icon = styled.svg`
  &:hover {
    & > path {
      fill: #b70c00;
    }
  }

  transition: width 0.3s ease-in-out;
`;

const Button = styled.button`
  width: 16px;
  height: 16px;

  padding: 0;
  border: 0;

  cursor: pointer;

  background: transparent;
`;

const StyledExecutionTableDeleteIcon = {
  Icon,
  Button,
};

export default StyledExecutionTableDeleteIcon;
