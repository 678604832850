import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useMatch, useNavigate, useParams, useLocation } from 'react-router-dom';

import { Tabs } from '../../components/ui';
import { useStore } from '../../utils/helpers/mobx';

import { NavigationTabsController, NavigationTabsStore } from './mobx';
import { INavigationTab } from './models';

interface IProps {
  tabs: INavigationTab[];
  pathKey: string;
  dataTestId: string;
}

export const NavigationTabs: FC<IProps> = observer(({ tabs, pathKey, dataTestId }) => {
  const { selectedTab, tabs: storedTabs, setTabs } = useStore(NavigationTabsStore);

  const { setSelectedTabByPath } = useStore(NavigationTabsController);

  const navigate = useNavigate();

  const location = useLocation();

  const match = useMatch(location?.pathname || '');

  const params = useParams();

  useEffect(() => {
    if (selectedTab?.id) {
      const tabPathKey = selectedTab?.path.match(pathKey);

      if (tabPathKey) {
        const navigatePath = generatePath(selectedTab.path, { [pathKey]: params[pathKey] });

        if (navigatePath !== location.pathname) {
          navigate(navigatePath);
        }
      }
    }
  }, [selectedTab?.id]);

  useEffect(() => {
    if (match.pathname && storedTabs.length > 0) {
      setSelectedTabByPath(match?.pathname, params[pathKey], pathKey);
    }
  }, [match?.pathname, storedTabs?.length]);

  useEffect(() => {
    setTabs(tabs);
  }, [tabs]);

  const onTabClick = useCallback(
    ({ path }) => {
      navigate(generatePath(path, { [pathKey]: params[pathKey] }));
    },
    [navigate, generatePath, params[pathKey]]
  );

  return (
    <Tabs
      tabs={tabs}
      activeTabId={selectedTab?.id}
      onTabClick={onTabClick}
      dataTestId={dataTestId}
    />
  );
});
