import { FC } from 'react';
import { isNaN } from 'lodash';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './ComparisonColumn.styles';

interface IAnalyzedLetter {
  id: number;
  tag: 'sub' | 'span';
  val: string;
}

const createAnalyzedLetterList = (word: string): IAnalyzedLetter[] => {
  return word.split('').map<IAnalyzedLetter>((letter, index) => {
    const number = Number(letter);

    return {
      id: index,
      tag: isNaN(number) ? 'span' : 'sub',
      val: letter,
    };
  });
};

interface IProps {
  name: string;
  dataTestId: string;
}

const ComparisonFormulaRow: FC<IProps> = ({ name, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.FormulaName {...getDataTestId('formula-name')}>
      {createAnalyzedLetterList(name).map(({ id, tag, val }) => {
        if (tag === 'sub') {
          return <sub key={id}>{val}</sub>;
        } else {
          return <span key={id}>{val}</span>;
        }
      })}
    </Styled.FormulaName>
  );
};

ComparisonFormulaRow.displayName = 'ComparisonFormulaRow';

export default ComparisonFormulaRow;
