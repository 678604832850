import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TDeleteExperimentStepReq = {} & { experimentStepId: string };

type TDeleteExperimentStepRes = {} & { id: string };

const deleteExperimentStep: TApiRoute & {
  request: TDeleteExperimentStepReq;
  response: TDeleteExperimentStepRes;
} = {
  url: ({ experimentStepId }) => `/api/as-fields/experimentSteps/${experimentStepId}`,
  method: 'DELETE',
  request: {} as TDeleteExperimentStepReq,
  response: {} as TDeleteExperimentStepRes,
  headers: {},
};

export type { TDeleteExperimentStepRes, TDeleteExperimentStepReq };

export { deleteExperimentStep };
