import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { EGeoUnitType, IGeoUnit } from '../../../models/as-fields/geo-unit';

type TGetGeoUnitListReq = { type?: EGeoUnitType; name?: string; parentId?: string; size?: number };

type TGetGeoUnitListRes = {} & TResponseList<IGeoUnit>;

const getGeoUnitList: TApiRoute & {
  request: TGetGeoUnitListReq;
  response: TGetGeoUnitListRes;
} = {
  url: () => `/api/as-fields/geo-units/list`,
  method: 'GET',
  request: {} as TGetGeoUnitListReq,
  response: {} as TGetGeoUnitListRes,
  headers: {},
};

export type { TGetGeoUnitListReq, TGetGeoUnitListRes };

export { getGeoUnitList };
