import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IAttachedFile } from '../../../models/as-fields/experiments';
import { FILE_ENTITY_URLS, TFileEntities } from '../../../models/as-fields/attachedFiles';

export type TUpdateAttachedFilesReq = {
  id: string;
  entity: TFileEntities;
  attachedFiles: string[];
};

export type TUpdateAttachedFilesRes = {
  attachedFiles: IAttachedFile[];
};

const updateAttachedFilesByEntity: TApiRoute & {
  request: TUpdateAttachedFilesReq;
  response: TUpdateAttachedFilesRes;
} = {
  url: ({ id, entity }) => `/api/as-fields/${FILE_ENTITY_URLS[entity]}/${id}/attachedFiles`,
  method: 'PUT',
  request: {} as TUpdateAttachedFilesReq,
  response: {} as TUpdateAttachedFilesRes,
  headers: {},
};

export { updateAttachedFilesByEntity };
