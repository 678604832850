import { TApiRoute } from '../../models/system/ApiRoute.model';

type TDownloadFileReq = { objectId: string };

type TDownloadFileRes = Blob;

const downloadFile: TApiRoute & {
  request: TDownloadFileReq;
  response: TDownloadFileRes;
} = {
  url: ({ objectId }) => `/api/da-files/files/${objectId}`,
  method: 'GET',
  request: {} as TDownloadFileReq,
  response: {} as TDownloadFileRes,
  headers: {},
};

export type { TDownloadFileRes, TDownloadFileReq };

export { downloadFile };
