import { makeAutoObservable } from 'mobx';

import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import {
  INutritionHistoryItem,
  INutritionHistory,
} from '../../../../../../../../../../../../api/models/as-fields/plan/NutrationHistory';
import { INutritionHistoryProtectionItem } from '../../../../../../../../../../../../api/models/as-fields/plan/NutrationHistory/NutritionHistory.model';
import { provide } from '../../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
export class HistoryStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _mapIdToCultureZone: Map<string, IExperimentCultureZone> = new Map();
  private _mapIdToExperimentStep: Map<string, IExperimentStep> = new Map();
  private _mapIdToNutritionHistory: Map<string, INutritionHistory> = new Map();
  private _activeCultureZone: IExperimentCultureZone | null = null;
  private _mapIdToFertilizerInventoryValues: Map<string, INutritionHistoryItem> = new Map();
  private _mapIdToProtectionInventoryValues: Map<string, INutritionHistoryProtectionItem> =
    new Map();
  private _currentFertilizerInventoryValue: INutritionHistoryItem | null = null;
  private _currentProtectionInventoryValue: INutritionHistoryProtectionItem | null = null;
  private _currentTechOperation: IExperimentStep | null = null;
  private _activeTab: 'techOperation' | 'activeSubstance' = 'techOperation';
  private _activeTabName = 'Техоперации';

  setCultureZones = (cultureZones: IExperimentCultureZone[]) => {
    const map: Map<string, IExperimentCultureZone> = new Map();

    for (const cultureZone of cultureZones) {
      map.set(cultureZone.id, cultureZone);
    }

    this._mapIdToCultureZone = map;
  };

  get cultureZones() {
    return [...this._mapIdToCultureZone.values()];
  }

  getCultureZoneById = (id: string) => this._mapIdToCultureZone.get(id);

  setActiveCultureZone = (cultureZone: IExperimentCultureZone) => {
    this._activeCultureZone = cultureZone;
  };

  get activeCultureZone() {
    return this._activeCultureZone;
  }

  setActiveTab = (activeTab: 'techOperation' | 'activeSubstance') => {
    this._activeTab = activeTab;
  };

  get activeTab() {
    return this._activeTab;
  }

  setActiveTabName = (activeTabName: string) => {
    this._activeTabName = activeTabName;
  };

  get activeTabName() {
    return this._activeTabName;
  }

  setExperimentSteps = (experimentSteps: IExperimentStep[]) => {
    const map = new Map<string, IExperimentStep>();

    for (const experimentStep of experimentSteps) {
      map.set(experimentStep.id, experimentStep);
    }
    this._mapIdToExperimentStep = map;
  };

  setNutritionHistory = (nutritionHistories: INutritionHistory[]) => {
    const map = new Map<string, INutritionHistory>();

    for (const nutritionHistory of nutritionHistories) {
      map.set(nutritionHistory.id, nutritionHistory);
    }

    this._mapIdToNutritionHistory = map;
  };

  setFertilizerInventoryValues = (inventoryValues: INutritionHistoryItem[]) => {
    const map = new Map<string, INutritionHistoryItem>();

    for (const inventoryValue of inventoryValues) {
      map.set(inventoryValue.id, inventoryValue);
    }

    this._mapIdToFertilizerInventoryValues = map;
  };

  setProtectionInventoryValues = (inventoryValues: INutritionHistoryProtectionItem[]) => {
    const map = new Map<string, INutritionHistoryProtectionItem>();

    for (const inventoryValue of inventoryValues) {
      map.set(inventoryValue.id, inventoryValue);
    }

    this._mapIdToProtectionInventoryValues = map;
  };

  getExperimentStepById = (id: string) => this._mapIdToExperimentStep.get(id);
  getNutritionHistoryById = (id: string) => this._mapIdToNutritionHistory.get(id);
  getNutritionHistoryByExperimentStepId = (id: string) =>
    this.nutritionHistories
      .filter(
        nutritionHistory =>
          nutritionHistory.cultureZone.id === this.activeCultureZone.cultureZone.id
      )
      .find(nutritionHistory => nutritionHistory.experimentStep.id === id);

  getNutritionHistoryFertilizerItemById = (id: string) =>
    this._mapIdToFertilizerInventoryValues.get(id);

  getNutritionHistoryProtectionItemById = (id: string) =>
    this._mapIdToProtectionInventoryValues.get(id);

  get experimentSteps() {
    return [...this._mapIdToExperimentStep.values()];
  }

  get nutritionHistories() {
    return [...this._mapIdToNutritionHistory.values()];
  }

  setCurrentFertilizerInventoryValue = (inventoryValue: INutritionHistoryItem) => {
    this._currentFertilizerInventoryValue = inventoryValue;
  };

  setCurrentProtectionInventoryValue = (inventoryValue: INutritionHistoryProtectionItem) => {
    this._currentProtectionInventoryValue = inventoryValue;
  };

  get currentFertilizerInventoryValue() {
    return this._currentFertilizerInventoryValue;
  }

  get currentProtectionInventoryValue() {
    return this._currentProtectionInventoryValue;
  }

  setCurrentTechOperation = (experimentStep: IExperimentStep) => {
    this._currentTechOperation = experimentStep;
  };

  get currentTechOperation() {
    return this._currentTechOperation;
  }
}
