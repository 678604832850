import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

const Title = styled.div`
  color: ${Colors.generalBlack};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

const StyledComparisonTableBuilderRowNestedRenderContainer = {
  Wrapper,
  Title,
};

export default StyledComparisonTableBuilderRowNestedRenderContainer;
