import styled from 'styled-components';

const Wrapper = styled.div<{ $name: string }>`
  grid-area: ${({ $name }) => $name};
`;

const StyledTableFiltersBuilderGridItem = {
  Wrapper,
};

export default StyledTableFiltersBuilderGridItem;
