import { FC } from 'react';
import { observer } from 'mobx-react';

import { IComparisonTableBuilderCell as ICell } from '../../../models/data';
import { ComparisonTableBuilderCellAutoRenderContainer as CellAutoRenderContainer } from '../ComparisonTableBuilderCellAutoRenderContainer';
import { ComparisonTableBuilderCellCustomRenderContainer as CellCustomRenderContainer } from '../ComparisonTableBuilderCellCustomRenderContainer';

interface IProps {
  cell: ICell;
}

const ComparisonTableBuilderCellContainer: FC<IProps> = ({ cell }) => {
  switch (cell.renderType) {
    case 'auto':
      return <CellAutoRenderContainer cell={cell} />;

    case 'custom':
      return <CellCustomRenderContainer cell={cell} />;

    default:
      return <></>;
  }
};

ComparisonTableBuilderCellContainer.displayName = 'ComparisonTableBuilderCellContainer';

export default observer(ComparisonTableBuilderCellContainer);
