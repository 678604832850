enum EFileUploadStatus {
  Idle = 'idle',
  FetchingUploadUrl = 'fetchingDataUrl',
  RejectFetchingUploadUrl = 'rejectFetchingDataUrl',
  SuccessFetchUploadUrl = 'successFetchUploadUrl',
  UploadUrlFetched = 'uploadUrlFetched',
  UploadingFile = 'uploadingFile',
  RejectFileUpload = 'rejectFileUpload',
  SuccessFileUpload = 'successFileUpload',
}

export { EFileUploadStatus };
