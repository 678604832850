import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../utils/helpers/mobx';
import { INavigationTab } from '../../models';

@provide.singleton()
class NavigationTabsStore {
  private _selectedTab: INavigationTab = null;

  private _tabs: INavigationTab[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get selectedTab() {
    return this._selectedTab;
  }

  get tabs() {
    return this._tabs;
  }

  setSelectedTab = (tab: INavigationTab) => {
    this._selectedTab = tab;
  };

  setTabs = (tabs: INavigationTab[]) => {
    this._tabs = tabs;
  };

  clearSelectedTab = () => {
    this._selectedTab = null;
  };

  clearTabs = () => {
    this._tabs = [];
  };

  clearStore = () => {
    this.clearSelectedTab();
    this.clearTabs();
  };
}

export default NavigationTabsStore;
