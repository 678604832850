import { observer } from 'mobx-react';
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { Input } from '../../../../../components/form';
import { useStore } from '../../../../../utils/helpers/mobx';
import { FormController } from '../../../mobx/controllers';
import { FormStore } from '../../../mobx/stores';
import { ValidationCollection } from '../../../utils/helpers/UniFormHelpers';

import { IInputContainerProps } from './InputContainer.types';

const InputContainer = forwardRef<HTMLInputElement, IInputContainerProps>(
  ({ formKey, elementName, id, element }, elementRef) => {
    const { getFormValue } = useStore(FormStore);
    const { onFormValueChange } = useStore(FormController);

    const value = getFormValue(formKey, elementName) as string | number;
    const { schema } = element;

    const dependencyName = element?.dependencyName as never;
    const dependencyValue = getFormValue(formKey, dependencyName);
    const isDependencyEmpty = !dependencyName ? false : !Boolean(dependencyValue);

    const validationScheme = element?.validation?.onChange;
    const shouldValidate = validationScheme;
    const { validateElementByScheme } = ValidationCollection;

    const [inputValue, setInputValue] = useState<string | number>(value ?? '');

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const debounceOnFormValueChange = useRef(
      _.debounce((newValue: string | number): void => {
        onFormValueChange(formKey, elementName, { [elementName]: newValue });
      }, 500)
    );

    const handleInputChange = useCallback(
      (newValue: string): void => {
        if (shouldValidate) {
          const validationResult = validateElementByScheme(newValue, validationScheme);
          if (!(validationResult instanceof Error)) {
            setInputValue(newValue);
            debounceOnFormValueChange.current(newValue);
          } else {
            setInputValue(prevValue => prevValue ?? '');
          }
        } else {
          setInputValue(newValue);
          debounceOnFormValueChange.current(newValue);
        }
      },
      [shouldValidate]
    );

    return (
      <>
        <Input
          id={id}
          value={inputValue}
          label={element?.label}
          placeholder={element?.placeholder}
          error={!schema?.isShownError ? '' : schema?.errorTitle}
          maxLength={element?.inputOptions?.maxLength}
          onChange={handleInputChange}
          ref={elementRef}
          isBlocked={element?.isBlocked || isDependencyEmpty}
          isDisabled={element?.isDisabled}
          dataTestId={id}
        />
      </>
    );
  }
);

InputContainer.displayName = 'InputContainer';

export default memo(observer(InputContainer));
