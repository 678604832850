import { ITask } from '../../../models/as-fields/task/Task';
import { TResponseList } from '../../../models/common/response';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TGetTaskListReq = {
  seasonYear: number;
  organizationId?: string;
  assigneeId?: string;
  cultureId?: string;
  noCulture?: boolean;
  operationId?: string;
  operationTypeId?: string;
  fieldId?: string;
  technologyId?: string;
  planDateFrom?: string;
  planDateTo?: string;
  statusIn?: string;
  experimentId?: string;
  experimentStepId?: string;
  page?: number;
  size?: number;
};

type TGetTaskListRes = {} & TResponseList<ITask>;

const getTaskList: TApiRoute & {
  request: TGetTaskListReq;
  response: TGetTaskListRes;
} = {
  url: () => `/api/as-fields/task/list`,
  method: 'GET',
  request: {} as TGetTaskListReq,
  response: {} as TGetTaskListRes,
  headers: {},
};

export type { TGetTaskListReq, TGetTaskListRes };

export { getTaskList };
