import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TablePlugBuilder } from '../../../../../../../../common/features/TablePlugBuilder';
import { useDataTestId } from '../../../../../../../../common/utils/hooks/locators';
import { ECreateEditContractRoute } from '../../../../../modules/CreateEditContract/routes';

import Styled from './ContractsListDefaultPlug.styles';

const ContractsListDefaultPlug: FC = () => {
  const navigate = useNavigate();

  const handleAddButtonClick = useCallback(() => {
    navigate(ECreateEditContractRoute.Create);
  }, []);

  const getDataTestId = useDataTestId('contracts-table');
  const noDataPlugDataTestId = getDataTestId('no-data-plug')['data-test-id'];

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'file' },
        content: {
          title: 'Создайте новый договор',
          description: 'Доступных вам договоров в системе не найдено',
        },
        footer: {
          renderButton: () => (
            <Styled.Button
              color={'primary'}
              onClick={handleAddButtonClick}
              alignment={'center'}
              dataTestId={getDataTestId('add-contract-plug-button')['data-test-id']}
            >
              Добавить
            </Styled.Button>
          ),
        },
      }}
      dataTestId={noDataPlugDataTestId}
    />
  );
};

ContractsListDefaultPlug.displayName = 'ContractsListDefaultPlug';

export default ContractsListDefaultPlug;
