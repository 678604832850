import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { IRegionEntity } from '../../../models/da-dictionary/region';

type TypeRequest = {};

type TypeResponse = {} & TResponseList<IRegionEntity>;

export const getRegionList: TApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/da-dictionary/dictionary/regions/findAll`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
