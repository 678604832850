import { IExperiment } from '../../../models/as-fields/experiments';
import { TResponseList } from '../../../models/common/response';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TGetOrganizationExperimentsListRequest = {
  size: number;
  page: number;
  organizationId: string;
  contractId: string;
};

type TGetOrganizationExperimentsListResponse = {} & TResponseList<IExperiment>;

const getOrganizationExperimentsList: TApiRoute & {
  request: TGetOrganizationExperimentsListRequest;
  response: TGetOrganizationExperimentsListResponse;
} = {
  url: `/api/as-fields/experiment/listForOrganization`,
  method: 'GET',
  request: {} as TGetOrganizationExperimentsListRequest,
  response: {} as TGetOrganizationExperimentsListResponse,
  headers: {},
};

export type { TGetOrganizationExperimentsListRequest, TGetOrganizationExperimentsListResponse };

export { getOrganizationExperimentsList };
