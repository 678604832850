import { useState, useEffect } from 'react';

import { useStore } from '../../../../../utils/helpers/mobx';
import formStore from '../../../mobx/stores/FormStore/Form.store';
import { FormController } from '../../../mobx/controllers';

const useCheckChangedForm = () => {
  const { lastChangedElement } = useStore(formStore);
  const { focusChangedElement } = useStore(FormController);

  const [isFormChanged, setIsFormChanged] = useState(Boolean(lastChangedElement));

  useEffect(() => {
    setIsFormChanged(Boolean(lastChangedElement));
  }, [lastChangedElement]);

  return {
    isFormChanged,
    setIsFormChanged,
    focusChangedElement,
  };
};

export default useCheckChangedForm;
