import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const StyledTitle = styled.span`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 125% */
`;

const config: TModalConfig = {
  id: 'warningBeforeLeavingThePage',
  name: 'warningBeforeLeavingThePage',
  title: (
    <StyledTitle>
      Если покинуть экран,
      <br />
      все несохранённые данные будут потеряны
    </StyledTitle>
  ),
  type: EModalType.Warning,
  styles: {
    title: {
      $size: 'large',
      $alignTitle: 'center',
    },
  },
  styledProps: { $size: EModalSize.Medium },
  dataTestId: 'warning-before-leaving-the-page',
  denyButton: {
    title: 'Остаться',
    dataTestId: 'warning-before-leaving-the-page-deny-button',
  },
  successButton: {
    title: 'Покинуть экран',
    dataTestId: 'warning-before-leaving-the-page-success-button',
  },
};

const createWarningBeforeLeavingThePage = (
  successHandler: () => void,
  denyHandler?: () => void
): { key: string; name: string; configList: TModalConfig[] } => {
  return {
    key: config.name,
    name: config.name,
    configList: [
      {
        ...config,
        denyButton: { ...config.denyButton, handler: denyHandler && denyHandler },
        successButton: { ...config.successButton, handler: successHandler, color: 'secondary' },
      },
    ],
  };
};

export default createWarningBeforeLeavingThePage;
