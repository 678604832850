import styled, { CSSProperties, keyframes } from 'styled-components';

import spinnerIcon from './assets/icons/spinner.svg';

const rotateAnimation = keyframes`
  100% {
    transform:rotate(360deg); 
  }
`;

const Spinner = styled.div`
  width: 24px;
  height: 24px;

  background: url(${spinnerIcon});

  animation: ${rotateAnimation} 1s linear infinite;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #151f32;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;

const Overlay = styled.div<{ $styles?: CSSProperties }>(({ $styles }) => ({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.3)',
  backdropFilter: 'blur(4px)',
  ...($styles ?? {}),
}));

const StyledContentLoader = {
  Spinner,
  Title,
  Wrapper,
  Overlay,
};

export default StyledContentLoader;
