import React, { FC, memo, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { CalendarComponent } from '@farmlink/farmik-ui';

import { TableFiltersBuilderStore as BuilderStore } from '../../../mobx/stores';
import { TableFiltersBuilderController as IBuilderController } from '../../../mobx/controllers';
import { ITableFiltersBuilderFilter as IFilter } from '../../../models/data';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { useStore } from '../../../../../utils/helpers/mobx';

interface IProps {
  filter: IFilter;
}

const TableFiltersBuilderDateRangeFilter: FC<IProps> = ({ filter }) => {
  const builderStore = useStore(BuilderStore);
  const builderController = useStore(IBuilderController);

  const appliedStartDateValue = builderStore.getAppliedValue(
    filter.builderId,
    filter.id
  )?.dateValue;

  const appliedEndDateValue = builderStore.getAppliedValue(
    filter.builderId,
    filter.dateRangeOptions.relatedEndDateFilterId
  )?.dateValue;

  const defaultValue = useMemo<[string, string]>(() => {
    if (!appliedStartDateValue) {
      return;
    }

    if (appliedEndDateValue && appliedEndDateValue) {
      return [
        moment(appliedStartDateValue).format('DD.MM.YYYY'),
        moment(appliedEndDateValue).format('DD.MM.YYYY'),
      ];
    }

    return [
      moment(appliedStartDateValue).format('DD.MM.YYYY'),
      moment(appliedStartDateValue).format('DD.MM.YYYY'),
    ];
  }, [appliedStartDateValue, appliedEndDateValue]);

  const handleChange = useCallback((dateRange: [Date, Date]) => {
    builderController.selectDataRange(filter.builderId, filter.id, dateRange);
  }, []);

  const getDataTestId = useDataTestId();

  return (
    <CalendarComponent
      onChange={handleChange}
      placeholder={filter.name}
      datePickerOptions={{ dateRange: filter.dateRangeOptions?.dateRange }}
      oneValueRangeOptions={{
        period: 1,
      }}
      defaultValue={defaultValue}
      dataTestId={
        getDataTestId(`date-range-filter-with-key-${filter.id as string}`)['data-test-id']
      }
      componentType={'secondary'}
      inputHeight={'34px'}
    />
  );
};

TableFiltersBuilderDateRangeFilter.displayName = 'TableFiltersBuilderDateRangeFilter';

export default memo(observer(TableFiltersBuilderDateRangeFilter));
