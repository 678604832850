import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import EditZoneInfoModal from './EditZoneInfoModal';

const exitZoneInfoModalConfig: TModalConfig = {
  id: 'editZoneInfoModal',
  name: 'editZoneInfoModal',
  title: '',
  type: EModalType.Custom,
  dataTestId: 'edit-zone-info-modal',
  children: EditZoneInfoModal,
  styledProps: {
    $modalPaddings: '40px 60px',
    $size: EModalSize.Medium,
  },
};

export default exitZoneInfoModalConfig;
