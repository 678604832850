import { IExperimentStep } from '../../../../models/as-fields/experiments';
import { TResponseList } from '../../../../models/common/response';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetExperimentStepFullListReq = {};

type TGetExperimentStepFullListRes = {} & TResponseList<IExperimentStep>;

const getExperimentStepFullList: TApiRoute & {
  request: TGetExperimentStepFullListReq;
  response: TGetExperimentStepFullListRes;
} = {
  url: () => `/api/as-fields/experimentSteps/completeList`,
  method: 'GET',
  request: {} as TGetExperimentStepFullListReq,
  response: {} as TGetExperimentStepFullListRes,
  headers: {},
};

export type { TGetExperimentStepFullListRes, TGetExperimentStepFullListReq };

export { getExperimentStepFullList };
