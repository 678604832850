import { IExperiment } from '../../../models/as-fields/experiments';
import { TResponseList } from '../../../models/common/response';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TRequest = {
  cultureIdIn?: string[];
  typeIn?: string[];
  seasonYearIn?: number[];
  statusIn?: string[];
  districtIdIn?: string[];
  regionIdIn?: string[];
  organizationIdIn?: string[];
  assigneeIdIn?: string[];
};

type TResponse = {} & TResponseList<IExperiment>;

const getExperimentList: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: `/api/as-fields/experiment/list`,
  method: 'POST',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};

export { getExperimentList };
