const isDevelopment = (): boolean => {
  return process.env?.NODE_ENV === 'development';
};

const getRegistrationStep2Url = (): string => {
  const registrationStep2Url = isDevelopment()
    ? `http://localhost:3100/sign_up/?step=2&systemId=experiments`
    : window._env_.EXTERNAL_REG_STEP_2;

  return registrationStep2Url;
};

const getAuthUrl = (): string => {
  const authUrl = isDevelopment()
    ? `http://localhost:3100/?systemId=localhost3000`
    : window._env_.EXTERNAL_AUTH_URL;

  return authUrl;
};

const getProfileUrl = (): string => {
  const profileUrl = isDevelopment() ? 'http://localhost:3100' : window._env_.AUTH_URL;

  return `${profileUrl}/agroid`;
};

const getAssistanceBaseUrl = (): string => {
  const currentStage = window._env_.CURRENT_STAGE;

  return window._env_[`ASSISTANCE_${currentStage}_URL`] ?? 'http://localhost:3000/';
};

const getActiveFirstLevelRoot = (pathname: string): string => {
  return pathname.slice(1).split('/')[0];
};

const getAssistanceTaskListUrl = (orgId: string): string => {
  const assistanceBaseUrl = getAssistanceBaseUrl();

  return `${assistanceBaseUrl}/${orgId}/tasks`;
};

const getAssistanceTaskUrl = (orgId: string, taskId: string): string => {
  const assistanceBaseUrl = getAssistanceBaseUrl();

  return `${assistanceBaseUrl}/${orgId}/tasks/${taskId}`;
};

const getAssistanceChecklistUrl = (orgId: string, taskId: string, checklistId: string): string => {
  const assistanceBaseUrl = getAssistanceBaseUrl();

  return `${assistanceBaseUrl}/${orgId}/tasks/${taskId}/checklists/${checklistId}`;
};

const goToAssistanceTaskList = (orgId: string): void => {
  window.open(getAssistanceTaskListUrl(orgId));
};

const goToAssistanceTask = (orgId: string, taskId: string): void => {
  window.open(getAssistanceTaskUrl(orgId, taskId));
};

const goToAssistanceChecklist = (orgId: string, taskId: string, checklistId: string): void => {
  window.open(getAssistanceChecklistUrl(orgId, taskId, checklistId));
};

const systemHelperCollection = {
  isDevelopment,
  getAssistanceBaseUrl,
  getRegistrationStep2Url,
  getAuthUrl,
  getActiveFirstLevelRoot,
  getProfileUrl,
  goToAssistanceTaskList,
  goToAssistanceTask,
  goToAssistanceChecklist,
};

export default systemHelperCollection;
