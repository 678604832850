import { ITaskReportInstance } from '../../../../../../../../../../../api/models/as-fields/task/Task';
import { IAttributeValue } from '../../../../../../../../../../common/features/DynamicTable/models';
import { ReportsInstWithAttrValList as InstWithListOfAttrData } from '../../../../common/models/InstWithAttrValList/InstWithAttrValList';
import { reportsDynamicTableInstanceHelpers } from '../../../../common/utils/helpers';
import { TaskReportInstanceHeader as InstanceHeader } from '../../../components';
import { TaskReportInstWithCustomProp as InstanceWithCustomProp } from '../../../models';
import AttributeValueHelpers from '../TaskReportAttributeValueHelpers/TaskReportAttributeValue.helpers';

const { createAttrValList } = reportsDynamicTableInstanceHelpers;

const getInstName = (inst: ITaskReportInstance, order: number): string => {
  if (inst.type === 'AGGREGATE') {
    return 'Среднее';
  } else {
    return `Точка ${order}`;
  }
};

const isSelected = (instanceId, aggregateInstanceLinks) => {
  return aggregateInstanceLinks.some(id => id === instanceId);
};

const createInstance = (
  { instance, attrWithValList }: InstWithListOfAttrData,
  index: number,
  aggregateInstanceLinks: string[]
): InstanceWithCustomProp => {
  const isAggregate = instance.type === 'AGGREGATE';

  const { createDateAttr, createViewChecklist } = AttributeValueHelpers;

  const attrValueList: IAttributeValue[] = [
    ...createAttrValList(instance, attrWithValList),
    createDateAttr(instance, instance.lastUpdate),
    createViewChecklist(instance.id, {
      isAggregateInstance: isAggregate,
    }),
  ];

  return {
    id: instance.id,
    tableId: 'auditsTaskReport',
    name: getInstName(instance, index),
    attributeValues: attrValueList,
    Component: !isAggregate ? InstanceHeader : null,
    customProp: {
      isSelected: isSelected(instance.id, aggregateInstanceLinks),
    },
  };
};

const createInstanceList = (
  instListWithListOfAttrData: InstWithListOfAttrData[],
  aggregateInstanceLinks: string[]
): InstanceWithCustomProp[] => {
  return instListWithListOfAttrData.map((inst, index) =>
    createInstance(inst, index, aggregateInstanceLinks)
  );
};

const taskReportDynamicTableInstanceHelpers = { createInstance, createInstanceList };

export default taskReportDynamicTableInstanceHelpers;
