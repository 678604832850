import { IChecklistInstance } from '../Instance/ChecklistInstance.model';
import { IDictionaryEntity } from '../../../da-dictionary/dictionary';

enum EChecklistAttributeType {
  Boolean = 'BOOLEAN',
  Int = 'INT',
  Double = 'DOUBLE',
  String = 'STRING',
  DictionaryLink = 'DICTIONARY_LINK',
  Enum = 'ENUM',
  UserDictionaryLink = 'USER_DICTIONARY_LINK',
  FileLink = 'FILE_LINK',
  ChecklistInstanceLink = 'CHECKLIST_INSTANCE_LINK',
  LongString = 'LONGSTRING',
  Date = 'DATE',
}

interface IChecklistAttribute {
  order: number;
  isRequired: boolean;
  isActive: boolean;
  isTitle: boolean;
  id: string;
  checkListId: string;
  hasChildren: boolean;
  attribute: {
    id: string;
    type: EChecklistAttributeType;
    name: string;
    checklistLink: string | null;
    checklistLinkPublicId: string | null;
    dictionaryLink: string | null;
    isMultiselect: boolean;
    toolTip?: string;
    placeholder?: string;
  };
  toolTip?: string;
  dependencyAttribute?: IChecklistAttribute;
  dependencyAttributeId?: string;
  displayParent?: boolean;
  parentId?: string;
  min?: number;
  max?: number;
  normal?: number;
  stageId?: string;
}

interface IChecklistAttributeValue {
  id: string;
  checkListAttributeId: string;
  attributeId?: string;
  integerValue?: number;
  integerMinValue?: number;
  integerMaxValue?: number;
  doubleValue?: number;
  skip?: boolean;
  doubleMinValue?: number;
  doubleMaxValue?: number;
  stringValue?: string;
  longStringValue?: string;
  booleanValue?: boolean;
  enumValues?: IChecklistAttributeEnumValue[];
  userDictionaryValues?: IChecklistAttributeUserDictionary[];
  dictionaryValueList?: IDictionaryEntity[];
  fileValue?: IChecklistAttributeFileValue[];
  dateValue?: string;
  checkListInstanceValue?: IChecklistInstance[];
}

interface IChecklistAttributeFileValue {
  id: string;
  fileName?: string;
  fileContentType?: string;
  fileSize?: number;
  downloadUrl?: string;
}

interface IChecklistAttributeEnumValue {
  id: string;
  value: string;
}

interface IChecklistAttributeUserDictionary {
  id: string;
  value: string;
}

export type { IChecklistAttribute, IChecklistAttributeValue };

export { EChecklistAttributeType };
