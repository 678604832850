import { FC, FormEvent, useEffect } from 'react';
import { Button, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesController } from '../../../../../mobx/controllers';
import { useForm } from '../../../../../../../../../../../../../../common/features/form/utils/hooks';
import { changeCultureZoneNameFormConfig } from '../../../../forms';
import { IChangeCultureZoneForm } from '../../../../forms/changeCultureZoneName/changeCultureZoneName.form.config';
import { useErrorModal } from '../../../../../../../../../../../../../../common/utils/hooks';
import { ZonesStore } from '../../../../../mobx/stores';
import { useMap } from '../../../../../../../../../../../../../../common/features/experimentsMap/hooks';

import Styled from './ChangeCultureZoneName.styles';

const ChangeCultureZoneName: FC = () => {
  const { editableZoneId, getZone } = useStore(ZonesStore);
  const { changeCultureZoneName } = useStore(ZonesController);

  const { closeModal } = useModal();
  const { showErrorModal } = useErrorModal();
  const { changeCultureZoneName: changeNameOnTheMap } = useMap();

  const { elements, registerForm, submitForm, changeListOfFormValue } =
    useForm<IChangeCultureZoneForm>(changeCultureZoneNameFormConfig.formKey);

  const { name: NameElement } = elements;

  useEffect(() => {
    registerForm(changeCultureZoneNameFormConfig);
  }, []);

  useEffect(() => {
    if (editableZoneId) {
      const zones = getZone(editableZoneId);

      changeListOfFormValue({ name: zones.name });
    }
  }, [editableZoneId]);

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();

    await submitForm(async validatedData => {
      await changeCultureZoneName(validatedData, closeModal, showErrorModal, changeNameOnTheMap);
    });
  };

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <Styled.Header>
        <Styled.Title data-test-id={'change-culture-zone-name-modal-title'}>
          Название участка
        </Styled.Title>
        <Styled.Description data-test-id={'change-culture-zone-name-modal-description'}>
          Придумайте понятное название для участка, чтобы не запутаться
        </Styled.Description>
      </Styled.Header>
      <Styled.Body>{NameElement ? <NameElement /> : null}</Styled.Body>
      <Styled.Footer>
        <Button
          type={'button'}
          color={'default'}
          onClick={closeModal}
          dataTestId={'change-culture-zone-name-modal-deny-button'}
        >
          Отменить
        </Button>
        <Button
          type={'submit'}
          color={'primary'}
          dataTestId={'change-culture-zone-name-modal-success-button'}
        >
          Сохранить
        </Button>
      </Styled.Footer>
    </Styled.Form>
  );
};

ChangeCultureZoneName.displayName = 'ChangeCultureZoneName';

export default observer(ChangeCultureZoneName);
