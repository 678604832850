import { FC, memo } from 'react';

import { IComparisonTableBuilderHeader as IHeader } from '../../../models/data';
import { ComparisonTableBuilderHeaderAutoRenderContainer as HeaderAutoRenderContainer } from '../ComparisonTableBuilderHeaderAutoRenderContainer';
import { ComparisonTableBuilderHeaderCustomRenderContainer as CustomRenderContainer } from '../ComparisonTableBuilderHeaderCustomRenderContainer';

interface IProps {
  header: IHeader;
}

const ComparisonTableBuilderHeaderContainer: FC<IProps> = ({ header }) => {
  switch (header.renderType) {
    case 'auto':
      return <HeaderAutoRenderContainer header={header} />;

    case 'custom':
      return <CustomRenderContainer header={header} />;

    default:
      return <></>;
  }
};

ComparisonTableBuilderHeaderContainer.displayName = 'ComparisonTableBuilderHeaderContainer';

export default memo(ComparisonTableBuilderHeaderContainer);
