const isNumWithLongFractPart = (value: string | number): boolean => {
  if (!value) {
    return false;
  }

  if (isNaN(Number(value))) {
    return false;
  }

  return String(value).split('e').length > 1;
};

export default isNumWithLongFractPart;
