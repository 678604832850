import { useEffect } from 'react';

import { IUseFocusTarget } from './useFocusTarget.types';

const useFocusTarget: IUseFocusTarget = ({ focusTargetId, isFocused, targetRef, setIsFocused }) => {
  useEffect(() => {
    if (!focusTargetId) {
      return;
    }

    if (targetRef?.current?.id === focusTargetId) {
      if (targetRef.current.nodeName === 'DIV') {
        targetRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      } else {
        targetRef.current.focus();
      }

      setIsFocused(false);
    }
  }, [isFocused]);
};

export default useFocusTarget;
