import { FC, PropsWithChildren } from 'react';

import { EExperimentStatus } from '../../../../../../../../../../../../api/models/as-fields/experiments';

import Styled from './ColorBadge.styles';

interface IProps {
  badgeStatus: EExperimentStatus;
}

const ColorBadge: FC<PropsWithChildren<IProps>> = ({ badgeStatus, children }) => {
  return <Styled.BadgeWrapper $badgeStatus={badgeStatus}>{children}</Styled.BadgeWrapper>;
};

ColorBadge.displayName = 'ColorBadge';

export default ColorBadge;
