import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../assets/styles/colors';

const { SecondaryGray } = ECommonColor;

const RowWrapper = styled.tr<{
  $isSelectedRow?: boolean;
  $needToShowAdditionalRows?: boolean;
  $isOnRowClick?: boolean;
}>`
  ${({ $isSelectedRow }) =>
    $isSelectedRow &&
    css`
      background-color: ${SecondaryGray};
    `}
  ${({ $isOnRowClick }) =>
    $isOnRowClick &&
    css`
      cursor: pointer;
    `}
  ${({ $needToShowAdditionalRows }) =>
    !$needToShowAdditionalRows &&
    css`
      &:not(:last-child) {
        border-bottom: ${({ theme }) => `1px solid ${theme?.Colors?.borderDefault ?? `#e9e6f0`}`};
      }
    `}
`;

const AdditionalRowWrapper = styled.tr<{
  $isLastRow?: boolean;
  $isOnRowClick?: boolean;
}>`
  ${({ $isLastRow }) =>
    $isLastRow &&
    css`
      &:not(:last-child) {
        border-bottom: ${({ theme }) => `1px solid ${theme?.Colors?.borderDefault ?? `#e9e6f0`}`};
      }
    `}
  ${({ $isOnRowClick }) =>
    $isOnRowClick &&
    css`
      cursor: pointer;
    `}
`;

const StyledTableBodyRow = {
  RowWrapper,
  AdditionalRowWrapper,
};

export default StyledTableBodyRow;
