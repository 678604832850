import { makeAutoObservable } from 'mobx';

import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { ISeason } from '../../../../../../../../../../../../api/models/as-fields/seasons';
import {
  ExperimentStepsService,
  NutritionHistoryService,
} from '../../../../../../../../../../../common/mobx/services/as-fields';
import { DictionaryService } from '../../../../../../../../../../../common/mobx/services/da-dictionary';
import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { formatDateToISO } from '../../../../../../../../../../../common/utils/helpers/dates';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { toNumber } from '../../../../../../../../../../../common/utils/helpers/numbers';
import { createDictionaryEntitySelectOptionList } from '../../../../../../../../../../../common/utils/helpers/selectOptions';
import { ITechOperationForm } from '../../config/forms/techOperationForm';
import { createSelectOptionInBbch } from '../../helpers/createSelectOptions';
import { TechOperationService } from '../services/TechOperation.service';
import { CalculationStore } from '../store/Calculation/Calculation.store';
import { TechOperationStore } from '../store/Calculation/TechOperation.store';
import { EExperimentStepType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';

import { CalculationController } from './Calculation/Calculation.controller';

@provide.singleton()
export class TechOperationController {
  @lazyInject(DictionaryService)
  dictionaryService: DictionaryService;

  @lazyInject(ExperimentStepsService)
  experimentStepService: ExperimentStepsService;

  @lazyInject(CalculationController)
  calculationController: CalculationController;

  @lazyInject(TechOperationService)
  techOperationService: TechOperationService;

  @lazyInject(NutritionHistoryService)
  nutritionHistoryService: NutritionHistoryService;

  @lazyInject(CalculationStore)
  calculationStore: CalculationStore;

  @lazyInject(TechOperationStore)
  techOperationStore: TechOperationStore;

  constructor() {
    makeAutoObservable(this);
  }

  fetchTechOperationTypes = async () => {
    const techOperationTypes = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'techOperationType',
        latestVersion: true,
        fetchAttributes: true,
        attrs: {
          forUH: true,
        },
      },
      {
        sort: 'name,asc',
      }
    );

    return techOperationTypes;
  };

  fetchPhenophase = async (cultureZone: IExperimentCultureZone) => {
    const phenophase = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'bbch',
        dependencyName: 'culture',
        dependencyRecordId: cultureZone.cultureZone.culture.id,
      },
      {
        sort: 'path,asc',
      }
    );

    return phenophase;
  };

  fetchBbch = async (phenophaseId: string) => {
    const bbch = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'bbch',
        level: 3,
        parentId: phenophaseId,
      },
      {
        sort: 'path,asc',
      }
    );

    return bbch;
  };

  getTechOperationTypesOptionList = async () => {
    const { content } = await this.fetchTechOperationTypes();

    return createDictionaryEntitySelectOptionList(content);
  };

  getPhenophases = async (cultureZone: IExperimentCultureZone) => {
    const { content } = await this.fetchPhenophase(cultureZone);

    this.techOperationStore.setPhenophase(content.filter(item => item.level === 2));
    this.techOperationStore.setBbch(content.filter(item => item.level === 3));
  };

  getPhenophaseOptionList = () => {
    const phenophases = this.techOperationStore.phenphase;

    return createDictionaryEntitySelectOptionList(phenophases);
  };

  getBbchOptionList = (phenophaseId: string) => {
    const { bbch } = this.techOperationStore;

    const currentBbch = bbch.filter(item => item.parentId === phenophaseId);

    return createSelectOptionInBbch(currentBbch);
  };

  getBbchCodeById = (bbchId: string): string => {
    const { bbch } = this.techOperationStore;

    const [foundBbch] = bbch.filter(({ id }) => id === bbchId);

    return foundBbch?.code;
  };

  getPhenophaseCodeById = (phenophaseId: string): string => {
    const { phenphase } = this.techOperationStore;

    const [foundPhenophase] = phenphase.filter(({ id }) => id === phenophaseId);

    return foundPhenophase?.code;
  };

  createTechOperation = async (
    form: ITechOperationForm,
    cultureZone: IExperimentCultureZone,
    experimentId: string
  ) => {
    const id = await this.experimentStepService.createExperimentStep({
      cultureZoneExperimentId: cultureZone.id,
      experimentId,
      name: form.name,
      phenophaseId: form.bbshFrom,
      phenophaseEndId: form.bbshTo,
      planStartDate: formatDateToISO(new Date(form.startDate)),
      planEndDate: formatDateToISO(new Date(form.endDate)),
      type: EExperimentStepType.Plan,
      techOperationTypeId: form.typeOperation,
      servicePricePerArea: toNumber(form.price),
      usageMethodTypeId: form.usageMethodType,
    });

    await this.calculationController.fetchExperimentSteps(
      {
        experimentId,
        type: EExperimentStepType.Plan,
        cultureZoneExperimentId: cultureZone.id,
      },
      cultureZone,
      cultureZone.type === EExperimentCultureZoneType.Control
    );

    return id;
  };

  updateTechOperation = async (
    form: ITechOperationForm,
    cultureZone: IExperimentCultureZone,
    experimentStep: IExperimentStep,
    experimentId: string
  ) => {
    await this.experimentStepService.updateExperimentStep({
      experimentStepId: experimentStep.id,
      cultureZoneExperimentId: cultureZone.id,
      experimentId,
      name: form.name,
      phenophaseId: form.bbshFrom,
      phenophaseEndId: form.bbshTo,
      planStartDate: formatDateToISO(new Date(form.startDate)),
      planEndDate: formatDateToISO(new Date(form.endDate)),
      type: EExperimentStepType.Plan,
      techOperationTypeId: form.typeOperation,
      servicePricePerArea: toNumber(form.price),
      usageMethodTypeId: form.usageMethodType,
    });

    await this.calculationController.fetchExperimentSteps(
      { experimentId, type: EExperimentStepType.Plan, cultureZoneExperimentId: cultureZone.id },
      cultureZone,
      cultureZone.type === EExperimentCultureZoneType.Control
    );
  };

  getCurrentSeason = async (organizationId: string, seasonYear: number): Promise<ISeason> => {
    const { getSeasonList } = this.techOperationService;

    if (!organizationId) {
      return;
    }

    const seasonList = await getSeasonList(organizationId);

    const currentSeasonInfo = seasonList.find(
      (season: ISeason): boolean => season.year === seasonYear
    );

    return currentSeasonInfo;
  };

  getUsageMethodTypeOptions = async (): Promise<ISelectOption[]> => {
    const list = await this.experimentStepService.getUsageMethodTypeOptions();

    return list;
  };
}
