import { makeAutoObservable } from 'mobx';

import {
  TGetCultureZoneExperimentListReq,
  TGetCultureZoneExperimentListRes,
} from '../../../../../../../../../../../../api';
import { AxiosService } from '../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
export class HistoryService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  constructor() {
    makeAutoObservable(this);
  }

  getCultureZoneExperimentList = async (
    payload: TGetCultureZoneExperimentListReq
  ): Promise<TGetCultureZoneExperimentListRes> => {
    const { getCultureZoneExperimentList } = this.axiosService.api;

    try {
      const fetchedCultureZoneList = await getCultureZoneExperimentList(payload, {
        omit: ['experimentId'],
      });

      return fetchedCultureZoneList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}
