import { FC, memo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Plug } from '../../../../../../../../../../../common/components/ui';
import cultureIcon from '../../../../../../assets/icons/culture.svg';
import { ECreateExperimentRoute } from '../../../../../../routes';

const NoPreviousCulturePlug: FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(generatePath(`../${ECreateExperimentRoute.Zones}`));
  };

  return (
    <Plug
      icon={cultureIcon}
      title="На участке отсутствует предшественник. Вернитесь к предыдущему шагу и укажите предшественника."
      primaryButtonTitle="Указать предшественника"
      onPrimaryButtonClick={handleClick}
      buttonExist
    />
  );
};

export default memo(NoPreviousCulturePlug);
