import styled from 'styled-components';

const DeviceScreenDummyElem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(21, 31, 50, 0.8);
  backdrop-filter: blur(16px);
  z-index: 100001;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #ffffff;
  img {
    margin-bottom: 24px;
  }
`;

const StyledDeviceScreenDummy = {
  DeviceScreenDummyElem,
};

export default StyledDeviceScreenDummy;
