import { HTMLProps } from 'react';
import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../common/assets/styles/colors';

const { SecondaryDarkGray, AddSky } = ECommonColor;

interface ITabsProps extends HTMLProps<HTMLButtonElement> {
  isActive: boolean;
}

const Tab = styled.button<ITabsProps>`
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  margin-right: 12px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? `${AddSky}` : `${SecondaryDarkGray}`)};
  ${({ isActive }) =>
    isActive &&
    `
    &:after {
      content: '';
      height: 2px;
      position: absolute;
      bottom: -8px;
      right: 0;
      left: 0;
      background-color: ${AddSky}
    }
  `}
`;

const Wrapper = styled.div``;

const StyledTabs = {
  Tab,
  Wrapper,
};

export default StyledTabs;
