import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import {
  EExperimentCultureZoneType,
  IExperimentCultureZone,
} from '../../../../models/as-fields/experiments/ExperimentCultureZone';

type TCreateCultureZoneExperimentReq = {
  experimentId: string;
  type: EExperimentCultureZoneType;
  cultureZoneId: string;
  plannedYield: number;
  plannedCropPrice: number;
  previousCultureIds: string[];
};

type TCreateCultureZoneExperimentRes = IExperimentCultureZone;

const createCultureZoneExperiment: TApiRoute & {
  request: TCreateCultureZoneExperimentReq;
  response: TCreateCultureZoneExperimentRes;
} = {
  url: () => `/api/as-fields/cultureZoneExperiment`,
  method: 'POST',
  request: {} as TCreateCultureZoneExperimentReq,
  response: {} as TCreateCultureZoneExperimentRes,
  headers: {},
};

export type { TCreateCultureZoneExperimentReq, TCreateCultureZoneExperimentRes };

export { createCultureZoneExperiment };
