import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../assets/styles/colors';

import arrowRight from './assets/images/arrow-black-right-14x8.svg';
import arrowLeft from './assets/images/arrow-black-left-14x8.svg';

const { SecondaryGray } = ECommonColor;

const disableArrow = (isDisabled: boolean) => {
  if (isDisabled) {
    return css`
      pointer-events: none;
      cursor: auto;
    `;
  }
};

const ArrowRight = styled.div<{ $isRightArrowDisabled: boolean }>`
  width: 7.64px;
  height: 13.06px;

  opacity: ${({ $isRightArrowDisabled }) => ($isRightArrowDisabled ? 0.5 : 1)};
  background: url(${arrowRight});
`;

const ArrowLeft = styled.div<{ $isLeftArrowDisabled: boolean }>`
  width: 7.64px;
  height: 13.06px;

  opacity: ${({ $isLeftArrowDisabled }) => ($isLeftArrowDisabled ? 0.5 : 1)};
  background: url(${arrowLeft});
`;

const ArrowWrapper = styled.div<{ $isDisabled: boolean }>`
  width: 40px;
  height: 40px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  border-radius: 12px;

  cursor: pointer;

  background-color: ${SecondaryGray};

  ${({ $isDisabled }) => disableArrow($isDisabled)}
`;

const ArrowsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 16px;
  margin-top: 16px;
  gap: 16px;

  position: absolute;
  top: 0;
  right: 0;

  background: linear-gradient(270deg, #fff 63.54%, rgba(255, 255, 255, 0.64) 100%);
`;

const Wrapper = styled.div`
  position: relative;
  overflow-y: hidden;
  height: 72px;
`;

const StyledSliderControls = {
  ArrowRight,
  ArrowLeft,
  ArrowWrapper,
  ArrowsWrapper,
  Wrapper,
};

export default StyledSliderControls;
