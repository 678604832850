import { FC, memo, useMemo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { useDataTestId } from '../../../../../../../common/utils/hooks/locators';

import { TFDCellComponentProps } from './FDCellComponent.type';
import { getFDCellComponentName } from './utils/helpers';
import Styled from './FDCellComponent.styles';

const FDCellComponent: FC<TFDCellComponentProps> = ({ rowData }) => {
  const FD = useMemo(() => rowData?.region?.parent?.path, [rowData?.region?.parent?.path]);
  const FDFullName = useMemo(() => rowData?.region?.parent?.name, [rowData?.region?.parent?.name]);

  const FDName = getFDCellComponentName(FD);

  const getDataTestId = useDataTestId('experiments-table-cell-with-key-district');

  return (
    <AutoTooltip position="top" content={FDFullName}>
      <Styled.Wrapper {...getDataTestId()}>{FDName}</Styled.Wrapper>
    </AutoTooltip>
  );
};

export default memo(FDCellComponent);
