import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IExperimentTotalComparison } from '../../../models/as-fields/experiments/ExperimentTotalComparisonTable/ExperimentTotalComparisonTable';

type TRequest = {
  id: string;
};

type TResponse = IExperimentTotalComparison;

export const getExperimentTotalComparisonTable: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/experiment/${id}/comparison`,
  method: 'GET',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};
