import { IExperimentPlanTable } from '../../../models/as-fields/experiments/ExperimentPlanTable';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TRequest = {
  id: string;
};

type TResponse = IExperimentPlanTable;

export const getExperimentPlanTable: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/experiment/${id}/plan`,
  method: 'GET',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};
