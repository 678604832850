import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TDeleteNutritionHistoryItemReq = { experimentStepId: string; nutritionHistoryItemId: string };
type TDeleteNutritionHistoryItemRes = {} & { id: string };

const deleteNutritionHistoryItem: TApiRoute & {
  request: TDeleteNutritionHistoryItemReq;
  response: TDeleteNutritionHistoryItemRes;
} = {
  url: ({ nutritionHistoryItemId, experimentStepId }) =>
    `/api/as-fields/nutrition-history-item/experimentStep/${experimentStepId}/fertilizer/${nutritionHistoryItemId}`,
  method: 'DELETE',
  request: {} as TDeleteNutritionHistoryItemReq,
  response: {} as TDeleteNutritionHistoryItemRes,
  headers: {},
};

export { deleteNutritionHistoryItem };
export type { TDeleteNutritionHistoryItemReq, TDeleteNutritionHistoryItemRes };
