import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { ExperimentZonesCarouselItem } from '../../../../components';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionStore } from '../../mobx/stores';
import { ExecutionController } from '../../mobx/controllers';
import { ConfigurableSlider } from '../../../../../../../../../common/components/ui';

const ExecutionZonesCarousel: FC = () => {
  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);

  const sliderRef = useRef<HTMLDivElement>();

  const handleSelectItem = useCallback((itemId: string) => {
    executionStore.setSelectedZoneId(itemId);
  }, []);

  const onScrollEnd = useCallback(() => {
    executionStore.setScrollStep(2);
  }, []);

  useEffect(() => {
    const slider = sliderRef.current;

    if (executionStore.unhandledInvalidRowId && executionStore.scrollStep === 1) {
      if (!(slider?.clientWidth < slider?.scrollWidth)) {
        executionStore.setScrollStep(2);

        return;
      }

      const element = document.getElementById(executionStore.selectedZoneId);

      slider.addEventListener('scrollend', onScrollEnd);

      setTimeout(() => {
        slider.scrollTo({ behavior: 'auto', left: 1 });
        slider.scrollTo({
          behavior: 'auto',
          left: element.offsetLeft / 2,
        });
      }, 100);
    }

    return () => {
      slider.removeEventListener('scrollend', onScrollEnd);
    };
  }, [executionStore.unhandledInvalidRowId, executionStore.scrollStep, sliderRef.current]);

  return (
    <ConfigurableSlider
      paddingTop={24}
      dataTestId="execution-culture-zones-carousel"
      ref={sliderRef}
    >
      {executionController.carouselItemList.map(item => {
        return (
          <ExperimentZonesCarouselItem
            id={item.id}
            key={item.id}
            name={item.name}
            description={item.description}
            selected={item.id === executionStore.selectedZoneId}
            img={item.img}
            handleSelectItem={handleSelectItem}
            sections={executionStore.zoneList}
          />
        );
      })}
    </ConfigurableSlider>
  );
};

ExecutionZonesCarousel.displayName = 'ExecutionZonesCarousel';

export default observer(ExecutionZonesCarousel);
