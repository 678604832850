import moment from 'moment';

const getFormattedDateRangeLabel = (startDate: string | Date, endDate: string | Date): string => {
  const formattedStartDate = moment(startDate).format('DD.MM.YYYY');
  const formattedEndDate = moment(endDate).format('DD.MM.YYYY');

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate;
  }

  return `${formattedStartDate} — ${formattedEndDate}`;
};

export default getFormattedDateRangeLabel;
