import React, { FC, memo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@farmlink/farmik-ui';

import { ITableFiltersBuilderFilter as IFilter } from '../../../models/data';
import { TableFiltersBuilderController as BuilderController } from '../../../mobx/controllers';
import { TableFiltersBuilderStore as BuilderStore } from '../../../mobx/stores';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { useStore } from '../../../../../utils/helpers/mobx';

import Styled from './TableFiltersBuilderBooleanFilter.styles';

interface IProps {
  filter: IFilter;
}

const TableFiltersBuilderBooleanFilter: FC<IProps> = ({ filter }) => {
  const builderStore = useStore(BuilderStore);
  const builderController = useStore(BuilderController);

  const appliedValue = builderStore.getAppliedValue(filter.builderId, filter.id)?.booleanValue;
  const selectedValue = builderStore.getSelectedValue(filter.builderId, filter.id)?.booleanValue;

  const defaultValue = Boolean(selectedValue) || Boolean(appliedValue) || false;

  const handleChange = useCallback(booleanValue => {
    builderController.selectBooleanFilter(filter.builderId, filter.id, booleanValue);
  }, []);

  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <Checkbox
        label={'Только просроченные'}
        value={defaultValue}
        onChange={handleChange}
        dataTestId={getDataTestId(`boolean-filter-with-key-${filter.id as string}`)['data-test-id']}
        stylePreset={'secondary'}
      />
    </Styled.Wrapper>
  );
};

TableFiltersBuilderBooleanFilter.displayName = 'TableFiltersBuilderBooleanFilter';

export default memo(observer(TableFiltersBuilderBooleanFilter));
