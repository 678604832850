import _ from 'lodash';
import { observer } from 'mobx-react';
import { FC, memo, useContext, useEffect, useMemo } from 'react';

import { IExperimentStep } from '../../../../../../../api/models/as-fields/experiments';
import { INutritionHistory } from '../../../../../../../api/models/as-fields/plan/NutrationHistory';
import { SliderContainer } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/history/containers/History/containers';
import { SliderContext } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/history/containers/History/context';
import { TActiveSubstances } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/history/containers/History/models';
import { activeSubstances } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/utils/const';
import { ActiveSubstancesColumn as Column } from '../../components';

import Styled from './ActiveSubstancesColumn.style';

type TActiveSubstancesColumnComponent = {
  nutritionHistories: INutritionHistory[];
  experimentSteps: IExperimentStep[];
};

const ActiveSubstancesColumnComponent: FC<TActiveSubstancesColumnComponent> = ({
  nutritionHistories,
  experimentSteps,
}) => {
  const { onToggleControlsVisibility } = useContext(SliderContext);

  const activeSubstanceColumns = useMemo(
    () =>
      nutritionHistories.reduce<TActiveSubstances>((acc, nutritionHistory) => {
        nutritionHistory.items.forEach(item => {
          const currentActiveSubstance = _.omit(item?.fertilizer?.attrs, 'price', 'cost');

          Object.keys(currentActiveSubstance).forEach(key => {
            const attrName = key.replace('substance.', '');

            if (activeSubstances[attrName]) {
              acc.set(attrName, { substanceName: activeSubstances[attrName] });
            }
          });
        });

        return acc;
      }, new Map()),
    [nutritionHistories, experimentSteps]
  );

  const sortedActiveSubstanceColumns = useMemo(() => {
    const activeSubstanceColumnsValues = [...activeSubstanceColumns.entries()];

    return new Map(
      activeSubstanceColumnsValues.sort(([key1], [key2]) => {
        const activeSubstancesList = Object.keys(activeSubstances);

        if (activeSubstancesList.indexOf(key1) < activeSubstancesList.indexOf(key2)) {
          return -1;
        } else {
          return 1;
        }
      })
    );
  }, [activeSubstanceColumns]);

  const sortedActiveSubstanceColumnsValues = useMemo(() => {
    return [...sortedActiveSubstanceColumns.values()];
  }, [sortedActiveSubstanceColumns]);

  useEffect(() => {
    if (sortedActiveSubstanceColumnsValues.length > 5) {
      onToggleControlsVisibility(true);
    } else {
      onToggleControlsVisibility(false);
    }
  }, [sortedActiveSubstanceColumnsValues]);

  return (
    <Styled.Wrapper>
      <SliderContainer isControl>
        {sortedActiveSubstanceColumnsValues.map(column => (
          <Column key={column.substanceName} columnName={column.substanceName} />
        ))}
      </SliderContainer>
    </Styled.Wrapper>
  );
};

ActiveSubstancesColumnComponent.displayName = 'ActiveSubstancesColumnComponent';

export default memo(observer(ActiveSubstancesColumnComponent));
