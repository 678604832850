import styled from 'styled-components';

import { EExperimentStatus } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { ECommonColor } from '../../../../../../../../../../../common/assets/styles/colors';

const {
  SecondaryDarkGray,
  GeneralBlack,
  GeneralWhite,
  AccentGreen,
  LightGreen,
  SecondaryGray,
  AccentPink,
  LightPink,
  Orange,
  SecondaryLightOrange,
  LightSky,
  AddSky,
  LightAqua,
  AccentAqua,
} = ECommonColor;

const getColors = (badgeStatus: EExperimentStatus): [ECommonColor, ECommonColor] => {
  switch (badgeStatus) {
    case EExperimentStatus.Draft:
      return [SecondaryDarkGray, SecondaryGray];
    case EExperimentStatus.InApproval:
      return [Orange, SecondaryLightOrange];
    case EExperimentStatus.Approved:
      return [AddSky, LightSky];
    case EExperimentStatus.InProgress:
      return [AccentGreen, LightGreen];
    case EExperimentStatus.Completed:
      return [GeneralBlack, SecondaryGray];
    case EExperimentStatus.FactAppruved:
      return [AddSky, LightSky];
    case EExperimentStatus.FullCompleted:
      return [AccentAqua, LightAqua];
    case EExperimentStatus.Canceled:
      return [AccentPink, LightPink];
    default:
      return [GeneralBlack, GeneralWhite];
  }
};

const BadgeWrapper = styled.div<{ $badgeStatus: EExperimentStatus }>`
  padding: 2px 8px !important;
  border-radius: 8px;
  width: fit-content;
  font-size: 14px;
  ${({ $badgeStatus }) => {
    const [fontColor, backgroundColor] = getColors($badgeStatus);

    return `
    color: ${fontColor};
    background-color: ${backgroundColor};
    `;
  }}
`;

const StyledColorBadge = {
  BadgeWrapper,
};

export default StyledColorBadge;
