import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../../../assets/styles/colors';

const { GeneralBlack } = ECommonColor;

const Icon = styled.div<{ $url: string }>`
  width: 12px;
  height: 12px;

  ${({ $url }) =>
    $url &&
    css`
      background: no-repeat center url(${$url});
    `}
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: ${GeneralBlack};
`;

const Wrapper = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  cursor: pointer;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const StyledLinearDropdownOption = {
  Icon,
  Title,
  Wrapper,
};

export default StyledLinearDropdownOption;
