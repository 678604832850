import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const StyledDocumentsActions = {
  Wrapper,
  ActionButton,
};

export default StyledDocumentsActions;
