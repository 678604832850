import { FC } from 'react';
import { observer } from 'mobx-react';
import { useContextualHelpActions, useModal } from '@farmlink/farmik-ui';
import { useNavigate } from 'react-router-dom';

import {
  ExecutionContainerHeader as ContainerHeader,
  ExecutionContentLoader,
} from '../../components';
import ComparisonTableBuilder from '../../../../../../../../../common/features/ComparisonTableBuilder/ComparisonTableBuilder';
import {
  checkEditableExperimentStatuses,
  createExecutionTableBuilderId as createTableBuilderId,
} from '../../helpers';
import { Container } from '../../../../../../../../../common/features/UI';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionStore } from '../../mobx/stores';
import {
  EComparisonTableName,
  EComparisonTableName as ETableName,
} from '../../../../../../../../constants/features';
import { ExecutionController } from '../../mobx/controllers';
import { IOperationFormModalPayload } from '../../../calculation/containers/Calculation/components/OperationForm/OperationForm';
import useExperimentsParams from '../../../../../../hooks/useExperimentsParams/useExperimentsParams';
import { ComparisonTableBuilderStore } from '../../../../../../../../../common/features/ComparisonTableBuilder/mobx/stores';
import { useSortBy } from '../../../../../../../../../common/utils/hooks/useSortBy';
import { CreateExperimentStore } from '../../../../mobx/stores';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';
import { Plug } from '../../../../../../../../../common/components/ui';
import { generateAuditsPath } from '../../../../../../../observations/utils/helpers';
import applicationTime from '../../assets/icons/application_time.svg';

import { ExecutionStepDictionaryService } from './mobx/services';
import Styled from './ExecutionSteps.styles';

const ExecutionSteps: FC = () => {
  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);
  const comparisonTableStore = useStore(ComparisonTableBuilderStore);
  const createExperimentStore = useStore(CreateExperimentStore);
  const { fetchPhenophaseBbchDictionaryOptionList } = useStore(ExecutionStepDictionaryService);

  const helpActions = useContextualHelpActions();

  const navigator = useNavigate();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.ExperimentsExperimentExecution,
    ContextualPaths.ExperimentsExperimentExecutionStepsTableTitle
  );

  const stepRowsGroupList =
    useSortBy(
      comparisonTableStore.getRowsGroupList(
        createTableBuilderId(executionStore.selectedZoneId, EComparisonTableName.ExecutionSteps)
      )
    ) || [];

  const modalActions = useModal();
  const params = useExperimentsParams();

  const handleAddTechOperationClick = () => {
    const modalPayload: IOperationFormModalPayload = {
      experimentId: params.experimentId,
      selectedCultureZone: executionStore.getExperimentZone(executionStore.selectedZoneId),
      onCreate: form => {
        const createdStepId = executionController.createExperimentStep(
          form,
          stepRowsGroupList.length + 1
        );

        return createdStepId;
      },
    };

    if (executionStore.bbchList.length === 0) {
      const cultureId = executionStore.getExperimentZone(executionStore.selectedZoneId)?.cultureZone
        ?.culture.id;

      fetchPhenophaseBbchDictionaryOptionList(cultureId).then(list =>
        executionStore.setBbchList(list)
      );
    }
    modalActions.openModalByModalId('addPlanTechOperation', modalPayload);
  };

  const handleAddAuditButtonClick = () => {
    navigator(generateAuditsPath('createAudit', { auditId: 'create' }), {
      state: {
        organizationName: createExperimentStore?.selectedExp?.organization?.name,
        organizationId: createExperimentStore?.selectedExp?.organization?.id,
        experimentName: createExperimentStore?.selectedExp?.name,
        experimentId: createExperimentStore?.selectedExp?.id,
      },
    });
  };

  const isShowTableData =
    executionStore.currentStepForUpdateList.length > 0 || executionStore.stepIdList.length > 0;

  return (
    <Styled.Wrapper>
      <Container
        headerChildren={
          <ContainerHeader title={'Техоперации'} ContextualHelpIcon={ContextualHelpIcon} />
        }
      >
        {executionStore.isPageLoading ? (
          <ExecutionContentLoader />
        ) : isShowTableData ? (
          <>
            <ComparisonTableBuilder
              builderId={createTableBuilderId(
                executionStore.selectedZoneId,
                ETableName.ExecutionSteps
              )}
              tableName={ETableName.ExecutionSteps}
            />

            {checkEditableExperimentStatuses(createExperimentStore?.selectedExp?.status) ? (
              <Styled.Footer>
                <Styled.AddTechOperationButton
                  color={'primary'}
                  type={'button'}
                  styleType={'outlined'}
                  onClick={handleAddTechOperationClick}
                  alignment={'center'}
                >
                  Добавить техоперацию
                </Styled.AddTechOperationButton>
              </Styled.Footer>
            ) : null}
          </>
        ) : (
          <Plug
            icon={applicationTime}
            title="Здесь можно будет внести фактические данные по опыту и сравнить их с плановыми"
            description="Сравнительная таблица будет сформирована после того, как вы добавите в опыт техоперации"
            primaryButtonTitle="Добавить техоперацию"
            secondaryButtonTitle="Добавить наблюдение"
            onPrimaryButtonClick={handleAddTechOperationClick}
            onSecondaryButtonClick={handleAddAuditButtonClick}
            buttonExist
          />
        )}
      </Container>
    </Styled.Wrapper>
  );
};

ExecutionSteps.displayName = 'ExecutionSteps';

export default observer(ExecutionSteps);
