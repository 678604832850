import {
  ActionCellComponent,
  InventoryValueCellComponent,
} from '../../../../../../../../../../../../common/components/ActiveSubstancesTable/cells';
import {
  ConsumptionCellComponent,
  CostCellComponent,
  PriceCellComponent,
} from '../../../../../../../../../../../../common/components/EconomyTable/cells';
import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../../../../../common/features/TableBuilder/models/configs';
import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentsTableBuilderId } from '../../../../../../../../../constants/configs';

@provide.transient()
class CalculationEconomyConfigsService {
  createConfig = (
    onClickAddTechoperation,
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    isViewOnly
  ): IBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList(
      onClickAddTechoperation,
      onClickDeleteTechoperation,
      onClickEditTechoperation,
      onClickDeleteInventoryValue,
      onClickEditInventoryValue,
      isViewOnly
    );
    const rowsGroupList = this.createRowsGroupList();

    return {
      id: EExperimentsTableBuilderId.CalculationEconomy,
      columnConfigList: columnList,
      cellConfigList: cellList,
      rowsGroupConfigList: rowsGroupList,
    };
  };

  createColumnList = (): IColumnConfig[] => {
    const number = this.createNumberColumn();
    const operation = this.createOperationColumn();
    const method = this.createMethodColumn();
    const inventoryValue = this.createInventoryValueColumn();
    const consumption = this.createConsumptionColumn();
    const price = this.createPriceColumn();
    const cost = this.createCostColumn();
    const actions = this.createActionsColumn();

    return [number, operation, method, inventoryValue, consumption, price, cost, actions];
  };

  createNumberColumn = (): IColumnConfig => {
    return {
      id: 'number',
      name: '№',
      width: {
        default: '36px',
      },
    };
  };

  createOperationColumn = (): IColumnConfig => {
    return {
      id: 'name',
      name: 'Техоперация',
      width: {
        default: '170px',
      },
    };
  };

  createMethodColumn = (): IColumnConfig => {
    return {
      id: 'method',
      name: 'Способ',
      width: {
        default: '169px',
      },
    };
  };

  createInventoryValueColumn = (): IColumnConfig => {
    return {
      id: 'inventoryValue',
      name: 'Ресурсы',
      width: {
        default: '169px',
      },
    };
  };

  createConsumptionColumn = (): IColumnConfig => {
    return {
      id: 'consumption',
      name: 'Расход',
      width: {
        default: '88px',
      },
    };
  };

  createPriceColumn = (): IColumnConfig => {
    return {
      id: 'price',
      name: 'Цена',
      width: {
        default: '88px',
      },
    };
  };

  createCostColumn = (): IColumnConfig => {
    return {
      id: 'cost',
      name: 'Стоимость',
      width: {
        default: '90px',
      },
    };
  };

  createActionsColumn = (): IColumnConfig => {
    return {
      id: 'actions',
      name: '',
      width: {
        default: '1fr',
      },
    };
  };

  createCellList = (
    onClickAddTechoperation,
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    isViewOnly
  ): ICellConfig[] => {
    const number = this.createNumberCell();
    const operation = this.createOperationCell();
    const method = this.createMethodCell();
    const inventoryValue = this.createInventoryValueCell(onClickAddTechoperation, isViewOnly);
    const consumption = this.createConsumptionCell();
    const price = this.createPriceCell();
    const cost = this.createCostCell();
    const actions = this.createActionsCell(
      onClickDeleteTechoperation,
      onClickEditTechoperation,
      onClickDeleteInventoryValue,
      onClickEditInventoryValue,
      isViewOnly
    );

    return [number, operation, method, inventoryValue, consumption, price, cost, actions];
  };

  createNumberCell = (): ICellConfig => {
    return {
      id: 'number',
      autoRenderConfig: {
        renderValue: row => row.number,
        alighnItems: 'top',
        isTitle: true,
      },
    };
  };

  createOperationCell = (): ICellConfig => {
    return {
      id: 'name',
      autoRenderConfig: {
        renderValue: row => row.name,
        alighnItems: 'top',
        height: 40,
        isTitle: true,
        isWrap: true,
      },
    };
  };

  createMethodCell = (): ICellConfig => {
    return {
      id: 'method',
      autoRenderConfig: {
        renderValue: row => row.method,
        alighnItems: 'top',
        isTitle: true,
      },
    };
  };

  createInventoryValueCell = (onClickAddTechoperation, isViewOnly): ICellConfig => {
    return {
      id: 'inventoryValue',
      customRenderConfig: {
        renderCell: row => (
          <InventoryValueCellComponent
            row={row}
            onClickAddTechoperation={onClickAddTechoperation}
            isViewOnly={isViewOnly}
            isShowJobLine={true}
          />
        ),
      },
    };
  };

  createConsumptionCell = (): ICellConfig => {
    return {
      id: 'consumption',
      customRenderConfig: {
        renderCell: row => <ConsumptionCellComponent row={row} />,
      },
    };
  };

  createPriceCell = (): ICellConfig => {
    return {
      id: 'price',
      customRenderConfig: {
        renderCell: row => <PriceCellComponent row={row} />,
      },
    };
  };

  createCostCell = (): ICellConfig => {
    return {
      id: 'cost',
      customRenderConfig: {
        renderCell: row => <CostCellComponent row={row} />,
      },
    };
  };

  createActionsCell = (
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    isViewOnly
  ): ICellConfig => {
    return {
      id: 'actions',
      customRenderConfig: {
        renderCell: row =>
          isViewOnly ? (
            <></>
          ) : (
            <ActionCellComponent
              row={row}
              onClickDeleteTechoperation={onClickDeleteTechoperation}
              onClickEditTechoperation={onClickEditTechoperation}
              onClickDeleteInventoryValue={onClickDeleteInventoryValue}
              onClickEditInventoryValue={onClickEditInventoryValue}
            />
          ),
      },
    };
  };

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EExperimentsTableBuilderId.CalculationEconomy,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default CalculationEconomyConfigsService;
