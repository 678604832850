import { IExperiment } from '../../../../../../../../../../../../api/models/as-fields/experiments';

const createOptionListFromExpiriments = (expiriments: IExperiment[]) => {
  return expiriments.map(expiriment => ({
    label: expiriment.name,
    value: expiriment.id,
  }));
};

export default createOptionListFromExpiriments;
