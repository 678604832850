import styled from 'styled-components';

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 2px;
`;

const Icon = styled.div`
  padding-left: 4px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
`;

const Wrapper = styled.div``;

const StyledAuditReportHeaderActions = {
  Title,
  TitleWrapper,
  Wrapper,
  Icon,
};

export default StyledAuditReportHeaderActions;
