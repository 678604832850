import styled from 'styled-components';

const FormWrapper = styled.div`
  display: flex;
  margin-bottom: 28px;
`;

const InputWrapper = styled.div`
  width: 33%;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledCalculationForm = {
  FormWrapper,
  InputWrapper,
};

export default StyledCalculationForm;
