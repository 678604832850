import { makeAutoObservable } from 'mobx';

import {
  ActionCellComponent,
  DosageValueCellComponent,
  InventoryValueCellComponent,
  ActiveSubstancesCellComponent,
} from '../../../../../../../../../../../../common/components/ActiveSubstancesTable/cells';
import { ActiveSubstancesColumnComponent } from '../../../../../../../../../../../../common/components/ActiveSubstancesTable/columns/containers';
import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../../../../../common/features/TableBuilder/models/configs';
import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentsTableBuilderId } from '../../../../../../../../../constants/configs';
import { SliderControlsContainer } from '../../../../../../history/containers/History/containers';
import { CalculationStore } from '../../store/Calculation/Calculation.store';

@provide.transient()
class CalculationActiveSubstancesConfigsService {
  @lazyInject(CalculationStore)
  protected calculationStore: CalculationStore;

  constructor() {
    makeAutoObservable(this);
  }

  createConfig = (
    onClickAddTechoperation,
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    isViewOnly
  ): IBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList(
      onClickAddTechoperation,
      onClickDeleteTechoperation,
      onClickEditTechoperation,
      onClickDeleteInventoryValue,
      onClickEditInventoryValue,
      isViewOnly
    );
    const rowsGroupList = this.createRowsGroupList();

    return {
      id: EExperimentsTableBuilderId.CalculationActiveSubstances,
      columnConfigList: columnList,
      cellConfigList: cellList,
      rowsGroupConfigList: rowsGroupList,
    };
  };

  createColumnList = (): IColumnConfig[] => {
    const number = this.createNumberColumn();
    const operation = this.createOperationColumn();
    const inventoryValue = this.createInventoryValueColumn();
    const dosage = this.createDosageColumn();
    const activeSubstances = this.createActiveSubstancesColumn();
    const actions = this.createActionsColumn();

    return [number, operation, inventoryValue, dosage, activeSubstances, actions];
  };

  createNumberColumn = (): IColumnConfig => {
    return {
      id: 'number',
      name: '№',
      width: {
        default: '35px',
      },
    };
  };

  createOperationColumn = (): IColumnConfig => {
    return {
      id: 'name',
      name: 'Техоперация',
      width: {
        default: '220px',
      },
    };
  };

  createInventoryValueColumn = (): IColumnConfig => {
    return {
      id: 'inventoryValue',
      name: 'Наименование ТМЦ',
      width: {
        default: '220px',
      },
    };
  };

  createDosageColumn = (): IColumnConfig => {
    return {
      id: 'dosage',
      name: 'Дозировка',
      width: {
        default: '96px',
      },
    };
  };

  createActiveSubstancesColumn = (): IColumnConfig => {
    const { nutritionHistories, experimentSteps } = this.calculationStore;

    return {
      id: 'activeSubstances',
      name: 'Активные вещества',
      width: {
        default: '240px',
      },
      customRenderConfig: {
        renderColumn: column => (
          <ActiveSubstancesColumnComponent
            key={column.id}
            nutritionHistories={nutritionHistories}
            experimentSteps={experimentSteps}
          />
        ),
      },
    };
  };

  createActionsColumn = (): IColumnConfig => {
    return {
      id: 'actions',
      name: '',
      width: {
        default: '1fr',
      },
      customRenderConfig: {
        renderColumn: () => <SliderControlsContainer />,
      },
    };
  };

  createCellList = (
    onClickAddTechoperation,
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    isViewOnly
  ): ICellConfig[] => {
    const number = this.createNumberCell();
    const operation = this.createOperationCell();
    const inventoryValue = this.createInventoryValueCell(onClickAddTechoperation, isViewOnly);
    const dosage = this.createDosageCell();
    const activeSubstances = this.createActiveSubstancesCell();
    const actions = this.createActionsCell(
      onClickDeleteTechoperation,
      onClickEditTechoperation,
      onClickDeleteInventoryValue,
      onClickEditInventoryValue,
      isViewOnly
    );

    return [number, operation, inventoryValue, dosage, activeSubstances, actions];
  };

  createNumberCell = (): ICellConfig => {
    return {
      id: 'number',
      autoRenderConfig: {
        renderValue: row => row.number,
        alighnItems: 'top',
        isTitle: true,
      },
    };
  };

  createOperationCell = (): ICellConfig => {
    return {
      id: 'name',
      autoRenderConfig: {
        renderValue: row => row.name,
        alighnItems: 'top',
        height: 40,
        isTitle: true,
        isWrap: true,
      },
    };
  };

  createInventoryValueCell = (onClickAddTechoperation, isViewOnly): ICellConfig => {
    return {
      id: 'inventoryValue',
      customRenderConfig: {
        renderCell: row => (
          <InventoryValueCellComponent
            row={row}
            onClickAddTechoperation={onClickAddTechoperation}
            isViewOnly={isViewOnly}
          />
        ),
      },
    };
  };

  createDosageCell = (): ICellConfig => {
    return {
      id: 'dosage',
      customRenderConfig: {
        renderCell: row => <DosageValueCellComponent row={row} />,
      },
    };
  };

  createActiveSubstancesCell = (): ICellConfig => {
    const { nutritionHistories, experimentSteps } = this.calculationStore;

    return {
      id: 'activeSubstances',
      customRenderConfig: {
        renderCell: row => (
          <ActiveSubstancesCellComponent
            row={row}
            nutritionHistories={nutritionHistories}
            experimentSteps={experimentSteps}
          />
        ),
      },
    };
  };

  createActionsCell = (
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    isViewOnly
  ): ICellConfig => {
    return {
      id: 'actions',
      customRenderConfig: {
        renderCell: row =>
          isViewOnly ? (
            <></>
          ) : (
            <ActionCellComponent
              row={row}
              onClickDeleteTechoperation={onClickDeleteTechoperation}
              onClickEditTechoperation={onClickEditTechoperation}
              onClickDeleteInventoryValue={onClickDeleteInventoryValue}
              onClickEditInventoryValue={onClickEditInventoryValue}
            />
          ),
      },
    };
  };

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EExperimentsTableBuilderId.CalculationActiveSubstances,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default CalculationActiveSubstancesConfigsService;
