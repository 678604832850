import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { IFormConfig } from '../../../../../../../../../../../common/features/form/models';

const COMMON_INFORMATION_FORM_KEY = 'commonInformation';

const REQUIRED_TEXT = 'Обязательно для заполнения';

interface ICommonInformationForm {
  organizationId: string;
  experimentId: string;
  planStartDate: string;
  planEndDate: string;
  parentStepId: string;
  techOperationTypeId: string;
  assigneeId: string;
  name: string;
}

const createCommonInformationFormConfig = ({
  organizationsSearchQueryHandler,
  usersSearchQueryHandler,
  experimentSearchQueryHandler,
  operationTypesSearchQueryHandler,
  experimentStepsSearchQueryHandler,
}: {
  organizationsSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  usersSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  experimentSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  operationTypesSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  experimentStepsSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
}): IFormConfig<ICommonInformationForm> => {
  const commonInformationFormConfig: IFormConfig<ICommonInformationForm> = {
    formKey: COMMON_INFORMATION_FORM_KEY,
    form: {
      organizationId: '',
      experimentId: '',
      planStartDate: '',
      planEndDate: '',
      parentStepId: '',
      techOperationTypeId: '',
      assigneeId: '',
      name: '',
    },
    elements: {
      organizationId: {
        name: 'organizationId',
        type: 'select',
        order: 1,
        isRequired: true,
        isLoading: true,
        label: 'Организация',
        selectOptions: {
          search: {},
          searchQuery: {
            handler: organizationsSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
        placeholder: 'Выберите из списка',
      },
      experimentId: {
        name: 'experimentId',
        type: 'select',
        dependencyName: 'organizationId',
        order: 2,
        isRequired: true,
        isLoading: true,
        label: 'Опыт',
        selectOptions: {
          search: {},
          searchQuery: {
            handler: experimentSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
        placeholder: 'Выберите из списка',
      },
      planStartDate: {
        name: 'planStartDate',
        type: 'date',
        dependencyName: 'experimentId',
        order: 3,
        isRequired: true,
        isLoading: true,
        label: 'Плановое начало',
        placeholder: 'дд.мм.гг',
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      planEndDate: {
        name: 'planEndDate',
        type: 'date',
        dependencyName: 'experimentId',
        order: 4,
        isRequired: true,
        isLoading: true,
        label: 'Плановое завершение',
        placeholder: 'дд.мм.гг',
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      parentStepId: {
        name: 'parentStepId',
        type: 'select',
        dependencyName: 'experimentId',
        order: 5,
        isLoading: true,
        label: 'Агротехнический приём',
        selectOptions: {
          search: {},
          searchQuery: {
            handler: experimentStepsSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
        placeholder: 'Выберите из списка',
      },
      techOperationTypeId: {
        name: 'techOperationTypeId',
        type: 'select',
        dependencyName: 'experimentId',
        order: 6,
        isRequired: true,
        isLoading: true,
        label: 'Операция',
        selectOptions: {
          search: {},
          searchQuery: {
            handler: operationTypesSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
        placeholder: 'Выберите из списка',
      },
      assigneeId: {
        name: 'assigneeId',
        type: 'select',
        dependencyName: 'experimentId',
        order: 7,
        isRequired: true,
        isLoading: true,
        label: 'Исполнитель',
        selectOptions: {
          search: {},
          searchQuery: {
            handler: usersSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
        placeholder: 'Выберите из списка',
      },
      name: {
        name: 'name',
        type: 'input',
        order: 8,
        isRequired: true,
        dependencyName: 'experimentId',
        label: 'Название наблюдения',
        inputOptions: {
          maxLength: 50,
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
        placeholder: 'Введите название',
      },
    },
  };

  return commonInformationFormConfig;
};

export type { ICommonInformationForm };

export { COMMON_INFORMATION_FORM_KEY, createCommonInformationFormConfig };
