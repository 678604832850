import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { IFormConfig } from '../../../../../../../../../../../../common/features/form/models';

interface IAddPreviousCultureForm {
  previousCultureId: string;
}

const ADD_PREVIOUS_CULTURE_FORM_KEY = 'addPreviousCulture';

const createAddPreviousCultureFormConfig = ({
  culturesSearchQueryHandler,
  prevCultureSelectOptionList,
}: {
  culturesSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  prevCultureSelectOptionList: ISelectOption[];
}): IFormConfig<IAddPreviousCultureForm> => {
  const previousCultureId = (prevCultureSelectOptionList?.[0]?.value as string) || '';

  const addPreviousCultureFormConfig: IFormConfig<IAddPreviousCultureForm> = {
    formKey: ADD_PREVIOUS_CULTURE_FORM_KEY,
    form: {
      previousCultureId,
    },
    elements: {
      previousCultureId: {
        name: 'previousCultureId',
        order: 1,
        type: 'select',
        label: 'Культура-предшественник',
        selectOptions: {
          defaultValue: { label: 'Не выбрана', value: '' },
          emptyOption: { label: 'Не выбрана', value: '' },
          search: {},
          searchQuery: {
            handler: culturesSearchQueryHandler,
          },
          optionList: prevCultureSelectOptionList,
        },
      },
    },
  };

  return addPreviousCultureFormConfig;
};

export type { IAddPreviousCultureForm };

export { ADD_PREVIOUS_CULTURE_FORM_KEY, createAddPreviousCultureFormConfig };
