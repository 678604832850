import styled from 'styled-components';

import { Container } from '../../../../../../../../../common/features/UI';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 100px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MarginBlock = styled.div`
  min-height: 24px;
  position: sticky;
  top: -24px;
  background-color: #faf9fd;
  z-index: 10;
`;

const StyledComparison = {
  Wrapper,
  MarginBlock,
  Container: StyledContainer,
};

export default StyledComparison;
