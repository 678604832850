const getWordByNumber = (value: number, words: string[]) => {
  const currentValue = Math.abs(value) % 100;

  const number = currentValue % 10;

  if (currentValue > 10 && currentValue < 20) {
    return words[2];
  }

  if (number > 1 && number < 5) {
    return words[1];
  }

  if (number === 1) {
    return words[0];
  }

  return words[2];
};

export default getWordByNumber;
