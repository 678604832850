import { EExperimentStatus } from '../../../../../../../../../../api/models/as-fields/experiments';

export const checkEditableExperimentStatuses = (status: EExperimentStatus): boolean => {
  switch (status) {
    case EExperimentStatus.Canceled:
      return false;
    case EExperimentStatus.Completed:
      return false;
    case EExperimentStatus.FactAppruved:
      return false;
    case EExperimentStatus.FullCompleted:
      return false;
    default:
      return true;
  }
};
