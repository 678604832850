import { FC } from 'react';

import { CalculationInfo } from '..';

import { TCalculationInfoBlockProps } from './CalculationInfoBlock.type';
import Styled from './CalculationInfoBlock.style';

const CalculationInfoBlock: FC<TCalculationInfoBlockProps> = ({
  calculationInfoData,
  bgColor,
  elementWidth,
  elementsJustify,
}) => {
  return (
    <Styled.Wrapper $bgColor={bgColor}>
      <Styled.InfoWrapper $justifyContent={elementsJustify}>
        {calculationInfoData.map(data => {
          const { value } = data;

          return (
            <CalculationInfo
              title={data.title}
              value={value}
              key={data.title}
              elementWidth={elementWidth}
            />
          );
        })}
      </Styled.InfoWrapper>
    </Styled.Wrapper>
  );
};

export default CalculationInfoBlock;
