import { FC } from 'react';
import { observer } from 'mobx-react';

import { IComparisonTableBuilderRowsGroup as IRowsGroup } from '../../../models/data/ComparisonTableBuilderRowsGroup';
import { ComparisonTableBuilderRowsGroupCustomRenderContainer as RowsGroupCustomRenderContainer } from '../ComparisonTableBuilderRowsGroupCustomRenderContainer';
import { ComparisonTableBuilderRowsGroupAutoRenderContainer } from '../ComparisonTableBuilderRowsGroupAutoRenderContainer';

interface IProps {
  rowsGroup: IRowsGroup;
}

const ComparisonTableBuilderRowsGroupContainer: FC<IProps> = ({ rowsGroup }) => {
  switch (rowsGroup.renderType) {
    case 'auto':
      return <ComparisonTableBuilderRowsGroupAutoRenderContainer rowsGroup={rowsGroup} />;

    case 'custom':
      return <RowsGroupCustomRenderContainer rowsGroup={rowsGroup} />;

    default:
      return <></>;
  }
};

ComparisonTableBuilderRowsGroupContainer.displayName = 'ComparisonTableBuilderRowsGroupContainer';

export default observer(ComparisonTableBuilderRowsGroupContainer);
