import styled from 'styled-components';

const FormulaName = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
`;

const StyledTotalComparisonFormulaRow = {
  FormulaName,
};

export default StyledTotalComparisonFormulaRow;
