import { TApiRoute } from '../../../models/system/ApiRoute.model';
import {
  EExperimentStatus,
  EExperimentsWizardProgress,
  IExperiment,
} from '../../../models/as-fields/experiments';
import { IExperimentAttributesForm } from '../../../../modules/dashboard/modules/experiments/modules/createExperiment/modules/attributes/forms/config/attributesFormConfig';

type TChangeExperimentReq = {
  id: string;
  status?: EExperimentStatus;
  wizardProgress?: EExperimentsWizardProgress;
} & Partial<IExperimentAttributesForm>;

type TChangeExperimentRes = IExperiment;

const changeExperiment: TApiRoute & {
  request: TChangeExperimentReq;
  response: TChangeExperimentRes;
} = {
  url: ({ id }) => `/api/as-fields/experiment/${id}`,
  method: 'PUT',
  request: {} as TChangeExperimentReq,
  response: {} as TChangeExperimentRes,
  headers: {},
};

export type { TChangeExperimentReq, TChangeExperimentRes };

export { changeExperiment };
