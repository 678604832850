import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { TableFiltersBuilderStore } from '../../../mobx/stores';
import { TableFiltersBuilderFilterContainer as Filter } from '../../filters';
import { useSortBy } from '../../../utils/hooks/states/sort';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { TableFiltersBuilderGrid as Grid } from '../../shared';
import { TableFiltersBuilderControls as Controls } from '../../buttons';
import { TableFiltersBuilderGridItem as GridItem } from '../../../components/shared';
import { useTableFiltersBuilderUIContext as useUIContext } from '../../../context/UI/TableFiltersBuilderUIContext/TableFiltersBuilderUI.context';
import { useStore } from '../../../../../utils/helpers/mobx';

import Styled from './TableFiltersBuilderFilters.styles';

const TableFiltersBuilderFilters: FC = () => {
  const builderStore = useStore(TableFiltersBuilderStore);

  const UIContext = useUIContext();

  const filterList = useSortBy(
    builderStore.getFilterList(UIContext.builderId, { isOnlyVisible: true })
  );

  const getDataTestId = useDataTestId();

  return (
    <Styled.Filters {...getDataTestId('filters')}>
      <Grid contentKey={'filters'}>
        {filterList.map(filter => (
          <GridItem key={filter.id as string} name={filter.id as string}>
            <Filter filter={filter} />
          </GridItem>
        ))}

        <Controls />
      </Grid>
    </Styled.Filters>
  );
};

TableFiltersBuilderFilters.displayName = 'TableFiltersBuilderFilters';

export default observer(TableFiltersBuilderFilters);
