import { EExperimentStatus } from '../../../../../api/models/as-fields/experiments';
import { IExperimentChangeStatusAvailability } from '../../../../../api/models/as-fields/experiments/Experiment';

import { experimentStatusMap } from './statuses.config';

const getAvailableExperimentStatus = (
  currentStatus: EExperimentStatus,
  availabilityScheme?: IExperimentChangeStatusAvailability
) => {
  switch (currentStatus) {
    case EExperimentStatus.Draft:
      return [
        ...(availabilityScheme?.canDraftToInApproval
          ? [experimentStatusMap.get(EExperimentStatus.InApproval)]
          : []),
        ...(availabilityScheme?.canDraftToInProgress
          ? [experimentStatusMap.get(EExperimentStatus.InProgress)]
          : []),
      ];

    case EExperimentStatus.InApproval:
      return [
        ...(availabilityScheme?.canInApprovalToApproved
          ? [experimentStatusMap.get(EExperimentStatus.Approved)]
          : []),
        ...(availabilityScheme?.canInApprovalToDraft
          ? [experimentStatusMap.get(EExperimentStatus.Draft)]
          : []),
      ];

    case EExperimentStatus.Approved:
      return [
        ...(availabilityScheme?.canApprovedToInProgress
          ? [experimentStatusMap.get(EExperimentStatus.InProgress)]
          : []),
        ...(availabilityScheme?.canApprovedToCanceled
          ? [experimentStatusMap.get(EExperimentStatus.Canceled)]
          : []),
      ];

    case EExperimentStatus.InProgress:
      return [
        ...(availabilityScheme?.canInProgressToCompleted
          ? [experimentStatusMap.get(EExperimentStatus.Completed)]
          : []),
        ...(availabilityScheme?.canInProgressToCanceled
          ? [experimentStatusMap.get(EExperimentStatus.Canceled)]
          : []),
        ...(availabilityScheme?.canInProgressToDraft
          ? [experimentStatusMap.get(EExperimentStatus.Draft)]
          : []),
      ];

    case EExperimentStatus.Completed:
      return [
        ...(availabilityScheme?.canCompletedToInProgress
          ? [experimentStatusMap.get(EExperimentStatus.InProgress)]
          : []),
        ...(availabilityScheme?.canCompletedToFactApproved
          ? [experimentStatusMap.get(EExperimentStatus.FactAppruved)]
          : []),
      ];

    case EExperimentStatus.FactAppruved:
      return [
        ...(availabilityScheme?.canFactApprovedToCompleted
          ? [experimentStatusMap.get(EExperimentStatus.Completed)]
          : []),
        ...(availabilityScheme?.canFactApprovedToFullCompleted
          ? [experimentStatusMap.get(EExperimentStatus.FullCompleted)]
          : []),
      ];

    case EExperimentStatus.FullCompleted:
      return [
        ...(availabilityScheme?.canFullCompletedToFactApproved
          ? [experimentStatusMap.get(EExperimentStatus.FactAppruved)]
          : []),
      ];

    case EExperimentStatus.Canceled:
      return [
        ...(availabilityScheme?.canCanceledToApproved
          ? [experimentStatusMap.get(EExperimentStatus.Approved)]
          : []),
        ...(availabilityScheme?.canCanceledToInProgress
          ? [experimentStatusMap.get(EExperimentStatus.InProgress)]
          : []),
      ];

    default:
      return Array.from(experimentStatusMap.values());
  }
};

export { getAvailableExperimentStatus };
