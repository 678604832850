import { FC, memo } from 'react';

import { IComparisonTableBuilderRowsGroup as IRowsGroup } from '../../../models/data/ComparisonTableBuilderRowsGroup';
import { ComparisonTableBuilderRowsContainer as RowsContainer } from '../../rows/ComparisonTableBuilderRowsContainer';
import { ComparisonTableBuilderClearedRowsGroup as ClearedRowsGroup } from '../../../components/rowsGroups';

interface IProps {
  rowsGroup: IRowsGroup;
}

const ComparisonTableBuilderRowsGroupAutoRenderContainer: FC<IProps> = ({ rowsGroup }) => {
  switch (rowsGroup.autoRenderConfig.preset) {
    case 'cleared':
      return (
        <ClearedRowsGroup
          rowsChildren={
            <RowsContainer rowsGroupId={rowsGroup.id} rowIdList={rowsGroup.rowIdList} />
          }
        />
      );

    default:
      return <></>;
  }
};

ComparisonTableBuilderRowsGroupAutoRenderContainer.displayName =
  'ComparisonTableBuilderRowsGroupAutoRenderContainer';

export default memo(ComparisonTableBuilderRowsGroupAutoRenderContainer);
