import { IExperimentStep } from '../../../../models/as-fields/experiments';
import { EExperimentStepApprovalStatus } from '../../../../models/as-fields/experiments/ExperimentComment';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TChangeExperimentStepApprovalStatusReq = {
  id: string;
  /**
   * Строго зависит от доступных PUT-ручек смены статуса апрува `EExperimentStepApprovalStatus`
   */
  status: EExperimentStepApprovalStatus;
};

type TChangeExperimentStepApprovalStatusRes = IExperimentStep;

const changeExperimentStepApprovalStatus: TApiRoute & {
  request: TChangeExperimentStepApprovalStatusReq;
  response: TChangeExperimentStepApprovalStatusRes;
} = {
  url: ({ id, status }) => `/api/as-fields/experimentSteps/${id}/${status}`,
  method: 'PUT',
  request: {} as TChangeExperimentStepApprovalStatusReq,
  response: {} as TChangeExperimentStepApprovalStatusRes,
  headers: {},
};

export type { TChangeExperimentStepApprovalStatusReq, TChangeExperimentStepApprovalStatusRes };

export { changeExperimentStepApprovalStatus };
