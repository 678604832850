import { v4 as uuid } from 'uuid';

import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import {
  IComparisonTableBuilderConfig,
  IComparisonTableBuilderRowsGroupConfig,
} from '../../../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import {
  IExperimentFactTableAttribute,
  IExperimentFactTableStage,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { ExecutionColumnConfigsService } from '../../../../../mobx/services/ExecutionColumnConfigsService';
import { createExecutionTableRowId as createRowId } from '../../../../../helpers';
import { ExecutionCalculationsCellConfigsService as CellConfigsService } from '../ExecutionCalculationsCellConfigsService';
import {
  TExecutionCalculationsCellConfig as TCellConfig,
  TExecutionCalculationsRowConfig as TRowConfig,
} from '../../../types';
import { EComparisonTableName as ETableName } from '../../../../../../../../../../../constants/features';

@provide.transient()
class ExecutionCalculationsConfigsService {
  @lazyInject(ExecutionColumnConfigsService)
  protected columnConfigService: ExecutionColumnConfigsService;

  @lazyInject(CellConfigsService)
  protected cellConfigService: CellConfigsService;

  /**
   * Коллекция формата:
   * идентификатор ряда к списку моделей-конфигов ячеек.
   */
  protected cellConfigsByColumnIdByRowI: Map<string, TCellConfig[]> = new Map();

  createTableConfig = (
    builderId: string,
    stageList: IExperimentFactTableStage[]
  ): IComparisonTableBuilderConfig => {
    this.cellConfigsByColumnIdByRowI = new Map();

    const config: IComparisonTableBuilderConfig = {
      id: builderId,
      name: ETableName.ExecutionCalculations,
      headerConfig: {
        autoRenderConfig: {
          preset: 'dashed',
        },
      },
      columnConfigList: this.columnConfigService.createColumnConfigList(),
      rowsGroupConfigList: this.createRowsGroupList(stageList),
    };

    config.cellConfigs = this.getCellConfigs();

    return config;
  };

  protected createRowsGroupList = (
    stageList: IExperimentFactTableStage[]
  ): IComparisonTableBuilderRowsGroupConfig[] => {
    return stageList.map(stage => this.createRowGroup(stage));
  };

  protected createRowGroup = (
    stage: IExperimentFactTableStage
  ): IComparisonTableBuilderRowsGroupConfig => {
    const id = uuid();

    const rowGroup: IComparisonTableBuilderRowsGroupConfig = {
      id,
      rowConfigList: this.createRowConfigList(id, stage.attributes),
      autoRenderConfig: {
        preset: 'cleared',
      },
    };

    return rowGroup;
  };

  protected createRowConfigList = (
    stageId: string,
    attributeList: IExperimentFactTableAttribute[]
  ): TRowConfig[] => {
    return attributeList.map(attribute => this.createRowConfig(attribute, stageId));
  };

  protected createRowConfig = (
    attribute: IExperimentFactTableAttribute,
    stageId: string
  ): TRowConfig => {
    const { id, name, values } = attribute;

    const rowConfig: TRowConfig = {
      id: createRowId(stageId, id),
      autoRenderConfig: {
        preset: 'default',
        name,
      },
      initialModel: attribute,
    };

    const cellConfigList = this.cellConfigService.createCellConfigList(rowConfig, values);
    this.cellConfigsByColumnIdByRowI.set(rowConfig.id, cellConfigList);

    return rowConfig;
  };

  protected getCellConfigs = (): IComparisonTableBuilderConfig['cellConfigs'] => {
    return Object.fromEntries([...this.cellConfigsByColumnIdByRowI.entries()]);
  };
}

export default ExecutionCalculationsConfigsService;
