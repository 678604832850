import { FC } from 'react';

import { ExperimentsFilters } from '../ExperimentsFilters';
import { ListingPage } from '../../../../../common/features/UI/pages/ListingPage';
import { ExperimentsList } from '../ExperimentsList';

const Experiments: FC = () => {
  return (
    <ListingPage id={'experiments'} filters={<ExperimentsFilters />} list={<ExperimentsList />} />
  );
};

Experiments.displayName = 'Experiments';

export default Experiments;
