import { Outlet } from 'react-router-dom';
import { FC, useEffect } from 'react';

import { useStore } from '../../../../../common/utils/helpers/mobx';
import { NavigationTabsStore } from '../../../../../common/features/NavigationTabs/mobx';

export const Experiment: FC = () => {
  const { clearStore } = useStore(NavigationTabsStore);

  useEffect(() => {
    return () => clearStore();
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

Experiment.displayName = 'Experiment';
