import styled from 'styled-components';

type TStyledStatusWrapperProps = {
  $bgColor: string;
  $color: string;
};

const StatusWrapper = styled.div<TStyledStatusWrapperProps>`
  color: ${({ $color }) => $color};

  font-size: 12px;
  line-height: 16px;

  border-radius: 8px;
  padding: 0 8px;
  width: fit-content;
  text-wrap: nowrap;
  background: ${({ $bgColor }) => $bgColor};
`;

const StyledSliderTaskStatus = {
  StatusWrapper,
};

export default StyledSliderTaskStatus;
