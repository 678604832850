import styled, { css } from 'styled-components';

const text = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

const PlanLabel = styled.div`
  ${text};

  color: #7e8b9d;
`;

const FactLabel = styled.div`
  ${text};

  color: #151f32;
`;

const Description = styled.div`
  width: 100%;

  display: flex;
  gap: 4px;
`;

const Name = styled.div`
  ${text};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #151f32;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const FormulaName = styled.span`
  font-size: 14px;
  margin-bottom: auto;
`;

const StyledComparisonColumn = {
  PlanLabel,
  FactLabel,
  Description,
  Name,
  Wrapper,
  FormulaName,
};

export default StyledComparisonColumn;
