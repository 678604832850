import { makeAutoObservable } from 'mobx';
import { sortBy } from 'lodash';

import { provide } from '../../../../../utils/helpers/mobx';
import {
  IComparisonTableBuilderColumn as IColumn,
  IComparisonTableBuilderRowsGroup as IRowsGroup,
  IComparisonTableBuilderRow as IRow,
  IComparisonTableBuilderCell as ICell,
  IComparisonTableBuilderHeader as IHeader,
} from '../../../models/data';

interface ISetElementListOptions {
  isClearPreviousList?: boolean;
}

@provide.singleton()
class ComparisonTableBuilderStore {
  /**
   * Коллекция хедеров.
   */
  private _headersByBuilderId: Map<string, IHeader> = new Map();

  /**
   * Коллекция колонок.
   */
  private _columnsByBuilderId: Map<string, Map<string, IColumn>> = new Map();

  /**
   * Коллекция группы рядов.
   */
  private _rowsGroupByBuilderId: Map<string, Map<string, IRowsGroup>> = new Map();

  /**
   * Коллекция рядов.
   */
  private _rowsByBuilderId: Map<string, Map<string, IRow>> = new Map();

  /**
   * Коллекция формата:
   * идентификатор таблицы к коллекции:
   * идентификатор строки к коллекции:
   * идентификатор колонки к ячейке.
   */
  private _cellsByColumnIdByRowIdByBuilderId: Map<string, Map<string, Map<string, ICell>>> =
    new Map();

  constructor() {
    makeAutoObservable(this);
  }

  getHeaderByBuilderId = (builderId: string): IHeader => {
    return this._headersByBuilderId.get(builderId);
  };

  getCellListByBuilderId = (builderId: string): ICell[] => {
    const rows = this._cellsByColumnIdByRowIdByBuilderId.get(builderId);

    if (!rows) {
      return [];
    }

    return [...rows.values()].flatMap(cells => [...cells.values()]);
  };

  getColumnList = (builderId: string, options?: { isSortByOrder?: boolean }): IColumn[] => {
    const columns = this._columnsByBuilderId.get(builderId);

    if (!columns) {
      return [];
    }

    const columnList = [...columns.values()];

    if (options?.isSortByOrder) {
      return sortBy(columnList, 'order');
    }

    return columnList;
  };

  getRowsGroupList = (
    builderId: string,
    options?: {
      byRootRowId?: string;
    }
  ): IRowsGroup[] => {
    const rowGroups = this._rowsGroupByBuilderId.get(builderId);

    if (!rowGroups) {
      return [];
    }

    const rowGroupList = [...rowGroups.values()];

    if (options?.byRootRowId) {
      return rowGroupList.filter(({ rootRowId }) => rootRowId === options.byRootRowId);
    }

    return rowGroupList.filter(({ rootRowId }) => !rootRowId);
  };

  getRowsGroup = (builderId: string, rowsGroupId: string): IRowsGroup => {
    return this._rowsGroupByBuilderId.get(builderId)?.get?.(rowsGroupId);
  };

  getRowList = (
    builderId: string,
    options?: {
      byRowsGroupId: string;
    }
  ): IRow[] => {
    const rows = this._rowsByBuilderId.get(builderId);

    if (rows) {
      const rowList = [...rows.values()];

      if (options?.byRowsGroupId) {
        return rowList.filter(({ rowsGroupId }) => rowsGroupId === options.byRowsGroupId);
      }

      return rowList;
    }

    return [];
  };

  getRow = (builderId: string, rowId: string): IRow => {
    return this._rowsByBuilderId.get(builderId)?.get?.(rowId);
  };

  getCellList = (builderId: string, rowId: string): ICell[] => {
    const cells = this._cellsByColumnIdByRowIdByBuilderId.get(builderId)?.get?.(rowId);

    if (!cells) {
      return [];
    }

    return [...cells.values()];
  };

  getCell = <M = any>(builderId: string, rowId: string, columnId: string): ICell<M> => {
    return this._cellsByColumnIdByRowIdByBuilderId.get(builderId)?.get?.(rowId)?.get?.(columnId);
  };

  setHeader = (builderId: string, header: IHeader): void => {
    this._headersByBuilderId.set(builderId, header);
  };

  setColumnList = (
    builderId: string,
    columnList: IColumn[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._columnsByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<string, IColumn>(previousEntryList);

    columnList.forEach(column => {
      newCollection.set(column.id, column);
    });

    this._columnsByBuilderId.set(builderId, newCollection);
  };

  setRowsGroupList = (
    builderId: string,
    rowsGroupList: IRowsGroup[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._rowsGroupByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<string, IRowsGroup>(previousEntryList);

    rowsGroupList.forEach(rowsGroup => {
      newCollection.set(rowsGroup.id, rowsGroup);
    });

    this._rowsGroupByBuilderId.set(builderId, newCollection);
  };

  setRowList = (builderId: string, rowList: IRow[], options?: ISetElementListOptions): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._rowsByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<string, IRow>(previousEntryList);

    rowList.forEach(row => {
      newCollection.set(row.id, row);
    });

    this._rowsByBuilderId.set(builderId, newCollection);
  };

  setCellList = (builderId: string, rowId: string, cellList: ICell[]): void => {
    const hasRows = this._cellsByColumnIdByRowIdByBuilderId.has(builderId);

    if (!hasRows) {
      const newCells: Map<string, ICell> = new Map();
      cellList.forEach(cell => newCells.set(cell.columnId, cell));

      const newRows: Map<string, Map<string, ICell>> = new Map();
      newRows.set(rowId, newCells);

      this._cellsByColumnIdByRowIdByBuilderId.set(builderId, newRows);

      return;
    }

    const hasCells = this._cellsByColumnIdByRowIdByBuilderId.get(builderId).has(rowId);

    if (!hasCells) {
      const newCells: Map<string, ICell> = new Map();
      cellList.forEach(cell => newCells.set(cell.columnId, cell));

      this._cellsByColumnIdByRowIdByBuilderId.get(builderId).set(rowId, newCells);

      return;
    }

    cellList.forEach(cell => {
      this._cellsByColumnIdByRowIdByBuilderId.get(builderId).get(rowId).set(cell.columnId, cell);
    });
  };

  updateCell = <M = any>(
    builderId: string,
    rowId: string,
    columnId: string,
    cellData: Partial<ICell<M>>
  ): void => {
    const cell = this._cellsByColumnIdByRowIdByBuilderId
      .get(builderId)
      ?.get?.(rowId)
      ?.get?.(columnId);

    if (!cell) {
      return;
    }

    this._cellsByColumnIdByRowIdByBuilderId
      .get(builderId)
      .get(rowId)
      .set(columnId, { ...cell, ...cellData });
  };

  deleteHeader = (builderId: string): void => {
    this._headersByBuilderId.delete(builderId);
  };

  deleteColumns = (builderId: string): void => {
    this._columnsByBuilderId.delete(builderId);
  };

  deleteRowsGroups = (builderId: string): void => {
    this._rowsGroupByBuilderId.delete(builderId);
  };

  deleteRowsGroup = (builderId: string, rowsGroupId: string): void => {
    this._rowsGroupByBuilderId.get(builderId)?.delete(rowsGroupId);
  };

  deleteRowsGroupList = (builderId: string, rowsGroupIdList: string[]): void => {
    const rowsGroups = this._rowsGroupByBuilderId.get(builderId);

    if (!rowsGroups) {
      return;
    }

    rowsGroupIdList.forEach(id => rowsGroups.delete(id));
  };

  deleteRows = (builderId: string): void => {
    this._rowsByBuilderId.delete(builderId);
  };

  deleteRow = (builderId: string, rowId: string): void => {
    this._rowsByBuilderId.get(builderId)?.delete(rowId);
  };

  deleteRowList = (builderId: string, rowsIdList: string[]): void => {
    const rows = this._rowsByBuilderId.get(builderId);

    if (!rows) {
      return;
    }

    rowsIdList.forEach(id => rows.delete(id));
  };

  deleteCellsByBuilderId = (builderId: string): void => {
    this._cellsByColumnIdByRowIdByBuilderId.delete(builderId);
  };

  deleteCellsByRowId = (builderId: string, rowId: string): void => {
    this._cellsByColumnIdByRowIdByBuilderId.get(builderId)?.delete?.(rowId);
  };
}

export default ComparisonTableBuilderStore;
