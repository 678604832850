import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './InventoryValueCell.style';

interface IInventoryValueCellProps {
  name: string;
  dataTestId?: string;
}

const InventoryValueCell: FC<IInventoryValueCellProps> = ({ name, dataTestId }) => {
  const { ref, showTooltip } = useShowTooltip();

  return (
    <AutoTooltip content={name} position="top" disabled={!showTooltip}>
      <Styled.InventoryValue data-test-id={dataTestId} ref={ref}>
        {name}
      </Styled.InventoryValue>
    </AutoTooltip>
  );
};

InventoryValueCell.displayName = 'InventoryValueCell';

export default memo(InventoryValueCell);
