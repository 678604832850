import styled from 'styled-components';

const Wrapper = styled.div`
  height: 128px;
`;

const StyledExecutionContentLoader = {
  Wrapper,
};

export default StyledExecutionContentLoader;
