import { FC, MouseEvent, memo, useCallback } from 'react';

import { useDataTestId } from '../../../utils/hooks/locators';

import Styled from './Icon.styles';

const DEFAULT_DATA_TEST_ID = 'icon';

export interface IIconState {
  default: string;
  defaultHover: string;
  selected: string;
  selectedHover: string;
}

export interface IIconStates {
  s40: IIconState;
  s32: IIconState;
  s24: IIconState;
}

export interface IIconProps {
  size: 's40' | 's32' | 's24';
  states: IIconStates;
  isSelected?: boolean;
  onClick?: () => void;
  dataTestId?: string;
}

const Icon: FC<IIconProps> = ({ states, size, isSelected, onClick, dataTestId }) => {
  const getDataTestId = useDataTestId(dataTestId || DEFAULT_DATA_TEST_ID);

  const handleClickOnIconButton = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      onClick?.();
    },
    [onClick]
  );

  return (
    <Styled.Icon
      onClick={handleClickOnIconButton}
      {...getDataTestId()}
      $size={size}
      $states={states}
      $isSelected={isSelected}
    />
  );
};

Icon.displayName = 'Icon';

export default memo(Icon);
