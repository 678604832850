import { useCallback, useMemo } from 'react';

import { TableFiltersBuilderController } from '../../../../mobx/controllers';
import { ITableFiltersBuilderFilter as IFilter } from '../../../../models/data';
import { TableFiltersBuilderStore as BuilderStore } from '../../../../mobx/stores';
import { ISelectOption } from '../../../../../../components/form/Dropdown/Dropdown.types';
import { useStore } from '../../../../../../utils/helpers/mobx';

type TPaginationConfig = {
  currentPage: number;
  totalPages: number;
  onScroll: () => Promise<any>;
  onPageChange: () => void;
};

const useTableFilterBuilderDropdownPagination = (filter: IFilter, optionList: ISelectOption[]) => {
  const controller = useStore(TableFiltersBuilderController);
  const builderStore = useStore(BuilderStore);

  const querySearchConfig = builderStore.getSearchQueryConfigs(filter.builderId, filter.id);

  const handleScroll = useCallback(async () => {
    if (!filter?.selectOptions?.pagination) {
      return;
    }

    const currentPage = (querySearchConfig?.currentPage ?? 0) + 1;

    const data = await filter?.selectOptions?.pagination?.requestQuery?.(
      currentPage,
      querySearchConfig?.searchQuery ?? ''
    );

    const updatedOptionList = [...optionList, ...data.optionList];

    builderStore.setSearchQueryConfigs(
      filter.builderId,
      filter.id,
      querySearchConfig?.searchQuery ?? '',
      data.totalPages,
      currentPage
    );

    if (filter?.selectOptions?.pagination?.updateDataHandler) {
      filter.selectOptions.pagination.updateDataHandler?.(updatedOptionList);
    }

    controller.addSelectOptionList(filter.builderId, filter.id, updatedOptionList);

    return Promise.resolve(true);
  }, [querySearchConfig, optionList, filter]);

  const paginationConfig = useMemo<TPaginationConfig>(
    () => ({
      currentPage: querySearchConfig?.currentPage ?? 0,
      totalPages: querySearchConfig?.totalPage ?? 2,
      onScroll: handleScroll,
      onPageChange: () => null,
    }),
    [querySearchConfig, handleScroll]
  );

  return { handleScroll, paginationConfig };
};

export default useTableFilterBuilderDropdownPagination;
