import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';
import { useComparisonTableBuilderContext } from '../../../../../../../../../common/features/ComparisonTableBuilder/context';

import { ReactComponent as ArrowTop } from './assets/icons/arrow-top-24x24.svg';
import { ReactComponent as ArrowDown } from './assets/icons/arrow-down-24x24.svg';
import Styled from './TotalComparisonCollapsingRowsGroup.styles';

interface IProps {
  name: string;
  dataTestId: string;
}

const TotalComparisonCollapsingRowsGroup: FC<PropsWithChildren<IProps>> = ({
  children,
  name,
  dataTestId,
}) => {
  const comparisonTableContext = useComparisonTableBuilderContext();

  const [isOpen, setIsOpen] = useState(true);

  const handleHeaderClick = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  useEffect(() => {
    if (isOpen) {
      comparisonTableContext.sliderControl.onToggleScrollUpdate(true);
    }
  }, [isOpen]);

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.HeaderWrapper {...getDataTestId('header-wrapper')}>
        <Styled.Header onClick={handleHeaderClick} {...getDataTestId('header')}>
          <Styled.Title {...getDataTestId('title')}>{name}</Styled.Title>

          <Styled.ArrowWrapper {...getDataTestId('arrow-wrapper')}>
            {isOpen ? (
              <ArrowTop {...getDataTestId('arrow-top')} />
            ) : (
              <ArrowDown {...getDataTestId('arrow-down')} />
            )}
          </Styled.ArrowWrapper>
        </Styled.Header>
      </Styled.HeaderWrapper>

      {isOpen ? <Styled.Content {...getDataTestId('content')}>{children}</Styled.Content> : null}
    </Styled.Wrapper>
  );
};

TotalComparisonCollapsingRowsGroup.displayName = 'TotalComparisonCollapsingRowsGroup';

export default TotalComparisonCollapsingRowsGroup;
