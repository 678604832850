import { FC, PropsWithChildren } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './TotalComparisonSummaryRowsGroup.styles';

interface IProps {
  dataTestId: string;
}

const TotalComparisonSummaryRowsGroup: FC<PropsWithChildren<IProps>> = ({
  children,
  dataTestId,
}) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return <Styled.Wrapper {...getDataTestId()}>{children}</Styled.Wrapper>;
};

TotalComparisonSummaryRowsGroup.displayName = 'TotalComparisonSummaryRowsGroup';

export default TotalComparisonSummaryRowsGroup;
