import { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useParams, generatePath } from 'react-router-dom';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { FullScreen } from '../../../../../../../../common/components/ui';
import { useStore } from '../../../../../../../../common/utils/helpers/mobx';
import { ExperimentSubsectionsController } from '../../../mobx/controllers';
import { ExperimentSubsectionsStore } from '../../../mobx/stores';
import { ExperimentStatus } from '../../../components';
import { EExperimentsRoute } from '../../../../../routes';
import { EDashboardRoute } from '../../../../../../../routes/DashboardRoute';
import { ECreateExperimentRoute } from '../../../../createExperiment/routes';
import { NavigationTabsStore } from '../../../../../../../../common/features/NavigationTabs/mobx';
import {
  BackButtonContent,
  HeaderContent,
} from '../../../../createExperiment/containers/CreateExperiment/components';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../common/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../../../../common/hooks/usePageContextualHelp';

export const useExperimentSubsectionsInfo = () => {
  const navigate = useNavigate();

  const { experimentId } = useParams<{ experimentId: string }>();

  usePageContextualHelp(EContextualParentPath.ExperimentsExperiment);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.ExperimentsExperiment,
    ContextualPaths.ExperimentsExperimentHeaderTitle
  );

  const { fetchExperiment } = useStore(ExperimentSubsectionsController);

  const { experiment } = useStore(ExperimentSubsectionsStore);

  const { selectedTab } = useStore(NavigationTabsStore);

  const backButtonHandler = useCallback(() => {
    if (selectedTab?.path) {
      navigate(generatePath(selectedTab.path, { experimentId }));
    } else {
      navigate(
        generatePath(
          `/${EDashboardRoute.Experiments}/${EExperimentsRoute.Experiment}/${ECreateExperimentRoute.Execution}`,
          {
            experimentId,
          }
        )
      );
    }
  }, [selectedTab?.path, experimentId, generatePath]);

  useEffect(() => {
    if (!experimentId) return;

    fetchExperiment(experimentId);
  }, [experimentId]);

  const renderStatus = useMemo(
    () => <ExperimentStatus status={experiment?.status} />,
    [experiment?.status]
  );

  return (
    <FullScreen
      backButtonText={experiment?.name}
      goBackText="К просмотру опыта"
      titleContent={renderStatus}
      backButtonHandler={backButtonHandler}
      data-test-id="create-exreriment-fullscreen"
      headerContent={<HeaderContent />}
      backButtonContent={
        <BackButtonContent selectedExp={experiment} ContextualHelpIcon={ContextualHelpIcon} />
      }
      marginBottom={0}
    >
      <Outlet />
    </FullScreen>
  );
};
