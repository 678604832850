import { FC } from 'react';
import { observer } from 'mobx-react';
import { Switcher, useModal } from '@farmlink/farmik-ui';

import { EExperimentStatus } from '../../../../../../../../../../../../../../api/models/as-fields/experiments';
import {
  EExperimentCultureZoneType,
  EExpZoneStyle,
} from '../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { useMap } from '../../../../../../../../../../../../../common/features/experimentsMap/hooks';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';
import { zonesModalConfig } from '../../../../config/modals';
import { ZonesController } from '../../../../mobx/controllers';
import { ZonesStore } from '../../../../mobx/stores';
import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';

export const ZoneListSwitcherCell: FC<{ rowData: ICultureZoneCell }> = observer(
  ({ rowData: { type, name, fwExpCultureZone } }) => {
    const { setEditableZoneId, setTableHasChanged } = useStore(ZonesStore);
    const { changeZoneType } = useStore(ZonesController);
    const { selectedExp } = useStore(CreateExperimentStore);

    const { changeCultureZoneStyle } = useMap();
    const { openModalByModalId } = useModal();

    const handleChange = (newValue: boolean): void => {
      if (selectedExp?.status !== EExperimentStatus.Draft) {
        return;
      }

      setTableHasChanged(true);
      changeZoneType(
        {
          ...fwExpCultureZone,
          isSelected: newValue,
          styleType: (!newValue ? EExpZoneStyle.ReadyToExperiment : type) as any,
          type: newValue ? fwExpCultureZone.type : EExperimentCultureZoneType.Experiment,
        },
        changeCultureZoneStyle
      );

      if (newValue) {
        setEditableZoneId(fwExpCultureZone.cultureZoneId);
        openModalByModalId(zonesModalConfig.name.editZoneInfo, { isSaveChanges: false });
      }
    };

    return (
      <Switcher
        value={fwExpCultureZone.isSelected}
        onChange={handleChange}
        isBlocked={selectedExp?.status !== EExperimentStatus.Draft}
      />
    );
  }
);
