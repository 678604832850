import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGenerateMonitoringReportReq = {} & { stepId: string };

type TGenerateMonitoringReportRes = Blob;

const generateMonitoringReport: TApiRoute & {
  request: TGenerateMonitoringReportReq;
  response: TGenerateMonitoringReportRes;
} = {
  url: ({ stepId }) => `/api/as-fields/experimentSteps/${stepId}/createMonitoringReport`,
  method: 'GET',
  request: {} as TGenerateMonitoringReportReq,
  response: {} as TGenerateMonitoringReportRes,
  headers: {},
};

export type { TGenerateMonitoringReportRes, TGenerateMonitoringReportReq };

export { generateMonitoringReport };
