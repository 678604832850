import { provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../../../../common/features/TableBuilder/models/configs';
import { EContractsTableBuilderId } from '../../../../../../../../constants/configs';
import {
  EExperimentStatus,
  EExperimentType,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { ColorBadge } from '../../components/ColorBadge';
import { ExperimentOptionsCell } from '../../components/ExperimentOptionsCell';
import { EExperimentTypeTranslateFull } from '../../../../../../../../../../../../api/models/as-fields/experiments/Experiment';

const experimentStatuses = {
  [EExperimentStatus.Draft]: 'Черновик',
  [EExperimentStatus.InApproval]: 'ТЗ на согласовании',
  [EExperimentStatus.Approved]: 'ТЗ согласовано',
  [EExperimentStatus.InProgress]: 'В работе',
  [EExperimentStatus.Completed]: 'Завершен',
  [EExperimentStatus.FactAppruved]: 'Отчёт согласован',
  [EExperimentStatus.FullCompleted]: 'Закрыт',
  [EExperimentStatus.Canceled]: 'Отменён',
};

@provide.transient()
class ExperimentsTableConfigService {
  createConfig = (): IBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList();
    const rowsGroupList = this.createRowsGroupList();

    return {
      id: EContractsTableBuilderId.Experiments,
      columnConfigList: columnList,
      cellConfigList: cellList,
      rowsGroupConfigList: rowsGroupList,
    };
  };

  createColumnList = (): IColumnConfig[] => {
    const name = this.createNameColumn();
    const culture = this.createCultureColumn();
    const type = this.createTypeColumn();
    const status = this.createStatusColumn();
    const actions = this.createActionsColumn();

    return [name, culture, type, status, actions];
  };

  createNameColumn = (): IColumnConfig => {
    return {
      id: 'name',
      name: 'Название',
      width: {
        default: '23%',
      },
    };
  };

  createCultureColumn = (): IColumnConfig => {
    return {
      id: 'culture',
      name: 'Культура',
      width: {
        default: '24%',
      },
    };
  };

  createTypeColumn = (): IColumnConfig => {
    return {
      id: 'type',
      name: 'Тип опыта',
      width: {
        default: '23%',
      },
    };
  };

  createStatusColumn = (): IColumnConfig => {
    return {
      id: 'status',
      name: 'Статус опыта',
      width: {
        default: '18%',
      },
    };
  };

  createActionsColumn = (): IColumnConfig => {
    return {
      id: 'actions',
      name: '',
      width: {
        default: '12%',
      },
    };
  };

  createCellList = (): ICellConfig[] => {
    const name = this.createNameCell();
    const culture = this.createCultureCell();
    const type = this.createTypeCell();
    const status = this.createStatusCell();
    const actions = this.createActionsCell();

    return [name, culture, type, status, actions];
  };

  createNameCell = (): ICellConfig => {
    return {
      id: 'name',
      autoRenderConfig: {
        renderValue: row => row.name,
      },
    };
  };

  createCultureCell = (): ICellConfig => {
    return {
      id: 'culture',
      autoRenderConfig: {
        renderValue: row => row.culture.name,
      },
    };
  };

  createTypeCell = (): ICellConfig => {
    return {
      id: 'type',
      autoRenderConfig: {
        renderValue: row =>
          row.type === EExperimentType.MultiProduct
            ? EExperimentTypeTranslateFull.MultiProduct
            : EExperimentTypeTranslateFull.SingleProduct,
      },
    };
  };

  createStatusCell = (): ICellConfig => {
    return {
      id: 'status',
      customRenderConfig: {
        renderCell: row => (
          <ColorBadge badgeStatus={row.status}>{experimentStatuses[row.status]}</ColorBadge>
        ),
      },
    };
  };

  createActionsCell = (): ICellConfig => {
    return {
      id: 'actions',
      customRenderConfig: {
        renderCell: row => <ExperimentOptionsCell rowData={row} />,
      },
    };
  };

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EContractsTableBuilderId.Experiments,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default ExperimentsTableConfigService;
