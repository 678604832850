import { FC } from 'react';
import { observer } from 'mobx-react';

import { ITableBuilderRow as IRow } from '../../../models/data';
import { useStore } from '../../../../../utils/helpers/mobx';
import { TableBuilderStore as Store } from '../../../mobx/stores';
import { useSortBy } from '../../../../../utils/hooks/useSortBy';
import { useTableBuilderUIContext as useContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { TableBuilderRowsGroupContainer } from '../../rowsGroups/TableBuilderRowsGroupContainer';

import Styled from './TableBuilderRowNestedRenderContainer.styles';

interface IProps {
  row: IRow;
  borderType: string;
}

const TableBuilderRowNestedRenderContainer: FC<IProps> = ({ row, borderType }) => {
  const store = useStore(Store);

  const context = useContext();

  const rowsGroupList = useSortBy(
    store.getRowsGroupList(context.builderId, { byRootRowId: row.id })
  );

  const getDataTestId = useDataTestId('nested-row');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      {rowsGroupList.map(rowsGroup => (
        <TableBuilderRowsGroupContainer
          key={rowsGroup.id}
          rowsGroup={rowsGroup}
          borderType={borderType}
        />
      ))}
    </Styled.Wrapper>
  );
};

TableBuilderRowNestedRenderContainer.displayName = 'TableBuilderRowNestedRenderContainer';

export default observer(TableBuilderRowNestedRenderContainer);
