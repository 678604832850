import { TApiRoute } from '../../models/system/ApiRoute.model';

type TUploadFileReq = {
  data: File;
  url: string;
};

type TUploadFileRes = {};

export const uploadFile: TApiRoute & {
  request: TUploadFileReq;
  response: TUploadFileRes;
} = {
  url: request => request.url,
  method: 'PUT',
  headers: {},
  request: {} as TUploadFileReq,
  response: {} as TUploadFileRes,
};

export type { TUploadFileReq, TUploadFileRes };
