import { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../common/utils/hooks/locators';
import { EExperimentStatus } from '../../../../../../../../api/models/as-fields/experiments';

import { getExperimentStatusColor } from './utils/helpers';
import Styled from './ExperimentStatus.styled';

interface IProps {
  status: EExperimentStatus;
}

const ExperimentStatus: FC<IProps> = ({ status }) => {
  const { bgColor, color, statusName } = getExperimentStatusColor(status);

  const getDataTestId = useDataTestIdV2('experiment');

  return (
    <Styled.Container>
      <Styled.Wrapper $bgColor={bgColor} $color={color} {...getDataTestId()}>
        {statusName}
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default memo(ExperimentStatus);
