import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { ButtonLink, TButtonLinkOnClick } from '@farmlink/farmik-ui';

import { TableFiltersBuilderStore as BuilderStore } from '../../../mobx/stores';
import { TableFiltersBuilderPresetContainer as Preset } from '../../filters';
import { TableFiltersBuilderController as BuilderController } from '../../../mobx/controllers';
import { useSortBy } from '../../../utils/hooks/states/sort';
import { TableFiltersBuilderGrid as Grid } from '../../shared';
import { TableFiltersBuilderGridItem as GridItem } from '../../../components/shared';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { useStore } from '../../../../../utils/helpers/mobx';

import Styled from './TableFiltersBuilderPresets.styles';

interface IProps {
  builderId: string;
}

const TableFiltersBuilderPresets: FC<IProps> = ({ builderId }) => {
  const builderStore = useStore(BuilderStore);
  const builderController = useStore(BuilderController);

  const presetList = useSortBy(builderStore.getPresetList(builderId));

  const isShowFilters = Boolean(builderStore.getIsShowFilters(builderId));
  const selectedPreset = builderStore.getSelectedPreset(builderId);

  const handleToggleFiltersClick = useCallback<TButtonLinkOnClick>(event => {
    event.stopPropagation();

    builderController.toggleFiltersVisibility(builderId);
  }, []);

  const getDataTestId = useDataTestId();
  const buttonLinkDataTestId = getDataTestId('toggle-filters-button')['data-test-id'];

  return (
    <Grid contentKey={'presets'}>
      {presetList.map(preset => (
        <GridItem key={preset.id} name={preset.id}>
          <Preset key={preset.id} preset={preset} isSelected={selectedPreset?.id === preset.id} />
        </GridItem>
      ))}

      <Styled.Controls {...getDataTestId('grid-item')}>
        {isShowFilters ? (
          <ButtonLink
            color={'secondary'}
            icon={{ type: 'arrow-top', placement: 'right' }}
            onClick={handleToggleFiltersClick}
            dataTestId={buttonLinkDataTestId}
          >
            Свернуть
          </ButtonLink>
        ) : (
          <ButtonLink
            color={'secondary'}
            icon={{ type: 'arrow-bottom', placement: 'right' }}
            onClick={handleToggleFiltersClick}
            dataTestId={buttonLinkDataTestId}
          >
            Ещё фильтры
          </ButtonLink>
        )}
      </Styled.Controls>
    </Grid>
  );
};

TableFiltersBuilderPresets.displayName = 'TableFiltersBuilderPresets';

export default observer(TableFiltersBuilderPresets);
