import { makeAutoObservable } from 'mobx';

import { TGetTechOperationTypesReq } from '../../../../../../../../../../../../api';
import { ISeason } from '../../../../../../../../../../../../api/models/as-fields/seasons';
import { AxiosService } from '../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
export class TechOperationService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  constructor() {
    makeAutoObservable(this);
  }

  getTechOperationTypes = async (payload?: TGetTechOperationTypesReq) => {
    try {
      const { content } = await this.axiosService.api.getTechOperationTypes(payload);

      return content;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  getSeasonList = async (organizationId: string): Promise<ISeason[]> => {
    const { getSeasonList } = this.axiosService.api;

    try {
      const seasonList = await getSeasonList({
        organizationId,
        allowStub: false,
        limit: 40,
        sort: 'ASC',
        endYear: 2030,
      });

      return seasonList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}
