import { CalendarComponent } from '@farmlink/farmik-ui';
import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../../common/assets/styles/colors';

const { GeneralBlack, GeneralWhite } = ECommonColor;

type TDatePickerContainerProps = {
  $isBlocked: boolean;
};

const DatePickerContainer = styled(CalendarComponent)<TDatePickerContainerProps>`
  ${({ $isBlocked }) =>
    $isBlocked &&
    css`
      & input[type='text'] {
        pointer-events: none;
        color: ${GeneralBlack};
        background-color: ${GeneralWhite};
      }

      ::placeholder {
        color: ${GeneralBlack};
      }
    `}
`;

const StyledDatePickerContainer = {
  DatePickerContainer,
};

export default StyledDatePickerContainer;
