import { useCallback, useEffect, useState } from 'react';

import { IFile } from '../../../../../../../../api/models/common';

export interface IUseImagesCarouselConfig {
  imageList: IFile[];
  selectedImage?: IFile;
  pinnedImage?: IFile;
  onPinImage?: (pinnedImage: IFile | null) => void;
}

const useImagesCarousel = ({
  imageList,
  selectedImage,
  pinnedImage,
  onPinImage,
}: IUseImagesCarouselConfig) => {
  const [_selectedImage, setSelectedImage] = useState<IFile | null>(null);
  const [_pinnedImage, setPinnedImage] = useState<IFile | null>(null);

  const [visibilityOfArrowButtons, setVisibilityOfArrowButtons] = useState({
    isLeftArrowButton: false,
    isRightArrowButton: false,
  });

  useEffect(() => {
    if (!_selectedImage || !imageList?.length || imageList?.length === 1) {
      setVisibilityOfArrowButtons({
        isLeftArrowButton: false,
        isRightArrowButton: false,
      });

      return;
    }

    const selectedImagePosition = imageList.indexOf(_selectedImage);

    if (selectedImagePosition === 0) {
      setVisibilityOfArrowButtons({
        isLeftArrowButton: false,
        isRightArrowButton: true,
      });
    } else if (selectedImagePosition === imageList.length - 1) {
      setVisibilityOfArrowButtons({
        isLeftArrowButton: true,
        isRightArrowButton: false,
      });
    } else {
      setVisibilityOfArrowButtons({
        isLeftArrowButton: true,
        isRightArrowButton: true,
      });
    }
  }, [_selectedImage]);

  useEffect(() => {
    if (imageList?.length) {
      setSelectedImage(imageList[0]);
    }
  }, [imageList]);

  useEffect(() => {
    // Если есть закрепленная картинка, то делаем ее выбранной.
    if (pinnedImage) {
      setSelectedImage(pinnedImage);
    }

    setPinnedImage(pinnedImage);
  }, [pinnedImage]);

  // selectedImage — это то изображение, которое в данный момент отображается.
  useEffect(() => {
    if (selectedImage) {
      setSelectedImage(selectedImage);
    }
  }, [selectedImage]);

  const handlePinButtonClick = useCallback(() => {
    setPinnedImage(_selectedImage);
    onPinImage?.(_selectedImage);
  }, [onPinImage, _selectedImage, _pinnedImage]);

  const handleLeftArrowClick = useCallback(() => {
    const selectedImagePosition = imageList.indexOf(_selectedImage);

    setSelectedImage(imageList[selectedImagePosition - 1]);
  }, [_selectedImage, imageList]);

  const handleRightArrowClick = useCallback(() => {
    const selectedImagePosition = imageList.indexOf(_selectedImage);

    setSelectedImage(imageList[selectedImagePosition + 1]);
  }, [_selectedImage, imageList]);

  return {
    _selectedImage,
    _pinnedImage,
    visibilityOfArrowButtons,
    handlePinButtonClick,
    handleLeftArrowClick,
    handleRightArrowClick,
  };
};

export default useImagesCarousel;
