import styled, { css } from 'styled-components';

const CustomArrowWrapper = styled.div<{ $isDropped?: boolean }>`
  display: flex;
  transition: 0.2s;

  ${({ $isDropped }) =>
    $isDropped
      ? css`
          transform: rotate(0deg);
        `
      : css`
          transform: rotate(180deg);
        `};
`;

const Styled = {
  CustomArrowWrapper,
};

export default Styled;
