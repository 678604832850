import { ICreateNutritionHistoryItemDto } from '../../../../models/as-fields/plan/NutrationHistory';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TCreateNutritionHistoryProtectionItemReq = ICreateNutritionHistoryItemDto;
type TCreateNutritionHistoryProtectionItemRes = {} & { id: string };

const createNutritionHistoryProtectionItem: TApiRoute & {
  request: TCreateNutritionHistoryProtectionItemReq;
  response: TCreateNutritionHistoryProtectionItemRes;
} = {
  url: () => `/api/as-fields/protection-item/create`,
  method: 'POST',
  request: {} as TCreateNutritionHistoryProtectionItemReq,
  response: {} as TCreateNutritionHistoryProtectionItemRes,
  headers: {},
};

export { createNutritionHistoryProtectionItem };
export type { TCreateNutritionHistoryProtectionItemReq, TCreateNutritionHistoryProtectionItemRes };
