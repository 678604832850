import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import { IExperiment } from '../../../../../../../../../api/models/as-fields/experiments';

@provide.singleton()
export class ExperimentSubsectionsStore {
  constructor() {
    makeAutoObservable(this);
  }

  _experiment: IExperiment = null;

  get experiment() {
    return this._experiment;
  }

  setExperiment = (experiment: IExperiment) => {
    this._experiment = experiment;
  };

  clearExperiment = () => {
    this._experiment = null;
  };

  clearStore = () => {
    this.clearExperiment();
  };
}
