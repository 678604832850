import React, { FC, memo } from 'react';
import { observer } from 'mobx-react';

import { AttributeValueComponentProps } from '../../../../../../../../../common/features/DynamicTable/models/AttributeValue/AttributeValue.model';
import { systemHelperCollection as SystemHelpers } from '../../../../../../../../../common/utils/helpers/system';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import TaskReportStore from '../../mobx/stores/TaskReport.store';

import Styled from './TaskReportViewChecklistAttrValue.styles';

const TaskReportViewChecklistAttrValue: FC<AttributeValueComponentProps> = ({ attributeValue }) => {
  const { instanceId, value } = attributeValue;

  const { selectedTask } = useStore(TaskReportStore);

  const handleClick = (): void => {
    SystemHelpers.goToAssistanceChecklist(selectedTask.organizationId, selectedTask.id, instanceId);
  };

  return <Styled.Wrapper onClick={handleClick}>{value}</Styled.Wrapper>;
};

TaskReportViewChecklistAttrValue.displayName = 'TaskReportViewChecklistAttrValue';

export default memo(observer(TaskReportViewChecklistAttrValue));
