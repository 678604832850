import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';
import { IGeoUnit } from '../../../../../../api/models/as-fields/geo-unit';

const createGeoUnitsSelectOption = (geoUnits: IGeoUnit): ISelectOption => {
  return { label: geoUnits.name, value: geoUnits.id };
};

const createGeoUnitsSelectOptionList = (geoUnitsList: IGeoUnit[]): ISelectOption[] => {
  return geoUnitsList.map<ISelectOption>(geoUnits => createGeoUnitsSelectOption(geoUnits));
};

export { createGeoUnitsSelectOption, createGeoUnitsSelectOptionList };
