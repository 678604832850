import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import {
  ITableBuilderCellConfig,
  ITableBuilderColumnConfig,
  ITableBuilderConfig,
} from '../../../../../../../../common/features/TableBuilder/models/configs';
import { EExperimentsTableBuilderId } from '../../../../../constants/configs';
import {
  FDCellComponent,
  StatusCellComponent,
  TypeCellComponent,
} from '../../../../Experiments/components';

type TBuilderConfig = ITableBuilderConfig;
type TColumnConfig = ITableBuilderColumnConfig;
type TCellConfig = ITableBuilderCellConfig;

@provide.transient()
class ExperimentsListConfigsService {
  createConfig = (): TBuilderConfig => ({
    id: EExperimentsTableBuilderId.Experiments,
    columnConfigList: this.createColumnList(),
    cellConfigList: this.createCellList(),
    rowsGroupConfigList: this.createRowsGroupList(),
  });

  protected createColumnList = (): TColumnConfig[] => [
    this.createCodeColumn(),
    this.createNameColumn(),
    this.createSeasonYearColumn(),
    this.createOrganizationColumn(),
    this.createTypeColumn(),
    this.createCultureColumn(),
    this.createDistrictColumn(),
    this.createRegionColumn(),
    this.createAssigneeColumn(),
    this.createStatusColumn(),
  ];

  protected createCodeColumn = (): TColumnConfig => ({
    id: 'code',
    name: 'Номер',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '76px',
      s1024: '76px',
    },
  });

  protected createNameColumn = (): TColumnConfig => ({
    id: 'name',
    name: 'Название',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '1fr',
      s1024: '1fr',
    },
  });

  protected createSeasonYearColumn = (): TColumnConfig => ({
    id: 'seasonYear',
    name: 'Год',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '56px',
      s1024: '56px',
    },
  });

  protected createOrganizationColumn = (): TColumnConfig => ({
    id: 'organization',
    name: 'Организация',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '1.25fr',
      s1024: '1.25fr',
    },
  });

  protected createTypeColumn = (): TColumnConfig => ({
    id: 'type',
    name: 'Тип',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '56px',
      s1024: '56px',
    },
  });

  protected createCultureColumn = (): TColumnConfig => ({
    id: 'culture',
    name: 'Культура',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '1fr',
      s1024: '1fr',
    },
  });

  protected createDistrictColumn = (): TColumnConfig => ({
    id: 'district',
    name: 'ФО',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '56px',
      s1024: '56px',
    },
  });

  protected createRegionColumn = (): TColumnConfig => ({
    id: 'region',
    name: 'Регион',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '0.75fr',
      s1024: '0.75fr',
    },
  });

  protected createAssigneeColumn = (): TColumnConfig => ({
    id: 'assignee',
    name: 'Ответственный',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '1fr',
      s1024: '1fr',
    },
  });

  protected createStatusColumn = (): TColumnConfig => ({
    id: 'status',
    name: 'Статус',
    width: {
      default: '1fr',
      s1920: '1fr',
      s1366: '135px',
      s1024: '135px',
    },
  });

  protected createCellList = (): TCellConfig[] => [
    this.createCodeCell(),
    this.createNameCell(),
    this.createSeasonYearCell(),
    this.createOrganizationCell(),
    this.createTypeCell(),
    this.createCultureCell(),
    this.createDistrictCell(),
    this.createRegionCell(),
    this.createAssigneeCell(),
    this.createStatusCell(),
  ];

  createCodeCell = (): TCellConfig => ({
    id: 'code',
    autoRenderConfig: {
      renderValue: row => row?.code,
    },
  });

  createNameCell = (): TCellConfig => ({
    id: 'name',
    autoRenderConfig: {
      renderValue: row => row?.name,
    },
  });

  createSeasonYearCell = (): TCellConfig => ({
    id: 'seasonYear',
    autoRenderConfig: {
      renderValue: row => row?.seasonYear,
    },
  });

  createOrganizationCell = (): TCellConfig => ({
    id: 'organization',
    autoRenderConfig: {
      renderValue: row => row?.organization?.name,
    },
  });

  createTypeCell = (): TCellConfig => ({
    id: 'type',
    customRenderConfig: {
      renderCell: row => <TypeCellComponent rowData={row} />,
    },
  });

  createCultureCell = (): TCellConfig => ({
    id: 'culture',
    autoRenderConfig: {
      renderValue: row => row?.culture?.name,
    },
  });

  createDistrictCell = (): TCellConfig => ({
    id: 'district',
    customRenderConfig: {
      renderCell: row => <FDCellComponent rowData={row} />,
    },
  });

  createRegionCell = (): TCellConfig => ({
    id: 'region',
    autoRenderConfig: {
      renderValue: row => row?.region?.name,
    },
  });

  createAssigneeCell = (): TCellConfig => ({
    id: 'assignee',
    autoRenderConfig: {
      renderValue: row => row?.assignee?.fullName,
    },
  });

  createStatusCell = (): TCellConfig => ({
    id: 'status',
    customRenderConfig: {
      renderCell: row => <StatusCellComponent status={row?.status} />,
    },
  });

  createRowsGroupList = () => {
    return [
      {
        id: EExperimentsTableBuilderId.Experiments,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default ExperimentsListConfigsService;
