import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
html {
    height: -webkit-fill-available;
}
@media screen and (min-width: 960px) {
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }
}
`;
