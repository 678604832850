import { useContext, useEffect } from 'react';
import { History } from 'history';
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type ExtendedNavigator = Navigator & Pick<History, 'block'>;

const useBlocker = (blocker: (tx: any) => void, isBlockActivated, whiteList: string[]): void => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!isBlockActivated) {
      return;
    }

    const unblock = (navigator as ExtendedNavigator).block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      if (whiteList.includes(tx.location.state as string)) {
        autoUnblockingTx.retry();
      } else {
        blocker(autoUnblockingTx);
      }
    });

    return () => {
      unblock();
    };
  }, [navigator, blocker, isBlockActivated]);
};

export default useBlocker;
