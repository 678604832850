import { TApiRoute } from '../../../models/system/ApiRoute.model';
import {
  EExperimentStatus,
  EExperimentsWizardProgress,
  IExperiment,
} from '../../../models/as-fields/experiments';
import { IExperimentAttributesFormForSave } from '../../../../modules/dashboard/modules/experiments/modules/createExperiment/modules/attributes/forms/config/attributesFormConfig';

type TCreateExperimentReq = {
  status: EExperimentStatus;
  wizardProgress: EExperimentsWizardProgress;
} & IExperimentAttributesFormForSave;

type TCreateExperimentRes = {} & IExperiment;

const createExperiment: TApiRoute & {
  request: TCreateExperimentReq;
  response: TCreateExperimentRes;
} = {
  url: () => `/api/as-fields/experiment`,
  method: 'POST',
  request: {} as TCreateExperimentReq,
  response: {} as TCreateExperimentRes,
  headers: {},
};

export type { TCreateExperimentReq, TCreateExperimentRes };

export { createExperiment };
