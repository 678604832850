import { AccessStore } from '../../../../../../../../../common/mobx/stores/AccessStore';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';

import useCreateExperimentError from './useCreateExperimentError';
import useCreateExperimentInfo from './useCreateExperimentInfo';

const useCreateExperimentGetContent = () => {
  const accessStore = useStore(AccessStore);
  const { responseError } = accessStore;

  const ExperimentInfo = useCreateExperimentInfo();
  const ExperimentError = useCreateExperimentError();

  if (responseError) {
    return ExperimentError;
  } else {
    return ExperimentInfo;
  }
};

export default useCreateExperimentGetContent;
