import { createContext } from 'react';

interface IPageContentContext {
  pageScrollHeight: number;
}

const defaultValue: IPageContentContext = {
  pageScrollHeight: 0,
};

const PageContentContext = createContext<IPageContentContext>(defaultValue);

export default PageContentContext;
