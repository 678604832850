import { observer } from 'mobx-react';
import { FC } from 'react';

import { useComparisonTableBuilderContext as useContext } from '../../context';
import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../hooks';
import { useStore } from '../../../../utils/helpers/mobx';
import { ComparisonTableBuilderStore as Store } from '../../mobx/stores';
import { useSortBy } from '../../../../utils/hooks/useSortBy';
import { ComparisonTableBuilderRowsGroupContainer as RowsGroupContainer } from '../rowsGroups/ComparisonTableBuilderRowsGroupContainer';

import Styled from './ComparisonTableBuilderContent.styles';

const ComparisonTableBuilderContent: FC = () => {
  const store = useStore(Store);

  const context = useContext();

  const rowsGroupList = useSortBy(store.getRowsGroupList(context.builderId));

  const getDataTestId = useDataTestId({ componentName: 'content' });

  return (
    <Styled.Wrapper {...getDataTestId()}>
      {rowsGroupList.map(rowsGroup => (
        <RowsGroupContainer key={rowsGroup.id} rowsGroup={rowsGroup} />
      ))}
    </Styled.Wrapper>
  );
};

ComparisonTableBuilderContent.displayName = 'ComparisonTableBuilderContent';

export default observer(ComparisonTableBuilderContent);
