import moment from 'moment';

import { IExperimentStep } from '../../../../../../../../api/models/as-fields/experiments';
import { EDashboardRoute } from '../../../../../../routes/DashboardRoute';
import { EAuditRoute } from '../../routes';
import { EClearedPaths } from '../constants';

const formatDate = (date: string): string => moment(date).format('DD.MM.YYYY');
const formatName = (name?: string): string => name || 'Какое-то наблюдение';
const formatText = (name: string, date: string): string => `${name} от ${date}`;

const createBackButtonText = (audit: IExperimentStep, clearedCurrentPath: string): string => {
  if (clearedCurrentPath === EClearedPaths.editAudit) {
    return 'Редактирование наблюдения';
  } else if (audit) {
    return formatText(formatName(audit?.name), formatDate(audit.planStartDate));
  } else {
    return 'Создание наблюдения';
  }
};

const createGoBackText = (clearedCurrentPath: string): string => {
  if (
    clearedCurrentPath === `${EDashboardRoute.Audits}/${EAuditRoute.Approval}` ||
    clearedCurrentPath === `${EDashboardRoute.Audits}/${EAuditRoute.Documents}` ||
    clearedCurrentPath === `${EAuditRoute.EditAudit}`
  ) {
    return 'К просмотру наблюдения';
  } else if (clearedCurrentPath === EClearedPaths.createAudit) {
    return 'К списку наблюдений';
  } else {
    return 'К списку наблюдений';
  }
};

const AuditHelpers = { createBackButtonText, createGoBackText };

export default AuditHelpers;
