import { useContext } from 'react';

import TableBuilderUIContext from '../../TableBuilderUI.context';

const useTableBuilderUIContext = () => {
  const UIContext = useContext(TableBuilderUIContext);

  return UIContext;
};

export default useTableBuilderUIContext;
