import { Checkbox, Colors } from '@farmlink/farmik-ui';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: row;
  border-top: 1px solid #e9e6f0;
  padding-top: 24px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: row;
  max-height: 50vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const FooterWrapper = styled.div<{ $isOverflow: boolean }>`
  width: 100%;
  ${({ $isOverflow }) =>
    $isOverflow &&
    css`
      background: #ffffff;
      padding-top: 16px;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    `}
`;

const Attribute = styled.div<{ marginBottom?: string; width?: string; marginRight?: string }>`
  flex: 1 1 ${({ width }) => width || '40%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  margin-right: ${({ marginRight }) => marginRight || 0};
`;

const CheckboxAttribute = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: start;
  flex: 1 1 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  p {
    color: ${Colors.secondaryDarkGray};
  }
`;

const StyledInventoryValuesForm = {
  Wrapper,
  Attribute,
  CheckboxAttribute,
  ContentWrapper,
  FooterWrapper,
  Checkbox: StyledCheckbox,
};
export default StyledInventoryValuesForm;
