import { FC, memo, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import {
  _AttributeValue,
  _Instance,
  IComparisonTableImagesConfigSelectedInstance as IImagesConfigSelectedInstance,
} from '../../../models';
import { useStore } from '../../../../../utils/helpers/mobx';
import { DynamicTableController } from '../../../mobx/controllers';
import { ImagesSelectionCard } from '../../../../UI';
import { IImagesSelectionCardProps } from '../../../../UI/ImagesSelectionCard/ImagesSelectionCard';

interface IProps {
  extendedInstance: _Instance | null;
  extendedAttributeValue: _AttributeValue;
  imageSizes: Pick<IImagesSelectionCardProps, 'cardWidth' | 'mainHeight'>;
  selectedInstance: IImagesConfigSelectedInstance;
  isEnableEditing: boolean;
}

const ComparisonTableImagesAttributeValue: FC<IProps> = ({
  extendedInstance,
  extendedAttributeValue,
  imageSizes,
  selectedInstance,
  isEnableEditing,
}) => {
  const { fileValueList } = extendedAttributeValue;

  const { onToggleImageSelection } = useStore(DynamicTableController);

  const pinnedImage = useMemo(() => {
    if (!fileValueList?.length) {
      return;
    }

    return fileValueList.find(img => img.id === selectedInstance?.imageId);
  }, [fileValueList, selectedInstance]);

  const handlePinButtonClick = useCallback<IImagesSelectionCardProps['onPinImage']>(
    _pinnedImage => {
      if (isEnableEditing) {
        onToggleImageSelection(extendedAttributeValue.attributeId, {
          imageId: _pinnedImage.id,
          instanceId: extendedAttributeValue.instanceId,
        });
      }
    },
    [isEnableEditing]
  );

  return (
    <ImagesSelectionCard
      imageList={fileValueList}
      name={extendedInstance?.name || 'Без названия'}
      pinnedImage={pinnedImage}
      onPinImage={handlePinButtonClick}
      isEnableEditing={isEnableEditing}
      {...imageSizes}
    />
  );
};

ComparisonTableImagesAttributeValue.displayName = 'ComparisonTableImagesAttributeValue';

export default memo(observer(ComparisonTableImagesAttributeValue));
