import { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import { ITabItemProps } from './TabItem.types';
import Styled from './TabItem.styles';

const TabItem: FC<ITabItemProps> = ({ tab, activeTabId, onClick, dataTestId }) => {
  const { id, title } = tab;

  const getDataTestId = useDataTestIdV2(`${dataTestId}__tabs-item`);

  const handleTabItemClick = () => {
    if (onClick) {
      onClick(tab);
    }
  };

  return (
    <Styled.Wrapper
      $isSelected={activeTabId === id}
      onClick={handleTabItemClick}
      {...getDataTestId()}
    >
      <Styled.Title {...getDataTestId('title')}>{title}</Styled.Title>
    </Styled.Wrapper>
  );
};

TabItem.displayName = 'TabItem';

export default memo(TabItem);
