import { IChecklistInstance } from '../../../../models/as-fields/checklists/Instance/ChecklistInstance.model';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetChecklistInstanceReq = {
  id: string;
};

type TGetChecklistInstanceRes = IChecklistInstance;

const getChecklistInstance: TApiRoute & {
  request: TGetChecklistInstanceReq;
  response: TGetChecklistInstanceRes;
} = {
  url: ({ id }) => `/api/as-fields/checklist/instance/${id}`,
  method: 'GET',
  request: {} as TGetChecklistInstanceReq,
  response: {} as TGetChecklistInstanceRes,
  headers: {},
};

export type { TGetChecklistInstanceReq, TGetChecklistInstanceRes };

export { getChecklistInstance };
