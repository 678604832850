import { useState, useCallback } from 'react';

import { useStore } from '../utils/helpers/mobx';
import FileUploadService from '../mobx/services/as-files/fileUpload.service';

interface IFileUploaderProps {
  file: File | null;
  isLoading: boolean;
  error: Error | null;
  handleFileInputClick: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<{
    id: string;
    name: string;
    size: number;
    type: string;
    file: File;
  }>;
  handleUpload: (file: File, ownerId?: string) => void;
}

interface IUseFileUploadConfig {
  maxSize?: number;
}

type TUseFileUpload = (
  inputRef: React.MutableRefObject<HTMLInputElement>,
  { maxSize }?: IUseFileUploadConfig
) => IFileUploaderProps;

const useFileUpload: TUseFileUpload = (inputRef, config) => {
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { uploadFile } = useStore(FileUploadService);

  const validateFile = useCallback(
    (selectedFile: File) => {
      if (config.maxSize && selectedFile.size > config.maxSize) {
        throw Error('Файл слишком большой.');
      }
    },
    [config]
  );

  const handleUpload = useCallback((selectedFile: File, ownerId?: string) => {
    try {
      validateFile(selectedFile);

      setIsLoading(true);
      const fileData = uploadFile(selectedFile, ownerId);
      setFile(null);

      return fileData;
    } catch (err) {
      setError(err);

      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setFile(event.target.files?.[0]);
      const result = await handleUpload(event.target.files?.[0]);

      return { ...result, file: event.target.files?.[0] };
    },
    [handleUpload]
  );

  const handleFileInputClick = useCallback(() => {
    if (inputRef?.current) {
      inputRef?.current.click();
    }
  }, []);

  return {
    file,
    isLoading,
    error,
    handleFileInputClick,
    handleFileChange,
    handleUpload,
  };
};

export { useFileUpload };
