import { makeAutoObservable } from 'mobx';

import { IDictionaryEntity } from '../../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { provide } from '../../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
export class TechOperationStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _mapIdToPhenophase: Map<string, IDictionaryEntity> = new Map();
  private _mapIdToBbch: Map<string, IDictionaryEntity> = new Map();

  setPhenophase = (phenophases: IDictionaryEntity[]) => {
    const newMap = new Map();
    for (const phenophase of phenophases) {
      newMap.set(phenophase.id, phenophase);
    }

    this._mapIdToPhenophase = newMap;
  };

  setBbch = (bbchs: IDictionaryEntity[]) => {
    const newMap = new Map();
    for (const bbch of bbchs) {
      newMap.set(bbch.id, bbch);
    }

    this._mapIdToBbch = newMap;
  };

  get phenphase() {
    return [...this._mapIdToPhenophase.values()];
  }

  get bbch() {
    return [...this._mapIdToBbch.values()];
  }
}
