import { EObservationStatus } from '../../../../../../../../../common/utils/constants/observation';

const getStatusCellComponentColors = status => {
  let bgColor = '#FAF9FD';
  let color = '#151F32';
  let statusName = status;

  switch (status) {
    case EObservationStatus.Draft:
      bgColor = '#FAF9FD';
      color = '#7E8B9D';
      statusName = 'Черновик';
      break;
    case EObservationStatus.InApproval:
      bgColor = '#FFEDB8';
      color = '#FF7527';
      statusName = 'На согласовании';
      break;
    case EObservationStatus.Approved:
      bgColor = '#E7F1FD';
      color = '#0770E3';
      statusName = 'Согласован';
      break;
    default:
      break;
  }

  return { bgColor, color, statusName };
};

export default getStatusCellComponentColors;
