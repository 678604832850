import { TApiRoute } from '../../models/system/ApiRoute.model';

type TGetExternalRequestsTokenReq = {
  code: string;
};

type TGetExternalRequestsTokenRes = {
  needAdditionalInfo: boolean;
  'access-token': string;
  'access-token-type': string;
  'refresh-token': string;
  'rt-expires-on': string | Date;
};

const getExternalRequestsToken: TApiRoute & {
  request: TGetExternalRequestsTokenReq;
  response: TGetExternalRequestsTokenRes;
} = {
  url: `/api/da-profile/externalRequests/token`,
  method: 'GET',
  request: {} as TGetExternalRequestsTokenReq,
  response: {} as TGetExternalRequestsTokenRes,
  headers: {},
};

export type { TGetExternalRequestsTokenReq, TGetExternalRequestsTokenRes };

export { getExternalRequestsToken };
