import { cloneDeep, flowRight } from 'lodash';

import {
  ReportsInstWithAttrValList as InstWithAttrValList,
  ReportsAttrWithVal,
} from '../../../models/InstWithAttrValList/InstWithAttrValList';
import { EChecklistAttributeType } from '../../../../../../../../../../../api/models/as-fields/checklists/Attribute/ChecklistAttribute.model';

import {
  ITaskReportInstance,
  ITaskReportTableStage,
  ITaskReportTableAttribute,
} from './../../../../../../../../../../../api/models/as-fields/task/Task/TaskReportData.model';

const createAttrWithVal = (
  attribute: ITaskReportTableAttribute,
  stageId: string,
  attributeId: string
): ReportsAttrWithVal[] => {
  if (attribute?.values[0].stages) {
    return createAttrWithValList(attribute?.values[0].stages);
  }

  return [
    {
      dynamicTableAttr: { ...attribute, stageId, attributeId },
      checklistAttrVal: attribute.values,
    },
  ];
};

function createAttrWithValList(stageList: ITaskReportTableStage[]): ReportsAttrWithVal[] {
  const attrWithValList: ReportsAttrWithVal[] = [];

  stageList.forEach(({ attributes, name }) => {
    attributes.forEach(attribute => {
      if (attribute.type === EChecklistAttributeType.ChecklistInstanceLink) {
        const attrWithValListFromChecklist = attribute?.values.map(({ stages, path }) =>
          createAttrWithValListFromChecklist(stages, path)
        );

        attrWithValListFromChecklist.forEach(attrWithValFromChecklist =>
          attrWithValList.push(...attrWithValFromChecklist)
        );
      } else {
        const attrWithVal = createAttrWithVal(attribute, `${name}`, attribute.id);

        if (attrWithVal) {
          attrWithValList.push(...attrWithVal);
        }
      }
    });
  });

  return attrWithValList;
}

function createAttrWithValListFromChecklist(
  stageList: ITaskReportTableStage[],
  path: string
): ReportsAttrWithVal[] {
  const attrWithValList: ReportsAttrWithVal[] = [];

  stageList.forEach(({ attributes, name }) => {
    attributes.forEach(attribute => {
      const attrWithVal = createAttrWithVal(attribute, `${name}-${path}`, attribute.id);

      if (attrWithVal) {
        attrWithValList.push(...attrWithVal);
      }
    });
  });

  return attrWithValList;
}

const addAttrWithValList = (stageList: ITaskReportTableStage[]) => {
  return (instList: ITaskReportInstance[]): InstWithAttrValList[] => {
    return instList.map(instance => ({
      instance,
      attrWithValList: createAttrWithValList(stageList),
    }));
  };
};

const isAggregate = (inst: ITaskReportInstance): boolean => {
  return inst.type === 'AGGREGATE';
};

const sortInstList = (instList: ITaskReportInstance[]): ITaskReportInstance[] => {
  return cloneDeep(instList).sort(inst => {
    if (isAggregate(inst)) {
      return -1;
    } else {
      return 1;
    }
  });
};

const createInstListWithAttrValList = (
  instList: ITaskReportInstance[],
  stageList: ITaskReportTableStage[]
): InstWithAttrValList[] => {
  const composition = flowRight(addAttrWithValList(stageList), sortInstList);

  return composition(instList);
};

const createInstWithAttrValListReportsHelpers = {
  createInstListWithAttrValList,
};

export default createInstWithAttrValListReportsHelpers;
