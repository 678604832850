import { FC, memo } from 'react';

import Styled from './SpinnerLoader.styles';
import { TSpinnerLoaderProps } from './SpinnerLoader.type';

const SpinnerLoader: FC<TSpinnerLoaderProps> = ({ needToHideContent }) => {
  return (
    <Styled.Wrapper data-test-id="spinner-loader-wrapper" $needToHideContent={needToHideContent}>
      <Styled.SpinnerLoader data-test-id="spinner-loader">
        <span data-test-id="spinner-loader-span" />
      </Styled.SpinnerLoader>
    </Styled.Wrapper>
  );
};

SpinnerLoader.displayName = 'SpinnerLoader';

export default memo(SpinnerLoader);
