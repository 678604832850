import { IFertilizerPrice } from '../../../../models/as-fields/plan/FertilizerPrice';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetFertilizerPricesReg = {
  experimentId: string;
};
type TGetFertilizerPricesRes = IFertilizerPrice[];

const getFertilizerPrices: TApiRoute & {
  request: TGetFertilizerPricesReg;
  response: TGetFertilizerPricesRes;
} = {
  url: () => `/api/as-fields/nutrition-history/fertilizerPrices`,
  method: 'GET',
  request: {} as TGetFertilizerPricesReg,
  response: {} as TGetFertilizerPricesRes,
  headers: {},
};

export { getFertilizerPrices };
export type { TGetFertilizerPricesReg, TGetFertilizerPricesRes };
