import { generatePath, useNavigate } from 'react-router-dom';

import { EExperimentsRoute } from '../../../../../routes';
import { ECreateExperimentRoute } from '../../../../../modules/createExperiment/routes';
// @ts-ignore
import noDataIcon from '../../../../../../../../common/components/table/assets/icons/noDataIcon.svg';
// @ts-ignore
import noPermissionIcon from '../../../../../../../../common/components/table/assets/icons/cancel.svg';
// @ts-ignore
import noSearchIcon from '../../../../../../../../common/components/table/assets/icons/error.svg';

const useGetPlugInfo = (isAllowToWatchExperiments, noDataFindWithFilters) => {
  const navigate = useNavigate();

  if (!isAllowToWatchExperiments) {
    const plugIcon = noPermissionIcon;

    const plugTitle = 'Вы не можете просматривать этот раздел';

    const buttonTitle = 'На главную';

    const plugDescription = 'Для смены настройки прав доступа, обратитесь к руководству';

    const onButtonClick = () => navigate('/');

    return { plugIcon, plugTitle, plugDescription, buttonTitle, onButtonClick };
  } else if (noDataFindWithFilters) {
    const plugIcon = noSearchIcon;

    const plugTitle = 'По заданным параметрам ничего не найдено';

    const plugDescription = 'Измените параметры запроса и попробуйте еще раз';

    return { plugIcon, plugTitle, plugDescription };
  } else {
    const plugIcon = noDataIcon;

    const plugTitle = 'У вас еще нет опытов';

    const buttonTitle = 'Добавить';

    const plugDescription = 'Добавьте хотя бы один опыт, чтобы начать работу';

    const onButtonClick = () =>
      navigate(
        generatePath(`${EExperimentsRoute.Experiment}/${ECreateExperimentRoute.Attributes}`, {
          experimentId: 'create',
        })
      );

    return { plugIcon, plugTitle, plugDescription, buttonTitle, onButtonClick };
  }
};

export default useGetPlugInfo;
