import styled, { css } from 'styled-components';

import { ILinearDropdownProps } from './LineralDropdown.types';

const disabledState = css`
  pointer-events: none;
  cursor: auto;
  opacity: 0.5;
`;

const normalState = css`
  pointer-events: auto;
  cursor: pointer;
  opacity: 1;
`;

const Wrapper = styled.div<{
  $width?: ILinearDropdownProps['styles']['width'];
  $isDisabled?: boolean;
}>`
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `};

  ${({ $isDisabled }) => ($isDisabled ? disabledState : normalState)}
`;

const StyledLinearDropdown = {
  Wrapper,
};

export default StyledLinearDropdown;
