import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../common/assets/styles/colors';
import MessageColorsScheme, {
  TMessageColorItem,
} from '../../../../../common/features/ChatJournal/configs/colors.config';

const StatusPillFactApproved = styled.div<{
  $colorScheme: keyof TMessageColorItem;
  $isSelected?: boolean;
}>`
  height: 20px;
  padding: 2px 8px;
  transition: 0.2s;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  display: flex;
  gap: 5px;
  align-items: center;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      font-size: 14px;
    `}

  ${({ $colorScheme }) =>
    css`
      background-color: ${MessageColorsScheme[$colorScheme].notificationBackgroundColor};
      color: ${MessageColorsScheme[$colorScheme].textColor};
    `};

  :hover {
    ${({ $colorScheme }) =>
      css`
        background-color: ${MessageColorsScheme[$colorScheme].borderColor};
        color: ${ECommonColor.GeneralWhite}};
      `};
`;

const StatusPillInfo = styled(StatusPillFactApproved)`
  background-color: ${ECommonColor.LightSky};
  color: ${ECommonColor.AddSky};

  :hover {
    background-color: ${ECommonColor.AddSky};
    color: ${ECommonColor.GeneralWhite};
  }
`;

export { StatusPillFactApproved, StatusPillInfo };
