import { FC, PropsWithChildren, useRef } from 'react';
import { observer } from 'mobx-react';

import { useTableBuilderDataTestId as useDataTestId } from '../../hooks';
import { useStore } from '../../../../utils/helpers/mobx';
import { TableBuilderStore as Store } from '../../mobx/stores';
import { ContentLoader } from '../../../UI/loaders/ContentLoader';

import Styled from './TableBuilderPlugContainer.styles';

interface IProps {
  builderId: string;
}

const TableBuilderPlugContainer: FC<PropsWithChildren<IProps>> = ({ builderId, children }) => {
  const store = useStore(Store);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const getDataTestId = useDataTestId(builderId);

  const isFetchingElements = store.getIsFetchingElements(builderId);
  const loaderDataTestId = getDataTestId('content-loader')['data-test-id'];

  return (
    <Styled.Wrapper ref={wrapperRef} {...getDataTestId('plug-wrapper')}>
      {isFetchingElements ? (
        <ContentLoader
          parentRef={wrapperRef}
          overlayStyles={{ borderRadius: '16px', zIndex: '3' }}
          dataTestId={loaderDataTestId}
        />
      ) : null}

      {children}
    </Styled.Wrapper>
  );
};

TableBuilderPlugContainer.displayName = 'TableBuilderPlugContainer';

export default observer(TableBuilderPlugContainer);
