import styled, { css } from 'styled-components';

type TWrapper = {
  $alignItems?: 'top' | 'right' | 'bottom' | 'left' | 'center';
  $isTitle?: boolean;
  $height?: number;
  $isWrap?: boolean;
};

const getCellAlignment = ($alignItems: 'top' | 'right' | 'bottom' | 'left' | 'center') => {
  switch ($alignItems) {
    case 'top':
      return css`
        margin-bottom: auto;
      `;
    case 'right':
      return css`
        margin-left: auto;
      `;
    case 'bottom':
      return css`
        margin-top: auto;
      `;
    case 'left':
      return css`
        margin-right: auto;
      `;
    default:
  }
};

const Wrapper = styled.div<TWrapper>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  height: ${({ $height }) => $height ?? 'auto'};

  ${({ $alignItems }) => getCellAlignment($alignItems)};

  ${({ $isTitle }) =>
    $isTitle &&
    css`
      font-weight: 600;
    `}

  white-space: ${({ $isWrap }) => ($isWrap ? 'wrap' : 'nowrap')};
  text-overflow: ellipsis;
  overflow: hidden;

  color: #151f32;
`;

const StyledTableBuilderCell = {
  Wrapper,
};

export default StyledTableBuilderCell;
