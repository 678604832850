import { MutableRefObject, useEffect, useState } from 'react';

type TUseGetWidthByRef = (ref: MutableRefObject<HTMLDivElement>) => number | null;

const useGetWidthByRef: TUseGetWidthByRef = ref => {
  const [width, setWidth] = useState<number | null>(null);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [ref?.current]);

  return width;
};

export default useGetWidthByRef;
