import {
  EExperimentTypeTranslate,
  EExperimentType,
  EExperimentTypeTranslateFull,
} from '../../../../../../../../../../api/models/as-fields/experiments/Experiment';

const getTypeCellComponentName = type => {
  let typeName;
  let fullTypeName;

  switch (type) {
    case EExperimentType.SingleProduct:
      typeName = EExperimentTypeTranslate.SingleProduct;
      fullTypeName = EExperimentTypeTranslateFull.SingleProduct;
      break;
    case EExperimentType.MultiProduct:
      typeName = EExperimentTypeTranslate.MultiProduct;
      fullTypeName = EExperimentTypeTranslateFull.MultiProduct;
      break;
    default:
      typeName = type;
      fullTypeName = type;
  }

  return { typeName, fullTypeName };
};

export default getTypeCellComponentName;
