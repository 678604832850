import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TCreateAggregateInstanceReq = {
  taskId: string;
  checkListInstanceIds: string[];
};

type TCreateAggregateInstanceRes = { id: string };

const createAggregateInstance: TApiRoute & {
  request: TCreateAggregateInstanceReq;
  response: TCreateAggregateInstanceRes;
} = {
  url: () => `/api/as-fields/checklist/instance/aggregate`,
  method: 'POST',
  request: {} as TCreateAggregateInstanceReq,
  response: {} as TCreateAggregateInstanceRes,
  headers: {},
};

export type { TCreateAggregateInstanceReq, TCreateAggregateInstanceRes };

export { createAggregateInstance };
