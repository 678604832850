import styled from 'styled-components';

const Table = styled.table<{ maxHeight?: string }>`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  white-space: nowrap;
`;

const StyledTable = {
  Table,
};

export default StyledTable;
