import { makeAutoObservable } from 'mobx';

import { IFertilizerPrice } from '../../../../../../../../../../../../../api/models/as-fields/plan/FertilizerPrice';
import { IProtectionPrice } from '../../../../../../../../../../../../../api/models/as-fields/plan/ProtectionPrice';
import { IDictionaryEntity } from '../../../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
export class InventoryValueStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _mapIdToFertilizerPrice: Map<string, IFertilizerPrice> = new Map();
  private _mapIdToProtectionPrice: Map<string, IProtectionPrice> = new Map();

  private _fertilizerCurrentPage = 0;
  private _fertilizerTotalPages = 0;
  private _fertilizeSearchQuery = '';
  private _fertilizeOptionList: ISelectOption[] = [];

  private _protectionCurrentPage = 0;
  private _protectionTotalPages = 0;
  private _protectionSearchQuery = '';
  private _protectionOptionList: ISelectOption[] = [];

  private _measureInfo: IDictionaryEntity;

  private _currentFertilizerIdList: Set<string> = new Set();
  private _currentProtectionIdList: Set<string> = new Set();

  private _previousNameValue = '';
  private _formType = '';

  get currentFertilizerIdList(): string[] {
    return [...this._currentFertilizerIdList.values()];
  }

  get currentProtectionIdList(): string[] {
    return [...this._currentProtectionIdList.values()];
  }

  get measureInfo() {
    return this._measureInfo;
  }

  get previousNameValue() {
    return this._previousNameValue;
  }

  get formType() {
    return this._formType;
  }

  setCurrentFertilizerIdList = (fertilizerIdList: string[]): void => {
    this._currentFertilizerIdList = new Set(fertilizerIdList);
  };

  setCurrentProtectionIdList = (protectionIdList: string[]): void => {
    this._currentProtectionIdList = new Set(protectionIdList);
  };

  setCurrentFertilizerId = (fertilizerId: string): void => {
    this._currentFertilizerIdList.add(fertilizerId);
  };

  setCurrentProtectionId = (protectionId: string): void => {
    this._currentProtectionIdList.add(protectionId);
  };

  setPreviousNameValue = (nameValue: string): void => {
    this._previousNameValue = nameValue;
  };

  setFormType = (formType: string): void => {
    this._formType = formType;
  };

  clearCurrentFertilizerIdList = (): void => {
    this._currentFertilizerIdList.clear();
  };

  clearCurrentProtectionIdList = (): void => {
    this._currentProtectionIdList.clear();
  };

  setFertilizerPrices = (fertilizerPrices: IFertilizerPrice[]) => {
    const newMap = new Map();
    for (const fertilizerPrice of fertilizerPrices) {
      newMap.set(fertilizerPrice.fertilizerId, fertilizerPrice);
    }

    this._mapIdToFertilizerPrice = newMap;
  };

  setProtectionPrices = (protectionPrices: IProtectionPrice[]) => {
    const newMap = new Map();
    for (const protectionPrice of protectionPrices) {
      newMap.set(protectionPrice.protectionId, protectionPrice);
    }

    this._mapIdToProtectionPrice = newMap;
  };

  setFertilizerCurrentPage = (pageNumber: number) => {
    this._fertilizerCurrentPage = pageNumber;
  };

  setFertilizerTotalPages = (pageNumber: number) => {
    this._fertilizerTotalPages = pageNumber;
  };

  setFertilizeSearchQuery = (query: string) => {
    this._fertilizeSearchQuery = query;
  };

  setFertilizeOptionList = (newOptionList: ISelectOption[]) => {
    this._fertilizeOptionList = newOptionList;
  };

  setProtectionCurrentPage = (pageNumber: number) => {
    this._protectionCurrentPage = pageNumber;
  };

  setProtectionTotalPages = (pageNumber: number) => {
    this._protectionTotalPages = pageNumber;
  };

  setProtectionSearchQuery = (query: string) => {
    this._protectionSearchQuery = query;
  };

  setProtectionOptionList = (newOptionList: ISelectOption[]) => {
    this._protectionOptionList = newOptionList;
  };

  setMeasureInfo = (newMeasureInfo: IDictionaryEntity) => {
    this._measureInfo = newMeasureInfo;
  };

  getPriceByFertilizerId = (id: string) => this._mapIdToFertilizerPrice.get(id);

  getPriceByProtectionId = (id: string) => this._mapIdToProtectionPrice.get(id);

  get fertilizerPrices() {
    return [...this._mapIdToFertilizerPrice.values()];
  }

  get protectionPrices() {
    return [...this._mapIdToProtectionPrice.values()];
  }

  get fertilizerCurrentPage() {
    return this._fertilizerCurrentPage;
  }

  get fertilizerTotalPages() {
    return this._fertilizerTotalPages;
  }

  get fertilizeSearchQuery() {
    return this._fertilizeSearchQuery;
  }

  get fertilizeOptionList() {
    return this._fertilizeOptionList;
  }

  get protectionCurrentPage() {
    return this._protectionCurrentPage;
  }

  get protectionTotalPages() {
    return this._protectionTotalPages;
  }

  get protectionSearchQuery() {
    return this._protectionSearchQuery;
  }

  get protectionOptionList() {
    return this._protectionOptionList;
  }
}
