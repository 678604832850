import { FC, memo } from 'react';

import { Icon } from '../../Icon';

import rightArrowIconDefaultS24 from './assets/icons/right-arrow-icon-default-24x24.svg';
import rightArrowIconDefaultS24Hover from './assets/icons/right-arrow-icon-default-hover-24x24.svg';
import rightArrowIconDefaultS40 from './assets/icons/right-arrow-icon-default-40x40.svg';
import rightArrowIconDefaultS40Hover from './assets/icons/right-arrow-icon-default-hover-40x40.svg';

const DEFAULT_DATA_TEST_ID = 'right-arrow-button';

interface IProps {
  size: 's40' | 's24';
  onClick?: () => void;
  dataTestId?: string;
}

const RightArrowButton: FC<IProps> = ({ onClick, size, dataTestId }) => {
  return (
    <Icon
      states={{
        s40: {
          default: rightArrowIconDefaultS40,
          defaultHover: rightArrowIconDefaultS40Hover,
          selected: null,
          selectedHover: null,
        },
        s32: null,
        s24: {
          default: rightArrowIconDefaultS24,
          defaultHover: rightArrowIconDefaultS24Hover,
          selected: null,
          selectedHover: null,
        },
      }}
      size={size}
      dataTestId={dataTestId || DEFAULT_DATA_TEST_ID}
      onClick={onClick}
    />
  );
};

RightArrowButton.displayName = 'RightArrowButton';

export default memo(RightArrowButton);
