const activeSubstances = {
  N: 'N',
  P2O5: 'P₂O₅',
  K2O: 'K₂O',
  Ca: 'Ca',
  Mg: 'Mg',
  S: 'S',
  Fe: 'Fe',
  Se: 'Se',
  Ag: 'Ag',
  B: 'B',
  Mo: 'Mo',
  Mn: 'Mn',
  Cu: 'Cu',
  Zn: 'Zn',
  Co: 'Co',
  I: 'I',
  V: 'V',
};

export default activeSubstances;
