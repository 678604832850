import { Button as FarmikButton } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../../../common/assets/styles/colors';
import { ReactComponent as Copy } from '../../../../../history/containers/History/assets/icons/copy.svg';

const { White, AccentGreen } = ECommonColor;

const Button = styled(FarmikButton)`
  justify-content: center;
  width: 300px;
`;

const BackButton = styled(FarmikButton)`
  justify-content: center;
  width: 300px;
  background-color: ${White};
  color: ${AccentGreen};
  border: 1px solid #3fb65f;
`;

const TableContainer = styled.div`
  background-color: ${White};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 24px 24px 32px;
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e6f0;
  padding-bottom: 12px;
  margin-bottom: 12px;
`;

const TableTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #151f32;
  display: block;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const TabsWrapper = styled.div`
  margin-top: 20px;
`;

const AddButton = styled.button`
  border: none;
  background-color: inherit;
  color: #3fb65f;
  cursor: pointer;
  align-self: start;
  padding: 0;
`;

const CopyIcon = styled(Copy)`
  margin: 0 4px -2px;
`;

const StyledCalculation = {
  Wrapper,
  ButtonWrapper,
  Button,
  BackButton,
  TabsWrapper,
  AddButton,
  TableContainer,
  TableTitle,
  ControlContainer,
  CopyIcon,
};

export default StyledCalculation;
