import { provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { IComparisonTableBuilderColumnConfig } from '../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import { ExecutionTableColumn } from '../../../components';
import { EExecutionTableColumnId as EColumnId } from '../../../types';

@provide.transient()
class ExecutionColumnConfigsService {
  createColumnConfigList = (): IComparisonTableBuilderColumnConfig[] => [
    this.createMainColumnConfig(),
    this.createPlanColumnConfig(),
    this.createFactColumnConfig(),
    this.createDeviationsColumnConfig(),
  ];

  protected createMainColumnConfig = (): IComparisonTableBuilderColumnConfig => ({
    id: 'main',
    width: '269px',
    isFixed: true,
    customRenderConfig: {
      render: (_column, dataTestId) => <ExecutionTableColumn name={''} dataTestId={dataTestId} />,
    },
  });

  protected createPlanColumnConfig = (): IComparisonTableBuilderColumnConfig => ({
    id: EColumnId.Plan,
    width: '1fr',
    isFixed: true,
    customRenderConfig: {
      render: (_column, dataTestId) => (
        <ExecutionTableColumn name={'План'} dataTestId={dataTestId} />
      ),
    },
  });

  protected createFactColumnConfig = (): IComparisonTableBuilderColumnConfig => ({
    id: EColumnId.Fact,
    width: '1fr',
    isFixed: true,
    customRenderConfig: {
      render: (_column, dataTestId) => (
        <ExecutionTableColumn name={'Факт'} dataTestId={dataTestId} />
      ),
    },
  });

  protected createDeviationsColumnConfig = (): IComparisonTableBuilderColumnConfig => ({
    id: EColumnId.Deviations,
    width: '1fr',
    isFixed: true,
    customRenderConfig: {
      render: (_column, dataTestId) => (
        <ExecutionTableColumn name={'Отклонения'} dataTestId={dataTestId} />
      ),
    },
  });
}

export default ExecutionColumnConfigsService;
