import styled from 'styled-components';
import { Button } from '@farmlink/farmik-ui';

const Wrapper = styled.div`
  margin-top: 24px;
`;

const Footer = styled.div`
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px dashed #e9e6f0;
`;

const AddTechOperationButton = styled(Button)`
  width: 240px;
  height: 40px;

  margin: 0 auto;
  border-radius: 12px;
`;

const StyledExecutionSteps = {
  Wrapper,
  Footer,
  AddTechOperationButton,
};

export default StyledExecutionSteps;
