import { TApiRoute } from '../../models/system/ApiRoute.model';

type TRenewAccessAndRefreshTokenReq = {
  needAdditionalInfo?: boolean;
  'access-token': string;
  'access-token-type'?: string;
  'refresh-token': string;
  'rt-expires-on'?: string;
};

type TRenewAccessAndRefreshTokenRes = {
  needAdditionalInfo: boolean;
  'access-token': string;
  'access-token-type': string;
  'refresh-token': string;
  'rt-expires-on': string;
};

const renewAccessAndRefreshToken: TApiRoute & {
  request: TRenewAccessAndRefreshTokenReq;
  response: TRenewAccessAndRefreshTokenRes;
} = {
  url: `/api/da-profile/users/v2/renew`,
  method: 'POST',
  request: {} as TRenewAccessAndRefreshTokenReq,
  response: {} as TRenewAccessAndRefreshTokenRes,
  headers: {},
};

export type { TRenewAccessAndRefreshTokenReq, TRenewAccessAndRefreshTokenRes };

export { renewAccessAndRefreshToken };
