import { makeAutoObservable } from 'mobx';
import { saveAs } from 'file-saver';

import { lazyInject, provide } from '../../../../../../common/utils/helpers/mobx';
import { AxiosService } from '../../../../../../common/mobx/services/axios/AxiosService';
import { TFileEntities } from '../../../../../../../api/models/as-fields/attachedFiles';
import { IAttachedFile } from '../../../../../../../api/models/as-fields/experiments';

@provide.singleton()
export class DocumentsTableService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  constructor() {
    makeAutoObservable(this);
  }

  getAttachedFiles = async (id: string, entity: TFileEntities): Promise<IAttachedFile[]> => {
    const response = await this.axiosService.api.getAttachedFilesByEntity(
      { id, entity },
      { omit: ['entity'] }
    );

    return response.attachedFiles || [];
  };

  downloadFile = async (file: IAttachedFile) => {
    try {
      const response = await this.axiosService.api.downloadFile(
        { objectId: file.id },
        { responseType: 'blob' }
      );

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, file.fileName);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  updateAttachedFiles = async (
    id: string,
    entity: TFileEntities,
    attachedFiles: string[] | string[]
  ): Promise<IAttachedFile[]> => {
    const response = await this.axiosService.api.updateAttachedFilesByEntity(
      {
        id,
        entity,
        attachedFiles,
      },
      { omit: ['entity'] }
    );

    return response.attachedFiles;
  };
}
