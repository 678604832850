import { FC, memo, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Message, NoMessagePlaceholder, Notification } from '../../components';
import { EMessageType, IChatMessage } from '../../models';
import { parseDateToChatFormat } from '../../../../utils/helpers/dates';

import Styled from './MessageList.styles';

interface IMessageListProps {
  messageList: IChatMessage[];
}

const MessageList: FC<IMessageListProps> = ({ messageList }) => {
  const noMessages = useMemo(() => messageList?.length < 1, [messageList]);
  const ref = useRef<HTMLDivElement>(null);

  const params = useParams();

  const noMessagesTitleText = useMemo(() => {
    if (params?.experimentId) {
      return 'Если вы закончили с расчётами, отправьте их на согласование';
    } else if (params?.auditId) {
      return 'Если отчёт по наблюдению готов, отправьте его на согласование';
    } else {
      return 'Если вы закончили с расчётами, отправьте их на согласование';
    }
  }, [params?.experimentId, params?.auditId]);

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollTo({
        top: ref?.current?.scrollHeight || ref.current.clientHeight,
        behavior: 'smooth',
      });
    }
  }, [messageList]);

  return (
    <Styled.MessageFadeWrapper>
      <Styled.MessageListContainer data-test-id="chat-message-container" ref={ref}>
        {noMessages ? (
          <NoMessagePlaceholder
            title={noMessagesTitleText}
            subtitle="Отслеживайте комментарии и замечания в чате"
          />
        ) : (
          messageList.map((message, i) =>
            message.messageType === EMessageType.Message ? (
              <Message
                author={message.author}
                date={parseDateToChatFormat(message.sendingDate).toLowerCase()}
                message={message.message}
                style={message.messageStyle}
                type={message.messageType}
                isAuthor={message.isAuthor}
                key={String(message.sendingDate.getTime() + i)}
              />
            ) : (
              <Notification
                author={message.author}
                date={parseDateToChatFormat(message.sendingDate).toLowerCase()}
                message={message.message}
                style={message.messageStyle}
                type={message.messageType}
                isAuthor={message.isAuthor}
                key={String(message.sendingDate.getTime() + i)}
              />
            )
          )
        )}
        {}
      </Styled.MessageListContainer>
    </Styled.MessageFadeWrapper>
  );
};

MessageList.displayName = 'MessageList';

export default memo(MessageList);
