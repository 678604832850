/* eslint-disable no-console */
import { TGetFileUploadURLReq, TUploadFileReq } from '../../../../../api';
import { lazyInject, provide } from '../../../utils/helpers/mobx';
import { AxiosService } from '../axios/AxiosService';

import { EFileUploadStatus } from './fileUpload.service.types';

@provide.transient()
class FileUploadService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  private _uploadStatus: EFileUploadStatus = EFileUploadStatus.Idle;

  get uploadState() {
    return this._uploadStatus;
  }

  setUploadState = (status: EFileUploadStatus) => {
    this._uploadStatus = status;
  };

  getFileUploadURL = async (payload: TGetFileUploadURLReq) => {
    this.setUploadState(EFileUploadStatus.FetchingUploadUrl);
    try {
      const fileUploadData = await this.axiosService.api.getFileUploadURL(payload);
      this.setUploadState(EFileUploadStatus.SuccessFetchUploadUrl);

      return fileUploadData;
    } catch (error) {
      console.log(error);
      this.setUploadState(EFileUploadStatus.RejectFetchingUploadUrl);
    }
  };

  uploadFileByUrl = async (payload: TUploadFileReq) => {
    this.setUploadState(EFileUploadStatus.UploadingFile);
    try {
      const fileUploadData = await this.axiosService.api.uploadFile(payload);
      this.setUploadState(EFileUploadStatus.SuccessFileUpload);

      return fileUploadData;
    } catch (error) {
      console.log(error);
      this.setUploadState(EFileUploadStatus.RejectFetchingUploadUrl);
    } finally {
      this.setUploadState(EFileUploadStatus.Idle);
    }
  };

  uploadFile = async (file: File, ownerId?: string) => {
    const { name, size, type } = file;

    const { uploadUrl, id } = await this.getFileUploadURL({
      fileContentType: type,
      fileName: name || 'file',
      fileSize: size,
      serviceName: 'da-experiment',
      ownerId,
    });

    await this.uploadFileByUrl({ data: file, url: uploadUrl });

    return {
      name,
      size,
      type,
      id,
    };
  };
}

export default FileUploadService;
