import { FC, memo } from 'react';

import { IAttachedFile } from '../../../../../../api/models/as-fields/experiments';

import Styled from './DocumentsActions.styles';
import { DocumentsActionIconContainer } from './containers';

interface IActionCellComponent {
  row: IAttachedFile;
  onClickDownloadFile: (file: IAttachedFile) => void;
  onClickDeleteFile: (fileId: string, fileName: string) => void;
  isShowDeleteButton: boolean;
}

const DocumentsActions: FC<IActionCellComponent> = ({
  row,
  onClickDownloadFile,
  onClickDeleteFile,
  isShowDeleteButton,
}) => {
  const handleDownloadClick = () => {
    onClickDownloadFile(row);
  };

  const handleDeleteClick = () => {
    onClickDeleteFile(row.id, row.fileName);
  };

  return (
    <Styled.Wrapper>
      <Styled.ActionButton onClick={handleDownloadClick}>
        <DocumentsActionIconContainer type={'download'} />
      </Styled.ActionButton>
      {isShowDeleteButton ? (
        <Styled.ActionButton onClick={handleDeleteClick}>
          <DocumentsActionIconContainer type={'delete'} />
        </Styled.ActionButton>
      ) : null}
    </Styled.Wrapper>
  );
};

DocumentsActions.displayName = 'DocumentsActions';

export default memo(DocumentsActions);
