const formatNumWithLongFractPart = (value: string | number): number => {
  const isValidValue = value || value === 0;

  if (!isValidValue) {
    return;
  }

  if (isNaN(Number(value))) {
    return NaN;
  }

  const [firstPart] = String(value).split('e');

  return Number(firstPart);
};

export default formatNumWithLongFractPart;
