import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IOrganization } from '../../../models/da-profile/organizations/Organization.model';

type TGetOrganizationReq = {
  organizationId: string;
};

type TGetOrganizationRes = IOrganization;

const getOrganization: TApiRoute & {
  request: TGetOrganizationReq;
  response: TGetOrganizationRes;
} = {
  url: ({ organizationId }) => `/api/da-profile/organizations/${organizationId}`,
  method: 'GET',
  request: {} as TGetOrganizationReq,
  response: {} as TGetOrganizationRes,
  headers: {},
};

export type { TGetOrganizationReq, TGetOrganizationRes };

export { getOrganization };
