import { TableBuilderStore as BuilderStore } from '../../stores';
import { ITableBuilderColumnConfig as IColumnConfig } from '../../../models/configs';
import {
  ITableBuilderColumn as IColumn,
  ITableBuilderColumnsWidth as IColumnsWidth,
} from '../../../models/data';
import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { sortBy } from '../../../../../utils/helpers/sort';

@provide.transient()
class TableBuilderColumnsService {
  @lazyInject(BuilderStore)
  protected store: BuilderStore;

  addColumnList = (builderId: string, configList: IColumnConfig[]): void => {
    const columnList = this.createColumnList(builderId, configList);

    this.store.setColumnList(builderId, columnList, { isClearPreviousList: true });
  };

  createColumnList = (builderId: string, configList: IColumnConfig[]): IColumn[] => {
    const columnList = configList.map((config, i) => this.createColumn(builderId, config, i + 1));

    return columnList;
  };

  createColumn = (builderId: string, config: IColumnConfig, order: number): IColumn => {
    const column: IColumn = {
      ...config,
      builderId,
      order,
      renderType: null,
    };

    if (config?.customRenderConfig) {
      column.renderType = 'custom-render';
    }

    return column;
  };

  getColumnsWidth = (builderId: string): IColumnsWidth => {
    const columnList = this.store.getColumnList(builderId);
    const orderedList = sortBy(columnList);

    const tempColumnsWidthList = orderedList.reduce(
      (acc, { width }) => {
        acc.s1920.push(width?.s1920 ?? width.default);
        acc.s1366.push(width?.s1366 ?? width.default);
        acc.s1024.push(width?.s1024 ?? width.default);

        return acc;
      },
      {
        s1920: [],
        s1366: [],
        s1024: [],
      }
    );

    return {
      s1920: tempColumnsWidthList.s1920.join(' '),
      s1366: tempColumnsWidthList.s1366.join(' '),
      s1024: tempColumnsWidthList.s1024.join(' '),
    };
  };
}

export default TableBuilderColumnsService;
