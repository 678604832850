import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';
import { EComparisonTableName } from '../../../../../../../../constants/features';
import { createComparisonTableBuilderRowClassName as createRowClassName } from '../../../../../../../../../common/features/ComparisonTableBuilder/helpers';

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  width: 100%;
`;

const Body = styled.div<{ $isWithoutMargin?: boolean }>`
  ${({ $isWithoutMargin }) =>
    !$isWithoutMargin &&
    css`
      margin-top: 16px;
    `}};

  & .${createRowClassName(EComparisonTableName.TotalComparison)} {
    padding: 0 12px;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid ${ECommonColor.BorderGray};
  padding-bottom: 10px;
`;

const HeaderWrapper = styled.div`
  padding: 0 12px;
`;

const Wrapper = styled.section`
  border-radius: 16px;

  padding: 24px;

  background-color: ${ECommonColor.White};
`;

const StyledTotalComparisonContainer = {
  Title,
  Body,
  Header,
  HeaderWrapper,
  Wrapper,
};

export default StyledTotalComparisonContainer;
