import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { PageAndFieldInfo } from '../../../../../../../../../../../common/components/modal/modalDescriptions/components';
import { CopyFieldForm } from '../../components/CopyFieldForm';
import { InventoryValuesForm } from '../../components/InventoryValuesForm';
import { OperationForm } from '../../components/OperationForm';

const techOperationModalList: TModalConfig[] = [
  {
    id: 'addNutritionHistoryTechOperation',
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 6px 0',
      },
    },
    styledProps: {
      $height: 'none',
      $size: '600px',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    type: EModalType.Custom,
    children: () => <OperationForm formType={'add'} />,
    name: 'addTechOperation',
    title: 'Добавить техоперацию',
    description: {
      value: () => <PageAndFieldInfo />,
    },
    closeOnClickOutside: false,
  },
  {
    id: `addNutritionHistoryInventoryValues`,
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 6px 0',
      },
    },
    styledProps: {
      $height: 'none',
      $size: '600px',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    type: EModalType.Custom,
    children: () => <InventoryValuesForm formType={'add'} />,
    name: 'addInventoryValues',
    title: 'Добавить ТМЦ',
    description: {
      value: () => <PageAndFieldInfo />,
    },
    closeOnClickOutside: false,
  },
  {
    id: `editNutritionHistoryTechOperation`,
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 6px 0',
      },
    },
    styledProps: {
      $height: 'none',
      $size: '600px',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    type: EModalType.Custom,
    children: () => <OperationForm formType={'edit'} />,
    name: 'editTechOperation',
    title: 'Изменить техоперацию',
    description: {
      value: () => <PageAndFieldInfo />,
    },
    closeOnClickOutside: false,
  },
  {
    id: `editNutritionHistoryFertilizerInventoryValues`,
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 6px 0',
      },
    },
    styledProps: {
      $height: 'none',
      $size: '600px',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    type: EModalType.Custom,
    children: () => <InventoryValuesForm formType={'edit'} inventoryValueType={'fertilizer'} />,
    name: 'editInventoryValues',
    title: 'Изменить ТМЦ',
    description: {
      value: () => <PageAndFieldInfo />,
    },
    closeOnClickOutside: false,
  },
  {
    id: `editNutritionHistoryProtectedInventoryValues`,
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 6px 0',
      },
    },
    styledProps: {
      $height: 'none',
      $size: '600px',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    type: EModalType.Custom,
    children: () => <InventoryValuesForm formType={'edit'} inventoryValueType={'protection'} />,
    name: 'editInventoryValues',
    title: 'Изменить ТМЦ',
    description: {
      value: () => <PageAndFieldInfo />,
    },
    closeOnClickOutside: false,
  },
  {
    name: 'copyFieldNutritionHistory',
    type: EModalType.Custom,
    title: '',
    id: 'copyFieldNutritionHistory',
    children: CopyFieldForm,
    dataTestId: 'nutrition-history-copy-field-modal',
  },
];

export default techOperationModalList;
