import isNumWithLongFractPart from '../isNumWithLongFractPart/isNumWithLongFractPart';
import formatNumWithLongFractPart from '../formatNumWithLongFractPart/formatNumWithLongFractPart';

const formatNumValue = (value: string | number): number => {
  if (isNaN(Number(value)) || Math.abs(Number(value)) < 0.001) {
    return 0;
  }

  if (isNumWithLongFractPart(value)) {
    return Number(formatNumWithLongFractPart(value).toFixed(2));
  }

  return Number(Number(value).toFixed(2));
};

export default formatNumValue;
