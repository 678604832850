import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { useStore } from '../../../../../common/utils/helpers/mobx';
import { TableBuilder } from '../../../../../common/features/TableBuilder';
import { EAuditsTableBuilderId } from '../../constants/configs';
import { TableBuilderController } from '../../../../../common/features/TableBuilder/mobx/controllers';
import { generateAuditsPath } from '../../utils/helpers';

import { AuditsListController } from './mobx/controllers';
import { AuditsListDefaultPlug as DefaultPlug, AuditsListNoDataPlug as NoDataPlug } from './plugs';

const AuditsList: FC = () => {
  const auditsListController = useStore(AuditsListController);
  const tableBuilderController = useStore(TableBuilderController);

  const navigate = useNavigate();

  const handleRowClick = rowDataInfo => {
    if (rowDataInfo?.id) {
      navigate(
        generateAuditsPath('auditInfo', {
          auditId: rowDataInfo?.id,
        })
      );
    }
  };

  useEffect(() => {
    auditsListController.initiateTable();

    tableBuilderController.addRowClickEvent(EAuditsTableBuilderId.Audits, handleRowClick);
    tableBuilderController.showDefaultPlug(EAuditsTableBuilderId.Audits);
    tableBuilderController.showLoader(EAuditsTableBuilderId.Audits);
  }, []);

  return (
    <TableBuilder
      builderId={EAuditsTableBuilderId.Audits}
      renderDefaultPlug={() => <DefaultPlug />}
      renderNoDataPlug={() => <NoDataPlug />}
    />
  );
};

AuditsList.displayName = 'AuditsList';

export default observer(AuditsList);
