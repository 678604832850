import { IExperimentStep } from '../../../../models/as-fields/experiments';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TUpdateExperimentStepFilesReq = { experimentStepId: string; attachedFiles: string[] };

type TUpdateExperimentStepFilesRes = IExperimentStep;

const updateExperimentStepFiles: TApiRoute & {
  request: TUpdateExperimentStepFilesReq;
  response: TUpdateExperimentStepFilesRes;
} = {
  url: ({ experimentStepId }) => `/api/as-fields/experimentSteps/${experimentStepId}/attachedFiles`,
  method: 'PUT',
  request: {} as TUpdateExperimentStepFilesReq,
  response: {} as TUpdateExperimentStepFilesRes,
  headers: {},
};

export type { TUpdateExperimentStepFilesRes, TUpdateExperimentStepFilesReq };

export { updateExperimentStepFiles };
