import { FC, useCallback, useEffect } from 'react';
import { ButtonLink, TButtonLinkOnClick, useDataTestId, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../../../mobx/stores';
import useExperimentsParams from '../../../../../../hooks/useExperimentsParams/useExperimentsParams';
import { ExecutionController } from '../../mobx/controllers';
import { IInventoryValuesFormModalPayload } from '../../../calculation/containers/Calculation/components/InventoryValuesForm/InventoryValuesForm';
import { checkEditableExperimentStatuses } from '../../helpers';
import { ExecutionStore } from '../../mobx/stores';

import Styled from './ExecutionTableAddFertilizerRow.styles';

interface IProps {
  rootRowId: string;
  stepId: string;
  initialFertilizerIdList: string[];
  initialProtectionIdList: string[];
  dataTestId: string;
}

const ExecutionTableAddFertilizerRow: FC<IProps> = ({
  rootRowId,
  stepId,
  initialFertilizerIdList,
  initialProtectionIdList,
  dataTestId,
}) => {
  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);
  const createExperimentStore = useStore(CreateExperimentStore);

  const modalActions = useModal();
  const params = useExperimentsParams();

  const currentFertilizerIdList = executionStore.getFertilizerIdList(stepId);
  const currentProtectionIdList = executionStore.getProtectionIdList(stepId);

  useEffect(() => {
    executionStore.setFertilizerIdList(stepId, initialFertilizerIdList);
  }, [initialFertilizerIdList]);

  useEffect(() => {
    executionStore.setProtectionIdList(stepId, initialProtectionIdList);
  }, [initialProtectionIdList]);

  const handleButtonClick = useCallback<TButtonLinkOnClick>(
    event => {
      event.stopPropagation();

      const defaultMeasureKG = 'f357cb40-6d3a-11eb-8d42-dd19d7aaf478';

      const payload: IInventoryValuesFormModalPayload = {
        experimentId: params.experimentId,
        currentFertilizerIdList,
        currentProtectionIdList,
        onCreate: (form, selectedItem, inventoryValueMeasureId) => {
          if (form.typeValue === 'fertilizer') {
            const createdItemByFertilizerId = executionController.createNutritionHistoryItem(
              form,
              stepId,
              rootRowId,
              selectedItem,
              defaultMeasureKG
            );

            return createdItemByFertilizerId;
          } else if (form.typeValue === 'protection') {
            const createdItemByProtectionId =
              executionController.createNutritionHistoryProtectionItem(
                form,
                stepId,
                rootRowId,
                selectedItem,
                inventoryValueMeasureId
              );

            return createdItemByProtectionId;
          }
        },
        isExecutionPage: true,
      };

      modalActions.openModalByModalId(`addPlanInventoryValues`, payload);
    },
    [currentFertilizerIdList]
  );

  const getDataTestId = useDataTestId(dataTestId);

  if (!checkEditableExperimentStatuses(createExperimentStore?.selectedExp?.status)) {
    return null;
  }

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <ButtonLink
        onClick={handleButtonClick}
        color={'accent'}
        // Временно отключили возможность добавления ТМЦ для новой операции.
        isDisabled={stepId.includes('new_item')}
        dataTestId={getDataTestId('add-fertilizer-button')['data-test-id']}
      >
        Добавить ТМЦ
      </ButtonLink>
    </Styled.Wrapper>
  );
};

ExecutionTableAddFertilizerRow.displayName = 'ExecutionTableAddFertilizerRow';

export default observer(ExecutionTableAddFertilizerRow);
