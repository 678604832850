import styled from 'styled-components';

const Column = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  min-width: 48px;

  color: #7e8b9d;
`;

const StyledActiveSubstancesColumn = {
  Column,
};

export default StyledActiveSubstancesColumn;
