import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../common/utils/helpers/mobx';
import { TableBuilderController } from '../../../../../../common/features/TableBuilder/mobx/controllers';
import { TFileEntities } from '../../../../../../../api/models/as-fields/attachedFiles';
import { IAttachedFile } from '../../../../../../../api/models/as-fields/experiments';
import { DocumentsTableConfigService, DocumentsTableService } from '../../services';
import { DocumentsTableStore } from '../../stores';

@provide.singleton()
export class DocumentsTableController {
  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(DocumentsTableConfigService)
  protected documentsConfigService: DocumentsTableConfigService;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  @lazyInject(DocumentsTableService)
  protected documentsTableService: DocumentsTableService;

  @lazyInject(DocumentsTableStore)
  protected documentsTableStore: DocumentsTableStore;

  initiateTable = (onClickDownloadFile, onClickDeleteFile, isShowDeleteButton) => {
    const config = this.documentsConfigService.createConfig(
      onClickDownloadFile,
      onClickDeleteFile,
      isShowDeleteButton
    );

    this.tableBuilderController.initiateTable(config);
  };

  fetchAttachedFiles = async (id: string, entity: TFileEntities) => {
    const { showLoader, hideLoader } = this.tableBuilderController;
    const { getAttachedFiles } = this.documentsTableService;
    const { setAttachedFiles } = this.documentsTableStore;

    showLoader('documents');

    const response = await getAttachedFiles(id, entity);

    setAttachedFiles(response);

    hideLoader('documents');
  };

  addAttachedFile = async (id: string, fileId: string, entity: TFileEntities) => {
    const filesToUpdate = [...this.documentsTableStore.attachedFiles.map(file => file.id), fileId];

    const response = await this.documentsTableService.updateAttachedFiles(
      id,
      entity,
      filesToUpdate
    );

    this.documentsTableStore.setAttachedFiles(response);
  };

  deleteAttachedFile = async (id: string, fileId: string, entity: TFileEntities) => {
    const filesToUpdate = this.documentsTableStore.attachedFiles
      .map(file => file.id)
      .filter(file => file !== fileId);

    const response = await this.documentsTableService.updateAttachedFiles(
      id,
      entity,
      filesToUpdate
    );

    this.documentsTableStore.setAttachedFiles(response);
  };

  downloadAttachedFile = (file: IAttachedFile) => {
    this.documentsTableService.downloadFile(file);
  };
}
