import styled from 'styled-components';

import { Container } from '../../../../../../../common/features/UI';

import { Colors } from './../../../../../../../common/styles/colors';

const { white } = Colors;

const LoaderWrapper = styled.div`
  height: 128px;
`;

const ContentWrapper = styled.div`
  background-color: ${white};
  border-radius: 16px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SectionContainer = styled(Container)`
  margin-top: 24px;
`;

const StyledRemarks = {
  LoaderWrapper,
  ContentWrapper,
  Wrapper,
  SectionContainer,
};

export default StyledRemarks;
