import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CalendarComponent } from '@farmlink/farmik-ui';
import moment from 'moment';

import { useDataTestIdV2 } from '../../../../../../../../../../common/utils/hooks/locators';
import { ExecutionStore } from '../../../mobx/stores';
import { ExecutionController } from '../../../mobx/controllers';
import { getExecutionTableAttributeIdByRowId as getAttributeIdByRowId } from '../../../helpers';
import { useStore } from '../../../../../../../../../../common/utils/helpers/mobx';
import { TExecutionStepsCell } from '../../../containers/ExecutionSteps/types';

import Styled from './ExecutionTableDateCell.styles';

interface IProps {
  cell: TExecutionStepsCell;
  dataTestId: string;
}

const ExecutionTableDateCell: FC<IProps> = ({ cell, dataTestId }) => {
  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);

  const attributeId = useMemo(() => getAttributeIdByRowId(cell.rowId), []);

  const initialValue = cell.initialModel.dateValue;

  const error = executionController.getStepsPlanEndDateError(cell.builderId, cell.rowId);

  useEffect(() => {
    if (error) {
      executionController.addInvalidRowId(cell.rowId);
    } else {
      executionController.removeInvalidRowId(cell.rowId);
    }
  }, [error]);

  const updatedValue = executionStore.getStepForUpdate(
    executionStore.selectedZoneId,
    cell.initialModel.stepId
  )?.[attributeId] as string;

  const value = updatedValue !== undefined ? updatedValue : initialValue;

  const handleChange = useCallback((dateValue: Date): void => {
    executionController.changeStep(cell.initialModel.stepId, {
      [attributeId]: dateValue ? moment(dateValue).format('YYYY-MM-DD') : null,
    });
  }, []);

  const getDataTestId = useDataTestIdV2(`${dataTestId}-date-cell`);

  return (
    <Styled.Wrapper {...getDataTestId()} id={cell.rowId}>
      <CalendarComponent
        placeholder={'дд.мм.гггг'}
        defaultValue={value ? moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''}
        onChange={handleChange}
        error={error}
        isInline={true}
        rootPropList={{ isClearable: true }}
      />
    </Styled.Wrapper>
  );
};

ExecutionTableDateCell.displayName = 'ExecutionTableDateCell';

export default observer(ExecutionTableDateCell);
