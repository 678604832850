import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { EExperimentsWizardProgress } from '../../../../models/as-fields/experiments';

type SendExperimentWizardProgressReq = {
  id: string;
  wizardProgress: EExperimentsWizardProgress;
};

type SendExperimentWizardProgressRes = {};

const sendExperimentWizardProgress: TApiRoute & {
  request: SendExperimentWizardProgressReq;
  response: SendExperimentWizardProgressRes;
} = {
  url: ({ id }) => `/api/as-fields/experiment/${id}/wizardProgress`,
  method: 'POST',
  request: {} as SendExperimentWizardProgressReq,
  response: {} as SendExperimentWizardProgressRes,
  headers: {},
};

export type { SendExperimentWizardProgressReq };

export { sendExperimentWizardProgress };
