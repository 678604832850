import { FC, memo } from 'react';
import { observer } from 'mobx-react';

import { CollapsingStage } from '../../components';
import { useStore } from '../../../../utils/helpers/mobx';
import { DynamicTableStore } from '../../mobx/stores';
import { DynamicTableController } from '../../mobx/controllers';

interface ICollapsingStageContainerProps {
  tableId: string;
  id: string;
  cellPadding: string;
}

const CollapsingStageContainer: FC<ICollapsingStageContainerProps> = ({
  tableId,
  id,
  cellPadding,
}) => {
  const { getCollapsingStage } = useStore(DynamicTableStore);
  const { onToggleCollapsingStage } = useStore(DynamicTableController);

  const collapsingStage = getCollapsingStage(tableId, id);

  return (
    <>
      {collapsingStage ? (
        <CollapsingStage
          collapsingStage={collapsingStage}
          onToggleCollapsingStage={onToggleCollapsingStage}
          cellPadding={cellPadding}
        />
      ) : null}
    </>
  );
};

CollapsingStageContainer.displayName = 'CollapsingStageContainer';

export default memo(observer(CollapsingStageContainer));
