import { generatePath } from 'react-router-dom';

import { TAuditsParams } from '../../../models/params/AuditsParams.model';
import { EAuditsRoute } from '../../../routes';
import { EDashboardRoute } from '../../../../../routes/DashboardRoute';
import { EAuditRoute } from '../../../modules/Audit/routes';

const auditsPath = '/audits';
const createAuditPath = `/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${EAuditRoute.CreateAudit}`;
const auditInfoPath = `/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${EAuditRoute.Info}`;
const editAuditPath = `/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${EAuditRoute.EditAudit}`;
const taskReportPath = `/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${EAuditRoute.TaskReport}`;

const path = {
  audits: auditsPath,
  createAudit: createAuditPath,
  editAudit: editAuditPath,
  auditInfo: auditInfoPath,
  taskReport: taskReportPath,
} as const;

type TShortPaths = keyof typeof path;
type TPaths = typeof path[TShortPaths];

type TGetPath = (shortPath: TShortPaths) => TPaths;
type TGenerateAuditsPath = (shortPath: TShortPaths, params?: Partial<TAuditsParams>) => string;

/**
 * @param shortPath - перечень путей на выбор.
 */
const getPath: TGetPath = shortPath => {
  switch (shortPath) {
    case 'audits':
      return auditsPath;
    case 'createAudit':
      return createAuditPath;
    case 'editAudit':
      return editAuditPath;
    case 'auditInfo':
      return auditInfoPath;
    case 'taskReport':
      return taskReportPath;
    default:
      return auditsPath;
  }
};

/**
 * @param shortPath - перечень путей на выбор;
 * @param params - параметры формата { ключ: значение }.
 */
const generateAuditsPath: TGenerateAuditsPath = (shortPath, params) => {
  return generatePath(getPath(shortPath), params);
};

export default generateAuditsPath;
