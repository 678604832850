import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import {
  TGenerateTaskReportDataReq,
  TGenerateTaskReportDataRes,
  TGetTaskListReq,
  TGetTaskListRes,
  TGetTaskReq,
} from '../../../../../../api';
import { ITask } from '../../../../../../api/models/as-fields/task/Task';

@provide.singleton()
class TasksService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getTask = async (payload: TGetTaskReq): Promise<ITask> => {
    const { api } = this.axiosService;

    try {
      const fetchedTask = await api.getTask(payload, { omit: ['id'] });

      return fetchedTask;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getTaskList = async (payload: TGetTaskListReq): Promise<TGetTaskListRes> => {
    const { api } = this.axiosService;

    try {
      const response = await api.getTaskList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getTaskReportData = async (
    payload: TGenerateTaskReportDataReq
  ): Promise<TGenerateTaskReportDataRes> => {
    const { generateTaskReportData } = this.axiosService.api;

    try {
      const response = await generateTaskReportData(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default TasksService;
