import { IExperiment } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { AxiosService } from '../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { GlobalService } from '../../../../../../../../../../../common/mobx/services/system';
import { TableBuilderController } from '../../../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { EContractsTableBuilderId } from '../../../../../../../../constants/configs';
import { ExperimentsTableStore } from '../stores';
import { ExperimentsTableConfigService } from '../services';
import { TableBuilderStore } from '../../../../../../../../../../../common/features/TableBuilder/mobx/stores';

@provide.singleton()
class ContractSidePanelService {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  @lazyInject(GlobalService)
  globalService: GlobalService;

  @lazyInject(TableBuilderController)
  protected tableBuilderExperimentsController: TableBuilderController;

  @lazyInject(TableBuilderStore)
  protected tableBuilderStore: TableBuilderStore;

  @lazyInject(ExperimentsTableConfigService)
  experimentsTableConfigService: ExperimentsTableConfigService;

  @lazyInject(ExperimentsTableStore)
  experimentTableStore: ExperimentsTableStore;

  initiateTable = (organizationId: string, contractId: string) => {
    const config = this.experimentsTableConfigService.createConfig();

    this.tableBuilderExperimentsController.initiateTable(config);

    this.tableBuilderExperimentsController.addPaginationScrollEvent(
      EContractsTableBuilderId.Experiments,
      () => {
        return this.getOrganizationExperimentList(organizationId, contractId, true);
      }
    );
  };

  getOrganizationExperimentList = async (
    organizationId: string,
    contractId: string,
    isSavePagination: boolean
  ): Promise<void> => {
    const { getOrganizationExperimentsList } = this.axiosService.api;
    const { showLoader, hideLoader, addCurrentPage, addTotalPages, addRowsToRowsGroupListById } =
      this.tableBuilderExperimentsController;
    const { getCurrentPage } = this.tableBuilderStore;

    if (!isSavePagination) {
      addCurrentPage(EContractsTableBuilderId.Experiments, 0);
      addTotalPages(EContractsTableBuilderId.Experiments, 0);
    }

    const currentPage = getCurrentPage(EContractsTableBuilderId.Contracts);

    showLoader(EContractsTableBuilderId.Experiments);

    const { content, totalPages } = await getOrganizationExperimentsList({
      page: isSavePagination ? currentPage : 0,
      size: 25,
      organizationId,
      contractId,
    });

    addTotalPages(EContractsTableBuilderId.Experiments, totalPages);
    addRowsToRowsGroupListById(
      EContractsTableBuilderId.Experiments,
      EContractsTableBuilderId.Experiments,
      content,
      {
        isClearPreviousList: !isSavePagination,
      }
    );

    hideLoader(EContractsTableBuilderId.Experiments);
  };
}

export default ContractSidePanelService;
