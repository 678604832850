import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TDeleteNutritionHistoryReg = {} & { nutritionHistoryId: string };
type TDeleteNutritionHistoryRes = {} & { id: string };

const deleteNutritionHistory: TApiRoute & {
  request: TDeleteNutritionHistoryReg;
  response: TDeleteNutritionHistoryRes;
} = {
  url: ({ nutritionHistoryId }) => `/api/as-fields/nutrition-history/${nutritionHistoryId}`,
  method: 'DELETE',
  request: {} as TDeleteNutritionHistoryReg,
  response: {} as TDeleteNutritionHistoryRes,
  headers: {},
};

export { deleteNutritionHistory };
export type { TDeleteNutritionHistoryReg, TDeleteNutritionHistoryRes };
