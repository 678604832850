import { lazyInject, provide } from '../../../utils/helpers/mobx';
import { SessionStore } from '../../stores';
import { SessionService } from '../../services/session';

@provide.singleton()
class SessionController {
  @lazyInject(SessionStore)
  protected sessionStore: SessionStore;

  @lazyInject(SessionService)
  protected sessionService: SessionService;

  checkIsLoggedIn = async (authKey?: string): Promise<void> => {
    const { setIsLoading } = this.sessionStore;
    const { getExternalRequestsToken } = this.sessionService;
    setIsLoading(true);

    if (authKey) {
      this.sessionStore.setIsNotFirstLoading(true);
      const authByKeyResponse = await getExternalRequestsToken({
        code: authKey,
      });

      if (!authByKeyResponse) {
        return this.checkIsLoggedInError();
      }

      localStorage.setItem('accessToken', authByKeyResponse['access-token']);
      localStorage.setItem('refreshToken', authByKeyResponse['refresh-token']);
    }

    const accessToken = localStorage.getItem('loginAs') || localStorage.getItem('accessToken');

    if (!accessToken) {
      return this.checkIsLoggedInError();
    }

    await this.verifyAccessToken();
  };

  verifyAccessToken = async (): Promise<void> => {
    const { setIsLoading } = this.sessionStore;
    const { verifyAccessToken } = this.sessionService;

    const isSuccess = await verifyAccessToken();

    if (isSuccess) {
      this.checkPass();
    }

    setIsLoading(false);
  };

  checkPass = (): void => {
    const { setIsLoggedIn, setIsLoading, setSelectedOrganizationId } = this.sessionStore;

    setSelectedOrganizationId('my');

    setIsLoggedIn(true);
    setIsLoading(true);
  };

  checkIsLoggedInError = (): void => {
    const { setIsLoggedIn, setIsLoading } = this.sessionStore;

    setIsLoggedIn(false);
    setIsLoading(false);

    localStorage.removeItem('accessToken');
    localStorage.removeItem('externalKey');
    localStorage.removeItem('externalEmail');
  };

  logout = () => {
    const { setIsLoading, setIsLoggedIn, setIsLogout } = this.sessionStore;

    setIsLogout(true);
    setIsLoading(false);
    setIsLoggedIn(false);

    localStorage.removeItem('accessToken');
    localStorage.removeItem('externalKey');
    localStorage.removeItem('externalEmail');
  };
}

export default SessionController;
