import { CSSProperties, FC, memo, useCallback, useMemo, useState } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { IFile } from '../../../../../api/models/common';
import { useDataTestId } from '../../../utils/hooks/locators';
import { useShowTooltip } from '../../../utils/hooks';
import ImagesCarousel from '../ImagesCarousel/ImagesCarousel';
import { LeftArrowButton, PinButton, RightArrowButton } from '../buttons';
import { IUseImagesCarouselConfig } from '../ImagesCarousel/utils/hooks/useImagesCarousel/useImagesCarousel';
import { useImagesCarousel } from '../ImagesCarousel/utils/hooks';

import Styled from './ImagesSelectionCard.styles';

const DEFAULT_DATA_TEST_ID = 'images-selection-card';

export interface IImagesSelectionCardProps {
  imageList: IFile[];
  isEnableEditing: boolean;
  name?: string;
  selectedImage?: IFile;
  pinnedImage?: IFile;
  dataTestId?: string;
  cardWidth?: CSSProperties['width'];
  mainHeight?: CSSProperties['height'];
  onPinImage?: IUseImagesCarouselConfig['onPinImage'];
}

const ImagesSelectionCard: FC<IImagesSelectionCardProps> = ({
  imageList,
  name,
  selectedImage,
  pinnedImage,
  dataTestId,
  cardWidth,
  mainHeight,
  isEnableEditing,
  onPinImage,
}) => {
  const getDataTestId = useDataTestId(dataTestId || DEFAULT_DATA_TEST_ID);

  const [isMouseOnContent, setIsMouseOnContent] = useState(false);
  const [isShowCarousel, setIsShowCarousel] = useState(false);

  const {
    _selectedImage,
    _pinnedImage,
    visibilityOfArrowButtons,
    handlePinButtonClick,
    handleLeftArrowClick,
    handleRightArrowClick,
  } = useImagesCarousel({ imageList, selectedImage, pinnedImage, onPinImage });

  const { ref: nameRef, showTooltip: isShowNameTooltip } = useShowTooltip<HTMLDivElement>(name);

  const { ref: imageNameRef, showTooltip: isShowImageNameTooltip } = useShowTooltip<HTMLDivElement>(
    _selectedImage?.fileName
  );

  const imagesCount = useMemo<string>(() => {
    if (!imageList || !_selectedImage) {
      return '';
    }

    const selectedImagePosition = imageList.indexOf(_selectedImage);
    const totalImagesNumber = imageList.length;

    return `${selectedImagePosition + 1} из ${totalImagesNumber}`;
  }, [imageList, _selectedImage]);

  const closeCarousel = useCallback(() => {
    setIsShowCarousel(false);
  }, []);

  const handleContentClick = useCallback(() => {
    setIsShowCarousel(true);
  }, []);

  const handleContentMouseOver = useCallback(() => {
    setIsMouseOnContent(true);
  }, []);

  const handleContentMouseLeave = useCallback(() => {
    setIsMouseOnContent(false);
  }, []);

  return (
    <>
      <Styled.Wrapper {...getDataTestId('wrapper')} $cardWidth={cardWidth} $mainHeight={mainHeight}>
        <Styled.Header {...getDataTestId('header')}>
          <AutoTooltip content={name} disabled={!isShowNameTooltip} position="top">
            <Styled.Name ref={nameRef} {...getDataTestId('name')}>
              {name}
            </Styled.Name>
          </AutoTooltip>

          {_selectedImage?.downloadUrl ? (
            <Styled.PinButtonWrapper $isEnableEditing={isEnableEditing}>
              <PinButton
                onClick={handlePinButtonClick}
                size={24}
                isSelected={_pinnedImage?.id === _selectedImage?.id}
                isEnableEditing={isEnableEditing}
              />
            </Styled.PinButtonWrapper>
          ) : null}
        </Styled.Header>

        {_selectedImage?.downloadUrl ? (
          <Styled.Content
            onMouseOver={handleContentMouseOver}
            onMouseLeave={handleContentMouseLeave}
            onClick={handleContentClick}
            {...getDataTestId('content-with-image')}
            $hasData
          >
            <Styled.LeftArrowButtonWrapper
              {...getDataTestId('left-arrow-button-wrapper')}
              $isShow={isMouseOnContent && visibilityOfArrowButtons.isLeftArrowButton}
            >
              <LeftArrowButton
                size={'s24'}
                onClick={handleLeftArrowClick}
                dataTestId={getDataTestId('left-arrow-button')['data-test-id']}
              />
            </Styled.LeftArrowButtonWrapper>

            <Styled.RightArrowButtonWrapper
              {...getDataTestId('right-arrow-button')}
              $isShow={isMouseOnContent && visibilityOfArrowButtons.isRightArrowButton}
            >
              <RightArrowButton
                size={'s24'}
                onClick={handleRightArrowClick}
                dataTestId={getDataTestId('right-arrow-button')['data-test-id']}
              />
            </Styled.RightArrowButtonWrapper>

            <Styled.Image {...getDataTestId('image')} $url={_selectedImage.downloadUrl} />
          </Styled.Content>
        ) : (
          <Styled.Content {...getDataTestId('content-with-plug')}>
            {<Styled.Plug {...getDataTestId('plug')} />}
          </Styled.Content>
        )}

        {_selectedImage ? (
          <Styled.Footer {...getDataTestId('footer')}>
            <AutoTooltip
              content={_selectedImage?.fileName}
              disabled={!isShowImageNameTooltip}
              position="top"
            >
              <Styled.SelectedImageName
                ref={imageNameRef}
                {...getDataTestId('selected-image-name')}
              >
                {_selectedImage?.fileName}
              </Styled.SelectedImageName>
            </AutoTooltip>

            <Styled.ImagesCount {...getDataTestId('images-count')}>
              {imagesCount}
            </Styled.ImagesCount>
          </Styled.Footer>
        ) : (
          <Styled.SelectedImageName>Нет фотографий</Styled.SelectedImageName>
        )}
      </Styled.Wrapper>

      {isShowCarousel ? (
        <ImagesCarousel
          imageList={imageList}
          selectedImage={_selectedImage}
          pinnedImage={_pinnedImage}
          isShowPinButton={isEnableEditing}
          onClose={closeCarousel}
          onPinImage={onPinImage}
        />
      ) : null}
    </>
  );
};

ImagesSelectionCard.displayName = 'ImagesSelectionCard';

export default memo(ImagesSelectionCard);
