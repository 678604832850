import React, { FC, memo } from 'react';

import { ITableFiltersBuilderFilter as IFilter } from '../../../models/data';
import { TableFiltersBuilderSelectFilter as SelectFilter } from '../TableFiltersBuilderSelectFilter';
import { TableFiltersBuilderDateRangeFilter as DateRangeFilter } from '../TableFiltersBuilderDateRangeFilter';
import { TableFiltersBuilderBooleanFilter as BooleanFilter } from '../TableFiltersBuilderBooleanFilter';

interface IProps {
  filter: IFilter;
}

const TableFiltersBuilderFilterContainer: FC<IProps> = ({ filter }) => {
  switch (filter.type) {
    case 'select':
      return <SelectFilter filter={filter} />;
    case 'date-range':
      return <DateRangeFilter filter={filter} />;
    case 'boolean':
      return <BooleanFilter filter={filter} />;
    default:
      return <></>;
  }
};

TableFiltersBuilderFilterContainer.displayName = 'TableFiltersBuilderFilterContainer';

export default memo(TableFiltersBuilderFilterContainer);
