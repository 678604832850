import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 14px;
`;

const StyledComparisonTableImagesAttribute = {
  Wrapper,
};

export default StyledComparisonTableImagesAttribute;
