import { FC, useEffect, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { IExperiment } from '../../../../../../../../../../api/models/as-fields/experiments';
import { EDashboardRoute } from '../../../../../../../../routes/DashboardRoute';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { TableBuilder } from '../../../../../../../../../common/features/TableBuilder';
import { EContractsTableBuilderId } from '../../../../../../constants/configs';
import { TableBuilderController } from '../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { ContractSidePanelStore } from '../../../../mobx/stores';

import { ExperimentsTableController } from './mobx/controllers';
import Styled from './ExperimentsTable.styles';

const ExperimentsTable: FC = () => {
  const navigate = useNavigate();
  const { selectedContract } = useStore(ContractSidePanelStore);
  const tableBuilderController = useStore(TableBuilderController);
  const experimentsTableController = useStore(ExperimentsTableController);

  const handleRowClick = useCallback((rowData: IExperiment) => {
    navigate(`/${EDashboardRoute.Experiments}/${rowData.id}/attributes`);
  }, []);

  useEffect(() => {
    experimentsTableController.getOrganizationExperimentList(
      selectedContract?.organization?.id,
      selectedContract?.id,
      false
    );
  }, [selectedContract?.organization?.id, selectedContract?.id]);

  useEffect(() => {
    if (!selectedContract?.organization?.id || !selectedContract?.id) return;

    experimentsTableController.initiateTable(
      selectedContract?.organization?.id,
      selectedContract?.id
    );

    tableBuilderController.addRowClickEvent(EContractsTableBuilderId.Experiments, handleRowClick);
  }, [selectedContract?.organization?.id, selectedContract?.id]);

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <TableBuilder
          builderId={EContractsTableBuilderId.Experiments}
          stylePreset="cleared"
          scrollPadding={'0px'}
        />
      </Styled.Content>
    </Styled.Wrapper>
  );
};

ExperimentsTable.displayName = 'ExperimentsTable';

export default memo(observer(ExperimentsTable));
