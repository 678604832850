import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { ComparisonTableBuilderStore as Store } from '../../stores';
import { IComparisonTableBuilderHeaderConfig as IHeaderConfig } from '../../../models/configs';
import { IComparisonTableBuilderHeader as IHeader } from '../../../models/data';

@provide.transient()
class ComparisonTableBuilderHeaderService {
  @lazyInject(Store)
  protected store: Store;

  addHeader = (builderId: string, config: IHeaderConfig): void => {
    const header = this.createHeader(builderId, config);

    this.store.setHeader(builderId, header);
  };

  protected createHeader = (builderId: string, config: IHeaderConfig): IHeader => {
    const header: IHeader = {
      ...config,
      builderId,
      renderType: null,
    };

    if (config?.autoRenderConfig) {
      header.renderType = 'auto';
    }

    if (config?.customRenderConfig) {
      header.renderType = 'custom';
    }

    return header;
  };
}

export default ComparisonTableBuilderHeaderService;
