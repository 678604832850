import { observer } from 'mobx-react';
import { FC } from 'react';

import { ExecutionDeviationsCell } from '../../../../../components/cells';
import { useStore } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionController } from '../../../../../mobx/controllers';

interface IProps {
  builderId: string;
  rowId: string;
  dataTestId: string;

  isFertilizerCell?: boolean;
  isProtectionCell?: boolean;
}

const ExecutionStepsDeviationsCalculatingCell: FC<IProps> = ({
  builderId,
  rowId,
  dataTestId,
  isFertilizerCell,
  isProtectionCell,
}) => {
  const executionController = useStore(ExecutionController);

  const formattedValue = executionController.getStepsDeviationsCalculatingValue(builderId, rowId, {
    isFertilizerCell,
    isProtectionCell,
  });

  return <ExecutionDeviationsCell {...formattedValue} dataTestId={dataTestId} />;
};

ExecutionStepsDeviationsCalculatingCell.displayName = 'ExecutionStepsDeviationsCalculatingCell';

export default observer(ExecutionStepsDeviationsCalculatingCell);
