import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { ComparisonController } from '../../mobx/controllers';
import { useSelectedExp, useZonesPlugResolver } from '../../../../hooks';
import { ComparisonTablePlug, ComparisonTabs } from '../../components';
import { ComparisonStore } from '../../mobx/stores';
import { ComparisonTableBuilder } from '../../../../../../../../../common/features/ComparisonTableBuilder';
import { ContentLoader } from '../../../../../../../../../common/features/UI/loaders/ContentLoader';
import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';
import { EComparisonTableName } from '../../../../../../../../constants/features';
import { createComparisonTableBuilderId as createTableBuilderId } from '../../utils/helpers/createComparisonTableBuilderId';
import { NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE } from '../../constants/titles';

import Styled from './Comparison.styles';

const Comparison: FC = () => {
  const { initiateTables, clearAllTablesData } = useStore(ComparisonController);
  const { activeTab, clearStore, isPageLoading, headerList, isComparisonDataExist } =
    useStore(ComparisonStore);

  const [currentExpId, setCurrentExpId] = useState(null);

  const selectedExp = useSelectedExp({ isClearOnUnmount: true });

  const {
    showPlug,
    plugType,
    plugTitle,
    Plug: ZonesPlug,
    loading,
  } = useZonesPlugResolver(
    selectedExp?.id,
    NO_ZONES_TITLE,
    NOT_SELECTED_ZONES_TITLE,
    isPageLoading
  );

  const referenceList = usePageContextualHelp(
    EContextualParentPath.ExperimentsExperimentComparison
  );
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = useMemo(() => {
    return helpActions.getContextualHelpIcon(
      EContextualParentPath.ExperimentsExperimentComparison,
      ContextualPaths.ExperimentsExperimentComparisonStepsTableTitle
    );
  }, [referenceList, helpActions]);

  useEffect(() => {
    if (!selectedExp?.id) {
      return;
    }

    setCurrentExpId(selectedExp?.id);

    return () => {
      clearStore();
      clearAllTablesData();
    };
  }, [selectedExp?.id]);

  /**
   * Вынесено в отдельную логику, так как иначе будут дублироваться данные
   * в таблице при переходе на листинг и обратно.
   */
  useEffect(() => {
    if (selectedExp?.id && currentExpId) {
      initiateTables(selectedExp?.id);
    }
  }, [selectedExp?.id, currentExpId]);

  const headerChildren = useMemo(
    () => (headerList.length > 1 ? <ComparisonTabs /> : null),
    [headerList.length]
  );

  const builderId = useMemo(
    () => createTableBuilderId(activeTab, EComparisonTableName.Comparison),
    [activeTab]
  );

  const isHideSliderControls = useMemo(() => activeTab === 'pairwise', [activeTab]);

  const getDataTestId = useDataTestIdV2('total-comparison');

  if (showPlug && !isComparisonDataExist) {
    return <ZonesPlug type={plugType} title={plugTitle} />;
  }

  return (
    <Styled.Wrapper>
      <Styled.MarginBlock />
      <Styled.Container
        title={'Сравнение'}
        headerChildren={headerChildren}
        ContextualHelpIcon={ContextualHelpIcon}
      >
        {isPageLoading || loading ? (
          <ContentLoader
            overlayStyles={{
              position: 'relative',
              height: '240px',
              width: '100%',
              borderRadius: '16px',
            }}
            dataTestId={getDataTestId('content-loader')['data-test-id']}
          />
        ) : !isComparisonDataExist ? (
          <ComparisonTablePlug />
        ) : (
          <ComparisonTableBuilder
            builderId={builderId}
            tableName={builderId}
            isHideSliderControls={isHideSliderControls}
          />
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

Comparison.displayName = 'Comparison';

export default observer(Comparison);
