import { FC } from 'react';

import { CalculationForm, CalculationInfoBlock } from '..';

import { calculationCostData } from './utils/consts';

export interface ICalculationZone {
  itemsCost: number;
  jobCost: number;
  summaryCost: number;
  plannedCropPrice: string;
  plannedYield: string;
  revenue: number;
  finishedCost: number;
  unitCost: number;
  onChangePlannedYield: (value: string) => void;
  onChangePlannedCropPrice: (value: string) => void;
}

interface IInfoForCurrentSectionProps {
  currentZone: ICalculationZone;
  changed: {
    currentRoi: number;
    revenueChanged: number;
    plannedYieldChanged: number;
    minimalIncrease: number;
  };
  isViewMode?: boolean;
}

const InfoForCurrentSection: FC<IInfoForCurrentSectionProps> = ({ currentZone, isViewMode }) => {
  return (
    <>
      <CalculationForm
        onChangePlannedCropPrice={currentZone?.onChangePlannedCropPrice}
        onChangePlannedYield={currentZone?.onChangePlannedYield}
        plannedCropPrice={currentZone?.plannedCropPrice}
        plannedYield={currentZone?.plannedYield}
        revenue={currentZone?.revenue}
        isViewMode={isViewMode}
      />
      <CalculationInfoBlock
        calculationInfoData={calculationCostData(currentZone)}
        elementWidth={'293.33px'}
      />
      {/* <CalculationInfoBlock calculationInfoData={calculationExpensesData(currentZone, changed)} /> */}
    </>
  );
};

export default InfoForCurrentSection;
