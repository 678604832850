import { forwardRef, useMemo } from 'react';
import { observer } from 'mobx-react';

import { TUniFormElementId, UniFormHelpers } from '../../../utils/helpers';
import { TFormElementType } from '../../../models/FormConfig';
import { useStore } from '../../../../../utils/helpers/mobx';
import { FormStore } from '../../../mobx/stores';
import { InputContainer } from '../InputContainer';
import { TextareaContainer } from '../TextareaContainer';
import { SelectContainer } from '../SelectContainer';
import { DatePickerContainer } from '../DatePickerContainer';

interface IFormElementContainerProps {
  formKey: string;
  elementName: string;
  id: TUniFormElementId;
  type: TFormElementType;
}

const FormElementContainer = forwardRef<HTMLInputElement, IFormElementContainerProps>(
  ({ formKey, elementName, id, type }, elementRef) => {
    const formStore = useStore(FormStore);
    const { focusedElementName, getElement } = formStore;

    const { createElementId } = UniFormHelpers;

    const focusedElementId = useMemo(() => {
      return createElementId(formKey, focusedElementName);
    }, [formKey, focusedElementName]);

    const focusedElementRef = useMemo(() => {
      return id === focusedElementId ? elementRef : null;
    }, [id, focusedElementId, elementRef]);

    const element = getElement(formKey, elementName);

    switch (type) {
      case 'input':
        return (
          <InputContainer
            formKey={formKey}
            elementName={elementName}
            id={id}
            ref={focusedElementRef}
            element={element}
          />
        );
      case 'textarea':
        return (
          <TextareaContainer
            formKey={formKey}
            elementName={elementName}
            id={id}
            ref={focusedElementRef}
            element={element}
          />
        );
      case 'select':
        return (
          <SelectContainer
            formKey={formKey}
            elementName={elementName}
            id={id}
            ref={focusedElementRef}
            element={element}
          />
        );
      case 'date':
        return (
          <DatePickerContainer
            formKey={formKey}
            elementName={elementName}
            id={id}
            element={element}
          />
        );
      default:
        return <></>;
    }
  }
);

FormElementContainer.displayName = 'FormElementContainer';

export default observer(FormElementContainer);
