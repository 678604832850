import { useEffect, useMemo, useState } from 'react';
import { generatePath, useMatch } from 'react-router-dom';

import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { EExperimentsRoute } from '../../../../routes';
import { ECreateExperimentRoute } from '../../routes';
import { CreateExperimentController } from '../../mobx/controllers';
import { CreateExperimentStore } from '../../mobx/stores';
import { EZonesPlugTypes, ZonesPlugResolver } from '../../components';
import { EDashboardRoute } from '../../../../../../routes/DashboardRoute';

export const useZonesPlugResolver = (
  experimentId: string,
  noZonesTitle: string,
  notSelectedZonesTitle: string,
  isPageLoading?: boolean
) => {
  const [loading, setLoading] = useState(false);
  const [showPlug, setShowPlug] = useState(false);

  const { fetchAllCultureZoneList } = useStore(CreateExperimentController);
  const { isAllowMapAccess, hasExperimentCultureZones } = useStore(CreateExperimentStore);

  const zonesMatch = useMatch(
    experimentId
      ? generatePath(
          `/${EDashboardRoute.Experiments}/${EExperimentsRoute.Experiment}/${ECreateExperimentRoute.Zones}`,
          { experimentId }
        )
      : `/${EDashboardRoute.Experiments}/create/${ECreateExperimentRoute.Zones}`
  );

  useEffect(() => {
    if (!experimentId) {
      setLoading(false);

      return;
    }

    setLoading(true);
    fetchAllCultureZoneList({ experimentId }).finally(() => setLoading(false));
  }, [experimentId]);

  useEffect(() => {
    if (!experimentId) {
      return;
    }

    if (
      ((!isAllowMapAccess && zonesMatch) || (!hasExperimentCultureZones && !zonesMatch)) &&
      !loading &&
      !isPageLoading
    ) {
      setShowPlug(true);
    } else {
      setShowPlug(false);
    }
  }, [isAllowMapAccess, hasExperimentCultureZones, zonesMatch, loading, isPageLoading]);

  const plugType = useMemo(() => {
    if (!isAllowMapAccess && !zonesMatch) {
      return EZonesPlugTypes.EmptyZones;
    }

    if (!isAllowMapAccess && zonesMatch) {
      return EZonesPlugTypes.NoZones;
    }

    if (!hasExperimentCultureZones) {
      return EZonesPlugTypes.NotSelectedZones;
    }
  }, [isAllowMapAccess, hasExperimentCultureZones, zonesMatch]);

  const plugTitle = useMemo(() => {
    if (!isAllowMapAccess && !zonesMatch) {
      return noZonesTitle;
    }

    if (!isAllowMapAccess && zonesMatch) {
      return noZonesTitle;
    }

    if (!hasExperimentCultureZones) {
      return notSelectedZonesTitle;
    }
  }, [isAllowMapAccess, hasExperimentCultureZones, zonesMatch]);

  return {
    loading,
    showPlug,
    plugType,
    plugTitle,
    Plug: ZonesPlugResolver,
  };
};
