import { toNumber } from '../../../../../../../../../../common/utils/helpers/numbers';

export const toAgrochemicalValue = (value: string) => {
  const numberValue = toNumber(value);

  if (numberValue) {
    return numberValue;
  }

  return value;
};
