import styled from 'styled-components';

const Header = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const Item = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & ${Item}:last-child {
    margin-bottom: 0;
  }
`;

const InventoryValueCellStyled = {
  Wrapper,
  Header,
  Item,
};

export default InventoryValueCellStyled;
