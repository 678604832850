import React, { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useNavigate } from 'react-router-dom';

import { useStore } from '../../../../../common/utils/helpers/mobx';
import { EExperimentsFiltersBuilderId } from '../../constants/configs';
import { EExperimentsRoute } from '../../routes';
import { ECreateExperimentRoute } from '../../modules/createExperiment/routes';
import { useTableFilters } from '../../../../../common/features/TableFiltersBuilder/hooks';
import { ExperimentsListController } from '../ExperimentsList/mobx/controllers';
import TableFiltersBuilder from '../../../../../common/features/TableFiltersBuilder/TableFiltersBuilder';

import { ExperimentsFiltersController } from './mobx/controllers';

const ExperimentsFilters: FC = () => {
  const experimentsFiltersController = useStore(ExperimentsFiltersController);
  const experimentsListController = useStore(ExperimentsListController);

  const navigate = useNavigate();

  const handleActionButtonClick = useCallback(() => {
    navigate(
      generatePath(`${EExperimentsRoute.Experiment}/${ECreateExperimentRoute.Attributes}`, {
        experimentId: 'create',
      })
    );
  }, []);

  useEffect(() => {
    experimentsFiltersController.initiateConfig(handleActionButtonClick);
  }, []);

  useEffect(() => {
    experimentsFiltersController.addOptionList();
  }, []);

  useTableFilters(EExperimentsFiltersBuilderId.Experiments, () =>
    experimentsListController.fetchExperimentList()
  );

  return <TableFiltersBuilder builderId={EExperimentsFiltersBuilderId.Experiments} />;
};

ExperimentsFilters.displayName = 'ExperimentsFilters';

export default observer(ExperimentsFilters);
