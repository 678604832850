import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import {
  EExperimentFactTableCalculationsAttributeId as ECalculationsAttributeId,
  IExperimentFactTableValue,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { formatDoubleNum } from '../../../../../../../../../../../../common/utils/helpers/numbers';
import {
  TExecutionCalculationsCellConfig as TCellConfig,
  TExecutionCalculationsRowConfig as TRowConfig,
} from '../../../types';
import { EExecutionTableColumnId as EColumnId } from '../../../../../types';
import {
  ExecutionCalculationsDeviationsCell as DeviationsCell,
  ExecutionCalculationsDoubleCell as DoubleCell,
  ExecutionCalculationsRevenueCell as RevenueCell,
  ExecutionCalculationsTotalFertilizerPriceCell as TotalFertilizerPriceCell,
  ExecutionCalculationsTotalPriceCell as TotalPriceCell,
  ExecutionCalculationsTotalServicePriceCell as TotalServicePriceCell,
} from '../../../containers/cells';
import { getExecutionTableAttributeIdByRowId as getAttributeIdByRowId } from '../../../../../helpers';

@provide.transient()
class ExecutionCalculationsCellConfigsService {
  createCellConfigList = (
    rowConfig: TRowConfig,
    valueList: IExperimentFactTableValue[]
  ): TCellConfig[] => {
    const formattedValueList = [...valueList, this.createDeviationsValue()];

    const cellConfigList = formattedValueList.map((initialValue, i) => {
      return this.createCellConfig(this.getColumnIdByOrder(i), rowConfig, initialValue);
    });

    return cellConfigList;
  };

  protected createCellConfig = (
    columnId: EColumnId,
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    switch (columnId) {
      case EColumnId.Plan:
        return this.createPlanCellConfig(rowConfig, value);

      case EColumnId.Fact:
        return this.createFactCellConfigByAttributeId(rowConfig, value);

      case EColumnId.Deviations:
        return this.createDeviationsCellConfig(rowConfig, value);

      default:
    }
  };

  protected createPlanCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Plan,
      rowId: rowConfig.id,
      autoRenderConfig: {
        preset: 'default',
        value: value.skip ? '—' : formatDoubleNum(value.doubleValue) ?? 0,
      },
      initialModel: value,
    };

    return cellConfig;
  };

  protected createFactCellConfigByAttributeId = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const attributeId = getAttributeIdByRowId(rowConfig.id);

    switch (attributeId) {
      case ECalculationsAttributeId.CropPrice:
        return this.createFactEditableCellConfig(rowConfig, value);

      case ECalculationsAttributeId.Yield:
        return this.createFactEditableCellConfig(rowConfig, value);

      case ECalculationsAttributeId.Revenue:
        return this.createFactRevenueCellConfig(rowConfig, value);

      case ECalculationsAttributeId.TotalFertilizerPrice:
        return this.createFactTotalFertilizerPriceCellConfig(rowConfig, value);

      case ECalculationsAttributeId.TotalServicePrice:
        return this.createFactTotalServicePriceCellConfig(rowConfig, value);

      case ECalculationsAttributeId.TotalPrice:
        return this.createFactTotalTotalPriceCellConfig(rowConfig, value);

      default:
    }
  };

  protected createFactEditableCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Fact,
      rowId: rowConfig.id,
      initialModel: value,
    };

    if (value.editable) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          return (
            <DoubleCell
              rowId={rowConfig.id}
              initialValue={value.doubleValue}
              dataTestId={dataTestId}
            />
          );
        },
      };
    } else {
      cellConfig.autoRenderConfig = {
        preset: rowConfig?.autoRenderConfig?.preset || 'default',
        value: value.skip ? '—' : value.doubleValue,
      };
    }

    return cellConfig;
  };

  protected createFactRevenueCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Fact,
      rowId: rowConfig.id,
      customRenderConfig: {
        render: cell => {
          return <RevenueCell cell={cell} />;
        },
      },
      initialModel: value,
    };

    return cellConfig;
  };

  protected createFactTotalFertilizerPriceCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Fact,
      rowId: rowConfig.id,
      customRenderConfig: {
        render: cell => {
          return <TotalFertilizerPriceCell cell={cell} />;
        },
      },
      initialModel: value,
    };

    return cellConfig;
  };

  protected createFactTotalServicePriceCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Fact,
      rowId: rowConfig.id,
      customRenderConfig: {
        render: cell => {
          return <TotalServicePriceCell cell={cell} />;
        },
      },
      initialModel: value,
    };

    return cellConfig;
  };

  protected createFactTotalTotalPriceCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Fact,
      rowId: rowConfig.id,
      customRenderConfig: {
        render: cell => {
          return <TotalPriceCell cell={cell} />;
        },
      },
      initialModel: value,
    };

    return cellConfig;
  };

  protected createDeviationsCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Deviations,
      rowId: rowConfig.id,
      customRenderConfig: {
        render: (cell, dataTestId) => (
          <DeviationsCell builderId={cell.builderId} rowId={cell.rowId} dataTestId={dataTestId} />
        ),
      },
      initialModel: value,
    };

    return cellConfig;
  };

  protected getColumnIdByOrder = (order: number): EColumnId => {
    switch (order) {
      case 0:
        return EColumnId.Plan;

      case 1:
        return EColumnId.Fact;

      case 2:
        return EColumnId.Deviations;

      default:
    }
  };

  protected createDeviationsValue = (): IExperimentFactTableValue => ({
    editable: false,
    skip: true,
  });
}

export default ExecutionCalculationsCellConfigsService;
