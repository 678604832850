import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
class ExperimentsStore {
  private _currentPageNumber = 0;

  private _totalPageNumber = 0;

  private _experimentsList: Array<any> | null = null;

  private _filters: { districtId?: string } = {};

  private _cultureList = [];

  private _organizationList = [];

  private _experimentUsersList = [];

  private _regionList = [];

  private _FDList = [];

  private _isLoading = false;

  private _filterPageNumbers = {
    organizationCurrentPageNumber: 0,
    organizationTotalPageNumber: 0,
    cultureCurrentPageNumber: 0,
    cultureTotalPageNumber: 0,
    federalDistrictCurrentPageNumber: 0,
    federalDistrictTotalPageNumber: 0,
    regionCurrentPageNumber: 0,
    regionTotalPageNumber: 0,
    responsibleCurrentPageNumber: 0,
    responsibleTotalPageNumber: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  get currentPageNumber() {
    return this._currentPageNumber;
  }

  get totalPageNumber() {
    return this._totalPageNumber;
  }

  get experimentsList() {
    return this._experimentsList;
  }

  get filters() {
    return this._filters;
  }

  get cultureList() {
    return this._cultureList;
  }

  get regionList() {
    return this._regionList;
  }

  get organizationList() {
    return this._organizationList;
  }

  get experimentUsersList() {
    return this._experimentUsersList;
  }

  get FDList() {
    return this._FDList;
  }

  get isLoading() {
    return this._isLoading;
  }

  get filterPageNumbers() {
    return this._filterPageNumbers;
  }

  setCurrentPageNumber = (pageNumber: number): void => {
    this._currentPageNumber = pageNumber;
  };

  setTotalPageNumber = (pageNumber: number): void => {
    this._totalPageNumber = pageNumber;
  };

  setExperimentsList = (experimentsList: Array<any>): void => {
    this._experimentsList = experimentsList;
  };

  setFilters = (filters): void => {
    this._filters = filters;
  };

  setCultureList = (cultureList): void => {
    this._cultureList = cultureList;
  };

  setRegionList = (regionList): void => {
    this._regionList = regionList;
  };

  setOrganizationList = (organizationList): void => {
    this._organizationList = organizationList;
  };

  setExperimentUsersList = (experimentUsersList): void => {
    this._experimentUsersList = experimentUsersList;
  };

  setFDList = (FDList): void => {
    this._FDList = FDList;
  };

  setIsLoading = (isLoading): void => {
    this._isLoading = isLoading;
  };

  setFilterPageNumbers = (filterName, pageNumber): void => {
    this._filterPageNumbers[filterName] = pageNumber;
  };

  clearCurrentPageNumber = (): void => {
    this._currentPageNumber = 0;
  };

  clearTotalPageNumber = (): void => {
    this._totalPageNumber = 0;
  };

  clearExperimentsList = (): void => {
    this._experimentsList = null;
  };

  clearFilters = (): void => {
    this._filters = {};
  };

  clearCultureList = (): void => {
    this._cultureList = [];
  };

  clearRegionList = (): void => {
    this._regionList = [];
  };

  clearOrganizationList = (): void => {
    this._organizationList = [];
  };

  clearExperimentUsersList = (): void => {
    this._experimentUsersList = [];
  };

  clearFDList = (): void => {
    this._FDList = [];
  };

  clearFilterPageNumbers = (): void => {
    this._filterPageNumbers = {
      organizationCurrentPageNumber: 0,
      organizationTotalPageNumber: 0,
      cultureCurrentPageNumber: 0,
      cultureTotalPageNumber: 0,
      federalDistrictCurrentPageNumber: 0,
      federalDistrictTotalPageNumber: 0,
      regionCurrentPageNumber: 0,
      regionTotalPageNumber: 0,
      responsibleCurrentPageNumber: 0,
      responsibleTotalPageNumber: 0,
    };
  };

  clearStore = (): void => {
    this.clearCurrentPageNumber();
    this.clearTotalPageNumber();
    this.clearExperimentsList();
    this.clearFilters();
    this.clearCultureList();
    this.clearOrganizationList();
    this.clearExperimentUsersList();
    this.clearRegionList();
    this.clearFDList();
    this.clearFilterPageNumbers();
  };
}

export default ExperimentsStore;
