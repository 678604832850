import styled from 'styled-components';

const FirstDropdownWrapper = styled.div`
  height: 20px;
  margin-top: -10px;
  position: absolute;
  width: 201px;
`;

const SecondDropdownWrapper = styled.div`
  height: 20px;
  margin-top: -10px;
  margin-right: -10px;
  position: absolute;
  right: 13px;
  width: 201px;
`;

const StyledPairWiseComparisonColumnsService = {
  FirstDropdownWrapper,
  SecondDropdownWrapper,
};

export default StyledPairWiseComparisonColumnsService;
