import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';
import { IExperimentShortInfo } from '../../../../../../api/models/as-fields/experiments';

const createExperimentShortInfoSelectOption = (
  expShortInfo: IExperimentShortInfo
): ISelectOption => {
  return { label: expShortInfo.code, value: expShortInfo.id };
};

const createExperimentShortInfoSelectOptionList = (
  expShortInfoList: IExperimentShortInfo[]
): ISelectOption[] => {
  return expShortInfoList.map<ISelectOption>(createExperimentShortInfoSelectOption);
};

export { createExperimentShortInfoSelectOption, createExperimentShortInfoSelectOptionList };
