import styled from 'styled-components';

type TStyledStatusWrapperProps = {
  $bgColor: string;
  $color: string;
};

const getStatusWrapperBGColor = ({ $bgColor }) => {
  return $bgColor;
};

const getStatusWrapperColor = ({ $color }) => {
  return $color;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  margin-left: 12px;
`;

const Wrapper = styled.div<TStyledStatusWrapperProps>`
  color: ${getStatusWrapperColor};

  font-size: 14px;
  line-height: 20px;
  text-wrap: nowrap;

  border-radius: 8px;
  padding: 0 8px;
  width: fit-content;
  background: ${getStatusWrapperBGColor};
`;

const StyledExperimentStatus = {
  Container,
  Wrapper,
};

export default StyledExperimentStatus;
