import { IExperimentStep } from '../../../models/as-fields/experiments';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TRequest = { id: string; cultureZoneExperimentId?: string };

type TResponse = IExperimentStep;

export const getFactExperimentStep: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/experimentSteps/${id}/fact`,
  method: 'GET',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};
