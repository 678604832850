import { IChatMessage } from '../../../../../../../../../common/features/ChatJournal/models';
import { provide } from '../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
class ApprovalStore {
  private _approvalMessageList: IChatMessage[] = [];
  private _isLoading = false;

  get approvalMessageList() {
    return this._approvalMessageList;
  }

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (state: boolean) => {
    this._isLoading = state;
  };

  setApprovalMessageList = (messageList: IChatMessage[]) => {
    this._approvalMessageList = messageList;
  };

  clearIsLoading = () => {
    this._isLoading = false;
  };

  clearMessageList = () => {
    this._approvalMessageList = [];
  };

  clearApprovalStore = () => {
    this.clearIsLoading();
    this.clearMessageList();
  };
}

export default ApprovalStore;
