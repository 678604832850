import { FC } from 'react';

import { ListingPage } from '../../../../../common/features/UI/pages/ListingPage';
import { ContractsFilters } from '../ContractsFilters';
import { ContractsList } from '../ContractsList';

const Contracts: FC = () => {
  return <ListingPage id={'contracts'} filters={<ContractsFilters />} list={<ContractsList />} />;
};

Contracts.displayName = 'Contracts';

export default Contracts;
