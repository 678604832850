import moment from 'moment/moment';

import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import {
  IExperimentFactTableRowsGroupOptions,
  IExperimentFactTableValue,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import {
  ExecutionPhenophaseBbchCell,
  ExecutionTableDateCell,
} from '../../../../../components/cells';
import { createExecutionPhenophaseName as createPhenophaseName } from '../../../../../helpers';
import { formatDoubleNum } from '../../../../../../../../../../../../common/utils/helpers/numbers';
import {
  TExecutionStepsCellConfig as TCellConfig,
  TExecutionStepsRowConfig as TRowConfig,
} from '../../../types';
import { EExecutionTableColumnId as EColumnId } from '../../../../../types';
import {
  ExecutionStepsDeviationsCalculatingCell,
  ExecutionStepsDeviationsConditionalCell,
  ExecutionStepsProtectionDoubleCell,
  ExecutionStepsStepDoubleCell,
} from '../../../containers/cells';
import ExecutionStepsFertilizerDoubleCell from '../../../containers/cells/ExecutionStepsFertilizerDoubleCell/ExecutionStepsFertilizerDoubleCell';

@provide.transient()
class ExecutionCalculationsConfigsService {
  createCellConfigList = (
    rowConfig: TRowConfig,
    valueList: IExperimentFactTableValue[]
  ): TCellConfig[] => {
    const formattedValueList = [...valueList, this.createDeviationsValue()];

    const cellConfigList = formattedValueList.map((value, i) => {
      return this.createCellConfig(this.getColumnIdByOrder(i), rowConfig, value);
    });

    return cellConfigList;
  };

  protected createCellConfig = (
    columnId: EColumnId,
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    switch (columnId) {
      case EColumnId.Plan:
        return this.createPlanCellConfig(rowConfig, value);

      case EColumnId.Fact:
        return this.createFactCellConfig(rowConfig, value);

      case EColumnId.Deviations:
        return this.createDeviationsCellConfig(rowConfig, value);

      default:
    }
  };

  protected createPlanCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    switch (rowConfig.initialModel.type) {
      case 'double':
        return this.createPlanDoubleCellConfig(rowConfig, value);

      case 'date':
        return this.createPlanDateCellConfig(rowConfig, value);

      case 'dictionary':
        return this.createPlanDictionaryCellConfig(rowConfig, value);

      default:
    }
  };

  protected createPlanDoubleCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Plan,
      rowId: rowConfig.id,
      autoRenderConfig: {
        preset: rowConfig?.autoRenderConfig?.preset || 'default',
        value: value.skip ? '—' : formatDoubleNum(value.doubleValue) ?? 0,
      },
      initialModel: {
        ...value,
        type: rowConfig.initialModel.type,
        stepId: rowConfig.initialModel.stepId,
        protectionId: rowConfig.initialModel.protectionId,
        fertilizerId: rowConfig.initialModel.fertilizerId,
      },
    };

    return cellConfig;
  };

  protected createPlanDateCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Plan,
      rowId: rowConfig.id,
      autoRenderConfig: {
        preset: rowConfig?.autoRenderConfig?.preset || 'default',
        value: value.skip ? '—' : moment(value.dateValue, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      },
      initialModel: {
        ...value,
        type: rowConfig.initialModel.type,
        stepId: rowConfig.initialModel.stepId,
        protectionId: rowConfig.initialModel.protectionId,
        fertilizerId: rowConfig.initialModel.fertilizerId,
      },
    };

    return cellConfig;
  };

  protected createPlanDictionaryCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Plan,
      rowId: rowConfig.id,
      autoRenderConfig: {
        preset: rowConfig?.autoRenderConfig?.preset || 'default',
        value: value.skip ? '—' : createPhenophaseName(value),
      },
      initialModel: {
        ...value,
        type: rowConfig.initialModel.type,
        stepId: rowConfig.initialModel.stepId,
        protectionId: rowConfig.initialModel.protectionId,
        fertilizerId: rowConfig.initialModel.fertilizerId,
      },
    };

    return cellConfig;
  };

  protected createFactCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    switch (rowConfig.initialModel.type) {
      case 'double':
        return this.createFactDoubleCellConfig(rowConfig, value);

      case 'date':
        return this.createFactDateCellConfig(rowConfig, value);

      case 'dictionary':
        return this.createFactDictionaryCellConfig(rowConfig, value);

      default:
    }
  };

  protected createFactDoubleCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Fact,
      rowId: rowConfig.id,
      initialModel: {
        ...value,
        type: rowConfig.initialModel.type,
        stepId: rowConfig.initialModel.stepId,
        protectionId: rowConfig.initialModel.protectionId,
        fertilizerId: rowConfig.initialModel.fertilizerId,
      },
    };

    if (value.editable) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          if (rowConfig.initialModel.fertilizerId) {
            return <ExecutionStepsFertilizerDoubleCell cell={cell} dataTestId={dataTestId} />;
          } else if (rowConfig.initialModel.protectionId) {
            return <ExecutionStepsProtectionDoubleCell cell={cell} dataTestId={dataTestId} />;
          }

          return <ExecutionStepsStepDoubleCell cell={cell} dataTestId={dataTestId} />;
        },
      };

      return cellConfig;
    }

    cellConfig.autoRenderConfig = {
      preset: rowConfig?.autoRenderConfig?.preset || 'default',
      value: value.skip ? '—' : formatDoubleNum(value.doubleValue) ?? 0,
    };

    return cellConfig;
  };

  protected createFactDictionaryCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Fact,
      rowId: rowConfig.id,
      initialModel: {
        ...value,
        type: rowConfig.initialModel.type,
        stepId: rowConfig.initialModel.stepId,
        protectionId: rowConfig.initialModel.protectionId,
        fertilizerId: rowConfig.initialModel.fertilizerId,
      },
    };

    if (value.editable) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => {
          return <ExecutionPhenophaseBbchCell cell={cell} dataTestId={dataTestId} />;
        },
      };
    } else {
      cellConfig.autoRenderConfig = {
        preset: rowConfig?.autoRenderConfig?.preset || 'default',
        value: value.skip ? '—' : createPhenophaseName(value),
      };
    }

    return cellConfig;
  };

  protected createFactDateCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Fact,
      rowId: rowConfig.id,
      initialModel: {
        ...value,
        type: rowConfig.initialModel.type,
        stepId: rowConfig.initialModel.stepId,
        protectionId: rowConfig.initialModel.protectionId,
        fertilizerId: rowConfig.initialModel.fertilizerId,
      },
    };

    if (value.editable) {
      cellConfig.customRenderConfig = {
        render: (cell, dataTestId) => (
          <ExecutionTableDateCell cell={cell} dataTestId={dataTestId} />
        ),
      };
    } else {
      cellConfig.autoRenderConfig = {
        preset: rowConfig?.autoRenderConfig?.preset || 'default',
        value: value.skip ? '—' : moment(value.dateValue, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      };
    }

    return cellConfig;
  };

  protected createDeviationsCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    switch (rowConfig.initialModel.type) {
      case 'double':
        return this.createDeviationsCalculatingCellConfig(rowConfig, value);

      case 'date':
        return this.createDeviationsConditionalCellConfig(rowConfig, value);

      case 'dictionary':
        return this.createDeviationsConditionalCellConfig(rowConfig, value);

      default:
    }
  };

  protected createDeviationsCalculatingCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Deviations,
      rowId: rowConfig.id,
      customRenderConfig: {
        render: (cell, dataTestId) => (
          <ExecutionStepsDeviationsCalculatingCell
            builderId={cell.builderId}
            rowId={cell.rowId}
            dataTestId={dataTestId}
            isFertilizerCell={Boolean(rowConfig.initialModel.fertilizerId)}
            isProtectionCell={Boolean(rowConfig.initialModel.protectionId)}
          />
        ),
      },
      initialModel: {
        ...value,
        type: rowConfig.initialModel.type,
        stepId: rowConfig.initialModel.stepId,
        protectionId: rowConfig.initialModel.protectionId,
        fertilizerId: rowConfig.initialModel.fertilizerId,
      },
    };

    return cellConfig;
  };

  protected createDeviationsConditionalCellConfig = (
    rowConfig: TRowConfig,
    value: IExperimentFactTableValue
  ): TCellConfig => {
    const cellConfig: TCellConfig = {
      columnId: EColumnId.Deviations,
      rowId: rowConfig.id,
      customRenderConfig: {
        render: (cell, dataTestId) => (
          <ExecutionStepsDeviationsConditionalCell
            builderId={cell.builderId}
            rowId={cell.rowId}
            dataTestId={dataTestId}
          />
        ),
      },
      initialModel: {
        ...value,
        type: rowConfig.initialModel.type,
        stepId: rowConfig.initialModel.stepId,
        protectionId: rowConfig.initialModel.protectionId,
        fertilizerId: rowConfig.initialModel.fertilizerId,
      },
    };

    return cellConfig;
  };

  protected getColumnIdByOrder = (order: number): EColumnId => {
    switch (order) {
      case 0:
        return EColumnId.Plan;

      case 1:
        return EColumnId.Fact;

      case 2:
        return EColumnId.Deviations;

      default:
    }
  };

  protected createDeviationsValue = (): IExperimentFactTableValue => ({
    editable: false,
    skip: true,
  });
}

export default ExecutionCalculationsConfigsService;
