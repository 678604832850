import { makeAutoObservable } from 'mobx';

import { IAccessRule } from '../../../../../api/models/da-profile/access/Access.model';
import { EErrorStatusName } from '../../../utils/constants/errorStatus';
import { lazyInject, provide } from '../../../utils/helpers/mobx';
import { AxiosService } from '../../services/axios/AxiosService';

@provide.singleton()
export class AccessStore {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  constructor() {
    makeAutoObservable(this);
  }

  private _accessRules: Array<IAccessRule> | void = [];
  private _accessRulesSetList: Set<string> = new Set();
  private _isAllowToWatchExperiments: boolean;
  private _isAllowToEditExperiments: boolean;
  private _isAllowToEditContracts: boolean;
  private _isAllowToWatchAudits: boolean;
  private _isAllowToAddAudits: boolean;
  private _responseError: EErrorStatusName;

  get accessRules() {
    return this._accessRules;
  }

  get isAllowToWatchExperiments() {
    return this._isAllowToWatchExperiments;
  }

  get isAllowToEditExperiments() {
    return this._isAllowToEditExperiments;
  }

  get isAllowToWatchAudits() {
    return this._isAllowToWatchAudits;
  }

  get isAllowToAddAudits() {
    return this._isAllowToAddAudits;
  }

  get responseError() {
    return this._responseError;
  }

  get isAllowToEditContracts() {
    return this._isAllowToEditContracts;
  }

  get isAllowToWatchSto() {
    return this._accessRulesSetList.has('sto.view');
  }

  setAccessRules = (newAccessRules: IAccessRule[] | void) => {
    this._accessRules = newAccessRules;

    if (newAccessRules) {
      this._accessRulesSetList = new Set(newAccessRules.flatMap(rule => rule.code));

      this._isAllowToEditExperiments = Boolean(
        newAccessRules.find(rule => rule.code === 'experiments.editExperiments')
      );
      this._isAllowToEditContracts = Boolean(
        newAccessRules.find(rule => rule.code === 'experiments.editContracts')
      );
      this._isAllowToAddAudits = Boolean(
        newAccessRules.find(rule => rule.code === 'experiments.addOperations') &&
          newAccessRules.find(rule => rule.code === 'scouting.workWithTasks') &&
          newAccessRules.find(rule => rule.code === 'scouting.manageTasks')
      );
    }
  };

  setIsAllowedToWatchExperiments = isAllowed => {
    this._isAllowToWatchExperiments = isAllowed;
    this._isAllowToWatchAudits = isAllowed;
  };

  setResponseError = (newResponseError: EErrorStatusName) => {
    this._responseError = newResponseError;
  };

  clearAccessRules = () => {
    this._accessRules = [];
    this._accessRulesSetList = new Set();
  };
}
