import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import {
  TDeleteNutritionHistoryReg,
  TGetFertilizerPricesReg,
  TGetNutritionHistoryListReg,
  TUpdateNutritionHistoryReg,
  TGetProtectionPricesReg,
} from '../../../../../../api';
import {
  ICreateNutritionHistoryDto,
  INutritionHistory,
} from '../../../../../../api/models/as-fields/plan/NutrationHistory';
import { EExperimentStepType } from '../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';

@provide.singleton()
class NutritionHistoryService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getNutritionHistoryList = async (
    payload: TGetNutritionHistoryListReg,
    query?: Record<string, any>
  ): Promise<INutritionHistory[]> => {
    try {
      const { content } = await this.axiosService.api.getNutritionHistoryList(payload, query);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  deleteNutritionHistory = async (payload: TDeleteNutritionHistoryReg) => {
    try {
      await this.axiosService.api.deleteNutritionHistory(payload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  createNutritionHistory = async (payload: ICreateNutritionHistoryDto) => {
    try {
      await this.axiosService.api.createNutritionHistory(payload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  updateNutritionHistory = async (payload: TUpdateNutritionHistoryReg) => {
    try {
      await this.axiosService.api.updateNutritionHistory(payload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  copyNutritionHistory = async (
    donorId: string,
    recipientId: string,
    type: EExperimentStepType.Plan | EExperimentStepType.NutritionHistory
  ) => {
    try {
      const copyNutritionHistory =
        type === EExperimentStepType.NutritionHistory
          ? this.axiosService.api.copyHistoryNutritionHistory
          : this.axiosService.api.copyPlanNutritionHistory;

      await copyNutritionHistory({ donorId, recipientId }, { omit: ['donorId', 'recipientId'] });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  copyAho = async (donorId: string, recipientId: string) => {
    try {
      await this.axiosService.api.copyAho(
        { donorId, recipientId },
        { omit: ['donorId', 'recipientId'] }
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  deleteAho = async cultureZoneExperimentId => {
    try {
      // @ts-ignore
      await this.axiosService.api.deleteAho(
        {},
        {
          query: {
            cultureZoneExperimentId,
          },
        }
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  getFertilizerPrices = async (payload: TGetFertilizerPricesReg) => {
    try {
      const fertilizerPrices = await this.axiosService.api.getFertilizerPrices(payload);

      return fertilizerPrices;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  getProtectionPrices = async (payload: TGetProtectionPricesReg) => {
    try {
      const protectionPrices = await this.axiosService.api.getProtectionPrices(payload);

      return protectionPrices;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}

export default NutritionHistoryService;
