import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { IDictionaryEntity } from '../../../models/da-dictionary/dictionary';

type TGetDictionaryEntityListReq = {
  idIn?: string[];
  parentId?: string;
  nameFilter?: string;
  codeFilter?: string;
  descriptionFilter?: string;
  pathEquals?: string;
  pathPrefix?: string;
  originalOnly?: boolean;
  latestVersion?: boolean;
  level?: number;
  attrs?: {
    [key: string]: any;
  };
  status?: string;
  fetchAttributes?: boolean;
  remoteName?: string;
  dependencyName?: string;
  dependencyRecordId?: string;
};

type TGetDictionaryEntityListRes = {} & TResponseList<IDictionaryEntity>;

const getDictionaryEntityList: TApiRoute & {
  request: TGetDictionaryEntityListReq;
  response: TGetDictionaryEntityListRes;
} = {
  url: ({ remoteName }) => `/api/da-dictionary/dictionary/${remoteName}/findAll`,
  method: 'POST',
  request: {} as TGetDictionaryEntityListReq,
  response: {} as TGetDictionaryEntityListRes,
  headers: {},
};

export type { TGetDictionaryEntityListReq, TGetDictionaryEntityListRes };

export { getDictionaryEntityList };
