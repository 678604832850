import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { Container } from '../../../../../../../common/features/UI';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { TAuditsParams } from '../../../../models/params/AuditsParams.model';
import { DynamicTable } from '../../../../../../../common/features/DynamicTable';
import { SpinnerLoader } from '../../../../../../../common/components/ui/loaders';

import { TaskReportHeaderActions } from './components';
import TaskReportController from './mobx/controllers/TaskReport.controller';
import TaskReportStore from './mobx/stores/TaskReport.store';

const TaskReport: FC = () => {
  const { dynamicTableConfig, clearStore } = useStore(TaskReportStore);
  const { getDynamicTableConfig, fetchTask } = useStore(TaskReportController);

  const { taskId } = useParams<TAuditsParams>();

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!taskId) {
        return;
      }

      await fetchTask(taskId);
      await getDynamicTableConfig();
    })();
  }, [taskId]);

  return (
    <Container headerChildren={<TaskReportHeaderActions />}>
      {dynamicTableConfig ? (
        <DynamicTable config={dynamicTableConfig} />
      ) : (
        <SpinnerLoader needToHideContent={false} />
      )}
    </Container>
  );
};

TaskReport.displayName = 'TaskReport';

export default observer(TaskReport);
