import { ActionCellComponentMini } from '../../../../../../../../../../../../common/components/AhoTable/cells/ActionsCell/component';
import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../../../../../common/features/TableBuilder/models/configs';
import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentsTableBuilderId } from '../../../../../../../../../constants/configs';

@provide.transient()
class CalculationAgrochemicalConfigsService {
  createConfig = (onClickEditAho, onClickDeleteAho, isViewOnly): IBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList(onClickEditAho, onClickDeleteAho, isViewOnly);
    const rowsGroupList = this.createRowsGroupList();

    return {
      id: EExperimentsTableBuilderId.CalculationAgrochemical,
      columnConfigList: columnList,
      cellConfigList: cellList,
      rowsGroupConfigList: rowsGroupList,
    };
  };

  createColumnList = (): IColumnConfig[] => {
    const date = this.createDateColumn();
    const type = this.createTypeColumn();
    const mechanicalComposition = this.createMechanicalCompositionColumn();
    const humus = this.createHumusColumn();
    const ph = this.createPHColumn();
    const po = this.createPOColumn();
    const ko = this.createKOColumn();
    const actions = this.createActionsColumn();

    return [date, type, mechanicalComposition, humus, ph, po, ko, actions];
  };

  createDateColumn = (): IColumnConfig => {
    return {
      id: 'date',
      name: 'Дата проведения',
      width: {
        default: '136px',
      },
    };
  };

  createTypeColumn = (): IColumnConfig => {
    return {
      id: 'type',
      name: 'Тип почвы',
      width: {
        default: '190px',
      },
    };
  };

  createMechanicalCompositionColumn = (): IColumnConfig => {
    return {
      id: 'mechanicalComposition',
      name: 'Мех.состав',
      width: {
        default: '190px',
      },
    };
  };

  createHumusColumn = (): IColumnConfig => {
    return {
      id: 'humus',
      name: 'Гумус, %',
      width: {
        default: '72px',
      },
    };
  };

  createPHColumn = (): IColumnConfig => {
    return {
      id: 'ph',
      name: 'pH',
      width: {
        default: '72px',
      },
    };
  };

  createPOColumn = (): IColumnConfig => {
    return {
      id: 'po',
      name: 'P₂O₅',
      width: {
        default: '72px',
      },
    };
  };

  createKOColumn = (): IColumnConfig => {
    return {
      id: 'ko',
      name: 'K₂O',
      width: {
        default: '72px',
      },
    };
  };

  createActionsColumn = (): IColumnConfig => {
    return {
      id: 'actions',
      name: '',
      width: {
        default: '1fr',
      },
    };
  };

  createCellList = (onClickEditAho, onClickDeleteAho, isViewOnly): ICellConfig[] => {
    const date = this.createDateCell();
    const type = this.createTypeCell();
    const mechanicalComposition = this.createMechanicalCompositionCell();
    const humus = this.createHumusCell();
    const ph = this.createPHCell();
    const po = this.createPOCell();
    const ko = this.createKOCell();
    const actions = this.createActionsCell(onClickEditAho, onClickDeleteAho, isViewOnly);

    return [date, type, mechanicalComposition, humus, ph, po, ko, actions];
  };

  createDateCell = (): ICellConfig => {
    return {
      id: 'date',
      autoRenderConfig: {
        renderValue: row => row.date,
        alighnItems: 'top',
      },
    };
  };

  createTypeCell = (): ICellConfig => {
    return {
      id: 'type',
      autoRenderConfig: {
        renderValue: row => row.type,
        alighnItems: 'top',
      },
    };
  };

  createMechanicalCompositionCell = (): ICellConfig => {
    return {
      id: 'mechanicalComposition',
      autoRenderConfig: {
        renderValue: row => row.mechanicalComposition,
        alighnItems: 'top',
      },
    };
  };

  createHumusCell = (): ICellConfig => {
    return {
      id: 'humus',
      autoRenderConfig: {
        renderValue: row => row.humus,
        alighnItems: 'top',
      },
    };
  };

  createPHCell = (): ICellConfig => {
    return {
      id: 'ph',
      autoRenderConfig: {
        renderValue: row => row.ph,
        alighnItems: 'top',
      },
    };
  };

  createPOCell = (): ICellConfig => {
    return {
      id: 'po',
      autoRenderConfig: {
        renderValue: row => row.po,
        alighnItems: 'top',
      },
    };
  };

  createKOCell = (): ICellConfig => {
    return {
      id: 'ko',
      autoRenderConfig: {
        renderValue: row => row.ko,
        alighnItems: 'top',
      },
    };
  };

  createActionsCell = (onClickEditAho, onClickDeleteAho, isViewOnly): ICellConfig => {
    return {
      id: 'actions',
      customRenderConfig: {
        renderCell: row =>
          isViewOnly ? (
            <></>
          ) : (
            <ActionCellComponentMini
              row={row}
              onClickDeleteAho={onClickDeleteAho}
              onClickEditAho={onClickEditAho}
            />
          ),
      },
    };
  };

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EExperimentsTableBuilderId.CalculationAgrochemical,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default CalculationAgrochemicalConfigsService;
