import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { IContract } from '../../../models/as-contracts';

type TGetContractListReq = {
  organizationId: string;
  searchName?: string;
  size?: number;
};

type TGetContractListRes = {} & TResponseList<IContract>;

const getContractList: TApiRoute & {
  request: TGetContractListReq;
  response: TGetContractListRes;
} = {
  url: () => `/api/as-contracts/contracts/list`,
  method: 'GET',
  request: {} as TGetContractListReq,
  response: {} as TGetContractListRes,
  headers: {},
};

export type { TGetContractListReq, TGetContractListRes };

export { getContractList };
