import { FC, useContext } from 'react';

import { SliderControls } from '../../components';
import { SliderContext } from '../../context';

const SliderControlsContainer: FC = () => {
  const { isShowControls, isLeftArrowDisabled, isRightArrowDisabled } = useContext(SliderContext);

  return (
    <>
      {isShowControls ? (
        <SliderControls
          isLeftArrowDisabled={isLeftArrowDisabled}
          isRightArrowDisabled={isRightArrowDisabled}
        />
      ) : null}
    </>
  );
};

export default SliderControlsContainer;
