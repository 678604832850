import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';

import { useStore } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionDoubleCell } from '../../../../../components/cells';
import { ExecutionStore } from '../../../../../mobx/stores';
import { ExecutionController } from '../../../../../mobx/controllers';
import { getExecutionTableAttributeIdByRowId as getAttributeIdByRowId } from '../../../../../helpers';
import { IExperimentFactDataNutritionHistoryItemUpdate } from '../../../../../../../../../../../../../api/models/as-fields/experiments';
import { TExecutionStepsCell } from '../../../types';

interface IProps {
  cell: TExecutionStepsCell;
  dataTestId: string;
}

const ExecutionStepsFertilizerDoubleCell: FC<IProps> = ({ cell, dataTestId }) => {
  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);

  const attributeId = useMemo(() => getAttributeIdByRowId(cell.rowId), []);

  const updatedValue = executionStore.getItemForUpdate(
    executionStore.selectedZoneId,
    cell.initialModel.stepId,
    cell.initialModel.fertilizerId
  )?.[attributeId] as string;

  const handleChange = useCallback((newValue: string, entityId?: string) => {
    const changedFertilizer: IExperimentFactDataNutritionHistoryItemUpdate = {
      fertilizerId: cell.initialModel.fertilizerId,
      [attributeId]: newValue,
    };

    if (entityId) {
      changedFertilizer.id = entityId;
    }

    executionController.changeFertilizer(cell.initialModel.stepId, changedFertilizer);
  }, []);

  return (
    <ExecutionDoubleCell
      initialValue={cell.initialModel.doubleValue}
      updatedValue={updatedValue}
      onChange={handleChange}
      dataTestId={dataTestId}
      entityId={cell.initialModel.entityId}
    />
  );
};

ExecutionStepsFertilizerDoubleCell.displayName = 'ExecutionStepsFertilizerDoubleCell';

export default observer(ExecutionStepsFertilizerDoubleCell);
