import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { TableFiltersBuilderController } from '../../../../../../../../common/features/TableFiltersBuilder/mobx/controllers';
import {
  ContractsFiltersConfigsService as ConfigsService,
  ContractsFiltersOptionsService as OptionsService,
} from '../../services';
import { IContractsFilters } from '../../../models';
import { EContractsFiltersBuilderId } from '../../../../../constants/configs';

@provide.transient()
class ContractsFiltersController {
  @lazyInject(ConfigsService)
  protected configsService: ConfigsService;

  @lazyInject(OptionsService)
  protected optionsService: OptionsService;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<IContractsFilters>;

  initiateConfig = (onActionButtonClick: () => void): void => {
    const config = this.configsService.createConfig(
      onActionButtonClick,
      this.optionsService.optionsQueryScheme
    );

    this.tableFiltersBuilderController.initiateConfig(config);
  };

  addOptionList = async (): Promise<void> => {
    const [organizationData] = await Promise.all([
      this.optionsService.organizationOptionListSearchQueryHandler(),
    ]);

    this.tableFiltersBuilderController.addSelectOptionList(
      EContractsFiltersBuilderId.Contracts,
      'organizationIdIn',
      organizationData.optionList
    );
  };
}

export default ContractsFiltersController;
