import { FC, useEffect, useMemo } from 'react';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { useForm } from '../../../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { copyFieldFormConfig, ICopyFieldForm } from '../../configs/forms/copyFieldForm';
import { HistoryStore } from '../../mobx/stores/History.store';
import { HistoryController } from '../../mobx/controllers/History.contorller';
import { createSelectOptionsInCultureZones } from '../../helpers/createSelectOptions';
import { CreateExperimentStore } from '../../../../../../mobx/stores';

import Styled from './CopyFieldForm.styles';

const CopyFieldForm: FC = observer(() => {
  const { selectedExp } = useStore(CreateExperimentStore);

  const { experimentId } = useParams<{ experimentId: string }>();
  const form = useForm<ICopyFieldForm>(copyFieldFormConfig.formKey);
  const { elements, registerForm, addOptionList, submitForm } = form;

  const currentExperimentId = useMemo(
    () => selectedExp?.id ?? experimentId,
    [selectedExp, experimentId]
  );

  const { activeCultureZone, cultureZones } = useStore(HistoryStore);
  const { copyNutritionHistory } = useStore(HistoryController);

  const { closeModal } = useModal();

  const { fieldId: FieldComponent } = elements;

  const fields = useMemo(() => {
    return createSelectOptionsInCultureZones(
      cultureZones.filter(
        item =>
          item.id !== activeCultureZone.id &&
          item.cultureZone.culture.id === activeCultureZone.cultureZone.culture.id &&
          item.previousCultures[0]?.id === activeCultureZone.previousCultures[0]?.id
      )
    );
  }, [cultureZones, activeCultureZone]);

  useEffect(() => {
    registerForm(copyFieldFormConfig);
    addOptionList('fieldId', fields);
  }, []);

  const handleSubmit = () => {
    submitForm(async forms => {
      await copyNutritionHistory(forms, activeCultureZone, currentExperimentId);
      closeModal();
    });
  };

  const successButton = useMemo(
    () => ({
      title: 'Скопировать',
      handler: handleSubmit,
    }),
    [handleSubmit]
  );

  const denyButton = useMemo(
    () => ({
      title: 'Отменить',
      handler: closeModal,
    }),
    []
  );

  return (
    <>
      <Styled.Wrapper>
        <Styled.Title>Скопировать с участка:</Styled.Title>
        <Styled.FieldBlock>{FieldComponent ? <FieldComponent /> : null}</Styled.FieldBlock>
        <Styled.Description>
          Все имеющиеся данные на текущем участке будут потеряны
        </Styled.Description>
      </Styled.Wrapper>
      <ModalFooter successButton={successButton} denyButton={denyButton} />
    </>
  );
});

CopyFieldForm.displayName = 'CopyFieldForm';

export default CopyFieldForm;
