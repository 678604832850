export const getValue = <T>(object: T, key: keyof T) => {
  if (!object) {
    return '—';
  }

  const value = object[key];

  if (Number.isFinite(value)) {
    if (Number.isInteger(value)) {
      return String(value);
    } else {
      // eslint-disable-next-line spaced-comment
      //@ts-ignore
      return value.toFixed(2);
    }
  }

  return '—';
};
