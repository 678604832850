import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { IExperiment } from '../../../../../../../../../../../../api/models/as-fields/experiments';

@provide.singleton()
class ExperimentsTableController {
  private _experimentsById: Map<string, IExperiment> | null = null;

  private _page = 0;
  private _totalPage = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get experimentList() {
    return this._experimentsById ? [...this._experimentsById.values()] : null;
  }

  get page() {
    return this._page;
  }

  get totalPage() {
    return this._totalPage;
  }

  setExperimentList = (experiments: IExperiment[]) => {
    const map = this._experimentsById
      ? new Map<string, IExperiment>([...this._experimentsById])
      : new Map<string, IExperiment>();
    experiments?.forEach(experiment => {
      map.set(experiment.id, experiment);
    });
    this._experimentsById = map;
  };

  setPage = (page: number) => {
    this._page = page;
  };

  setTotalPage = (totalPage: number) => {
    this._totalPage = totalPage;
  };

  clearExperimentList = () => {
    this._experimentsById = null;
  };

  clearPage = () => {
    this._page = 0;
  };

  clearTotalPage = () => {
    this._totalPage = 0;
  };

  clearStore = () => {
    this.clearExperimentList();
    this.clearPage();
    this.clearTotalPage();
  };
}

export default ExperimentsTableController;
