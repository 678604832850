import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TCopyHistoryNutritionHistoryReg = { donorId: string; recipientId: string };
type TCopyHistoryNutritionHistoryRes = {};

const copyHistoryNutritionHistory: TApiRoute & {
  request: TCopyHistoryNutritionHistoryReg;
  response: TCopyHistoryNutritionHistoryRes;
} = {
  url: ({ donorId, recipientId }) =>
    `/api/as-fields/cultureZoneExperiment/copyHistory/from/${donorId}/to/${recipientId}`,
  method: 'POST',
  request: {} as TCopyHistoryNutritionHistoryReg,
  response: {} as TCopyHistoryNutritionHistoryRes,
  headers: {},
};

export { copyHistoryNutritionHistory };
export type { TCopyHistoryNutritionHistoryReg, TCopyHistoryNutritionHistoryRes };
