import { useCallback } from 'react';

const getDataTestId = (main: string) => (secondary?: string) => ({
  'data-test-id': secondary ? `${main}-${secondary}` : `${main}`,
});

const useDataTestId = (dataTestId: string) => {
  const getSecondary = useCallback(getDataTestId(dataTestId || ''), [dataTestId]);

  return getSecondary;
};

export default useDataTestId;
