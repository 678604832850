import { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';

import { TableBuilderController as Controller } from '../../mobx/controllers';
import { useTableBuilderUIContext as useContext } from '../../context/UI/TableBuilderUIContext/hooks';
import { useTableBuilderDataTestId as useDataTestId } from '../../hooks';
import { useStore } from '../../../../utils/helpers/mobx';

import Styled from './TableBuilderGrid.styles';

const TableBuilderGrid: FC<PropsWithChildren<any>> = ({ children }) => {
  const controller = useStore(Controller);

  const context = useContext();

  const columnsWidth = controller.getColumnsWidth(context.builderId);

  const getDataTestId = useDataTestId(context.builderId);

  return (
    <Styled.Wrapper $columnsWidth={columnsWidth} {...getDataTestId('grid')}>
      {children}
    </Styled.Wrapper>
  );
};

TableBuilderGrid.displayName = 'TableBuilderGrid';

export default observer(TableBuilderGrid);
