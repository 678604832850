import styled, { css } from 'styled-components';

const InstancesNameWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const changeWidth = (width: number) => {
  if (width) {
    return css`
      width: ${width}px;
    `;
  }
};

const changeHeadColumnWidth = (width: string) => {
  if (width) {
    return css`
      grid-template-columns: ${width || '260px'} auto;
    `;
  }
};

const makeFixed = (isFixed: boolean, stickyTopIndent?: number) => {
  if (isFixed) {
    return css`
      top: ${stickyTopIndent ?? 0}px;
      position: fixed;
    `;
  }
};

const Wrapper = styled.div<{
  $width: number;
  $isFixed: boolean;
  $stickyTopIndent?: number;
  $headColumnWidth?: string;
  $headColumnPadding?: string;
}>`
  z-index: 99 !important;

  display: grid;

  background-color: white;
  padding: ${({ $headColumnPadding }) => ($headColumnPadding ? $headColumnPadding : '0 0 0 12px')};

  ${({ $width }) => changeWidth($width)};
  ${({ $isFixed, $stickyTopIndent }) => makeFixed($isFixed, $stickyTopIndent)};
  ${({ $headColumnWidth }) => changeHeadColumnWidth($headColumnWidth)};
`;

const StyledDynamicTableHead = {
  InstancesNameWrapper,
  Wrapper,
};

export default StyledDynamicTableHead;
