import { forwardRef } from 'react';
import { observer } from 'mobx-react';

import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { useStore } from '../../../../../utils/helpers/mobx';
import { TableBuilderStore as Store } from '../../../mobx/stores';
import { useTableBuilderUIContext as useContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { useSortBy } from '../../../../../utils/hooks/useSortBy';
import { TableBuilderRowContainer as RowContainer } from '../TableBuilderRowContainer';

import Styled from './TableBuilderRowsContainer.styles';

interface IProps {
  rowsGroupId: string;
  rowIdList: string[];
  borderType: string;
  scrollPadding?: string;
  isHideScroll?: boolean;
}

const TableBuilderRowsContainer = forwardRef<HTMLDivElement, IProps>(
  ({ rowsGroupId, borderType, scrollPadding, isHideScroll }, ref) => {
    const store = useStore(Store);

    const context = useContext();

    const rowList = store.getRowList(context.builderId, { byRowsGroupId: rowsGroupId });
    const orderedRowList = useSortBy(rowList);

    const getDataTestId = useDataTestId('rows-container');

    return isHideScroll ? (
      <Styled.Wrapper ref={ref} {...getDataTestId()} $borderType={borderType}>
        {orderedRowList.map(row => (
          <RowContainer key={row.id} row={row} borderType={borderType} />
        ))}
      </Styled.Wrapper>
    ) : (
      <Styled.ScrollWrapper
        ref={ref}
        {...getDataTestId()}
        $borderType={borderType}
        $scrollPadding={scrollPadding}
      >
        {orderedRowList.map(row => (
          <RowContainer key={row.id} row={row} borderType={borderType} />
        ))}
      </Styled.ScrollWrapper>
    );
  }
);

TableBuilderRowsContainer.displayName = 'TableBuilderRowsContainer';

export default observer(TableBuilderRowsContainer);
