import { observer } from 'mobx-react';
import { FC, PropsWithChildren, UIEvent, memo, useEffect, useCallback, useRef } from 'react';
import { useSidebar } from '@farmlink/farmik-ui';

import { Header } from '../Header';

import Styled from './FullScreen.styles';
import { TFullscreenProps } from './FullScreen.types';

const Fullscreen: FC<PropsWithChildren<TFullscreenProps>> = observer(
  ({
    children,
    backButtonHandler,
    backButtonText,
    goBackText,
    headerContent,
    marginBottom,
    getScrollData,
    titleContent,
    isHideAdditionalContent,
    backButtonContent,
  }) => {
    const { setSidebarVisibility } = useSidebar();

    const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      setSidebarVisibility(false);

      return () => {
        setSidebarVisibility(true);
      };
    }, []);

    const handleScroll = useCallback(
      (e: UIEvent<HTMLDivElement>) => {
        e.preventDefault();

        getScrollData?.(scrollableContainerRef.current.scrollTop);
      },
      [scrollableContainerRef.current, getScrollData]
    );

    return (
      <Styled.FullscreenWrapper data-test-id="fullscreen-wrapper">
        <Header
          title={backButtonText}
          backButtonText={goBackText}
          onClickBackButton={backButtonHandler}
          leftContent={titleContent}
          rightContent={headerContent}
          isHideAdditionalContent={isHideAdditionalContent}
          backButtonContent={backButtonContent}
        />
        <Styled.FullscreenFooter
          ref={scrollableContainerRef}
          onScroll={handleScroll}
          id="scrollable-fullscreen"
          data-test-id="fullscreen-footer"
        >
          <Styled.Fullscreen $marginBottom={marginBottom} data-test-id="fullscreen">
            {children}
          </Styled.Fullscreen>
        </Styled.FullscreenFooter>
      </Styled.FullscreenWrapper>
    );
  }
);

export default memo(Fullscreen);
