import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ChangeCultureZoneName } from './components';

const changeCultureZoneNameModalConfig: TModalConfig = {
  id: 'changeCultureZoneName',
  name: 'changeCultureZoneName',
  title: '',
  type: EModalType.Custom,
  dataTestId: 'change-culture-zone-name-modal',
  children: ChangeCultureZoneName,
  styledProps: {
    $modalPaddings: '40px 60px',
    $size: EModalSize.Medium,
  },
};

export default changeCultureZoneNameModalConfig;
