import { FC, memo } from 'react';
import { AutoTooltip, Typography } from '@farmlink/farmik-ui';

import { ITask } from '../../../../../../../../../../../../../api/models/as-fields/task/Task';
import { useDataTestIdV2 } from '../../../../../../../../../../../../common/utils/hooks/locators';
import warningIcon from '../../../../../../../../../../../../common/assets/icons/warning-simple.svg';

import Styled from './PointsCell.styles';

interface IProps {
  row: ITask;
}

const PointsCell: FC<IProps> = ({ row }) => {
  const { pointOutOfField, completedInstances } = row;

  const getDataTestId = useDataTestIdV2('tasks-table-point-cell');

  if (!completedInstances) {
    return (
      <Styled.Wrapper {...getDataTestId('wrapper')}>
        <Typography align={'center'}>0</Typography>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      {pointOutOfField && (
        <Styled.IconWrapper>
          <AutoTooltip
            content={'Точка находится вне границ поля.\nОбратитесь к руководству'}
            position={'bottom'}
          >
            <Styled.Icon $url={warningIcon} {...getDataTestId('warning-icon')} />
          </AutoTooltip>
        </Styled.IconWrapper>
      )}

      <Typography {...getDataTestId('name')}>{completedInstances}</Typography>
    </Styled.Wrapper>
  );
};

PointsCell.displayName = 'TasksListPointsCell';

export default memo(PointsCell);
