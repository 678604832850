import { observer } from 'mobx-react';
import { FC } from 'react';

import { ExecutionDeviationsCell } from '../../../../../components/cells';
import { useStore } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionController } from '../../../../../mobx/controllers';

interface IProps {
  builderId: string;
  rowId: string;
  dataTestId: string;
}

const ExecutionCalculationsDeviationsCell: FC<IProps> = ({ builderId, rowId, dataTestId }) => {
  const executionController = useStore(ExecutionController);

  const formattedValue = executionController.getCalculationsDeviationsValue(builderId, rowId);

  return <ExecutionDeviationsCell {...formattedValue} dataTestId={dataTestId} />;
};

ExecutionCalculationsDeviationsCell.displayName = 'ExecutionCalculationsDeviationsCell';

export default observer(ExecutionCalculationsDeviationsCell);
