import { FC, memo } from 'react';
import { useDataTestId } from '@farmlink/farmik-ui';

import Styled from './ExecutionTableColumn.styles';

interface IProps {
  name: string;
  dataTestId: string;
}

const ExecutionTableColumn: FC<IProps> = ({ name, dataTestId }) => {
  const getDataTestId = useDataTestId(dataTestId);

  return <Styled.Wrapper {...getDataTestId()}>{name}</Styled.Wrapper>;
};

ExecutionTableColumn.displayName = 'ExecutionTableColumn';

export default memo(ExecutionTableColumn);
