import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { ExperimentsService } from '../../services';
import { ExperimentsStore } from '../../stores';
import { ExperimentService } from '../../../../../../../../common/mobx/services/as-fields';
import { TChangeExperimentReq } from '../../../../../../../../../api';
import { IExperimentsFilters } from '../../../../ExperimentsFilters/models';

@provide.singleton()
class ExperimentController {
  @lazyInject(ExperimentsStore)
  protected experimentsStore: ExperimentsStore;

  @lazyInject(ExperimentService)
  experimentService: ExperimentService;

  @lazyInject(ExperimentsService)
  protected experimentsService: ExperimentsService;

  changeExperiment = async (payload: TChangeExperimentReq): Promise<boolean> => {
    const { changeExperiment } = this.experimentService;

    const changedExperiment = await changeExperiment(payload);

    if (changedExperiment) {
      return true;
    }
  };

  fetchExperimentsList = async (
    appliedFilters: IExperimentsFilters,
    needToShowLoader = true,
    isNeedToSavePagination?: boolean
  ): Promise<any> => {
    const {
      setTotalPageNumber,
      setCurrentPageNumber,
      setExperimentsList,
      currentPageNumber,
      experimentsList,
      setIsLoading,
    } = this.experimentsStore;
    const { fetchExperimentsList } = this.experimentsService;

    if (needToShowLoader) {
      setIsLoading(true);
    }

    if (!isNeedToSavePagination) {
      setTotalPageNumber(0);
      setCurrentPageNumber(0);
    }

    const payload = {
      page: currentPageNumber,
      size: 25,
      ...appliedFilters,
    };

    const response = await fetchExperimentsList(payload);

    if (response?.content) {
      setExperimentsList(
        isNeedToSavePagination
          ? [...(experimentsList || []), ...response.content]
          : response.content
      );

      setTotalPageNumber(response?.totalPages ?? 0);
      setIsLoading(false);

      return response;
    }

    setIsLoading(false);
  };

  fetchCultureList = async (
    searchQuery: string,
    currentPageNumber?: number
  ): Promise<{
    newCultureList: { value: string; label: string }[];
    totalPages: number;
  }> => {
    const { fetchCultureList } = this.experimentsService;

    const { content, totalPages } = await fetchCultureList(searchQuery, currentPageNumber);

    if (content) {
      const newCultureList = content.map(culture => {
        return {
          value: culture.id,
          label: culture.name,
        };
      });

      return { newCultureList, totalPages };
    }
  };

  fetchCultures = async (searchQuery: string) => {
    const { setFilterPageNumbers, setCultureList } = this.experimentsStore;

    const { newCultureList, totalPages } = await this.fetchCultureList(searchQuery, 0);

    if (newCultureList) {
      setCultureList(newCultureList);
      setFilterPageNumbers('cultureCurrentPageNumber', 0);
      setFilterPageNumbers('cultureTotalPageNumber', totalPages);
    }
  };

  onCultureListScroll = async (searchQuery: string) => {
    const { setCultureList, cultureList, filterPageNumbers } = this.experimentsStore;

    const { newCultureList } = await this.fetchCultureList(
      searchQuery,
      filterPageNumbers.cultureCurrentPageNumber
    );

    if (newCultureList) {
      setCultureList([...cultureList, ...newCultureList]);
    }
  };

  fetchRegionList = async (
    searchQuery: string,
    currentPageNumber?: number
  ): Promise<{
    newRegionList: { value: string; label: string; type: string }[];
    totalPages: number;
  }> => {
    const { fetchRegionList } = this.experimentsService;

    const { content, totalPages } = await fetchRegionList(searchQuery, currentPageNumber);

    if (content) {
      const newRegionList = content.map(region => {
        return {
          value: region.id,
          label: region.description,
          type: 'regionId',
        };
      });

      return { newRegionList, totalPages };
    }
  };

  fetchRegions = async (searchQuery: string) => {
    const { setFilterPageNumbers, setRegionList } = this.experimentsStore;

    const { newRegionList, totalPages } = await this.fetchRegionList(searchQuery, 0);

    if (newRegionList) {
      setRegionList(newRegionList);
      setFilterPageNumbers('regionCurrentPageNumber', 0);
      setFilterPageNumbers('regionTotalPageNumber', totalPages);
    }
  };

  onRegionListScroll = async (searchQuery: string) => {
    const { setRegionList, regionList, filterPageNumbers } = this.experimentsStore;

    const { newRegionList } = await this.fetchRegionList(
      searchQuery,
      filterPageNumbers.regionCurrentPageNumber
    );

    if (newRegionList) {
      setRegionList([...regionList, ...newRegionList]);
    }
  };

  fetchOrganizationList = async (
    searchQuery: string,
    currentPageNumber?: number
  ): Promise<{
    newOrganizationList: { value: string; label: string; type: string }[];
    totalPages: number;
  }> => {
    const { fetchOrganizationList } = this.experimentsService;

    const { content, totalPages } = await fetchOrganizationList(searchQuery, currentPageNumber);

    if (content) {
      const newOrganizationList = content.map(organization => {
        return {
          value: organization.organizationId,
          label: organization.name,
          type: 'organizationId',
        };
      });

      return { newOrganizationList, totalPages };
    }
  };

  fetchOrganizations = async (searchQuery: string) => {
    const { setOrganizationList, setFilterPageNumbers } = this.experimentsStore;

    const { newOrganizationList, totalPages } = await this.fetchOrganizationList(searchQuery, 0);

    if (newOrganizationList) {
      setOrganizationList(newOrganizationList);
      setFilterPageNumbers('organizationCurrentPageNumber', 0);
      setFilterPageNumbers('organizationTotalPageNumber', totalPages);
    }
  };

  onOrganizationListScroll = async (searchQuery: string) => {
    const { setOrganizationList, organizationList, filterPageNumbers } = this.experimentsStore;

    const { newOrganizationList } = await this.fetchOrganizationList(
      searchQuery,
      filterPageNumbers.organizationCurrentPageNumber
    );

    if (newOrganizationList) {
      setOrganizationList([...organizationList, ...newOrganizationList]);
    }
  };

  fetchExperimentUserList = async (
    searchQuery: string,
    currentPageNumber?: number
  ): Promise<{
    newExperimentUserList: { value: string; label: string; type: string }[];
    totalPages: number;
  }> => {
    const { fetchAllExperimentUsersList } = this.experimentsService;

    const { content, totalPages } = await fetchAllExperimentUsersList(
      searchQuery,
      currentPageNumber
    );

    if (content) {
      const newExperimentUserList = content.map(user => {
        return {
          value: user.id,
          label: user.fullName,
          type: 'assigneeId',
        };
      });

      return { newExperimentUserList, totalPages };
    }
  };

  fetchExperimentUsers = async (searchQuery: string) => {
    const { setExperimentUsersList, setFilterPageNumbers } = this.experimentsStore;

    const { newExperimentUserList, totalPages } = await this.fetchExperimentUserList(
      searchQuery,
      0
    );

    if (newExperimentUserList) {
      setExperimentUsersList(newExperimentUserList);
      setFilterPageNumbers('responsibleCurrentPageNumber', 0);
      setFilterPageNumbers('responsibleTotalPageNumber', totalPages);
    }
  };

  onExperimentUserListScroll = async (searchQuery: string) => {
    const { setExperimentUsersList, experimentUsersList, filterPageNumbers } =
      this.experimentsStore;

    const { newExperimentUserList } = await this.fetchExperimentUserList(
      searchQuery,
      filterPageNumbers.organizationCurrentPageNumber
    );

    if (newExperimentUserList) {
      setExperimentUsersList([...experimentUsersList, ...newExperimentUserList]);
    }
  };

  fetchFD = async (): Promise<any> => {
    const { setFDList } = this.experimentsStore;
    const { fetchFD } = this.experimentsService;

    const response = await fetchFD();

    if (response?.content) {
      const FDList = response.content.map(user => {
        return {
          value: user.id,
          label: user.name,
          type: 'districtId',
        };
      });

      setFDList(FDList);

      return response;
    }
  };

  changePage = (): void => {
    const { currentPageNumber, setCurrentPageNumber } = this.experimentsStore;

    setCurrentPageNumber(currentPageNumber + 1);
  };

  changeFilterPageNumber = (filterName: string): void => {
    const { setFilterPageNumbers, filterPageNumbers } = this.experimentsStore;

    setFilterPageNumbers(filterName, filterPageNumbers[filterName] + 1);
  };
}

export default ExperimentController;
