import { FC, MouseEvent, useCallback } from 'react';
import { AutoTooltip, useDataTestId, useShowTooltip } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionController } from '../../mobx/controllers';
import { ExecutionTableDeleteIcon } from '../ExecutionTableDeleteIcon';
import { useWarningBeforeDeleting } from '../../../../../../../../../common/utils/hooks';
import { IExperimentFactTableRowsGroupOptions } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';

import Styled from './ExecutionTableTitleRow.styles';

interface IProps {
  rowsGroupId: string;
  name: string;
  stepId: string;
  rowsGroupOptions: IExperimentFactTableRowsGroupOptions;
  dataTestId: string;
}

const ExecutionTableTitleRow: FC<IProps> = ({
  rowsGroupId,
  name,
  stepId,
  rowsGroupOptions,
  dataTestId,
}) => {
  const executionController = useStore(ExecutionController);

  const warningBeforeDeletingActions = useWarningBeforeDeleting();

  const { ref: titleRef, isShowTooltip: isShowTitleTooltip } = useShowTooltip<HTMLDivElement>();

  const handleDeleteIconClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      warningBeforeDeletingActions.showWarningBeforeDeleting(`ТМЦ: «${name}»`, () => {
        if (rowsGroupOptions.isFertilizer) {
          executionController.deleteNutritionHistoryItem(
            rowsGroupId,
            stepId,
            rowsGroupOptions.fertilizerId
          );
        } else if (rowsGroupOptions.isProtection) {
          executionController.deleteNutritionHistoryProtectionItem(
            rowsGroupId,
            stepId,
            rowsGroupOptions.protectionId
          );
        }
      });
    },
    [
      rowsGroupOptions.isFertilizer,
      rowsGroupOptions.isProtection,
      rowsGroupOptions.fertilizerId,
      rowsGroupOptions.protectionId,
    ]
  );

  const getDataTestId = useDataTestId(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <AutoTooltip content={name} disabled={!isShowTitleTooltip}>
        <Styled.Title ref={titleRef} {...getDataTestId('title')}>
          {name}
        </Styled.Title>
      </AutoTooltip>

      {rowsGroupOptions.deletable ? (
        <ExecutionTableDeleteIcon
          onClick={handleDeleteIconClick}
          dataTestId={getDataTestId('delete-button')['data-test-id']}
        />
      ) : null}
    </Styled.Wrapper>
  );
};

ExecutionTableTitleRow.displayName = 'ExecutionTableTitleRow';

export default observer(ExecutionTableTitleRow);
