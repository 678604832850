import {
  EExperimentStatus,
  EExperimentStepStatus,
} from '../../../../../api/models/as-fields/experiments';
import { EExperimentApprovalStatus } from '../../../../../api/models/as-fields/experiments/Experiment/Experiment.model';

const getChangeStatusMethod = (
  previousStatus: EExperimentStatus | EExperimentStepStatus,
  currentStatus: EExperimentStatus | EExperimentStepStatus
) => {
  if (
    previousStatus === EExperimentStatus.Approved &&
    currentStatus === EExperimentStatus.Canceled
  ) {
    return EExperimentApprovalStatus.approvedToCanceled;
  }
  if (
    previousStatus === EExperimentStatus.Approved &&
    currentStatus === EExperimentStatus.InProgress
  ) {
    return EExperimentApprovalStatus.approvedToInProgress;
  }
  if (
    previousStatus === EExperimentStatus.Approved &&
    currentStatus === EExperimentStatus.InApproval
  ) {
    return EExperimentApprovalStatus.approvedToInApproval;
  }
  if (
    previousStatus === EExperimentStatus.Canceled &&
    currentStatus === EExperimentStatus.Approved
  ) {
    return EExperimentApprovalStatus.canceledToApproved;
  }
  if (
    previousStatus === EExperimentStatus.Canceled &&
    currentStatus === EExperimentStatus.InProgress
  ) {
    return EExperimentApprovalStatus.canceledToInProgress;
  }
  if (
    previousStatus === EExperimentStatus.Completed &&
    currentStatus === EExperimentStatus.InProgress
  ) {
    return EExperimentApprovalStatus.completedToInProgress;
  }
  if (
    previousStatus === EExperimentStatus.Draft &&
    currentStatus === EExperimentStatus.InApproval
  ) {
    return EExperimentApprovalStatus.draftToInApproval;
  }
  if (
    previousStatus === EExperimentStatus.Draft &&
    currentStatus === EExperimentStatus.InProgress
  ) {
    return EExperimentApprovalStatus.draftToInProgress;
  }
  if (
    previousStatus === EExperimentStatus.InApproval &&
    currentStatus === EExperimentStatus.Approved
  ) {
    return EExperimentApprovalStatus.inApprovalToApproved;
  }
  if (
    previousStatus === EExperimentStatus.InApproval &&
    currentStatus === EExperimentStatus.Draft
  ) {
    return EExperimentApprovalStatus.inApprovalToDraft;
  }
  if (
    previousStatus === EExperimentStatus.InProgress &&
    currentStatus === EExperimentStatus.Draft
  ) {
    return EExperimentApprovalStatus.inProgressToDraft;
  }
  if (
    previousStatus === EExperimentStatus.InProgress &&
    currentStatus === EExperimentStatus.Completed
  ) {
    return EExperimentApprovalStatus.inProgressToCompleted;
  }
  if (
    previousStatus === EExperimentStatus.InProgress &&
    currentStatus === EExperimentStatus.Canceled
  ) {
    return EExperimentApprovalStatus.inProgressToCanceled;
  }
  if (
    previousStatus === EExperimentStatus.Completed &&
    currentStatus === EExperimentStatus.FactAppruved
  ) {
    return EExperimentApprovalStatus.completedToFactApproved;
  }
  if (
    previousStatus === EExperimentStatus.FactAppruved &&
    currentStatus === EExperimentStatus.Completed
  ) {
    return EExperimentApprovalStatus.factApprovedToCompleted;
  }
  if (
    previousStatus === EExperimentStatus.FactAppruved &&
    currentStatus === EExperimentStatus.FullCompleted
  ) {
    return EExperimentApprovalStatus.factApprovedToFullCompleted;
  }
  if (
    previousStatus === EExperimentStatus.FullCompleted &&
    currentStatus === EExperimentStatus.FactAppruved
  ) {
    return EExperimentApprovalStatus.fullCompletedToFactApproved;
  }

  return null;
};

export { getChangeStatusMethod };
