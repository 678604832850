import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;

  & span:first-child {
    padding-top: 52px;
  }
`;

const Item = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: -52px;
  letter-spacing: -0.15px;
`;

const StyledDateCellComponent = {
  Wrapper,
  Item,
};

export default StyledDateCellComponent;
