import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const createConfig = (entity: string): TModalConfig => {
  return {
    id: `warningBeforeDeleting`,
    name: `warningBeforeDeleting`,
    title: `Вы уверены, что хотите удалить ${entity}?`,
    type: EModalType.Warning,
    dataTestId: 'warning-before-deleting',
    denyButton: {
      title: 'Не удалять',
      dataTestId: 'warning-before-deleting-deny-button',
    },
    successButton: {
      title: 'Удалить',
      dataTestId: 'warning-before-deleting-success-button',
      color: 'secondary',
    },
  };
};

const createWarningBeforeDeleting = (
  entity: string,
  successHandler: () => void
): { key: string; name: string; configList: TModalConfig[] } => {
  const config = createConfig(entity);

  return {
    key: config.name,
    name: config.name,
    configList: [
      {
        ...config,
        successButton: { ...config.successButton, handler: successHandler },
      },
    ],
  };
};

export default createWarningBeforeDeleting;
