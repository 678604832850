import { FC, memo } from 'react';
import { observer } from 'mobx-react';

import { Stage } from '../../components';
import { useStore } from '../../../../utils/helpers/mobx';
import { DynamicTableStore } from '../../mobx/stores';

interface IStageContainerProps {
  tableId: string;
  id: string;
  cellPadding: string;
}

const StageContainer: FC<IStageContainerProps> = ({ tableId, id, cellPadding }) => {
  const { getStage } = useStore(DynamicTableStore);

  const stage = getStage(tableId, id);

  return <>{stage ? <Stage stage={stage} cellPadding={cellPadding} /> : null}</>;
};

StageContainer.displayName = 'StageContainer';

export default memo(observer(StageContainer));
