import { TGetAxiosUrl } from './getAxiosUrl.helper.types';

const getAxiosUrl: TGetAxiosUrl = ({ url, path, query }) => {
  if (path) {
    return `${url}/${path}`;
  }

  if (query) {
    const queryString: string = Object.entries(query)
      .map<string>(
        ([key, value]) => `${key}=${typeof value === 'string' ? value : JSON.stringify(value)}`
      )
      .join('&');

    return `${url}?${queryString}`;
  }

  return url;
};

export default getAxiosUrl;
