import { FC, memo } from 'react';

import { _Stage } from '../../models';
import { AttributeContainer, StageContainer } from '../../containers';
import formatPaddingForColumn from '../../helpers/formatPaddingForColumn';

import Styled from './Stage.styles';

interface IStageProps {
  stage: _Stage;
  cellPadding: string;
}

const Stage: FC<IStageProps> = ({ stage, cellPadding }) => {
  const { tableId, rootAttributeId, name, isNested, attributeIds, nestedStageIds, Component } =
    stage;

  if (Component) {
    const AttributeContainerList = attributeIds.map(attributeId => (
      <AttributeContainer
        key={attributeId}
        tableId={tableId}
        id={attributeId}
        cellPadding={cellPadding}
      />
    ));

    return <Component extendedStage={stage} attributeContainerList={AttributeContainerList} />;
  }

  return (
    <Styled.Wrapper $isNested={isNested || Boolean(rootAttributeId)}>
      {name ? (
        <Styled.Header $cellPadding={formatPaddingForColumn(cellPadding)}>
          <Styled.Name>{name}</Styled.Name>
        </Styled.Header>
      ) : null}

      <Styled.Body>
        {nestedStageIds?.map(nestedStageId => (
          <StageContainer
            key={nestedStageId}
            tableId={tableId}
            id={nestedStageId}
            cellPadding={cellPadding}
          />
        ))}

        {attributeIds.map(attributeId => (
          <AttributeContainer
            key={attributeId}
            tableId={tableId}
            id={attributeId}
            cellPadding={cellPadding}
          />
        ))}
      </Styled.Body>
    </Styled.Wrapper>
  );
};

Stage.displayName = 'Stage';

export default memo(Stage);
