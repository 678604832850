import { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useContextualHelpActions } from '@farmlink/farmik-ui';
import { debounce } from 'lodash';

import { FullScreen } from '../../../../../../../../../common/components/ui';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../../../mobx/stores';
import { BackButtonContent, HeaderContent } from '../../components';
import { CREATE_EXPERIMENT_TABS, CREATE_EXPERIMENT_PATH_KEY } from '../../../../constants';
import { NavigationTabs } from '../../../../../../../../../common/features/NavigationTabs';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';
import { ContentLoader } from '../../../../../../../../../common/features/UI/loaders/ContentLoader';
import { PageContentContext } from '../../context';

const useCreateExperimentInfo = () => {
  const { clearStore, selectedExp, tableHeaderInfo, setTableHeaderInfo, isLoadingExperimentInfo } =
    useStore(CreateExperimentStore);

  const navigate = useNavigate();

  const [pageScrollHeight, setPageScrollHeight] = useState(0);

  usePageContextualHelp(EContextualParentPath.ExperimentsExperiment);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.ExperimentsExperiment,
    ContextualPaths.ExperimentsExperimentHeaderTitle
  );

  const backButtonHandler = useCallback(() => {
    navigate('/experiments');
  }, []);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  const onScrollHeightChange = scrollHeight => {
    setPageScrollHeight(scrollHeight);
  };

  const debounceOnScrollHeightChange = useRef(debounce(onScrollHeightChange, 500));

  const handleGetScrollData = useCallback(
    (scrollTop: number) => {
      debounceOnScrollHeightChange.current(scrollTop);

      if (tableHeaderInfo.ref) {
        const isHeaderStuck =
          tableHeaderInfo.ref.current.getBoundingClientRect().top === tableHeaderInfo.stuckPosition;

        if (isHeaderStuck) {
          setTableHeaderInfo({ isStuck: true });
        } else {
          setTableHeaderInfo({ isStuck: false });
        }
      }
    },
    [tableHeaderInfo]
  );

  const isShowBackButtonContent = Boolean(selectedExp?.name);

  return (
    <PageContentContext.Provider value={{ pageScrollHeight }}>
      <FullScreen
        backButtonText={'Создание опыта'}
        backButtonHandler={backButtonHandler}
        data-test-id="create-exreriment-fullscreen"
        getScrollData={handleGetScrollData}
        headerContent={<HeaderContent />}
        backButtonContent={
          isLoadingExperimentInfo ? (
            <ContentLoader
              overlayStyles={{
                position: 'relative',
                height: '50px',
                width: '100%',
                borderRadius: '16px',
              }}
              dataTestId={'content-loader'}
            />
          ) : isShowBackButtonContent ? (
            <BackButtonContent selectedExp={selectedExp} ContextualHelpIcon={ContextualHelpIcon} />
          ) : null
        }
        goBackText={'К списку опытов'}
        marginBottom={0}
      >
        <NavigationTabs
          tabs={CREATE_EXPERIMENT_TABS}
          pathKey={CREATE_EXPERIMENT_PATH_KEY}
          dataTestId="create-experiment"
        />

        <Outlet />
      </FullScreen>
    </PageContentContext.Provider>
  );
};

export default useCreateExperimentInfo;
