import { FC, useRef } from 'react';
import { ENotificationType, ENotificatorTheme, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { TFileEntities } from '../../../../../../api/models/as-fields/attachedFiles';
import { Plug } from '../../../../components/ui';
import { useFileUpload } from '../../../../hooks/useFileUpload';
import { useStore } from '../../../../utils/helpers/mobx';
import { DocumentsTableController } from '../../mobx/controllers';
import iconFolder from '../../../../assets/icons/folder.svg';

import Styled from './DocumentsNoDataPlug.styles';

type TDocumentsNoDataPlugProps = {
  isShowButton: boolean;
  id: string;
  entity: TFileEntities;
};

export const DocumentsNoDataPlug: FC<TDocumentsNoDataPlugProps> = observer(
  ({ isShowButton, id, entity }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { handleFileChange } = useFileUpload(inputRef, { maxSize: 1e8 });

    const { addAttachedFile } = useStore(DocumentsTableController);

    const { setNotification } = useNotificator();

    const addFileToExperiment = (fileId: string) => {
      addAttachedFile(id, fileId, entity);
    };

    const uploadNewDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFileChange(e)
        .then(file => {
          addFileToExperiment(file.id);
          setNotification({
            message: 'Документ успешно загружен',
            style: {
              placement: 'top-center',
              type: ENotificationType.Success,
              theme: ENotificatorTheme.Dark,
            },
          });
        })
        .catch((err: Error) => {
          setNotification({
            message: err.message,
            style: {
              placement: 'top-center',
              type: ENotificationType.Warning,
              theme: ENotificatorTheme.Dark,
            },
          });
        });
    };

    const handleUploadClick = () => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.value = null;
      inputRef.current?.click();
    };

    return (
      <Styled.Wrapper>
        <Plug
          icon={iconFolder}
          title="Загрузите документы по опыту"
          description={
            'Добавить можно любые документы, которые относятся к опыту, например, подписанный контрагентом отчёт. \n' +
            'Загруженные файлы сможет просматривать консультант или руководитель.'
          }
          primaryButtonTitle="Загрузить"
          onPrimaryButtonClick={handleUploadClick}
          buttonExist={isShowButton}
        />
        <Styled.FileInput type="file" ref={inputRef} onChange={uploadNewDocument} />
      </Styled.Wrapper>
    );
  }
);
