import { EExperimentStepStatus } from '../../../../../api/models/as-fields/experiments';
import { IExperimentChangeStatusAvailability } from '../../../../../api/models/as-fields/experiments/Experiment';

import { experimentStatusMap } from './statuses.config';

const getAvailableExperimentStepStatus = (
  currentStatus: EExperimentStepStatus,
  availabilityScheme?: IExperimentChangeStatusAvailability
) => {
  switch (currentStatus) {
    case EExperimentStepStatus.Draft:
      return [
        ...(availabilityScheme?.canDraftToInApproval
          ? [experimentStatusMap.get(EExperimentStepStatus.InApproval)]
          : []),
      ];

    case EExperimentStepStatus.InApproval:
      return [
        ...(availabilityScheme?.canInApprovalToApproved
          ? [experimentStatusMap.get(EExperimentStepStatus.Approved)]
          : []),
        ...(availabilityScheme?.canInApprovalToDraft
          ? [experimentStatusMap.get(EExperimentStepStatus.Draft)]
          : []),
      ];

    case EExperimentStepStatus.Approved:
      return [
        ...(availabilityScheme?.canApprovedToInApproval
          ? [experimentStatusMap.get(EExperimentStepStatus.InApproval)]
          : []),
      ];

    default:
      return Array.from(experimentStatusMap.values());
  }
};

export { getAvailableExperimentStepStatus };
