import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../../models/common/response';
import { IExperimentContract } from '../../../../models/as-fields/experiments';

type TGetExperimentContractListReq = {
  name?: string;
  organizationId?: string;
  responsibleCustomerId?: string;
  responsibleContractorId?: string;
  seasonYear?: number;
  startDateFrom?: string;
  startDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  size?: number;
  page?: number;
};

type TGetExperimentContractListRes = TResponseList<IExperimentContract>;

const getExperimentContractList: TApiRoute & {
  request: TGetExperimentContractListReq;
  response: TGetExperimentContractListRes;
} = {
  url: () => `/api/as-fields/experiment/contract/list`,
  method: 'GET',
  request: {} as TGetExperimentContractListReq,
  response: {} as TGetExperimentContractListRes,
  headers: {},
};

export type { TGetExperimentContractListReq, TGetExperimentContractListRes };

export { getExperimentContractList };
