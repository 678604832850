import {
  EExperimentCommentTypeEvent,
  IExperimentComment,
} from '../../../../../../../../../api/models/as-fields/experiments/ExperimentComment';
import { EMessageStyleType } from '../../../../../../../../common/features/ChatJournal/models/common';

const getMessageStyle = (event: IExperimentComment<EExperimentCommentTypeEvent>) => {
  switch (event.typeEvent) {
    case EExperimentCommentTypeEvent.APPROVED_TO_CANCELED:
    case EExperimentCommentTypeEvent.IN_PROGRESS_TO_CANCELED:
      return EMessageStyleType.Error;

    case EExperimentCommentTypeEvent.APPROVED_TO_IN_APPROVAL:
    case EExperimentCommentTypeEvent.DRAFT_TO_IN_APPROVAL:
      return EMessageStyleType.Warning;

    case EExperimentCommentTypeEvent.APPROVED_TO_IN_PROGRESS:
    case EExperimentCommentTypeEvent.CANCELED_TO_IN_PROGRESS:
    case EExperimentCommentTypeEvent.COMPLETED_TO_IN_PROGRESS:
    case EExperimentCommentTypeEvent.DRAFT_TO_IN_PROGRESS:
      return EMessageStyleType.Success;

    case EExperimentCommentTypeEvent.FACT_APPROVED_TO_FULL_COMPLETED:
      return EMessageStyleType.FullSuccess;

    case EExperimentCommentTypeEvent.CANCELED_TO_APPROVED:
    case EExperimentCommentTypeEvent.IN_APPROVAL_TO_APPROVED:
    case EExperimentCommentTypeEvent.FULL_COMPLETED_TO_FACT_APPROVED:
    case EExperimentCommentTypeEvent.COMPLETED_TO_FACT_APPROVED:
      return EMessageStyleType.Info;

    case EExperimentCommentTypeEvent.IN_APPROVAL_TO_DRAFT:
    case EExperimentCommentTypeEvent.IN_PROGRESS_TO_DRAFT:
      return EMessageStyleType.Draft;

    case EExperimentCommentTypeEvent.IN_PROGRESS_TO_COMPLETED:
      return EMessageStyleType.Default;

    default:
      return EMessageStyleType.Default;
  }
};

export { getMessageStyle };
