import { useParams } from 'react-router-dom';

import { TExperimentsParams } from '../../models/ExperimentsParams/ExperimentsParams.model';

const useExperimentsParams = (): ReturnType<typeof useParams<TExperimentsParams>> => {
  const params = useParams<TExperimentsParams>();

  return params;
};

export default useExperimentsParams;
