import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { EContractsFiltersBuilderId } from '../../constants/configs';
import { useStore } from '../../../../../common/utils/helpers/mobx';
import { ECreateEditContractRoute } from '../../modules/CreateEditContract/routes';
import { ContractsListController } from '../ContractsList/mobx/controllers';
import { useTableFilters } from '../../../../../common/features/TableFiltersBuilder/hooks';
import TableFiltersBuilder from '../../../../../common/features/TableFiltersBuilder/TableFiltersBuilder';

import { ContractsFiltersController } from './mobx/controllers';

const ContractsFilters: FC = () => {
  const contractsListController = useStore(ContractsListController);
  const contractsFiltersController = useStore(ContractsFiltersController);

  const navigate = useNavigate();

  const handleActionButtonClick = useCallback(() => {
    navigate(ECreateEditContractRoute.Create);
  }, []);

  useEffect(() => {
    contractsFiltersController.initiateConfig(handleActionButtonClick);
  }, []);

  useEffect(() => {
    contractsFiltersController.addOptionList();
  }, []);

  useTableFilters(EContractsFiltersBuilderId.Contracts, () =>
    contractsListController.fetchContractList()
  );

  return <TableFiltersBuilder builderId={EContractsFiltersBuilderId.Contracts} />;
};

ContractsFilters.displayName = 'ContractsFilters';

export default observer(ContractsFilters);
