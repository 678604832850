import {
  EExperimentCommentTypeEvent,
  IExperimentComment,
} from '../../../../models/as-fields/experiments/ExperimentComment';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TPostExperimentCommentsReq = {
  id: string;
  comment: string;
};

type TPostExperimentCommentsRes = IExperimentComment<EExperimentCommentTypeEvent>[];

const postExperimentComment: TApiRoute & {
  request: TPostExperimentCommentsReq;
  response: TPostExperimentCommentsRes;
} = {
  url: ({ id }) => `/api/as-fields/experiment/${id}/comment`,
  method: 'POST',
  request: {} as TPostExperimentCommentsReq,
  response: {} as TPostExperimentCommentsRes,
  headers: {},
};

export type { TPostExperimentCommentsReq, TPostExperimentCommentsRes };

export { postExperimentComment };
