import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
`;

const StyledActiveSubstancesColumn = {
  Wrapper,
};

export default StyledActiveSubstancesColumn;
