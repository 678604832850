import {
  EExperimentCommentTypeEvent,
  IExperimentComment,
} from '../../../../models/as-fields/experiments/ExperimentComment';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetExperimentCommentsReq = {
  id: string;
  typeEvent?: EExperimentCommentTypeEvent;
};

type TGetExperimentCommentsRes = IExperimentComment<EExperimentCommentTypeEvent>[];

const getExperimentComments: TApiRoute & {
  request: TGetExperimentCommentsReq;
  response: TGetExperimentCommentsRes;
} = {
  url: ({ id }) => `/api/as-fields/experiment/${id}/events`,
  method: 'GET',
  request: {} as TGetExperimentCommentsReq,
  response: {} as TGetExperimentCommentsRes,
  headers: {},
};

export type { TGetExperimentCommentsReq, TGetExperimentCommentsRes };

export { getExperimentComments };
