import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #e7f1fd4d;
  margin-left: -12px;
  padding-left: 12px;
`;

const StyledComparisonSummaryRowsGroup = {
  Wrapper,
};

export default StyledComparisonSummaryRowsGroup;
