import { useDataTestId } from '../../../../utils/hooks/locators';
import { useTableBuilderUIContext } from '../../context/UI/TableBuilderUIContext/hooks';

const useTableBuilderDataTestId = (builderId?: string) => {
  const UIContext = useTableBuilderUIContext();

  const getDataTestId = useDataTestId(`${builderId ?? UIContext.builderId}-table`);

  return getDataTestId;
};

export default useTableBuilderDataTestId;
