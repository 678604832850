import { AuditReportAttrWithCustomProp as AttrWithCustomProp } from '../../../../AuditReport/models';

function createViewChecklist(stageId: string): AttrWithCustomProp {
  return {
    id: 'view-checklist-attr',
    name: '',
    customProp: {
      stageId,
      type: null,
    },
  };
}

function createDateAttr(stageId: string): AttrWithCustomProp {
  return {
    id: 'date-attr',
    name: 'Дата заполнения',
    customProp: {
      stageId,
      type: null,
    },
  };
}

const TaskReportAttributeHelpers = { createViewChecklist, createDateAttr };

export default TaskReportAttributeHelpers;
