import { useEffect, useState } from 'react';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { EContextualParentPath } from '../../constants/contextualPath';
import { useStore } from '../../utils/helpers/mobx';
import { ReferencesService } from '../../mobx/services/as-fields/ReferencesService';

import { IReference } from './../../../../api/models/as-fields/references/Reference.model';

const usePageContextualHelp = (parentPath: EContextualParentPath) => {
  const referencesService = useStore(ReferencesService);
  const helpActions = useContextualHelpActions();

  const [referenceList, setReferenceList] = useState<IReference[]>([]);

  useEffect(() => {
    helpActions.initiateContextualHelpList(parentPath, async () => {
      const response = await referencesService.getReferenceList({
        active: true,
        size: 2000,
        parentPath,
      });

      setReferenceList(response.content);

      if (response.content) return response.content;
      else return [];
    });
  }, []);

  //* * Нужно заменить в дальнейшем на значение, которое будет возвращаться из хука useContextualHelpActions */
  return referenceList;
};

export default usePageContextualHelp;
