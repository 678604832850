import { FC, memo } from 'react';

import { Icon } from '../../Icon';

import leftArrowIconDefaultS24 from './assets/icons/left-arrow-icon-default-24x24.svg';
import leftArrowIconDefaultS24Hover from './assets/icons/left-arrow-icon-default-hover-24x24.svg';
import leftArrowIconDefaultS40 from './assets/icons/left-arrow-icon-default-40x40.svg';
import leftArrowIconDefaultS40Hover from './assets/icons/left-arrow-icon-default-hover-40x40.svg';

const DEFAULT_DATA_TEST_ID = 'left-arrow-button';

interface IProps {
  size: 's40' | 's24';
  onClick?: () => void;
  dataTestId?: string;
}

const LeftArrowButton: FC<IProps> = ({ onClick, size, dataTestId }) => {
  return (
    <Icon
      states={{
        s40: {
          default: leftArrowIconDefaultS40,
          defaultHover: leftArrowIconDefaultS40Hover,
          selected: null,
          selectedHover: null,
        },
        s32: null,
        s24: {
          default: leftArrowIconDefaultS24,
          defaultHover: leftArrowIconDefaultS24Hover,
          selected: null,
          selectedHover: null,
        },
      }}
      size={size}
      dataTestId={dataTestId || DEFAULT_DATA_TEST_ID}
      onClick={onClick}
    />
  );
};

LeftArrowButton.displayName = 'LeftArrowButton';

export default memo(LeftArrowButton);
