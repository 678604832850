import { useCallback, useState } from 'react';

type TSliderArrow = 'left' | 'right';

type TOnArrowClick = (value: TSliderArrow) => void;
type TOnClearClickedArrow = () => void;
type TOnDisableArrow = (value: 'left' | 'right' | 'both') => void;
type TOnToggleControlsVisibility = (value: boolean) => void;

type TUseSliderControls = () => {
  clickedArrow: TSliderArrow | null;
  isShowControls: boolean;
  isLeftArrowDisabled: boolean;
  isRightArrowDisabled: boolean;
  scrollValue: number;
  onArrowClick: TOnArrowClick;
  onClearClickedArrow: TOnClearClickedArrow;
  onDisableArrow: TOnDisableArrow;
  onToggleControlsVisibility: TOnToggleControlsVisibility;
};

const useSliderControl: TUseSliderControls = () => {
  const [clickedArrow, setClickedArrow] = useState<TSliderArrow | null>(null);

  const [isShowControls, setIsShowControls] = useState<boolean>(false);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState<boolean>(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState<boolean>(false);

  const [scrollValue, setScrollValue] = useState<number>(0);

  const handleArrowClick = useCallback<TOnArrowClick>(value => {
    setClickedArrow(value);

    if (value === 'left') {
      setScrollValue(prevValue => prevValue - 48);
    } else {
      setScrollValue(prevValue => prevValue + 48);
    }
  }, []);

  const handleClearClickedArrow = useCallback<TOnClearClickedArrow>(() => {
    setClickedArrow(null);
  }, []);

  const handleDisableArrow = useCallback<TOnDisableArrow>(value => {
    switch (value) {
      case 'left': {
        setIsLeftArrowDisabled(true);
        setIsRightArrowDisabled(false);
        break;
      }

      case 'right': {
        setIsLeftArrowDisabled(false);
        setIsRightArrowDisabled(true);
        break;
      }

      case 'both': {
        setIsLeftArrowDisabled(false);
        setIsRightArrowDisabled(false);
        break;
      }
      default:
    }
  }, []);

  const handleToggleControlsVisibility = useCallback<TOnToggleControlsVisibility>(value => {
    setIsShowControls(value);
  }, []);

  return {
    clickedArrow,
    isShowControls,
    isLeftArrowDisabled,
    isRightArrowDisabled,
    scrollValue,
    onArrowClick: handleArrowClick,
    onClearClickedArrow: handleClearClickedArrow,
    onDisableArrow: handleDisableArrow,
    onToggleControlsVisibility: handleToggleControlsVisibility,
  };
};

export default useSliderControl;
