import React, { FC, MouseEvent, useCallback } from 'react';
import { observer } from 'mobx-react';
import { ButtonLink, TButtonLinkOnClick } from '@farmlink/farmik-ui';

import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { TableFiltersBuilderController as BuilderController } from '../../../mobx/controllers';
import { TableFiltersBuilderClearButton as ClearButton } from '../TableFiltersBuilderClearButton';
import { useTableFiltersBuilderUIContext as useUIContext } from '../../../context/UI/TableFiltersBuilderUIContext/TableFiltersBuilderUI.context';
import { useStore } from '../../../../../utils/helpers/mobx';

import Styled from './TableFiltersBuilderControls.styles';

const TableFiltersBuilderControls: FC = () => {
  const builderController = useStore(BuilderController);

  const UIContext = useUIContext();

  const handleApplyButtonClick = useCallback<TButtonLinkOnClick>(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      builderController.applySelectedValues(UIContext.builderId);
    },
    []
  );

  const getDataTestId = useDataTestId();

  return (
    <Styled.Controls {...getDataTestId('grid-item')}>
      <ButtonLink
        color={'accent'}
        onClick={handleApplyButtonClick}
        dataTestId={getDataTestId('apply-button')['data-test-id']}
      >
        Применить
      </ButtonLink>

      <ClearButton />
    </Styled.Controls>
  );
};

TableFiltersBuilderControls.displayName = 'TableFiltersBuilderControls';

export default observer(TableFiltersBuilderControls);
