import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TypeRequest = {
  systemId: string;
  redirectUrl?: string;
  scope?: string;
};

type TypeResponse = {
  redirectUrl: string;
  authKey: string;
};

export const redirectData: TApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/da-profile/externalRequests/redirectData`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
