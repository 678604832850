import {
  TChangeExperimentApprovalStatusReq,
  TChangeExperimentApprovalStatusRes,
  TGetExperimentCommentsRes,
} from '../../../../../../../../../../api';
import { EExperimentCommentTypeEvent } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentComment';
import { AxiosService } from '../../../../../../../../../common/mobx/services/axios/AxiosService';
import { lazyInject, provide } from '../../../../../../../../../common/utils/helpers/mobx';

@provide.transient()
class ApprovalService {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  fetchChecklistCommentList = async (
    checklistId: string,
    type: EExperimentCommentTypeEvent
  ): Promise<TGetExperimentCommentsRes> => {
    try {
      const commentList = await this.axiosService.api.getExperimentComments(
        { id: checklistId, typeEvent: type },
        { omit: ['id'] }
      );

      return commentList;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  postComment = (checklistId: string, comment: string): Promise<TGetExperimentCommentsRes> => {
    try {
      return this.axiosService.api.postExperimentComment(
        { id: checklistId, comment },
        { omit: ['id'] }
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  changeStatus = (
    payload: TChangeExperimentApprovalStatusReq
  ): Promise<TChangeExperimentApprovalStatusRes> => {
    try {
      return this.axiosService.api.changeExperimentApprovalStatus(payload, {
        omit: ['id', 'status'],
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
}

export default ApprovalService;
