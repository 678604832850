import { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../utils/hooks/locators';

import Styled from './Plug.styles';

interface IProps {
  className?: string;
  icon?: string;
  title?: string;
  description?: string;
  buttonExist?: boolean;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonDisabled?: boolean;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
}

const Plug: FC<IProps> = ({
  className,
  icon,
  title,
  description,
  buttonExist,
  primaryButtonTitle,
  secondaryButtonTitle,
  isPrimaryButtonDisabled,
  isSecondaryButtonDisabled,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) => {
  const getDataTestId = useDataTestIdV2('plug');

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')} className={className}>
      {icon && <Styled.Icon src={icon} {...getDataTestId('icon')} />}
      {title && <Styled.Title {...getDataTestId('title')}>{title}</Styled.Title>}
      {description && (
        <Styled.DescriptionWrapper>
          <Styled.Description {...getDataTestId('description')}>{description}</Styled.Description>
        </Styled.DescriptionWrapper>
      )}
      {buttonExist && (
        <Styled.ButtonWrapper {...getDataTestId('buttons-wrapper')}>
          {primaryButtonTitle && (
            <Styled.PrimaryButton
              color={'primary'}
              styleType={'outlined'}
              onClick={onPrimaryButtonClick}
              alignment={'center'}
              disabled={isPrimaryButtonDisabled}
              dataTestId={getDataTestId('primary-button')['data-test-id']}
            >
              {primaryButtonTitle}
            </Styled.PrimaryButton>
          )}
          {secondaryButtonTitle && (
            <Styled.SecondaryButton
              color={'default'}
              styleType={'outlined'}
              onClick={onSecondaryButtonClick}
              alignment={'center'}
              disabled={isSecondaryButtonDisabled}
              dataTestId={getDataTestId('secondary-button')['data-test-id']}
            >
              {secondaryButtonTitle}
            </Styled.SecondaryButton>
          )}
        </Styled.ButtonWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default memo(Plug);
