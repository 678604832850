import { IFormConfig } from '../../../../../../../../../../../../common/features/form/models';

interface IChangeCultureZoneForm {
  name: string;
}

const changeCultureZoneNameFormConfig: IFormConfig<IChangeCultureZoneForm> = {
  formKey: 'changeCultureZoneName',
  form: {
    name: '',
  },
  elements: {
    name: {
      name: 'name',
      order: 1,
      type: 'input',
      label: 'Название участка',
      placeholder: 'Не указано',
      inputOptions: {
        maxLength: 50,
      },
    },
  },
};

export type { IChangeCultureZoneForm };

export default changeCultureZoneNameFormConfig;
