import styled, { css } from 'styled-components';

import { ITableFiltersBuilderTechSizesByScreen as ITechSizesByScreen } from '../../../models/data';

const getGridTemplateColumnsByScreen = (
  techSizesByScreen: ITechSizesByScreen,
  screen: keyof ITechSizesByScreen
) => {
  const columnsLength = techSizesByScreen[screen][0].split(' ').length;

  return css`
    grid-template-columns: repeat(${columnsLength}, 1fr);
  `;
};

const getGridTemplateAreasByScreen = (
  techSizesByScreen: ITechSizesByScreen,
  screen: keyof ITechSizesByScreen
) => {
  const gridTemplateAreas = techSizesByScreen[screen].reduce<string>((acc, areas, index) => {
    if (index === 0) {
      return `'${areas}'`;
    }

    return `${acc} '${areas}'`;
  }, '');

  return css`
    grid-template-areas: ${gridTemplateAreas};
  `;
};

const getGridByScreen1920 = (techSizesByScreen: ITechSizesByScreen) => {
  const gridTemplateColumns = getGridTemplateColumnsByScreen(techSizesByScreen, 's1920');
  const gridTemplateAreas = getGridTemplateAreasByScreen(techSizesByScreen, 's1920');

  return css`
    @media only screen and (min-width: 1920px) {
      ${gridTemplateColumns};
      ${gridTemplateAreas};
    }
  `;
};

const getGridByScreen1366 = (techSizesByScreen: ITechSizesByScreen) => {
  const gridTemplateColumns = getGridTemplateColumnsByScreen(techSizesByScreen, 's1366');
  const gridTemplateAreas = getGridTemplateAreasByScreen(techSizesByScreen, 's1366');

  return css`
    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      ${gridTemplateColumns};
      ${gridTemplateAreas};
    }
  `;
};

const getGridByScreen1024 = (techSizesByScreen: ITechSizesByScreen) => {
  const gridTemplateColumns = getGridTemplateColumnsByScreen(techSizesByScreen, 's1024');
  const gridTemplateAreas = getGridTemplateAreasByScreen(techSizesByScreen, 's1024');

  return css`
    @media only screen and (max-width: 1365px) {
      ${gridTemplateColumns};
      ${gridTemplateAreas};
    }
  `;
};

const getGridTemplateAreasByScreenSize = (techSizesByScreen: ITechSizesByScreen) => {
  return css`
    ${getGridByScreen1920(techSizesByScreen)};

    ${getGridByScreen1366(techSizesByScreen)};

    ${getGridByScreen1024(techSizesByScreen)};
  `;
};

const Grid = styled.div<{ $techSizesByScreen: ITechSizesByScreen }>`
  display: grid;
  align-items: center;
  grid-gap: 16px 12px;

  ${({ $techSizesByScreen }) => getGridTemplateAreasByScreenSize($techSizesByScreen)}
`;

const StyledTableBuilderGrid = {
  Grid,
};

export default StyledTableBuilderGrid;
