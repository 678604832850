import styled, { css } from 'styled-components';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  align-self: flex-end;
`;

const TagList = styled.div<{ $isShowHiddenTags?: boolean }>`
  flex: 1 1 50%;

  display: flex;
  gap: 8px;

  overflow: hidden;

  ${({ $isShowHiddenTags }) =>
    $isShowHiddenTags &&
    css`
      flex-wrap: wrap;
    `}
`;

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  gap: 8px;
`;

const StyledTableFiltersBuilderTags = {
  ButtonsWrapper,
  TagList,
  Wrapper,
};

export default StyledTableFiltersBuilderTags;
