import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  margin-bottom: 12px;

  border-bottom: 1px dashed #e9e6f0;
`;

const StyledComparisonTableBuilderDashedHeader = {
  Wrapper,
};

export default StyledComparisonTableBuilderDashedHeader;
