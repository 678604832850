import isNumWithLongFractPart from '../isNumWithLongFractPart/isNumWithLongFractPart';
import formatNumWithLongFractPart from '../formatNumWithLongFractPart/formatNumWithLongFractPart';

const toNumber = (value: number | string): number => {
  if (isNumWithLongFractPart(value)) {
    return formatNumWithLongFractPart(value);
  }

  const numberString = String(value).replace(',', '.');
  const numberValue = Number(numberString);

  if (!isNaN(numberValue)) {
    return numberValue;
  }
};

export default toNumber;
