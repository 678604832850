import styled from 'styled-components';

const ShowMoreTagsButton = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.1px;

  padding: 4px 12px;
  border-radius: 16px;

  cursor: pointer;

  color: white;
  background-color: #0770e3;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTableFiltersBuilderTagContainer = {
  ShowMoreTagsButton,
  Wrapper,
};

export default StyledTableFiltersBuilderTagContainer;
