import { createContext } from 'react';

interface IUIContext {
  builderId: string;
}

const defaultValue: IUIContext = {
  builderId: '',
};

const TableBuilderUIContext = createContext<IUIContext>(defaultValue);

export default TableBuilderUIContext;
