import { FC, memo } from 'react';

import Styled from './StatusCellComponent.style';
import { TStatusCellComponentProps } from './StatusCellComponent.types';
import { getStatusCellComponentColors } from './utils/helpers';

const StatusCellComponent: FC<TStatusCellComponentProps> = ({ status }) => {
  const { bgColor, color, statusName } = getStatusCellComponentColors(status);

  return (
    <Styled.StatusWrapper
      $bgColor={bgColor}
      $color={color}
      data-test-id="status-cell-component-wrapper"
    >
      {statusName}
    </Styled.StatusWrapper>
  );
};

export default memo(StatusCellComponent);
