import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

type TSliderArrow = 'left' | 'right';

type TOnClearClickedArrow = () => void;
type TOnDisableArrow = (value: 'left' | 'right' | 'both') => void;
type TOnToggleControlsVisibility = (value: boolean) => void;
type TOnClearIsNeedToUpdateScroll = (value: boolean) => void;

const useComparisonTableBuilderSliderControl = isHideSliderControls => {
  const [clickedArrow, setClickedArrow] = useState<TSliderArrow | null>(null);

  const [isShowControls, setIsShowControls] = useState<boolean>(false);
  const [isHideSlider, setIsHideSlider] = useState<boolean>(false);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState<boolean>(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState<boolean>(false);
  const [isNeedToUpdateScroll, setIsNeedToUpdateScroll] = useState<boolean>(false);
  const [allowScroll, setAllowScroll] = useState(true);

  const [scrollValue, setScrollValue] = useState(0);
  const [addingScrollValue, setAddingScrollValue] = useState(0);

  const handleArrowClick = useCallback(
    (value: TSliderArrow): void => {
      setClickedArrow(value);
      setAllowScroll(false)
      if (value === 'left') {
        setScrollValue(prevValue => prevValue - addingScrollValue);
      } else {
        setScrollValue(prevValue => prevValue + addingScrollValue);
      }
      setTimeout(() => {
        setAllowScroll(true)
      }, 600)
    },
    [addingScrollValue]
  );

  const handleClearClickedArrow = useCallback<TOnClearClickedArrow>(() => {
    setClickedArrow(null);
  }, []);

  const handleDisableArrow = useCallback<TOnDisableArrow>(value => {
    switch (value) {
      case 'left': {
        setIsLeftArrowDisabled(true);
        setIsRightArrowDisabled(false);
        break;
      }

      case 'right': {
        setIsLeftArrowDisabled(false);
        setIsRightArrowDisabled(true);
        break;
      }

      case 'both': {
        setIsLeftArrowDisabled(false);
        setIsRightArrowDisabled(false);
        break;
      }
      default:
    }
  }, []);

  const handleToggleControlsVisibility = useCallback<TOnToggleControlsVisibility>(value => {
    setIsShowControls(value);
  }, []);

  const handleToggleScrollUpdate = useCallback<TOnClearIsNeedToUpdateScroll>(value => {
    setIsNeedToUpdateScroll(value);
  }, []);

  useEffect(() => {
    if (!_.isUndefined(isHideSliderControls)) {
      setIsHideSlider(isHideSliderControls);
    }
  }, [isHideSliderControls]);

  return {
    clickedArrow,
    isShowControls,
    isLeftArrowDisabled,
    isRightArrowDisabled,
    isNeedToUpdateScroll,
    isHideSliderControls: isHideSlider,
    scrollValue,
    addingScrollValue,
    onArrowClick: handleArrowClick,
    onClearClickedArrow: handleClearClickedArrow,
    onDisableArrow: handleDisableArrow,
    onToggleControlsVisibility: handleToggleControlsVisibility,
    onToggleScrollUpdate: handleToggleScrollUpdate,
    setAddingScrollValue,
    setAllowScroll,
    allowScroll,
  };
};

export default useComparisonTableBuilderSliderControl;
