import { Plug } from '../../../../../../../../../common/components/ui';
import applicationTime from '../../assets/icons/application_time.svg';

import Styled from './TotalComparisonPlug.styles';

export const TotalComparisonPlug = () => {
  return (
    <Styled.Wrapper>
      <Plug
        icon={applicationTime}
        title="Здесь можно будет сравнить участки опыта по всем показателям, которые вы внесли в предыдущих разделах"
      />
    </Styled.Wrapper>
  );
};
