import { FC, memo, useMemo } from 'react';
import { Colors, IconButton } from '@farmlink/farmik-ui';

const DEFAULT_DATA_TEST_ID = 'pin-button';

interface IProps {
  size: 32 | 24;
  isEnableEditing: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  dataTestId?: string;
}

const PinButton: FC<IProps> = ({ isSelected, onClick, size, dataTestId, isEnableEditing }) => {
  const colorScheme = useMemo(
    () => ({
      default: {
        background: isSelected ? Colors.accentGreen : Colors.secondaryBGGrey,
        icon: isSelected ? Colors.generalWhite : Colors.generalBlack,
      },
      hover: {
        background: isSelected ? Colors.hoverGreen : Colors.lightGreen,
        icon: isSelected ? Colors.generalWhite : Colors.accentGreen,
      },
      disabled: {
        background: isSelected ? Colors.borderHover : Colors.secondaryBGGrey,
        icon: isSelected ? Colors.generalWhite : Colors.borderHover,
      },
    }),
    [isSelected]
  );

  return (
    <IconButton
      $borderRadius="8px"
      icon="attach"
      colorScheme={colorScheme}
      size={size}
      $isDisabled={!isEnableEditing}
      dataTestId={dataTestId || DEFAULT_DATA_TEST_ID}
      onClick={isEnableEditing ? onClick : () => ''}
      hasStroke
    />
  );
};

PinButton.displayName = 'PinButton';

export default memo(PinButton);
