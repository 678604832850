import { IExperimentStep } from '../../../../models/as-fields/experiments';
import { TResponseList } from '../../../../models/common/response';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetExperimentStepListReq = {} & {
  experimentId?: string;
  type?: string;
  cultureZoneExperimentId?: string;
  cultureZoneId?: string;
};

type TGetExperimentStepListRes = {} & TResponseList<IExperimentStep>;

const getExperimentStepList: TApiRoute & {
  request: TGetExperimentStepListReq;
  response: TGetExperimentStepListRes;
} = {
  url: () => `/api/as-fields/experimentSteps/list`,
  method: 'GET',
  request: {} as TGetExperimentStepListReq,
  response: {} as TGetExperimentStepListRes,
  headers: {},
};

export type { TGetExperimentStepListRes, TGetExperimentStepListReq };

export { getExperimentStepList };
