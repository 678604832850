import { FC, PropsWithChildren } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './TotalComparisonHeader.styles';

interface IProps {
  dataTestId: string;
}

const TotalComparisonHeader: FC<PropsWithChildren<IProps>> = ({ children, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.StickyWrapper {...getDataTestId('sticky-wrapper')}>
      <Styled.Wrapper {...getDataTestId()}>{children}</Styled.Wrapper>
    </Styled.StickyWrapper>
  );
};

TotalComparisonHeader.displayName = 'TotalComparisonColumn';

export default TotalComparisonHeader;
