import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { formatNumValue } from '../../../../../../utils/helpers/numbers';
import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './PriceCell.style';

interface IPriceCellProps {
  price: number;
  unitOfMeasure: string;
  dataTestId?: string;
}

const PriceCell: FC<IPriceCellProps> = ({ price, unitOfMeasure, dataTestId }) => {
  const { ref, showTooltip } = useShowTooltip();

  const valueToDisplay = `${formatNumValue(price)} ₽/${unitOfMeasure ?? 'кг'}`;

  return (
    <AutoTooltip
      content={`${price} ₽/${unitOfMeasure ?? 'кг'}`}
      position="top"
      disabled={!showTooltip}
    >
      <Styled.Price data-test-id={dataTestId} ref={ref}>
        {valueToDisplay}
      </Styled.Price>
    </AutoTooltip>
  );
};

PriceCell.displayName = 'PriceCell';

export default memo(PriceCell);
