import styled from 'styled-components';

const FormWrapper = styled.form`
  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

const StyledAuditReport = {
  FormWrapper,
};

export default StyledAuditReport;
