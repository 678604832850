import styled, { css } from 'styled-components';

import { IIconState, IIconStates } from './Icon';

type TSize = 's40' | 's32' | 's24';

const getBg = (state: IIconState, isSelected: boolean) => {
  if (isSelected) {
    return css`
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url(${state.selected});
    `;
  }

  return css`
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(${state.default});
  `;
};

const getBgHover = (state: IIconState, isSelected: boolean) => {
  if (isSelected) {
    return css`
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url(${state.selectedHover});
    `;
  }

  return css`
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(${state.defaultHover});
  `;
};

const getPreset = (size: TSize, states: IIconStates, isSelected: boolean) => {
  switch (size) {
    case 's40':
      return css`
        width: 40px;
        height: 40px;

        border-radius: 12px;

        ${getBg(states.s40, isSelected)}

        &:hover {
          ${getBgHover(states.s40, isSelected)}
        }
      `;
    case 's32':
      return css`
        width: 32px;
        height: 32px;

        border-radius: 12px;

        ${getBg(states.s32, isSelected)}

        &:hover {
          ${getBgHover(states.s32, isSelected)}
        }
      `;
    case 's24':
      return css`
        width: 24px;
        height: 24px;

        border-radius: 8px;

        ${getBg(states.s24, isSelected)}

        &:hover {
          ${getBgHover(states.s24, isSelected)}
        }
      `;
    default:
  }
};

const Icon = styled.div<{
  $size: TSize;
  $states: IIconStates;
  $isSelected: boolean;
}>`
  cursor: pointer;

  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.07);

  ${({ $size, $states, $isSelected }) => getPreset($size, $states, $isSelected)}
`;

const StyledIcon = {
  Icon,
};

export default StyledIcon;
