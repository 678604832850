import { FC, memo } from 'react';
import { ButtonLink } from '@farmlink/farmik-ui';

import { InventoryValueCellTitle } from '../InventoryValueCellTitle';
import { InventoryValueCell as CellContent } from '../../content';
import { IActiveSubstancesData } from '../../../../model/IActiveSubstancesData.model';

import Styled from './InventoryValueCell.styles';

interface InventoryValueCellComponentProps {
  row: Pick<IActiveSubstancesData, 'id' | 'protectionItemsList' | 'itemsList'>;
  onClickAddTechoperation: (
    row: Pick<IActiveSubstancesData, 'id' | 'protectionItemsList' | 'itemsList'>
  ) => void;
  isViewOnly: boolean;
  isShowJobLine?: boolean;
}

const InventoryValueCellComponent: FC<InventoryValueCellComponentProps> = ({
  row,
  onClickAddTechoperation,
  isShowJobLine,
  isViewOnly,
}) => {
  const addTechOperation = () => {
    onClickAddTechoperation(row);
  };

  return (
    <Styled.Wrapper data-test-id={`inventory-value-cell-wrapper-${row?.id}`}>
      <InventoryValueCellTitle row={row} isShowJobLine={isShowJobLine} />

      {row?.itemsList?.map(value => (
        <CellContent key={value.fertilizer.id} name={value.fertilizer.name} />
      ))}

      {row?.protectionItemsList?.map(value => (
        <CellContent key={value.protection?.id} name={value.protection?.name} />
      ))}

      {isShowJobLine && <CellContent name={'Работа'} />}

      {!isViewOnly && (
        <ButtonLink color={'accent'} onClick={addTechOperation}>
          Добавить ТМЦ
        </ButtonLink>
      )}
    </Styled.Wrapper>
  );
};

InventoryValueCellComponent.displayName = 'InventoryValueCellComponent';

export default memo(InventoryValueCellComponent);
