import { getValue } from '../getValue';

const createCalculationDifferenceData = (changed: {
  currentRoi: number;
  revenueChanged: number;
  plannedYieldChanged: number;
  minimalIncrease: number;
  costDiff: number;
  cashDiff: number;
}) => [
  {
    title: 'Разница стоимости, ₽/га',
    value: getValue(changed, 'costDiff'),
  },

  {
    title: 'Разница выручки, ₽/га',
    value: getValue(changed, 'cashDiff'),
  },
  {
    title: 'Прибавка, т/га',
    value: getValue(changed, 'plannedYieldChanged'),
  },
  {
    title: 'Минимально необходимая прибавка, т/га',
    value: getValue(changed, 'minimalIncrease'),
  },
];

export default createCalculationDifferenceData;
