import moment from 'moment';

import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../common/features/TableBuilder/models/configs';
import { EContractsTableBuilderId } from '../../../../../constants/configs';
import { ContractsListOrganizationCell } from '../../../components/cells';

@provide.transient()
class ContractsListConfigsService {
  createConfig = (): IBuilderConfig => ({
    id: EContractsTableBuilderId.Contracts,
    columnConfigList: this.createColumnList(),
    cellConfigList: this.createCellList(),
    rowsGroupConfigList: this.createRowsGroupList(),
  });

  protected createColumnList = (): IColumnConfig[] => [
    this.createOrganizationColumn(),
    this.createContractNumberColumn(),
    this.createDateColumn(),
  ];

  protected createOrganizationColumn = (): IColumnConfig => ({
    id: 'organization',
    name: 'Контрагент',
    width: {
      default: '1fr',
    },
  });

  protected createContractNumberColumn = (): IColumnConfig => ({
    id: 'name',
    name: '№ Договора',
    width: {
      default: '1fr',
    },
  });

  protected createDateColumn = (): IColumnConfig => ({
    id: 'contractDate',
    name: 'Дата ',
    width: {
      default: '134px',
    },
  });

  protected createCellList = (): ICellConfig[] => [
    this.createOrganizationCell(),
    this.createContractNumberCell(),
    this.createDateCell(),
  ];

  createOrganizationCell = (): ICellConfig => ({
    id: 'organization',
    customRenderConfig: {
      renderCell: row => <ContractsListOrganizationCell organization={row.organization} />,
    },
  });

  createContractNumberCell = (): ICellConfig => ({
    id: 'name',
    autoRenderConfig: {
      renderValue: row => row?.name,
    },
  });

  createDateCell = (): ICellConfig => ({
    id: 'contractDate',
    autoRenderConfig: {
      renderValue: row => moment(row.contractDate).format('DD.MM.YYYY'),
    },
  });

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EContractsTableBuilderId.Contracts,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default ContractsListConfigsService;
