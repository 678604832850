import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import SpinnerLoader from '../../../components/ui/loaders/SpinnerLoader/SpinnerLoader';
import { SessionController } from '../../../mobx/controllers';
import { SessionStore } from '../../../mobx/stores';
import { useStore } from '../../../utils/helpers/mobx';
import { ExternalRedirectContainer } from '../ExternalRedirectContainer';

import { TAuthorizationContainerProps } from './AuthorizationContainer.types';

const AuthorizationContainer: FC<TAuthorizationContainerProps> = ({
  redirectTo,
  isZoneForAuthorized,
  children,
}) => {
  const sessionStore = useStore(SessionStore);
  const { isLogout, isLoggedIn, isLoading } = sessionStore;

  const sessionController = useStore(SessionController);
  const { checkIsLoggedIn } = sessionController;

  const location = useLocation();
  const { search } = location;

  useEffect(() => {
    if (!isLoggedIn && !sessionStore.isNotFirstLoading) {
      const searchParams = new URLSearchParams(search.slice(1));

      checkIsLoggedIn(searchParams.get('authKey'));
    }
  }, [search, isLoggedIn]);

  if (isLoading) {
    return <SpinnerLoader data-test-id="authorization-loader" needToHideContent={true} />;
  }

  if (isZoneForAuthorized) {
    if (isLoggedIn) {
      return children;
    }

    return (
      <ExternalRedirectContainer
        redirectTo={redirectTo}
        isLogout={isLogout}
        data-test-id="authorization-external-redirect"
      />
    );
  }

  return (
    <ExternalRedirectContainer
      redirectTo={redirectTo}
      isLogout={isLogout}
      data-test-id="authorization-external-redirect"
    />
  );
};

AuthorizationContainer.displayName = 'AuthorizationContainer';

export default observer(AuthorizationContainer);
