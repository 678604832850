import { FC, MouseEvent, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './ExecutionTableDeleteIcon.styles';

interface IProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  dataTestId: string;
}

const ExecutionTableDeleteIcon: FC<IProps> = ({ onClick, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Button type={'button'} onClick={onClick} {...getDataTestId()}>
      <Styled.Icon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...getDataTestId('icon')}
      >
        <path d="M5.67432 7.30615H6.54651V13.7515H5.67432V7.30615Z" fill="#DF2E22" />
        <path d="M7.56396 7.30615H8.43616V13.7515H7.56396V7.30615Z" fill="#DF2E22" />
        <path d="M9.45361 7.30615H10.3258V13.7515H9.45361V7.30615Z" fill="#DF2E22" />
        <path
          d="M13.55 6.70117H13.2415L13.3782 5.375H14.4107V4.07617C14.4107 3.36001 13.7602 2.77734 12.9606 2.77734H11.2594L10.2673 1H5.73275L4.74062 2.77734H3.03938C2.23985 2.77734 1.58935 3.36001 1.58935 4.07617V5.375H2.62181L2.75851 6.70117H2.45003C1.6505 6.70117 1 7.28384 1 8C1 8.71616 1.6505 9.29883 2.45003 9.29883H3.02626L3.61393 15H12.3861L12.9737 9.29883H13.55C14.3495 9.29883 15 8.71616 15 8C15 7.28384 14.3495 6.70117 13.55 6.70117ZM6.29878 1.82031H9.70128L10.2355 2.77734H5.76456L6.29878 1.82031ZM2.50516 4.07617C2.50516 3.81233 2.74483 3.59766 3.03938 3.59766H12.9606C13.2552 3.59766 13.4949 3.81233 13.4949 4.07617V4.55469H2.50516V4.07617ZM2.45003 8.47852C2.15548 8.47852 1.91581 8.26384 1.91581 8C1.91581 7.73616 2.15548 7.52148 2.45003 7.52148H2.84307L2.94173 8.47852H2.45003ZM11.5509 14.1797H4.44909L3.54152 5.375H12.4585L11.5509 14.1797ZM13.55 8.47852H13.0583L13.157 7.52148H13.55C13.8446 7.52148 14.0843 7.73616 14.0843 8C14.0843 8.26384 13.8446 8.47852 13.55 8.47852Z"
          fill="#DF2E22"
        />
      </Styled.Icon>
    </Styled.Button>
  );
};

ExecutionTableDeleteIcon.displayName = 'ExecutionTableDeleteIcon';

export default memo(ExecutionTableDeleteIcon);
