import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IExperimentFactDataZoneUpdate } from '../../../models/as-fields/experiments';

export type TUpdateExperimentFactTableRequest = {
  id: string;
  zoneList: IExperimentFactDataZoneUpdate[];
};

type TResponse = {};

export const updateExperimentFactTable: TApiRoute & {
  request:
    | TUpdateExperimentFactTableRequest
    | ((req: TUpdateExperimentFactTableRequest) => IExperimentFactDataZoneUpdate[]);
  response: TResponse;
} = {
  url: () => `/api/as-fields/experiment`,
  method: 'PUT',
  request: req => req.zoneList,
  response: {} as TResponse,
  headers: {},
};
