import { Button, NewDropdown } from '@farmlink/farmik-ui';
import styled from 'styled-components';

type TFilterBarProps = {
  $showFilterBar: boolean;
};

const FilterBar = styled.div<TFilterBarProps>`
  background: ${({ theme }) => theme?.Colors?.generalLight ?? '#ffffff'};
  overflow: ${({ $showFilterBar }) => ($showFilterBar ? 'visible' : 'hidden')};
  max-height: ${({ $showFilterBar }) => ($showFilterBar ? '500px' : 0)};
  transition: 'max-height 0.25s ease-in-out';
  padding-left: 12px;
  display: flex;
`;

const FilterBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  & > div {
    margin: 0 0 16px 12px;
    width: 177px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  height: 20px;
  margin: auto 24px 24px auto !important;
`;

const StyledSearchButton = styled(Button)`
  background-color: transparent;
  margin: auto 0;
  height: 20px;
  width: auto;
  border: none;
  color: ${({ theme }) => theme?.Colors?.greenInfo ?? '#3FB65F'};
`;

const StyledClearButton = styled(Button)`
  background-color: transparent;
  border: none;
  margin: auto 0;
  height: 20px;
  width: auto;
  color: ${({ theme }) => theme?.Colors?.redInfo ?? '#DF2E22'};
`;

const StyledSelect = styled(NewDropdown)`
  width: 177px;
`;

const StyledFilterBar = {
  FilterBar,
  FilterBodyWrapper,
  ButtonsWrapper,
  StyledSearchButton,
  StyledClearButton,
  StyledSelect,
};

export default StyledFilterBar;
