import { FC, memo, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Attribute } from '../../components';
import { useStore } from '../../../../utils/helpers/mobx';
import { DynamicTableStore } from '../../mobx/stores';
import { _AttributeValue } from '../../models';
import { useOrderedInstanceList } from '../../private';

interface IAttributeContainerProps {
  tableId: string;
  id: string;
  cellPadding: string;
}

const AttributeContainer: FC<IAttributeContainerProps> = ({ tableId, id, cellPadding }) => {
  const { getAttribute, getValueListByAttributeId } = useStore(DynamicTableStore);

  const attr = getAttribute(tableId, id);
  const instList = useOrderedInstanceList(tableId);
  const attrValList = getValueListByAttributeId(tableId, id);

  const sortedAttrValList = useMemo(() => {
    return instList.reduce<_AttributeValue[]>((acc, inst) => {
      attrValList.forEach(attrVal => {
        if (inst.id === attrVal.instanceId) {
          acc.push(attrVal);
        }
      });

      return acc;
    }, []);
  }, [instList, attrValList]);

  return (
    <>
      {attr ? (
        <Attribute
          attribute={attr}
          attributeValueList={sortedAttrValList}
          cellPadding={cellPadding}
        />
      ) : null}
    </>
  );
};

AttributeContainer.displayName = 'AttributeContainer';

export default memo(observer(AttributeContainer));
