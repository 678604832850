import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo, ReactNode } from 'react';

import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './HeadTitle.styles';

interface IDynamicTableHeadTitleProps {
  headColumnPadding: string;
  children: ReactNode;
  headColumnMargin?: string;
}

const HeadTitle: FC<IDynamicTableHeadTitleProps> = ({
  children,
  headColumnPadding,
  headColumnMargin,
}) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  return (
    <AutoTooltip position="top" content={children} disabled={!showTooltip}>
      <Styled.Wrapper
        ref={ref}
        $headColumnPadding={headColumnPadding}
        $headColumnMargin={headColumnMargin}
      >
        {children}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

HeadTitle.displayName = 'HeadTitle';

export default memo(HeadTitle);
