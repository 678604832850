enum ECreateExperimentRoute {
  Preparation = 'preparation',
  Attributes = 'attributes',
  Zones = 'zones',
  NutritionHistory = 'nutrition-history',
  Calculation = 'calculation',
  Comparison = 'comparison',
  Approval = 'approval',
  Execution = 'execution',
  TotalComparison = 'total-comparison',
  Documents = 'documents',
  Remarks = 'remarks',
}

export default ECreateExperimentRoute;
