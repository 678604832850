import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { AddPreviousCulture } from './components';

const addPreviousCultureModalConfig: TModalConfig = {
  id: 'addPreviousCulture',
  name: 'addPreviousCulture',
  title: '',
  type: EModalType.Custom,
  dataTestId: 'add-previous-culture-modal',
  children: AddPreviousCulture,
  styledProps: {
    $modalPaddings: '40px 60px',
    $size: EModalSize.Medium,
  },
};

export default addPreviousCultureModalConfig;
