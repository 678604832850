import { FC } from 'react';

import { EObservationStatus } from '../../../../../../../../../../../common/utils/constants/observation';
import { getAuditStatusColors } from '../../utils/helpers';

import Styled from './SliderAuditStatus.styles';

interface IProps {
  status: EObservationStatus;
}

export const SliderAuditStatus: FC<IProps> = ({ status }) => {
  const { bgColor, color, statusName } = getAuditStatusColors(status);

  return (
    <Styled.StatusWrapper
      $bgColor={bgColor}
      $color={color}
      data-test-id="status-cell-component-wrapper"
    >
      {statusName}
    </Styled.StatusWrapper>
  );
};
