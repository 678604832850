import { FC, memo, PropsWithChildren } from 'react';

import Styled from './ComparisonContainer.styles';

interface IProps {
  title: string;
  isWithoutBodyMargin?: boolean;
}

const ComparisonContainer: FC<PropsWithChildren<IProps>> = ({
  children,
  title,
  isWithoutBodyMargin,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.HeaderWrapper>
        <Styled.Header>
          <Styled.Title>{title}</Styled.Title>
        </Styled.Header>
      </Styled.HeaderWrapper>

      <Styled.Body $isWithoutMargin={isWithoutBodyMargin}>{children}</Styled.Body>
    </Styled.Wrapper>
  );
};

ComparisonContainer.displayName = 'ComparisonContainer';

export default memo(ComparisonContainer);
