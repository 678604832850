import { observer } from 'mobx-react';
import { FC } from 'react';

import { useComparisonTableBuilderContext as useContext } from '../../../context';
import { useStore } from '../../../../../utils/helpers/mobx';
import { useSortBy } from '../../../../../utils/hooks/useSortBy';
import { ComparisonTableBuilderGrid as Grid } from '../../ComparisonTableBuilderGrid';
import { ComparisonTableBuilderColumnContainer as ColumnContainer } from '../../ComparisonTableBuilderColumnContainer';
import { IComparisonTableBuilderHeader as IHeader } from '../../../models/data';
import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { ComparisonTableBuilderController } from '../../../mobx/controllers';

interface IProps {
  header: IHeader;
}

const ComparisonTableBuilderHeaderCustomRenderContainer: FC<IProps> = ({ header }) => {
  const controller = useStore(ComparisonTableBuilderController);

  const context = useContext();

  const fixedColumnList = useSortBy(
    controller.getColumnList(context.builderId, { isOnlyFixed: true })
  );

  const scrollableColumnList = useSortBy(controller.getColumnList(context.builderId));

  const getDataTestId = useDataTestId({ componentName: 'custom-header' });

  return (
    <>
      {header.customRenderConfig.render(
        header,
        <Grid
          fixedChildren={fixedColumnList.map(column => (
            <ColumnContainer key={column.id} column={column} />
          ))}
          scrollableChildren={scrollableColumnList.map(column => (
            <ColumnContainer key={column.id} column={column} />
          ))}
          isHeader
        />,
        getDataTestId()['data-test-id']
      )}
    </>
  );
};

ComparisonTableBuilderHeaderCustomRenderContainer.displayName =
  'ComparisonTableBuilderHeaderCustomRenderContainer';

export default observer(ComparisonTableBuilderHeaderCustomRenderContainer);
