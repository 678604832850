import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
`;

const StyledComparisonTablePlug = {
  Wrapper,
};

export default StyledComparisonTablePlug;
