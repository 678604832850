import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../../../../../../common/assets/styles/colors';

const { BorderGray, GeneralBlack } = ECommonColor;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${GeneralBlack};
`;

const Description = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${GeneralBlack};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1px solid ${BorderGray};

  & > :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Body = styled.div`
  margin: 24px 0;
`;

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & button {
    justify-content: center;
    flex: 1 1 40%;
  }

  & > :not(:last-child) {
    margin-right: 12px;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledChangeCultureZoneName = {
  Title,
  Description,
  Header,
  Body,
  Footer,
  Form,
};

export default StyledChangeCultureZoneName;
