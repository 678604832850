import styled from 'styled-components';

import { Colors } from '../../../../../../../../../common/styles/colors';

const { white } = Colors;

const Wrapper = styled.div`
  background-color: ${white};
  border-radius: 16px;
  padding: 24px 24px 32px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #151f32;
  padding-bottom: 12px;
  letter-spacing: -0.5px;
  display: block;
  border-bottom: 1px solid #e9e6f0;
`;

const StyledRemarksPlug = {
  Wrapper,
  Title,
};

export default StyledRemarksPlug;
