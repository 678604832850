import _ from 'lodash';
import { makeAutoObservable } from 'mobx';

import { provide, lazyInject } from '../../../../../../../../common/utils/helpers/mobx';
import { IContractForm } from '../../../forms/config/contractFormConfig';
import { CreateEditContractService } from '../../services';
import { CreateEditContractStore } from '../../stores';
import { IOrganization } from '../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import { ISelectOption } from '../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { CreateEditContractHelpers } from '../../../utils/helpers';
import { IServiceConfig } from '../../../../../../../../common/models/service';
import { AccessController } from '../../../../../../../../common/mobx/controllers/AccessController';

type TContractForm = Pick<IContractForm, 'contractDate' | 'organizationId'> & {
  name?: string;
};

@provide.transient()
class CreateEditContractController {
  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(CreateEditContractService)
  protected createEditContractService: CreateEditContractService;

  @lazyInject(CreateEditContractStore)
  createEditContractStore: CreateEditContractStore;

  @lazyInject(AccessController)
  accessController: AccessController;

  fetchContract = async (id: string): Promise<void> => {
    const { setIsLoading, setContract } = this.createEditContractStore;
    const { getContract } = this.createEditContractService;

    setIsLoading(true);

    const contract = await getContract(id);

    setContract(contract);

    setIsLoading(false);
  };

  createContract = async (form: TContractForm, config: IServiceConfig): Promise<void> => {
    const { setIsLoading } = this.createEditContractStore;
    const { createContract } = this.createEditContractService;

    setIsLoading(true);
    await createContract(form, config);
    setIsLoading(false);
  };

  changeContract = async (
    id: string,
    form: TContractForm,
    config: IServiceConfig
  ): Promise<void> => {
    const { setIsLoading } = this.createEditContractStore;
    const { changeContract } = this.createEditContractService;

    setIsLoading(true);
    const payload = { ...form, id };
    await changeContract(payload, config);
    setIsLoading(false);
  };

  createOrganizationOptionList = (organizationList: IOrganization[]): ISelectOption[] => {
    const { createOrganizationOptionList } = CreateEditContractHelpers;

    if (!_.isArray(organizationList)) {
      return [];
    }

    return createOrganizationOptionList(organizationList);
  };

  organizationNameSearchQueryHandler = async (
    searchQueryValue: string
  ): Promise<ISelectOption[]> => {
    const { getOrganizationList } = this.createEditContractService;

    const { content, totalPages } = await getOrganizationList(searchQueryValue, 0);

    if (_.isArray(content)) {
      const {
        setOrganizationList,
        setDropdownPageNumbers,
        setOrganizationOptionList,
        setDropdownSearchQuery,
      } = this.createEditContractStore;

      setOrganizationList(content);

      const organizationList = this.createOrganizationOptionList(content);

      setDropdownPageNumbers('organizationCurrentPageNumber', 0);

      setDropdownPageNumbers('organizationTotalPageNumber', totalPages);

      setOrganizationOptionList(organizationList);

      setDropdownSearchQuery('organizationSearchQuery', searchQueryValue);

      return organizationList;
    }
  };

  onOrganizationListScroll = async (querySearch: string) => {
    const { organizationList, dropdownPageNumbers } = this.createEditContractStore;

    const { content } = await this.createEditContractService.getOrganizationList(
      querySearch,
      dropdownPageNumbers.organizationCurrentPageNumber
    );

    return this.createOrganizationOptionList([...organizationList, ...content]);
  };

  changeDropdownPageNumber = (dropdownName: string): void => {
    const { setDropdownPageNumbers, dropdownPageNumbers } = this.createEditContractStore;

    setDropdownPageNumbers(dropdownName, dropdownPageNumbers[dropdownName] + 1);
  };

  getAccessToEditContract = async (organizationId: string) => {
    const { getAccessRuleForCurrentUserInOrganization } = this.accessController;
    const { setAccessToEditContracts } = this.createEditContractStore;

    const hasAccess = await getAccessRuleForCurrentUserInOrganization({
      organizationId,
      rule: 'experiments.editContracts',
    });

    setAccessToEditContracts(hasAccess);
  };
}

export default CreateEditContractController;
