import {
  EExperimentStatus,
  EExperimentStepStatus,
} from '../../../../../api/models/as-fields/experiments';
import {
  StatusPill,
  StatusPillApproved,
  StatusPillCanceled,
  StatusPillDraft,
  StatusPillInWork,
  StatusPillOnApprove,
  StatusPillFactApproved,
  StatusPillFullCompleted,
} from '../components';

type TExperimentStatusMap = Map<
  EExperimentStatus | EExperimentStepStatus,
  {
    label: React.NamedExoticComponent<{
      children: any;
      isSelected?: boolean;
    }>;
    value: EExperimentStatus;
  }
>;

const experimentStatusMap: TExperimentStatusMap = new Map([
  [EExperimentStatus.Draft, { label: StatusPillDraft, value: EExperimentStatus.Draft }],
  [
    EExperimentStatus.InApproval,
    { label: StatusPillOnApprove, value: EExperimentStatus.InApproval },
  ],
  [EExperimentStatus.Approved, { label: StatusPillApproved, value: EExperimentStatus.Approved }],
  [EExperimentStatus.InProgress, { label: StatusPillInWork, value: EExperimentStatus.InProgress }],
  [
    EExperimentStatus.Completed,
    {
      label: StatusPill,
      value: EExperimentStatus.Completed,
    },
  ],
  [
    EExperimentStatus.FactAppruved,
    {
      label: StatusPillFactApproved,
      value: EExperimentStatus.FactAppruved,
    },
  ],
  [
    EExperimentStatus.FullCompleted,
    {
      label: StatusPillFullCompleted,
      value: EExperimentStatus.FullCompleted,
    },
  ],
  [EExperimentStatus.Canceled, { label: StatusPillCanceled, value: EExperimentStatus.Canceled }],
]);

export { experimentStatusMap };
