export enum EContextualParentPath {
  ExperimentsExperiment = 'exp/experiments/experiment',
  ExperimentsExperimentZones = 'exp/experiments/experiment/zones',
  ExperimentsExperimentCalculation = 'exp/experiments/experiment/calculation',
  ExperimentsExperimentExecution = 'exp/experiments/experiment/execution',
  ExperimentsExperimentApproval = 'exp/experiments/experiment/approval',
  AuditsAuditReportAudit = 'exp/audits/audit/report/audit',
  AuditsAuditReportTask = 'exp/audits/audit/report/audit/task',
  AuditsAuditPhotos = 'exp/audits/audit/photos',
  AuditsAuditApproval = 'exp/audits/audit/approval',
  AuditsAuditCreate = 'exp/audits/audit/create',
  AuditsAuditEdit = 'exp/audits/audit/edit',
  AuditsAuditView = 'exp/audits/audit/view',
  AuditsAuditTasks = 'exp/audits/audit/tasks',
  AuditsAuditDocuments = 'exp/audits/audit/documents',
  Audits = 'exp/audits',
  ExperimentsExperimentNutritionHistory = 'exp/experiments/experiment/nutritionHistory',
  ExperimentsExperimentComparison = 'exp/experiments/experiment/comparison',
  ExperimentsExperimentTotalComparison = 'exp/experiments/experiment/totalComparison',
  ExperimentsExperimentRemarks = 'exp/experiments/experiment/remarks',
}

export const ContextualPaths = {
  ExperimentsExperimentHeaderTitle: `${EContextualParentPath.ExperimentsExperiment}/header__title`,
  ExperimentsExperimentZonesMapTitle: `${EContextualParentPath.ExperimentsExperimentZones}/map__title`,
  ExperimentsExperimentCalculationNutritionSystemTitle: `${EContextualParentPath.ExperimentsExperimentCalculation}/nutrition-system__title`,
  ExperimentsExperimentExecutionStepsTableTitle: `${EContextualParentPath.ExperimentsExperimentExecution}/steps-table__title`,
  ExperimentsExperimentApprovalTableTitle: `${EContextualParentPath.ExperimentsExperimentApproval}/approval-table__title`,
  AuditsHeaderTitle: `${EContextualParentPath.Audits}/header__title`,
  AuditsAuditReportAuditComparisonTableTitle: `${EContextualParentPath.AuditsAuditReportAudit}/comparison-table__title`,
  AuditsAuditReportAuditTaskTableTitle: `${EContextualParentPath.AuditsAuditReportAudit}/task/task-table__title`,
  AuditsAuditReportAuditCommentTitle: `${EContextualParentPath.AuditsAuditReportAudit}/comment__title`,
  AuditsAuditPhotosPhotosTableTitle: `${EContextualParentPath.AuditsAuditPhotos}/photos-table__title`,
  AuditsAuditApprovalTableTitle: `${EContextualParentPath.AuditsAuditApproval}/approval-table__title`,
  AuditsAuditCreateSummaryTitle: `${EContextualParentPath.AuditsAuditCreate}/summary__title`,
  AuditsAuditEditSummaryTitle: `${EContextualParentPath.AuditsAuditEdit}/summary__title`,
  AuditsAuditViewSummaryTitle: `${EContextualParentPath.AuditsAuditView}/summary__title`,
  AuditsAuditTasksTableTitle: `${EContextualParentPath.AuditsAuditTasks}/tasks-table__title`,
  ExperimentsExperimentNutritionHistoryStepsTableTitle: `${EContextualParentPath.ExperimentsExperimentNutritionHistory}/steps-table__title`,
  ExperimentsExperimentComparisonStepsTableTitle: `${EContextualParentPath.ExperimentsExperimentComparison}/steps-table__title`,
  ExperimentsExperimentTotalComparisonStepsTableIndicators: `${EContextualParentPath.ExperimentsExperimentTotalComparison}/steps-table__indicators`,
  ExperimentsExperimentRemarksRemarkTitleAudit: `${EContextualParentPath.ExperimentsExperimentRemarks}/remark-title__audit`,
  ExperimentsExperimentRemarksRemarkTitleTotalComparison: `${EContextualParentPath.ExperimentsExperimentRemarks}/remark-title__totalComparison`,
  AuditsAuditDocumentsTableTitle: `${EContextualParentPath.AuditsAuditDocuments}/documents-table__title`,
};
