import { FC } from 'react';

import { Plug } from '../../../../../../../../../common/components/ui';
import iconCamera from '../../../../../../../../../common/assets/icons/camera.svg';

import Styled from './AuditPhotosNoDataPlug.styles';

export const AuditPhotosNoDataPlug: FC = () => {
  return (
    <Styled.Wrapper>
      <Plug
        icon={iconCamera}
        title="Здесь будут фотографии по всем участкам"
        description="Фотографии будут разделены по категориям и включены, при необходимости их можно отключить"
      />
    </Styled.Wrapper>
  );
};

AuditPhotosNoDataPlug.displayName = 'AuditPhotosNoDataPlug';
