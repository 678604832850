import { FC, memo, useMemo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { useShowTooltip } from '../../../../utils/hooks';

import { TCellWithTooltipComponentProps } from './CellComponentWithTooltip.type';
import Styled from './CellComponentWithTooltip.styles';

const CellComponentWithTooltip: FC<TCellWithTooltipComponentProps> = ({
  cellInfo,
  children,
  forceShowTooltip,
  showOnlyChildren,
  position,
}) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  const isDisabled = useMemo(
    () => (forceShowTooltip ? !forceShowTooltip : !showTooltip),
    [forceShowTooltip, showTooltip]
  );

  return (
    <AutoTooltip position={position ?? 'top'} content={cellInfo} disabled={isDisabled}>
      <Styled.ContentWrapper>
        {children}
        <Styled.Content ref={ref} $isContentHide={showOnlyChildren}>
          {cellInfo}
        </Styled.Content>
      </Styled.ContentWrapper>
    </AutoTooltip>
  );
};

export default memo(CellComponentWithTooltip);
