import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TRequest = {
  organizationId: string;
  actionCode: string;
};

type TResponse = boolean;

export const getAccessRuleForCurrentUserInOrganization: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: `/api/da-profile/access/action/checkAccess`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
