import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IOperation } from '../../../models/as-fields/operation/Operation/Operation.model';

type TGetOperationReq = {
  id: string;
};

type TGetOperationRes = IOperation;

const getOperation: TApiRoute & {
  request: TGetOperationReq;
  response: TGetOperationRes;
} = {
  url: ({ id }) => `/api/as-fields/operation/${id}`,
  method: 'GET',
  request: {} as TGetOperationReq,
  response: {} as TGetOperationRes,
  headers: {},
};

export type { TGetOperationReq, TGetOperationRes };

export { getOperation };
