import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, isValidElement, memo, PropsWithChildren, useMemo } from 'react';

import { useShowTooltip } from '../../../../../../utils/hooks';
import formatPaddingForColumn from "../../../../helpers/formatPaddingForColumn";

import Styled from './AttributeName.styles';


interface IProps {
  isTitle?: boolean;
  showFullText?: boolean;
  cellPadding: string;
  isFirstColumn?: boolean;
}

const AttributeName: FC<PropsWithChildren<IProps>> = ({
  children,
  isTitle,
  showFullText,
  cellPadding,
  isFirstColumn,
}) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  const formattedCellPadding = useMemo(() => {

    if (isFirstColumn) {
      return formatPaddingForColumn(cellPadding);
    } else {
      return cellPadding;
    }
  }, [cellPadding, isFirstColumn]);

  return (
    <AutoTooltip content={children} disabled={!showTooltip}>
      <Styled.Wrapper
        ref={ref}
        $isTitle={isTitle}
        $showFullText={showFullText}
        $cellPadding={formattedCellPadding}
      >
        {children}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

AttributeName.displayName = 'AttributeName';

export default memo(AttributeName);
