import { observer } from 'mobx-react';
import { FC, memo } from 'react';
import { Switcher } from '@farmlink/farmik-ui';

import { IComparisonTableStageComponentProps as IStageComponentProps } from '../../../../../../../../../common/features/DynamicTable/models/Stage/Stage.model';

import Styled from './AuditPhotosStage.styles';

const AuditPhotosStage: FC<IStageComponentProps> = ({ extendedStage, attributeContainerList }) => {
  const { name } = extendedStage;

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.InnerHeaderWrapper>
          <Switcher />

          <Styled.Name>{name}</Styled.Name>
        </Styled.InnerHeaderWrapper>
      </Styled.Header>

      {attributeContainerList}
    </Styled.Wrapper>
  );
};

AuditPhotosStage.displayName = 'AuditPhotosStage';

export default memo(observer(AuditPhotosStage));
