import { TApiRoute } from '../../models/system/ApiRoute.model';

type TGetFileUploadURLReq = {
  serviceName: string;
  ownerId?: string;
  fileName: string;
  fileSize: number;
  fileContentType: string;
};

type TGetFileUploadURLRes = { id: string; uploadUrl: string };

export const getFileUploadURL: TApiRoute & {
  request: TGetFileUploadURLReq;
  response: TGetFileUploadURLRes;
} = {
  url: `/api/da-files/files`,
  method: 'POST',
  headers: {},
  request: {} as TGetFileUploadURLReq,
  response: {} as TGetFileUploadURLRes,
};

export type { TGetFileUploadURLReq, TGetFileUploadURLRes };
