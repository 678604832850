import { FC, memo, ReactNode } from 'react';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';

import Styled from './ComparisonTableBuilderClearedRowsGroup.styles';

interface IProps {
  rowsChildren: ReactNode;
}

const ComparisonTableBuilderClearedRowsGroup: FC<IProps> = ({ rowsChildren }) => {
  const getDataTestId = useDataTestId({ componentName: 'cleared-rows-group' });

  return <Styled.Wrapper {...getDataTestId()}>{rowsChildren}</Styled.Wrapper>;
};

ComparisonTableBuilderClearedRowsGroup.displayName = 'ComparisonTableBuilderClearedRowsGroup';

export default memo(ComparisonTableBuilderClearedRowsGroup);
