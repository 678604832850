import { FC } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';
import { formatDoubleNum } from '../../../../../../../../../common/utils/helpers/numbers';

import Styled from './ComparisonComparableCell.styles';

interface IProps {
  factValue: string | number;
  dataTestId: string;
  planValue?: string;
  padding?: string;
  margin?: string;
  isBold?: boolean;
}

const ComparisonComparableCell: FC<IProps> = ({
  factValue,
  dataTestId,
  isBold,
  padding,
  margin,
}) => {
  const { ref: factValueRef } = useShowTooltip<HTMLDivElement>();

  const formattedValue =
    typeof factValue === 'string' ? factValue : formatDoubleNum(factValue) ?? '—';

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()} $padding={padding} $margin={margin}>
      <AutoTooltip content={factValue} disabled={typeof factValue === 'undefined'}>
        <Styled.FactLabel ref={factValueRef} $isBold={isBold} {...getDataTestId('fact-value')}>
          {formattedValue}
        </Styled.FactLabel>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

ComparisonComparableCell.displayName = 'ComparisonComparableCell';

export default ComparisonComparableCell;
