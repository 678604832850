import { IOrganization } from '../../../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import { ISelectOption } from '../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { IEmployee, IUser } from '../../../../../../../../../../../api/models/da-profile/users';
import { ISeason } from '../../../../../../../../../../../api/models/as-fields/seasons';
import { IDictionaryEntity } from '../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { IGeoUnit } from '../../../../../../../../../../../api/models/as-fields/geo-unit';
import { IExperimentContract } from '../../../../../../../../../../../api/models/as-fields/experiments';

const createContractorLabel = (orgType: string, orgName: string): string => {
  if (!orgType) {
    return `"${orgName}"`;
  }

  return `${orgType} "${orgName}"`;
};

const createContractorOptionList = (contractorList: IOrganization[]): ISelectOption[] => {
  const optionList: ISelectOption[] = contractorList.map<ISelectOption>(
    ({ name, organizationId, type }) => ({
      label: createContractorLabel(type?.OrgType, name),
      value: organizationId,
    })
  );

  return optionList;
};

const createContractOptionList = (contractList: IExperimentContract[]): ISelectOption[] => {
  const optionList: ISelectOption[] = contractList.map<ISelectOption>(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return optionList;
};

const createEmployeeOptionList = (employeeList: IEmployee[]): ISelectOption[] => {
  const optionList: ISelectOption[] = employeeList.map<ISelectOption>(({ userId, userName }) => ({
    label: userName,
    value: userId,
  }));

  return optionList;
};

const createUserOptionList = (userList: IUser[]): ISelectOption[] => {
  const optionList: ISelectOption[] = userList.map<ISelectOption>(({ id, fullName }) => ({
    label: fullName?.trim(),
    value: id,
  }));

  return optionList;
};

const createSeasonOptionList = (seasonList: ISeason[]): ISelectOption[] => {
  const optionList: ISelectOption[] = seasonList.map<ISelectOption>(({ label, year }) => ({
    label,
    value: year.toString(),
  }));

  return optionList;
};

const createDictionaryEntityOptionList = (entityList: IDictionaryEntity[]): ISelectOption[] => {
  const optionList: ISelectOption[] = entityList.map<ISelectOption>(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return optionList;
};

const createRegionOptionList = (regionList: IGeoUnit[]): ISelectOption[] => {
  const optionList: ISelectOption[] = regionList.map<ISelectOption>(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return optionList;
};

const ExperimentAttributesHelpers = {
  createContractorOptionList,
  createContractOptionList,
  createEmployeeOptionList,
  createUserOptionList,
  createSeasonOptionList,
  createDictionaryEntityOptionList,
  createRegionOptionList,
};

export default ExperimentAttributesHelpers;
