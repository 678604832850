import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Input } from '@farmlink/farmik-ui';
import { debounce } from 'lodash';

import { useDataTestIdV2 } from '../../../../../../../../../../common/utils/hooks/locators';
import { ValidationCollection } from '../../../../../../../../../../common/features/form/utils/helpers/UniFormHelpers';

import Styled from './ExecutionDoubleCell.styles';

const validationScheme = {
  regExp: {
    expression: new RegExp(/^\d+(,|\.)?(\d{1,2})?$/),
    errorTitle: 'Ошибка валидации',
  },
};

interface IProps {
  initialValue: string | number;
  updatedValue: string | number;
  onChange: (newValue: string, entityId?: string) => void;
  dataTestId: string;
  /**
   * Требуется для изменения ТМЦ.
   * (В противном случае, из onChange передается неактуальное значение.
   * Почему? Хороший вопрос. Пока ответ найти не удалось,
   * так как вся мемоизация работает корректно.)
   */
  entityId?: string;
}

const ExecutionDoubleCell: FC<IProps> = ({
  initialValue,
  updatedValue,
  onChange,
  dataTestId,
  entityId,
}) => {
  const [enteredValue, setEnteredValue] = useState<string | number>('');

  useEffect(() => {
    setEnteredValue(updatedValue ?? initialValue ?? '');
  }, [initialValue, updatedValue]);

  const debounceOnChange = useRef(debounce(onChange, 500));

  const handleInputChange = useCallback(
    (newValue: string): void => {
      const validationResult = ValidationCollection.validateElementByScheme(
        newValue,
        validationScheme
      );

      if (!(validationResult instanceof Error)) {
        setEnteredValue(newValue);
        debounceOnChange.current(newValue, entityId);
      } else {
        setEnteredValue(prevValue => prevValue ?? '');
      }
    },
    [onChange, entityId]
  );

  const getDataTestId = useDataTestIdV2(`${dataTestId}-double-cell`);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Input
        isInline
        placeholder={'Внесите данные'}
        value={enteredValue}
        onChange={handleInputChange}
        maxLength={16}
        dataTestId={getDataTestId('input')['data-test-id']}
      />
    </Styled.Wrapper>
  );
};

ExecutionDoubleCell.displayName = 'ExecutionDoubleCell';

export default memo(ExecutionDoubleCell);
