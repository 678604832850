import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ProgressBarStyles = {
  Wrapper,
};

export default ProgressBarStyles;
