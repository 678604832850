export const formatBooleanValueList = (booleanValue: boolean[]): string => {
  const yesOrNoList = booleanValue.map(value => {
    if (value) {
      return 'да';
    } else {
      return 'нет';
    }
  });

  return yesOrNoList.join(', ');
};
