import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { UniFormHelpers } from '../../../utils/helpers';
import { IFormConfig, IFormElement, TFormValue } from '../../../models';
import { FormService } from '../../services';
import { FormStore } from '../../stores';
import { ISelectOption } from '../../../../../components/form/Dropdown/Dropdown.types';
import { IDatePickerOptions, IPaginationConfig } from '../../../models/FormConfig/Form.model';

import { FormControllerValidationHelpers } from './utils/helpers';

@provide.singleton()
class FormController {
  @lazyInject(FormStore)
  protected formStore: FormStore;

  @lazyInject(FormService)
  protected formService: FormService;

  submitForm = async <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    handler: (from: F) => Promise<any>
  ): Promise<void> => {
    const {
      getForm,
      getElements,
      setFocusedElementName,
      setIsElementFocused,
      setElements,
      setInvalidElements,
    } = this.formStore;

    const { submitForm } = this.formService;

    const { validateForm, getNameOfTheFirstElement } = FormControllerValidationHelpers;

    /**
     * Очищаем данные последнего отслеживаемого измененного элемента, так как если
     * данные валидные, то убираем флаг, который показывает, что присутствуют
     * измененные данные формы. Это нужно для того, чтобы лишний раз, перед отправкой
     * формы и переходом на новый роут, не триггерить модалку о потере несохраненных
     * данных.
     */
    this.clearLastChangedElementData();

    const form = getForm<F>(formKey);
    const elements = getElements<F>(formKey);

    if (!form || !elements) {
      return;
    }

    Object.keys(form).forEach(key => {
      const formFieldValue = form[key];

      if (typeof formFieldValue === 'string') {
        form[key] = formFieldValue.trim();
      }
    });

    const invalidElements = validateForm<F>(form, elements);

    if (invalidElements) {
      setElements(formKey, { ...elements, ...invalidElements });
      setInvalidElements(formKey, invalidElements);

      const nameOfTheFirstElement = getNameOfTheFirstElement<F>(invalidElements);

      setFocusedElementName(nameOfTheFirstElement);
      setIsElementFocused(true);

      return;
    }

    await submitForm(form, handler);
  };

  registerForm = <F extends Record<keyof F, TFormValue>>({
    formKey,
    form,
    elements,
  }: IFormConfig<F>): void => {
    const { setForm, setElements, setInitialForm } = this.formStore;

    setForm(formKey, form);
    setInitialForm(formKey, form);
    setElements(formKey, elements);
  };

  /**
   * @param formKey - ключ формы;
   * @param elementName - наименование (ключ) элемента формы, в который вносятся изменения;
   * @param partialData - данные, что нужно изменить формата { ключ: значение };
   * @param options - различные настройки.
   */
  onFormValueChange = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    partialData: Partial<F>,
    options?: {
      isDoNotChangeSchema?: boolean;
      isIgnoreChangeTracking?: boolean;
    }
  ): void => {
    const { getElement, setLastChangedElement, setFormValue, setElement, deleteInvalidElement } =
      this.formStore;

    const element = getElement<F>(formKey, elementName);

    const defaultSchema: IFormElement<F>['schema'] = {
      ...(element?.schema || { errorTitle: '', isShownError: false }),
    };
    const changedSchema: IFormElement<F>['schema'] = { ...defaultSchema, isShownError: false };
    const schema: IFormElement<F>['schema'] = options?.isDoNotChangeSchema
      ? defaultSchema
      : changedSchema;

    const changedElement: IFormElement<F> = {
      ...element,
      schema,
    };

    setElement<F>(formKey, elementName, changedElement);
    setFormValue<F>(formKey, partialData);

    /**
     * В случае, если в данном поле ошибка, но, при этом, оно зависит от другого, которое
     * было изменено и, следовательно, нам нужно обнулить данное поле, то не удаляем из
     * коллекции "элементов с ошибкой" (если нам нужно удалить ошибку, то нужно использовать
     * параметр isRemoveError, который спрячет ошибки)
     */
    if (!options?.isDoNotChangeSchema) {
      /**
       * Данная запись нужна для того, что если в схеме мы больше не показываем ошибку, то
       * удаляем данный элемент из коллекции "элементов с ошибкой". Данная коллекция
       * служит для скролла к неправильному элементу и разблокированию кнопки 'submit'
       */
      deleteInvalidElement(formKey, elementName);
    }

    if (!options?.isIgnoreChangeTracking) {
      setLastChangedElement(changedElement);
    }
  };

  getFirstElementIdWithError = <F extends Record<keyof F, TFormValue>>(formKey: string) => {
    const { getElements } = this.formStore;

    const elements = getElements<F>(formKey);

    const elementsArr = Object.entries(elements as any);

    const elementName = (
      elementsArr.find((element: [string, any]) => {
        return element?.[1]?.schema?.isShownError;
      })?.[1] as any
    )?.name;

    const { createElementId } = UniFormHelpers;

    return createElementId(formKey, elementName);
  };

  changeInitialFormValue = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    partialData: Partial<F>
  ) => {
    const { setInitialFormValue } = this.formStore;
    setInitialFormValue<F>(formKey, partialData);
  };

  changeListOfFormValue = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    partialData: Partial<F>,
    isRemoveError: boolean
  ) => {
    const dataEntries = Object.entries(partialData);

    dataEntries.forEach(([elementName, data]) => {
      this.onFormValueChange(formKey, elementName as any, { [elementName]: data } as Partial<F>, {
        isDoNotChangeSchema: !isRemoveError,
        isIgnoreChangeTracking: true,
      });
    });
  };

  setElementErrorShow = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    isShowError: boolean,
    errorMessage?: string
  ) => {
    const { setElementError } = this.formStore;

    setElementError(formKey, elementName, isShowError, errorMessage);
  };

  changeElementData = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    partialData: Partial<IFormElement<F>>
  ) => {
    const { setElementData } = this.formStore;

    setElementData(formKey, elementName, partialData);
  };

  addOptionList = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    optionList: ISelectOption[]
  ) => {
    const { setElementOptionList } = this.formStore;

    setElementOptionList(formKey, elementName, optionList);
  };

  addDatePickerParams = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    params: IDatePickerOptions
  ) => {
    const { setElementDatePickerParams } = this.formStore;

    setElementDatePickerParams(formKey, elementName, params);
  };

  addPaginationConfig = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    paginationConfig: IPaginationConfig
  ) => {
    const { setElementPaginationConfig } = this.formStore;

    setElementPaginationConfig(formKey, elementName, paginationConfig);
  };

  blockElement = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    isBlocked: boolean
  ) => {
    const { setElementIsBlocked } = this.formStore;

    setElementIsBlocked(formKey, elementName, isBlocked);
  };

  addSearchQueryHandler = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    handler: (v: string) => Promise<ISelectOption[]>
  ) => {
    const { setElementSearchQueryHandler } = this.formStore;

    setElementSearchQueryHandler(formKey, elementName, handler);
  };

  changeElementLabel = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    label: string
  ) => {
    const { setElementLabel } = this.formStore;

    setElementLabel(formKey, elementName, label);
  };

  focusChangedElement = (): void => {
    const { lastChangedElement, setFocusedElementName, setIsElementFocused } = this.formStore;

    if (!lastChangedElement) {
      return;
    }

    setFocusedElementName<any>(lastChangedElement.name);
    setIsElementFocused(true);
  };

  focusElement = <F extends Record<keyof F, TFormValue>>(elementName: keyof F) => {
    const { setFocusedElementName, setIsElementFocused } = this.formStore;

    setFocusedElementName<any>(elementName);
    setIsElementFocused(true);
  };

  clearLastChangedElementData = (): void => {
    const { clearFocusedElementName, clearIsElementFocused, clearLastChangedElement } =
      this.formStore;

    clearFocusedElementName();
    clearIsElementFocused();
    clearLastChangedElement();
  };

  clearForm = (formKey: string): void => {
    const { deleteForm, deleteFormElements, deleteFormInvalidElements } = this.formStore;

    deleteForm(formKey);
    deleteFormElements(formKey);
    deleteFormInvalidElements(formKey);

    this.clearLastChangedElementData();
  };
}

export default FormController;
