import styled from 'styled-components';

const MenuContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const StyledExperimentOptionsCell = {
  MenuContent,
};

export default StyledExperimentOptionsCell;
