import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { IExperimentContract } from '../../../../models/as-fields/experiments';

type TDeleteExperimentContractReq = { id: string };

type TDeleteExperimentContractRes = IExperimentContract;

const deleteExperimentContract: TApiRoute & {
  request: TDeleteExperimentContractReq;
  response: TDeleteExperimentContractRes;
} = {
  url: ({ id }) => `/api/as-fields/experiment/contract/${id}`,
  method: 'DELETE',
  request: {} as TDeleteExperimentContractReq,
  response: {} as TDeleteExperimentContractRes,
  headers: {},
};

export type { TDeleteExperimentContractReq, TDeleteExperimentContractRes };

export { deleteExperimentContract };
