import { makeAutoObservable } from 'mobx';

import { ContractSidePanelService } from '../../services';
import { ContractSidePanelStore } from '../../stores';
import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { AccessController } from '../../../../../../../../common/mobx/controllers/AccessController';

@provide.transient()
class ContractSidePanelController {
  @lazyInject(ContractSidePanelService)
  protected contractSidePanelService: ContractSidePanelService;

  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(ContractSidePanelStore)
  contractSidePanelStore: ContractSidePanelStore;

  @lazyInject(AccessController)
  accessController: AccessController;

  getAccessToEditContract = async (organizationId: string) => {
    const { getAccessRuleForCurrentUserInOrganization } = this.accessController;
    const { setAccessToEditContracts } = this.contractSidePanelStore;

    const hasAccess = await getAccessRuleForCurrentUserInOrganization({
      organizationId,
      rule: 'experiments.editContracts',
    });

    setAccessToEditContracts(hasAccess);
  };
}

export default ContractSidePanelController;
