import styled from 'styled-components';
import { Button as InitialButton } from '@farmlink/farmik-ui';

const Button = styled(InitialButton)`
  width: 240px;
  height: 40px;

  border-radius: 12px;
`;

const StyledExperimentsListDefaultPlug = {
  Button,
};

export default StyledExperimentsListDefaultPlug;
