import { provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { IComparisonTableBuilderColumnConfig as IColumnConfig } from '../../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import { IExperimentPlanHeader as IHeader } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentPlanTable/ExperimentPlanTable.model';
import { ComparisonColumn as Column } from '../../../../components';

@provide.transient()
class CommonComparisonColumnsService {
  createColumnConfigList = (headerList: IHeader[]): IColumnConfig[] => {
    const initialList: IColumnConfig[] = [this.createMainColumnConfig()];

    return headerList.reduce<IColumnConfig[]>((list, header) => {
      list.push(this.createColumnConfig(header));

      return list;
    }, initialList);
  };

  protected createMainColumnConfig = (): IColumnConfig => {
    return {
      id: 'main',
      width: '260px',
      isFixed: true,
      customRenderConfig: {
        render: (column, dataTestId) => (
          <Column name={'Показатели'} dataTestId={dataTestId} isMain />
        ),
      },
    };
  };

  protected createColumnConfig = ({ id, name }: IHeader): IColumnConfig => {
    return {
      id,
      width: '188px',
      customRenderConfig: {
        render: (column, dataTestId) => <Column name={name} dataTestId={dataTestId} />,
      },
    };
  };
}

export default CommonComparisonColumnsService;
