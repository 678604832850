import { FC, memo, useMemo } from 'react';

import { IEconomyData } from '../../../../model/IEconomyData.model';
import { ConsumptionCell as CellContent } from '../../content';

import Styled from './ConsumptionCell.styles';

interface IConsumptionCellComponentProps {
  row: Pick<IEconomyData, 'id' | 'itemsList' | 'protectionItemsList'>;
}

const ConsumptionCellComponent: FC<IConsumptionCellComponentProps> = ({ row }) => {
  const isShowContent = useMemo(
    () => row?.itemsList?.length > 0 || row?.protectionItemsList?.length > 0,
    [row?.itemsList?.length, row?.protectionItemsList?.length]
  );

  return (
    <>
      {isShowContent ? (
        <Styled.Wrapper data-test-id={`consumption-cell-wrapper-${row?.id}`}>
          {row.itemsList.map(value => (
            <CellContent
              key={value.id}
              consumption={value.concentration}
              unitOfMeasure={value.unitOfMeasure?.name}
            />
          ))}
          {row.protectionItemsList.map(value => (
            <CellContent
              key={value.id}
              consumption={value.concentration}
              unitOfMeasure={value.unitOfMeasure?.name}
            />
          ))}
        </Styled.Wrapper>
      ) : (
        <Styled.NoInfo>—</Styled.NoInfo>
      )}
    </>
  );
};

ConsumptionCellComponent.displayName = 'ConsumptionCellComponent';

export default memo(ConsumptionCellComponent);
