import { TFormValue } from '../../../models';

type TUniFormElementId = `uni-form-element-${string}-${string}`;

const createElementId = <F extends Record<keyof F, TFormValue>>(
  formKey: string,
  elementName: string | number | symbol
): TUniFormElementId | undefined => {
  if (!formKey && !elementName) {
    return;
  }

  return `uni-form-element-${formKey}-${String(elementName)}`;
};

const UniFormHelpers = { createElementId };

export type { TUniFormElementId };

export default UniFormHelpers;
