import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';

import { useStore } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionDoubleCell } from '../../../../../components/cells';
import { ExecutionStore } from '../../../../../mobx/stores';
import { ExecutionController } from '../../../../../mobx/controllers';
import { getExecutionTableAttributeIdByRowId as getAttributeIdByRowId } from '../../../../../helpers';
import { TExecutionStepsCell } from '../../../types';

interface IProps {
  cell: TExecutionStepsCell;
  dataTestId: string;
}

const ExecutionStepsStepDoubleCell: FC<IProps> = ({ cell, dataTestId }) => {
  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);

  const attributeId = useMemo(() => getAttributeIdByRowId(cell.rowId), []);

  const updatedValue = executionStore.getStepForUpdate(
    executionStore.selectedZoneId,
    cell.initialModel.stepId
  )?.[attributeId] as string;

  const handleChange = useCallback((newValue: string) => {
    executionController.changeStep(cell.initialModel.stepId, { [attributeId]: newValue });
  }, []);

  return (
    <ExecutionDoubleCell
      initialValue={cell.initialModel.doubleValue}
      updatedValue={updatedValue}
      onChange={handleChange}
      dataTestId={dataTestId}
    />
  );
};

ExecutionStepsStepDoubleCell.displayName = 'ExecutionStepsStepDoubleCell';

export default observer(ExecutionStepsStepDoubleCell);
