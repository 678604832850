import { IAccessRule } from '../../../../../../api/models/da-profile/access/Access.model';
import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';

@provide.singleton()
class AccessService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getAllActiveGlobalActionsForCurrentUser = (): Promise<void | IAccessRule[]> => {
    return this.axiosService.api
      .getAllActiveGlobalActionsForCurrentUser({})
      .then(response => {
        return response;
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  getAccessRuleForCurrentUserInOrganization = ({
    organizationId,
    rule,
  }: {
    organizationId: string;
    rule: string;
  }): Promise<boolean> => {
    return (
      this.axiosService.api
        // @ts-ignore
        .getAccessRuleForCurrentUserInOrganization({ organizationId, actionCode: rule })
        .then(response => {
          return response;
        })
    );
  };
}

export default AccessService;
