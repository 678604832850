import { IExperimentFactTableValue } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';

const createExecutionPhenophaseName = (value: IExperimentFactTableValue): string => {
  const [firstValue] = value.dictionaryValues;

  if (firstValue.name) {
    return `${firstValue.name} (${firstValue.code})`;
  }

  return firstValue.name;
};

export default createExecutionPhenophaseName;
