import { FC, memo, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Slider from '../../components/Slider/Slider';
import { SliderContext } from '../../context';
import { useStore } from '../../../../utils/helpers/mobx';
import { DynamicTableStore } from '../../mobx/stores';

interface ISliderContainerProps {
  tableId?: string;
  collapsingStageId?: string;
  /**
   * Если у контейнера есть данный флаг,
   * то он будет автоматически показывать/скрывать стрелки слайдера.
   */
  isControl?: boolean;
}

const SliderContainer: FC<PropsWithChildren<ISliderContainerProps>> = ({
  children,
  tableId,
  collapsingStageId,
  isControl,
}) => {
  const { getCollapsingStage } = useStore(DynamicTableStore);
  const {
    clickedArrow,
    scrollValue,
    onClearClickedArrow,
    onDisableArrow,
    onToggleControlsVisibility,
  } = useContext(SliderContext);

  const [isNeedToUpdateScroll, setIsNeedToUpdateScroll] = useState<boolean>(false);

  const collapsingStage = getCollapsingStage(tableId, collapsingStageId);

  useEffect(() => {
    if (!collapsingStage?.isCollapsed) {
      setIsNeedToUpdateScroll(true);
    }
  }, [collapsingStage?.isCollapsed]);

  const handleClearIsNeedToUpdateScroll = useCallback(() => {
    setIsNeedToUpdateScroll(false);
  }, []);

  return (
    <Slider
      clickedArrow={clickedArrow}
      scrollValue={scrollValue}
      scrollValueToChange={204}
      paddingToAdd={12}
      isNeedToUpdateScroll={isNeedToUpdateScroll}
      onClearClickedArrow={onClearClickedArrow}
      onClearIsNeedToUpdateScroll={handleClearIsNeedToUpdateScroll}
      onDisableArrow={onDisableArrow}
      onToggleControlsVisibility={onToggleControlsVisibility}
      isControl={isControl}
    >
      {children}
    </Slider>
  );
};

SliderContainer.displayName = 'SliderContainer';

export default memo(observer(SliderContainer));
