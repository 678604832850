import React, { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';

import { TableFiltersBuilderController as Controller } from '../../../mobx/controllers';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { useTableFiltersBuilderUIContext as useUIContext } from '../../../context/UI/TableFiltersBuilderUIContext/TableFiltersBuilderUI.context';
import { useStore } from '../../../../../utils/helpers/mobx';

import Styled from './TableFiltersBuilderGrid.styles';

interface IProps {
  contentKey: 'filters' | 'presets';
}

const TableFiltersBuilderGrid: FC<PropsWithChildren<IProps>> = ({ contentKey, children }) => {
  const controller = useStore(Controller);

  const UIContext = useUIContext();

  const techSizesByScreen = controller.getTechSizesByScreen(UIContext.builderId, contentKey);

  const getDataTestId = useDataTestId();

  return (
    <Styled.Grid $techSizesByScreen={techSizesByScreen} {...getDataTestId('grid')}>
      {children}
    </Styled.Grid>
  );
};

TableFiltersBuilderGrid.displayName = 'TableFiltersBuilderGrid';

export default observer(TableFiltersBuilderGrid);
