import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { Outlet } from 'react-router-dom';

type TBrowserTitle = {
  title: string;
};

export const BrowserTitle: FC<TBrowserTitle> = ({ title }) => {
  return (
    <>
      <Helmet>
        <title>ДА.Опыты. {title}</title>
      </Helmet>
      <Outlet />
    </>
  );
};
