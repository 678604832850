import React from 'react';

import StyledDocumentsActionsIcon from '../DocumetnsActionIcon.styles';

const DownloadIcon = ({ fill, hoverFill }) => {
  return (
    <StyledDocumentsActionsIcon.filledSvg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={fill}
      hoverFill={hoverFill}
    >
      <path d="M7.00366 9.32426C7.15014 9.32426 7.27465 9.28163 7.42113 9.13951L9.89662 6.81585C10.0065 6.70926 10.0724 6.59556 10.0724 6.44634C10.0724 6.15499 9.83803 5.94892 9.53775 5.94892C9.39859 5.94892 9.24479 6.00577 9.14225 6.11946L8.02901 7.26353L7.53831 7.76805L7.58225 6.70215V0.554267C7.58225 0.255815 7.31859 0 7.00366 0C6.68873 0 6.41775 0.255815 6.41775 0.554267V6.70215L6.46169 7.76805L5.97099 7.26353L4.85775 6.11946C4.75521 6.00577 4.59408 5.94892 4.45493 5.94892C4.14732 5.94892 3.92761 6.15499 3.92761 6.44634C3.92761 6.59556 3.99352 6.70926 4.10338 6.81585L6.57887 9.13951C6.72535 9.28163 6.84986 9.32426 7.00366 9.32426ZM2.79972 14H11.2003C12.731 14 13.5 13.261 13.5 11.7971V4.70537C13.5 3.24154 12.731 2.50252 11.2003 2.50252H9.1569V3.64658H11.1783C11.9034 3.64658 12.3208 4.0303 12.3208 4.76933V11.7332C12.3208 12.4722 11.9034 12.8559 11.1783 12.8559H2.81437C2.08197 12.8559 1.67915 12.4722 1.67915 11.7332V4.76933C1.67915 4.0303 2.08197 3.64658 2.81437 3.64658H4.8431V2.50252H2.79972C1.26901 2.50252 0.5 3.24154 0.5 4.70537V11.7971C0.5 13.261 1.26901 14 2.79972 14Z" />
    </StyledDocumentsActionsIcon.filledSvg>
  );
};

export default DownloadIcon;
