import { FC, memo } from 'react';

import Styled from './ActiveSubstancesColumn.style';

interface IActiveSubstancesColumn {
  columnName: string;
}

const ActiveSubstancesColumn: FC<IActiveSubstancesColumn> = ({ columnName }) => {
  return <Styled.Column>{columnName}</Styled.Column>;
};

ActiveSubstancesColumn.displayName = 'ActiveSubstancesColumn';

export default memo(ActiveSubstancesColumn);
