import styled from 'styled-components';

const HeaderRowWrapper = styled.tr<{ existHeaderBorder?: boolean }>`
  position: sticky;
  top: 0;
  height: 36px;
  z-index: 9;
  background: ${({ theme }) => theme?.Colors?.generalLight ?? '#ffffff'};
  ${({ existHeaderBorder, theme }) =>
    existHeaderBorder ? `border-bottom: 1px solid ${theme.Colors.borderDefault}` : ''}
`;

type TStyledHeaderCellWrapperProps = {
  $columnWidth: string | number;
  existHeaderBorder?: boolean;
};

const getCellWidth = ({ $columnWidth }) => {
  return typeof $columnWidth === 'number' ? `${$columnWidth + 16}px` : `${$columnWidth}`;
};

const HeaderCellWrapper = styled.td<TStyledHeaderCellWrapperProps>`
  color: #7e8b9d;
  table-layout: fixed;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: ${getCellWidth};
  vertical-align: top;
`;

const StyledTableHeader = {
  HeaderCellWrapper,
  HeaderRowWrapper,
};

export default StyledTableHeader;
