import React, { FC, MouseEvent, memo, useCallback } from 'react';

import Styled from './TableFiltersBuilderPreset.styles';

interface IProps {
  id: string;
  name: string;
  isSelected?: boolean;
  onClick?: (id: string) => void;
  dataTestId?: string;
}

const TableFiltersBuilderPreset: FC<IProps> = ({ id, name, isSelected, onClick, dataTestId }) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      onClick?.(id);
    },
    [id, onClick]
  );

  return (
    <Styled.Wrapper onClick={handleClick} data-test-id={dataTestId} $isSelected={isSelected}>
      {name}
    </Styled.Wrapper>
  );
};

TableFiltersBuilderPreset.displayName = 'TableFiltersBuilderPreset';

export default memo(TableFiltersBuilderPreset);
