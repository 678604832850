import { FC, memo } from 'react';

import { SpinnerLoader } from '../ui/loaders';

import { TableHeader, TableBodyRow } from './components';
import Styled from './Table.styles';
import { TTableProps } from './Table.types';

const TableComponent: FC<TTableProps> = ({
  tableHeadData,
  tableBodyData,
  onRowClick,
  onAdditionalRowClick,
  isAllowToWatchTableData = true,
  actions,
  plugComponent,
  selectedRowId,
  additionalRowsName,
  additionalRowsHead,
  config,
  existHeaderBorder,
  isLoading,
}) => {
  const Plug = plugComponent;

  if (!tableBodyData && isLoading) {
    return <SpinnerLoader needToHideContent={false} />;
  }

  if (!tableBodyData?.length || !isAllowToWatchTableData) {
    return Plug && <Plug />;
  }

  return (
    <Styled.Table data-test-id="table">
      <thead data-test-id="table-head">
        <TableHeader
          existHeaderBorder={existHeaderBorder}
          tableHeadData={tableHeadData}
          data-test-id="table-header"
        />
      </thead>
      <tbody data-test-id="table-body">
        {tableBodyData.map(bodyItem => {
          return (
            <TableBodyRow
              key={bodyItem.id}
              rowData={bodyItem}
              tableHeadData={tableHeadData}
              actions={actions}
              onRowClick={onRowClick}
              onAdditionalRowClick={onAdditionalRowClick}
              data-test-id={`table-row${bodyItem.id}`}
              isSelected={selectedRowId ? selectedRowId === bodyItem?.id : false}
              additionalRowsName={additionalRowsName}
              additionalRowsHead={additionalRowsHead}
              config={config}
            />
          );
        })}
      </tbody>
    </Styled.Table>
  );
};

const Table = memo(TableComponent);

Table.displayName = 'Table';

export default Table;
