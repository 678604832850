import styled from 'styled-components';
import { Button } from '@farmlink/farmik-ui';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

const { White } = ECommonColor;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #151f32;
`;

const Header = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid #e9e6f0; ;
`;

const AttributeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1 1 40%;
`;

const Attribute = styled.div<{ marginBottom?: string; width?: string; marginRight?: string }>`
  flex: 1 1 ${({ width }) => width || '40%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  margin-right: ${({ marginRight }) => marginRight || 0};
`;

const Body = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: row;
`;

const Container = styled.div`
  background-color: ${White};
  border-radius: 16px;
  padding: 24px;
`;

const Wrapper = styled.form`
  width: 912px;
  margin: 24px auto 0;

  & ${Container}:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const AddButton = styled.button`
  border: none;
  background-color: transparent;
  color: #3fb65f;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 16px auto 0 0;
  letter-spacing: -0.15px;
  :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: default;
    color: #7e8b9d;
  }
`;

const ChallengesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SubTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #151f32;
`;

const ChallengeContainer = styled.div`
  padding-top: 16px;
`;

const ButtonWrapper = styled(Button)`
  width: 300px;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
`;

const StyledRequisites = {
  AttributeWrapper,
  Attribute,
  Title,
  Header,
  Body,
  Wrapper,
  Container,
  AddButton,
  ChallengesWrapper,
  SubTitle,
  ChallengeContainer,
  ButtonWrapper,
};

export default StyledRequisites;
