import { FC, useEffect } from 'react';
import { useContextualHelpActions, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { EExperimentStatus } from '../../../../../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { useMap } from '../../../../../../../../../common/features/experimentsMap/hooks';
import {
  useErrorModal,
  useWarningBeforeLeavingThePage,
} from '../../../../../../../../../common/utils/hooks';
import { useSelectedExp, useZonesPlugResolver } from '../../../../hooks';
import { SpinnerLoader } from '../../../../../../../../../common/components/ui/loaders';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';
import { NO_ZONES_TITLE } from '../Zones/constants/titles';

import { ZonesList, ZonesMap } from './components';
import { ZonesController } from './mobx/controllers';
import { ZonesStore } from './mobx/stores';
import { zonesModalConfig } from './config/modals';
import Styled from './Zones.styles';

const Zones: FC = () => {
  const {
    isAllowMapAccess,
    isZonesChanged,
    hasControlZone,
    tableHasChanged,
    setTableHasChanged,
    fwExpCultureZoneListWithoutPolyId,
  } = useStore(ZonesStore);
  const { fetchCultureZoneList, saveExpZoneList, allowCultureZonesDrawing, clearStore } =
    useStore(ZonesController);

  const {
    drawCultureZoneList,
    clearStore: clearMapStore,
    mapInstance,
    clearPolygonList,
  } = useMap();
  const { registerModalList } = useModal();

  const { showErrorModal } = useErrorModal();

  const selectedExp = useSelectedExp({ isClearOnUnmount: true });

  const {
    loading,
    showPlug,
    plugType,
    plugTitle,
    Plug: ZonesPlug,
  } = useZonesPlugResolver(selectedExp?.id, NO_ZONES_TITLE, NO_ZONES_TITLE);

  useWarningBeforeLeavingThePage(isZonesChanged);

  usePageContextualHelp(EContextualParentPath.ExperimentsExperimentZones);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.ExperimentsExperimentZones,
    ContextualPaths.ExperimentsExperimentZonesMapTitle
  );

  useEffect(() => {
    (async () => {
      if (selectedExp) {
        await fetchCultureZoneList({ experimentId: selectedExp.id });
      }
    })();
  }, [selectedExp]);

  useEffect(() => {
    if (isAllowMapAccess && mapInstance) {
      allowCultureZonesDrawing(drawCultureZoneList);
    }
  }, [isAllowMapAccess, mapInstance, fwExpCultureZoneListWithoutPolyId]);

  useEffect(() => {
    registerModalList(zonesModalConfig.configList, zonesModalConfig.key);

    return () => {
      clearMapStore();
      clearStore();
    };
  }, []);

  const handleContinueClick = () => {
    saveExpZoneList(showErrorModal)
      .then(() => {
        setTableHasChanged(false);
        clearPolygonList();
      })
      .catch(() => {
        setTableHasChanged(true);
      });
  };

  if (loading) return <SpinnerLoader needToHideContent={false} />;

  if (showPlug) {
    return <ZonesPlug type={plugType} title={plugTitle} />;
  }

  return (
    <Styled.Wrapper>
      <Styled.Container title={'Участки для опыта'} ContextualHelpIcon={ContextualHelpIcon}>
        {isAllowMapAccess && (
          <>
            <ZonesMap />
            <ZonesList />
          </>
        )}
      </Styled.Container>
      {selectedExp?.status === EExperimentStatus.Draft && (
        <Styled.ButtonWrapper
          type="button"
          color="primary"
          disabled={!isAllowMapAccess || !hasControlZone || !tableHasChanged}
          onClick={handleContinueClick}
        >
          Сохранить
        </Styled.ButtonWrapper>
      )}
    </Styled.Wrapper>
  );
};

Zones.displayName = 'Zones';

export default observer(Zones);
