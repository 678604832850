import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { IExperimentCultureZone } from '../../../../../../../../../../../api/models/as-fields/experiments';
import { TComparisonTabs } from '../../../models';
import { IExperimentTotalComparisonHeader as IHeader } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentTotalComparisonTable/ExperimentTotalComparisonTable';
import { IExperimentPlanTable } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentPlanTable/ExperimentPlanTable.model';

@provide.singleton()
class ComparisonStore {
  private _idToExpCultureZone: Map<string, IExperimentCultureZone> = new Map<
    string,
    IExperimentCultureZone
  >();
  private _activeTab: TComparisonTabs = 'common';
  private _headersByPosition: Map<number, IHeader> = new Map();
  private _headerList: IHeader[] = [];
  private _comparisonTablesList: IExperimentPlanTable[] = [];
  private _selectedFields: Array<{ id: string }> = [];
  private _isPageLoading = true;
  private _isComparisonDataExist = false;

  constructor() {
    makeAutoObservable(this);
  }

  get expCultureZoneList(): IExperimentCultureZone[] {
    return Array.from(this._idToExpCultureZone.values());
  }

  get activeTab(): TComparisonTabs {
    return this._activeTab;
  }

  get headerList() {
    return this._headerList;
  }

  get comparisonTableList() {
    return this._comparisonTablesList;
  }

  get selectedFields() {
    return this._selectedFields;
  }

  get isPageLoading() {
    return this._isPageLoading;
  }

  get isComparisonDataExist() {
    return this._isComparisonDataExist;
  }

  getHeaderByPosition = (position: number): IHeader => {
    return this._headersByPosition.get(position);
  };

  setExpCultureZone = (expCultureZone: IExperimentCultureZone): void => {
    this._idToExpCultureZone.set(expCultureZone.id, expCultureZone);
  };

  setExpCultureZoneList = (expCultureZoneList: IExperimentCultureZone[]): void => {
    expCultureZoneList.forEach(expCultureZone => {
      this.setExpCultureZone(expCultureZone);
    });
  };

  setActiveTab = (tab: TComparisonTabs): void => {
    this._activeTab = tab;
  };

  setHeaderList = (headerList: IHeader[]): void => {
    this._headerList = headerList;

    headerList.forEach((header, i) => {
      this._headersByPosition.set(i, header);
    });
  };

  setComparisonTableList = (comparisonTableList: IExperimentPlanTable[]): void => {
    this._comparisonTablesList = comparisonTableList;
  };

  setSelectedFields = (selectedFields: Array<{ id: string }>): void => {
    this._selectedFields = selectedFields;
  };

  setIsPageLoading = (value: boolean): void => {
    this._isPageLoading = value;
  };

  setIsComparisonDataExist = (value: boolean): void => {
    this._isComparisonDataExist = value;
  };

  clearIdToExpCultureZone = (): void => {
    this._idToExpCultureZone.clear();
  };

  clearActiveTab = (): void => {
    this._activeTab = 'common';
  };

  clearHeadersByPosition = (): void => {
    this._headersByPosition.clear();
  };

  clearHeaderList = (): void => {
    this._headerList = [];
  };

  clearComparisonTableList = (): void => {
    this._comparisonTablesList = [];
  };

  clearSelectedFields = (): void => {
    this._selectedFields = [];
  };

  clearIsPageLoading = (): void => {
    this._isPageLoading = true;
  };

  clearIsComparisonDataExist = (): void => {
    this._isComparisonDataExist = false;
  };

  clearStore = (): void => {
    this.clearIdToExpCultureZone();
    this.clearActiveTab();
    this.clearHeadersByPosition();
    this.clearHeaderList();
    this.clearComparisonTableList();
    this.clearIsPageLoading();
    this.clearIsComparisonDataExist();
  };
}

export default ComparisonStore;
