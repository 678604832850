import { FC, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Dashboard } from '../../modules/dashboard/containers';
import { EDashboardRoute } from '../../modules/dashboard/routes/DashboardRoute';
import { Experiments } from '../../modules/dashboard/modules/experiments/containers';
import { EExperimentsRoute } from '../../modules/dashboard/modules/experiments/routes';
import { CreateExperiment } from '../../modules/dashboard/modules/experiments/modules/createExperiment/containers';
import { ECreateExperimentRoute } from '../../modules/dashboard/modules/experiments/modules/createExperiment/routes';
import { Attributes } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/attributes/containers';
import { Zones } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/zones/containers';
import { History } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/history/containers';
import { Calculation } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/calculation/containers';
import { Comparison } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/comparison/containers/Comparison';
import { ExperimentSubsections } from '../../modules/dashboard/modules/experiments/modules/experimentSubsections/containers';
import { Documents } from '../../modules/dashboard/modules/experiments/modules/experimentSubsections/modules/documents';
import { Audits } from '../../modules/dashboard/modules/observations/containers';
import { Contracts } from '../../modules/dashboard/modules/contracts/containers';
import { EAuditsRoute } from '../../modules/dashboard/modules/observations/routes';
import Audit from '../../modules/dashboard/modules/observations/modules/Audit/Audit';
import { EAuditRoute } from '../../modules/dashboard/modules/observations/modules/Audit/routes';
import { AuditReport } from '../../modules/dashboard/modules/observations/modules/Audit/modules/AuditReport';
import TaskReport from '../../modules/dashboard/modules/observations/modules/Audit/modules/TaskReport/TaskReport';
import { PageNotFound } from '../../modules/common/features/UI';
import Sidebar from '../../modules/dashboard/components/Sidebar/Sidebar';
import CreateAudit from '../../modules/dashboard/modules/observations/modules/Audit/modules/CreateAudit/modules/createAudit/CreateAudit';
import { ECreateEditContractRoute } from '../../modules/dashboard/modules/contracts/modules/CreateEditContract/routes';
import { CreateEditContract } from '../../modules/dashboard/modules/contracts/modules/CreateEditContract/containers';
import { ExperimentApproval } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/approval';
import { AuditTasks } from '../../modules/dashboard/modules/observations/modules/Audit/modules/Tasks';
import { AuditDocuments } from '../../modules/dashboard/modules/observations/modules/Audit/modules/AuditDocuments';
import { AuditApproval } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/approval/containers/AuditApproval';
import { AuditPhotos } from '../../modules/dashboard/modules/observations/modules/Audit/modules/AuditPhotos';
import { ShowAuditInfo } from '../../modules/dashboard/modules/observations/modules/Audit/modules/Info/containers/AuditInfoPage/showAuditContainer';
import { EditAuditInfo } from '../../modules/dashboard/modules/observations/modules/Audit/modules/Info/containers/AuditInfoPage/editAuditContainer';
import { BrowserTitle } from '../../modules/common/components/BrowserTitle';
import {
  withAccessToExperiments,
  withAccessToAudits,
} from '../../modules/dashboard/modules/experiments/hoc';
import { useStore } from '../../modules/common/utils/helpers/mobx';
import { ProfileStore } from '../../modules/common/mobx/stores/ProfileStore';
import { SessionStore } from '../../modules/common/mobx/stores';
import { Execution } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/Execution';
import { Remarks } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/remarks';
import { Experiment } from '../../modules/dashboard/modules/experiments/modules/experiment';
import { TotalComparison } from '../../modules/dashboard/modules/experiments/modules/createExperiment/modules/TotalComparison';

const ExperimentsWithAccess = withAccessToExperiments(props => (
  <BrowserTitle title="Опыты" {...props} />
));

const AuditsWithAccess = withAccessToAudits(props => (
  <BrowserTitle title="Наблюдения" {...props} />
));

const AppRouter: FC = observer(() => {
  const sessionStore = useStore(SessionStore);
  const profileStore = useStore(ProfileStore);

  const { fetchProfile } = profileStore;
  const { isLoggedIn } = sessionStore;

  useEffect(() => {
    if (isLoggedIn) {
      fetchProfile();
    }
  }, [isLoggedIn]);

  return (
    <>
      <Routes>
        <Route path={'/'} element={<Dashboard />}>
          <Route path={EDashboardRoute.Experiments} element={<ExperimentsWithAccess />}>
            <Route element={<Sidebar />}>
              <Route index element={<Experiments />} />
            </Route>

            <Route element={<Experiment />}>
              {/* Относится к визарду опыта */}
              <Route path={EExperimentsRoute.Experiment} element={<CreateExperiment />}>
                <Route path={ECreateExperimentRoute.Attributes} element={<Attributes />} />
                <Route path={ECreateExperimentRoute.Zones} element={<Zones />} />
                <Route path={ECreateExperimentRoute.NutritionHistory} element={<History />} />
                <Route path={ECreateExperimentRoute.Calculation} element={<Calculation />} />
                <Route path={ECreateExperimentRoute.Comparison} element={<Comparison />} />
                <Route path={ECreateExperimentRoute.Execution} element={<Execution />} />
                <Route
                  path={ECreateExperimentRoute.TotalComparison}
                  element={<TotalComparison />}
                />
                <Route path={ECreateExperimentRoute.Remarks} element={<Remarks />} />
              </Route>

              {/* Относится к подразделам визарда опыта */}
              <Route path={EExperimentsRoute.Experiment} element={<ExperimentSubsections />}>
                <Route path={ECreateExperimentRoute.Documents} element={<Documents />} />
                <Route path={ECreateExperimentRoute.Approval} element={<ExperimentApproval />} />
              </Route>
            </Route>
          </Route>

          <Route path={EDashboardRoute.Audits} element={<AuditsWithAccess />}>
            <Route element={<Sidebar />}>
              <Route index element={<Audits />} />
            </Route>

            <Route path={EAuditsRoute.Audit} element={<Audit />}>
              {/* Относится к визарду наблюдений */}
              <Route path={EAuditRoute.Info} element={<ShowAuditInfo />} />
              <Route path={EAuditRoute.Tasks} element={<AuditTasks />} />
              <Route path={EAuditRoute.Report} element={<AuditReport />} />
              <Route path={EAuditRoute.Photos} element={<AuditPhotos />} />
              {/* Относится к подразделам визарда наблюдений */}
              <Route path={EAuditRoute.CreateAudit} element={<CreateAudit />} />
              <Route path={EAuditRoute.EditAudit} element={<EditAuditInfo />} />
              <Route path={EAuditRoute.TaskReport} element={<TaskReport />} />
              <Route path={EAuditRoute.Approval} element={<AuditApproval />} />
              <Route path={EAuditRoute.Documents} element={<AuditDocuments />} />
            </Route>
          </Route>

          <Route path={EDashboardRoute.Contracts} element={<BrowserTitle title="Договоры" />}>
            <Route element={<Sidebar />}>
              <Route index element={<Contracts />} />
            </Route>

            {[ECreateEditContractRoute.Create, ECreateEditContractRoute.Edit].map(path => (
              <Route key={path} path={path} element={<CreateEditContract />} />
            ))}
          </Route>
        </Route>

        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </>
  );
});

export default AppRouter;
