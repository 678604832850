import React from 'react';

import StyledDocumentsActionsIcon from '../DocumetnsActionIcon.styles';

const DeleteIcon = ({ fill, hoverFill }) => {
  return (
    <StyledDocumentsActionsIcon.filledSvg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill={fill}
      hoverFill={hoverFill}
    >
      <path d="M6.2207 7.30615H7.0929V13.7515H6.2207V7.30615Z" />
      <path d="M8.11133 7.30615H8.98353V13.7515H8.11133V7.30615Z" />
      <path d="M10 7.30615H10.8722V13.7515H10V7.30615Z" />
      <path d="M14.0969 6.70117H13.7884L13.9251 5.375H14.9576V4.07617C14.9576 3.36001 14.3071 2.77734 13.5075 2.77734H11.8063L10.8142 1H6.27962L5.2875 2.77734H3.58626C2.78673 2.77734 2.13623 3.36001 2.13623 4.07617V5.375H3.16868L3.30538 6.70117H2.99691C2.19737 6.70117 1.54688 7.28384 1.54688 8C1.54688 8.71616 2.19737 9.29883 2.99691 9.29883H3.57313L4.16081 15H12.9329L13.5206 9.29883H14.0968C14.8964 9.29883 15.5469 8.71616 15.5469 8C15.5469 7.28384 14.8964 6.70117 14.0969 6.70117ZM6.84565 1.82031H10.2482L10.7824 2.77734H6.31143L6.84565 1.82031ZM3.05204 4.07617C3.05204 3.81233 3.2917 3.59766 3.58626 3.59766H13.5075C13.8021 3.59766 14.0417 3.81233 14.0417 4.07617V4.55469H3.05204V4.07617ZM2.99691 8.47852C2.70235 8.47852 2.46268 8.26384 2.46268 8C2.46268 7.73616 2.70235 7.52148 2.99691 7.52148H3.38994L3.4886 8.47852H2.99691ZM12.0978 14.1797H4.99596L4.0884 5.375H13.0054L12.0978 14.1797ZM14.0969 8.47852H13.6052L13.7039 7.52148H14.0969C14.3915 7.52148 14.6311 7.73616 14.6311 8C14.6311 8.26384 14.3914 8.47852 14.0969 8.47852Z" />
    </StyledDocumentsActionsIcon.filledSvg>
  );
};

export default DeleteIcon;
