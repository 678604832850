import { FC, memo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, Switcher, useShowTooltip } from '@farmlink/farmik-ui';

import { _Stage, IComparisonTableImagesConfig } from '../../../models';
import { DynamicTableController } from '../../../mobx/controllers';
import { useStore } from '../../../../../utils/helpers/mobx';
import { ComparisonTableImagesAttributeContainer as ImagesAttributeContainer } from '../../../containers/attributes';

import Styled from './ComparisonTableImagesStage.styles';

interface IProps {
  extendedStage: _Stage;
  imagesConfig: IComparisonTableImagesConfig | null;
  isEnableEditing: boolean;
}

const ComparisonTableImagesStage: FC<IProps> = ({
  extendedStage,
  imagesConfig,
  isEnableEditing,
}) => {
  const { name, attributeIds } = extendedStage;

  const { ref: nameRef, isShowTooltip: isShowNameTooltip } = useShowTooltip<HTMLDivElement>();

  const { onToggleImagesStageSelection, onToggleImagesStageOrientation } =
    useStore(DynamicTableController);

  const handleSelectClick = useCallback((isSelected: boolean) => {
    onToggleImagesStageSelection(extendedStage.id, isSelected);
  }, []);

  const handleHorizontalClick = useCallback(() => {
    if (isEnableEditing) {
      onToggleImagesStageOrientation(extendedStage.id, 'horizontal');
    }
  }, [isEnableEditing]);

  const handleVerticalClick = useCallback(() => {
    if (isEnableEditing) {
      onToggleImagesStageOrientation(extendedStage.id, 'vertical');
    }
  }, [isEnableEditing]);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.FlexWrapper>
          <Switcher
            value={imagesConfig?.isSelected}
            onChange={handleSelectClick}
            isBlocked={!isEnableEditing}
          />

          <AutoTooltip content={name} disabled={!isShowNameTooltip}>
            <Styled.Name ref={nameRef}>{name}</Styled.Name>
          </AutoTooltip>
        </Styled.FlexWrapper>

        <Styled.FlexWrapper $isHidden={!imagesConfig?.isSelected}>
          <Styled.OrientationLabel>Группировка в отчёте:</Styled.OrientationLabel>

          <Styled.FlexWrapper>
            <Styled.HorizontalOrientationButton
              onClick={handleHorizontalClick}
              $isEnableEditing={isEnableEditing}
              $isSelected={imagesConfig?.orientation === 'horizontal'}
            />

            <Styled.VerticalOrientationButton
              onClick={handleVerticalClick}
              $isEnableEditing={isEnableEditing}
              $isSelected={imagesConfig?.orientation === 'vertical'}
            />
          </Styled.FlexWrapper>
        </Styled.FlexWrapper>
      </Styled.Header>

      <Styled.Body $isHidden={!imagesConfig?.isSelected}>
        {attributeIds.map(attributeId => (
          <ImagesAttributeContainer
            key={attributeId}
            tableId={extendedStage.tableId}
            attributeId={attributeId}
            isEnableEditing={isEnableEditing}
          />
        ))}
      </Styled.Body>
    </Styled.Wrapper>
  );
};

ComparisonTableImagesStage.displayName = 'ComparisonTableImagesStage';

export default memo(observer(ComparisonTableImagesStage));
