import { IExperimentShortInfo } from '../../../models/as-fields/experiments';
import { TResponseList } from '../../../models/common/response';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TGetExperimentShortInfoRequest = {};

type TGetExperimentShortInfoResponse = TResponseList<IExperimentShortInfo>;

const getExperimentShortInfo: TApiRoute & {
  request: TGetExperimentShortInfoRequest;
  response: TGetExperimentShortInfoResponse;
} = {
  url: `/api/as-fields/experiment/shortInfo`,
  method: 'GET',
  request: {} as TGetExperimentShortInfoRequest,
  response: {} as TGetExperimentShortInfoResponse,
  headers: {},
};

export type { TGetExperimentShortInfoRequest, TGetExperimentShortInfoResponse };

export { getExperimentShortInfo };
