import { FC } from 'react';

import { TFileEntities } from '../../../../../../api/models/as-fields/attachedFiles';
import { TTableBuilderStylePreset as TTableStylePreset } from '../../../TableBuilder/types/styles';
import { DocumentsHeader, DocumentsList } from '../../components';

interface IProps {
  id: string;
  entity: TFileEntities;
  hideHeader?: boolean;
  hideActionButtons?: boolean;
  stylePreset?: TTableStylePreset;
  ContextualHelpIcon?: JSX.Element;
  hideDownloadButton?: boolean;
}

export const DocumentsTable: FC<IProps> = ({
  id,
  entity,
  hideHeader,
  hideActionButtons,
  stylePreset,
  ContextualHelpIcon,
  hideDownloadButton,
}) => {
  return (
    <>
      {!hideHeader && (
        <DocumentsHeader
          id={id}
          entity={entity}
          hideActionButtons={hideActionButtons}
          hideDownloadButton={hideDownloadButton}
          ContextualHelpIcon={ContextualHelpIcon}
        />
      )}
      <DocumentsList
        id={id}
        entity={entity}
        hideActionButtons={hideActionButtons}
        stylePreset={stylePreset}
      />
    </>
  );
};
