import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: auto;
`;

const Item = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  min-width: 48px;
`;

const NoInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & div:not(:first-child) {
    padding-bottom: 24px;
  }
`;

const StyledDosageValueCell = {
  Wrapper,
  Item,
  NoInfoWrapper,
  ContentWrapper,
};

export default StyledDosageValueCell;
