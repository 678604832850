import { ApprovalStore, ApprovalService } from '..';
import { lazyInject, provide } from '../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentCommentTypeEvent } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentComment';
import { EExperimentApprovalStatus } from '../../../../../../../../../../api/models/as-fields/experiments/Experiment/Experiment.model';

@provide.transient()
class ApprovalController {
  @lazyInject(ApprovalStore)
  approvalStore: ApprovalStore;

  @lazyInject(ApprovalService)
  approvalService: ApprovalService;

  fetchExperimentCommentList = async (checklistId: string, type?: EExperimentCommentTypeEvent) => {
    this.approvalStore.setIsLoading(true);
    const commentList = await this.approvalService.fetchChecklistCommentList(checklistId, type);

    this.approvalStore.setIsLoading(false);

    return commentList;
  };

  sendMessage = (checklistId: string, message: string) => {
    return this.approvalService.postComment(checklistId, message);
  };

  changeStatus = (experimentId: string, status: EExperimentApprovalStatus) => {
    return this.approvalService.changeStatus({ id: experimentId, status });
  };
}

export default ApprovalController;
