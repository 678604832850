import { FC, memo, useCallback, useState } from 'react';

import Styled from './DropArrow.styles';

interface IDropArrowProps {
  defaultValue?: boolean;
  onChange?: (value: boolean) => void;
}

const DropArrow: FC<IDropArrowProps> = ({ defaultValue, onChange }) => {
  const [_isDropped, setIsDropped] = useState<boolean>(Boolean(defaultValue));

  const handleClick = useCallback(() => {
    setIsDropped(!_isDropped);
    onChange?.(!_isDropped);
  }, [_isDropped, onChange]);

  return <Styled.Wrapper onClick={handleClick} $isDropped={_isDropped} />;
};

DropArrow.displayName = 'DropArrow';

export default memo(DropArrow);
