import {
  IChangeExperimentCultureZone,
  IExperimentCultureZone,
} from '../../../../models/as-fields/experiments/ExperimentCultureZone';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TChangeCultureZoneExperimentListReq = {
  zoneList: IChangeExperimentCultureZone[];
};

type TChangeCultureZoneExperimentListRes = IExperimentCultureZone[];

const changeCultureZoneExperimentList: TApiRoute & {
  request:
    | TChangeCultureZoneExperimentListReq
    | ((req: TChangeCultureZoneExperimentListReq) => IChangeExperimentCultureZone[]);
  response: TChangeCultureZoneExperimentListRes;
} = {
  url: () => `/api/as-fields/experiment`,
  method: 'PUT',
  request: req => req.zoneList,
  response: {} as TChangeCultureZoneExperimentListRes,
  headers: {},
};

export type { TChangeCultureZoneExperimentListReq, TChangeCultureZoneExperimentListRes };

export { changeCultureZoneExperimentList };
