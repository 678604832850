import { ReactNode } from 'react';

import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import {
  AuditsFiltersConfigsService as ConfigsService,
  AuditsFiltersOptionsService as OptionsService,
} from '../../services';
import { TableFiltersBuilderController } from '../../../../../../../../common/features/TableFiltersBuilder/mobx/controllers';
import { IAuditsFilters } from '../../../models';
import { EAuditsFiltersBuilderId } from '../../../../../constants/configs';

@provide.transient()
class AuditsFiltersController {
  @lazyInject(ConfigsService)
  protected configsService: ConfigsService;

  @lazyInject(OptionsService)
  protected optionsService: OptionsService;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<IAuditsFilters>;

  initiateConfig = (onActionButtonClick: () => void): void => {
    const config = this.configsService.createConfig(
      onActionButtonClick,
      this.optionsService.optionsQueryScheme
    );

    this.tableFiltersBuilderController.initiateConfig(config);
  };

  addContextualHelpIcon = (ContextualHelpIcon: ReactNode): void => {
    this.tableFiltersBuilderController.updateHeaderConfig(EAuditsFiltersBuilderId.Audits, {
      ContextualHelpIcon,
    });
  };

  addOptionList = async (): Promise<void> => {
    const [
      techOperationOptionList,
      organizationOptionList,
      assigneeOptionList,
      experimentOptionList,
      statusOptionList,
    ] = await Promise.all([
      this.optionsService.techOperationOptionListSearchQueryHandler(),
      this.optionsService.organizationOptionListSearchQueryHandler(),
      this.optionsService.assigneeOptionListSearchQueryHandler(),
      this.optionsService.experimentOptionListSearchQueryHandler(),
      this.optionsService.createStatusOptionList(),
    ]);

    this.tableFiltersBuilderController.addSelectOptionList(
      EAuditsFiltersBuilderId.Audits,
      'techOperationTypeIdIn',
      techOperationOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EAuditsFiltersBuilderId.Audits,
      'organizationIdIn',
      organizationOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EAuditsFiltersBuilderId.Audits,
      'assigneeIdIn',
      assigneeOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EAuditsFiltersBuilderId.Audits,
      'experimentIdIn',
      experimentOptionList.optionList
    );

    this.tableFiltersBuilderController.addSelectOptionList(
      EAuditsFiltersBuilderId.Audits,
      'statusIn',
      statusOptionList.optionList
    );
  };
}

export default AuditsFiltersController;
