import { FC, FormEvent, memo, useEffect, useMemo } from 'react';
import { Button, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesController } from '../../../../../mobx/controllers';
import { useForm } from '../../../../../../../../../../../../../../common/features/form/utils/hooks';
import { ZonesStore } from '../../../../../mobx/stores';
import {
  ADD_PREVIOUS_CULTURE_FORM_KEY,
  createAddPreviousCultureFormConfig,
  IAddPreviousCultureForm,
} from '../../../../forms/addPreviousCulture/addPreviousCulture';
import { createPreviousSeasonByCultureZone } from '../../../../../../../../../../../../../../common/utils/helpers/dates';

import Styled from './AddPreviousCulture.styles';

const AddPreviousCulture: FC = () => {
  const {
    editableZoneId,
    getZone,
    previousCultureCurrentPage,
    previousCultureTotalPages,
    previousCultureOptionList,
    setPreviousCultureOptionList,
    previousCultureSearchQuery,
    setPreviousCultureSearchQuery,
  } = useStore(ZonesStore);
  const {
    addPreviousCulture,
    culturesSearchQueryHandler,
    onPreviousCultureListScroll,
    changePreviousCulturePageNumber,
  } = useStore(ZonesController);

  const { closeModal } = useModal();

  const { elements, registerForm, submitForm, addPaginationConfig, addOptionList } =
    useForm<IAddPreviousCultureForm>(ADD_PREVIOUS_CULTURE_FORM_KEY);

  const { previousCultureId: PreviousCulture } = elements;

  useEffect(() => {
    const zone = getZone(editableZoneId);

    const isPrevCultureListExist = zone?.prevCultureSelectOptionList?.[0]?.label;

    (async () => {
      const prevCultureSelectOptionList = await culturesSearchQueryHandler(
        zone?.prevCultureSelectOptionList?.[0]?.label || ''
      );

      /**
       * По умолчанию добавляем searchQuery выбранного предшественника
       */
      setPreviousCultureSearchQuery(zone?.prevCultureSelectOptionList?.[0]?.label || '');

      setPreviousCultureOptionList(prevCultureSelectOptionList);

      /**
       * Если массив прошлых культур пуст, то выводим селект без выбранной опции
       */
      registerForm(
        createAddPreviousCultureFormConfig({
          culturesSearchQueryHandler,
          prevCultureSelectOptionList: isPrevCultureListExist ? prevCultureSelectOptionList : [],
        })
      );
    })();
  }, []);

  const onPreviousCultureScroll = async () => {
    const newPreviousCultureOptionList = await onPreviousCultureListScroll(
      previousCultureSearchQuery
    );

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('previousCultureId', [
      ...previousCultureOptionList,
      ...newPreviousCultureOptionList,
    ]);

    setPreviousCultureOptionList([...previousCultureOptionList, ...newPreviousCultureOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('previousCultureId', {
      currentPage: previousCultureCurrentPage,
      totalPages: previousCultureTotalPages,
      onScroll: onPreviousCultureScroll,
      onPageChange: changePreviousCulturePageNumber,
    });
  }, [
    previousCultureCurrentPage,
    previousCultureTotalPages,
    onPreviousCultureScroll,
    changePreviousCulturePageNumber,
  ]);

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();

    await submitForm(async validatedData => {
      await addPreviousCulture(validatedData, closeModal);
    });
  };

  const previousSeason = useMemo<string>(() => {
    if (!editableZoneId) {
      return '';
    }

    const zone = getZone(editableZoneId);

    return createPreviousSeasonByCultureZone(zone.cultureZone);
  }, [editableZoneId]);

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <Styled.Header>
        <Styled.Title data-test-id={'add-previous-culture-modal-title'}>
          Культура-предшественник
        </Styled.Title>
        <Styled.Description data-test-id={'add-previous-culture-modal-description'}>
          {previousSeason}
        </Styled.Description>
      </Styled.Header>
      <Styled.Body>{PreviousCulture ? <PreviousCulture /> : null}</Styled.Body>
      <Styled.Footer>
        <Button
          type={'button'}
          color={'default'}
          onClick={closeModal}
          dataTestId={'add-previous-culture-modal-deny-button'}
        >
          Отменить
        </Button>
        <Button
          type={'submit'}
          color={'primary'}
          dataTestId={'add-previous-culture-modal-success-button'}
        >
          Сохранить
        </Button>
      </Styled.Footer>
    </Styled.Form>
  );
};

AddPreviousCulture.displayName = 'AddPreviousCulture';

export default memo(observer(AddPreviousCulture));
