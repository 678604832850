import { observer } from 'mobx-react';
import { forwardRef, memo, useCallback, useEffect, useRef, useState, FocusEvent } from 'react';
import _ from 'lodash';

import { Input } from '../../../../../components/form';
import { useStore } from '../../../../../utils/helpers/mobx';
import { FormController } from '../../../mobx/controllers';
import { FormStore } from '../../../mobx/stores';

import { ITextareaContainerProps } from './TextareaContainer.types';

const MAX_LONGSTRING_LENGTH = 4000;

const TextareaContainer = forwardRef<HTMLInputElement, ITextareaContainerProps>(
  ({ formKey, elementName, id, element }, elementRef) => {
    const { getFormValue } = useStore(FormStore);
    const { onFormValueChange } = useStore(FormController);

    const value = getFormValue(formKey, elementName) as string | number;
    const { schema } = element;

    const dependencyName = element?.dependencyName as never;
    const dependencyValue = getFormValue(formKey, dependencyName);
    const isDependencyEmpty = !dependencyName ? false : !Boolean(dependencyValue);

    const [textareaValue, setTextareaValue] = useState<string | number>(value ?? '');

    useEffect(() => {
      setTextareaValue(value);
    }, [value]);

    const debounceOnFormValueChange = useRef(
      _.debounce((newValue: string | number): void => {
        onFormValueChange(formKey, elementName, { [elementName]: newValue });
      }, 500)
    );

    const handleTextareaChange = useCallback((newValue: string): void => {
      setTextareaValue(newValue);
      debounceOnFormValueChange.current(newValue);
    }, []);

    const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
      event.target.scrollTo({ top: 0 });
    }, []);

    return (
      <>
        <Input
          id={id}
          dataTestId={id}
          value={textareaValue}
          onKeyPress={element?.textareaOptions?.onKeyPress || null}
          maxLength={element?.textareaOptions?.maxLength || MAX_LONGSTRING_LENGTH}
          as={'textarea'}
          isResizable={false}
          height={'100px'}
          hideScrollbar
          label={element?.label}
          placeholder={element?.placeholder}
          error={!schema?.isShownError ? '' : schema?.errorTitle}
          onChange={handleTextareaChange}
          onBlur={handleBlur}
          isDisabled={element?.isDisabled || isDependencyEmpty}
          isBlocked={element?.isBlocked}
          ref={elementRef}
          data-test-id="textarea-input"
        />
      </>
    );
  }
);

TextareaContainer.displayName = 'TextareaContainer';

export default memo(observer(TextareaContainer));
