import { Plug } from '../../../../../../../../../../../common/components/ui';
import { AccessStore } from '../../../../../../../../../../../common/mobx/stores/AccessStore';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { CommonInformation } from '../../../commonInformation';
import { useGetPlugInfo } from '../helpers';

const useCreateAuditGetContent = () => {
  const accessStore = useStore(AccessStore);
  const { isAllowToAddAudits } = accessStore;

  const { plugDescription, plugIcon, plugTitle, buttonTitle, onButtonClick } =
    useGetPlugInfo('/audits');

  if (isAllowToAddAudits) {
    return <CommonInformation />;
  } else {
    return (
      <Plug
        icon={plugIcon}
        title={plugTitle}
        description={plugDescription}
        primaryButtonTitle={buttonTitle}
        onPrimaryButtonClick={onButtonClick}
        buttonExist
      />
    );
  }
};

export default useCreateAuditGetContent;
