import { FC } from 'react';

import Styled from './CustomArrow.styles';

const CustomArrow: FC<{ $isDropped?: boolean }> = ({ $isDropped }) => {
  return (
    <Styled.CustomArrowWrapper $isDropped={$isDropped}>
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3981 7.97162C11.3981 7.75972 11.322 7.59128 11.1808 7.44458L7.1111 3.27711C6.92636 3.09237 6.73619 3.00543 6.50255 3C6.26891 3 6.07331 3.08694 5.894 3.27711L1.8189 7.44458C1.67763 7.59128 1.60156 7.76515 1.60156 7.97162C1.60156 8.39543 1.93844 8.73231 2.35681 8.73231C2.56329 8.73231 2.75889 8.64537 2.91103 8.49324L6.50255 4.79848L10.0886 8.49324C10.2408 8.64537 10.4364 8.73231 10.6429 8.73231C11.0612 8.73231 11.3981 8.39543 11.3981 7.97162Z"
          fill="currentColor"
        />
      </svg>
    </Styled.CustomArrowWrapper>
  );
};

export default CustomArrow;
