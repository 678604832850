import { IAttachedFile } from '../ExperimentContract';

enum EExperimentsWizardProgress {
  Preparation = 'PREPARATION',
  Attributes = 'ATTRIBUTES',
  Zones = 'ZONES',
  NutritionHistory = 'NUTRITION_HISTORY',
  Calculation = 'CALCULATION',
  Comparison = 'COMPARISON',
  Approval = 'APPROVAL',
  Execution = 'EXECUTION',
  Remarks = 'REMARKS',
}

enum EExperimentType {
  SingleProduct = 'SINGLE_PRODUCT',
  MultiProduct = 'MULTI_PRODUCT',
}

enum EExperimentTypeTranslate {
  SingleProduct = 'М',
  MultiProduct = 'СП',
}

enum EExperimentTypeTranslateFull {
  SingleProduct = 'Монопродуктовый',
  MultiProduct = 'Система питания ',
}

enum EExperimentStatus {
  Draft = 'DRAFT',
  InApproval = 'IN_APPROVAL',
  Approved = 'APPROVED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  FactAppruved = 'FACT_APPROVED',
  FullCompleted = 'FULL_COMPLETED',
  Canceled = 'CANCELED',
}

enum EExperimentApprovalStatus {
  approvedToCanceled = 'approvedToCanceled',
  approvedToInProgress = 'approvedToInProgress',
  approvedToInApproval = 'approvedToInApproval',
  canceledToApproved = 'canceledToApproved',
  canceledToInProgress = 'canceledToInProgress',
  completedToInProgress = 'completedToInProgress',
  draftToInApproval = 'draftToInApproval',
  draftToInProgress = 'draftToInProgress',
  inApprovalToApproved = 'inApprovalToApproved',
  inApprovalToDraft = 'inApprovalToDraft',
  inProgressToDraft = 'inProgressToDraft',
  inProgressToCompleted = 'inProgressToCompleted',
  completedToFactApproved = 'completedToFactApproved',
  factApprovedToCompleted = 'factApprovedToCompleted',
  factApprovedToFullCompleted = 'factApprovedToFullCompleted',
  fullCompletedToFactApproved = 'fullCompletedToFactApproved',
  inProgressToCanceled = 'inProgressToCanceled',
}

interface IExperimentChangeStatusAvailability {
  canDraftToInProgress?: boolean;
  canInProgressToDraft?: boolean;
  canInApprovalToDraft?: boolean;
  canDraftToInApproval?: boolean;
  canInApprovalToApproved?: boolean;
  canApprovedToInProgress?: boolean;
  canApprovedToInApproval?: boolean;
  canApprovedToCanceled?: boolean;
  canInProgressToCanceled?: boolean;
  canInProgressToCompleted?: boolean;
  canCanceledToApproved?: boolean;
  canCanceledToInProgress?: boolean;
  canCompletedToInProgress?: boolean;
  canCompletedToFactApproved?: boolean;
  canFactApprovedToCompleted?: boolean;
  canFactApprovedToFullCompleted?: boolean;
  canFullCompletedToFactApproved?: boolean;
}

interface IExperiment extends IExperimentChangeStatusAvailability {
  id: string;
  name?: string;
  type?: EExperimentType;
  status?: EExperimentStatus;
  wizardProgress?: EExperimentsWizardProgress;
  seasonYear?: number;
  topic?: string;
  purpose?: string;
  code?: string;
  canEditCultureZoneExperiment?: boolean;
  canEditExperiment?: boolean;
  canEditContract?: boolean;
  organization?: {
    id: string;
    name: string;
  };
  contract?: {
    id: string;
    name: string;
  };
  responsible?: {
    id: string;
    fullName: string;
  };
  region?: {
    id: string;
    name: string;
  };
  culture?: {
    id: string;
    name: string;
  };
  assignee?: {
    id: string;
    fullName: string;
  };
  challenges?: Array<string>;
  planDeviation?: string;
  technologicalDeviation?: string;
  specialNotes?: string;
  /**
   * Временное свойство. Для обратной совместимости с таблицей.
   */
  district?: string;
  attachedFiles?: IAttachedFile[];
}

export type { IExperiment, IExperimentChangeStatusAvailability };

export {
  EExperimentStatus,
  EExperimentType,
  EExperimentsWizardProgress,
  EExperimentTypeTranslate,
  EExperimentTypeTranslateFull,
  EExperimentApprovalStatus,
};
