import { FC, memo, useMemo } from 'react';

import { IActiveSubstancesData } from '../../../../model/IActiveSubstancesData.model';
import { DosageValueCell as CellContent } from '../../content';

import Styled from './DosageValueCell.styles';

interface IDosageValueCellComponent {
  row: Pick<IActiveSubstancesData, 'id' | 'itemsList' | 'protectionItemsList'>;
}

const DosageValueCellComponent: FC<IDosageValueCellComponent> = ({ row }) => {
  const isShowContent = useMemo(
    () => row?.itemsList?.length > 0 || row?.protectionItemsList?.length > 0,
    [row?.itemsList?.length, row?.protectionItemsList?.length]
  );

  return (
    <Styled.Wrapper data-test-id={`dosage-cell-wrapper-${row?.id}`}>
      {isShowContent ? (
        <>
          {row?.itemsList.map(item => (
            <CellContent
              dosageValue={item.concentration}
              unitOfMeasure={item.unitOfMeasure?.name}
              key={item.id}
            />
          ))}
          {row?.protectionItemsList.map(item => (
            <CellContent
              dosageValue={item.concentration}
              unitOfMeasure={item.unitOfMeasure?.name}
              key={item.id}
            />
          ))}
        </>
      ) : (
        <Styled.Item data-test-id={`dosage-cell-item-${row?.id}`}>—</Styled.Item>
      )}
    </Styled.Wrapper>
  );
};

DosageValueCellComponent.displayName = 'DosageValueCellComponent';

export default memo(DosageValueCellComponent);
