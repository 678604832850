import { FC, memo, useCallback, useMemo } from 'react';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react';
import { Colors, ENotificationType, ENotificatorTheme, useNotificator } from '@farmlink/farmik-ui';
import { IconNames } from '@farmlink/farmik-ui/dist/Icon';

import { IAttachedFile } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { ECommonColor } from '../../../../../../../../../../../common/assets/styles/colors';
import { useWarningBeforeDeleting } from '../../../../../../../../../../../common/utils/hooks';
import { AccessStore } from '../../../../../../../../../../../common/mobx/stores/AccessStore';
import { ContractSidePanelStore } from '../../../../../../mobx/stores';
import { DocumentsTableService } from '../../mobx/services';
import { DocumentsTableController } from '../../mobx/controllers';
import { DocumentsTableStore } from '../../mobx/stores';
import ActionsMenu, {
  IMenuItem,
} from '../../../../../../../../../../../common/features/UI/ActionsMenu/ActionsMenu';
import { useDataTestIdV2 } from '../../../../../../../../../../../common/utils/hooks/locators';

import Styled from './DocumentOptionsCell.styles';

const { AccentPink } = ECommonColor;

interface IProps {
  rowData: IAttachedFile;
}

const DocumentOptionsCell: FC<IProps> = ({ rowData }) => {
  const getDataTestId = useDataTestIdV2('document-options-cell');

  const { downloadFile } = useStore(DocumentsTableService);

  const { updateAttachedFiles } = useStore(DocumentsTableController);

  const { attachedFiles } = useStore(DocumentsTableStore);

  const { selectedContract } = useStore(ContractSidePanelStore);

  const { isAllowToEditContracts } = useStore(AccessStore);

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();
  const { setNotification } = useNotificator();

  const downloadFileHandler = async () => {
    try {
      const response = await downloadFile(rowData.id);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, rowData.fileName);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const deleteFile = useCallback(() => {
    const attachedFilesIds = attachedFiles.map(file => file.id);
    const updatedAttachedFilesIds = attachedFilesIds.filter(id => id !== rowData.id);
    const payload = {
      id: selectedContract.id,
      attachedFiles: [...updatedAttachedFilesIds],
    };

    updateAttachedFiles(payload)
      .then(() => {
        setNotification({
          message: 'Документ успешно удален',
          style: {
            placement: 'top-center',
            type: ENotificationType.Success,
            theme: ENotificatorTheme.Dark,
          },
        });
      })
      .catch((e: Error) => {
        setNotification({
          message: e.message,
          style: {
            placement: 'top-center',
            type: ENotificationType.Warning,
            theme: ENotificatorTheme.Dark,
          },
        });
      });
  }, [selectedContract?.id, attachedFiles]);

  const menuConfig = useMemo<IMenuItem[]>(() => {
    const menuItems = [
      {
        icon: 'download' as IconNames,
        onClick: () => {
          downloadFileHandler();
        },
        colorScheme: {
          default: {
            background: Colors.primaryWhite,
            icon: Colors.black,
          },
          hover: {
            background: Colors.primaryWhite,
            icon: Colors.hoverGreen,
          },
        },
      },
    ];

    if (isAllowToEditContracts) {
      menuItems.unshift({
        icon: 'bin' as IconNames,
        onClick: () => {
          showWarningBeforeDeleting(rowData.fileName, deleteFile);
        },
        colorScheme: {
          default: {
            background: Colors.primaryWhite,
            icon: Colors.red,
          },
          hover: {
            background: Colors.primaryWhite,
            icon: Colors.hoverPink,
          },
        },
      });
    }

    return menuItems;
  }, [isAllowToEditContracts, rowData.fileName]);

  return (
    <Styled.MenuContent {...getDataTestId()}>
      <ActionsMenu
        dataTestId={getDataTestId()['data-test-id']}
        menuConfig={menuConfig}
        dataId={rowData.id}
      />
    </Styled.MenuContent>
  );
};

DocumentOptionsCell.displayName = 'DocumentOptionsCell';

export default memo(observer(DocumentOptionsCell));
