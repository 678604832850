import { FC, memo } from 'react';

import icons from '../../../assets/icons';

import Styled from './ActionCell.styles';

interface IActionCell {
  deleteHandler: () => void;
  editHandler: () => void;
}

const ActionCell: FC<IActionCell> = ({ deleteHandler, editHandler }) => {
  return (
    <Styled.Wrapper data-test-id={'agrochemical-table-buttons-wrapper'}>
      <Styled.ActionButton onClick={editHandler} data-test-id={'agrochemical-table-edit-button'}>
        <Styled.Icon src={icons.edit} />
      </Styled.ActionButton>
      <Styled.ActionButton
        onClick={deleteHandler}
        data-test-id={'agrochemical-table-delete-button'}
      >
        <Styled.Icon src={icons.delete} />
      </Styled.ActionButton>
    </Styled.Wrapper>
  );
};

ActionCell.displayName = 'ActionCell';

export default memo(ActionCell);
