import { FC, MouseEvent, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { ITableBuilderRow as IRow } from '../../../models/data';
import { TableBuilderGrid as Grid } from '../../TableBuilderGrid';

interface IProps {
  row: IRow;
}

const TableBuilderRowCustomRenderContainer: FC<IProps> = ({ row }) => {
  const getDataTestId = useDataTestId('custom-row');
  const dataTestId = getDataTestId()['data-test-id'];

  const handleRowClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      row?.onRowClick?.(row, event);
    },
    [row?.onRowClick, row]
  );

  return <Grid>{row.customRenderConfig.render(row, handleRowClick, dataTestId)}</Grid>;
};

TableBuilderRowCustomRenderContainer.displayName = 'TableBuilderRowCustomRenderContainer';

export default observer(TableBuilderRowCustomRenderContainer);
