import { FC, memo, useMemo } from 'react';

import { IEconomyData } from '../../../../model/IEconomyData.model';
import { PriceCell as CellContent } from '../../content';

import Styled from './PriceCell.styles';

interface IPriceCellComponentProps {
  row: Pick<IEconomyData, 'id' | 'itemsList' | 'protectionItemsList'>;
}

const PriceCellComponent: FC<IPriceCellComponentProps> = ({ row }) => {
  const isShowContent = useMemo(
    () => row?.itemsList?.length > 0 || row?.protectionItemsList?.length > 0,
    [row?.itemsList?.length, row?.protectionItemsList?.length]
  );

  return (
    <>
      {isShowContent ? (
        <Styled.Wrapper data-test-id={`price-cell-wrapper-${row?.id}`}>
          {row?.itemsList?.map(value => (
            <CellContent
              key={value.id}
              price={value.pricePerUnit}
              unitOfMeasure={value.unitOfMeasure?.name}
            />
          ))}
          {row?.protectionItemsList?.map(value => (
            <CellContent
              key={value.id}
              price={value.pricePerUnit}
              unitOfMeasure={value.unitOfMeasure?.name}
            />
          ))}
        </Styled.Wrapper>
      ) : (
        <Styled.NoInfo>—</Styled.NoInfo>
      )}
    </>
  );
};

PriceCellComponent.displayName = 'PriceCellComponent';

export default memo(PriceCellComponent);
