import { TableBuilderStore as Store } from '../../stores';
import {
  ITableBuilderConfig as IBuilderConfig,
  ITableBuilderPaginationConfig as IPaginationConfig,
  ITableBuilderRowConfig as IRowConfig,
  ITableBuilderRowsGroupConfig,
} from '../../../models/configs';
import {
  TableBuilderCellsService as CellsService,
  TableBuilderColumnsService as ColumnsService,
  TableBuilderRowsService as RowsService,
} from '../../services';
import { lazyInject, provide } from '../../../../../utils/helpers/mobx';

@provide.transient()
class TableBuilderController {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ColumnsService)
  protected columnsService: ColumnsService;

  @lazyInject(RowsService)
  protected rowsService: RowsService;

  @lazyInject(CellsService)
  protected cellsService: CellsService;

  initiateTable = (config: IBuilderConfig): void => {
    this.columnsService.addColumnList(config.id, config.columnConfigList);
    this.cellsService.addCellList(config.id, config.cellConfigList);

    if (config?.rowConfig) {
      this.rowsService.addPartialRowConfig(config.id, config.rowConfig);
    }

    if (config.rowsGroupConfigList) {
      this.rowsService.addRowsGroupList(config.id, config.rowsGroupConfigList);
    }

    if (config?.paginationConfig) {
      this.store.setPartialPaginationConfig(config.id, config.paginationConfig);
    }
  };

  addRowsGroupList = (
    builderId: string,
    configList: ITableBuilderRowsGroupConfig[],
    options?: { rootRowId?: string }
  ) => {
    this.rowsService.addRowsGroupList(builderId, configList, options);
  };

  addRowsToRowsGroupListById = (
    builderId: string,
    groupId: string,
    rowsConfig: IRowConfig[],
    options?: { isClearPreviousList?: boolean }
  ) => {
    this.rowsService.addRowList(builderId, groupId, rowsConfig, options);
  };

  getColumnsWidth: ColumnsService['getColumnsWidth'] = builderId => {
    const columnWidthList = this.columnsService.getColumnsWidth(builderId);

    return columnWidthList;
  };

  addRowClickEvent = (builderId: string, event: IRowConfig['onRowClick']): void => {
    this.rowsService.addPartialRowConfig(builderId, { onRowClick: event });
  };

  addPaginationScrollEvent = (builderId: string, event: IPaginationConfig['onScroll']): void => {
    this.store.setPartialPaginationConfig(builderId, { onScroll: event });
  };

  addCurrentPage = (builderId: string, currentPage: number): void => {
    this.store.setCurrentPage(builderId, currentPage);
  };

  addTotalPages = (builderId: string, totalPages: number): void => {
    this.store.setTotalPages(builderId, totalPages);
  };

  increaseCurrentPage = (builderId: string): void => {
    const currentPage = this.store.getCurrentPage(builderId);

    this.store.setCurrentPage(builderId, currentPage + 1);
  };

  showLoader = (builderId: string): void => {
    this.store.setIsFetchingElements(builderId, true);
  };

  hideLoader = (builderId: string): void => {
    this.store.setIsFetchingElements(builderId, false);
  };

  showDefaultPlug = (builderId: string): void => {
    this.store.setIsShowDefaultPlug(builderId, true);
  };

  hideDefaultPlug = (builderId: string): void => {
    this.store.setIsShowDefaultPlug(builderId, false);
  };

  clearRowsGroupList = (builderId: string) => {
    this.rowsService.clearRowsGroupList(builderId);
  };

  clearStore = (builderId: string): void => {
    this.store.deleteColumnList(builderId);
    this.store.deleteRowList(builderId);
    this.store.deleteCellList(builderId);
    this.store.deleteRowConfig(builderId);
    this.store.deletePaginationConfig(builderId);
    this.store.deleteCurrentPage(builderId);
    this.store.deleteTotalPages(builderId);
    this.store.deleteIsFetchingElements(builderId);
    this.store.deleteIsShowDefaultPlug(builderId);
  };
}

export default TableBuilderController;
