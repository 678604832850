import styled from 'styled-components';

import { ECommonColor } from '../../../../../assets/styles/colors';

import StyledLinearDropdownOption from './components/LinearDropdownOption/LinearDropdownOption.styles';

const { BorderGray } = ECommonColor;

const Wrapper = styled.div<{ $width: `${number}px` | `${number}%` }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  top: 10px;
  left: -70px;

  padding: 16px;
  margin-top: 12px;

  width: ${({ $width }) => ($width ? $width : '140px')};

  background: #ffffff;
  border-radius: 16px;

  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 6px 8px rgba(0, 0, 0, 0.05),
    0 2px 24px rgba(0, 0, 0, 0.1);

  cursor: auto;

  & ${StyledLinearDropdownOption.Wrapper}:not(:last-child) {
    border-bottom: 1px solid ${BorderGray};
    padding-bottom: 16px;
  }

  & ${StyledLinearDropdownOption.Wrapper}:not(:first-child) {
    padding-top: 16px;
  }
`;

const StyledLinearDropdownBody = {
  Wrapper,
};

export default StyledLinearDropdownBody;
