import { makeAutoObservable } from 'mobx';
import { throttle } from 'lodash';

import { provide } from '../../../common/utils/helpers/mobx';

export enum ESidebarWidth {
  SHORT = '62px',
  LARGE = '196px',
}

@provide.singleton()
export class UiStore {
  constructor() {
    makeAutoObservable(this);
    this.pageWidth = document.documentElement.clientWidth;
    this.pageHeight = document.documentElement.clientHeight;
    const vh = this.pageHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', throttle(this.handleResize, 300));
  }

  pageWidth = 1920;
  pageHeight = 1080;

  handleResize = () => {
    this.pageWidth = document.documentElement.clientWidth;
    this.pageHeight = document.documentElement.clientHeight;
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
}
