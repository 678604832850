import styled, { CSSObject } from 'styled-components';

// @ts-ignore
import arrowDownSvg from '../../assets/images/arrow-down-12x12.svg';
// @ts-ignore
import arrowTopSvg from '../../assets/images/arrow-top-12x12.svg';
import { ECommonColor } from '../../../../../assets/styles/colors';

const { GeneralBlack } = ECommonColor;

const Arrow = styled.div<{ $isDropped: boolean }>`
  width: 12px;
  height: 12px;

  background: url(${({ $isDropped }) => ($isDropped ? arrowDownSvg : arrowTopSvg)});
`;

const Icon = styled.div<{ $url: string }>`
  width: 12px;
  height: 12px;

  background: no-repeat center url(${({ $url }) => $url});
`;

const Label = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  flex: 1 1 50%;

  color: ${GeneralBlack};
`;

const Wrapper = styled.div<{ $justifyContent: CSSObject['justifyContent'] }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ $justifyContent }) => $justifyContent || 'space-between'};
  align-items: center;

  & > :not(:last-child) {
    margin-right: 9px;
  }
`;

const StyledLinearDropdownField = {
  Arrow,
  Icon,
  Label,
  Wrapper,
};

export default StyledLinearDropdownField;
