import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface IConfig {
  currentPage: number;
  totalPages: number;
  onScroll: () => Promise<any>;
  onPageChange: () => void;
}

const useDynamicPagination = <T extends HTMLElement>(
  props: IConfig
): {
  scrollRef: MutableRefObject<T>;
  isShowLoader: boolean;
} => {
  const [isFetching, setIsFetching] = useState(false);

  const scrollRef = useRef<T | null>(null);

  const scrollHandler = useCallback(() => {
    if (props?.totalPages) {
      const { currentPage, totalPages } = props;
      const { scrollTop, scrollHeight, offsetHeight } = scrollRef.current;

      /**
       * При низких разрешениях экрана нужна поправка, чтобы отрабатывала пагинация
       */
      const scrollRemarkOnLowResolutions = 1;

      const contentHeight = scrollHeight - offsetHeight - scrollRemarkOnLowResolutions;

      if (contentHeight <= scrollTop && currentPage + 1 < totalPages) {
        if (!isFetching) {
          setIsFetching(true);
        }
      }
    }
  }, [props?.currentPage, props?.totalPages, isFetching, scrollRef.current]);

  useEffect(() => {
    scrollRef.current?.addEventListener('scroll', scrollHandler);

    return () => {
      scrollRef.current?.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  useEffect(() => {
    if (isFetching && props.onPageChange && props.onScroll) {
      const { onPageChange, onScroll } = props;

      onPageChange();
      onScroll().finally(() => setIsFetching(false));
    }
  }, [isFetching]);

  const isShowLoader = useMemo(() => isFetching, [isFetching]);

  return { scrollRef, isShowLoader };
};

export default useDynamicPagination;
