import { makeAutoObservable } from 'mobx';
import { ITab } from '@farmlink/farmik-ui/dist/Tabs/types';

import { provide } from '../../../../../../../../../common/utils/helpers/mobx';
import { IDynamicTableConfig } from '../../../../../../../../../common/features/DynamicTable/models';
import { ITask } from '../../../../../../../../../../api/models/as-fields/task/Task';
import { EChecklistType } from '../../../../../../../../../../api/models/as-fields/checklists/Checklist/Checklist.model';

@provide.singleton()
class TaskReportStore {
  private _selectedTask: ITask | null = null;

  private _dynamicTableConfig: IDynamicTableConfig | null = null;

  private _selectedInstIdList: Set<string> = new Set<string>();

  private _selectedTabId: EChecklistType | null = null;

  private _availableTabList: ITab[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get selectedTask() {
    return this._selectedTask;
  }

  get dynamicTableConfig() {
    return this._dynamicTableConfig;
  }

  get selectedTabId() {
    return this._selectedTabId;
  }

  get availableTabList() {
    return this._availableTabList;
  }

  setDynamicTableConfig = (config: IDynamicTableConfig): void => {
    this._dynamicTableConfig = config;
  };

  clearDynamicTableConfig = (): void => {
    this._dynamicTableConfig = null;
  };

  get selectedInstIdList(): string[] {
    return Array.from(this._selectedInstIdList.values());
  }

  setSelectedInstId = (instId: string): void => {
    this._selectedInstIdList.add(instId);
  };

  setSelectedTask = (task: ITask): void => {
    this._selectedTask = task;
  };

  setSelectedTabId = (tab: EChecklistType): void => {
    this._selectedTabId = tab;
  };

  setAvailableTabList = (tabList: ITab[]): void => {
    this._availableTabList = tabList;
  };

  deleteSelectedInstId = (instId: string): void => {
    this._selectedInstIdList.delete(instId);
  };

  clearSelectedTask = (): void => {
    this._selectedTask = null;
  };

  clearSelectedInstIdList = (): void => {
    this._selectedInstIdList.clear();
  };

  clearSelectedTabId = (): void => {
    this._selectedTabId = null;
  };

  clearAvailableTabList = (): void => {
    this._availableTabList = [];
  };

  clearStore = (): void => {
    this.clearSelectedTask();
    this.clearDynamicTableConfig();
    this.clearSelectedInstIdList();

    this.clearSelectedTabId();
    this.clearAvailableTabList();
  };
}

export default TaskReportStore;
