import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGenerateFinalReportReq = {} & { experimentId: string };

type TGenerateFinalReportRes = Blob;

const generateFinalReport: TApiRoute & {
  request: TGenerateFinalReportReq;
  response: TGenerateFinalReportRes;
} = {
  url: ({ experimentId }) => `/api/as-fields/experiment/${experimentId}/generateFinalReport`,
  method: 'GET',
  request: {} as TGenerateFinalReportReq,
  response: {} as TGenerateFinalReportRes,
  headers: {},
};

export type { TGenerateFinalReportRes, TGenerateFinalReportReq };

export { generateFinalReport };
