import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { ComparisonTableBuilderStore as Store } from '../../stores';
import {
  ComparisonTableBuilderColumnsService as ColumnsService,
  ComparisonTableBuilderGridsService as GridsService,
  ComparisonTableBuilderRowsService as RowsService,
  ComparisonTableBuilderCellsService as CellsService,
  ComparisonTableBuilderHeaderService as HeaderService,
} from '../../services';
import { IComparisonTableBuilderConfig as IBuilderConfig } from '../../../models/configs';

@provide.transient()
class ComparisonTableBuilderController {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(HeaderService)
  protected headerService: HeaderService;

  @lazyInject(ColumnsService)
  protected columnsService: ColumnsService;

  @lazyInject(RowsService)
  protected rowsService: RowsService;

  @lazyInject(CellsService)
  protected cellsService: CellsService;

  @lazyInject(GridsService)
  protected gridsService: GridsService;

  initiateTable = (config: IBuilderConfig): void => {
    this.headerService.addHeader(config.id, config.headerConfig);
    this.columnsService.addColumnList(config.id, config.columnConfigList);

    if (config.rowsGroupConfigList) {
      this.rowsService.addRowsGroupList(config.id, config.rowsGroupConfigList);
    }

    if (config.cellConfigs) {
      Object.entries(config.cellConfigs).forEach(([rowId, cellConfigList]) => {
        this.cellsService.addCellList(config.id, rowId, cellConfigList);
      });
    }
  };

  addCellList: CellsService['addCellList'] = (builderId, rowId, configList) => {
    this.cellsService.addCellList(builderId, rowId, configList);
  };

  addRowsGroupList: RowsService['addRowsGroupList'] = (builderId, configList, options) => {
    this.rowsService.addRowsGroupList(builderId, configList, options);
  };

  updateCell: Store['updateCell'] = <M = any>(builderId, rowId, columnId, cellData) => {
    this.store.updateCell<M>(builderId, rowId, columnId, cellData);
  };

  removeRowsGroup = (builderId: string, rowsGroupId: string): void => {
    const rowsGroup = this.store.getRowsGroup(builderId, rowsGroupId);

    this.store.deleteRowsGroup(builderId, rowsGroupId);
    this.store.deleteRowList(builderId, rowsGroup.rowIdList);

    rowsGroup.rowIdList.forEach(rowId => {
      this.store.deleteCellsByRowId(builderId, rowId);
    });
  };

  clearStore = (builderId: string): void => {
    this.store.deleteHeader(builderId);
    this.store.deleteColumns(builderId);
    this.store.deleteRowsGroups(builderId);
    this.store.deleteRows(builderId);

    this.store.deleteCellsByBuilderId(builderId);
  };

  // Системные методы

  getFixedColumnWidth: GridsService['getFixedWidth'] = builderId => {
    return this.gridsService.getFixedWidth(builderId);
  };

  getScrollableColumnsData: GridsService['getScrollableColumnsData'] = builderId => {
    return this.gridsService.getScrollableColumnsData(builderId);
  };

  getColumnList: GridsService['getColumnList'] = (builderId, options) => {
    return this.gridsService.getColumnList(builderId, options);
  };

  getOrderedCellList: GridsService['getOrderedCellList'] = (builderId, rowId) => {
    return this.gridsService.getOrderedCellList(builderId, rowId);
  };
}

export default ComparisonTableBuilderController;
