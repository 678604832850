import { FC } from 'react';
import { observer } from 'mobx-react';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../hooks';
import { IComparisonTableBuilderColumn as IColumn } from '../../models/data';

interface IProps {
  column: IColumn;
}

const ComparisonTableBuilderColumnContainer: FC<IProps> = ({ column }) => {
  const getDataTestId = useDataTestId({ componentName: 'column' });

  switch (column.renderType) {
    case 'auto':
      return <div {...getDataTestId()}>{column.autoRenderConfig?.name ?? ''}</div>;
    case 'custom': {
      const dataTestId = getDataTestId()['data-test-id'];

      return <>{column.customRenderConfig.render(column, dataTestId)}</>;
    }
    default:
      return <></>;
  }
};

ComparisonTableBuilderColumnContainer.displayName = 'ComparisonTableBuilderColumnContainer';

export default observer(ComparisonTableBuilderColumnContainer);
