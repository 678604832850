import _ from 'lodash';

import { ISelectOption } from '../../../../../../../../components/form/Dropdown/Dropdown.types';

const getDefaultValue = (
  value: string | number,
  optionList: ISelectOption[],
  defaultValue?: ISelectOption
): ISelectOption => {
  if (!_.isArray(optionList) || !value) {
    return defaultValue || null;
  }

  const selectedValue = optionList.find(option => option.value === value);

  if (selectedValue) {
    return selectedValue;
  } else {
    return defaultValue || null;
  }
};

const SelectContainerHelpers = { getDefaultValue };

export default SelectContainerHelpers;
