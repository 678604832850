import {
  EExperimentStatus,
  EExperimentStepStatus,
} from '../../../../../api/models/as-fields/experiments';
import { IExperimentChangeStatusAvailability } from '../../../../../api/models/as-fields/experiments/Experiment';
import { getAvailableExperimentStatus } from '../config/experimentsStatusProgression.config copy';
import { getAvailableExperimentStepStatus } from '../config/experimentsStepStatusProgression.config';

const getAvailableOptionList = (
  currentStatus: EExperimentStatus | EExperimentStepStatus,
  availabilityScheme?: IExperimentChangeStatusAvailability,

  /**
   * Производит выборку по конфигу ExperimentStep
   */
  isAudit?: boolean
) => {
  if (isAudit) {
    return getAvailableExperimentStepStatus(
      currentStatus as EExperimentStepStatus,
      availabilityScheme
    );
  } else {
    return getAvailableExperimentStatus(currentStatus as EExperimentStatus, availabilityScheme);
  }
};

export { getAvailableOptionList };
