import styled from 'styled-components';

import { Container } from '../../../../../../../common/features/UI';

const Wrapper = styled(Container)<{ $flex?: boolean }>`
  display: flex;
  flex: ${({ $flex }) => ($flex ? 1 : 0)};
  flex-direction: column;
`;

const FooterNoDataPlug = styled.footer`
    height: 1px;
    padding-bottom: 15%;
`;

const StyledAuditPhotos = { Wrapper, FooterNoDataPlug};

export default StyledAuditPhotos;
