import { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { ECommonColor } from '../../../../../assets/styles/colors';

const { SecondaryDarkGray, HoverDarkGray, AddSky, SkyHover, BorderGray } = ECommonColor;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
`;

interface IStyledWrapperProps extends HTMLAttributes<HTMLDivElement> {
  $isSelected?: boolean;
  $isBlocked?: boolean;
}

const Wrapper = styled.div<IStyledWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  flex: 1;

  cursor: pointer;

  & ${Title} {
    color: ${({ $isSelected, $isBlocked }) => {
      if ($isBlocked) return BorderGray;

      return $isSelected ? AddSky : SecondaryDarkGray;
    }};
  }

  &:hover {
    & ${Title} {
      color: ${({ $isSelected, $isBlocked }) => {
        if ($isBlocked) return null;

        return $isSelected ? SkyHover : HoverDarkGray;
      }};
    }
  }

  &:hover::after {
    content: '';
    border: ${({ $isSelected, $isBlocked }) => {
      if ($isBlocked) return null;

      return $isSelected ? `1px solid ${SkyHover}` : `1px solid ${BorderGray}`;
    }};
  }

  ::after {
    box-sizing: border-box;
    content: '';
    height: 1px;
    width: 100%;
    border: ${({ $isSelected }) => ($isSelected ? `1px solid ${AddSky}` : null)};
    border-radius: 4px;
  }
`;

const StyledProgressBarItem = { Title, Wrapper };

export default StyledProgressBarItem;
