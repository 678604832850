import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IUser } from '../../../models/da-profile/users';

type TGetUserReq = {
  id: string;
};

type TGetUserRes = IUser;

const getUser: TApiRoute & {
  request: TGetUserReq;
  response: TGetUserRes;
} = {
  url: ({ id }) => `/api/da-profile/users/${id}`,
  method: 'GET',
  request: {} as TGetUserReq,
  response: {} as TGetUserRes,
  headers: {},
};

export type { TGetUserReq, TGetUserRes };

export { getUser };
