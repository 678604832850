import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { SpinnerLoader } from '../loaders';

import { TInfinityScrollProps } from './InfinityScroll.type';

const InfinityScroll: FC<TInfinityScrollProps> = ({
  page,
  totalPages,
  onScroll,
  onPageChange,
  children,
  scrollRef,
  showLoader = true,
}) => {
  const [fetching, setFetching] = useState(false);

  const scrollHandler = useCallback(() => {
    const { scrollTop, scrollHeight, offsetHeight } = scrollRef.current;
    const contentHeight = scrollHeight - offsetHeight;
    if (contentHeight <= scrollTop && page + 1 < totalPages) {
      if (!fetching) {
        setFetching(true);
      }
    }
  }, [page, totalPages, fetching]);

  useEffect(() => {
    scrollRef.current?.addEventListener('scroll', scrollHandler);

    return () => {
      scrollRef.current?.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  useEffect(() => {
    if (fetching) {
      onPageChange();
      onScroll().finally(() => setFetching(false));
    }
  }, [fetching]);

  const needToShowLoader = useMemo(() => fetching && showLoader, [fetching, showLoader]);

  return (
    <>
      {children}
      {needToShowLoader && (
        <SpinnerLoader data-test-id="spinner-loader" needToHideContent={false} />
      )}
    </>
  );
};

export default memo(InfinityScroll);
