import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

const { White } = ECommonColor;

const Wrapper = styled.div`
  background: ${White};
`;

const Content = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  height: auto;
  td {
    > div {
      padding: 11px 0 8px 0;
    }
  }

  &::-webkit-scrollbar {
    height: 0;
    display: none;
  }

  &::-moz-scrollbar {
    display: none;
  }

  scroll-width: none;
  scrollbar-color: transparent transparent;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
`;

export default {
  Wrapper,
  Content,
};
