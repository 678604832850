import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../../assets/styles/colors';

const { GeneralBlack } = ECommonColor;

const Wrapper = styled.div<{ $isTitle?: boolean; $showFullText?: boolean; $cellPadding: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: ${GeneralBlack};

  ${({ $isTitle }) =>
    $isTitle &&
    css`
      font-weight: 600;
    `}

  ${({ $showFullText }) =>
    !$showFullText &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
  
  padding: ${({ $cellPadding }) => ($cellPadding ? $cellPadding : 0)};
`;

const StyledAttributeName = {
  Wrapper,
};

export default StyledAttributeName;
