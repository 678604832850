import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IOrganization } from '../../../models/da-profile/organizations/Organization.model';
import { TResponseList } from '../../../models/common/response';

type TGetExperimentOrganizationListReq = {
  nameOrInn?: string;
  size?: number;
  page?: number;
};

type TGetExperimentOrganizationListRes = {} & TResponseList<IOrganization>;

const getExperimentOrganizationList: TApiRoute & {
  request: TGetExperimentOrganizationListReq;
  response: TGetExperimentOrganizationListRes;
} = {
  url: () => `/api/da-profile/organizations/list/experiment`,
  method: 'GET',
  request: {} as TGetExperimentOrganizationListReq,
  response: {} as TGetExperimentOrganizationListRes,
  headers: {},
};

export type { TGetExperimentOrganizationListReq, TGetExperimentOrganizationListRes };

export { getExperimentOrganizationList };
