import styled from 'styled-components';

const Wrapper = styled.div<{
  $bgColor?: string;
}>`
  background-color: ${({ $bgColor }) => $bgColor ?? `transparent`};
  border-radius: 16px;
  padding: 0 24px 0 0;
  margin-bottom: 16px;
`;

const TitleWrapper = styled.div`
  padding-bottom: 12px;
`;

const InfoWrapper = styled.div<{ $justifyContent: string }>`
  display: flex;
  justify-content: ${({ $justifyContent }) => ($justifyContent ? $justifyContent : 'flex-start')};
`;

const StyledCalculationInfoBlock = {
  Wrapper,
  TitleWrapper,
  InfoWrapper,
};

export default StyledCalculationInfoBlock;
