import { makeAutoObservable } from 'mobx';

import { TGetExternalRequestsTokenReq, TGetExternalRequestsTokenRes } from '../../../../../../api';
import { AxiosService } from '../../axios/AxiosService';
import { lazyInject, provide } from '../../../../utils/helpers/mobx';

@provide.singleton()
class SessionService {
  @lazyInject(AxiosService)
  private axiosService: AxiosService;

  constructor() {
    makeAutoObservable(this);
  }

  getExternalRequestsToken = async (
    payload: TGetExternalRequestsTokenReq
  ): Promise<TGetExternalRequestsTokenRes> => {
    try {
      const res = await this.axiosService.api.getExternalRequestsToken(payload);

      return res;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  verifyAccessToken = async (): Promise<boolean> => {
    try {
      await this.axiosService.api.verifyAccessToken({});

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default SessionService;
