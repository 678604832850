import { IExperiment } from '../../../models/as-fields/experiments';
import { TResponseList } from '../../../models/common/response';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TGetExperimentsListRequest = {
  size: number;
  page: number;
  organizationId?: string;
  code?: string;
};

type TGetExperimentsListResponse = {} & TResponseList<IExperiment>;

const getExperimentsList: TApiRoute & {
  request: TGetExperimentsListRequest;
  response: TGetExperimentsListResponse;
} = {
  url: `/api/as-fields/experiment/list`,
  method: 'GET',
  request: {} as TGetExperimentsListRequest,
  response: {} as TGetExperimentsListResponse,
  headers: {},
};

export type { TGetExperimentsListRequest, TGetExperimentsListResponse };

export { getExperimentsList };
