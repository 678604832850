import { TComparisonTabs } from '../../../models';

const tabContent = [
  {
    label: 'Общее',
    id: 'common' as TComparisonTabs,
    size: 'medium' as const,
  },
  {
    label: 'Попарно',
    id: 'pairwise' as TComparisonTabs,
    size: 'medium' as const,
  },
];

export default tabContent;
