import { FC, useCallback, useEffect, useMemo } from 'react';
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { FullScreen } from '../../../../../common/components/ui';
import { generateAuditsPath } from '../../utils/helpers';
import { useStore } from '../../../../../common/utils/helpers/mobx';
import { TAuditsParams } from '../../models/params/AuditsParams.model';
import { EDashboardRoute } from '../../../../routes/DashboardRoute';
import { StatusCellComponent } from '../../containers/Observations/components';
import { NavigationTabs } from '../../../../../common/features/NavigationTabs';
import { NavigationTabsStore } from '../../../../../common/features/NavigationTabs/mobx';
import { AUDIT_PATH_KEY, AUDIT_TABS } from '../../constants/AuditTabs/AuditTabs';

import AuditController from './mobx/controller/Audit.controller';
import { AuditStore } from './mobx/store';
import { AuditHelpers } from './utils/helpers';
import Styled from './Audit.style';
import { HeaderContent } from './components/HeaderContent';
import { EClearedPaths } from './utils/constants';
import useClearedCurrentPath from './hooks/path/useClearedCurrentPath/useClearedCurrentPath';
import { EAuditRoute } from './routes';
import { BackButtonContent } from './components';

const { createBackButtonText, createGoBackText } = AuditHelpers;

const Audit: FC = () => {
  const { selectedAudit, clearStore } = useStore(AuditStore);
  const { clearedCurrentPath } = useClearedCurrentPath();

  const { fetchAuditAndExp } = useStore(AuditController);

  const { clearStore: clearNavigationTabsStore, selectedTab } = useStore(NavigationTabsStore);

  const navigate = useNavigate();
  const { auditId } = useParams<TAuditsParams>();

  useEffect(() => {
    return () => {
      clearNavigationTabsStore();
    };
  }, []);

  useEffect(() => {
    fetchAuditAndExp(auditId);

    return () => {
      clearStore();
    };
  }, [auditId]);

  const backButtonText = useMemo(
    () => createBackButtonText(selectedAudit, clearedCurrentPath),
    [selectedAudit, clearedCurrentPath]
  );

  const goBackText = useMemo(() => createGoBackText(clearedCurrentPath), [clearedCurrentPath]);

  const navigateToActiveTab = useCallback(() => {
    if (selectedTab?.id) {
      const tabPathKey = selectedTab?.path.match(AUDIT_PATH_KEY);

      if (tabPathKey) {
        const navigatePath = generatePath(selectedTab.path, { auditId });

        if (navigatePath !== location.pathname) {
          navigate(navigatePath);
        }
      }
    } else {
      navigate(generateAuditsPath('auditInfo', { auditId }));
    }
  }, [selectedTab, auditId]);

  const backButtonHandler = useCallback(() => {
    if (clearedCurrentPath === EClearedPaths.editAudit) {
      navigate(generateAuditsPath('auditInfo', { auditId }));
    } else if (
      clearedCurrentPath === `${EDashboardRoute.Audits}/${EAuditRoute.Approval}` ||
      clearedCurrentPath === `${EDashboardRoute.Audits}/${EAuditRoute.Documents}`
    ) {
      navigateToActiveTab();
    } else {
      navigate(generateAuditsPath(EDashboardRoute.Audits));
    }
  }, [clearedCurrentPath, auditId, selectedTab]);

  const isHideAdditionalContent = useMemo(
    () => clearedCurrentPath === EClearedPaths.editAudit,
    [clearedCurrentPath]
  );

  const isShowNavigationTabs = useMemo(
    () =>
      !(
        clearedCurrentPath === EClearedPaths.createAudit ||
        clearedCurrentPath === EClearedPaths.editAudit ||
        clearedCurrentPath === `${EDashboardRoute.Audits}/${EAuditRoute.Approval}` ||
        clearedCurrentPath === `${EDashboardRoute.Audits}/${EAuditRoute.Documents}` ||
        clearedCurrentPath === `${EClearedPaths.taskReportClearedPath}`
      ),
    [clearedCurrentPath]
  );

  return (
    <FullScreen
      backButtonText={backButtonText}
      goBackText={goBackText}
      backButtonHandler={backButtonHandler}
      backButtonContent={
        <BackButtonContent
          selectedAudit={selectedAudit}
          backButtonText={backButtonText}
          isBackButtonTextTitleOnAuditName={isHideAdditionalContent}
        />
      }
      headerContent={<HeaderContent />}
      titleContent={
        <Styled.StatusWrapper>
          <StatusCellComponent status={selectedAudit?.status} />
        </Styled.StatusWrapper>
      }
      isHideAdditionalContent={isHideAdditionalContent}
    >
      {isShowNavigationTabs && (
        <NavigationTabs tabs={AUDIT_TABS} pathKey={AUDIT_PATH_KEY} dataTestId="audit" />
      )}
      <Outlet />
    </FullScreen>
  );
};

Audit.displayName = 'Audit';

export default observer(Audit);
