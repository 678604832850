import styled from 'styled-components';

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #151f32;
`;

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 24px;

  align-items: center;

  padding: 12px 0;
`;

const StyledExecutionTableAddFertilizerRow = {
  Title,
  Wrapper,
};

export default StyledExecutionTableAddFertilizerRow;
