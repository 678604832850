import { useEffect } from 'react';

import { IExperiment } from '../../../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../mobx/stores';
import { CreateExperimentController } from '../../mobx/controllers';
import useExperimentsParams from '../../../../hooks/useExperimentsParams/useExperimentsParams';

interface IConfig {
  /**
   * После размонтирования компонента, у нас есть возможность очищать данные
   * выбранного эксперимента, чтобы на новой странице, как пример, не были показаны
   * данные неактуального компонента.
   */
  isClearOnUnmount?: boolean;
}

const useSelectedExp = (config?: IConfig): IExperiment | null => {
  const { selectedExp, clearSelectedExp } = useStore(CreateExperimentStore);
  const { fetchExp } = useStore(CreateExperimentController);

  const { experimentId } = useExperimentsParams();

  useEffect(() => {
    (async () => {
      if (experimentId === 'create') {
        return;
      }

      await fetchExp(experimentId);
    })();
  }, [experimentId]);

  useEffect(() => {
    return () => {
      if (config?.isClearOnUnmount) {
        clearSelectedExp();
      }
    };
  }, []);

  return selectedExp;
};

export default useSelectedExp;
