import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGenerateTechTaskReportReq = {} & { experimentId: string };

type TGenerateTechTaskReportRes = Blob;

const generateTechTaskReport: TApiRoute & {
  request: TGenerateTechTaskReportReq;
  response: TGenerateTechTaskReportRes;
} = {
  url: ({ experimentId }) => `/api/as-fields/experiment/${experimentId}/generateTechTaskReport`,
  method: 'GET',
  request: {} as TGenerateTechTaskReportReq,
  response: {} as TGenerateTechTaskReportRes,
  headers: {},
};

export type { TGenerateTechTaskReportRes, TGenerateTechTaskReportReq };

export { generateTechTaskReport };
