import { Typography } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div<{ $elementWidth: string }>`
  width: ${({ $elementWidth }) => ($elementWidth ? $elementWidth : 'auto')};

  justify-content: space-between;

  &:last-child {
    width: auto;
  }
`;

const TypographyWrapper = styled(Typography)`
  color: #7e8b9d;
`;

const TitleWrapper = styled.div`
  padding-bottom: 8px;
`;

const InfoWrapper = styled.div`
  padding-bottom: 16px;
`;

const StyledCalculationInfo = {
  Wrapper,
  TitleWrapper,
  InfoWrapper,
  TypographyWrapper,
};

export default StyledCalculationInfo;
