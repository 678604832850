import { FC, memo, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Table } from '../../../../../../../common/components/table';
import { InfinityScroll, Plug } from '../../../../../../../common/components/ui';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { ExperimentController } from '../../mobx/controllers';
import { ExperimentsStore } from '../../mobx/stores';
import { getExperimentColumns } from '../../utils/helpers';
import { EExperimentsRoute } from '../../../../routes';
import { AccessStore } from '../../../../../../../common/mobx/stores/AccessStore';
import { EExperimentsFiltersBuilderId } from '../../../../constants/configs';
import { TableFiltersBuilderController } from '../../../../../../../common/features/TableFiltersBuilder/mobx/controllers';
import { ECreateExperimentRoute } from '../../../../modules/createExperiment/routes';

import Styled from './ExperimentsContentBody.styles';
import { useGetPlugInfo } from './hooks';

const Experiments: FC = observer(() => {
  const experimentsStore = useStore(ExperimentsStore);
  const { isAllowToWatchExperiments } = useStore(AccessStore);

  const tableFiltersBuilderController = useStore(TableFiltersBuilderController);

  const { currentPageNumber, totalPageNumber, experimentsList, filters, isLoading } =
    experimentsStore;

  const experimentController = useStore(ExperimentController);
  const { changePage, fetchExperimentsList } = experimentController;

  const scrollRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const noDataFindWithFilters = Object.keys(filters)?.length > 0 && experimentsList?.length === 0;

  const { plugDescription, plugIcon, plugTitle, buttonTitle, onButtonClick } = useGetPlugInfo(
    isAllowToWatchExperiments,
    noDataFindWithFilters
  );

  const showPlugButton = !noDataFindWithFilters;

  const appliedFilters = tableFiltersBuilderController.getAppliedFilters(
    EExperimentsFiltersBuilderId.Experiments
  );

  // useEffect(() => {
  //   if (isAllowToWatchExperiments) {
  //     fetchExperimentsList(appliedFilters);
  //   }
  // }, [isAllowToWatchExperiments]);

  const columns = useMemo(() => getExperimentColumns(), []);

  const onRowClick = rowDataInfo => {
    navigate(
      generatePath(`${EExperimentsRoute.Experiment}/${ECreateExperimentRoute.Execution}`, {
        experimentId: rowDataInfo.id,
      })
    );
  };

  const isNeedToShowPlug = !experimentsList?.length || !isAllowToWatchExperiments;

  return (
    <Styled.Wrapper>
      <Styled.Content ref={scrollRef} $isNeedToShowPlug={isNeedToShowPlug}>
        <InfinityScroll
          page={currentPageNumber}
          totalPages={totalPageNumber}
          onScroll={() => fetchExperimentsList(appliedFilters, false, true)}
          onPageChange={changePage}
          scrollRef={scrollRef}
          data-test-id="experiment-content-body-infinity-scroll"
        >
          <Table
            tableBodyData={experimentsList}
            tableHeadData={columns}
            onRowClick={onRowClick}
            data-test-id="experiment-content-body-table"
            isLoading={isLoading}
            isAllowToWatchTableData={isAllowToWatchExperiments}
            plugComponent={() => (
              <Plug
                icon={plugIcon}
                title={plugTitle}
                description={plugDescription}
                primaryButtonTitle={buttonTitle}
                onPrimaryButtonClick={onButtonClick}
                buttonExist={showPlugButton}
              />
            )}
          />
        </InfinityScroll>
      </Styled.Content>
    </Styled.Wrapper>
  );
});

Experiments.displayName = 'Experiments';

export default memo(Experiments);
