import { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './RemarksForm.style';

type TRemarksProps = {
  formElements: any;
};

const RemarksForm: FC<TRemarksProps> = ({ formElements }) => {
  const getDataTestId = useDataTestIdV2('remarks-form');

  const {
    planDeviation: PlanDeviation,
    technologicalDeviation: TechnologicalDeviation,
    specialNotes: SpecialNotes,
  } = formElements;

  return (
    <>
      <Styled.Container {...getDataTestId('container-plan-deviation')}>
        <Styled.TitleWrapper {...getDataTestId('title-wrapper-plan-deviation')}>
          <Styled.Title {...getDataTestId('title-plan-deviation')}>
            Отклонения от плана
          </Styled.Title>
        </Styled.TitleWrapper>
        {PlanDeviation ? <PlanDeviation /> : null}
      </Styled.Container>
      <Styled.Container {...getDataTestId('container-technological-deviation')}>
        <Styled.TitleWrapper {...getDataTestId('title-wrapper-technological-deviation')}>
          <Styled.Title {...getDataTestId('title-technological-deviation')}>
            Разница в технологиях
          </Styled.Title>
        </Styled.TitleWrapper>
        {TechnologicalDeviation ? <TechnologicalDeviation /> : null}
      </Styled.Container>
      <Styled.Container {...getDataTestId('container-special-notes')}>
        <Styled.TitleWrapper {...getDataTestId('title-wrapper-special-notes')}>
          <Styled.Title {...getDataTestId('title-special-notes')}>Особые отметки</Styled.Title>
        </Styled.TitleWrapper>
        {SpecialNotes ? <SpecialNotes /> : null}
      </Styled.Container>
    </>
  );
};
export default memo(RemarksForm);
