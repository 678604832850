const getFDCellComponentName = FD => {
  if (FD) {
    const pathText = FD;
    const indexOfFirstSlash = pathText.indexOf('/', pathText.indexOf('/') + 1);
    const FDTextBetweenSlashes = pathText.substr(indexOfFirstSlash + 1);

    return FDTextBetweenSlashes;
  }

  return '';
};

export default getFDCellComponentName;
