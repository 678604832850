import { FC, memo, PropsWithChildren, useContext } from 'react';
import { Colors, Icon, IconButton } from '@farmlink/farmik-ui';

import { ComparisonTableBuilderContext as Context } from '../../../context';

import Styled from './ComparisonTableBuilderSliderControls.styles';

const iconColorScheme = {
  default: {
    background: '#F6F7F8',
    icon: Colors.secondaryDarkGray,
  },
  hover: {
    background: '#F6F7F8',
    icon: Colors.generalBlack,
  },
  disabled: {
    background: '#F6F7F8',
    icon: Colors.borderGray,
  },
};

const ComparisonTableBuilderSliderControls: FC<PropsWithChildren> = ({ children }) => {
  const context = useContext(Context);

  const handleArrowClick = (position: 'left' | 'right') => {
    if (!context.sliderControl.allowScroll) return;
    if (position === 'right') {
      context.sliderControl.onArrowClick(position);
    } else {
      context.sliderControl.onArrowClick(position);
    }
  };

  const isShowControls =
    context.sliderControl.isShowControls && !context.sliderControl.isHideSliderControls;

  return (
    <Styled.Wrapper>
      {children}

      {isShowControls ? (
        <Styled.ArrowsWrapper>
          <IconButton
            icon="arrow_left"
            size={40}
            onClick={() => handleArrowClick('left')}
            colorScheme={iconColorScheme}
            $padding="12px"
            $isDisabled={context.sliderControl.isLeftArrowDisabled}
          />

          <IconButton
            icon="arrow_right"
            size={40}
            onClick={() => handleArrowClick('right')}
            colorScheme={iconColorScheme}
            $padding="12px"
            $isDisabled={context.sliderControl.isRightArrowDisabled}
          />
        </Styled.ArrowsWrapper>
      ) : null}
    </Styled.Wrapper>
  );
};

ComparisonTableBuilderSliderControls.displayName = 'ComparisonTableBuilderSliderControls';

export default memo(ComparisonTableBuilderSliderControls);
