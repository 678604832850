import { lazyInject, provide } from '../../../../../../../../../common/utils/helpers/mobx';
import { ExperimentStepsService } from '../../../../../../../../../common/mobx/services/as-fields';
import { IDynamicTableConfig } from '../../../../../../../../../common/features/DynamicTable/models';
import { AuditPhotosComparisonTableHelpers } from '../../utils/helpers';
import { TGetMonitoringReportDataRes } from '../../../../../../../../../../api';
import { DynamicTableController } from '../../../../../../../../../common/features/DynamicTable/mobx/controllers';
import { DynamicTableStore } from '../../../../../../../../../common/features/DynamicTable/mobx/stores';

const { createTableConfig, createImagesConfigList, createReportImagesConfigs, createInstanceList } =
  AuditPhotosComparisonTableHelpers;

@provide.transient()
class AuditPhotosController {
  @lazyInject(ExperimentStepsService)
  protected experimentStepsService: ExperimentStepsService;

  @lazyInject(DynamicTableStore)
  protected dynamicTableStore: DynamicTableStore;

  @lazyInject(DynamicTableController)
  protected dynamicTableController: DynamicTableController;

  fetchMonitoringReportData = async (stepId: string): Promise<TGetMonitoringReportDataRes> => {
    const { getMonitoringReportData } = this.experimentStepsService;

    const reportData = await getMonitoringReportData({ id: stepId });

    if (reportData) {
      return reportData;
    }
  };

  saveImagesConfig = async (stepId: string): Promise<void> => {
    const report = createReportImagesConfigs(this.dynamicTableStore.imagesConfigList);

    await this.experimentStepsService.updateExperimentStep({
      experimentStepId: stepId,
      report,
    });

    this.dynamicTableStore.setIsImagesTableIsEdited(false);
  };

  createTableConfig = async (
    auditId: string,
    isEnableEditing: boolean
  ): Promise<IDynamicTableConfig> => {
    const { setImagesConfigList } = this.dynamicTableStore;

    const { addInstanceList } = this.dynamicTableController;

    const reportData = await this.fetchMonitoringReportData(auditId);

    if (!reportData) {
      return;
    }

    const tableConfig = createTableConfig(reportData, isEnableEditing);

    setImagesConfigList(
      createImagesConfigList(reportData.imagesConfig ?? {}, tableConfig.headColumn.stages)
    );

    if (reportData?.experimentZones) {
      addInstanceList(createInstanceList(reportData.experimentZones));
    }

    return tableConfig;
  };
}

export default AuditPhotosController;
