import { Stage } from '../../../../../../../../../../common/features/DynamicTable/models';
import { AuditReportAttrWithCustomProp as AttrWithCustomProp } from '../../../models';

const createViewTaskAttr = (stageId: string): AttrWithCustomProp => {
  return {
    id: 'view-task-attr',
    name: '',
    customProp: {
      stageId,
      type: null,
    },
  };
};

const createDateAttr = (stageId: string): AttrWithCustomProp => {
  return {
    id: 'date-attr',
    name: 'Дата проведения',
    customProp: {
      stageId,
      type: null,
    },
  };
};

const auditReportAddSystemStage = (): Stage => {
  const fieldsTotalInfoId = 'info-stage';

  return {
    id: fieldsTotalInfoId,
    name: 'ОТЧЕТЫ ПО УЧАСТКАМ',
    attributes: [createDateAttr(fieldsTotalInfoId), createViewTaskAttr(fieldsTotalInfoId)],
  };
};

export default auditReportAddSystemStage;
