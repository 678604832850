import { observer } from 'mobx-react';
import { FC, memo } from 'react';

import { TContentBodyProps } from './ContentInfoBlock.types';

const PageContentInfoBlock: FC<TContentBodyProps> = observer(({ children }) => {
  return <>{children}</>;
});

export default memo(PageContentInfoBlock);
