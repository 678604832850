import { observer } from 'mobx-react';
import { FC } from 'react';

import { ChatJournalStore } from '../../mobx/stores';
import { useStore } from '../../../../utils/helpers/mobx';
import { MessageList } from '..';
import { CommentInput } from '../../components';
import { ICommentInputProps } from '../../components/CommentInput';

type TChatJournalProps = { chatInstanceId: string; isReadOnly?: boolean } & ICommentInputProps;

const ChatJournal: FC<TChatJournalProps> = ({ chatInstanceId, onSubmit, isReadOnly }) => {
  const { getChatInstance } = useStore(ChatJournalStore);

  const chatInstance = getChatInstance(chatInstanceId);

  const messageList = chatInstance?.messageList || [];

  return (
    <>
      <MessageList messageList={messageList} />

      {!isReadOnly ? <CommentInput onSubmit={onSubmit} /> : null}
    </>
  );
};

ChatJournal.displayName = 'ChatJournal';

export default observer(ChatJournal);
