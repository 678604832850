import { FC, memo, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../utils/helpers/mobx';
import { DynamicTableStore } from '../../../mobx/stores';
import { ComparisonTableImagesAttributeValue as ImagesAttributeValue } from '../../../components/attributeValues';
import { IImagesSelectionCardProps } from '../../../../UI/ImagesSelectionCard/ImagesSelectionCard';

interface IProps {
  tableId: string;
  attributeValueId: string;
  order: number;
  isEnableEditing: boolean;
}

const ComparisonTableImagesAttributeValueContainer: FC<IProps> = ({
  tableId,
  attributeValueId,
  order,
  isEnableEditing,
}) => {
  const { getImagesConfig, getInstanceList, getAttributeValue } = useStore(DynamicTableStore);

  const instanceList = getInstanceList(tableId);
  const extendedAttributeValue = getAttributeValue(tableId, attributeValueId);
  const imagesConfig = getImagesConfig(extendedAttributeValue?.attributeId);

  const extendedInstance = instanceList?.find?.(instance => instance.order === order);

  const selectedInstance = imagesConfig?.selectedInstanceList?.find?.(
    instance => instance.instanceId === extendedAttributeValue?.instanceId
  );

  const imageSizes = useMemo<Pick<IImagesSelectionCardProps, 'cardWidth' | 'mainHeight'>>(() => {
    if (instanceList.length >= 4) {
      return {
        cardWidth: '205px',
        mainHeight: '128px',
      };
    }

    if (instanceList.length === 3) {
      return {
        cardWidth: '278px',
        mainHeight: '174px',
      };
    }

    if (instanceList.length <= 2) {
      return {
        cardWidth: '424px',
        mainHeight: '228px',
      };
    }
  }, [instanceList?.length]);

  return (
    <>
      {extendedAttributeValue ? (
        <ImagesAttributeValue
          extendedInstance={extendedInstance}
          extendedAttributeValue={extendedAttributeValue}
          imageSizes={imageSizes}
          selectedInstance={selectedInstance}
          isEnableEditing={isEnableEditing}
        />
      ) : null}
    </>
  );
};

ComparisonTableImagesAttributeValueContainer.displayName =
  'ComparisonTableImagesAttributeValueContainer';

export default memo(observer(ComparisonTableImagesAttributeValueContainer));
