import { Typography } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-bottom: 20px;
`;

const MainInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const StatusComponentWrapper = styled.div`
  margin: auto;
  margin-left: 12px;
`;

const ContextualIconWrapper = styled.div`
  margin: 1px 0 0 4px;
`;

const AdditionalInfo = styled(Typography)`
  color: #7e8b9d;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 876px;
`;

const ExperimentName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 508px;
`;

const ExperimentInfoWrapper = styled.div`
  display: flex;
`;

const StyledBackButtonContent = {
  Wrapper,
  MainInfoWrapper,
  StatusComponentWrapper,
  ContextualIconWrapper,
  AdditionalInfo,
  ExperimentName,
  ExperimentInfoWrapper,
};

export default StyledBackButtonContent;
