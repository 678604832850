import { Plug } from '../../../../../../../../../common/components/ui';
import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';
import applicationTime from '../../assets/icons/application_time.svg';

import Styled from './RemarksPlug.styles';

export const RemarksPlug = () => {
  const getDataTestId = useDataTestIdV2('audit-plug');

  return (
    <Styled.Wrapper>
      <Styled.Title {...getDataTestId('title')}>Наблюдения</Styled.Title>
      <Plug
        icon={applicationTime}
        title="Вы ещё не внесли данные по наблюдениям"
        description="Добавить наблюдения можно на вкладке «Исполнение» или в разделе «Наблюдения»"
      />
    </Styled.Wrapper>
  );
};
