import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0 12px;
`;

const StyledTableFiltersBuilderBooleanFilter = {
  Wrapper,
};

export default StyledTableFiltersBuilderBooleanFilter;
