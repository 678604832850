import { MouseEvent, memo, useCallback, forwardRef } from 'react';

import {
  ILinearDropdownChange,
  ILinearDropdownOption,
  ILinearDropdownProps,
} from '../../LineralDropdown.types';

import Styled from './LinearDropdownBody.styles';
import { LinearDropdownOption } from './components';

interface ILinearDropdownBodyProps {
  optionList: ILinearDropdownOption[];
  onOptionCLick: ILinearDropdownChange;
  styles: ILinearDropdownProps['styles']['dropdownBody'];
}

const LinearDropdownBody = forwardRef<HTMLDivElement, ILinearDropdownBodyProps>(
  ({ optionList, onOptionCLick, styles }, ref) => {
    const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    }, []);

    return (
      <Styled.Wrapper $width={styles?.width} onClick={handleClick} ref={ref}>
        {optionList.map(option => (
          <LinearDropdownOption key={option?.value} option={option} onOptionClick={onOptionCLick} />
        ))}
      </Styled.Wrapper>
    );
  }
);

LinearDropdownBody.displayName = 'LinearDropdownBody';

export default memo(LinearDropdownBody);
