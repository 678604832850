import styled from 'styled-components';

const Header = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 24px;
`;

const Item = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
`;

const StyledDosageValueCell = {
  Header,
  Item,
};

export default StyledDosageValueCell;
