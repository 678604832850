import styled from 'styled-components';

import { createComparisonTableBuilderRowClassName as createRowClassName } from '../../../../../../../../../common/features/ComparisonTableBuilder/helpers';
import { EComparisonTableName } from '../../../../../../../../constants/features';

const StickyWrapper = styled.div`
  position: sticky;
  top: -24px;
  padding: 24px 0;

  background-color: #faf9fd;

  /**
    Чтобы текст строк (из-за того, что там используется тултип)
    не отображался поверх хедера таблицы.
   */
  z-index: 2;
`;

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 16px;
  padding: 8px 24px;

  & .${createRowClassName(EComparisonTableName.TotalComparison)} {
    padding: 0 12px;
  }
`;

const StyledTotalComparisonHeader = {
  Wrapper,
  StickyWrapper,
};

export default StyledTotalComparisonHeader;
