import { Plug } from '../../../../../../../../../common/components/ui';
import applicationTime from '../../assets/icons/application_time.svg';

import Styled from './ComparisonTablePlug.styles';

export const ComparisonTablePlug = () => {
  return (
    <Styled.Wrapper>
      <Plug
        icon={applicationTime}
        title="В этом блоке будет таблица для сравнения плановых значений по участкам опыта"
        description="Чтобы таблица была сформирована, внесите данные на вкладке «Расчёт»"
      />
    </Styled.Wrapper>
  );
};
