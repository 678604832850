// @ts-ignore
import boronovanieSvg from './assets/boronovanie.svg';
// @ts-ignore
import diskovanieSvg from './assets/diskovanie.svg';
// @ts-ignore
import kultivaciyaSvg from './assets/kultivaciya.svg';
// @ts-ignore
import monitoringSvg from './assets/monitoring.svg';
// @ts-ignore
import opriskivanieSvg from './assets/opriskivanie.svg';
// @ts-ignore
import pahotaSvg from './assets/pahota.svg';
// @ts-ignore
import posevSvg from './assets/posev.svg';
// @ts-ignore
import uborkaSvg from './assets/uborka.svg';
// @ts-ignore
import udobreniyaSvg from './assets/udobreniya.svg';
// @ts-ignore
import defaultOperation from './assets/defaultOperation.svg';

const getOperstionTypeSvg = (operationTypeId: string): any => {
  // todo Рефактор завязываться так на айди очень плохо на фронте
  switch (operationTypeId) {
    case '90ab2d38-5e84-4a96-a59f-b2f64ed727c8':
      return defaultOperation; // audit posevov
    case '00ecc3cd-b32d-4673-92a3-52e9ccc7b0b1':
      return boronovanieSvg;
    case '4644a5f4-e0a2-48ad-b0da-204d23495410':
      return udobreniyaSvg;

    case 'e015ef2a-c00b-47db-882d-b0320182f0a2':
      return pahotaSvg;
    case '0fa85f64-5717-4562-b3fc-2c963f66afa6':
      return diskovanieSvg;
    case '6a7cde39-1c04-45d2-912b-39bf94e491e1':
      return defaultOperation; // инокуляция

    case 'fb9cfdbd-baf1-44ca-8414-480bd5868590':
      return diskovanieSvg; // лущение
    case 'dd854e27-967f-4a37-93d5-a21828bffe02':
      return monitoringSvg;
    case '422cfb9d-7fe7-4330-998b-5043cfafe875':
      return opriskivanieSvg;
    case '341ef983-f8ef-4d91-8f4c-14aa7b71eaaa':
      return opriskivanieSvg;
    case '116576a8-d6cd-41a9-b87c-6801e6062f19':
      return opriskivanieSvg;
    case '65b3db9e-1b40-49b9-9274-9d1e29a6849c':
      return kultivaciyaSvg;
    case '8a6b16a6-f226-41d6-817d-43a791931a6d':
      return kultivaciyaSvg;
    case '33113393-3d2d-4219-97f4-c4727649f72c':
      return defaultOperation; // Предуборочный мониторинг

    case '9897d453-1705-4b3b-a43f-490d969c0b71':
      return defaultOperation; // Протравливание семян яровых и озимых культур
    case 'c8fa78f5-6616-4ab4-91c2-cdb4d07d5406':
      return posevSvg;
    case 'abfd4772-e18a-4d0c-a71a-4123d89fad21':
      return posevSvg; // ???Сев яровых и озимых культур

    case '7ed3a3ec-e743-4c81-b636-9e9f7395c4a4':
      return uborkaSvg; // Уборка. Контроль за комбайном
    case '860514c2-3818-4fdc-addd-3b16c6f9b779':
      return uborkaSvg; // Уборка. Общий контроль на поле

    default:
      return defaultOperation;
  }
};

export default getOperstionTypeSvg;
