import styled from 'styled-components';
import { Button } from '@farmlink/farmik-ui';

const ActionButton = styled(Button)`
  width: 160px;
  height: 40px;

  justify-content: center;

  border-radius: 12px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;

  cursor: pointer;
`;

const Icons = styled.div`
  display: flex;
  gap: 12px;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;

  color: #151f32;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTableFiltersBuilderHeader = {
  ActionButton,
  IconWrapper,
  Icons,
  Controls,
  Title,
  TitleWrapper,
  Header,
};

export default StyledTableFiltersBuilderHeader;
