import { FC, memo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@farmlink/farmik-ui';

import { InstanceComponentProps } from '../../../../../../../../../common/features/DynamicTable/models/Instance/Instance.model';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import TaskReportStore from '../../mobx/stores/TaskReport.store';
import { TaskReportInstCustomProp as CustomProp } from '../../models';
import { useInstance } from '../../../../../../../../../common/features/DynamicTable/hooks';

import Styled from './TaskReportInstanceHeader.styles';

const TaskReportInstanceHeader: FC<InstanceComponentProps<CustomProp>> = ({ instance }) => {
  const { setSelectedInstId, deleteSelectedInstId } = useStore(TaskReportStore);
  const { changeInstanceCustomProp } = useInstance();

  const selectInstance = useCallback(
    (value: boolean) => {
      const { tableId, id, customProp } = instance;

      if (value) {
        setSelectedInstId(instance.id);
        changeInstanceCustomProp<CustomProp>(tableId, id, { ...customProp, isSelected: true });
      } else {
        deleteSelectedInstId(instance.id);
        changeInstanceCustomProp<CustomProp>(tableId, id, { ...customProp, isSelected: false });
      }
    },
    [instance.id]
  );

  return (
    <Styled.Wrapper>
      <Checkbox value={instance.customProp.isSelected} onChange={selectInstance} />
      {instance.name}
    </Styled.Wrapper>
  );
};

TaskReportInstanceHeader.displayName = 'TaskReportInstanceHeader';

export default memo(observer(TaskReportInstanceHeader));
