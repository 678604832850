import { FC, memo } from 'react';

import { ITablePlugBuilderAutoRenderConfig as IAutoRenderConfig } from '../../models/configs';
import { useDataTestId } from '../../../../utils/hooks/locators';

import Styled from './TablePlugBuilderAutoRender.styles';

const DEFAULT_DATA_TEST_ID = 'table-plug-body';

type TProps = { dataTestId?: string } & IAutoRenderConfig;

const TablePlugBuilderAutoRender: FC<TProps> = ({ dataTestId, header, content, footer }) => {
  const getDataTestId = useDataTestId(dataTestId ? `${dataTestId}-content` : DEFAULT_DATA_TEST_ID);

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      {header ? (
        <Styled.Header {...getDataTestId('header')}>
          <Styled.Icon $name={header.icon} {...getDataTestId('icon')} />
        </Styled.Header>
      ) : null}

      {content ? (
        <Styled.Content {...getDataTestId('content')}>
          <Styled.Title {...getDataTestId('title')}>{content.title}</Styled.Title>

          <Styled.Description {...getDataTestId('description')}>
            {content.description}
          </Styled.Description>
        </Styled.Content>
      ) : null}

      {footer ? (
        <Styled.Footer {...getDataTestId('footer')}>
          {footer.renderButton ? footer.renderButton() : null}
        </Styled.Footer>
      ) : null}
    </Styled.Wrapper>
  );
};

TablePlugBuilderAutoRender.displayName = 'TablePlugBuilderAutoRender';

export default memo(TablePlugBuilderAutoRender);
