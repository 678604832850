import { FC, memo } from 'react';

import { notes } from '../../assets/icons';

import Styled from './NoMessagePlaceholder.styles';

const NoMessagePlaceholder: FC<{ title?: string; subtitle?: string }> = ({ title, subtitle }) => {
  return (
    <Styled.NoMessagePlaceholderWrapper>
      <Styled.NoMessageIcon data-test-id="no-message-icon" src={notes} alt="no-message-icon" />
      <Styled.NoMessageTitle data-test-id="no-message-title">{title}</Styled.NoMessageTitle>
      <Styled.NoMessageSubtitle data-test-id="no-message-subtitle">
        {subtitle}
      </Styled.NoMessageSubtitle>
    </Styled.NoMessagePlaceholderWrapper>
  );
};

NoMessagePlaceholder.displayName = 'NoMessagePlaceholder';

export default memo(NoMessagePlaceholder);
