import styled, { css } from 'styled-components';

import { TTableBuilderCellPreset as TCellPreset } from '../../../types/cells';

const Wrapper = styled.div<{ $hasClickEvent?: boolean; $preset?: TCellPreset }>`
  padding: ${({ $preset }) => ($preset === 'big-margins' ? '18px 0' : '10px 0 12px')};

  ${({ $hasClickEvent }) =>
    $hasClickEvent
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: auto;
        `}
`;

const StyledTableBuilderRow = {
  Wrapper,
};

export default StyledTableBuilderRow;
