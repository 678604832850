import { FC } from 'react';

import Styled from './Tabs.styles';

export enum ETabs {
  Experiments = 'Experiments',
  Documents = 'Documents',
}

interface IProps {
  activeTab: ETabs;
  onTabClick: (tab: ETabs) => void;
}

const tabList = [
  {
    id: ETabs.Experiments,
    label: 'Опыты',
  },
  {
    id: ETabs.Documents,
    label: 'Документы',
  },
];

const Tabs: FC<IProps> = ({ activeTab, onTabClick }) => {
  return (
    <Styled.Wrapper>
      {tabList.map(({ id, label }) => (
        <Styled.Tab isActive={activeTab === id} key={label} onClick={() => onTabClick(id)}>
          {label}
        </Styled.Tab>
      ))}
    </Styled.Wrapper>
  );
};

Tabs.displayName = 'Tabs';

export default Tabs;
