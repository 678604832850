import { omit } from 'lodash';

import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { DynamicTableStore } from '../../stores';
import {
  Stage,
  _Stage,
  IDynamicTableConfig,
  Instance,
  _Attribute,
  IAttribute,
  _ICollapsingStage,
  ICollapsingStage,
  IAttributeValue,
  _AttributeValue,
  _Instance,
  IComparisonTableImagesConfig as IImagesConfig,
  IComparisonTableImagesConfigSelectedInstance as IImagesConfigSelectedInstance,
} from '../../../models';

@provide.singleton()
class DynamicTableController {
  @lazyInject(DynamicTableStore)
  dynamicTableStore: DynamicTableStore;

  createTable = (config: IDynamicTableConfig): void => {
    this.createCollapsingStageList(config);
    this.createStageList(config.id, config.headColumn.stages);

    config.headColumn.stages.forEach(stage => {
      if (stage?.nestedStages) {
        this.createStageList(config.id, stage.nestedStages);
      }
    });

    if (config?.instances) {
      this.addInstanceList(config.instances);
    }
  };

  protected createCollapsingStageList = (config: IDynamicTableConfig): void => {
    const { setCollapsingStageList } = this.dynamicTableStore;

    if (!config.headColumn?.collapsingStages) {
      return;
    }

    const _collapsingStageList = config.headColumn.collapsingStages.map<_ICollapsingStage>(
      (collapsingStage, index) => {
        return this.createCollapsingStage(
          config.id,
          collapsingStage,
          index + 1,
          config.headColumn.stages
        );
      }
    );

    setCollapsingStageList(_collapsingStageList);
  };

  protected createCollapsingStage = (
    tableId: string,
    collapsingStage: ICollapsingStage,
    order: number,
    stages: Stage[]
  ): _ICollapsingStage => {
    const stageIds = stages.reduce<string[]>((stageIdList, stage) => {
      if (stage?.collapsingStageId === collapsingStage.id) {
        stageIdList.push(stage.id);
      }

      return stageIdList;
    }, []);

    return {
      ...collapsingStage,
      tableId,
      order,
      stageIds,
      isCollapsed: false,
    };
  };

  protected createStageList = (tableId: string, stageList: Stage[]): string[] => {
    const { setStageList } = this.dynamicTableStore;

    const _stageList = stageList.map<_Stage>((stage, index) => {
      return this.createStage(tableId, stage, index + 1);
    });

    setStageList(_stageList);

    return _stageList.map<string>(({ id }) => id);
  };

  protected createStage = (tableId: string, stage: Stage, order: number): _Stage => {
    const _stage: _Stage = {
      ...stage,
      tableId,
      order,
      attributeIds: this.createAttrList(tableId, stage),
    };

    if (stage?.nestedStages) {
      _stage.nestedStageIds = stage.nestedStages.map<string>(({ id }) => id);
    }

    return _stage;
  };

  protected createAttrList = (tableId: string, stage: Stage): string[] => {
    const { setAttributeList } = this.dynamicTableStore;

    const _attrList = stage.attributes.map<_Attribute>((attr, index) => {
      return this.createAttr(tableId, stage, attr, index + 1);
    });

    setAttributeList(_attrList);

    return _attrList.map<string>(({ id }) => id);
  };

  protected createAttr = (
    tableId: string,
    stage: Stage,
    attribute: IAttribute,
    order: number
  ): _Attribute => {
    const { getConfig } = this.dynamicTableStore;

    const config = getConfig(tableId);

    const id = config?.system?.isIgnoreStageIdInAttr
      ? attribute.id
      : this.createAttrId(attribute.id, stage.id);

    const attr: _Attribute = {
      ...attribute,
      id,
      tableId,
      order,
      stageId: stage.id,
      collapsingStageId: stage?.collapsingStageId,
    };

    if (attribute?.stages) {
      attr.stageIds = this.createNestedStageList(tableId, attribute.id, attribute.stages);
    }

    if (attribute?.attributeValueList) {
      attr.attributeValueIdList = attribute.attributeValueList.map(attrVal => attrVal.id);

      this.createAttrValList(tableId, attribute.attributeValueList);
    }

    return attr;
  };

  protected createNestedStageList = (
    tableId: string,
    attrId: string,
    stageList: Stage[]
  ): string[] => {
    const { setStageList } = this.dynamicTableStore;

    const _stageList = stageList.map<_Stage>((stage, index) => {
      return this.createNestedStage(tableId, attrId, stage, index + 1);
    });

    setStageList(_stageList);

    return _stageList.map<string>(({ id }) => id);
  };

  protected createNestedStage = (
    tableId: string,
    attrId: string,
    stage: Stage,
    order: number
  ): _Stage => {
    const formattedStage = omit(stage, ['attributes', 'nestedStages']);

    return {
      ...formattedStage,
      tableId,
      order,
      rootAttributeId: attrId,
      attributeIds: this.createAttrList(tableId, stage),
    };
  };

  protected createInstanceList = (instList: Instance[]): void => {
    const { setInstanceList } = this.dynamicTableStore;

    const _instList = instList.map<_Instance>((inst, index) => {
      return this.createInstance(inst, index + 1);
    });

    setInstanceList(_instList);
  };

  protected createInstance = (inst: Instance, order: number): _Instance => {
    const { setAttributeValueList } = this.dynamicTableStore;

    const _inst = omit(inst, ['attributeValues']);

    setAttributeValueList(this.createAttrValList(inst.tableId, inst.attributeValues));

    return {
      ..._inst,
      order,
    };
  };

  protected createAttrValList = (
    tableId: string,
    attrValList: IAttributeValue[]
  ): _AttributeValue[] => {
    const { setAttributeValueList } = this.dynamicTableStore;

    const _attrValList = attrValList.map(attrVal => this.createAttrVal(tableId, attrVal));

    setAttributeValueList(_attrValList);

    return _attrValList;
  };

  protected createAttrVal = (tableId: string, attrVal: IAttributeValue): _AttributeValue => {
    const { getConfig } = this.dynamicTableStore;

    const config = getConfig(tableId);

    const attributeId = config?.system?.isIgnoreStageIdInAttr
      ? attrVal.attributeId
      : this.createAttrId(attrVal.attributeId, attrVal?.stageId);

    return {
      ...attrVal,
      attributeId,
      stageId: attrVal?.stageId,
      tableId,
    };
  };

  addInstanceList = (instList: Instance[]): void => {
    this.createInstanceList(instList);
  };

  addInstance = (instance: Instance): void => {
    const { getInstanceList } = this.dynamicTableStore;

    const total = getInstanceList(instance.tableId).length;
    const order = total + 1;

    this.createInstance(instance, order);
  };

  /**
   * @param prevInstOrder - Порядковый номер предыдущей инстансы;
   * @param instance - Новая инстанса.
   */
  replaceInstance = (prevInstOrder: number, instance: Instance): void => {
    const {
      getInstanceList,
      getValueListByInstanceId,
      setInstance,
      deleteInstance,
      deleteAttributeValue,
    } = this.dynamicTableStore;

    const instList = getInstanceList(instance.tableId);
    const prevInst = instList.find(({ order }) => order === prevInstOrder);

    if (!prevInst) {
      return;
    }

    const prevValList = getValueListByInstanceId(prevInst.tableId, prevInst.id);
    prevValList.forEach(value => deleteAttributeValue(prevInst.tableId, value.id));

    deleteInstance(prevInst.tableId, prevInst.id);

    const createdInstance = this.createInstance(instance, prevInstOrder);
    setInstance(createdInstance);
  };

  onToggleCollapsingStage = (tableId: string, id: string, value: boolean): void => {
    const { getCollapsingStage, setCollapsingStage } = this.dynamicTableStore;

    setCollapsingStage({ ...getCollapsingStage(tableId, id), isCollapsed: value });
  };

  onToggleImagesStageSelection = (stageId: string, isSelected: boolean): void => {
    const { getImagesConfig, setImagesConfig, setIsImagesTableIsEdited } = this.dynamicTableStore;

    const previouslyCreatedConfig = getImagesConfig(stageId);

    setIsImagesTableIsEdited(true);

    if (previouslyCreatedConfig) {
      setImagesConfig({ ...previouslyCreatedConfig, isSelected });
    } else {
      setImagesConfig({
        id: stageId,
        orientation: 'horizontal',
        isSelected,
        selectedInstanceList: [],
      });
    }
  };

  onToggleImagesStageOrientation = (
    stageId: string,
    orientation: IImagesConfig['orientation']
  ): void => {
    const { getImagesConfig, setImagesConfig, setIsImagesTableIsEdited } = this.dynamicTableStore;

    const previouslyCreatedConfig = getImagesConfig(stageId);

    if (previouslyCreatedConfig) {
      setIsImagesTableIsEdited(true);
      setImagesConfig({ ...previouslyCreatedConfig, orientation });
    }
  };

  onToggleImageSelection = (stageId, selectedInstance: IImagesConfigSelectedInstance): void => {
    const { getImagesConfig, setImagesConfig, setIsImagesTableIsEdited } = this.dynamicTableStore;

    const previouslyCreatedConfig = getImagesConfig(stageId);

    if (previouslyCreatedConfig) {
      const previouslySelectedInstance = previouslyCreatedConfig.selectedInstanceList.find(
        instance => instance.instanceId === selectedInstance.instanceId
      );

      setIsImagesTableIsEdited(true);

      if (previouslySelectedInstance) {
        // Проверяем, на то, выбрана ли такая же фотография.
        const hasSelectedTheSameImage =
          previouslySelectedInstance.imageId === selectedInstance.imageId;

        // Удаляем из списка выбранных инстансов ту, id которой совпал с новой.
        const instanceListWithoutSelected = previouslyCreatedConfig.selectedInstanceList.filter(
          instance => instance.instanceId !== selectedInstance.instanceId
        );

        setImagesConfig({
          ...previouslyCreatedConfig,
          // Сетаем список инстансов включая новую, если у нее выбрана другая фотография.
          selectedInstanceList: hasSelectedTheSameImage
            ? instanceListWithoutSelected
            : [...instanceListWithoutSelected, selectedInstance],
        });
      } else {
        setImagesConfig({
          ...previouslyCreatedConfig,
          selectedInstanceList: [...previouslyCreatedConfig.selectedInstanceList, selectedInstance],
        });
      }
    }
  };

  protected createAttrId = (attrId: string, stageId: string): string => {
    return `${attrId}-stageId-${stageId}`;
  };

  clearTableStore = (tableId: string): void => {
    const {
      deleteTableConfig,
      deleteTableCollapsingStageList,
      deleteTableStageList,
      deleteTableAttributeList,
      deleteTableInstanceList,
      deleteTableAttributeValueList,
      clearImagesConfigsById,
    } = this.dynamicTableStore;

    clearImagesConfigsById();

    deleteTableConfig(tableId);
    deleteTableCollapsingStageList(tableId);
    deleteTableStageList(tableId);
    deleteTableAttributeList(tableId);
    deleteTableInstanceList(tableId);
    deleteTableAttributeValueList(tableId);
  };
}

export default DynamicTableController;
