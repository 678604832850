import styled from 'styled-components';
import { IconButton } from '@farmlink/farmik-ui';

const ActionButton = styled(IconButton)`
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

const StyledSidePanelActionsMenu = {
  ActionButton,
};

export default StyledSidePanelActionsMenu;
