import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { IFormConfig } from '../../../../../../../../../../../common/features/form/models';

const INVENTORY_VALUES_FORM_KEY = 'addInventoryValues';

const REQUIRED_TEXT = 'Это поле не может быть пустым';

interface IInventoryValuesForm {
  typeValue: string;
  nameValue: string;
  dosage: string;
}

const createInventoryValuesFormConfig = ({
  fertilizersSearchQueryHandler,
  protectionsSearchQueryHandler,
}: {
  fertilizersSearchQueryHandler?: (v: string) => Promise<ISelectOption[]>;
  protectionsSearchQueryHandler?: (v: string) => Promise<ISelectOption[]>;
}): IFormConfig<IInventoryValuesForm> => {
  const inventoryValuesFormConfig: IFormConfig<IInventoryValuesForm> = {
    formKey: INVENTORY_VALUES_FORM_KEY,
    form: {
      typeValue: '',
      nameValue: '',
      dosage: '',
    },
    elements: {
      typeValue: {
        type: 'select',
        name: 'typeValue',
        order: 1,
        label: 'Тип ТМЦ',
        placeholder: 'Выберите тип',
        isRequired: false,
        selectOptions: {
          optionList: [
            { label: 'Удобрение', value: 'fertilizer' },
            { label: 'Средство защиты растений', value: 'protection' },
          ],
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      nameValue: {
        type: 'select',
        name: 'nameValue',
        order: 2,
        label: 'Наименование ТМЦ',
        isBlocked: true,
        placeholder: 'Выберите значение',
        isRequired: true,
        selectOptions: {
          search: {},
          searchQuery: {
            handler: fertilizersSearchQueryHandler
              ? fertilizersSearchQueryHandler
              : protectionsSearchQueryHandler,
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      dosage: {
        type: 'input',
        name: 'dosage',
        placeholder: 'Укажите значение',
        order: 3,
        isBlocked: true,
        label: 'Дозировка',
        isRequired: true,
        nativeType: 'number',
        validation: {
          onChange: {
            regExp: {
              expression: new RegExp(/^\d+(,|\.)?(\d{1,2})?$/),
              errorTitle: 'Ошибка валидации',
            },
          },
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
    },
  };

  return inventoryValuesFormConfig;
};

export type { IInventoryValuesForm };

export { createInventoryValuesFormConfig, INVENTORY_VALUES_FORM_KEY };
