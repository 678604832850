import { makeAutoObservable } from 'mobx';

import { IUser } from '../../../../../api/models/da-profile/users';
import { lazyInject, provide } from '../../../utils/helpers/mobx';
import { AxiosService } from '../../services/axios/AxiosService';
import { AccessStore } from '../AccessStore';

export const NEED_ADDITIONAL_INFO = 'NEED_ADDITIONAL_INFO';

@provide.singleton()
export class ProfileStore {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  @lazyInject(AccessStore)
  protected accessStore: AccessStore;

  constructor() {
    makeAutoObservable(this);
  }

  private _isLoading = true;

  private _user: IUser | null;

  set isLoading(flag: boolean) {
    this._isLoading = flag;
  }

  get isLoading() {
    return this._isLoading;
  }

  set user(user: IUser | null) {
    this._user = user;
  }

  get user() {
    return this._user;
  }

  fetchProfile = async () => {
    try {
      this.isLoading = true;
      this.user = await this.axiosService.api.getProfile({});

      const { setIsAllowedToWatchExperiments } = this.accessStore;

      setIsAllowedToWatchExperiments(this.user.experiment);
    } catch (error) {
      if (this.axiosService.needAdditionalInfo && localStorage.getItem('accessToken')) {
        throw new Error(NEED_ADDITIONAL_INFO);
      }
    } finally {
      this.isLoading = false;
    }
  };
}
