import {
  ICreateExperimentStepDto,
  IExperimentStep,
} from '../../../../models/as-fields/experiments';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TUpdateExperimentStepReq = {} & { experimentStepId: string } & ICreateExperimentStepDto;

type TUpdateExperimentStepRes = {} & IExperimentStep;

const updateExperimentStep: TApiRoute & {
  request: TUpdateExperimentStepReq;
  response: TUpdateExperimentStepRes;
} = {
  url: ({ experimentStepId }) => `/api/as-fields/experimentSteps/${experimentStepId}`,
  method: 'PUT',
  request: {} as TUpdateExperimentStepReq,
  response: {} as TUpdateExperimentStepRes,
  headers: {},
};

export type { TUpdateExperimentStepRes, TUpdateExperimentStepReq };

export { updateExperimentStep };
