import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';
import { IExperimentStep } from '../../../../../../api/models/as-fields/experiments';

interface IConfig {
  isAddInitialModel?: boolean;
}

export const createExpStepSelectOption = (
  step: IExperimentStep,
  config?: IConfig
): ISelectOption => {
  const option: ISelectOption<IExperimentStep> = { label: step.name, value: step.id };

  if (config?.isAddInitialModel) {
    option.initialModel = step;
  }

  return option;
};

export const createExpStepSelectOptionList = (
  stepList: IExperimentStep[],
  config?: IConfig
): ISelectOption<IExperimentStep>[] => {
  return stepList.map(step => createExpStepSelectOption(step, config));
};
