import styled, { css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

export const SliderWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  position: relative;
`;

export const SliderContainer = styled.div<{ $paddingTop?: number }>`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 12px;
  margin-bottom: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  padding-top: ${({ $paddingTop }) => `${$paddingTop}px`};
`;

export const ArrowButton = styled.div`
  background: ${Colors.secondaryGray};
  border-radius: 12px;
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg > path {
    fill: ${Colors.secondaryDarkGray};
    transition: fill 0.3s ease-in-out;
  }

  :hover {
    & svg > path {
      fill: ${Colors.black};
    }
  }
`;

export const ArrowButtonBgGradient = styled.div<{
  $position: 'left' | 'right';
  $paddingTop?: number;
  $gradientColor: string;
}>`
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 62px;
  padding-top: ${({ $paddingTop }) => `${$paddingTop}px`};
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;

  ${({ $position, $gradientColor }) =>
    $position === 'left'
      ? css`
          justify-content: flex-start;
          background: linear-gradient(90deg, ${$gradientColor} 65.63%, rgba(255, 255, 255, 0) 100%);
          left: 0;
        `
      : css`
          justify-content: flex-end;
          background: linear-gradient(
            270deg,
            ${$gradientColor} 65.63%,
            rgba(255, 255, 255, 0) 100%
          );
          right: 0;
        `};
`;
