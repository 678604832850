import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { IExperimentCultureZone } from '../../../../models/as-fields/experiments';

type TDeleteCultureZoneExperimentReq = {
  id: string;
};

type TDeleteCultureZoneExperimentRes = IExperimentCultureZone;

const deleteCultureZoneExperiment: TApiRoute & {
  request: TDeleteCultureZoneExperimentReq;
  response: TDeleteCultureZoneExperimentRes;
} = {
  url: ({ id }) => `/api/as-fields/cultureZoneExperiment/${id}`,
  method: 'DELETE',
  request: {} as TDeleteCultureZoneExperimentReq,
  response: {} as TDeleteCultureZoneExperimentRes,
  headers: {},
};

export type { TDeleteCultureZoneExperimentReq, TDeleteCultureZoneExperimentRes };

export { deleteCultureZoneExperiment };
