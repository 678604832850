import { Stage } from '../../../../../../../../../../common/features/DynamicTable/models';
import AttributeHelpers from '../TaskReportAttributeHelpers/TaskReportAttribute.helpers';

function createSystem(): Stage {
  const id = 'system-stage';

  const { createDateAttr, createViewChecklist } = AttributeHelpers;

  return {
    id,
    name: 'ОТЧЕТЫ ПО УЧАСТКАМ',
    attributes: [createDateAttr(id), createViewChecklist(id)],
  };
}

const TaskReportStageHelpers = {
  createSystem,
};

export default TaskReportStageHelpers;
