import styled from 'styled-components';

const Wrapper = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;

  padding: 0 16px 16px 0;

  color: #7e8b9d;
`;

const StyledComparisonTableBuilderColumn = {
  Wrapper,
};

export default StyledComparisonTableBuilderColumn;
