import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';
import { FC, ReactNode } from 'react';

import { IExperiment } from '../../../../../../../../../../api/models/as-fields/experiments';
import { StatusCellComponent } from '../../../../../../containers/Experiments/components';

import Styled from './BackButtonContent.style';

interface IBackButtonContentProps {
  selectedExp: IExperiment;
  /**
   * Иконка контекстной справки.
   */
  ContextualHelpIcon?: ReactNode;
}

const BackButtonContent: FC<IBackButtonContentProps> = ({ selectedExp, ContextualHelpIcon }) => {
  const { ref: experimentNameRef, isShowTooltip: isShowExperimentNameTooltip } =
    useShowTooltip<HTMLDivElement>();

  const { ref: additionalInfoRef, isShowTooltip: isShowAdditionalInfoTooltip } =
    useShowTooltip<HTMLDivElement>();

  const experimentInfo = `${selectedExp?.organization?.name} – ${selectedExp?.culture?.name} – ${
    selectedExp?.seasonYear - 1
  }-${selectedExp?.seasonYear}`;

  return (
    <Styled.Wrapper>
      <Styled.MainInfoWrapper>
        <AutoTooltip
          content={selectedExp?.name}
          position="bottom"
          disabled={!isShowExperimentNameTooltip}
        >
          <Styled.ExperimentName variant="h4" ref={experimentNameRef}>
            {selectedExp?.name}
          </Styled.ExperimentName>
        </AutoTooltip>

        {ContextualHelpIcon ? (
          <Styled.ContextualIconWrapper>{ContextualHelpIcon}</Styled.ContextualIconWrapper>
        ) : null}

        <Styled.StatusComponentWrapper>
          <StatusCellComponent status={selectedExp?.status} />
        </Styled.StatusComponentWrapper>
      </Styled.MainInfoWrapper>
      <AutoTooltip
        content={experimentInfo}
        position="bottom"
        disabled={!isShowAdditionalInfoTooltip}
      >
        <Styled.AdditionalInfo variant="body" ref={additionalInfoRef}>
          {experimentInfo}
        </Styled.AdditionalInfo>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

BackButtonContent.displayName = 'BackButtonContent';

export default BackButtonContent;
