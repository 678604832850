import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IUser } from '../../../models/da-profile/users';

type TypeRequest = {};

type TypeResponse = IUser;

export const getProfile: TApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/users/myProfile/`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
