import { FC, memo } from 'react';

import { StatusPill as StyledStatusPill } from '../StatusPill/StatusPill.styles';
import { EMessageStyleType } from '../../../../../common/features/ChatJournal/models/common';

const StatusPillCanceled: FC<{ children; isSelected?: boolean }> = ({ children, isSelected }) => {
  return (
    <StyledStatusPill $colorScheme={EMessageStyleType.Error} $isSelected={isSelected}>
      Отменен {children}
    </StyledStatusPill>
  );
};

export default memo(StatusPillCanceled);
