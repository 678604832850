import L from 'leaflet';

import { useMapConstants } from '../../constants';
import {
  EExpZoneStyle,
  IFWExperimentCultureZone,
} from '../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';

const { FIELD_TOOLTIP_ON_THE_MAP, EXPERIMENT_STYLE, CONTROL_STYLE, READY_TO_EXPERIMENT, OTHER } =
  useMapConstants;

interface IPolygonTooltip {
  content: L.Content | ((layer: L.Layer) => L.Content) | L.Tooltip;
  options?: L.TooltipOptions;
}

const createCultureZoneTooltip = (name: string): IPolygonTooltip => {
  return {
    content: name,
    options: { permanent: true, direction: 'center', className: FIELD_TOOLTIP_ON_THE_MAP },
  };
};

const createCultureZoneStyle = (
  styleType: IFWExperimentCultureZone['styleType']
): L.PathOptions => {
  switch (styleType) {
    case EExpZoneStyle.Control:
      return CONTROL_STYLE;
    case EExpZoneStyle.Experiment:
      return EXPERIMENT_STYLE;
    case EExpZoneStyle.ReadyToExperiment:
      return READY_TO_EXPERIMENT;
    case EExpZoneStyle.Other:
      return OTHER;
    default:
      return {};
  }
};

const useMapHelpers = { createCultureZoneTooltip, createCultureZoneStyle };

export default useMapHelpers;
