import { AutoTooltip, Icon } from '@farmlink/farmik-ui';
import { IconNames } from '@farmlink/farmik-ui/dist/Icon';
import { FC, InputHTMLAttributes, useMemo } from 'react';

import Styled from './RadioButton.styles';

export const RadioButton: FC<
  InputHTMLAttributes<HTMLInputElement> & {
    children: string;
    color?: 'blue' | 'red';
    fullWidth?: boolean;
    icon?: IconNames;
    iconName?: string;
    isIgnoreDisabledStyles?: boolean;
  }
> = ({
  children,
  disabled,
  checked,
  color,
  fullWidth,
  icon,
  iconName,
  isIgnoreDisabledStyles,
  ...props
}) => {
  const isDisabledStyle = useMemo(
    () => (isIgnoreDisabledStyles ? false : disabled),
    [isIgnoreDisabledStyles, disabled]
  );

  return (
    <Styled.Label
      $checked={checked}
      $color={color}
      $isShowDefaultCursor={disabled}
      $isDisableHoverEffect={disabled}
      $disabled={isDisabledStyle}
      $fullWidth={fullWidth}
      data-test-id={'radio-button-label'}
    >
      {icon ? (
        <AutoTooltip content={iconName} position="bottom">
          <Styled.Icon data-test-id={'radio-button-icon-wrapper'}>
            <Icon icon={icon} size={16} data-test-id={'radio-button-icon'} />
          </Styled.Icon>
        </AutoTooltip>
      ) : (
        <Styled.CheckMark
          $checked={checked}
          $color={color}
          $disabled={isDisabledStyle}
          data-test-id={'radio-button-checkmark'}
        />
      )}
      <Styled.Text
        $checked={checked}
        $disabled={isDisabledStyle}
        data-test-id={'radio-button-text'}
      >
        {children}
      </Styled.Text>
      <Styled.Input
        type="radio"
        {...props}
        disabled={disabled}
        checked={checked}
        data-test-id={'radio-button-input'}
        data-is-checked={checked}
      />
    </Styled.Label>
  );
};
