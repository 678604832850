import { useStore } from '../../../../utils/helpers/mobx';
import { DynamicTableController } from '../../mobx/controllers';
import { DynamicTableStore } from '../../mobx/stores';
import { _Instance } from '../../models';

type GetInstance = <CustomProp = any>(tableId: string, id: string) => _Instance<CustomProp>;
type ChangeInstanceCustomProp = <CustomProp = any>(
  tableId: string,
  id: string,
  customProp: _Instance<CustomProp>['customProp']
) => void;

const useInstance = (): {
  getConfig: DynamicTableStore['getConfig'];
  getInstance: GetInstance;
  addInstance: DynamicTableController['addInstance'];
  addInstanceList: DynamicTableController['addInstanceList'];
  replaceInstance: DynamicTableController['replaceInstance'];
  changeInstanceCustomProp: ChangeInstanceCustomProp;
} => {
  const { getInstance, getConfig, changeInstanceCustomProp } = useStore(DynamicTableStore);
  const { addInstance, addInstanceList, replaceInstance } = useStore(DynamicTableController);

  return {
    getConfig,
    getInstance,
    addInstance,
    addInstanceList,
    replaceInstance,
    changeInstanceCustomProp,
  };
};

export default useInstance;
