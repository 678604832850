import { IExperimentStep } from '../../../../../api/models/as-fields/experiments';
import {
  INutritionHistory,
  INutritionHistoryItem,
} from '../../../../../api/models/as-fields/plan/NutrationHistory';
import { INutritionHistoryProtectionItem } from '../../../../../api/models/as-fields/plan/NutrationHistory/NutritionHistory.model';
import { IActiveSubstancesData } from '../model/IActiveSubstancesData.model';

const createActiveSubstancesTableBuilderData = (
  experimentSteps: IExperimentStep[],
  nutritionHistories: INutritionHistory[]
): IActiveSubstancesData[] => {
  const experimentStepWithInventoryValues = experimentSteps.map(
    (
      experimentStep
    ): IExperimentStep & {
      itemsList: INutritionHistoryItem[];
      protectionItemsList: INutritionHistoryProtectionItem[];
    } => ({
      ...experimentStep,
      itemsList: nutritionHistories.reduce((acc, nutritionHistory) => {
        if (nutritionHistory.experimentStep.id === experimentStep.id) {
          acc.push(...nutritionHistory.items);
        }

        return acc;
      }, []),
      protectionItemsList: nutritionHistories.reduce((acc, nutritionHistory) => {
        if (nutritionHistory.experimentStep.id === experimentStep.id) {
          acc.push(...nutritionHistory.protectionItems);
        }

        return acc;
      }, []),
    })
  );

  const sortedExperimentStep = experimentStepWithInventoryValues.sort(
    (prev, next) => prev.order - next.order
  );

  const rows = sortedExperimentStep.map((experimentStep, index) => ({
    id: experimentStep.id,
    number: index + 1,
    name: experimentStep.name,
    inventoryValue: '—',
    dosage: '—',
    itemsList: experimentStep.itemsList,
    protectionItemsList: experimentStep.protectionItemsList,
    actions: null,
    activeSubstances: null,
  }));

  return rows;
};

export { createActiveSubstancesTableBuilderData };
