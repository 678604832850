import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
class AuditInfoStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _auditInfo = null;
  private _auditName = '';
  private _auditStartDate = '';

  get auditInfo() {
    return this._auditInfo;
  }

  setAuditInfo = (auditInfo): void => {
    this._auditInfo = auditInfo;
  };

  setAuditName = (name): void => {
    this._auditName = name;
  };

  setAuditStartDate = (startDate): void => {
    this._auditStartDate = startDate;
  };

  clearAuditStartDate = (): void => {
    this._auditStartDate = '';
  };

  clearAuditInfo = (): void => {
    this._auditInfo = null;
  };

  clearAuditName = (): void => {
    this._auditInfo = null;
  };

  clearStore = () => {
    this.clearAuditStartDate();
    this.clearAuditInfo();
    this.clearAuditName();
  };
}

export default AuditInfoStore;
