import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { IExperimentContract } from '../../../../models/as-fields/experiments';

type TGetExperimentContractReq = {
  id: string;
};

type TGetExperimentContractRes = IExperimentContract;

const getExperimentContract: TApiRoute & {
  request: TGetExperimentContractReq;
  response: TGetExperimentContractRes;
} = {
  url: ({ id }) => `/api/as-fields/experiment/contract/${id}`,
  method: 'GET',
  request: {} as TGetExperimentContractReq,
  response: {} as TGetExperimentContractRes,
  headers: {},
};

export type { TGetExperimentContractReq, TGetExperimentContractRes };

export { getExperimentContract };
