import React, { FC, memo } from 'react';
import { useDataTestId } from '@farmlink/farmik-ui';

import Styled from './TableFiltersBuilderFilterIcon.styles';

interface IProps {
  isOpened?: boolean;
  isActive?: boolean;
  dataTestId?: string;
}

const TableFiltersBuilderFilterIcon: FC<IProps> = ({ isOpened, isActive, dataTestId }) => {
  const getDataTestId = useDataTestId(dataTestId || 'filter-icon');

  return (
    <Styled.SVG
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $isOpened={isOpened}
      {...getDataTestId()}
    >
      <rect
        id={'table-filters-builder-filter-icon-body'}
        width="40"
        height="40"
        rx="12"
        fill="#FAF9FD"
      />

      <path
        id={'table-filters-builder-filter-icon-figure'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9987 12.0736C29.0096 11.9015 28.9513 11.7323 28.8377 11.6063C28.7241 11.4803 28.5653 11.4086 28.399 11.4083H11.601C11.4346 11.4084 11.2757 11.48 11.1621 11.6061C11.0484 11.7321 10.9902 11.9014 11.0013 12.0736C11.2911 16.2589 14.2311 19.7326 18.1945 20.5727V28.7848C18.1946 29.0268 18.3296 29.2468 18.5408 29.3495C18.752 29.4521 19.0014 29.4187 19.1807 29.2639L21.5859 27.1848C21.7231 27.0665 21.8025 26.8909 21.8023 26.7058V20.5727C25.7672 19.7342 28.7088 16.26 28.9987 12.0736Z"
        stroke="#151F32"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {isActive ? (
        <rect
          id={'table-filters-builder-filter-icon-circle'}
          x="30"
          y="2"
          width="8"
          height="8"
          rx="4"
          fill="#3FB65F"
          stroke="#3FB65F"
          strokeWidth="2"
        />
      ) : null}
    </Styled.SVG>
  );
};

TableFiltersBuilderFilterIcon.displayName = 'TableFiltersBuilderFilterIcon';

export default memo(TableFiltersBuilderFilterIcon);
