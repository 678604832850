enum ECommonColor {
  White = '#FFFFFF',
  LightGray = '#FAF9FD',
  AccentGreen = '#3FB65F',
  SecondaryDarkGray = '#7E8B9D',
  AddSky = '#0770E3',
  BorderGray = '#E9E6F0',
  BorderHover = '#bac2d3',
  Pink = '#F29993',
  GeneralDark = '#151F32',
  GeneralBlack = '#151F32',
  SecondaryGray = '#FAF9FD',
  SecondaryBgGray = '#F2F1F4',
  GeneralWhite = '#FFFFFF',
  HoverDarkGray = '#3C414A',
  LightSky = '#E7F1FD',
  LightGreen = '#ECF8EF',
  LightPink = '#FEF5F5',
  Orange = '#FF7527',
  LightOrange = '#FFF5E4',
  SecondaryLightOrange = '#FFEDB8',
  AccentOrange = '#EF6517',
  AccentPink = '#DF2E22',
  SkyHover = '#0359B7',
  AccentAqua = '#129AA3',
  LightAqua = '#EEFFFE',
  AddOrange = '#EF6517',
}

export default ECommonColor;
