import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { DeviceScreenDummy } from '../../../common/components/ui';
import { AuthorizationContainer } from '../../../common/containers/authorization';
import { useStore } from '../../../common/utils/helpers/mobx';
import { systemHelperCollection } from '../../../common/utils/helpers/system';
import { EDashboardRoute } from '../../routes/DashboardRoute';
import BaseStyles from '../../../common/styles/base';
import { AccessController } from '../../../common/mobx/controllers/AccessController';
import { SessionStore } from '../../../common/mobx/stores';

const DashboardRouter: FC = observer(() => {
  const accessController = useStore(AccessController);
  const sessionStore = useStore(SessionStore);

  const { getAllActiveGlobalActionsForCurrentUser } = accessController;
  const { isLoggedIn } = sessionStore;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(EDashboardRoute.Experiments);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getAllActiveGlobalActionsForCurrentUser();
    }
  }, [isLoggedIn]);

  const { getAuthUrl } = systemHelperCollection;

  return (
    <>
      <BaseStyles data-test-id="dashboard-base-styles" />
      <AuthorizationContainer
        isZoneForAuthorized={true}
        redirectTo={getAuthUrl()}
        data-test-id="dashboard-authorization"
      >
        <Outlet />
        <DeviceScreenDummy />
      </AuthorizationContainer>
    </>
  );
});

export default DashboardRouter;
