import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { IExperimentChangeStatusAvailability } from '../../../../../../../../../../api/models/as-fields/experiments/Experiment';
import { Approval } from '../Approval';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { AuditStore } from '../../../../../../../observations/modules/Audit/mobx/store';
import AuditController from '../../../../../../../observations/modules/Audit/mobx/controller/Audit.controller';
import { parseEventRecordToMessage } from '../../utils/parseEventRecordToMessage';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';

type TRequestMessageListApiName = 'getExperimentStepEventList';
type TSendMessageApiName = 'postExperimentStepComment';

const AuditApproval: FC = () => {
  const { selectedAudit, setSelectedAudit } = useStore(AuditStore);
  const { fetchAuditEvents, postAuditApprovalComment, changeExperimentStepApprovalStatus } =
    useStore(AuditController);

  const contextHelpIconConfig = {
    parentPath: EContextualParentPath.AuditsAuditApproval,
    path: ContextualPaths.AuditsAuditApprovalTableTitle,
  };

  const availabilityScheme = useMemo<IExperimentChangeStatusAvailability>(() => {
    if (selectedAudit) {
      return {
        canApprovedToInApproval: selectedAudit.canApprovedToInApproval,
        canDraftToInApproval: selectedAudit.canDraftToInApproval,
        canInApprovalToApproved: selectedAudit.canInApprovalToApproved,
        canInApprovalToDraft: selectedAudit.canInApprovalToDraft,
      };
    }
  }, [selectedAudit]);

  return (
    <Approval<TRequestMessageListApiName, TSendMessageApiName>
      entityApproveId={selectedAudit?.id}
      entityApproveStatus={selectedAudit?.status}
      entityAvailabilityScheme={availabilityScheme}
      contextHelpIconConfig={contextHelpIconConfig}
      fetchMessageListHandler={fetchAuditEvents}
      sendMessageHandler={postAuditApprovalComment}
      changeStatusHandler={changeExperimentStepApprovalStatus}
      parseEntityToMessageHandler={parseEventRecordToMessage}
      setOnChangeEntityData={setSelectedAudit}
      statusScheme="observer"
    />
  );
};

AuditApproval.displayName = 'AuditApproval';

export default observer(AuditApproval);
