import styled from 'styled-components';

const ArrowWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;

  padding-bottom: 16px;
  border-bottom: 1px solid #151f32;

  cursor: pointer;
`;

const HeaderWrapper = styled.div``;

const Content = styled.div`
  margin-top: 16px;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const StyledComparisonCollapsingRowsGroup = {
  ArrowWrapper,
  Title,
  Header,
  HeaderWrapper,
  Content,
  Wrapper,
};

export default StyledComparisonCollapsingRowsGroup;
