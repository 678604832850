import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import {
  ITableFiltersBuilderConfig,
  ITableFiltersBuilderHeaderConfig as IHeaderConfig,
} from '../../../../../../../../common/features/TableFiltersBuilder/models/configs';
import { TFiltersFilterPaginationQueryItem as TPageQueryItem } from '../../../../../../../../common/features/TableFiltersBuilder/types/pagination/FiltersFilterPaginationConfig';
import { IContractsFilters } from '../../../models';
import { EContractsFiltersBuilderId } from '../../../../../constants/configs';
import {
  TContractsFiltersFilterConfig as TFilterConfig,
  TContractsFiltersPageQueryConfig as TPageQueryConfig,
} from '../../../types';

@provide.transient()
class ContractsFiltersConfigsService {
  createConfig = (
    onActionButtonClick: () => void,
    paginationQueryConfig: TPageQueryConfig
  ): ITableFiltersBuilderConfig<IContractsFilters> => {
    const headerConfig = this.createHeaderConfig(onActionButtonClick);
    const filterList = this.createFilterList(paginationQueryConfig);

    return {
      id: EContractsFiltersBuilderId.Contracts,
      headerConfig,
      filterConfigList: filterList,
    };
  };

  protected createHeaderConfig = (onActionButtonClick: () => void): IHeaderConfig => {
    return {
      title: 'Договоры',
      iconsConfig: {
        isShowToggleFilters: true,
      },
      actionButtonConfig: {
        autoRenderConfig: {
          title: 'Создать договор',
          onClick: onActionButtonClick,
        },
      },
    };
  };

  protected createFilterList = (paginationQueryConfig: TPageQueryConfig): TFilterConfig[] => {
    return [
      this.createOrganizationFilter({
        paginationConfig: paginationQueryConfig.organizationIdIn,
      }),
    ];
  };

  protected createOrganizationFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'organizationIdIn',
      name: 'Контрагент',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  private getOptionPaginationConfig = (paginationConfig: TPageQueryItem) => ({
    updateDataHandler: data => paginationConfig?.updateHandler?.(data),
    requestQuery: (page, query) => paginationConfig.requestHandler(page, query),
  });
}

export default ContractsFiltersConfigsService;
