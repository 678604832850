import { observer } from 'mobx-react';
import { FC, useEffect, useMemo } from 'react';
import { ButtonLink } from '@farmlink/farmik-ui';

import { EExperimentStatus } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { TableBuilder } from '../../../../../../../../../../../common/features/TableBuilder';
import { TableBuilderController } from '../../../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentsTableBuilderId } from '../../../../../../../../constants/configs';
import { CalculationController } from '../../mobx/controllers/Calculation/Calculation.controller';
import { AgrochemicalPlug } from '../AgrochemicalPlug';

import Styled from './AgrochemicalTable.styles';

interface AxoTableProps {
  onClickAdd: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onClickCopy: () => void;
  rows: IAgrochemicalTable[];
  isViewOnly?: boolean;
  expStatus?: EExperimentStatus;
}

export interface IAgrochemicalTable {
  id: string;
  date: string;
  type: string;
  mechanicalComposition: string;
  humus: string;
  ph: string;
  po: string;
  ko: string;
  actions: string | null;
}

const AgrochemicalTable: FC<AxoTableProps> = ({
  onClickAdd,
  onClickEdit,
  onClickDelete,
  onClickCopy,
  isViewOnly,
  rows,
  expStatus,
}) => {
  const tableBuilderController = useStore(TableBuilderController);
  const { initiateAgrochemicalTable } = useStore(CalculationController);

  useEffect(() => {
    tableBuilderController.showLoader(EExperimentsTableBuilderId.CalculationAgrochemical);

    tableBuilderController.addRowsToRowsGroupListById(
      EExperimentsTableBuilderId.CalculationAgrochemical,
      EExperimentsTableBuilderId.CalculationAgrochemical,
      rows,
      {
        isClearPreviousList: true,
      }
    );

    tableBuilderController.hideLoader(EExperimentsTableBuilderId.CalculationAgrochemical);
  }, [rows]);

  useEffect(() => {
    initiateAgrochemicalTable(onClickEdit, onClickDelete, isViewOnly);
  }, [rows, isViewOnly]);

  const noSoilParameters = useMemo(() => {
    return rows.length === 0 && expStatus === EExperimentStatus.Draft;
  }, [rows.length, expStatus]);

  return (
    <Styled.TableContainer>
      <Styled.ControlContainer>
        <Styled.TableTitle>Параметры почвы</Styled.TableTitle>
        {!isViewOnly && (
          <ButtonLink color={'accent'} onClick={onClickCopy} dataTestId={`history-copy-button`}>
            <Styled.CopyIcon data-test-id={`history-copy-icon`} />
            Скопировать данные с другого участка
          </ButtonLink>
        )}
      </Styled.ControlContainer>
      <TableBuilder
        builderId={EExperimentsTableBuilderId.CalculationAgrochemical}
        stylePreset={'cleared'}
        borderType={'dashed'}
        scrollPadding={'0px'}
        isHideScroll
        renderDefaultPlug={() => (
          <AgrochemicalPlug
            onClickAdd={onClickAdd}
            isViewMode={isViewOnly}
            noSoilParameters={noSoilParameters}
          />
        )}
        renderNoDataPlug={() => (
          <AgrochemicalPlug
            onClickAdd={onClickAdd}
            isViewMode={isViewOnly}
            noSoilParameters={noSoilParameters}
          />
        )}
      />
    </Styled.TableContainer>
  );
};

export default observer(AgrochemicalTable);
