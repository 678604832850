import { IOperationType } from '../../../../models/as-fields/operation';
import { TResponseList } from '../../../../models/common/response';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetTechOperationTypesReq = {
  name?: string;
};

type TGetTechOperationTypesRes = {} & TResponseList<IOperationType>;

const getTechOperationTypes: TApiRoute & {
  request: TGetTechOperationTypesReq;
  response: TGetTechOperationTypesRes;
} = {
  url: () => `/api/as-fields/operation/type/list`,
  method: 'GET',
  request: {} as TGetTechOperationTypesReq,
  response: {} as TGetTechOperationTypesRes,
  headers: {},
};

export type { TGetTechOperationTypesRes, TGetTechOperationTypesReq };

export { getTechOperationTypes };
