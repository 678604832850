import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { IExperimentCultureZone } from '../../../../models/as-fields/experiments';

type TGetCultureZoneExperimentListReq = {
  experimentId: string;
};

type TGetCultureZoneExperimentListRes = IExperimentCultureZone[];

const getCultureZoneExperimentList: TApiRoute & {
  request: TGetCultureZoneExperimentListReq;
  response: TGetCultureZoneExperimentListRes;
} = {
  url: ({ experimentId }) => `/api/as-fields/experiment/${experimentId}/cultureZones`,
  method: 'GET',
  request: {} as TGetCultureZoneExperimentListReq,
  response: {} as TGetCultureZoneExperimentListRes,
  headers: {},
};

export type { TGetCultureZoneExperimentListReq, TGetCultureZoneExperimentListRes };

export { getCultureZoneExperimentList };
