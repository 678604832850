interface IExperimentComment<T extends EExperimentCommentTypeEvent | EExperimentStepEventType> {
  createdBy: {
    id: string;
    fullName: string;
  };
  typeEvent: T;
  comment: string;
  creationDate: string;
  fileName: string;
}

enum EExperimentStepApprovalStatus {
  approvedToInApproval = 'approvedToInApproval',
  draftToInApproval = 'draftToInApproval',
  inApprovalToApproved = 'inApprovalToApproved',
  inApprovalToDraft = 'inApprovalToDraft',
}

enum EExperimentStepEventType {
  DRAFT_TO_IN_APPROVAL = 'DRAFT_TO_IN_APPROVAL',
  APPROVED_TO_IN_APPROVAL = 'APPROVED_TO_IN_APPROVAL',
  IN_APPROVAL_TO_APPROVED = 'IN_APPROVAL_TO_APPROVED',
  IN_APPROVAL_TO_DRAFT = 'IN_APPROVAL_TO_DRAFT',
  COMMENT = 'COMMENT',
  ADD_FILE = 'ADD_FILE',
  DELETE_FILE = 'DELETE_FILE',
}

enum EExperimentCommentTypeEvent {
  APPROVED_TO_IN_PROGRESS = 'APPROVED_TO_IN_PROGRESS',
  DRAFT_TO_IN_APPROVAL = 'DRAFT_TO_IN_APPROVAL',
  DRAFT_TO_IN_PROGRESS = 'DRAFT_TO_IN_PROGRESS',
  IN_APPROVAL_TO_APPROVED = 'IN_APPROVAL_TO_APPROVED',
  IN_APPROVAL_TO_DRAFT = 'IN_APPROVAL_TO_DRAFT',
  APPROVED_TO_IN_APPROVAL = 'APPROVED_TO_IN_APPROVAL',
  COMPLETED_TO_IN_PROGRESS = 'COMPLETED_TO_IN_PROGRESS',
  IN_PROGRESS_TO_DRAFT = 'IN_PROGRESS_TO_DRAFT',
  APPROVED_TO_CANCELED = 'APPROVED_TO_CANCELED',
  IN_PROGRESS_TO_CANCELED = 'IN_PROGRESS_TO_CANCELED',
  IN_PROGRESS_TO_COMPLETED = 'IN_PROGRESS_TO_COMPLETED',
  COMPLETED_TO_FACT_APPROVED = 'COMPLETED_TO_FACT_APPROVED',
  FACT_APPROVED_TO_COMPLETED = 'FACT_APPROVED_TO_COMPLETED',
  FACT_APPROVED_TO_FULL_COMPLETED = 'FACT_APPROVED_TO_FULL_COMPLETED',
  FULL_COMPLETED_TO_FACT_APPROVED = 'FULL_COMPLETED_TO_FACT_APPROVED',
  CANCELED_TO_APPROVED = 'CANCELED_TO_APPROVED',
  CANCELED_TO_IN_PROGRESS = 'CANCELED_TO_IN_PROGRESS',
  COMMENT = 'COMMENT',
  ADD_FILE = 'ADD_FILE',
  DELETE_FILE = 'DELETE_FILE',
}

export type { IExperimentComment };
export { EExperimentCommentTypeEvent, EExperimentStepEventType, EExperimentStepApprovalStatus };
