import { FC, memo } from 'react';

import Styled from './DateCellComponent.styles';

interface IDateCellComponent {
  startDate: string;
  endDate: string;
  rowId: string;
}

const DateCellComponent: FC<IDateCellComponent> = ({ startDate, endDate, rowId }) => {
  const isBothDateExistAndNotEqual = startDate && endDate && startDate !== endDate;
  const isOneDateExist = startDate || endDate;

  return (
    <Styled.Wrapper data-test-id={`date-cell-wrapper-${rowId}`}>
      {isBothDateExistAndNotEqual ? (
        <Styled.Item data-test-id={`both-date-cell-item-${rowId}`}>
          {startDate}–{endDate}
        </Styled.Item>
      ) : isOneDateExist ? (
        <Styled.Item data-test-id={`one-date-cell-item-${rowId}`}>{isOneDateExist}</Styled.Item>
      ) : (
        <Styled.Item data-test-id={`no-date-cell-item-${rowId}`}>—</Styled.Item>
      )}
    </Styled.Wrapper>
  );
};

DateCellComponent.displayName = 'DateCellComponent';

export default memo(DateCellComponent);
