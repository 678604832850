import { FC, memo, useMemo } from 'react';

import { IMessageProps } from '../../models';

import Styled from './Notification.styles';

const Notification: FC<IMessageProps> = ({ author, date, message, style }) => {
  const content = useMemo(() => `${message}: ${author.trim()}, ${date}`, [message, author, date]);

  return (
    <Styled.NotificationContainer data-test-id="chat-notification" $colorScheme={style}>
      {content}
    </Styled.NotificationContainer>
  );
};

export default memo(Notification);
