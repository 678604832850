import styled from 'styled-components';

const ContentHeader = styled.div`
  background: ${({ theme }) => theme?.Colors?.generalLight ?? '#ffffff'};
  height: 60px;
  display: flex;
  justify-content: space-between;
`;

const ContentHeaderChildrenWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
`;

const TitleWrapper = styled.div`
  margin: auto 0 auto 24px;
`;

const StyledContentHeader = {
  ContentHeader,
  ContentHeaderChildrenWrapper,
  TitleWrapper,
};

export default StyledContentHeader;
