import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import { TGetOperationListReq, TGetOperationReq } from '../../../../../../api';
import { IOperation } from '../../../../../../api/models/as-fields/operation/Operation/Operation.model';

@provide.singleton()
class OperationService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getOperationList = async (payload: TGetOperationListReq): Promise<IOperation[]> => {
    try {
      const { content } = await this.axiosService.api.getOperationList(payload);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getOperation = async (payload: TGetOperationReq): Promise<IOperation> => {
    try {
      const fetchedOperation = await this.axiosService.api.getOperation(payload, { omit: ['id'] });

      return fetchedOperation;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default OperationService;
