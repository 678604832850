enum EAuditRoute {
  Info = 'info',
  Tasks = 'tasks',
  Report = 'report/audit',
  Photos = 'photos',
  Approval = 'approval',
  Documents = 'documents',
  CreateAudit = 'audit',
  EditAudit = 'info/edit',
  TaskReport = 'report/task/:taskId',
}

export default EAuditRoute;
