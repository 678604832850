import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { TChangeExperimentContractReq } from '../../../../../../../../../../../../api';
import { IExperimentContract } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { AxiosService } from '../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { GlobalService } from '../../../../../../../../../../../common/mobx/services/system';

@provide.transient()
class DocumentsTableService {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  @lazyInject(GlobalService)
  globalService: GlobalService;

  getExperimentContract = async (contractId: string): Promise<IExperimentContract> => {
    const { getExperimentContract } = this.axiosService.api;

    try {
      const experimentContract = await getExperimentContract({
        id: contractId,
      });

      return experimentContract;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  changeExperimentContract = async (
    payload: TChangeExperimentContractReq
  ): Promise<IExperimentContract> => {
    const { changeExperimentContract } = this.axiosService.api;
    const { showErrorModalHandler } = this.globalService;

    try {
      const response = await changeExperimentContract(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      showErrorModalHandler(e);
    }
  };

  downloadFile = async (objectId: string) => {
    const { downloadFile } = this.axiosService.api;

    const response = await downloadFile({ objectId }, { responseType: 'blob' });

    return response;
  };
}

export default DocumentsTableService;
