import styled from 'styled-components';

const Wrapper = styled.div`
  height: 128px;
`;

const StyledRemarksContentLoader = {
  Wrapper,
};

export default StyledRemarksContentLoader;
