import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IOrganization } from '../../../models/da-profile/organizations/Organization.model';
import { TResponseList } from '../../../models/common/response';

type TGetOrganizationListReq = {
  nameOrInn?: string;
  isScout?: boolean;
  size?: number;
  page?: number;
};

type TGetOrganizationListRes = {} & TResponseList<IOrganization>;

const getOrganizationList: TApiRoute & {
  request: TGetOrganizationListReq;
  response: TGetOrganizationListRes;
} = {
  url: () => `/api/da-profile/organizations/list`,
  method: 'GET',
  request: {} as TGetOrganizationListReq,
  response: {} as TGetOrganizationListRes,
  headers: {},
};

export type { TGetOrganizationListReq, TGetOrganizationListRes };

export { getOrganizationList };
