import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import {
  ExperimentService,
  ExperimentStepsService,
} from '../../../../../../../../../../common/mobx/services/as-fields';
import { RemarksStore } from '../../stores';
import { TUpdateExperimentNotesReq } from '../../../../../../../../../../../api';
import { EExperimentStepType } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';

@provide.transient()
class RemarksController {
  @lazyInject(ExperimentStepsService)
  protected experimentStepsService: ExperimentStepsService;

  @lazyInject(ExperimentService)
  protected experimentService: ExperimentService;

  @lazyInject(RemarksStore)
  protected remarksStore: RemarksStore;

  fetchExperimentSteps = async (experimentId: string) => {
    const experimentSteps = await this.experimentStepsService.getExperimentStepList({
      experimentId,
      type: EExperimentStepType.Audit,
    });
    this.remarksStore.setExperimentSteps(experimentSteps);
  };

  saveExperimentNotes = async (payload: TUpdateExperimentNotesReq) => {
    await this.experimentService.updateExperimentNotes(payload);
  };
}

export default RemarksController;
