import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IAttachedFile } from '../../../models/as-fields/experiments';
import { FILE_ENTITY_URLS, TFileEntities } from '../../../models/as-fields/attachedFiles';

export type TGetAttachedFilesReq = {
  id: string;
  entity: TFileEntities;
};

export type TGetAttachedFilesRes = {
  attachedFiles: IAttachedFile[];
};

const getAttachedFilesByEntity: TApiRoute & {
  request: TGetAttachedFilesReq;
  response: TGetAttachedFilesRes;
} = {
  url: ({ id, entity }) => `/api/as-fields/${FILE_ENTITY_URLS[entity]}/${id}`,
  method: 'GET',
  request: {} as TGetAttachedFilesReq,
  response: {} as TGetAttachedFilesRes,
  headers: {},
};

export { getAttachedFilesByEntity };
