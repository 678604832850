import { FC } from 'react';
import { observer } from 'mobx-react';

import { ITableBuilderCell as ICell, ITableBuilderRow as IRow } from '../../../models/data';

interface IProps {
  cell: ICell;
  row: IRow;
}

const TableBuilderCellCustomRenderContainer: FC<IProps> = ({ cell, row }) => {
  return <>{cell.customRenderConfig.renderCell(row)}</>;
};

TableBuilderCellCustomRenderContainer.displayName = 'TableBuilderCellCustomRenderContainer';

export default observer(TableBuilderCellCustomRenderContainer);
