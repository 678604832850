import styled from 'styled-components';

import MessageColorsScheme, { TMessageColorItem } from '../../configs/colors.config';

const NotificationContainer = styled.div<{ $colorScheme: keyof TMessageColorItem }>`
  max-height: 24px;
  width: fit-content;
  align-self: center;
  border-radius: 8px;
  padding: 4px 8px;

  font-size: 14px;
  letter-spacing: -0.1px;

  background-color: ${({ $colorScheme }) =>
    MessageColorsScheme[$colorScheme].notificationBackgroundColor};
  color: ${({ $colorScheme }) => MessageColorsScheme[$colorScheme].textColor};
`;

const Styled = { NotificationContainer };

export default Styled;
