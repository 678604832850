import { FC } from 'react';
import { observer } from 'mobx-react';

import { IComparisonTableBuilderRow } from '../../../models/data';
import { useStore } from '../../../../../utils/helpers/mobx';
import { ComparisonTableBuilderStore as Store } from '../../../mobx/stores';
import { useSortBy } from '../../../../../utils/hooks/useSortBy';
import { useComparisonTableBuilderContext as useContext } from '../../../context';
import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { ComparisonTableBuilderRowsGroupContainer } from '../../rowsGroups/ComparisonTableBuilderRowsGroupContainer';

import Styled from './ComparisonTableBuilderRowNestedRenderContainer.styles';

interface IProps {
  row: IComparisonTableBuilderRow;
}

const ComparisonTableBuilderRowNestedRenderContainer: FC<IProps> = ({ row }) => {
  const store = useStore(Store);

  const context = useContext();

  const rowsGroupList = useSortBy(
    store.getRowsGroupList(context.builderId, { byRootRowId: row.id })
  );

  const getDataTestId = useDataTestId({ componentName: 'nested-row' });

  return (
    <Styled.Wrapper {...getDataTestId()}>
      {rowsGroupList.map(rowsGroup => (
        <ComparisonTableBuilderRowsGroupContainer key={rowsGroup.id} rowsGroup={rowsGroup} />
      ))}
    </Styled.Wrapper>
  );
};

ComparisonTableBuilderRowNestedRenderContainer.displayName =
  'ComparisonTableBuilderRowNestedRenderContainer';

export default observer(ComparisonTableBuilderRowNestedRenderContainer);
