import { FC, memo, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../utils/helpers/mobx';

import { CollapsingStageContainer, DynamicTableHeadContainer, StageContainer } from './containers';
import { IDynamicTableConfig } from './models';
import Styled from './DynamicTable.styles';
import { DynamicTableController } from './mobx/controllers';
import { SliderContext } from './context';
import { useGetWidthByRef, useSliderControl } from './private';
import { DynamicTableStore } from './mobx/stores';
import { ComparisonTableImagesStageContainer as ImagesStageContainer } from './containers/stages';

interface IDynamicTableProps {
  config?: IDynamicTableConfig;
  stickyTopIndent?: number;
  parentScrollTop?: number;
}

const DynamicTable: FC<IDynamicTableProps> = ({ config, parentScrollTop, stickyTopIndent }) => {
  const { setConfig } = useStore(DynamicTableStore);
  const { createTable, clearTableStore } = useStore(DynamicTableController);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const width = useGetWidthByRef(wrapperRef);

  const sliderControl = useSliderControl();

  const stageListToDraw = useMemo(() => {
    return config.headColumn.stages.filter(stage => {
      return !stage.collapsingStageId;
    });
  }, [config.headColumn.stages]);

  useEffect(() => {
    setConfig(config);
    createTable(config);

    return () => {
      clearTableStore(config.id);
    };
  }, [config]);

  return (
    <SliderContext.Provider value={sliderControl}>
      <Styled.Wrapper
        ref={wrapperRef}
        $columnHeaderNameMargin={config.styles?.columnHeaderNameMargin}
      >
        {!config.system?.isHideHeader ? (
          <DynamicTableHeadContainer
            config={config}
            width={width}
            stickyTopIndent={stickyTopIndent}
            parentScrollTop={parentScrollTop}
          />
        ) : null}

        <Styled.Body>
          {config.headColumn?.collapsingStages?.map?.(({ id }) => (
            <CollapsingStageContainer
              key={id}
              tableId={config.id}
              id={id}
              cellPadding={config.headColumn.padding}
            />
          ))}

          {stageListToDraw.map(stage => {
            if (stage?.imagesStage) {
              return (
                <ImagesStageContainer
                  tableId={config.id}
                  imagesStageId={stage.id}
                  isEnableEditing={config.system?.isEnableEditing}
                  key={stage.id}
                />
              );
            }

            return (
              <Styled.StageWrapper key={stage.id}>
                <StageContainer
                  tableId={config.id}
                  id={stage.id}
                  cellPadding={config.cell?.padding}
                />
              </Styled.StageWrapper>
            );
          })}
        </Styled.Body>
      </Styled.Wrapper>
    </SliderContext.Provider>
  );
};

DynamicTable.displayName = 'DynamicTable';

export default memo(observer(DynamicTable));
