import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { AttributeValueComponentProps } from '../../../../../../../../../common/features/DynamicTable/models/AttributeValue/AttributeValue.model';
import { useInstance } from '../../../../../../../../../common/features/DynamicTable/hooks';
import { AuditReportInstCustomProp } from '../../models';

const AuditReportDateAttrVal: FC<AttributeValueComponentProps> = ({ attributeValue }) => {
  const { tableId, instanceId, value } = attributeValue;

  const { getInstance } = useInstance();

  const instance = getInstance<AuditReportInstCustomProp>(tableId, instanceId);

  const isTaskIdHaveInformation = useMemo(
    () => Boolean(instance?.customProp?.taskId),
    [instance?.customProp?.taskId]
  );

  return <div>{isTaskIdHaveInformation ? value : '–'}</div>;
};

AuditReportDateAttrVal.displayName = 'AuditReportDateAttrVal';

export default observer(AuditReportDateAttrVal);
