import styled from 'styled-components';

import { ECommonColor } from '../../../../common/assets/styles/colors';

const { White } = ECommonColor;

const Overlay = styled.div`
  background: rgba(21, 31, 50, 0.3);
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9002;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 600px;
  z-index: 9003;
  background-color: ${White};
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledSidePanel = {
  Overlay,
  Wrapper,
};

export default StyledSidePanel;
