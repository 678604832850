import _ from 'lodash';

import { IFormConfig, IFormElement, TFormValue } from '../../../../../../models';

const hasNumberValue = (value: number): boolean => {
  const isEmpty: boolean = value === null;

  return !isEmpty;
};

const hasValue = (value: TFormValue): boolean => {
  if (_.isNumber(value)) {
    return hasNumberValue(value);
  }

  return Boolean(value);
};

const validateForm = <F extends Record<keyof F, TFormValue>>(
  form: F,
  elements: IFormConfig<F>['elements']
): Partial<IFormConfig<F>['elements']> | void => {
  const invalidElements: Partial<IFormConfig<F>['elements']> = {};

  const formEntries: [string, TFormValue][] = Object.entries(form);

  formEntries.forEach(([name, value]) => {
    const element: IFormElement<F> = elements[name];

    if (!element) {
      return;
    }

    const isRequired: boolean = element?.isRequired;
    const isInvalidElement: boolean = element?.schema?.isShownError;

    if (isRequired && !hasValue(value)) {
      const invalidElement: IFormElement<F> = {
        ...element,
        schema: { ...element.schema, isShownError: true },
      };

      invalidElements[name] = invalidElement;
    } else if (isInvalidElement) {
      invalidElements[name] = element;
    }
  });

  if (!_.isEmpty(invalidElements)) {
    return invalidElements;
  }
};

const getNameOfTheFirstElement = <F extends Record<keyof F, TFormValue>>(
  invalidElements: Partial<IFormConfig<F>['elements']>
): keyof F => {
  const elementList: IFormElement<F>[] = Object.values(invalidElements);
  const [firstElement] = _.sortBy(elementList, 'order');

  return firstElement.name;
};

const FormControllerValidationHelpers = { validateForm, getNameOfTheFirstElement };

export default FormControllerValidationHelpers;
