import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';
import { IDictionaryEntity } from '../../../../../../api/models/da-dictionary/dictionary';

interface IConfig {
  isAddInitialModel?: boolean;
  nameAdditionalKey?: keyof IDictionaryEntity;
  isNeedBrackets?: boolean;
}

const createDictionaryEntitySelectOption = (
  entity: IDictionaryEntity,
  config?: IConfig
): ISelectOption<IDictionaryEntity> => {
  let { name } = entity;

  if (config?.nameAdditionalKey) {
    name = `${name} ${
      config.isNeedBrackets
        ? `(${entity[config.nameAdditionalKey]})`
        : `${entity[config.nameAdditionalKey]}`
    }`;
  }

  const option: ISelectOption<IDictionaryEntity> = { label: name, value: entity.id };

  if (config?.isAddInitialModel) {
    option.initialModel = entity;
  }

  return option;
};

const createDictionaryEntitySelectOptionList = (
  entityList: IDictionaryEntity[],
  config?: IConfig
): ISelectOption<IDictionaryEntity>[] => {
  return entityList.map(entity => createDictionaryEntitySelectOption(entity, config));
};

export { createDictionaryEntitySelectOption, createDictionaryEntitySelectOptionList };
