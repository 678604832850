import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { systemHelperCollection } from '../../../../../../../common/utils/helpers/system';
import { EDashboardExternalRoutes } from '../../../../../../routes/DashboardRoute';
import { ECreateExperimentRoute } from '../../routes';
import fieldIcon from '../../assets/icons/field.svg';

import { EZonesPlugTypes } from './ZonesPlugResolver.types';
import Styled from './ZonesPlugResolver.styles';

interface IProps {
  type: EZonesPlugTypes;
  title: string;
}

export const ZonesPlugResolver: FC<IProps> = ({ type, title }) => {
  const navigate = useNavigate();

  const navigateToZones = () => {
    navigate(`../${ECreateExperimentRoute.Zones}`);
  };

  const navigateToFields = () => {
    const assistanceBaseUrl = systemHelperCollection.getAssistanceBaseUrl();

    window.open(new URL(EDashboardExternalRoutes.Fields, assistanceBaseUrl).href, '_blank');
  };

  switch (type) {
    case EZonesPlugTypes.NotSelectedZones:
      return (
        <Styled.Plug
          icon={fieldIcon}
          title={title}
          primaryButtonTitle="К участкам"
          onPrimaryButtonClick={navigateToZones}
          buttonExist
        />
      );
    case EZonesPlugTypes.NoZones:
      return (
        <Styled.Plug
          icon={fieldIcon}
          title={title}
          primaryButtonTitle="Перейти в Помощник"
          onPrimaryButtonClick={navigateToFields}
          buttonExist
        />
      );
    case EZonesPlugTypes.EmptyZones:
    default:
      return (
        <Styled.Plug
          icon={fieldIcon}
          title={title}
          primaryButtonTitle="К участкам"
          onPrimaryButtonClick={navigateToZones}
          buttonExist
        />
      );
  }
};
