import { useMemo } from 'react';

import { sortBy } from '../../../../helpers/sort';

const useSortBy = <E>(elementList: E[], orderPropertyName?: keyof E | string): E[] => {
  const sortedElementList = useMemo(() => {
    return sortBy(elementList, String(orderPropertyName ?? 'order'));
  }, [elementList]);

  return sortedElementList;
};

export default useSortBy;
