import { useContext } from 'react';

import ComparisonTableBuilderContext from '../../ComparisonTableBuilder.context';

const useComparisonTableBuilderContext = () => {
  const context = useContext(ComparisonTableBuilderContext);

  return context;
};

export default useComparisonTableBuilderContext;
