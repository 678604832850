export enum Sizes {
  ExtendedFullScreen = '1316px',
  FullScreen = '1284px',
}

export enum BorderRadius {
  default = '16px',
  xsmall = '8px',
  small = '12px',
  large = '24px',
}

export enum Breakpoints {
  large = '1365px',
  medium = '1024px',
  small = '767px',
  miniSmall = '600px',
  extraMiniSmall = '450px',
  extraSmall = '374px',
}

export enum BreakpointsForMaxWidth {
  large = '1365px',
  medium = '1023px',
  small = '767px',
  extraSmall = '374px',
}

export enum BreakpointsNumbers {
  large = 1365,
  medium = 1023,
  small = 767,
  extraSmall = 374,
}

export enum FontSizes {
  default = 'font-size: 12px; line-height: 16px;',
  defaultBold = 'font-size: 12px; line-height: 16px; font-weight: 600;',
  medium = 'font-size: 14px; line-height: 20px;',
  mediumBold = 'font-size: 14px; line-height: 20px; font-weight: 600;',
  large = 'font-size: 16px; line-height: 20px;',
  largeBold = 'font-size: 16px; line-height: 20px; font-weight: 600;',
  xlarge = 'font-size: 20px; line-height: 28px;',
  xlargeBold = 'font-size: 20px; line-height: 28px; font-weight: 600;',
  xxlargeBold = 'font-size: 24px; line-height: 28px; font-weight: 600;',
  title = 'font-size: 24px; line-height: 28px;',
}
