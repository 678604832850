import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import axios, { AxiosRequestConfig } from 'axios';

import * as apiRaw from '../../../../../../api';
import { provide } from '../../../../utils/helpers/mobx';
import { logoutEvent } from '../../../../events/logout';
import { detectDeviceType } from '../../../../utils/helpers/device/detectDeviceType';
import { APP_VERSION } from '../../../../utils/constants/app';

import { TApiName, TApiRequest, TApiResponse, TRequestParams } from './Axios.service.types';
import { API_TECHNICAL_FIELD_LIST } from './utils/constants';
import { getAxiosUrl } from './utils/helpers/getAxiosUrl';

@provide.singleton()
class AxiosService {
  private _api: {
    [FnName in TApiName]: (
      request: TApiRequest<FnName>,
      config?: {
        omit?: Array<Partial<keyof TApiRequest<FnName>>>;
        path?: string;
        query?: { [key: string]: any };
        formData?: FormData;
        responseType?: AxiosRequestConfig['responseType'];
      }
    ) => Promise<TApiResponse<FnName>>;
  } = _.mapValues(apiRaw, (route, apiName) => (params: any, config: any) => {
    if (_.isArray(params)) {
      return this.request({ route, apiName, config, data: [...params] } as any);
    }

    return this.request({ route, apiName, config, ...params });
  });

  private _isRenewLoading = true;

  private _needAdditionalInfo = false;

  private _canRenew = true;

  constructor() {
    makeAutoObservable(this);

    if (!this.accessToken() || !this.refreshToken()) {
      document.dispatchEvent(logoutEvent);
    }
  }

  get api() {
    return this._api;
  }

  get isRenewLoading() {
    return this._isRenewLoading;
  }

  get needAdditionalInfo() {
    return this._needAdditionalInfo;
  }

  get canRenew() {
    return this._canRenew;
  }

  setNeedAdditionalInfo = (value: boolean): void => {
    this._needAdditionalInfo = value;
  };

  accessToken = (): string => {
    return localStorage.getItem('loginAs') || localStorage.getItem('accessToken') || '';
  };

  refreshToken = (): string => localStorage.getItem('refreshToken') || '';

  renewTokens = async () => {
    try {
      if (this.refreshToken() && this.accessToken()) {
        const response = await this.api.renewAccessAndRefreshToken({
          'refresh-token': this.refreshToken(),
          'access-token': this.accessToken(),
        });

        localStorage.setItem('refreshToken', response['refresh-token']);
        localStorage.setItem('accessToken', response['access-token']);
      }
    } catch (e) {
      document.dispatchEvent(logoutEvent);
    }
  };

  request = (params: TRequestParams) => {
    return Promise.resolve()
      .then(() => this.sendRequest(params))
      .catch(error => {
        // TODO: make appropriate handler

        if (error.response) {
          // Request was made but server responded with something
          // other than 2xx
          // eslint-disable-next-line no-console
          console.error('Status:', error.response?.status);
          // eslint-disable-next-line no-console
          console.error('Data:', error.response?.data);
          // eslint-disable-next-line no-console
          console.error('Headers:', error.response?.headers);
          if (
            !this.needAdditionalInfo &&
            error.response.headers['x-need-additional-info'] === 'true'
          ) {
            this.setNeedAdditionalInfo(true);
          }
        } else {
          // Something else happened while setting up the request
          // triggered the error
          // eslint-disable-next-line no-console
          console.error('Error Message:', error.message);
        }

        const isRenewMethod = params.route.url === '/api/da-profile/users/v2/renew';

        if (error.response.status === 401 && !isRenewMethod && this.canRenew) {
          this.renewTokens();
        } else {
          throw error;
        }
      });
  };

  sendRequest = (params: TRequestParams) => {
    const { route, config } = params;

    const requestParams = _.omit(params, API_TECHNICAL_FIELD_LIST);
    const token = this.accessToken();

    // @ts-ignore
    const url = _.isFunction(route.url) ? route.url(requestParams) : route.url;

    const axiosParams: AxiosRequestConfig = {
      url: getAxiosUrl({ url, path: config?.path, query: config?.query }),
      method: route.method,
      headers: Object.assign(
        // TODO: unsafe to use localStorage directly
        {
          'access-token': token,
          'tracking-resolution': `${window.screen.width}x${window.screen.height}`,
          'tracking-user-agent': window.navigator.userAgent,
          'tracking-language': window.navigator.language.slice(0, 2),
          'x-device': detectDeviceType(),
          'x-app': 'assistance',
          'x-app-version': APP_VERSION,
        },
        route.headers
      ),
      responseType: config?.responseType,
    };

    axiosParams[route.method === 'GET' ? 'params' : 'data'] = config?.omit
      ? _.omit(requestParams, config.omit)
      : requestParams.data
      ? requestParams.data
      : requestParams;

    if (route.method === 'POST' || route.method === 'PUT') {
      const requestData = _.isFunction(route.request)
        ? // @ts-ignore
          route.request(requestParams)
        : requestParams;

      const clearedRequestData = config?.omit ? _.omit(requestData, config?.omit) : requestData;

      axiosParams.data = clearedRequestData;

      if (config?.formData) {
        axiosParams.data = config.formData;
      }
    }

    if (route.method === 'PUT' && requestParams.data) {
      axiosParams.data = requestParams.data;
    }

    const axiosClient = axios.create();

    return axiosClient(axiosParams).then(response => response.data);
  };
}

export default AxiosService;
