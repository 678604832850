import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/helpers/mobx';
import {
  _Attribute,
  _AttributeValue,
  _ICollapsingStage,
  _Stage,
  _Instance,
  IDynamicTableConfig,
  IComparisonTableImagesConfig as IImagesConfig,
} from '../../../models';

@provide.singleton()
class DynamicTableStore {
  private _imagesConfigById: Map<string, IImagesConfig> = new Map<string, IImagesConfig>();

  private _isImagesTableIsEdited = false;

  private _tableIdToConfig: Map<string, IDynamicTableConfig> = new Map<
    string,
    IDynamicTableConfig
  >();

  private _tableIdToMapFromCollapsingStages: Map<string, Map<string, _ICollapsingStage>> = new Map<
    string,
    Map<string, _ICollapsingStage>
  >();

  private _tableIdToMapFromStages: Map<string, Map<string, _Stage>> = new Map<
    string,
    Map<string, _Stage>
  >();

  private _tableIdToMapFromAttributes: Map<string, Map<string, _Attribute>> = new Map<
    string,
    Map<string, _Attribute>
  >();

  private _tableIdToMapFromInstances: Map<string, Map<string, _Instance>> = new Map<
    string,
    Map<string, _Instance>
  >();

  private _tableIdToMapFromAttributeValues: Map<string, Map<string, _AttributeValue>> = new Map<
    string,
    Map<string, _AttributeValue>
  >();

  constructor() {
    makeAutoObservable(this);
  }

  get imagesConfigList() {
    return [...this._imagesConfigById.values()];
  }

  get isImagesTableIsEdited() {
    return this._isImagesTableIsEdited;
  }

  getImagesConfig = (id: string): IImagesConfig => {
    return this._imagesConfigById.get(id);
  };

  getAttributeValue = (tableId: string, attributeValueId: string): _AttributeValue => {
    return this._tableIdToMapFromAttributeValues.get(tableId)?.get(attributeValueId);
  };

  setImagesConfig = (config: IImagesConfig): void => {
    this._imagesConfigById.set(config.id, config);
  };

  setImagesConfigList = (configList: IImagesConfig[]): void => {
    configList.forEach(config => this._imagesConfigById.set(config.id, config));
  };

  setIsImagesTableIsEdited = (isEdited: boolean): void => {
    this._isImagesTableIsEdited = isEdited;
  };

  getConfig = (tableId: string): IDynamicTableConfig => {
    return this._tableIdToConfig.get(tableId);
  };

  setConfig = (config: IDynamicTableConfig): void => {
    this._tableIdToConfig.set(config.id, config);
  };

  deleteTableConfig = (tableId: string): void => {
    this._tableIdToConfig.delete(tableId);
  };

  clearTableIdToConfig = (): void => {
    this._tableIdToConfig.clear();
  };

  getCollapsingStage = (tableId: string, id: string): _ICollapsingStage => {
    return this._tableIdToMapFromCollapsingStages.get(tableId)?.get?.(id);
  };

  setCollapsingStage = (collapsingStage: _ICollapsingStage): void => {
    const { tableId } = collapsingStage;

    const isMapAlreadyCreated = this._tableIdToMapFromCollapsingStages.has(tableId);

    if (isMapAlreadyCreated) {
      this._tableIdToMapFromCollapsingStages.get(tableId).set(collapsingStage.id, collapsingStage);
    } else {
      const newMapFromCollapsingStages: Map<string, _ICollapsingStage> = new Map<
        string,
        _ICollapsingStage
      >();

      newMapFromCollapsingStages.set(collapsingStage.id, collapsingStage);

      this._tableIdToMapFromCollapsingStages.set(tableId, newMapFromCollapsingStages);
    }
  };

  setCollapsingStageList = (collapsingStageList: _ICollapsingStage[]): void => {
    if (!collapsingStageList.length) {
      return;
    }

    collapsingStageList.forEach(collapsingStage => {
      this.setCollapsingStage(collapsingStage);
    });
  };

  deleteTableCollapsingStageList = (tableId: string): void => {
    this._tableIdToMapFromCollapsingStages.delete(tableId);
  };

  clearTableIdToMapFromCollapsingStages = (): void => {
    this._tableIdToMapFromCollapsingStages.clear();
  };

  getStage = (tableId: string, id: string): _Stage => {
    return this._tableIdToMapFromStages.get(tableId)?.get?.(id);
  };

  setStage = (stage: _Stage): void => {
    const { tableId } = stage;

    const isMapAlreadyCreated = this._tableIdToMapFromStages.has(tableId);

    if (isMapAlreadyCreated) {
      this._tableIdToMapFromStages.get(tableId).set(stage.id, stage);
    } else {
      const newMapFromStages: Map<string, _Stage> = new Map<string, _Stage>();

      newMapFromStages.set(stage.id, stage);

      this._tableIdToMapFromStages.set(tableId, newMapFromStages);
    }
  };

  setStageList = (stageList: _Stage[]): void => {
    if (!stageList.length) {
      return;
    }

    stageList.forEach(stage => {
      this.setStage(stage);
    });
  };

  deleteTableStageList = (tableId: string): void => {
    this._tableIdToMapFromStages.delete(tableId);
  };

  clearTableIdToMapFromStages = (): void => {
    this._tableIdToMapFromStages.clear();
  };

  getAttribute = (tableId: string, id: string): _Attribute => {
    return this._tableIdToMapFromAttributes.get(tableId)?.get?.(id);
  };

  setAttribute = (attribute: _Attribute): void => {
    const { tableId } = attribute;

    const isMapAlreadyCreated = this._tableIdToMapFromAttributes.has(tableId);

    if (isMapAlreadyCreated) {
      this._tableIdToMapFromAttributes.get(tableId).set(attribute.id, attribute);
    } else {
      const newMapFromAttributes: Map<string, _Attribute> = new Map<string, _Attribute>();

      newMapFromAttributes.set(attribute.id, attribute);

      this._tableIdToMapFromAttributes.set(tableId, newMapFromAttributes);
    }
  };

  setAttributeList = (attributeList: _Attribute[]): void => {
    if (!attributeList.length) {
      return;
    }

    attributeList.forEach(attribute => {
      this.setAttribute(attribute);
    });
  };

  deleteTableAttributeList = (tableId: string): void => {
    this._tableIdToMapFromAttributes.delete(tableId);
  };

  clearTableIdToMapFromAttributes = (): void => {
    this._tableIdToMapFromAttributes.clear();
  };

  getInstance = (tableId: string, id: string): _Instance => {
    return this._tableIdToMapFromInstances.get(tableId)?.get(id);
  };

  getInstanceList = (tableId: string): _Instance[] => {
    return Array.from(this._tableIdToMapFromInstances.get(tableId)?.values?.() || []);
  };

  setInstance = (instance: _Instance): void => {
    const { tableId } = instance;

    const isMapAlreadyCreated = this._tableIdToMapFromInstances.has(tableId);

    if (isMapAlreadyCreated) {
      this._tableIdToMapFromInstances.get(tableId).set(instance.id, instance);
    } else {
      const newMapFromInstances: Map<string, _Instance> = new Map<string, _Instance>();

      newMapFromInstances.set(instance.id, instance);

      this._tableIdToMapFromInstances.set(tableId, newMapFromInstances);
    }
  };

  changeInstanceCustomProp = <CustomProp>(
    tableId: string,
    id: string,
    customProp: _Instance<CustomProp>['customProp']
  ): void => {
    const _instance = this._tableIdToMapFromInstances.get(tableId)?.get(id);

    if (_instance) {
      _instance.customProp = customProp;
    }
  };

  setInstanceList = (instanceList: _Instance[]): void => {
    if (!instanceList.length) {
      return;
    }

    instanceList.forEach(instance => {
      this.setInstance(instance);
    });
  };

  deleteInstance = (tableId: string, id: string): void => {
    this._tableIdToMapFromInstances.get(tableId)?.delete(id);
  };

  deleteTableInstanceList = (tableId: string): void => {
    this._tableIdToMapFromInstances.delete(tableId);
  };

  clearTableIdToMapFromInstances = (): void => {
    this._tableIdToMapFromInstances.clear();
  };

  getValueListByTableId = (tableId: string): _AttributeValue[] => {
    const attributeValueList = Array.from(
      this._tableIdToMapFromAttributeValues.get(tableId)?.values?.() || []
    );

    return attributeValueList;
  };

  getValueListByAttributeId = (tableId: string, attributeId: string): _AttributeValue[] => {
    const attributeValueList = this.getValueListByTableId(tableId);

    return attributeValueList.filter(value => value.attributeId === attributeId);
  };

  getValueListByInstanceId = (tableId: string, instanceId: string): _AttributeValue[] => {
    const attributeValueList = this.getValueListByTableId(tableId);

    return attributeValueList.filter(value => value.instanceId === instanceId);
  };

  setAttributeValue = (attributeValue: _AttributeValue): void => {
    const { tableId } = attributeValue;

    const isMapAlreadyCreated = this._tableIdToMapFromAttributeValues.has(tableId);

    if (isMapAlreadyCreated) {
      this._tableIdToMapFromAttributeValues.get(tableId).set(attributeValue.id, attributeValue);
    } else {
      const newMapFromAttributeValues: Map<string, _AttributeValue> = new Map<
        string,
        _AttributeValue
      >();

      newMapFromAttributeValues.set(attributeValue.id, attributeValue);

      this._tableIdToMapFromAttributeValues.set(tableId, newMapFromAttributeValues);
    }
  };

  deleteAttributeValue = (tableId: string, id: string): void => {
    this._tableIdToMapFromAttributeValues.get(tableId)?.delete(id);
  };

  setAttributeValueList = (attributeValueList: _AttributeValue[]): void => {
    if (!attributeValueList.length) {
      return;
    }

    attributeValueList.forEach(attributeValue => {
      this.setAttributeValue(attributeValue);
    });
  };

  deleteTableAttributeValueList = (tableId: string): void => {
    this._tableIdToMapFromAttributeValues.delete(tableId);
  };

  clearTableIdToMapFromAttributeValues = (): void => {
    this._tableIdToMapFromAttributeValues.clear();
  };

  deleteImagesConfig = (id: string): void => {
    this._imagesConfigById.delete(id);
  };

  clearImagesConfigsById = (): void => {
    this._imagesConfigById.clear();
  };

  clearIsImagesTableIsEdited = (): void => {
    this._isImagesTableIsEdited = false;
  };

  clearStore = (): void => {
    this.clearImagesConfigsById();
    this.clearIsImagesTableIsEdited();

    this.clearTableIdToConfig();
    this.clearTableIdToMapFromCollapsingStages();
    this.clearTableIdToMapFromStages();
    this.clearTableIdToMapFromAttributes();

    this.clearTableIdToMapFromInstances();
    this.clearTableIdToMapFromAttributeValues();
  };
}

export default DynamicTableStore;
