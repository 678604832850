import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

const { AccentGreen } = ECommonColor;

const Wrapper = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: ${AccentGreen};
`;

const NoInfoWrapper = styled.span`
  white-space: normal;
`;

const StyledAuditReportViewTaskAttrVal = {
  Wrapper,
  NoInfoWrapper,
};

export default StyledAuditReportViewTaskAttrVal;
