import { FC, memo, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, TCarouselItemProps, useShowTooltip } from '@farmlink/farmik-ui';

import { EExperimentCultureZoneType } from '../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { TExecutionZone } from '../../modules/Execution/types';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { ExecutionStore } from '../../modules/Execution/mobx/stores';

import { controlSectionIcon, experimentSectionIcon } from './assets';
import Styled from './ExperimentZonesCarouselItem.styles';

const mapIconBySectionType = {
  [EExperimentCultureZoneType.Control]: controlSectionIcon,
  [EExperimentCultureZoneType.Experiment]: experimentSectionIcon,
};

interface IProps extends TCarouselItemProps {
  sections: TExecutionZone[];
}

const ExperimentZonesCarouselItem: FC<IProps> = observer(
  ({ id, name, description, selected, handleSelectItem, sections }) => {
    const executionStore = useStore(ExecutionStore);

    const ref = useRef<HTMLDivElement>();

    const { ref: titleRef, isShowTooltip: isShowTitleTooltip } = useShowTooltip();

    const currentSection = useMemo(
      () => sections.find(section => section.id === id),
      [sections, id]
    );

    const sectionIcon = useMemo(() => mapIconBySectionType[currentSection.type], [currentSection]);

    const handleItemClick = () => {
      handleSelectItem(id);
    };

    const hasInvalidRowByZoneId = useMemo(
      () => executionStore.hasInvalidRowByZoneId(id),
      [executionStore.invalidRowIdList]
    );

    return (
      <Styled.Wrapper onClick={handleItemClick} $IsSelected={selected} ref={ref} id={id}>
        <Styled.InfoContainer>
          <AutoTooltip content={name} position="top" disabled={!isShowTitleTooltip}>
            <Styled.Title ref={titleRef}>{name}</Styled.Title>
          </AutoTooltip>

          <Styled.DescriptionContainer>
            <Styled.SectionIcon src={sectionIcon} />

            <Styled.Description>{description}</Styled.Description>
          </Styled.DescriptionContainer>
        </Styled.InfoContainer>
        {hasInvalidRowByZoneId && (
          <AutoTooltip content="Исправьте данные в таблице" position="bottom">
            <Styled.ErrorBudget />
          </AutoTooltip>
        )}
      </Styled.Wrapper>
    );
  }
);

ExperimentZonesCarouselItem.displayName = 'ExperimentZonesCarouselItem';

export default memo(ExperimentZonesCarouselItem);
