import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.Colors.secondaryDefault};
`;

const FieldBlock = styled.div`
  margin-bottom: 24px;
`;

export default {
  Wrapper,
  Title,
  Description,
  FieldBlock,
};
