import { FC, useEffect } from 'react';

import SpinnerLoader from '../../../components/ui/loaders/SpinnerLoader/SpinnerLoader';

import { TExternalRedirectContainerProps } from './ExternalRedirectContainer.types';

const ExternalRedirectContainer: FC<TExternalRedirectContainerProps> = ({
  redirectTo,
  isLogout,
}) => {
  useEffect(() => {
    let redirectUrl = redirectTo;

    if (isLogout) {
      redirectUrl = redirectUrl.replace('?', 'logout?');
    }

    window.location.href = redirectUrl;
  }, []);

  return <SpinnerLoader data-test-id="external-redirect-loader" needToHideContent={true} />;
};

ExternalRedirectContainer.displayName = 'ExternalRedirectContainer';

export default ExternalRedirectContainer;
