import styled from 'styled-components';

const StickyWrapper = styled.div<{ $isStuck: boolean }>`
  position: sticky;
  top: 0;
  margin-left: -24px;
  padding: 0 34px;
  background-color: #ffffff;
  width: 912px;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: ${({ $isStuck }) => ($isStuck ? '0px 2px 12px 0px rgba(0, 0, 0, 0.1)' : 'none')};
  clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
  /**
    Чтобы текст строк (из-за того, что там используется тултип)
    не отображался поверх хедера таблицы.
   */
  z-index: 2;
`;

const Wrapper = styled.div`
  background-color: #ffffff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const StyledComparisonHeader = {
  Wrapper,
  StickyWrapper,
};

export default StyledComparisonHeader;
