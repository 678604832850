import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import {
  IExperiment,
  IExperimentStep,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments';
import { IOrganization } from '../../../../../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import { IEmployee } from '../../../../../../../../../../../../../api/models/da-profile/users';
import { IDictionaryEntity } from '../../../../../../../../../../../../../api/models/da-dictionary/dictionary';

@provide.singleton()
class ObservationStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _mapIdToExpiriments: Map<string, IExperiment> = new Map();
  private _mapIdToOrganizations: Map<string, IOrganization> = new Map();
  private _mapIdToAllUsers: Map<string, IEmployee> = new Map();
  private _mapIdToExperimentSteps: Map<string, IExperimentStep> = new Map();
  private _mapIdToOperations: Map<string, IDictionaryEntity> = new Map();

  private _organizationId = null;
  private _experimentId = null;

  private _searchQueries = {
    organizationSearchQuery: '',
    experimentSearchQuery: '',
    experimentStepSearchQuery: '',
    techOperationSearchQuery: '',
    assigneeSearchQuery: '',
  };

  private _dropdownPageNumbers = {
    organizationCurrentPageNumber: 0,
    organizationTotalPageNumber: 0,
    experimentCurrentPageNumber: 0,
    experimentTotalPageNumber: 0,
    experimentStepCurrentPageNumber: 0,
    experimentStepTotalPageNumber: 0,
    techOperationCurrentPageNumber: 0,
    techOperationTotalPageNumber: 0,
    assigneeCurrentPageNumber: 0,
    assigneeTotalPageNumber: 0,
  };

  get allUserList() {
    return [...this._mapIdToAllUsers.values()];
  }

  get organizationList() {
    return [...this._mapIdToOrganizations.values()];
  }

  get expirimentList() {
    return [...this._mapIdToExpiriments.values()];
  }

  get operationList() {
    return [...this._mapIdToOperations.values()];
  }

  get expirimentStepList() {
    return [...this._mapIdToExperimentSteps.values()];
  }

  get organizationId() {
    return this._organizationId;
  }

  get experimentId() {
    return this._experimentId;
  }

  get searchQueries() {
    return this._searchQueries;
  }

  get dropdownPageNumbers() {
    return this._dropdownPageNumbers;
  }

  getExpStepById = (stepId: string): IExperimentStep => {
    return this._mapIdToExperimentSteps.get(stepId);
  };

  setDropdownPageNumbers = (dropdownName: string, pageNumber: number): void => {
    this._dropdownPageNumbers[dropdownName] = pageNumber;
  };

  setDropdownSearchQuery = (dropdownName: string, query: string): void => {
    this._searchQueries[dropdownName] = query;
  };

  setOrganizationId = (organizationId): void => {
    this._organizationId = organizationId;
  };

  setExperimentId = (experimentId): void => {
    this._experimentId = experimentId;
  };

  getExperimentInfoById = (experimentId): IExperiment => {
    return this._mapIdToExpiriments.get(experimentId);
  };

  setExperimentById = (experimentList: IExperiment[]): void => {
    experimentList.forEach(experiment => this._mapIdToExpiriments.set(experiment.id, experiment));
  };

  setOrganizationById = (organizationList: IOrganization[]): void => {
    organizationList.forEach(organization =>
      this._mapIdToOrganizations.set(organization.organizationId, organization)
    );
  };

  setExperimentStepById = (experimentStepList: IExperimentStep[]): void => {
    experimentStepList.forEach(experimentStep =>
      this._mapIdToExperimentSteps.set(experimentStep.id, experimentStep)
    );
  };

  setOperationById = (operationList: IDictionaryEntity[]): void => {
    operationList.forEach(operation => this._mapIdToOperations.set(operation.id, operation));
  };

  setAssigneeById = (assigneeList: IEmployee[]): void => {
    assigneeList.forEach(assignee => this._mapIdToAllUsers.set(assignee.id, assignee));
  };

  clearOrganizationById = (): void => {
    this._mapIdToOrganizations.clear();
  };

  clearExperimentById = (): void => {
    this._mapIdToExpiriments.clear();
  };

  clearExperimentStepsById = (): void => {
    this._mapIdToExperimentSteps.clear();
  };

  clearOperationById = (): void => {
    this._mapIdToOperations.clear();
  };

  clearAssigneeById = (): void => {
    this._mapIdToAllUsers.clear();
  };

  clearAfterExperimentChange = (): void => {
    this.clearExperimentStepsById();
    this.clearOperationById();
    this.clearAssigneeById();
  };

  clearAfterOrganizationChange = (): void => {
    this.clearExperimentById();
    this.clearAfterExperimentChange();
  };

  clearStore = (): void => {
    this.clearOrganizationById();
    this.clearAfterOrganizationChange();
  };
}

export default ObservationStore;
