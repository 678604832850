import { AxiosService } from '../../../../../../../../common/mobx/services/axios/AxiosService';
import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { ContractSidePanelStore } from '../../stores';
import {
  IExperiment,
  IExperimentContract,
} from '../../../../../../../../../api/models/as-fields/experiments';
import { TChangeExperimentContractReq } from '../../../../../../../../../api';
import { GlobalService } from '../../../../../../../../common/mobx/services/system';

@provide.singleton()
class ContractSidePanelService {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  @lazyInject(GlobalService)
  globalService: GlobalService;

  @lazyInject(ContractSidePanelStore)
  contractSidePanelStore: ContractSidePanelStore;

  generateTechTaskReport = async (experimentId: string): Promise<Blob> => {
    const { generateTechTaskReport } = this.axiosService.api;

    const response = await generateTechTaskReport({ experimentId }, { responseType: 'blob' });

    return response;
  };

  deleteContract = async (contractId: string): Promise<IExperimentContract> => {
    const { deleteExperimentContract } = this.axiosService.api;

    const response = await deleteExperimentContract({ id: contractId });

    return response;
  };
}

export default ContractSidePanelService;
