enum EComparisonTableName {
  Comparison = 'comparison',

  ExecutionSteps = 'execution__steps',
  ExecutionCalculations = 'execution__calculations',

  TotalComparison = 'total-comparison',
}

export default EComparisonTableName;
