import styled from 'styled-components';

const Wrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  color: #151f32;
`;

const StyledTypeCellComponent = {
  Wrapper,
};

export default StyledTypeCellComponent;
