import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import { Plug } from '../../../../../../../common/components/ui';

const StyledPlug = styled(Plug)`
  height: 100%;
  width: 100%;
  margin: 24px auto 100px;
  padding: 24px;

  background-color: ${Colors.generalWhite};
  border-radius: 16px;
`;

const StyledZonesPlugResolver = {
  Plug: StyledPlug,
};

export default StyledZonesPlugResolver;
