import React, { FC, memo, PropsWithChildren } from 'react';

import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';

import Styled from './TableFiltersBuilderGridItem.styles';

interface IProps {
  name: string;
}

const TableFiltersBuilderGridItem: FC<PropsWithChildren<IProps>> = ({ name, children }) => {
  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper $name={name} {...getDataTestId('grid-item')}>
      {children}
    </Styled.Wrapper>
  );
};

TableFiltersBuilderGridItem.displayName = 'TableFiltersBuilderGridItem';

export default memo(TableFiltersBuilderGridItem);
