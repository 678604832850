import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { IUser } from '../../../models/da-profile/users';

type TGetExperimentUserListReq = {
  fullName?: string;
  organizationId?: string;
  size?: number;
  page?: number;
  notCurrentUser?: boolean;
  notRoleId?: string;
  notOwnerOrganizationId?: string;
};

type TGetExperimentUserListRes = {} & TResponseList<IUser>;

const getExperimentUserList: TApiRoute & {
  request: TGetExperimentUserListReq;
  response: TGetExperimentUserListRes;
} = {
  url: () => `/api/da-profile/users/experiment`,
  method: 'GET',
  request: {} as TGetExperimentUserListReq,
  response: {} as TGetExperimentUserListRes,
  headers: {},
};

export type { TGetExperimentUserListReq, TGetExperimentUserListRes };

export { getExperimentUserList };
