import { IExperimentStep } from '../../../../models/as-fields/experiments';
import { TResponseList } from '../../../../models/common/response';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetExperimentStepOldReq = {} & { experimentId: string; type?: string };

type TGetExperimentStepOldRes = {} & TResponseList<IExperimentStep>;

const getExperimentStepOld: TApiRoute & {
  request: TGetExperimentStepOldReq;
  response: TGetExperimentStepOldRes;
} = {
  url: ({ experimentId }) => `/api/as-fields/experimentSteps/${experimentId}`,
  method: 'GET',
  request: {} as TGetExperimentStepOldReq,
  response: {} as TGetExperimentStepOldRes,
  headers: {},
};

export type { TGetExperimentStepOldRes, TGetExperimentStepOldReq };

export { getExperimentStepOld };
