import { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../utils/hooks/locators';

import { TabItem } from './components';
import { ITabsProps } from './Tabs.types';
import Styled from './Tabs.styles';

const Tabs: FC<ITabsProps> = ({ tabs = [], activeTabId, onTabClick, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId('tabs-wrapper')}>
      {tabs.map(tab => (
        <TabItem
          key={tab.id}
          tab={tab}
          activeTabId={activeTabId}
          onClick={onTabClick}
          dataTestId={dataTestId}
        />
      ))}
    </Styled.Wrapper>
  );
};

Tabs.displayName = 'Tabs';

export default memo(Tabs);
