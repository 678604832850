import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  padding-top: 2px;
`;

const StyledActionCell = {
  Wrapper,
};

export default StyledActionCell;
