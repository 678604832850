import { IExperimentStep } from '../../../models/as-fields/experiments';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TGetExperimentStepReq = { id: string; cultureZoneExperimentId?: string };

type TGetExperimentStepRes = IExperimentStep;

const getExperimentStep: TApiRoute & {
  request: TGetExperimentStepReq;
  response: TGetExperimentStepRes;
} = {
  url: ({ id }) => `/api/as-fields/experimentSteps/${id}`,
  method: 'GET',
  request: {} as TGetExperimentStepReq,
  response: {} as TGetExperimentStepRes,
  headers: {},
};

export type { TGetExperimentStepRes, TGetExperimentStepReq };

export { getExperimentStep };
