import { FC, memo } from 'react';

import { ITableBuilderRow } from '../../../models/data';
import { TableBuilderRowAutoRenderContainer as RowAutoRenderContainer } from '../TableBuilderRowAutoRenderContainer';
import { TableBuilderRowCustomRenderContainer as RowCustomRenderContainer } from '../TableBuilderRowCustomRenderContainer';
import { TableBuilderRowNestedRenderContainer as RowNestedRenderContainer } from '../TableBuilderRowNestedRenderContainer';

interface IProps {
  row: ITableBuilderRow;
  borderType: string;
}

const TableBuilderRowContainer: FC<IProps> = ({ row, borderType }) => {
  switch (row.renderType || 'auto') {
    case 'auto':
      return <RowAutoRenderContainer row={row} />;

    case 'custom':
      return <RowCustomRenderContainer row={row} />;

    case 'nested':
      return <RowNestedRenderContainer row={row} borderType={borderType} />;

    default:
      return <></>;
  }
};

TableBuilderRowContainer.displayName = 'TableBuilderRowContainer';

export default memo(TableBuilderRowContainer);
