import styled from 'styled-components';

const HeaderWrapper = styled.div``;

const ContentPresets = styled.div`
  margin-top: 12px;
`;

const ContentFilters = styled.div`
  margin-top: 16px;
`;

const Content = styled.div``;

const FooterWrapper = styled.div`
  width: 100%;

  overflow: hidden;

  margin-top: 12px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  padding: 20px 24px;

  background-color: white;
`;

const StyledTableFiltersBuilder = {
  HeaderWrapper,
  ContentPresets,
  ContentFilters,
  Content,
  FooterWrapper,
  Wrapper,
};

export default StyledTableFiltersBuilder;
