import { FC } from 'react';
import { observer } from 'mobx-react';

import { TableBuilderStore as Store } from '../../../mobx/stores';
import { useTableBuilderUIContext as useUIContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { TableBuilderGrid as Grid } from '../../TableBuilderGrid';
import { TableBuilderColumn as Column } from '../../content';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { useStore } from '../../../../../utils/helpers/mobx';
import { useSortBy } from '../../../../../utils/hooks/useSortBy';

import Styled from './TableBuilderHeader.styles';

interface ITableBuilderHeaderProps {
  borderType: string;
}

const TableBuilderHeader: FC<ITableBuilderHeaderProps> = ({ borderType }) => {
  const store = useStore(Store);

  const UIContext = useUIContext();

  const columnList = useSortBy(store.getColumnList(UIContext.builderId));

  const getDataTestId = useDataTestId();

  if (columnList?.length === 0) {
    return null;
  }

  return (
    <Styled.Wrapper {...getDataTestId('header')} $borderType={borderType}>
      <Grid>
        {columnList.map(column => (
          <Column key={column.id} column={column} />
        ))}
      </Grid>
    </Styled.Wrapper>
  );
};

TableBuilderHeader.displayName = 'TableBuilderHeader';

export default observer(TableBuilderHeader);
