import { AxiosError } from 'axios';

import { IAccessRule } from '../../../../../api/models/da-profile/access/Access.model';
import { getImportedFieldsSaveError } from '../../../utils/helpers/getImportedFieldSaveError';
import { lazyInject, provide } from '../../../utils/helpers/mobx';
import { AccessService } from '../../services/da-profile';
import { AccessStore } from '../../stores/AccessStore';

@provide.singleton()
class AccessController {
  @lazyInject(AccessStore)
  protected accessStore: AccessStore;

  @lazyInject(AccessService)
  protected accessService: AccessService;

  getAllActiveGlobalActionsForCurrentUser = async (): Promise<IAccessRule[] | void> => {
    const { getAllActiveGlobalActionsForCurrentUser } = this.accessService;
    const { setAccessRules } = this.accessStore;

    const allAccessRules = await getAllActiveGlobalActionsForCurrentUser();

    setAccessRules(allAccessRules);

    return allAccessRules;
  };

  checkUserResponseError = (error: AxiosError): void => {
    const { setResponseError } = this.accessStore;

    const responseError = getImportedFieldsSaveError(error);

    setResponseError(responseError);
  };

  getAccessRuleForCurrentUserInOrganization = async ({
    organizationId,
    rule,
  }: {
    organizationId: string;
    rule: string;
  }): Promise<boolean> => {
    const { getAccessRuleForCurrentUserInOrganization } = this.accessService;

    const accessRule = await getAccessRuleForCurrentUserInOrganization({ organizationId, rule });

    return accessRule;
  };
}

export default AccessController;
