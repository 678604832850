import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import {
  createExecutionTableBuilderId,
  createExecutionTableFertilizerRowsGroupId as createFertilizerRowsGroupId,
  createExecutionTableProtectionRowsGroupId as createProtectionRowsGroupId,
  createExecutionTableRowId as createRowId,
  getExecutionTableRowsGroupIdByRowId as getRowsGroupIdByRowId,
} from '../../../../../helpers';
import { ExecutionCellsService } from '../../../../../mobx/services';
import { IExecutionDeviationsCellFormattedValue as IDeviationsFormattedValue } from '../../../../../components/cells/ExecutionDeviationsCell/ExecutionDeviationsCell.types';
import { formatDoubleNum } from '../../../../../../../../../../../../common/utils/helpers/numbers';
import {
  EExperimentFactTableCalculationsAttributeId as ECalculationsAttributeId,
  EExperimentFactTableStepAttributeId as EStepAttributeId,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { EComparisonTableName } from '../../../../../../../../../../../constants/features';

@provide.transient()
class ExecutionCalculationsCellsService extends ExecutionCellsService {
  getDeviationsValue = (builderId: string, rowId: string): IDeviationsFormattedValue => {
    const planInitialValue = this.getPlanCellDoubleValue(builderId, rowId);
    const factInitialValue = this.getFactCellDoubleValue(builderId, rowId);

    const factUpdatedValue = this.getZoneFactUpdatedValue(builderId, rowId);

    return this.getFormattedDeviationsDoubleValue(
      planInitialValue,
      factUpdatedValue ?? factInitialValue
    );
  };

  getFactRevenueValue = (builderId: string, rowId: string): string => {
    const rowsGroupId = getRowsGroupIdByRowId(rowId);
    const cropPriceRowId = createRowId(rowsGroupId, ECalculationsAttributeId.CropPrice);
    const yieldRowId = createRowId(rowsGroupId, ECalculationsAttributeId.Yield);

    const cropPriceValue = this.getFactZoneDoubleValue(builderId, cropPriceRowId);
    const yieldValue = this.getFactZoneDoubleValue(builderId, yieldRowId);

    const result = Number(cropPriceValue) * Number(yieldValue);

    if (isNaN(result)) {
      return formatDoubleNum(0);
    }

    return formatDoubleNum(result);
  };

  getFactTotalServicePriceValue = (): string => {
    const { stepIdList } = this.executionStore;

    const priceList = stepIdList.map<string>(stepId => {
      return this.getServicePricePerAreaValue(stepId);
    });

    const result = priceList.reduce<number>((acc, price) => {
      return acc + Number(price);
    }, 0);

    if (isNaN(result)) {
      return formatDoubleNum(0);
    }

    return formatDoubleNum(result);
  };

  getFactTotalFertilizerPriceValue = (): string => {
    const { stepIdList, getFertilizerIdList, getProtectionIdList } = this.executionStore;

    const priceList: string[] = stepIdList.flatMap(stepId => {
      const fertilizerIdList = getFertilizerIdList(stepId);

      const fertilizerPriceList = fertilizerIdList.map(fertilizerId => {
        return this.getFertilizerPriceValue(stepId, fertilizerId);
      });

      const protectionIdList = getProtectionIdList(stepId);

      const protectionPriceList = protectionIdList.map(protectionId => {
        return this.getProtectionPriceValue(stepId, protectionId);
      });

      return fertilizerPriceList.concat(protectionPriceList);
    });

    const result = priceList.reduce<number>((acc, price) => {
      return acc + Number(price);
    }, 0);

    if (isNaN(result)) {
      return formatDoubleNum(0);
    }

    return formatDoubleNum(result);
  };

  getFactTotalPriceValue = (builderId: string, rowId: string): string => {
    const rowsGroupId = getRowsGroupIdByRowId(rowId);
    const totalFertilizerPriceRowId = createRowId(
      rowsGroupId,
      ECalculationsAttributeId.TotalFertilizerPrice
    );

    const totalServicePriceRowId = createRowId(
      rowsGroupId,
      ECalculationsAttributeId.TotalServicePrice
    );

    const totalFertilizerPriceValue =
      this.getFactCellDoubleValue(builderId, totalFertilizerPriceRowId) ?? '0.00';

    const totalServicePriceValue =
      this.getFactCellDoubleValue(builderId, totalServicePriceRowId) ?? '0.00';

    const result = Number(totalFertilizerPriceValue) + Number(totalServicePriceValue);

    if (isNaN(result)) {
      return formatDoubleNum(0);
    }

    return formatDoubleNum(result);
  };

  protected getFertilizerPriceValue = (stepId: string, fertilizerId: string): string => {
    const concentrationRowId = createRowId(
      createFertilizerRowsGroupId(stepId, fertilizerId),
      EStepAttributeId.Concentration
    );

    const pricePerUnitRowId = createRowId(
      createFertilizerRowsGroupId(stepId, fertilizerId),
      EStepAttributeId.PricePerUnit
    );

    const stepsBuilderId = createExecutionTableBuilderId(
      this.executionStore.selectedZoneId,
      EComparisonTableName.ExecutionSteps
    );

    const concentrationValue =
      this.getFactFertilizerDoubleValue(stepsBuilderId, concentrationRowId) ?? '0.00';

    const pricePerUnitValue =
      this.getFactFertilizerDoubleValue(stepsBuilderId, pricePerUnitRowId) ?? '0.00';

    const result = Number(concentrationValue) * Number(pricePerUnitValue);

    return formatDoubleNum(result);
  };

  protected getProtectionPriceValue = (stepId: string, protectionId: string): string => {
    const concentrationRowId = createRowId(
      createProtectionRowsGroupId(stepId, protectionId),
      EStepAttributeId.Concentration
    );

    const pricePerUnitRowId = createRowId(
      createProtectionRowsGroupId(stepId, protectionId),
      EStepAttributeId.PricePerUnit
    );

    const stepsBuilderId = createExecutionTableBuilderId(
      this.executionStore.selectedZoneId,
      EComparisonTableName.ExecutionSteps
    );

    const concentrationValue =
      this.getFactProtectionDoubleValue(stepsBuilderId, concentrationRowId) ?? '0.00';

    const pricePerUnitValue =
      this.getFactProtectionDoubleValue(stepsBuilderId, pricePerUnitRowId) ?? '0.00';

    const result = Number(concentrationValue) * Number(pricePerUnitValue);

    return formatDoubleNum(result);
  };

  protected getServicePricePerAreaValue = (stepId: string): string => {
    const servicePricePerAreaRowId = createRowId(stepId, EStepAttributeId.ServicePricePerArea);

    const stepsBuilderId = createExecutionTableBuilderId(
      this.executionStore.selectedZoneId,
      EComparisonTableName.ExecutionSteps
    );

    const servicePricePerAreaValue =
      this.getFactStepDoubleValue(stepsBuilderId, servicePricePerAreaRowId) ?? '0.00';

    return servicePricePerAreaValue;
  };
}

export default ExecutionCalculationsCellsService;
