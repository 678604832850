import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../utils/helpers/mobx';

import Styled from './ComparisonTableBuilder.styles';
import { ComparisonTableBuilderContext as Context } from './context';
import {
  useComparisonTableBuilderDataTestId as useDataTestId,
  useComparisonTableBuilderSliderControl as useSliderControl,
} from './hooks';
import { ComparisonTableBuilderContent as Content } from './containers';
import { ComparisonTableBuilderController as Controller } from './mobx/controllers';
import { ComparisonTableBuilderStore as Store } from './mobx/stores';
import { ComparisonTableBuilderHeaderContainer as HeaderContainer } from './containers/headers';

interface IProps {
  /**
   * Уникальный идентификатор таблицы [билдера].
   */
  builderId: string;
  /**
   * "Понятное" название для таблицы.
   * Данное название отображается как ключ в локаторах.
   */
  tableName: string;
  /**
   * Скрывать ли слайдер для таблицы.
   */
  isHideSliderControls?: boolean;
}

const ComparisonTableBuilder: FC<IProps> = ({ builderId, tableName, isHideSliderControls }) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const sliderControl = useSliderControl(isHideSliderControls);

  const header = store.getHeaderByBuilderId(builderId);

  useEffect(() => {
    return () => {
      controller.clearStore(builderId);
    };
  }, []);

  const getDataTestId = useDataTestId({ tableName });

  return (
    <Context.Provider value={{ builderId, tableName, sliderControl }}>
      <Styled.Wrapper {...getDataTestId()}>
        {header ? <HeaderContainer header={header} /> : null}

        <Content />
      </Styled.Wrapper>
    </Context.Provider>
  );
};

ComparisonTableBuilder.displayName = 'ComparisonTableBuilder';

export default observer(ComparisonTableBuilder);
