import { IFormConfig } from '../../../../../../../../../../../common/features/form/models';

const AUDIT_REPORT_FORM_KEY = 'auditReport';

interface IAuditReportForm {
  specialNotes: string;
  technologicalDeviation: string;
  generalComment: string;
}

const createAuditReportFormConfig = (): IFormConfig<IAuditReportForm> => {
  const auditReportFormConfig: IFormConfig<IAuditReportForm> = {
    formKey: AUDIT_REPORT_FORM_KEY,
    form: {
      specialNotes: '',
      technologicalDeviation: '',
      generalComment: '',
    },
    elements: {
      specialNotes: {
        name: 'specialNotes',
        type: 'textarea',
        order: 1,
        label: 'Особые отметки',
        textareaOptions: {
          maxLength: 500,
        },
      },
      technologicalDeviation: {
        name: 'technologicalDeviation',
        type: 'textarea',
        order: 2,
        label: 'Отклонение от технологии',
        textareaOptions: {
          maxLength: 500,
        },
      },
      generalComment: {
        name: 'generalComment',
        type: 'textarea',
        order: 3,
        label: 'Общий комментарий',
        textareaOptions: {
          maxLength: 500,
        },
      },
    },
  };

  return auditReportFormConfig;
};

export type { IAuditReportForm };

export { AUDIT_REPORT_FORM_KEY, createAuditReportFormConfig };
