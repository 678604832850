import styled, { css } from 'styled-components';

const textFormatNormal = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
`;

const textFormatBold = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

const textDisplay = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  max-width: 70%;
`;

const PlanLabel = styled.div<{ $isBold: boolean }>`
  ${({ $isBold }) => ($isBold ? textFormatBold : textFormatNormal)};

  ${textDisplay};

  color: #7e8b9d;
`;

const FactLabel = styled.div<{ $isBold: boolean }>`
  ${({ $isBold }) => ($isBold ? textFormatBold : textFormatNormal)};

  ${textDisplay};

  color: #151f32;
`;

const Wrapper = styled.div<{ $padding: string; $margin: string }>`
  width: 100%;

  display: flex;
  gap: 4px;

  padding: ${({ $padding }) => ($padding ? $padding : '0 0 12px')};
  margin: ${({ $margin }) => ($margin ? $margin : '0')};
`;

const StyledComparisonComparableCell = {
  PlanLabel,
  FactLabel,
  Wrapper,
};

export default StyledComparisonComparableCell;
