import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useExperimentSubsectionsInfo } from './hooks';

const ExperimentSubsections: FC = () => {
  const ExperimentSubsectionsContent = useExperimentSubsectionsInfo();

  return ExperimentSubsectionsContent;
};

ExperimentSubsections.displayName = 'ExperimentSubsections';

export default observer(ExperimentSubsections);
