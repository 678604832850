import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';
import { IUser } from '../../../../../../api/models/da-profile/users';

const createUserSelectOption = (user: IUser): ISelectOption => {
  return { label: user.fullName, value: user.id };
};

const createUserSelectOptionList = (userList: IUser[]): ISelectOption[] => {
  return userList.map<ISelectOption>(user => createUserSelectOption(user));
};

export { createUserSelectOption, createUserSelectOptionList };
