import { makeAutoObservable } from 'mobx';

import { provide } from '../../../utils/helpers/mobx';

@provide.singleton()
class SessionStore {
  private _isLogout = false;

  private _isLoggedIn = false;

  private _isLoading = true;

  private _selectedOrganizationId: string | null = null;

  private _notFirstLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLogout() {
    return this._isLogout;
  }

  get isLoggedIn() {
    return this._isLoggedIn;
  }

  get isLoading() {
    return this._isLoading;
  }

  get selectedOrganizationId() {
    return this._selectedOrganizationId;
  }

  get isNotFirstLoading() {
    return this._notFirstLoading;
  }

  setIsNotFirstLoading = (value: boolean): void => {
    this._notFirstLoading = value;
  };

  setIsLogout = (value: boolean): void => {
    this._isLogout = value;
  };

  setIsLoggedIn = (value: boolean): void => {
    this._isLoggedIn = value;
  };

  setIsLoading = (value: boolean): void => {
    this._isLoading = value;
  };

  setSelectedOrganizationId = (id: string): void => {
    this._selectedOrganizationId = id;
  };

  clearIsLogout = (): void => {
    this._isLogout = false;
  };

  clearIsLoggedIn = (): void => {
    this._isLoggedIn = false;
  };

  clearIsLoading = (): void => {
    this._isLoading = false;
  };

  clearSelectedOrganizationId = (): void => {
    this._selectedOrganizationId = null;
  };

  clearStore = (): void => {
    this.clearIsLogout();
    this.clearIsLoggedIn();
    this.clearIsLoading();
    this.setIsNotFirstLoading(false);

    this.clearSelectedOrganizationId();
  };
}

export default SessionStore;
