import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { IComparisonTableBuilderConfig } from '../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import { EComparisonTableName } from '../../../../../../../../../constants/features';
import { TotalComparisonColumnsService } from '../TotalComparisonColumnsService';
import { IExperimentTotalComparison } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentTotalComparisonTable/ExperimentTotalComparisonTable';
import { TotalComparisonHeader as Header } from '../../../components';
import { TotalComparisonRowsService } from '../TotalComparisonRowsService';

@provide.transient()
class TotalComparisonService {
  @lazyInject(TotalComparisonColumnsService)
  protected columnsService: TotalComparisonColumnsService;

  @lazyInject(TotalComparisonRowsService)
  protected rowsService: TotalComparisonRowsService;

  createTableConfig = ({
    headers,
    comparisonTables,
  }: IExperimentTotalComparison): IComparisonTableBuilderConfig => {
    this.rowsService.clearCellConfigsByColumnIdByRowI();

    const config: IComparisonTableBuilderConfig = {
      id: EComparisonTableName.TotalComparison,
      name: EComparisonTableName.TotalComparison,
      headerConfig: {
        customRenderConfig: {
          render: (header, columnsChildren, dataTestId) => (
            <Header dataTestId={dataTestId}>{columnsChildren}</Header>
          ),
        },
      },
      columnConfigList: this.columnsService.createColumnConfigList(headers),
      rowsGroupConfigList: this.rowsService.createBlockRowsGroupConfigList(comparisonTables),
    };

    config.cellConfigs = this.rowsService.cellConfigs;

    return config;
  };
}

export default TotalComparisonService;
