import { CellComponentWithTooltip } from '../../../../../../../common/components/ui';
import { FDCellComponent, StatusCellComponent, TypeCellComponent } from '../../components';

const getExperimentColumns = () => [
  {
    field: 'code',
    type: 'string',
    headerName: 'Номер',
    width: 70,
    editable: false,
  },
  {
    field: 'name',
    type: 'string',
    headerName: 'Название',
    width: '20%',
    cellRenderer: rowData => (
      <CellComponentWithTooltip cellInfo={rowData.name} id={rowData.id} dataTestId={'name'} />
    ),
    editable: false,
  },
  {
    field: 'seasonYear',
    type: 'string',
    headerName: 'Год',
    width: 40,
    editable: false,
  },
  {
    field: 'organization',
    type: 'string',
    headerName: 'Организация',
    width: '25%',
    cellRenderer: rowData => (
      <CellComponentWithTooltip
        cellInfo={rowData?.organization?.name}
        id={rowData.id}
        dataTestId={'organization'}
      />
    ),
    editable: false,
  },
  {
    field: 'type',
    type: 'string',
    headerName: 'Тип',
    width: 40,
    cellRenderer: rowData => <TypeCellComponent rowData={rowData} />,
    editable: false,
  },
  {
    field: 'culture',
    type: 'string',
    headerName: 'Культура',
    width: '20%',
    cellRenderer: rowData => (
      <CellComponentWithTooltip
        cellInfo={rowData?.culture?.name}
        id={rowData.id}
        dataTestId={'culture'}
      />
    ),
    editable: false,
  },
  {
    field: 'FD',
    type: 'string',
    headerName: 'ФО',
    width: 42,
    cellRenderer: rowData => <FDCellComponent rowData={rowData} />,
    editable: false,
  },
  {
    field: 'region',
    type: 'string',
    headerName: 'Регион',
    width: '15%',
    cellRenderer: rowData => (
      <CellComponentWithTooltip
        cellInfo={rowData?.region?.name}
        id={rowData.id}
        dataTestId={'region'}
      />
    ),
    editable: false,
  },
  {
    field: 'assigneeId',
    type: 'string',
    headerName: 'Ответственный',
    width: '20%',
    cellRenderer: rowData => (
      <CellComponentWithTooltip
        cellInfo={rowData?.assignee?.fullName}
        id={rowData.id}
        dataTestId={'assigneeId'}
      />
    ),
    editable: false,
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    width: 85,
    cellRenderer: rowData => <StatusCellComponent status={rowData.status} />,
    editable: false,
  },
];

export default getExperimentColumns;
