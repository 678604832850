import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { createClearedPath } from '../../../../../../../../common/utils/helpers/pathname';
import { EClearedPaths, EXISTING_NAME_LIST } from '../../../utils/constants';

const useClearedCurrentPath = (): { clearedCurrentPath: string } => {
  const { pathname } = useLocation();

  const clearedCurrentPath = useMemo(() => {
    if (pathname.includes(EClearedPaths.createAudit)) {
      return EClearedPaths.createAudit;
    }

    if (pathname.includes(EClearedPaths.editAudit)) {
      return EClearedPaths.editAudit;
    }

    if (pathname.includes(EClearedPaths.taskReportClearedPath)) {
      return EClearedPaths.taskReportClearedPath;
    }

    return createClearedPath(pathname, EXISTING_NAME_LIST);
  }, [pathname]);

  return { clearedCurrentPath };
};

export default useClearedCurrentPath;
