import { Typography } from '@farmlink/farmik-ui';
import { FC } from 'react';

import Styled from './InfoBlock.style';
import { TInfoBlockProps } from './InfoBlock.type';

const InfoBlock: FC<TInfoBlockProps> = ({ title, infoBody }) => {
  return (
    <Styled.Wrapper>
      <Styled.TitleWrapper>
        <Typography variant="h5">{title}</Typography>
      </Styled.TitleWrapper>
      <Styled.InfoWrapper>{infoBody}</Styled.InfoWrapper>
    </Styled.Wrapper>
  );
};

export default InfoBlock;
