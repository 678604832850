import { lazyInject, provide } from '../../../../../../../../../common/utils/helpers/mobx';
import { TasksService } from '../../../../../../../../../common/mobx/services/as-fields';
import {
  TCreateAggregateInstanceReq,
  TGenerateTaskReportDataRes,
} from '../../../../../../../../../../api';
import { AuditReportsHelpers } from '../../../AuditReport/utils/helpers';
import { createInstWithAttrValListReportsHelpers } from '../../../common/utils/helpers';
import TaskReportStore from '../stores/TaskReport.store';
import taskReportDynamicTableInstanceHelpers from '../../utils/helpers/dynamicTableInstanceHelpers/dynamicTableInstanceHelpers';
import StageHelpers from '../../utils/helpers/TaskReportStageHelpers/TaskReportStage.helpers';
import ChecklistsService from '../../../../../../../../../common/mobx/services/as-fields/ChecklistsService/Checklists.service';
import { ITask } from '../../../../../../../../../../api/models/as-fields/task/Task';
import { EChecklistType } from '../../../../../../../../../../api/models/as-fields/checklists/Checklist/Checklist.model';
import { TaskReportTabsService } from '../services';
import { TApiRequest } from '../../../../../../../../../common/mobx/services/axios/AxiosService/Axios.service.types';

@provide.transient()
class TaskReportController {
  @lazyInject(TaskReportStore)
  protected taskReportStore: TaskReportStore;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  @lazyInject(ChecklistsService)
  protected checklistsService: ChecklistsService;

  @lazyInject(TaskReportTabsService)
  protected tabsService: TaskReportTabsService;

  fetchTask = async (id: string): Promise<ITask> => {
    const { getTask } = this.tasksService;

    const { setSelectedTask } = this.taskReportStore;

    const fetchedTask = await getTask({ id });

    if (fetchedTask) {
      setSelectedTask(fetchedTask);

      this.tabsService.addAvailableTabList();

      return fetchedTask;
    }
  };

  fetchMonitoringReportData = async (taskId: string): Promise<TGenerateTaskReportDataRes> => {
    const { getTaskReportData } = this.tasksService;

    const payload: TApiRequest<'generateTaskReportData'> = {
      taskId,
      checklistType: this.taskReportStore.selectedTabId,
    };

    const reportData = await getTaskReportData(payload);

    if (reportData) {
      return reportData;
    }
  };

  createAggregateInstance = async (): Promise<string> => {
    const { selectedTask, selectedInstIdList } = this.taskReportStore;
    const { createAggregateInstance } = this.checklistsService;

    if (!selectedTask) {
      return;
    }

    const payload: TCreateAggregateInstanceReq = {
      taskId: selectedTask.id,
      checkListInstanceIds: selectedInstIdList,
    };

    const aggregateInstanceId = await createAggregateInstance(payload);

    if (aggregateInstanceId) {
      return aggregateInstanceId;
    }
  };

  getDynamicTableConfig = async (): Promise<void> => {
    const { selectedTask, setDynamicTableConfig, setSelectedInstId } = this.taskReportStore;

    const { createDynamicTableConfig } = AuditReportsHelpers;
    const { createInstListWithAttrValList } = createInstWithAttrValListReportsHelpers;
    const { createInstanceList } = taskReportDynamicTableInstanceHelpers;

    const reportData = await this.fetchMonitoringReportData(selectedTask.id);

    // Создаем конфиг таблицы
    const dynamicTableConfig = createDynamicTableConfig(
      'auditsTaskReport',
      reportData.table.stages
    );

    const instListWithAttrValList = createInstListWithAttrValList(
      reportData.instances,
      reportData.table.stages
    );

    const aggregatedPointsList = reportData.instances.find(
      instance => instance.type === 'AGGREGATE'
    ).aggregatedFrom;

    aggregatedPointsList.forEach(point => setSelectedInstId(point));

    // Создаем значения и колонки с КЗ для таблицы
    dynamicTableConfig.instances = createInstanceList(
      instListWithAttrValList,
      aggregatedPointsList
    );
    dynamicTableConfig.headColumn.stages.push(StageHelpers.createSystem());

    setDynamicTableConfig(dynamicTableConfig);
  };

  changeTab = (tabId: EChecklistType): void => {
    this.taskReportStore.setSelectedTabId(tabId);

    this.taskReportStore.clearDynamicTableConfig();
    this.taskReportStore.clearSelectedInstIdList();

    this.getDynamicTableConfig();
  };
}

export default TaskReportController;
