import {
  EExperimentStepEventType,
  IExperimentComment,
} from '../../../../models/as-fields/experiments/ExperimentComment';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetExperimentStepEventListReq = { stepId: string; typeEvent?: EExperimentStepEventType };

type TGetExperimentStepEventListRes = IExperimentComment<EExperimentStepEventType>[];

const getExperimentStepEventList: TApiRoute & {
  request: TGetExperimentStepEventListReq;
  response: TGetExperimentStepEventListRes;
} = {
  url: ({ stepId }) => `/api/as-fields/experimentSteps/${stepId}/events`,
  method: 'GET',
  request: {} as TGetExperimentStepEventListReq,
  response: {} as TGetExperimentStepEventListRes,
  headers: {},
};

export type { TGetExperimentStepEventListRes, TGetExperimentStepEventListReq };

export { getExperimentStepEventList };
