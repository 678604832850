import { EComparisonTableName as ETableName } from '../../../../../../../../../constants/features';
import { TComparisonTabs } from '../../../models';

const createComparisonTableBuilderId = (
  activeTab: TComparisonTabs,
  tableName: ETableName
): string => {
  return `${activeTab}--//--${tableName}`;
};

export default createComparisonTableBuilderId;
