import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../common/utils/helpers/mobx';
import { IAttachedFile } from '../../../../../../../api/models/as-fields/experiments';

@provide.singleton()
export class DocumentsTableStore {
  protected _attachedFiles: IAttachedFile[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get attachedFiles() {
    return this._attachedFiles;
  }

  setAttachedFiles = (attachedFiles: IAttachedFile[]) => {
    this._attachedFiles = attachedFiles;
  };

  clearAttachedFiles = () => {
    this._attachedFiles = [];
  };

  clearStore = () => {
    this.clearAttachedFiles();
  };
}
