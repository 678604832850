import { FC } from 'react';

import { TablePlugBuilder } from '../../../../../../../../common/features/TablePlugBuilder';
import { useDataTestId } from '../../../../../../../../common/utils/hooks/locators';

const ExperimentsListNoDataPlug: FC = () => {
  const getDataTestId = useDataTestId('experiments-table');

  const noDataPlugDataTestId = getDataTestId('no-data-plug')['data-test-id'];

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'search' },
        content: {
          title: 'По заданным параметрам ничего не найдено',
          description: 'Измените параметры запроса и попробуйте еще раз',
        },
      }}
      dataTestId={noDataPlugDataTestId}
    />
  );
};

ExperimentsListNoDataPlug.displayName = 'ExperimentsListNoDataPlug';

export default ExperimentsListNoDataPlug;
