import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useCreateExperimentGetContent } from './utils/hooks';

const CreateExperiment: FC = () => {
  const CreateExperimentContent = useCreateExperimentGetContent();

  return CreateExperimentContent;
};

CreateExperiment.displayName = 'CreateExperiment';

export default observer(CreateExperiment);
