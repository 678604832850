import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { IconNames } from '@farmlink/farmik-ui/dist/Icon';
import { Colors } from '@farmlink/farmik-ui';

import {
  EExperimentStatus,
  IExperiment,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { EDashboardRoute } from '../../../../../../../../../../routes/DashboardRoute';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { ContractSidePanelService } from '../../../../../../mobx/services';
import { AccessStore } from '../../../../../../../../../../../common/mobx/stores/AccessStore';
import ActionsMenu, {
  IMenuItem,
} from '../../../../../../../../../../../common/features/UI/ActionsMenu/ActionsMenu';
import { useDataTestIdV2 } from '../../../../../../../../../../../common/utils/hooks/locators';

import Styled from './ExperimentOptionsCell.styles';

interface IProps {
  rowData: IExperiment;
}

const ExperimentOptionsCell: FC<IProps> = ({ rowData }) => {
  const getDataTestId = useDataTestIdV2('experiment-options-cell');

  const contractSidePanelService = useStore(ContractSidePanelService);
  const { isAllowToEditExperiments } = useStore(AccessStore);
  const { generateTechTaskReport } = contractSidePanelService;

  const { status, canEditContract } = rowData;

  const navigate = useNavigate();

  const generateTechTask = async () => {
    try {
      const response = await generateTechTaskReport(rowData.id);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, rowData.name);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const menuConfig = useMemo<IMenuItem[]>(() => {
    const menuItems = [
      {
        icon: 'download' as IconNames,
        onClick: () => {
          generateTechTask();
        },
        colorScheme: {
          default: {
            background: Colors.primaryWhite,
            icon: Colors.black,
          },
          hover: {
            background: Colors.primaryWhite,
            icon: Colors.hoverGreen,
          },
        },
      },
    ];

    const isEditAllowed =
      (isAllowToEditExperiments || canEditContract) && status === EExperimentStatus.Draft;

    if (isEditAllowed) {
      menuItems.unshift({
        icon: 'edit' as IconNames,
        onClick: () => {
          navigate(`/${EDashboardRoute.Experiments}/${rowData.id}/attributes`);
        },
        colorScheme: {
          default: {
            background: Colors.primaryWhite,
            icon: Colors.black,
          },
          hover: {
            background: Colors.primaryWhite,
            icon: Colors.hoverGreen,
          },
        },
      });
    }

    return menuItems;
  }, [rowData, isAllowToEditExperiments, canEditContract]);

  return (
    <Styled.MenuContent {...getDataTestId()}>
      <ActionsMenu
        dataTestId={getDataTestId()['data-test-id']}
        menuConfig={menuConfig}
        dataId={rowData.id}
      />
    </Styled.MenuContent>
  );
};

ExperimentOptionsCell.displayName = 'ExperimentOptionsCell';

export default ExperimentOptionsCell;
