import styled from 'styled-components';

interface IWrapperProps {
  $borderType: string;
}

const Wrapper = styled.div<IWrapperProps>`
  padding-bottom: 16px;

  border-bottom: ${({ $borderType }) => `1px ${$borderType ?? 'solid'} #e9e6f0`};

  background-color: white;
`;

const StyledTableBuilderHeader = {
  Wrapper,
};

export default StyledTableBuilderHeader;
