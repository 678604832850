import React, { FC } from 'react';

import { useDataTestId } from '../../../../../../../../common/utils/hooks/locators';
import { TablePlugBuilder } from '../../../../../../../../common/features/TablePlugBuilder';

const ContractsListNoDataPlug: FC = () => {
  const getDataTestId = useDataTestId('contracts-table');

  const noDataPlugDataTestId = getDataTestId('no-data-plug')['data-test-id'];

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'search' },
        content: {
          title: 'По заданным параметрам ничего не найдено',
          description: 'Измените параметры запроса и попробуйте еще раз',
        },
      }}
      dataTestId={noDataPlugDataTestId}
    />
  );
};

ContractsListNoDataPlug.displayName = 'ContractsListNoDataPlug';

export default ContractsListNoDataPlug;
