import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useCreateAuditGetContent } from './utils/hooks';

const CreateAudit: FC = () => {
  const CreateAuditGetContent = useCreateAuditGetContent();

  return CreateAuditGetContent;
};

CreateAudit.displayName = 'CreateAudit';

export default observer(CreateAudit);
