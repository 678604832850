import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { TableBuilderController } from '../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { AuditsListConfigsService } from '../../services';
import { IAuditsFilters } from '../../../../AuditsFilters/models';
import { EAuditsFiltersBuilderId, EAuditsTableBuilderId } from '../../../../../constants/configs';
import { TableBuilderStore } from '../../../../../../../../common/features/TableBuilder/mobx/stores';
import { ExperimentStepsService } from '../../../../../../../../common/mobx/services/as-fields';
import { TableFiltersBuilderController } from '../../../../../../../../common/features/TableFiltersBuilder/mobx/controllers';
import { TApiRequest } from '../../../../../../../../common/mobx/services/axios/AxiosService/Axios.service.types';
import { EExperimentStepType } from '../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';

@provide.transient()
class AuditsListController {
  @lazyInject(AuditsListConfigsService)
  protected configService: AuditsListConfigsService;

  @lazyInject(ExperimentStepsService)
  protected experimentStepsService: ExperimentStepsService;

  @lazyInject(TableBuilderStore)
  protected tableBuilderStore: TableBuilderStore;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<IAuditsFilters>;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  initiateTable = (): void => {
    const config = this.configService.createConfig();

    this.tableBuilderController.initiateTable(config);

    this.tableBuilderController.addPaginationScrollEvent(EAuditsTableBuilderId.Audits, () => {
      return this.fetchAuditList(true);
    });
  };

  /**
   * Метод получения списка наблюдений согласно фильтрам.
   * @param payload - выбранные фильтры.
   * @param isSavePagination - флаг, который говорит о том, сохраняем мы пагинацию или нет.
   * Когда активируется запрос после обновленных фильтров, то обнуляем пагинацию.
   */
  fetchAuditList = async (isSavePagination?: boolean): Promise<void> => {
    const appliedFilters = this.tableFiltersBuilderController.getAppliedFilters(
      EAuditsFiltersBuilderId.Audits
    );

    if (!isSavePagination) {
      this.tableBuilderController.addCurrentPage(EAuditsTableBuilderId.Audits, 0);
      this.tableBuilderController.addTotalPages(EAuditsTableBuilderId.Audits, 0);
    }

    const currentPage = this.tableBuilderStore.getCurrentPage(EAuditsTableBuilderId.Audits);

    const payload: TApiRequest<'getExperimentStepCompleteList'> = {
      ...appliedFilters,
      type: EExperimentStepType.Audit,
    };

    await this.experimentStepsService.getExperimentStepCompleteList(payload, {
      query: {
        page: isSavePagination ? currentPage : 0,
        size: 25,
      },
      actions: {
        showLoader: () => {
          this.tableBuilderController.showLoader(EAuditsTableBuilderId.Audits);
        },
        hideLoader: () => {
          this.tableBuilderController.hideLoader(EAuditsTableBuilderId.Audits);
        },
        success: response => {
          this.tableBuilderController.addTotalPages(
            EAuditsTableBuilderId.Audits,
            response.totalPages
          );

          this.tableBuilderController.addRowsToRowsGroupListById(
            EAuditsTableBuilderId.Audits,
            EAuditsTableBuilderId.Audits,
            response.content,
            {
              isClearPreviousList: !isSavePagination,
            }
          );

          if (response.content.length > 0 || Object.keys(appliedFilters).length > 0) {
            this.tableBuilderController.hideDefaultPlug(EAuditsTableBuilderId.Audits);
          } else {
            this.tableBuilderController.showDefaultPlug(EAuditsTableBuilderId.Audits);
          }
        },
      },
    });
  };
}

export default AuditsListController;
