import { FC, memo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { useShowTooltip } from '../../../../../../../../../../../../../common/utils/hooks';
import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { useDataTestId } from '../../../../../../../../../../../../../common/utils/hooks/locators';

import Styled from './ZoneListCellWithDisabled.styles';
interface IZoneListCellWithDisabledProps {
  rowData: ICultureZoneCell;
  text: string;
  dataTestId: string;
}

const ZoneListCellWithDisabled: FC<IZoneListCellWithDisabledProps> = ({
  text,
  rowData: { fwExpCultureZone },
  dataTestId,
}) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>(text);

  const getDataTestId = useDataTestId(`zones-table-${dataTestId}`);

  return (
    <AutoTooltip position="top" content={text} disabled={!showTooltip}>
      <Styled.Wrapper ref={ref} $isDisabled={!fwExpCultureZone?.isSelected} {...getDataTestId()}>
        {text}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

ZoneListCellWithDisabled.displayName = 'ZoneListCellWithDisabled';

export default memo(ZoneListCellWithDisabled);
