import styled from 'styled-components';

interface IContentProps {
  $isNeedToShowPlug: boolean;
}

const Wrapper = styled.div`
  margin: 20px 20px 0 24px;
  padding: 20px 24px 16px 24px;
  background: #ffffff;
  border-radius: 16px;
`;

const Content = styled.div<IContentProps>`
  max-height: ${({ $isNeedToShowPlug }) =>
    $isNeedToShowPlug ? 'auto' : 'calc(86vh - var(--filter-bar-height))'};
  overflow-y: ${({ $isNeedToShowPlug }) => ($isNeedToShowPlug ? 'hide' : 'scroll')};
  height: ${({ $isNeedToShowPlug }) => ($isNeedToShowPlug ? '86vh' : 'auto')};
`;

const StyledExperimentsContentBody = {
  Wrapper,
  Content,
};

export default StyledExperimentsContentBody;
