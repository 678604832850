import { ITask } from '../../../models/as-fields/task/Task';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TGetTaskReq = {
  id: string;
};

type TGetTaskRes = ITask;

const getTask: TApiRoute & {
  request: TGetTaskReq;
  response: TGetTaskRes;
} = {
  url: ({ id }) => `/api/as-fields/task/${id}`,
  method: 'GET',
  request: {} as TGetTaskReq,
  response: {} as TGetTaskRes,
  headers: {},
};

export type { TGetTaskReq, TGetTaskRes };

export { getTask };
