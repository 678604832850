import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -12px;
`;

const NoInfo = styled.div`
  margin-bottom: auto;
`;

const ConsumptionCellStyled = {
  Wrapper,
  NoInfo,
};

export default ConsumptionCellStyled;
