import { EExperimentStatus } from '../../../../../../api/models/as-fields/experiments';

const experimentStatusListOptions = [
  { label: 'В работе', value: EExperimentStatus.InProgress, type: 'status' },
  { label: 'ТЗ на согласовании', value: EExperimentStatus.InApproval, type: 'status' },
  { label: 'Завершен', value: EExperimentStatus.Completed, type: 'status' },
  { label: 'Черновик', value: EExperimentStatus.Draft, type: 'status' },
  { label: 'ТЗ согласовано', value: EExperimentStatus.Approved, type: 'status' },
  { label: 'Отчет согласован', value: EExperimentStatus.FactAppruved, type: 'status' },
  { label: 'Закрыт', value: EExperimentStatus.FullCompleted, type: 'status' },
  { label: 'Отменен', value: EExperimentStatus.Canceled, type: 'status' },
];

export default experimentStatusListOptions;
