import { useModal } from '@farmlink/farmik-ui';
import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';

import responseErrorModalConfig from '../../../configs/modals/errors/response/responseErrorModal.config';

interface IShowErrorModal {
  (error: AxiosError): void;
}

interface IUseErrorModalReturn {
  showErrorModal: IShowErrorModal;
}

interface IUseErrorModal {
  (): IUseErrorModalReturn;
}

const useErrorModal: IUseErrorModal = () => {
  const { key, modalName, configList } = responseErrorModalConfig;

  const { registerModalList, openModalByModalId } = useModal();

  useEffect(() => {
    registerModalList(configList, key);
  }, []);

  const showErrorModal = useCallback<IShowErrorModal>(error => {
    // eslint-disable-next-line no-console
    console.log('THIS_IS_RECEIVED_ERROR', error);

    openModalByModalId(modalName.CommonError);
  }, []);

  return {
    showErrorModal,
  };
};

export type { IShowErrorModal };

export default useErrorModal;
