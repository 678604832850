import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { ObservationStore } from '../../stores';
import { ExperimentStepsService } from '../../../../../../../../common/mobx/services/as-fields';

@provide.singleton()
class ObservationController {
  @lazyInject(ExperimentStepsService)
  protected experimentStepsService: ExperimentStepsService;

  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(ObservationStore)
  observationStore: ObservationStore;

  deleteAudit = async (id: string): Promise<void> => {
    const { deleteExperimentStep } = this.experimentStepsService;
    const { deleteAudit } = this.observationStore;

    const isSuccess = await deleteExperimentStep({ experimentStepId: id });

    if (isSuccess) {
      deleteAudit(id);
    }
  };
}

export default ObservationController;
