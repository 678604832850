import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { priceType } from '../../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/calculation/containers/Calculation/constants';
import { formatNumValue } from '../../../../../../utils/helpers/numbers';
import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './CostCellTitle.style';

interface ICostCellTitleProps {
  totalCost: number;
  dataTestId: string;
}

const CostCellTitle: FC<ICostCellTitleProps> = ({ totalCost, dataTestId }) => {
  const { ref, showTooltip } = useShowTooltip();

  const valueToDisplay = `${formatNumValue(totalCost)} ${priceType.rublesOnGa}`;

  return (
    <AutoTooltip
      content={`${totalCost} ${priceType.rublesOnGa}`}
      position="top"
      disabled={!showTooltip}
    >
      <Styled.Header ref={ref} data-test-id={`cost-cell-header-${dataTestId}`}>
        {valueToDisplay}
      </Styled.Header>
    </AutoTooltip>
  );
};

CostCellTitle.displayName = 'CostCellTitle';

export default memo(CostCellTitle);
