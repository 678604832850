export const tabContent = [
  {
    label: 'Техоперации',
    id: 'techOperation',
    size: 'medium' as const,
  },
  {
    label: 'Действующие вещества',
    id: 'activeSubstance',
    size: 'medium' as const,
  },
  {
    label: 'Экономика',
    id: 'economy',
    size: 'medium',
  },
];
