import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IExperimentFactTable } from '../../../models/as-fields/experiments/ExperimentFactTable';

type TRequest = {
  id: string;
};

type TResponse = IExperimentFactTable;

export const getExperimentFactTable: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/experiment/${id}/fact`,
  method: 'GET',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};
