/* eslint-disable spaced-comment */
//@ts-nocheck

import deleteIcon from './delete.svg';
import editIcon from './edit.svg';
import arrowIcon from './arrow.svg';
import plusIcon from './plus.svg';
import menuIcon from './menu.svg';

export default {
  delete: deleteIcon,
  arrow: arrowIcon,
  edit: editIcon,
  plus: plusIcon,
  menu: menuIcon,
};
