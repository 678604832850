import { FC } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';

import Styled from './ComparisonColumn.styles';

interface IProps {
  name: string;
  dataTestId: string;
  isMain?: boolean;
}

const ComparisonColumn: FC<IProps> = ({ name, dataTestId }) => {
  const { ref: nameRef, isShowTooltip: isShowNameTooltip } = useShowTooltip<HTMLDivElement>();

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <AutoTooltip content={name} disabled={!isShowNameTooltip}>
        <Styled.Name ref={nameRef} {...getDataTestId('name')}>
          {name}
        </Styled.Name>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

ComparisonColumn.displayName = 'ComparisonColumn';

export default ComparisonColumn;
