import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../assets/styles/colors';

const { GeneralBlack } = ECommonColor;

const Name = styled.div<{ $isTitle?: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${GeneralBlack};

  ${({ $isTitle }) =>
    $isTitle &&
    css`
      font-weight: 600;
    `}
`;

const InstanceValueWrapper = styled.div`
  overflow: hidden;
`;

const Wrapper = styled.div<{ $isTitle?: boolean }>`
  display: grid;
  grid-template-columns: 240px auto;

  ${({ $isTitle }) =>
    $isTitle &&
    css`
      font-weight: 600;
      margin-left: -12px;
      padding: 11px 0 11px 12px;
      border-radius: 12px;
      background-color: rgba(231, 241, 253, 0.3);
    `}
`;

const StyledAttribute = {
  Name,
  InstanceValueWrapper,
  Wrapper,
};

export default StyledAttribute;
