import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import { TGetGeoUnitsReq } from '../../../../../../api';
import { IServiceOptions, IServiceReturnData } from '../../../../models/service';
import { createGeoUnitsSelectOption } from '../../../../utils/helpers/selectOptions';
import { IGeoUnit } from '../../../../../../api/models/as-fields/geo-unit';

@provide.singleton()
class GeoUnitsService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getGeoUnits = async (
    payload: TGetGeoUnitsReq,
    options?: IServiceOptions
  ): Promise<IServiceReturnData<IGeoUnit>> => {
    const { getGeoUnits } = this.axiosService.api;

    try {
      const fetchedGeoUnits = await getGeoUnits(payload, { omit: ['id'] });

      const data: IServiceReturnData<IGeoUnit> = {
        data: fetchedGeoUnits,
      };

      if (options?.isCreateSelectOptions) {
        data.selectOptionList = [createGeoUnitsSelectOption(fetchedGeoUnits)];
      }

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default GeoUnitsService;
