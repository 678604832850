import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 48px 0;
`;

const StyledAuditReportPlug = {
  Wrapper,
};

export default StyledAuditReportPlug;
