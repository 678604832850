import { Colors } from '@farmlink/farmik-ui';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  gap: 14px;
  margin-left: 8px;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;

      path {
        fill: ${Colors.secondaryDarkGray};
        pointer-events: none;
      }
    `}

  &:hover path {
    fill: ${({ $disabled }) => (!$disabled ? Colors.accentGreen : Colors.secondaryDarkGray)};
  }
`;

const StyledZoneListActionsCell = {
  Wrapper,
};

export default StyledZoneListActionsCell;
