import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { ISeason } from '../../../models/as-fields/seasons';

type TGetSeasonListReq = {
  organizationId: string;
  startYear?: number;
  endYear?: number;
  allowStub?: boolean;
  sort?: 'ASC' | 'DESC';
  limit?: number;
  withFieldsCount?: boolean;
};

type TGetSeasonListRes = ISeason[];

const getSeasonList: TApiRoute & {
  request: TGetSeasonListReq;
  response: TGetSeasonListRes;
} = {
  url: () => `/api/as-fields/seasons/fetch`,
  method: 'POST',
  request: {} as TGetSeasonListReq,
  response: {} as TGetSeasonListRes,
  headers: {},
};

export type { TGetSeasonListReq, TGetSeasonListRes };

export { getSeasonList };
