import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import { TGetFieldReq } from '../../../../../../api';
import { IField } from '../../../../../../api/models/as-fields/fields/Field.model';

@provide.singleton()
class FieldsService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getField = async (payload: TGetFieldReq): Promise<IField> => {
    const { getField } = this.axiosService.api;

    try {
      const field = await getField(payload, { omit: ['id'] });

      return field;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default FieldsService;
