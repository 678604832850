import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledComparisonTableBuilderClearedRowsGroup = {
  Wrapper,
};

export default StyledComparisonTableBuilderClearedRowsGroup;
