import { omit, pick } from 'lodash';

import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import {
  IExperimentFactDataNutritionHistoryItemUpdate,
  IExperimentFactDataNutritionHistoryProtectionItemUpdate,
  IExperimentFactDataStepUpdate,
  IExperimentFactDataZoneUpdate,
} from '../../../../../../../../../../../api/models/as-fields/experiments';
import ExecutionStore from '../../stores/ExecutionStore/Execution.store';
import { toNumber } from '../../../../../../../../../../common/utils/helpers/numbers';

@provide.transient()
class ExecutionInputsService {
  @lazyInject(ExecutionStore)
  protected executionStore: ExecutionStore;

  changeZone = (zoneData: Partial<IExperimentFactDataZoneUpdate>): void => {
    const { selectedZoneId } = this.executionStore;

    this.executionStore.setZoneForUpdate(
      {
        cultureZoneExperimentId: selectedZoneId,
        ...zoneData,
      },
      {
        isUpdate: true,
      }
    );
  };

  changeStep = (stepId: string, stepData: Partial<IExperimentFactDataStepUpdate>): void => {
    const { selectedZoneId } = this.executionStore;

    if (!this.executionStore.hasZoneForUpdate(selectedZoneId)) {
      this.executionStore.setZoneForUpdate(
        {
          cultureZoneExperimentId: selectedZoneId,
        },
        { isUpdate: true }
      );
    }

    this.executionStore.setStepForUpdate(
      selectedZoneId,
      {
        id: stepId,
        ...stepData,
      },
      {
        isUpdate: true,
      }
    );
  };

  changeFertilizer = (
    stepId: string,
    changedFertilizer: IExperimentFactDataNutritionHistoryItemUpdate
  ): void => {
    const { selectedZoneId } = this.executionStore;

    if (!this.executionStore.hasZoneForUpdate(selectedZoneId)) {
      this.executionStore.setZoneForUpdate(
        {
          cultureZoneExperimentId: selectedZoneId,
        },
        { isUpdate: true }
      );
    }

    if (!this.executionStore.hasStepForUpdate(selectedZoneId, stepId)) {
      this.executionStore.setStepForUpdate(
        selectedZoneId,
        {
          id: stepId,
        },
        { isUpdate: true }
      );
    }

    this.executionStore.setItemForUpdate(selectedZoneId, stepId, changedFertilizer, {
      isUpdate: true,
    });
  };

  changeProtection = (
    stepId: string,
    changedProtection: IExperimentFactDataNutritionHistoryProtectionItemUpdate
  ): void => {
    const { selectedZoneId } = this.executionStore;

    if (!this.executionStore.hasZoneForUpdate(selectedZoneId)) {
      this.executionStore.setZoneForUpdate(
        {
          cultureZoneExperimentId: selectedZoneId,
        },
        { isUpdate: true }
      );
    }

    if (!this.executionStore.hasStepForUpdate(selectedZoneId, stepId)) {
      this.executionStore.setStepForUpdate(
        selectedZoneId,
        {
          id: stepId,
        },
        { isUpdate: true }
      );
    }

    this.executionStore.setProtectionItemForUpdate(selectedZoneId, stepId, changedProtection, {
      isUpdate: true,
    });
  };

  getFormattedZoneList = (): IExperimentFactDataZoneUpdate[] => {
    const { zoneForUpdateList } = this.executionStore;

    return zoneForUpdateList.reduce<IExperimentFactDataZoneUpdate[]>((formattedList, zone) => {
      const numberValues = omit(zone, ['cultureZoneExperimentId', 'steps']);
      const formattedNumberValues = this.formatNumberValues(numberValues);

      const formattedZone: IExperimentFactDataZoneUpdate = {
        ...zone,
        ...formattedNumberValues,
      };

      const hasStepList = this.executionStore.hasStepForUpdateList(zone.cultureZoneExperimentId);

      if (hasStepList) {
        formattedZone.steps = this.getFormattedStepList(zone.cultureZoneExperimentId);
      }

      formattedList.push(formattedZone);

      return formattedList;
    }, []);
  };

  protected getFormattedStepList = (zoneId: string): IExperimentFactDataStepUpdate[] => {
    const stepForUpdateList = this.executionStore.getStepForUpdateList(zoneId);

    return stepForUpdateList.reduce((formattedList, step) => {
      const numberValues = pick(step, ['servicePricePerArea']);
      const formattedNumberValues = this.formatNumberValues(numberValues);

      if (step.id.includes('new_item')) {
        delete step.id;
      }

      const formattedStep: IExperimentFactDataStepUpdate = {
        ...step,
        ...formattedNumberValues,
      };

      const hasItemList = this.executionStore.hasItemForUpdateList(zoneId, step.id);

      const hasProtectionItemList = this.executionStore.hasProtectionItemForUpdateList(
        zoneId,
        step.id
      );

      if (hasItemList) {
        formattedStep.nutritionHistoryItems = this.getFormattedItemList(zoneId, step.id);
      }

      if (hasProtectionItemList) {
        formattedStep.protectionItems = this.getFormattedProtectionItemList(zoneId, step.id);
      }

      formattedList.push(formattedStep);

      return formattedList;
    }, []);
  };

  protected getFormattedItemList = (
    zoneId: string,
    stepId: string
  ): IExperimentFactDataNutritionHistoryItemUpdate[] => {
    const itemForUpdateList = this.executionStore.getItemForUpdateList(zoneId, stepId);

    return itemForUpdateList.reduce((formattedList, item) => {
      const numberValues = omit(item, ['id', 'fertilizerId']);
      const formattedNumberValues = this.formatNumberValues(numberValues);

      const formattedItem: IExperimentFactDataNutritionHistoryItemUpdate = {
        ...item,
        ...formattedNumberValues,
      };

      formattedList.push(formattedItem);

      return formattedList;
    }, []);
  };

  protected getFormattedProtectionItemList = (
    zoneId: string,
    stepId: string
  ): IExperimentFactDataNutritionHistoryProtectionItemUpdate[] => {
    const itemForUpdateList = this.executionStore.getProtectionItemForUpdateList(zoneId, stepId);

    return itemForUpdateList.reduce((formattedList, item) => {
      const numberValues = omit(item, ['id', 'protectionId', 'unitOfMeasureId']);
      const formattedNumberValues = this.formatNumberValues(numberValues);

      const formattedItem: IExperimentFactDataNutritionHistoryProtectionItemUpdate = {
        ...item,
        ...formattedNumberValues,
      };

      formattedList.push(formattedItem);

      return formattedList;
    }, []);
  };

  protected formatNumberValues = <V = any,>(initialValues: V): V => {
    const entryList = Object.entries(initialValues);

    return entryList.reduce<V>((values, [key, value]) => {
      values[key] = value === '' ? '' : toNumber(value);

      return values;
    }, {} as V);
  };
}

export default ExecutionInputsService;
