import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../common/assets/styles/colors';
import alertIndicator from '../../modules/Execution/assets/icons/alert_indicator.svg';

const { White, BorderHover } = ECommonColor;

const Wrapper = styled.div<{ $IsSelected: boolean }>`
  position: relative;
  border: 2px
    ${({ theme, $IsSelected }) =>
      $IsSelected ? theme.Colors.greenInfo : theme.Colors.secondaryLight}
    solid;
  border-radius: 12px;
  background-color: ${White};
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  width: 200px;
  cursor: pointer;

  :hover {
    border: 1px
      ${({ $IsSelected, theme }) => ($IsSelected ? theme.Colors.accentPositiveHover : BorderHover)}
      solid;
  }
`;

const Title = styled.span`
  ${({ theme }) => theme.Typography.schemas.bodySubAccent}
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block;
  overflow: hidden;
`;

const Description = styled.span`
  ${({ theme }) => theme.Typography.schemas.note}
  margin-left: 4px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SectionIcon = styled.img`
  width: 10px;
  height: 10px;
`;

const InfoContainer = styled.div`
  margin-right: 8px;
  width: 100%;
`;

const CheckedContainer = styled.div`
  background-color: ${({ theme }) => theme.Colors.secondaryDefault};
  border-radius: 12px;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckedIcon = styled.img``;

const ErrorBudget = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  background-image: url(${alertIndicator});
`;

const StyledExperimentZonesCarouselItem = {
  Wrapper,
  Title,
  Description,
  DescriptionContainer,
  SectionIcon,
  InfoContainer,
  CheckedIcon,
  CheckedContainer,
  ErrorBudget,
};

export default StyledExperimentZonesCarouselItem;
