import styled from 'styled-components';

const NameWrapper = styled.div`
  flex: 1 1 auto;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const BadgeWrapper = styled.div`
  flex: 0 0 36px;
  div {
    width: 36px;
    height: 36px;

    img {
      width: 36px;
      height: 36px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 16px;

  overflow: hidden;
`;

const StyledContractsListOrganizationCell = {
  NameWrapper,
  BadgeWrapper,
  Wrapper,
};

export default StyledContractsListOrganizationCell;
