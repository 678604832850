import { FC } from 'react';
import { observer } from 'mobx-react';

import { AuditsFilters } from '../AuditsFilters';
import { AuditsList } from '../AuditsList';
import { ListingPage } from '../../../../../common/features/UI/pages/ListingPage';
import { usePageContextualHelp } from '../../../../../common/hooks/usePageContextualHelp';
import { EContextualParentPath } from '../../../../../common/constants/contextualPath';

const Audits: FC = () => {
  usePageContextualHelp(EContextualParentPath.Audits);

  return <ListingPage id={'audits'} filters={<AuditsFilters />} list={<AuditsList />} />;
};

Audits.displayName = 'Audits';

export default observer(Audits);
