import { observer } from 'mobx-react';
import { FC } from 'react';

import { useComparisonTableBuilderContext as useContext } from '../../../context';
import { useStore } from '../../../../../utils/helpers/mobx';
import { ComparisonTableBuilderStore as Store } from '../../../mobx/stores';
import { useSortBy } from '../../../../../utils/hooks/useSortBy';
import { ComparisonTableBuilderGrid as Grid } from '../../ComparisonTableBuilderGrid';
import { ComparisonTableBuilderColumnContainer as ColumnContainer } from '../../ComparisonTableBuilderColumnContainer';
import { IComparisonTableBuilderHeader as IHeader } from '../../../models/data';
import { ComparisonTableBuilderDashedHeader as DashedHeader } from '../../../components/headers';
import { ComparisonTableBuilderController } from '../../../mobx/controllers';

interface IProps {
  header: IHeader;
}

const ComparisonTableBuilderHeaderAutoRenderContainer: FC<IProps> = ({ header }) => {
  const controller = useStore(ComparisonTableBuilderController);

  const context = useContext();

  const fixedColumnList = useSortBy(
    controller.getColumnList(context.builderId, { isOnlyFixed: true })
  );

  const scrollableColumnList = useSortBy(controller.getColumnList(context.builderId));

  switch (header.autoRenderConfig.preset) {
    case 'dashed':
      return (
        <DashedHeader
          columnsChildren={
            <Grid
              fixedChildren={fixedColumnList.map(column => (
                <ColumnContainer key={column.id} column={column} />
              ))}
              scrollableChildren={scrollableColumnList.map(column => (
                <ColumnContainer key={column.id} column={column} />
              ))}
              isHeader
            />
          }
        />
      );

    default:
      return <></>;
  }
};

ComparisonTableBuilderHeaderAutoRenderContainer.displayName =
  'ComparisonTableBuilderHeaderAutoRenderContainer';

export default observer(ComparisonTableBuilderHeaderAutoRenderContainer);
