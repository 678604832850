import { TableBuilderStore as Store } from '../../stores';
import { ITableBuilderCell as ICell } from '../../../models/data';
import { ITableBuilderCellConfig as ICellConfig } from '../../../models/configs';
import { lazyInject, provide } from '../../../../../utils/helpers/mobx';

@provide.transient()
class TableBuilderCellsService {
  @lazyInject(Store)
  protected store: Store;

  addCellList = (
    builderId: string,
    configList: ICellConfig[],
    options?: { isClearPreviousList?: boolean }
  ): void => {
    const cellList = this.createCellList(builderId, configList);

    this.store.setCellList(builderId, cellList, options);
  };

  createCellList = (builderId: string, configList: ICellConfig[]): ICell[] => {
    const cellList = configList.map((config, index) =>
      this.createCell(builderId, config, index + 1)
    );

    return cellList;
  };

  createCell = (builderId: string, config: ICellConfig, order: number): ICell => {
    const cell: ICell = {
      ...config,
      builderId,
      order,
      renderType: null,
    };

    if (config?.autoRenderConfig) {
      cell.renderType = 'auto';
    } else if (config?.customRenderConfig) {
      cell.renderType = 'custom';
    }

    return cell;
  };
}

export default TableBuilderCellsService;
