import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;
  padding-bottom: 12px;
  border-bottom: 1px dashed ${Colors.borderGray};
`;

const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  letter-spacing: -0.15px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.accentGreen};
  height: 20px;
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const FileInput = styled.input`
  display: none;
`;

const StyledDocumentsHeader = { Wrapper, Button, FileInput, TitleWrapper };

export default StyledDocumentsHeader;
