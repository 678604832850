import { FC, memo } from 'react';

import { EMessageStyleType } from '../../../../../common/features/ChatJournal/models/common';

import { StatusPillFactApproved as StyledStatusPill } from './StatusPillFactApproved.styles';

const StatusPillFactApproved: FC<{ children; isSelected?: boolean }> = ({
  children,
  isSelected,
}) => {
  return (
    <StyledStatusPill $colorScheme={EMessageStyleType.Info} $isSelected={isSelected}>
      Отчет согласован {children}
    </StyledStatusPill>
  );
};

export default memo(StatusPillFactApproved);
