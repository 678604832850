import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../common/utils/helpers/mobx';
import { generateAuditsPath } from '../../utils/helpers';
import TableFiltersBuilder from '../../../../../common/features/TableFiltersBuilder/TableFiltersBuilder';
import { EAuditsFiltersBuilderId } from '../../constants/configs';
import { AuditsListController } from '../AuditsList/mobx/controllers';
import {
  useTableFilters,
  useTableFiltersResize,
} from '../../../../../common/features/TableFiltersBuilder/hooks';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../common/constants/contextualPath';

import { AuditsFiltersController } from './mobx/controllers';

const AuditsFilters: FC = () => {
  const auditsFiltersController = useStore(AuditsFiltersController);
  const auditsListController = useStore(AuditsListController);

  const navigate = useNavigate();
  useTableFiltersResize(EAuditsFiltersBuilderId.Audits, { isNeedToCheckSidebar: true });

  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.Audits,
    ContextualPaths.AuditsHeaderTitle
  );

  const handleActionButtonClick = useCallback(() => {
    navigate(
      generateAuditsPath('createAudit', {
        auditId: 'create',
      })
    );
  }, []);

  useEffect(() => {
    auditsFiltersController.initiateConfig(handleActionButtonClick);
  }, []);

  useEffect(() => {
    auditsFiltersController.addOptionList();
  }, []);

  useEffect(() => {
    if (!ContextualHelpIcon) return;

    auditsFiltersController.addContextualHelpIcon(ContextualHelpIcon);
  }, [ContextualHelpIcon]);

  useTableFilters(EAuditsFiltersBuilderId.Audits, () => auditsListController.fetchAuditList());

  return <TableFiltersBuilder builderId={EAuditsFiltersBuilderId.Audits} />;
};

AuditsFilters.displayName = 'AuditsFilters';

export default observer(AuditsFilters);
