import { FC, memo } from 'react';

import { _Attribute } from '../../../models';
import { ComparisonTableImagesAttributeValueContainer as ImagesAttributeValueContainer } from '../../../containers/attributeValues';

import Styled from './ComparisonTableImagesAttribute.styles';

interface IProps {
  extendedAttribute: _Attribute;
  isEnableEditing: boolean;
}

const ComparisonTableImagesAttribute: FC<IProps> = ({ extendedAttribute, isEnableEditing }) => {
  const { tableId, attributeValueIdList } = extendedAttribute;

  return (
    <Styled.Wrapper>
      {attributeValueIdList.map((attributeValueId, index) => (
        <ImagesAttributeValueContainer
          key={attributeValueId}
          tableId={tableId}
          attributeValueId={attributeValueId}
          isEnableEditing={isEnableEditing}
          order={index + 1}
        />
      ))}
    </Styled.Wrapper>
  );
};

ComparisonTableImagesAttribute.displayName = 'ComparisonTableImagesAttribute';

export default memo(ComparisonTableImagesAttribute);
