enum EExperimentsTableBuilderId {
  Experiments = 'experiments',
  HistoryActiveSubstances = 'experiments__history__active-substances',
  HistoryTechOperations = 'experiments__history__tech-operations',
  CalculationActiveSubstances = 'experiments__calculation__active-substances',
  CalculationTechOperations = 'experiments__calculation__tech-operations',
  CalculationEconomy = 'experiments__calculation__economy',
  CalculationAgrochemical = 'experiments__calculation__agrochemical',
  Documents = 'experiments__documents',
  Zones = 'experiments_zones',
}

export default EExperimentsTableBuilderId;
