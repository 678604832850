import React, { FC, memo, useMemo } from 'react';

import Styled from './TableBodyCell.styles';
import { TTableBodyCellProps } from './TableBodyCell.types';

const TableBodyCellComponent: FC<TTableBodyCellProps> = ({
  rowData,
  headData,
  actions,
  changeAdditionalRowsState,
  config,
  padding,
}) => {
  const fieldValue = useMemo(
    () => (headData.calculateValue ? headData.calculateValue(rowData) : rowData[headData.field]),
    [rowData]
  );

  return (
    <Styled.TableCell data-test-id="table-cell">
      <Styled.RowCellWrapper
        key={`${rowData.id + headData.field}`}
        data-test-id={`table-cell-wrapper-${headData.field}`}
        $isNoOverFlow={headData?.styles?.isNoOverflow}
        contentEditable={headData.editable}
        $align={headData.alignItem}
        padding={padding}
        $removeRightMargin={headData?.styles?.isRemoveLastCellInRowMargin}
      >
        {headData.cellRenderer
          ? headData.cellRenderer(rowData, { ...actions, changeAdditionalRowsState }, config)
          : fieldValue}
      </Styled.RowCellWrapper>
    </Styled.TableCell>
  );
};

const TableBodyCell = memo(TableBodyCellComponent);

TableBodyCell.displayName = 'TableBodyCell';

export default TableBodyCell;
