import { IExperimentCultureZone } from '../../../../../../../../../../../api/models/as-fields/experiments';

interface ICarouselItem {
  id: string;
  name: string;
  description: string;
  img: string;
}

export const experimentType = {
  EXPERIMENT: 'Опытный',
  CONTROL: 'Контрольный',
};

export const createCarouselItems = (cultureZones: IExperimentCultureZone[]): ICarouselItem[] =>
  cultureZones.map(cultureZone => ({
    id: cultureZone?.id,
    description: `${experimentType[cultureZone.type]} — ${cultureZone.cultureZone.area.toFixed(
      1
    )} га`,
    name: cultureZone?.cultureZone?.name,
    img: '',
  }));
