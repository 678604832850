import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IDictionaryEntity } from '../../../models/da-dictionary/dictionary';

type TGetDictionaryEntityReq = {
  remoteName: string;
  id: string;
};

type TGetDictionaryEntityRes = IDictionaryEntity;

const getDictionaryEntity: TApiRoute & {
  request: TGetDictionaryEntityReq;
  response: TGetDictionaryEntityRes;
} = {
  url: ({ remoteName, id }) => `/api/da-dictionary/dictionary/${remoteName}/${id}`,
  method: 'GET',
  request: {} as TGetDictionaryEntityReq,
  response: {} as TGetDictionaryEntityRes,
  headers: {},
};

export type { TGetDictionaryEntityReq, TGetDictionaryEntityRes };

export { getDictionaryEntity };
