import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TDeleteAhoReg = {};
type TDeleteAhoRes = {};

const deleteAho: TApiRoute & {
  request: TDeleteAhoReg;
  response: TDeleteAhoRes;
} = {
  url: () => `/api/as-fields/aho/agrochemical-examinations/byCultureZoneExperiment`,
  method: 'DELETE',
  request: {} as TDeleteAhoReg,
  response: {} as TDeleteAhoRes,
  headers: {},
};

export { deleteAho };
export type { TDeleteAhoReg, TDeleteAhoRes };
