import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $isSelected?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  width: 100%;

  border-radius: 8px;
  border: 1px solid #e9e6f0;

  padding: 6px 12px;

  cursor: pointer;

  background-color: #faf9fd;
  color: #151f32;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-color: #151f32;
    `}

  &:hover {
    ${({ $isSelected }) =>
      $isSelected
        ? css`
            background-color: #f2f1f4;
            border-color: #151f32;
          `
        : css`
            border-color: #bac2d3;
          `}
  }
`;

const StyledTableFiltersBuilderPreset = { Wrapper };

export default StyledTableFiltersBuilderPreset;
