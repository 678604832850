import { useCallback, useMemo } from 'react';

import { useStore } from '../../../../utils/helpers/mobx';
import { ChatJournalStore } from '../../mobx/stores';
import { IChatMessage } from '../../models';

interface IUseChatConfig {
  chatId?: string;
  authorId?: string;
}

const useChat = (config?: IUseChatConfig) => {
  const { getChatInstance, addMessageListToChat } = useStore(ChatJournalStore);

  const addMessage = useCallback(
    (chatId: string, messageList: IChatMessage[]) => {
      addMessageListToChat(chatId, messageList);
    },
    [config?.chatId]
  );

  const chatInstance = useMemo(() => {
    return getChatInstance(config?.chatId);
  }, [config?.chatId]);

  return { chatInstance, addMessage };
};

export default useChat;
