import { ITaskReportData } from '../../../models/as-fields/task/Task';
import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { EChecklistType } from '../../../models/as-fields/checklists/Checklist/Checklist.model';

type TGenerateTaskReportDataReq = { taskId: string; checklistType: EChecklistType };

type TGenerateTaskReportDataRes = ITaskReportData;

const generateTaskReportData: TApiRoute & {
  request: TGenerateTaskReportDataReq;
  response: TGenerateTaskReportDataRes;
} = {
  url: ({ taskId }) => `/api/as-fields/task/${taskId}/generateTaskReportData`,
  method: 'GET',
  request: {} as TGenerateTaskReportDataReq,
  response: {} as TGenerateTaskReportDataRes,
  headers: {},
};

export type { TGenerateTaskReportDataRes, TGenerateTaskReportDataReq };

export { generateTaskReportData };
