import { ICreateNutritionHistoryDto } from '../../../../models/as-fields/plan/NutrationHistory';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TUpdateNutritionHistoryReg = {} & { nutritionHistoryId: string } & ICreateNutritionHistoryDto;
type TUpdateNutritionHistoryRes = {} & { id: string };

const updateNutritionHistory: TApiRoute & {
  request: TUpdateNutritionHistoryReg;
  response: TUpdateNutritionHistoryRes;
} = {
  url: ({ nutritionHistoryId }) => `/api/as-fields/nutrition-history/${nutritionHistoryId}`,
  method: 'PUT',
  request: {} as TUpdateNutritionHistoryReg,
  response: {} as TUpdateNutritionHistoryRes,
  headers: {},
};

export { updateNutritionHistory };
export type { TUpdateNutritionHistoryReg, TUpdateNutritionHistoryRes };
