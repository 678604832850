import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
`;

const StyledActionCell = {
  Wrapper,
  ActionButton,
  Icon,
};

export default StyledActionCell;
