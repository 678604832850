import _ from 'lodash';
import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import { IExperimentContract } from '../../../../../../../../../api/models/as-fields/experiments';
import { IOrganization } from '../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import { ISelectOption } from '../../../../../../../../common/components/form/Dropdown/Dropdown.types';

@provide.singleton()
class CreateEditContractStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _isLoading = false;
  private _contract: IExperimentContract | null = null;
  private _idToOrganization: Map<string, IOrganization> = new Map<string, IOrganization>();

  private _searchQueries = {
    organizationSearchQuery: '',
  };

  private _dropdownPageNumbers = {
    organizationCurrentPageNumber: 0,
    organizationTotalPageNumber: 0,
  };

  private _organizationOptionList: Array<ISelectOption> = [];

  private _hasAccessToEditContract = false;

  get searchQueries() {
    return this._searchQueries;
  }

  get dropdownPageNumbers() {
    return this._dropdownPageNumbers;
  }

  get organizationOptionList() {
    return this._organizationOptionList;
  }

  get isLoading() {
    return this._isLoading;
  }

  get contract() {
    return this._contract;
  }

  get organizationList() {
    return this._idToOrganization.values();
  }

  get hasAccessToEditContract() {
    return this._hasAccessToEditContract;
  }

  setIsLoading = (isLoading: boolean) => {
    this._isLoading = isLoading;
  };

  setContract = (contract: IExperimentContract) => {
    this._contract = contract;
  };

  setOrganizationList = (organizationList: IOrganization[]): void => {
    const newIdToOrganization: Map<string, IOrganization> = new Map<string, IOrganization>();

    if (_.isArray(organizationList)) {
      organizationList.forEach(organization => {
        newIdToOrganization.set(organization.organizationId, organization);
      });
    }

    this._idToOrganization = newIdToOrganization;
  };

  setDropdownPageNumbers = (dropdownName: string, pageNumber: number): void => {
    this._dropdownPageNumbers[dropdownName] = pageNumber;
  };

  setDropdownSearchQuery = (dropdownName: string, query: string): void => {
    this._searchQueries[dropdownName] = query;
  };

  setOrganizationOptionList = (optionList: ISelectOption[]): void => {
    this._organizationOptionList = optionList;
  };

  setAccessToEditContracts = (hasAccess: boolean): void => {
    this._hasAccessToEditContract = hasAccess;
  };

  clearContract = () => {
    this._contract = null;
  };

  clearIdToOrganization = (): void => {
    this._idToOrganization.clear();
  };

  clearIsLoading = () => {
    this._isLoading = false;
  };

  clearSearchQueries = () => {
    this._searchQueries = {
      organizationSearchQuery: '',
    };
  };

  clearDropdownPageNumbers = () => {
    this._dropdownPageNumbers = {
      organizationCurrentPageNumber: 0,
      organizationTotalPageNumber: 0,
    };
  };

  clearOrganizationOptionList = () => {
    this._organizationOptionList = [];
  };

  clearCreateEditContractStore = () => {
    this.clearContract();
    this.clearIdToOrganization();
    this.clearIsLoading();
    this.clearSearchQueries();
    this.clearDropdownPageNumbers();
    this.clearOrganizationOptionList();
  };
}

export default CreateEditContractStore;
