import { EReferenceType, IReference } from '../../../models/as-fields/references/Reference.model';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TRequest = {
  size?: number;
  page?: number;
  path?: string;
  parentPath?: string;
  type?: EReferenceType;
  active?: boolean;
};

type TResponse = { content: IReference[] };

export const getReferenceList: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/references/list`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
