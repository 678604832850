import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TChangeCultureZoneReq = {
  id: string;
  seasonYear?: number;
  name?: string;
  variety?: string;
  sowingDate?: string;
  harvestDate?: string;
  avgYield?: number;
  reproductionCycle?: number;
  factYield?: number;
  planYield?: number;
  experimentReady?: boolean;
  technologyId?: string;
  cultureZoneId?: string;
  cultureId?: string;
  geoJson?: {
    type: string;
    id: {};
    properties: {
      [key: string]: any;
    };
  };
};

type TChangeCultureZoneRes = {};

const changeCultureZone: TApiRoute & {
  request: TChangeCultureZoneReq;
  response: TChangeCultureZoneRes;
} = {
  url: ({ id }) => `/api/as-fields/fields/cultureZones/${id}`,
  method: 'PUT',
  request: {} as TChangeCultureZoneReq,
  response: {} as TChangeCultureZoneRes,
  headers: {},
};

export type { TChangeCultureZoneReq, TChangeCultureZoneRes };

export { changeCultureZone };
