import { useEffect } from 'react';

const useHandlerByScreenResize = (handler: () => void): void => {
  useEffect(() => {
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);
};

export default useHandlerByScreenResize;
