import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const { borderGray, generalBlack, secondaryDarkGray } = Colors;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: ${generalBlack};
`;

const Description = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${generalBlack};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1px solid ${borderGray};

  & > :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Body = styled.div`
  margin-top: 24px;
`;

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & button {
    justify-content: center;
    flex: 1 1 40%;
  }

  & > :not(:last-child) {
    margin-right: 12px;
  }
`;

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: ${secondaryDarkGray};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 8px;
`;

const Container = styled.div`
  margin-bottom: 24px;
`;

const StyledEditZoneInfoModal = {
  Title,
  Description,
  Header,
  Body,
  Footer,
  Wrapper,
  Label,
  RadioButtonWrapper,
  Container,
};

export default StyledEditZoneInfoModal;
