import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IEmployee } from '../../../models/da-profile/users';

type TGetOrganizationUserReq = {
  organizationId: string;
  userId: string;
};

type TGetOrganizationUserRes = IEmployee;

const getOrganizationUser: TApiRoute & {
  request: TGetOrganizationUserReq;
  response: TGetOrganizationUserRes;
} = {
  url: ({ organizationId, userId }) =>
    `/api/da-profile/organizations/${organizationId}/user/${userId}`,
  method: 'GET',
  request: {} as TGetOrganizationUserReq,
  response: {} as TGetOrganizationUserRes,
  headers: {},
};

export type { TGetOrganizationUserReq, TGetOrganizationUserRes };

export { getOrganizationUser };
