import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 20px;
`;

const StyledComparisonTableBuilderHeader = {
  Wrapper,
};

export default StyledComparisonTableBuilderHeader;
