import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { TAuditsParams } from '../../../../../../models/params/AuditsParams.model';
import { generateAuditsPath } from '../../../../../../utils/helpers';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';

import Styled from './AuditInfo.style';

type TAuditInfoProps = {
  formElements: any;
  handleReadyClick: () => void;
  titleName: string;
  buttonName: string;
  isSubmitDisabled?: boolean;
  isShowEditIcon?: boolean;
  isShowSubmit?: boolean;
  mode: string;
};

const AuditInfo: FC<TAuditInfoProps> = ({
  formElements,
  handleReadyClick,
  titleName,
  buttonName,
  isSubmitDisabled = false,
  isShowEditIcon = false,
  isShowSubmit = false,
  mode,
}) => {
  const {
    organizationId: Organization,
    experimentId: Expiriment,
    planStartDate: ObservationStartDate,
    planEndDate: ObservationEndDate,
    parentStepId: ExpirimentStep,
    techOperationTypeId: Operation,
    assigneeId: Executor,
    name: Name,
  } = formElements;

  const navigate = useNavigate();

  const { auditId } = useParams<TAuditsParams>();

  const onIconClick = () => {
    navigate(generateAuditsPath('editAudit', { auditId }));
  };

  const commonInformationDataTestId = 'audit-info';

  usePageContextualHelp(EContextualParentPath[`AuditsAudit${mode}`]);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = useMemo(() => {
    switch (mode) {
      case 'Create':
        return helpActions.getContextualHelpIcon(
          EContextualParentPath.AuditsAuditCreate,
          ContextualPaths.AuditsAuditCreateSummaryTitle
        );
      case 'Edit':
        return helpActions.getContextualHelpIcon(
          EContextualParentPath.AuditsAuditEdit,
          ContextualPaths.AuditsAuditEditSummaryTitle
        );
      case 'View':
        return helpActions.getContextualHelpIcon(
          EContextualParentPath.AuditsAuditView,
          ContextualPaths.AuditsAuditViewSummaryTitle
        );
      default:
        break;
    }
  }, [mode, helpActions]);

  return (
    <>
      <Styled.Container data-test-id={`${commonInformationDataTestId}-wrapper`}>
        <Styled.Header data-test-id={`${commonInformationDataTestId}-header`}>
          <Styled.TitleWrapper>
            <Styled.Title data-test-id={`${commonInformationDataTestId}-header-title`}>
              {titleName}
            </Styled.Title>
            {ContextualHelpIcon}
          </Styled.TitleWrapper>
          {isShowEditIcon && (
            <Styled.Icon
              onClick={onIconClick}
              data-test-id={`${commonInformationDataTestId}-header-icon`}
            />
          )}
        </Styled.Header>
        <Styled.Body data-test-id={`${commonInformationDataTestId}-body`}>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id={`${commonInformationDataTestId}-organization-wrapper`}
          >
            {Organization ? (
              <Organization data-test-id={`${commonInformationDataTestId}-organization`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            data-test-id={`${commonInformationDataTestId}-expiriment-wrapper`}
            marginBottom="16px"
          >
            {Expiriment ? (
              <Expiriment data-test-id={`${commonInformationDataTestId}-expiriment`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id={`${commonInformationDataTestId}-observation-start-date-wrapper`}
          >
            {ObservationStartDate ? (
              <ObservationStartDate
                data-test-id={`${commonInformationDataTestId}-observation-start-date`}
              />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            data-test-id={`${commonInformationDataTestId}-observation-end-date-wrapper`}
            marginBottom="16px"
          >
            {ObservationEndDate ? (
              <ObservationEndDate
                data-test-id={`${commonInformationDataTestId}-observation-end-date`}
              />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id={`${commonInformationDataTestId}-expiriment-step-wrapper`}
          >
            {ExpirimentStep ? (
              <ExpirimentStep data-test-id={`${commonInformationDataTestId}-expiriment-step`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            data-test-id={`${commonInformationDataTestId}-operation-wrapper`}
            marginBottom="16px"
          >
            {Operation ? (
              <Operation data-test-id={`${commonInformationDataTestId}-operation`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id={`${commonInformationDataTestId}-executor-wrapper`}
          >
            {Executor ? (
              <Executor data-test-id={`${commonInformationDataTestId}-executor`} />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            data-test-id={`${commonInformationDataTestId}-name-wrapper`}
          >
            {Name ? <Name data-test-id={`${commonInformationDataTestId}-name`} /> : null}
          </Styled.Attribute>
        </Styled.Body>
      </Styled.Container>

      {isShowSubmit ? (
        <Styled.ButtonWrapper
          type={'button'}
          color={'primary'}
          fontSize={'14px'}
          onClick={handleReadyClick}
          disabled={isSubmitDisabled}
          data-test-id={`${commonInformationDataTestId}-button-wrapper`}
        >
          {buttonName}
        </Styled.ButtonWrapper>
      ) : null}
    </>
  );
};
export default observer(AuditInfo);
