import { IExperimentCultureZone } from '../../../../models/as-fields/experiments/ExperimentCultureZone';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TRequest = { id: string };

type TResponse = IExperimentCultureZone;

export const getExperimentCultureZone: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/cultureZoneExperiment/${id}`,
  method: 'GET',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};
