import { useModal } from '@farmlink/farmik-ui';
import { useCallback } from 'react';
// import { Transition } from 'history';

import { createWarningBeforeLeavingThePage } from '../../../configs/modals/global';
import useBlocker from '../useBlocker/useBlocker';

const useWarningBeforeLeavingThePage = (
  isBlockActivated: boolean,
  denyHandler?: () => void,
  whiteList?: string[]
): void => {
  const { registerModalList, openModalByModalId } = useModal();

  const blocker = useCallback((tx: any) => {
    const modalConfig = createWarningBeforeLeavingThePage(() => tx.retry(), denyHandler);

    registerModalList(modalConfig.configList, modalConfig.key);

    openModalByModalId(modalConfig.name);
  }, []);

  useBlocker(blocker, isBlockActivated, whiteList ?? []);
};

export default useWarningBeforeLeavingThePage;
