import { observer } from 'mobx-react';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';
import { CreateExperimentStore } from '../../../../mobx/stores';

import Styled from './ComparisonHeader.styles';

interface IProps {
  dataTestId: string;
}

const ComparisonHeader: FC<PropsWithChildren<IProps>> = ({ children, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  const { tableHeaderInfo, setTableHeaderInfo } = useStore(CreateExperimentStore);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTableHeaderInfo({ ref, stuckPosition: 137 });
  }, [ref]);

  return (
    <Styled.StickyWrapper
      ref={ref}
      {...getDataTestId('sticky-wrapper')}
      $isStuck={tableHeaderInfo.isStuck}
    >
      <Styled.Wrapper {...getDataTestId()}>{children}</Styled.Wrapper>
    </Styled.StickyWrapper>
  );
};

ComparisonHeader.displayName = 'ComparisonColumn';

export default observer(ComparisonHeader);
