import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px 24px 1px;
  margin-top: 24px;
`;

const TitleWrapper = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid #e9e6f0;
`;

const InfoWrapper = styled.div`
  padding-top: 24px;
`;

const StyledInfoBlock = {
  Wrapper,
  TitleWrapper,
  InfoWrapper,
};

export default StyledInfoBlock;
