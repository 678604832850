import { Button as FarmikButton } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { ECommonColor } from '../../../assets/styles/colors';

const { White, AccentGreen } = ECommonColor;

const BackButton = styled(FarmikButton)`
  justify-content: center;
  width: 300px;
  background-color: ${White};
  color: ${AccentGreen};
  border: 1px solid #3fb65f;
`;

const ContinueButton = styled(FarmikButton)`
  justify-content: center;
  width: 300px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 100px;
`;

const StyledStepFooter = {
  BackButton,
  ContinueButton,
  Footer,
};

export default StyledStepFooter;
