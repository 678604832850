import { IOrganization } from '../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import { ISelectOption } from '../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { IExperimentContract } from '../../../../../../../../../api/models/as-fields/experiments';
import { formatDate } from '../../../../../../../../common/utils/helpers/dates';

const createOrganizationLabel = (orgType: string, orgName: string): string => {
  if (!orgType) {
    return `"${orgName}"`;
  }

  return `${orgType} "${orgName}"`;
};

const createOrganizationOptionList = (organizationList: IOrganization[]): ISelectOption[] => {
  const optionList: ISelectOption[] = organizationList.map<ISelectOption>(
    ({ name, organizationId, type }) => ({
      label: createOrganizationLabel(type?.OrgType, name),
      value: organizationId,
    })
  );

  return optionList;
};

const formatContractInfo = (contract: IExperimentContract): string => {
  const contractDate = formatDate(new Date(contract.contractDate));

  return `Договор ${contract.name} от ${contractDate}`;
};

const CreateEditContractHelpers = {
  createOrganizationOptionList,
  formatContractInfo,
  createOrganizationLabel,
};

export default CreateEditContractHelpers;
