import { FC, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { observer } from 'mobx-react';
import { DefaultTheme, ModalContainer, Notificator } from '@farmlink/farmik-ui';
import { useLocation } from 'react-router-dom';

import { useStore } from './modules/common/utils/helpers/mobx';
import { AxiosService } from './modules/common/mobx/services/axios/AxiosService';
import { EAppSettingsType, LS_APP_SETTINGS_KEY } from './modules/common/utils/constants/app';
import { systemHelperCollection } from './modules/common/utils/helpers/system';
import { ExternalRedirectContainer } from './modules/common/containers/authorization';
import AppRouter from './router/AppRouter/AppRouter';
import { useErrorModal } from './modules/common/utils/hooks';
import { GlobalService } from './modules/common/mobx/services/system';

const App: FC = observer(() => {
  const axiosService = useStore(AxiosService);
  const { needAdditionalInfo } = axiosService;

  const { setShowErrorModalHandler } = useStore(GlobalService);

  const location = useLocation();
  const { search } = location;
  const { showErrorModal } = useErrorModal();

  useEffect(() => {
    setShowErrorModalHandler(showErrorModal);
  }, []);

  const { getRegistrationStep2Url } = systemHelperCollection;

  const organizationQuery = new URLSearchParams(search).get('organizationId');

  const appSettingsStr = localStorage.getItem(LS_APP_SETTINGS_KEY);
  const appSettings = JSON.parse(appSettingsStr);

  const orgId =
    organizationQuery || (appSettings && appSettings[EAppSettingsType.Organization]) || 'my';

  if (organizationQuery && appSettings) {
    appSettings[EAppSettingsType.Organization] = orgId;
    localStorage.setItem(LS_APP_SETTINGS_KEY, JSON.stringify(appSettings));
  }

  return (
    <>
      <ThemeProvider theme={DefaultTheme} data-test-id="app-theme-provider">
        <>
          {needAdditionalInfo ? (
            <ExternalRedirectContainer
              redirectTo={getRegistrationStep2Url()}
              data-test-id="app-external-redirect"
            />
          ) : null}

          <AppRouter />
          <ModalContainer />
          <Notificator />
        </>
      </ThemeProvider>
    </>
  );
});

// test change
export default App;
