import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TCopyAhoReg = { donorId: string; recipientId: string };
type TCopyAhoRes = {};

const copyAho: TApiRoute & {
  request: TCopyAhoReg;
  response: TCopyAhoRes;
} = {
  url: ({ donorId, recipientId }) =>
    `/api/as-fields/cultureZoneExperiment/copyAho/from/${donorId}/to/${recipientId}`,
  method: 'POST',
  request: {} as TCopyAhoReg,
  response: {} as TCopyAhoRes,
  headers: {},
};

export { copyAho };
export type { TCopyAhoReg, TCopyAhoRes };
