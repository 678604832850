import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { IChatMessage } from '../../../models';
import { ChatJournalStore } from '../../stores';

@provide.transient()
class ChatJournalController {
  @lazyInject(ChatJournalStore)
  protected chatJournalStore: ChatJournalStore;

  addMessageListToChat = (chatId: string, messageList: IChatMessage[]) => {
    this.chatJournalStore.setMessageListByChatId(chatId, messageList);
  };
}

export default ChatJournalController;
