import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react';

import { ITableBuilderCell as ICell, ITableBuilderRow as IRow } from '../../../models/data';
import { TableBuilderCellAutoRenderContainer as CellAutoRenderContainer } from '../TableBuilderCellAutoRenderContainer';
import { TableBuilderCellCustomRenderContainer as CellCustomRenderContainer } from '../TableBuilderCellCustomRenderContainer';

interface IProps {
  cell: ICell;
  row: IRow;
}

const TableBuilderCellContainer: FC<IProps> = ({ cell, row }) => {
  switch (cell.renderType || 'auto') {
    case 'auto':
      return <CellAutoRenderContainer cell={cell} row={row} />;

    case 'custom':
      return <CellCustomRenderContainer cell={cell} row={row} />;

    default:
      return <></>;
  }
};

TableBuilderCellContainer.displayName = 'TableBuilderCellContainer';

export default observer(TableBuilderCellContainer);
