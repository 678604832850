import { FC, memo } from 'react';
import { Colors, IconButton } from '@farmlink/farmik-ui';

import Styled from './ActionCell.styles';

interface IActionCell {
  deleteHandler: () => void;
  editHandler: () => void;
  dataTestId?: string;
}

const editColorScheme = {
  default: {
    background: 'transparent',
    icon: Colors.generalBlack,
  },
  hover: {
    background: 'transparent',
    icon: Colors.accentGreen,
  },
  disabled: {
    background: 'transparent',
    icon: Colors.lightGrey,
  },
};

const deleteColorScheme = {
  default: {
    background: 'transparent',
    icon: Colors.accentPink,
  },
  hover: {
    background: 'transparent',
    icon: Colors.hoverPink,
  },
  disabled: {
    background: 'transparent',
    icon: Colors.lightGrey,
  },
};

const ActionCell: FC<IActionCell> = ({ deleteHandler, editHandler, dataTestId }) => {
  const testId = dataTestId ?? 'techoperation';

  return (
    <Styled.Wrapper data-test-id={`${testId}-action-cell-buttons-wrapper`}>
      <IconButton
        size={20}
        onClick={editHandler}
        colorScheme={editColorScheme}
        icon="edit"
        dataTestId={`${testId}-action-cell-edit-button`}
        $padding="3px"
      />
      <IconButton
        size={20}
        onClick={deleteHandler}
        colorScheme={deleteColorScheme}
        icon="delete"
        dataTestId={`${testId}-action-cell-delete-button`}
        $padding="3px"
      />
    </Styled.Wrapper>
  );
};

ActionCell.displayName = 'ActionCell';

export default memo(ActionCell);
