import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../assets/styles/colors';
import StyledStage from '../Stage/Stage.styles';

const { GeneralBlack, SecondaryGray } = ECommonColor;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: ${GeneralBlack};
`;

const DropIcon = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 16px 16px 16px 12px;
  margin-bottom: 16px;

  border-radius: 12px;

  background-color: ${SecondaryGray};
`;

const Body = styled.div`
  padding: 0 0 0 12px;

  & ${StyledStage.Wrapper}:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Wrapper = styled.div<{ $isCollapsed: boolean }>`
  ${({ $isCollapsed }) => css`
    & ${StyledStage.Wrapper} {
      display: ${$isCollapsed ? 'none' : 'block'};
    }
  `}
`;

const StyledCollapsingStage = {
  Title,
  DropIcon,
  Header,
  Body,
  Wrapper,
};

export default StyledCollapsingStage;
