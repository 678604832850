import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';
import { IExperimentContract } from '../../../../../../api/models/as-fields/experiments';

const createExpContractSelectOption = (contract: IExperimentContract): ISelectOption => {
  return { label: contract.name, value: contract.id };
};

const createExpContractSelectOptionList = (
  contractList: IExperimentContract[]
): ISelectOption[] => {
  return contractList.map<ISelectOption>(contract => createExpContractSelectOption(contract));
};

export { createExpContractSelectOption, createExpContractSelectOptionList };
