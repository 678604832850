import { FC, memo, useCallback, useState, useMemo, useEffect } from 'react';

import { TableBodyCell } from '../TableBodyCell';

import Styled from './TableBodyRow.styles';
import { TTableBodyProps } from './TableBodyRow.types';

const TableBodyRowComponent: FC<TTableBodyProps> = ({
  rowData,
  tableHeadData,
  onRowClick,
  isSelected,
  additionalRowsName,
  additionalRowsHead,
  actions,
  onAdditionalRowClick,
  config,
}) => {
  const [showAdditionalRows, setShowAdditionalRows] = useState(false);
  const isViewMode = config?.isViewMode;

  useEffect(() => {
    if (rowData?.[additionalRowsName]?.length < 2) {
      setShowAdditionalRows(false);
    }
  }, [rowData?.[additionalRowsName]?.length]);

  const onClickCell = useCallback(() => {
    if (isViewMode) {
      return;
    }

    onRowClick?.(rowData);
  }, [rowData, isViewMode]);

  const onClickAdditionalCell = useCallback(
    additionRowsData => {
      onAdditionalRowClick?.(additionRowsData);
    },
    [rowData]
  );

  const changeAdditionalRowsState = useCallback(() => {
    if (isViewMode) {
      return;
    }

    setShowAdditionalRows(!showAdditionalRows);
  }, [showAdditionalRows, isViewMode]);

  const isOnRowClick = useMemo(() => Boolean(onRowClick), [onRowClick]);

  const needToShowAdditionalRows = useMemo(
    () => additionalRowsName && showAdditionalRows,
    [additionalRowsName, showAdditionalRows]
  );

  return (
    <>
      <Styled.RowWrapper
        onClick={onClickCell}
        data-test-id="table-row-wrapper"
        $isSelectedRow={isSelected}
        $needToShowAdditionalRows={needToShowAdditionalRows}
        $isOnRowClick={isOnRowClick}
      >
        {tableHeadData.map(data => {
          return (
            <TableBodyCell
              key={rowData.id + data.field}
              rowData={rowData}
              headData={data}
              changeAdditionalRowsState={changeAdditionalRowsState}
              actions={{ ...actions }}
              config={config}
            />
          );
        })}
      </Styled.RowWrapper>
      {needToShowAdditionalRows &&
        rowData[additionalRowsName].map((additionRowsData, index) => {
          const isLastRow = rowData[additionalRowsName].length - 1 === index;

          return (
            <Styled.AdditionalRowWrapper
              onClick={() => onClickAdditionalCell(additionRowsData)}
              $isOnRowClick={isOnRowClick}
              key={additionRowsData?.id}
              data-test-id="table-additional-row-wrapper"
              $isLastRow={isLastRow}
            >
              {additionalRowsHead.map(data => {
                return (
                  <TableBodyCell
                    rowData={additionRowsData}
                    headData={data}
                    key={data?.field + additionRowsData.id}
                    actions={{ ...actions }}
                    config={config}
                    padding="0 0 12px 0"
                  />
                );
              })}
            </Styled.AdditionalRowWrapper>
          );
        })}
    </>
  );
};

const TableBodyRow = memo(TableBodyRowComponent);

TableBodyRow.displayName = 'TableBodyRow';

export default TableBodyRow;
