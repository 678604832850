import { IAccessRule } from '../../../models/da-profile/access/Access.model';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TRequest = {};

type TResponse = IAccessRule[];

export const getAllActiveGlobalActionsForCurrentUser: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: `/api/da-profile/access/action/global/active/list`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
