import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 24px;
`;

const StyledExecutionCalculations = {
  Wrapper,
};

export default StyledExecutionCalculations;
