import { FC, memo, ReactNode } from 'react';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';

import Styled from './ComparisonTableBuilderDashedHeader.styles';

interface IProps {
  columnsChildren: ReactNode;
}

const ComparisonTableBuilderDashedHeader: FC<IProps> = ({ columnsChildren }) => {
  const getDataTestId = useDataTestId({ componentName: 'dashed-header' });

  return <Styled.Wrapper {...getDataTestId()}>{columnsChildren}</Styled.Wrapper>;
};

ComparisonTableBuilderDashedHeader.displayName = 'ComparisonTableBuilderDashedHeader';

export default memo(ComparisonTableBuilderDashedHeader);
