import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import { TGetDictionaryEntityListReq, TGetDictionaryEntityReq } from '../../../../../../api';
import { IDictionaryEntity } from '../../../../../../api/models/da-dictionary/dictionary';

@provide.singleton()
class DictionaryService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getDictionaryEntityList = async (
    payload: Partial<TGetDictionaryEntityListReq>,
    query?: Record<string, string | number>
  ): Promise<{ content: IDictionaryEntity[]; totalPages: number }> => {
    const { getDictionaryEntityList } = this.axiosService.api;

    try {
      const { content, totalPages } = await getDictionaryEntityList(payload, query && { query });

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getDictionaryEntity = async (payload: TGetDictionaryEntityReq): Promise<IDictionaryEntity> => {
    const { getDictionaryEntity } = this.axiosService.api;

    try {
      const fetchedEntity = await getDictionaryEntity(payload, { omit: ['remoteName', 'id'] });

      return fetchedEntity;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default DictionaryService;
