import { FC, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Icon, Tabs, useContextualHelpActions } from '@farmlink/farmik-ui';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import TaskReportController from '../../mobx/controllers/TaskReport.controller';
import TaskReportStore from '../../mobx/stores/TaskReport.store';
import { EAuditRoute } from '../../../../routes';
import { EDashboardRoute } from '../../../../../../../../routes/DashboardRoute';
import { TAuditsParams } from '../../../../../../models/params/AuditsParams.model';
import { EExperimentCultureZoneType } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';
import { AuditStore } from '../../../../mobx/store';

import Styled from './TaskReportHeaderActions.styles';

const TaskReportHeaderActions: FC = () => {
  const taskReportStore = useStore(TaskReportStore);
  const { selectedTabId, setSelectedTabId } = useStore(AuditStore);

  const taskReportController = useStore(TaskReportController);

  const { auditId } = useParams<TAuditsParams>();

  const navigate = useNavigate();

  const goToFieldsComparison = () => {
    navigate(
      generatePath(`/${EDashboardRoute.Audits}/${auditId}/${EAuditRoute.Report}`, {
        auditId,
      })
    );
  };

  useEffect(() => {
    if (taskReportStore.selectedTabId) {
      setSelectedTabId(taskReportStore.selectedTabId);
    }
  }, [taskReportStore.selectedTabId]);

  usePageContextualHelp(EContextualParentPath.AuditsAuditReportTask);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.AuditsAuditReportTask,
    ContextualPaths.AuditsAuditReportAuditTaskTableTitle
  );

  const calculateAverage = useCallback(async (): Promise<void> => {
    taskReportStore.clearDynamicTableConfig();

    const aggregateInstanceId = await taskReportController.createAggregateInstance();

    if (!aggregateInstanceId) {
      return;
    }

    await taskReportController.getDynamicTableConfig();
  }, []);

  const titleText = useMemo(() => {
    return `${taskReportStore?.selectedTask?.cultureZone?.name}. Отчёт по задаче`;
  }, [taskReportStore?.selectedTask?.cultureZone?.name]);

  const iconType = useMemo(() => {
    return taskReportStore?.selectedTask?.cultureZoneExperiment?.type ===
      EExperimentCultureZoneType.Control
      ? 'controlField'
      : 'experimentField';
  }, [taskReportStore?.selectedTask?.cultureZoneExperiment?.type]);

  return (
    <Styled.Wrapper>
      <Styled.TitleWrapper>
        <Styled.Title>
          <Styled.IconWrapper>
            <Icon icon={iconType} size={'large'} />
          </Styled.IconWrapper>
          {titleText}
          {ContextualHelpIcon ? ContextualHelpIcon : null}
        </Styled.Title>

        {taskReportStore.availableTabList.length > 1 ? (
          <Tabs
            tabWidth="97.33px"
            selectedTabId={selectedTabId}
            onChangeTab={taskReportController.changeTab}
            content={taskReportStore.availableTabList}
            size="small"
            dataTestId="test"
          />
        ) : null}
      </Styled.TitleWrapper>

      <Styled.ActionsWrapper>
        <Styled.ActionWrapper onClick={goToFieldsComparison}>
          <Styled.ArrowLeft />
          <Styled.Action> К сравнению по участкам</Styled.Action>
        </Styled.ActionWrapper>

        <Styled.Action
          onClick={calculateAverage}
          $isDisabled={!taskReportStore.selectedInstIdList.length}
        >
          Рассчитать среднее
        </Styled.Action>
      </Styled.ActionsWrapper>
    </Styled.Wrapper>
  );
};

TaskReportHeaderActions.displayName = 'TaskReportHeaderActions';

export default observer(TaskReportHeaderActions);
