import completeAggregateInstanceHelpers from './completeAggregateInstanceHelpers/completeAggregateInstance.helpers';
import dynamicTableConfigHelpers from './dynamicTableConfigHelpers/dynamicTableConfig.helpers';
import dynamicTableInstanceHelpers from './dynamicTableInstanceHelpers/dynamicTableInstance.helpers';
import addSystemStage from './auditReportAddSystemStage/auditReportAddSystemStage';

const { createCompleteInstList } = completeAggregateInstanceHelpers;
const { createDynamicTableConfig } = dynamicTableConfigHelpers;
const { createInstanceList } = dynamicTableInstanceHelpers;

const AuditReportsHelpers = {
  createCompleteInstList,
  createDynamicTableConfig,
  createInstanceList,
  addSystemStage,
};

export default AuditReportsHelpers;
