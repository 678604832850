import { IProtectionPrice } from '../../../../models/as-fields/plan/ProtectionPrice';
import { TApiRoute } from '../../../../models/system/ApiRoute.model';

type TGetProtectionPricesReg = {
  experimentId: string;
};
type TGetProtectionPricesRes = IProtectionPrice[];

const getProtectionPrices: TApiRoute & {
  request: TGetProtectionPricesReg;
  response: TGetProtectionPricesRes;
} = {
  url: () => `/api/as-fields/nutrition-history/protectionPrices`,
  method: 'GET',
  request: {} as TGetProtectionPricesReg,
  response: {} as TGetProtectionPricesRes,
  headers: {},
};

export { getProtectionPrices };
export type { TGetProtectionPricesReg, TGetProtectionPricesRes };
