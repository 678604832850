import { IExperiment } from '../../../models/as-fields/experiments';
import { EExperimentApprovalStatus } from '../../../models/as-fields/experiments/Experiment/Experiment.model';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TChangeExperimentApprovalStatusReq = {
  id: string;
  /**
   * Строго зависит от доступных PUT-ручек смены статуса апрува `EExperimentApprovalStatus`
   */
  status: EExperimentApprovalStatus;
};

type TChangeExperimentApprovalStatusRes = IExperiment;

const changeExperimentApprovalStatus: TApiRoute & {
  request: TChangeExperimentApprovalStatusReq;
  response: TChangeExperimentApprovalStatusRes;
} = {
  url: ({ id, status }) => `/api/as-fields/experiment/${id}/${status}`,
  method: 'PUT',
  request: {} as TChangeExperimentApprovalStatusReq,
  response: {} as TChangeExperimentApprovalStatusRes,
  headers: {},
};

export type { TChangeExperimentApprovalStatusReq, TChangeExperimentApprovalStatusRes };

export { changeExperimentApprovalStatus };
