/**
 * @param revenue - выручка;
 * @param controlRevenue - контрольная выручка;
 * @param totalCosts - суммарные затраты;
 * @param controlTotalCosts - контрольные суммарные затраты.
 */
const calculateRoi = (
  revenue: number,
  controlRevenue: number,
  totalCosts: number,
  controlTotalCosts: number
): string => {
  const revenueDifference = revenue - controlRevenue;
  const totalCostsDifference = totalCosts - controlTotalCosts;

  if (!totalCosts) {
    return '0.00';
  }

  const result = (revenueDifference - totalCostsDifference) / totalCostsDifference;

  return result.toFixed(2);
};

export default calculateRoi;
