import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { ICultureEntity } from '../../../models/da-dictionary/culture';

type TypeRequest = {};

type TypeResponse = {} & TResponseList<ICultureEntity>;

export const getCulturesList: TApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/da-dictionary/dictionary/culture/findAll`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
