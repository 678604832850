import moment from 'moment/moment';
import { v4 as uuid } from 'uuid';

import { IAttributeValue } from '../../../../../../../../../../common/features/DynamicTable/models';
import { EChecklistAttributeType } from '../../../../../../../../../../../api/models/as-fields/checklists/Attribute/ChecklistAttribute.model';
import {
  ReportsAttrWithVal as AttrData,
  ReportsAttrWithVal,
} from '../../../models/InstWithAttrValList/InstWithAttrValList';
import {
  ITaskReportInstance,
  ITaskReportTableAttributeValue,
} from '../../../../../../../../../../../api/models/as-fields/task/Task';
import { formatNumValue } from '../../../../../../../../../../common/utils/helpers/numbers';

const formatMinAndMaxVal = (min: number, max: number): string => {
  if (min !== undefined && max !== undefined) {
    return `(${min}-${max})`;
  }
};

const formatEnumAndUserDictVal = (
  listVal:
    | ITaskReportTableAttributeValue['userDictionaryValue']
    | ITaskReportTableAttributeValue['enumValue']
): string => {
  return listVal
    ? listVal
        .reduce<string[]>((acc, el) => {
          acc.push(el);

          return acc;
        }, [])
        .join(', ')
    : '—';
};

const formatDictVal = (
  dictValList: ITaskReportTableAttributeValue['dictionaryValueList']
): string => {
  return dictValList
    ? dictValList
        .reduce<string[]>((acc, el) => {
          acc.push(el.name);

          return acc;
        }, [])
        .join(', ')
    : '—';
};

const getIntegerVal = ({
  intValue,
  intMinValue,
  intMaxValue,
}: ITaskReportTableAttributeValue): string | number => {
  const minAndMaxVal = formatMinAndMaxVal(intMinValue, intMaxValue);

  if (minAndMaxVal) {
    return `${intValue} ${minAndMaxVal}`;
  } else if (intValue) {
    return intValue;
  }

  return '—';
};

const getDoubleVal = ({
  doubleValue,
  doubleMinValue,
  doubleMaxValue,
}: ITaskReportTableAttributeValue): string | number => {
  if (doubleMinValue && doubleMaxValue) {
    const minAndMaxVal = formatMinAndMaxVal(
      formatNumValue(doubleMinValue),
      formatNumValue(doubleMaxValue)
    );

    return `${formatNumValue(doubleValue)} ${minAndMaxVal}`;
  } else if (doubleValue) {
    return formatNumValue(doubleValue);
  }

  return '—';
};

const getBoolVal = ({ booleanValue }: ITaskReportTableAttributeValue): string => {
  if (booleanValue) {
    const yesOrNoList = booleanValue?.map(value => {
      if (value) {
        return 'да';
      } else {
        return 'нет';
      }
    });

    return yesOrNoList.join(', ');
  } else {
    return '—';
  }
};

const getDateVal = ({ dateValue }: ITaskReportTableAttributeValue): string => {
  if (dateValue) {
    const formatedDateList = dateValue?.map(date => moment(date).format('DD.MM.YYYY'));

    return formatedDateList.join(', ');
  } else {
    return '—';
  }
};

const getStringVal = ({ stringValue }: ITaskReportTableAttributeValue): string => {
  return stringValue ?? '—';
};

const getLongStringVal = ({ longStringValue }: ITaskReportTableAttributeValue): string => {
  return longStringValue ?? '—';
};

const getEnumVal = ({ enumValue }: ITaskReportTableAttributeValue): string => {
  return formatEnumAndUserDictVal(enumValue);
};

const getUserDictVal = ({ userDictionaryValue }: ITaskReportTableAttributeValue): string => {
  return formatEnumAndUserDictVal(userDictionaryValue);
};

const getDictVal = ({ dictionaryValueList }: ITaskReportTableAttributeValue): string => {
  return formatDictVal(dictionaryValueList);
};

const getFileVal = ({ fileValue }: ITaskReportTableAttributeValue): string => {
  return fileValue ? `Фотографий: ${fileValue.length}` : '—';
};

const getValueByType = (
  attrVal: ITaskReportTableAttributeValue,
  type: EChecklistAttributeType
): string | number => {
  switch (type) {
    case EChecklistAttributeType.Int:
      return getIntegerVal(attrVal);
    case EChecklistAttributeType.Double:
      return getDoubleVal(attrVal);
    case EChecklistAttributeType.Boolean:
      return getBoolVal(attrVal);
    case EChecklistAttributeType.Date:
      return getDateVal(attrVal);
    case EChecklistAttributeType.String:
      return getStringVal(attrVal);
    case EChecklistAttributeType.LongString:
      return getLongStringVal(attrVal);
    case EChecklistAttributeType.Enum:
      return getEnumVal(attrVal);
    case EChecklistAttributeType.UserDictionaryLink:
      return getUserDictVal(attrVal);
    case EChecklistAttributeType.DictionaryLink:
      return getDictVal(attrVal);
    case EChecklistAttributeType.FileLink:
      return getFileVal(attrVal);
    default:
      return '—';
  }
};

const createAttrVal = (
  instance: ITaskReportInstance,
  { dynamicTableAttr, checklistAttrVal }: AttrData
): IAttributeValue => {
  const { stageId, type, attributeId } = dynamicTableAttr;

  const instanceValue = checklistAttrVal.find(value => value.instanceId === instance.id);

  return {
    instanceId: instance.id,
    stageId,
    id: uuid(),
    attributeId,
    value: getValueByType(instanceValue, type),
  };
};

const createAttrValList = (
  inst: ITaskReportInstance,
  attrDataList: ReportsAttrWithVal[] = []
): IAttributeValue[] => {
  return attrDataList.map(attrData => createAttrVal(inst, attrData));
};

const reportsDynamicTableInstanceHelpers = {
  createAttrValList,
};

export default reportsDynamicTableInstanceHelpers;
