import { FC, memo } from 'react';
import { Colors, IconButton } from '@farmlink/farmik-ui';

const DEFAULT_DATA_TEST_ID = 'download-button';

interface IProps {
  size: 32 | 24;
  onClick?: () => void;
  dataTestId?: string;
}

const colorScheme = {
  default: {
    background: Colors.accentGreen,
    icon: Colors.generalWhite,
  },
  hover: {
    background: Colors.hoverGreen,
    icon: Colors.generalWhite,
  },
  disabled: {
    background: Colors.borderHover,
    icon: Colors.generalWhite,
  },
};

const DownloadButton: FC<IProps> = ({ size, onClick, dataTestId }) => {
  return (
    <IconButton
      $borderRadius="12px"
      icon="download"
      colorScheme={colorScheme}
      size={size}
      onClick={onClick}
      dataTestId={dataTestId || DEFAULT_DATA_TEST_ID}
    />
  );
};

DownloadButton.displayName = 'DownloadButton';

export default memo(DownloadButton);
