import { FC, useMemo } from 'react';
import { Typography } from '@farmlink/farmik-ui';

import { TCalculationInfoProps } from './CalculationInfo.type';
import Styled from './CalculationInfo.style';

const CalculationInfo: FC<TCalculationInfoProps> = ({ title, value, elementWidth }) => {
  const valueToDisplay = useMemo(
    () =>
      value === '—' ? (
        <Typography variant="h5">&mdash;</Typography>
      ) : (
        <Typography variant="h5">{value}</Typography>
      ),
    [value]
  );

  return (
    <Styled.Wrapper $elementWidth={elementWidth}>
      <Styled.TitleWrapper>
        <Styled.TypographyWrapper variant="body">{title}</Styled.TypographyWrapper>
      </Styled.TitleWrapper>
      <Styled.InfoWrapper>{valueToDisplay}</Styled.InfoWrapper>
    </Styled.Wrapper>
  );
};

export default CalculationInfo;
