import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledComparisonTableBuilderRowNestedRenderContainer = {
  Wrapper,
};

export default StyledComparisonTableBuilderRowNestedRenderContainer;
