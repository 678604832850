import styled, { css } from 'styled-components';

const SVG = styled.svg<{
  $isOpened?: boolean;
}>`
  ${({ $isOpened }) => {
    if ($isOpened) {
      return css`
        #table-filters-builder-filter-icon-body {
          fill: #e8e9eb;
        }
      `;
    }
  }}

  &:hover {
    #table-filters-builder-filter-icon-body {
      fill: #ecf8ef;
    }

    #table-filters-builder-filter-icon-figure {
      stroke: #259a42;
    }

    #table-filters-builder-filter-icon-circle {
      fill: #259a42;
      stroke: #259a42;
    }
  }
`;

const StyledTableFiltersBuilderFilterIcon = {
  SVG,
};

export default StyledTableFiltersBuilderFilterIcon;
