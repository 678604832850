import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import { IExperimentContract } from '../../../../../../../../../api/models/as-fields/experiments';

@provide.singleton()
class ContractSidePanelStore {
  private _selectedContract: IExperimentContract = null;
  private _hasAccessToEditContracts = false;

  constructor() {
    makeAutoObservable(this);
  }

  get selectedContract() {
    return this._selectedContract;
  }

  get hasAccessToEditContracts() {
    return this._hasAccessToEditContracts;
  }

  setSelectedContract = (contact: IExperimentContract) => {
    this._selectedContract = contact;
  };

  setAccessToEditContracts = (hasAccess: boolean) => {
    this._hasAccessToEditContracts = hasAccess;
  };

  clearSelectedContract = () => {
    this._selectedContract = null;
  };

  clearAccessToEditContracts = () => {
    this._hasAccessToEditContracts = false;
  };

  clearStore = () => {
    this.clearSelectedContract();
    this.clearAccessToEditContracts();
  };
}

export default ContractSidePanelStore;
