import { makeAutoObservable } from 'mobx';

import {
  TChangeCultureZoneExperimentReq,
  TGetCultureZoneExperimentListReq,
  TGetCultureZoneExperimentListRes,
  TGetExaminationAttributeValuesReq,
  TGetExaminationsReq,
  TGetOperationListReq,
} from '../../../../../../../../../../../../../api';
import { AxiosService } from '../../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
export class CalculationService {
  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(AxiosService)
  axiosService: AxiosService;

  getCultureZoneExperimentList = async (
    payload: TGetCultureZoneExperimentListReq
  ): Promise<TGetCultureZoneExperimentListRes> => {
    const { getCultureZoneExperimentList } = this.axiosService.api;

    try {
      const fetchedCultureZoneList = await getCultureZoneExperimentList(payload, {
        omit: ['experimentId'],
      });

      return fetchedCultureZoneList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getOperations = async (payload: TGetOperationListReq) => {
    const { getOperationList } = this.axiosService.api;

    try {
      const { content } = await getOperationList(payload);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getExaminationAttributeValues = async (payload: TGetExaminationAttributeValuesReq) => {
    const { getExaminationAttributeValues } = this.axiosService.api;

    try {
      const examinationAttributeValues = await getExaminationAttributeValues(payload, {
        omit: ['experimentZoneId'],
      });

      return examinationAttributeValues;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getExamination = async (payload: TGetExaminationsReq) => {
    try {
      const examination = await this.axiosService.api.getExaminations(payload);

      return examination;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  updateCultureZoneExperiment = async (payload: TChangeCultureZoneExperimentReq) => {
    try {
      await this.axiosService.api.changeCultureZoneExperiment(payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}
