import styled from 'styled-components';

import { TABLE_FILTERS_BUILDER_CONTROLS_KEY as CONTROLS_KEY } from '../../../constants';

const Controls = styled.div`
  display: flex;
  justify-content: end;

  grid-area: ${CONTROLS_KEY};
`;

const StyledTableFiltersBuilderPresets = {
  Controls,
};

export default StyledTableFiltersBuilderPresets;
