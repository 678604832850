import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';

import { AttributeValueComponentProps } from '../../../../../../../../../common/features/DynamicTable/models/AttributeValue/AttributeValue.model';
import { useInstance } from '../../../../../../../../../common/features/DynamicTable/hooks';
import { generateAuditsPath } from '../../../../../../utils/helpers';
import { TAuditsParams } from '../../../../../../models/params/AuditsParams.model';
import { AuditReportInstCustomProp } from '../../models';

import Styled from './AuditReportViewTaskAttrVal.styles';

const AuditReportViewTaskAttrVal: FC<AttributeValueComponentProps> = ({ attributeValue }) => {
  const { tableId, instanceId, value } = attributeValue;

  const { auditId } = useParams<TAuditsParams>();
  const { getInstance } = useInstance();

  const instance = getInstance<AuditReportInstCustomProp>(tableId, instanceId);

  const isTaskIdHaveInformation = useMemo(
    () => Boolean(instance?.customProp?.taskId),
    [instance?.customProp?.taskId]
  );

  const goToTaskReport = useMemo(() => {
    if (isTaskIdHaveInformation) {
      const taskId = instance?.customProp?.taskId ?? '';

      return generateAuditsPath('taskReport', { auditId, taskId });
    }
  }, [instance]);

  return (
    <>
      {isTaskIdHaveInformation ? (
        <Link to={goToTaskReport}>
          <Styled.Wrapper>{value}</Styled.Wrapper>
        </Link>
      ) : (
        <Styled.NoInfoWrapper>Данные по задаче отсутствуют</Styled.NoInfoWrapper>
      )}
    </>
  );
};

AuditReportViewTaskAttrVal.displayName = 'AuditReportViewTaskAttrVal';

export default observer(AuditReportViewTaskAttrVal);
