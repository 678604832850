import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IExperiment } from '../../../models/as-fields/experiments';

type TGetExperimentReq = {
  id: string;
};

type TGetExperimentRes = IExperiment;

const getExperiment: TApiRoute & {
  request: TGetExperimentReq;
  response: TGetExperimentRes;
} = {
  url: ({ id }) => `/api/as-fields/experiment/${id}`,
  method: 'GET',
  request: {} as TGetExperimentReq,
  response: {} as TGetExperimentRes,
  headers: {},
};

export type { TGetExperimentReq, TGetExperimentRes };

export { getExperiment };
