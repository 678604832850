import moment from 'moment';

import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../common/features/TableBuilder/models/configs';
import { EAuditsTableBuilderId } from '../../../../../constants/configs';
import { StatusCellComponent } from '../../../../Observations/components';

@provide.transient()
class AuditsListConfigsService {
  createConfig = (): IBuilderConfig => ({
    id: EAuditsTableBuilderId.Audits,
    columnConfigList: this.createColumnList(),
    cellConfigList: this.createCellList(),
    rowsGroupConfigList: this.createRowsGroupList(),
  });

  protected createColumnList = (): IColumnConfig[] => [
    this.createNameColumn(),
    this.createTechOperationColumn(),
    this.createAssigneeColumn(),
    this.createContractorColumn(),
    this.createDateColumn(),
    this.createExperimentNumberColumn(),
    this.createStatusColumn(),
  ];

  protected createNameColumn = (): IColumnConfig => ({
    id: 'name',
    name: 'Название наблюдения',
    width: {
      default: '1fr',
      s1920: '1.5fr',
      s1366: '1.5fr',
      s1024: '1fr',
    },
  });

  protected createTechOperationColumn = (): IColumnConfig => ({
    id: 'techOperationType',
    name: 'Тип операции',
    width: {
      default: '0.9fr',
      s1920: '0.75fr',
      s1366: '0.75fr',
      s1024: '0.9fr',
    },
  });

  protected createAssigneeColumn = (): IColumnConfig => ({
    id: 'assignee',
    name: 'Ответственный',
    width: {
      default: '0.8fr',
      s1920: '1fr',
      s1366: '1fr',
      s1024: '0.8fr',
    },
  });

  protected createContractorColumn = (): IColumnConfig => ({
    id: 'organization',
    name: 'Контрагент',
    width: {
      default: '0.9fr',
      s1920: '0.75fr',
      s1366: '0.75fr',
      s1024: '0.9fr',
    },
  });

  protected createDateColumn = (): IColumnConfig => ({
    id: 'planStartDate',
    name: 'Дата',
    width: {
      default: '0.7fr',
      s1920: '0.5fr',
      s1366: '0.5fr',
      s1024: '0.7fr',
    },
  });

  protected createExperimentNumberColumn = (): IColumnConfig => ({
    id: 'experiment',
    name: '№ опыта',
    width: {
      default: '0.7fr',
      s1920: '0.5fr',
      s1366: '0.5fr',
      s1024: '0.7fr',
    },
  });

  protected createStatusColumn = (): IColumnConfig => ({
    id: 'status',
    name: 'Статус',
    width: {
      default: '112px',
    },
  });

  protected createCellList = (): ICellConfig[] => [
    this.createNameCell(),
    this.createTechOperationCell(),
    this.createAssigneeCell(),
    this.createContractorCell(),
    this.createDateCell(),
    this.createExperimentNumberCell(),
    this.createStatusCell(),
  ];

  createNameCell = (): ICellConfig => ({
    id: 'name',
    autoRenderConfig: {
      renderValue: row => row?.name,
    },
  });

  createTechOperationCell = (): ICellConfig => ({
    id: 'techOperationType',
    autoRenderConfig: {
      renderValue: row => row?.techOperationType?.name,
    },
  });

  createAssigneeCell = (): ICellConfig => ({
    id: 'assignee',
    autoRenderConfig: {
      renderValue: row => row?.assignee?.fullName,
    },
  });

  createContractorCell = (): ICellConfig => ({
    id: 'organization',
    autoRenderConfig: {
      renderValue: row => row?.organization?.name,
    },
  });

  createDateCell = (): ICellConfig => ({
    id: 'planStartDate',
    autoRenderConfig: {
      renderValue: row => moment(row?.planStartDate).format('DD.MM.YYYY'),
    },
  });

  createExperimentNumberCell = (): ICellConfig => ({
    id: 'experiment',
    autoRenderConfig: {
      renderValue: row => row?.experiment?.code,
    },
  });

  createStatusCell = (): ICellConfig => ({
    id: 'status',
    customRenderConfig: {
      renderCell: row => <StatusCellComponent status={row.status} />,
    },
  });

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EAuditsTableBuilderId.Audits,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default AuditsListConfigsService;
