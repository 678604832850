import { FC, memo } from 'react';
import { AutoTooltip, Avatar, ColorBadge, useShowTooltip } from '@farmlink/farmik-ui';

import { IExperimentContract } from '../../../../../../../../../api/models/as-fields/experiments';
import { useDataTestId } from '../../../../../../../../common/utils/hooks/locators';

import Styled from './ContractsListOrganizationCell.styles';

interface IProps {
  organization: IExperimentContract['organization'];
}

const ContractsListOrganizationCell: FC<IProps> = ({ organization }) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>();

  const fullName = `${organization.orgType.OrgType} «${organization.name}»`;

  const getDataTestId = useDataTestId('contracts-table-organization-cell');

  const organizationLogo = organization.logo?.downloadUrl;

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.BadgeWrapper {...getDataTestId('badge-wrapper')}>
        {organizationLogo ? (
          <Avatar src={organizationLogo} />
        ) : (
          <ColorBadge text={organization.name} styleProps={{ width: 36, height: 36 }} />
        )}
      </Styled.BadgeWrapper>

      <AutoTooltip content={fullName ?? '—'} disabled={!isShowTooltip}>
        <Styled.NameWrapper ref={ref} {...getDataTestId('name-wrapper')}>
          {fullName}
        </Styled.NameWrapper>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

export default memo(ContractsListOrganizationCell);
