import { ICulture } from '../../../../../../api/models/as-fields/fields/CultureZone';
import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';

const createCultureSelectOption = (culture: ICulture): ISelectOption => {
  return { label: culture.name, value: culture.id };
};

const createCultureSelectOptionList = (cultureList: ICulture[]): ISelectOption[] => {
  return cultureList.map<ISelectOption>(culture => createCultureSelectOption(culture));
};

export { createCultureSelectOption, createCultureSelectOptionList };
