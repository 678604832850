import { ICultureZone } from '../../../../../../api/models/as-fields/fields/CultureZone';

const createPreviousSeasonByCultureZone = (cultureZone: ICultureZone): string => {
  if (cultureZone?.seasonYear) {
    return `Сезон ${cultureZone?.seasonYear - 2}-${cultureZone?.seasonYear - 1}`;
  }

  if (cultureZone?.sowingDate && cultureZone?.harvestDate) {
    const sowingYear = Number(cultureZone.sowingDate.split('—')[0]);
    const harvestYear = Number(cultureZone.harvestDate.split('—')[0]);

    return `Сезон ${sowingYear - 1}-${harvestYear - 1}`;
  }

  return '';
};

export { createPreviousSeasonByCultureZone };
