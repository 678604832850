import { useState, useRef, useEffect } from 'react';

/**
 * @param depForUpdate - Зависимость, при которой мы будет обновлять флаг по скрытию/показу тултипа.
 */
export const useShowTooltip = <T extends HTMLElement>(depForUpdate?: any) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current) {
      if (ref.current.scrollWidth > ref.current.clientWidth) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    }
  }, [depForUpdate]);

  return { ref, showTooltip };
};
