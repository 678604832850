import { FC } from 'react';

import { CalculationInfoBlock } from '../CalculationInfoBlock';

import { calculationDifferenceData } from './utils/consts';

interface IInfoForCompareSectionsProps {
  changed: {
    currentRoi: number;
    revenueChanged: number;
    plannedYieldChanged: number;
    minimalIncrease: number;
    costDiff: number;
    cashDiff: number;
  };
}

const InfoForCompareSections: FC<IInfoForCompareSectionsProps> = ({ changed }) => {
  return (
    <>
      <CalculationInfoBlock
        calculationInfoData={calculationDifferenceData(changed)}
        bgColor={'transparent'}
        elementsJustify={'space-between'}
      />
    </>
  );
};

export default InfoForCompareSections;
