import { v4 as uuid } from 'uuid';

import {
  IMonitoringReportData,
  IMonitoringReportData as IReportData,
  IMonitoringReportDataZone,
  IMonitoringReportImagesConfig,
  IMonitoringReportTableAttribute as IReportTableAttribute,
  IMonitoringReportTableAttributeValue,
  IMonitoringReportTableStage as IReportTableStage,
} from '../../../../../../../../../../../api/models/as-fields/experiments/MonitoringReportData/MonitoringReportData';
import {
  IAttribute,
  IAttributeValue,
  IComparisonTableImagesConfig as IImagesConfig,
  IDynamicTableConfig as ITableConfig,
  Instance,
  Stage,
} from '../../../../../../../../../../common/features/DynamicTable/models';
import { EChecklistAttributeType } from '../../../../../../../../../../../api/models/as-fields/checklists/Attribute/ChecklistAttribute.model';

const TABLE_ID = 'auditPhotos';

function createInitialImagesConfig(stage: Stage): IImagesConfig {
  return {
    id: stage.id,
    isSelected: true,
    orientation: 'horizontal',
    selectedInstanceList: [],
  };
}

function createImagesConfig(
  reportConfigId: string,
  { includedInReport, orientationInReport, zones }: IMonitoringReportImagesConfig
): IImagesConfig {
  return {
    id: reportConfigId,
    isSelected: includedInReport,
    orientation: orientationInReport,
    selectedInstanceList: zones,
  };
}

function createImagesConfigList(
  reportConfigs: IMonitoringReportData['imagesConfig'],
  stageList: Stage[]
): IImagesConfig[] {
  const imagesConfigsById: Map<string, IImagesConfig> = new Map();

  Object.entries(reportConfigs).forEach(([id, reportConfig]) => {
    const imagesConfig = createImagesConfig(id, reportConfig);

    imagesConfigsById.set(imagesConfig.id, imagesConfig);
  });

  stageList.forEach(stage => {
    if (!imagesConfigsById.has(stage.id)) {
      const initialImagesConfig = createInitialImagesConfig(stage);

      imagesConfigsById.set(initialImagesConfig.id, initialImagesConfig);
    }
  });

  return [...imagesConfigsById.values()];
}

function createReportImagesConfigs(
  imagesConfigList: IImagesConfig[]
): IMonitoringReportData['imagesConfig'] {
  return imagesConfigList.reduce<IMonitoringReportData['imagesConfig']>((reportConfigs, config) => {
    reportConfigs[config.id] = {
      includedInReport: config.isSelected,
      orientationInReport: config.orientation,
      zones: config.selectedInstanceList,
    };

    return reportConfigs;
  }, {});
}

function createInstanceList(zoneList: IMonitoringReportDataZone[]): Instance[] {
  return zoneList.map(zone => ({
    id: uuid(),
    tableId: TABLE_ID,
    name: zone?.name || 'Безымянный',
    attributeValues: [],
  }));
}

function checkIfFileLink(type: EChecklistAttributeType): boolean {
  return type === EChecklistAttributeType.FileLink;
}

function checkIfInstanceLink(type: EChecklistAttributeType): boolean {
  return type === EChecklistAttributeType.ChecklistInstanceLink;
}

function createAttributeValueList(
  reportValueList: IMonitoringReportTableAttributeValue[]
): IAttributeValue[] {
  return reportValueList.map(reportValue => ({
    id: uuid(),
    instanceId: reportValue.instanceId,
    attributeId: reportValue.path,
    value: '',
    fileValueList: reportValue.fileValue,
  }));
}

function createStage(reportAttribute: IReportTableAttribute): Stage {
  const path = reportAttribute.values?.[0]?.path;

  // Если мы не нашли значение, у которого корректный путь до атрибута, то стэйдж не создаем.
  if (!path) {
    return;
  }

  const attribute: IAttribute = {
    id: path,
    name: path,
    attributeValueList: createAttributeValueList(reportAttribute.values),
  };

  return {
    id: path,
    name: path,
    attributes: [attribute],
    imagesStage: {},
  };
}

function createStageList(reportStageList: IReportTableStage[]): Stage[] {
  // Получаем список всех возможных атрибутов.
  const reportAttrList = reportStageList.reduce<IReportTableAttribute[]>(
    (attrList, reportStage) => {
      attrList.push(...reportStage.attributes);

      return attrList;
    },
    []
  );

  // Создаем список стэйджей.
  return reportAttrList.reduce<Stage[]>((stageList, reportAttr) => {
    // Если это вложенный чек-лист, то рекурсивно получаем список вложенных стэйджей.
    if (checkIfInstanceLink(reportAttr.type)) {
      const nestedReportStageList = reportAttr.values.reduce<IReportTableStage[]>(
        (list, attrVal) => {
          list.push(...attrVal.stages);

          return list;
        },
        []
      );

      const newStageList = createStageList(nestedReportStageList);

      stageList.push(...newStageList);

      return stageList;
    }

    // Если это атрибут-файл, то создаем новый стэйдж.
    if (checkIfFileLink(reportAttr.type)) {
      const newStage = createStage(reportAttr);

      if (newStage) {
        stageList.push(newStage);
      }
    }

    return stageList;
  }, []);
}

function createTableConfig(reportData: IReportData, isEnableEditing: boolean): ITableConfig {
  return {
    id: TABLE_ID,
    headColumn: {
      name: '',
      width: '0px',
      stages: createStageList(reportData.table.stages),
    },
    system: {
      isHideHeader: true,
      isIgnoreStageIdInAttr: true,
      isEnableEditing,
    },
  };
}

const AuditPhotosComparisonTableHelpers = {
  createTableConfig,
  createImagesConfigList,
  createReportImagesConfigs,
  createInstanceList,
};

export default AuditPhotosComparisonTableHelpers;
