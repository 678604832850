import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;

  background-color: white;
`;

const StyledTablePlugBuilder = {
  Wrapper,
};

export default StyledTablePlugBuilder;
