const MONTH_NAME_LIST = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
];

const getMonthName = (date: Date) => {
  return MONTH_NAME_LIST[date.getMonth()];
};

const parseDateToChatFormat = (date: Date): string => {
  return `${date.getDate()} ${getMonthName(date)} в ${date.toLocaleTimeString(['ru'], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

export { parseDateToChatFormat };
