import { FC, memo } from 'react';

import { useDataTestId } from '../../utils/hooks/locators';

import Styled from './TablePlugBuilder.styles';
import { ITablePlugBuilderAutoRenderConfig as IAutoRenderConfig } from './models/configs';
import { TablePlugBuilderAutoRender as AutoRender } from './components';

const DEFAULT_DATA_TEST_ID = 'table-plug';

interface IProps {
  /**
   * Конфиг автоматического рендера заглушки. Вся структура подготовлена, нужно лишь передать
   * значения для каждого конкретного свойства.
   */
  autoRenderConfig?: IAutoRenderConfig;
  /**
   * Локатор-идентификатор для автотестов.
   */
  dataTestId?: string;
}

const TablePlugBuilder: FC<IProps> = ({ autoRenderConfig, dataTestId }) => {
  const getDataTestId = useDataTestId(dataTestId || DEFAULT_DATA_TEST_ID);

  if (autoRenderConfig) {
    return (
      <Styled.Wrapper {...getDataTestId('wrapper')}>
        <AutoRender {...autoRenderConfig} />
      </Styled.Wrapper>
    );
  }

  return <></>;
};

TablePlugBuilder.displayName = 'TablePlugBuilder';

export default memo(TablePlugBuilder);
