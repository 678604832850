import { AxiosError } from 'axios';

import { EErrorStatusName } from '../../constants/errorStatus';

const getImportedFieldsSaveError = (error: AxiosError): EErrorStatusName => {
  const errorStatus = error?.response?.status;

  switch (errorStatus) {
    case 400:
      return EErrorStatusName.RequestUrlError;
    case 403:
      return EErrorStatusName.NotEnoughRights;
    default:
  }
};

export default getImportedFieldsSaveError;
