import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import {
  TGetExperimentUserListReq,
  TGetExperimentUserListRes,
  TGetUserReq,
} from '../../../../../../api';
import { IServiceOptions, IServiceReturnData } from '../../../../models/service';
import { createUserSelectOption } from '../../../../utils/helpers/selectOptions';
import { IUser } from '../../../../../../api/models/da-profile/users';

@provide.singleton()
class UsersService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getUser = async (
    payload: TGetUserReq,
    options?: IServiceOptions
  ): Promise<IServiceReturnData<IUser>> => {
    const { getUser } = this.axiosService.api;

    try {
      const fetchedUser = await getUser(payload, {
        omit: ['id'],
      });

      const data: IServiceReturnData<IUser> = {
        data: fetchedUser,
      };

      if (options?.isCreateSelectOptions) {
        data.selectOptionList = [createUserSelectOption(fetchedUser)];
      }

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getExperimentUserList = async (
    payload: TGetExperimentUserListReq
  ): Promise<TGetExperimentUserListRes> => {
    try {
      const response = await this.axiosService.api.getExperimentUserList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default UsersService;
