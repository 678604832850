import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #e7f1fd4d;
`;

const StyledTotalComparisonSummaryRowsGroup = {
  Wrapper,
};

export default StyledTotalComparisonSummaryRowsGroup;
