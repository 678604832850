import { FC, useRef } from 'react';
import {
  ButtonLink,
  ENotificationType,
  ENotificatorTheme,
  Typography,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { TFileEntities } from '../../../../../../api/models/as-fields/attachedFiles';
import { useFileUpload } from '../../../../hooks/useFileUpload';
import { useStore } from '../../../../utils/helpers/mobx';
import { DocumentsTableStore } from '../../mobx/stores';
import { DocumentsTableController } from '../../mobx/controllers';

import Styled from './DocumentsHeader.styles';

interface IProps {
  id: string;
  entity: TFileEntities;
  hideActionButtons?: boolean;
  hideDownloadButton?: boolean;
  ContextualHelpIcon?: JSX.Element;
}

export const DocumentsHeader: FC<IProps> = observer(
  ({ id, entity, hideActionButtons, ContextualHelpIcon, hideDownloadButton }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { handleFileChange } = useFileUpload(inputRef, { maxSize: 1e8 });

    const { attachedFiles } = useStore(DocumentsTableStore);

    const { addAttachedFile } = useStore(DocumentsTableController);

    const { setNotification } = useNotificator();

    const addFileToExperiment = (fileId: string) => {
      addAttachedFile(id, fileId, entity);
    };

    const uploadNewDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFileChange(e)
        .then(file => {
          addFileToExperiment(file.id);
          setNotification({
            message: 'Документ успешно загружен',
            style: {
              placement: 'top-center',
              type: ENotificationType.Success,
              theme: ENotificatorTheme.Dark,
            },
          });
        })
        .catch((err: Error) => {
          setNotification({
            message: err.message,
            style: {
              placement: 'top-center',
              type: ENotificationType.Warning,
              theme: ENotificatorTheme.Dark,
            },
          });
        });
    };

    const handleUploadClick = () => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.value = null;
      inputRef.current?.click();
    };

    const isShowDownloadButton =
      !hideDownloadButton && attachedFiles?.length > 0 && !hideActionButtons;

    return (
      <Styled.Wrapper>
        <Styled.TitleWrapper>
          <Typography variant="h5" data-test-id="exp-documents-title">
            Документы
          </Typography>
          {ContextualHelpIcon ? ContextualHelpIcon : null}
        </Styled.TitleWrapper>

        <div>
          {isShowDownloadButton && (
            <ButtonLink
              color={'accent'}
              onClick={handleUploadClick}
              dataTestId={'exp-documents-upload-file-button'}
            >
              Загрузить документ
            </ButtonLink>
          )}
          <Styled.FileInput type="file" ref={inputRef} onChange={uploadNewDocument} />
        </div>
      </Styled.Wrapper>
    );
  }
);
