import { format } from 'date-fns';

import { IExaminationAttributeValue } from '../../../../../../../../../../../api/models/aho/examination-attribute-values/ExaminationAttributeValue.model';
import { IExamination } from '../../../../../../../../../../../api/models/aho/examination/Examination.model';

const mapIdToExaminationType: Record<string, { type: string; valueType: string }> = {
  'fd1f9730-451d-4499-8a47-a4b03f3ee35c': {
    type: 'type',
    valueType: 'dictionaryValue',
  },
  '975b0fac-eccd-4819-b3e1-c83762cbb3f6': {
    type: 'mechanicalComposition',
    valueType: 'dictionaryValue',
  },
  'e4a14583-3909-4cad-b63a-60bd7dcd76b1': {
    type: 'ph',
    valueType: 'doubleValue',
  },
  '47dab72f-ffe3-4a39-91fb-3ebef5241fea': {
    type: 'humus',
    valueType: 'doubleValue',
  },
  '7467bcac-17eb-4b36-8d3f-88cb6b00d740': {
    type: 'ko',
    valueType: 'doubleValue',
  },
  '8f3d652c-50c7-4567-95f2-030877cb5662': {
    type: 'po',
    valueType: 'doubleValue',
  },
};

export const createAgrochemicalTableConfig = (
  currentExamination: IExamination,
  examinationAttributeValues: IExaminationAttributeValue[]
) => {
  if (!currentExamination) {
    return [];
  }

  const row = examinationAttributeValues.reduce((acc, examinationAttributeValue) => {
    const { valueType, type: typed } =
      mapIdToExaminationType[examinationAttributeValue.examinationMethod.examinationAttribute.id];

    const value = examinationAttributeValue[valueType];

    acc[typed] = typeof value === 'number' ? value : value?.name;

    return acc;
  }, {} as any);

  row.id = 'agrochemicalTable';
  row.date = format(new Date(currentExamination.sampleDate), 'dd.MM.yyyy');

  row.options = { borderExist: false };

  return [row];
};
