import styled from 'styled-components';

import { ECommonColor } from '../../../../../../assets/styles/colors';

const { GeneralBlack } = ECommonColor;

const Wrapper = styled.div<{ $headColumnPadding: string; $headColumnMargin: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: ${({ $headColumnMargin }) => ($headColumnMargin ? $headColumnMargin : 'auto 0')};

  padding: ${({ $headColumnPadding }) => ($headColumnPadding ? $headColumnPadding : '10px 0')};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${GeneralBlack};
`;

const StyledAttributeName = {
  Wrapper,
};

export default StyledAttributeName;
