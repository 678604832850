import { EDashboardRoute } from '../../../../../../routes/DashboardRoute';
import { EExperimentsRoute } from '../../../../routes';
import { ECreateExperimentRoute } from '../../routes';

export function getCreateExperimentLink(path: ECreateExperimentRoute): string {
  const { Experiments } = EDashboardRoute;
  const { Experiment } = EExperimentsRoute;

  const link = `/${Experiments}/${Experiment}/${path}`;

  return link;
}

export const CREATE_EXPERIMENT_TABS = [
  {
    id: 1,
    path: getCreateExperimentLink(ECreateExperimentRoute.Attributes),
    title: 'Постановка',
  },
  {
    id: 2,
    path: getCreateExperimentLink(ECreateExperimentRoute.Zones),
    title: 'Участки',
  },
  {
    id: 3,
    path: getCreateExperimentLink(ECreateExperimentRoute.NutritionHistory),
    title: 'История',
  },
  {
    id: 4,
    path: getCreateExperimentLink(ECreateExperimentRoute.Calculation),
    title: 'Расчёт',
  },
  {
    id: 5,
    path: getCreateExperimentLink(ECreateExperimentRoute.Comparison),
    title: 'Сравнение ТЗ',
  },
  {
    id: 6,
    path: getCreateExperimentLink(ECreateExperimentRoute.Execution),
    title: 'Исполнение',
  },
  {
    id: 7,
    path: getCreateExperimentLink(ECreateExperimentRoute.TotalComparison),
    title: 'Сравнение итог',
  },
  {
    id: 8,
    path: getCreateExperimentLink(ECreateExperimentRoute.Remarks),
    title: 'Замечания',
  },
];

export const CREATE_EXPERIMENT_PATH_KEY = 'experimentId';
