import { makeAutoObservable } from 'mobx';

import { TAddExaminationAttributeValuesReq } from '../../../../../../../../../../../../api';
import { AxiosService } from '../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
export class AgrochemicalService {
  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(AxiosService)
  axiosService: AxiosService;

  getMethodsByAttributeId = async (examinationAttributeId: string) => {
    try {
      const { content } = await this.axiosService.api.getExaminationAttributeMethods({
        examinationAttributeId,
      });

      return content;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  createExaminationAttributeValues = async (payload: TAddExaminationAttributeValuesReq) => {
    try {
      await this.axiosService.api.addExaminationAttributeValues(payload, {
        omit: ['experimentZoneId'],
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}
