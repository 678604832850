import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import {
  EExperimentCultureZoneType,
  IFWExperimentCultureZone,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import {
  ICulture,
  ICultureZone,
} from '../../../../../../../../../../../../../api/models/as-fields/fields/CultureZone';
import { IDictionaryEntity } from '../../../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';

@provide.singleton()
class ZonesStore {
  private _isAllowMapAccess = false;

  private _editableZoneId: string | null = null;

  private _idToCultureZone: Map<string, ICultureZone> = new Map<string, ICultureZone>();

  private _idToFwExpCultureZoneWithoutPolyId: Map<string, IFWExperimentCultureZone> = new Map<
    string,
    IFWExperimentCultureZone
  >();

  private _idToFwExpCultureZone: Map<string, IFWExperimentCultureZone> = new Map<
    string,
    IFWExperimentCultureZone
  >();

  private _idToUnchangedFwExpCultureZone: Map<string, IFWExperimentCultureZone> = new Map<
    string,
    IFWExperimentCultureZone
  >();

  private _idToCulture: Map<string, ICulture> = new Map<string, ICulture>();

  private _previousCultureCurrentPage = 0;

  private _previousCultureTotalPages = 0;

  private _previousCultureOptionList: ISelectOption[];

  private _previousCultureSearchQuery = '';

  private _tableHasChanged = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isAllowMapAccess() {
    return this._isAllowMapAccess;
  }

  get editableZoneId() {
    return this._editableZoneId;
  }

  setEditableZoneId = (id: string): void => {
    this._editableZoneId = id;
  };

  clearEditableZoneId = (): void => {
    this._editableZoneId = null;
  };

  get fwExpCultureZoneListWithoutPolyId() {
    return Array.from(this._idToFwExpCultureZoneWithoutPolyId.values());
  }

  getZone = (cultureZoneId: string): IFWExperimentCultureZone => {
    return this._idToFwExpCultureZone.get(cultureZoneId);
  };

  get fwExpCultureZoneList() {
    return Array.from(this._idToFwExpCultureZone.values()) || [];
  }

  get unchangedFwExpCultureZoneList() {
    return Array.from(this._idToUnchangedFwExpCultureZone.values());
  }

  get isZonesChanged(): boolean {
    if (!this.unchangedFwExpCultureZoneList.length) {
      return false;
    }

    return !_.isEqual(this.fwExpCultureZoneList, this.unchangedFwExpCultureZoneList);
  }

  get hasControlZone(): boolean {
    return Boolean(
      this.fwExpCultureZoneList.filter(
        fwZone => fwZone.isSelected && fwZone.type === EExperimentCultureZoneType.Control
      ).length
    );
  }

  get previousCultureCurrentPage() {
    return this._previousCultureCurrentPage;
  }

  get previousCultureTotalPages() {
    return this._previousCultureTotalPages;
  }

  get previousCultureOptionList() {
    return this._previousCultureOptionList;
  }

  get previousCultureSearchQuery() {
    return this._previousCultureSearchQuery;
  }

  get tableHasChanged() {
    return this._tableHasChanged;
  }

  setPreviousCultureCurrentPage = (pageNumber: number): void => {
    this._previousCultureCurrentPage = pageNumber;
  };

  setPreviousCultureTotalPages = (pageNumber: number): void => {
    this._previousCultureTotalPages = pageNumber;
  };

  setPreviousCultureOptionList = (optionList: ISelectOption[]): void => {
    this._previousCultureOptionList = optionList;
  };

  setPreviousCultureSearchQuery = (searchQuery: string): void => {
    this._previousCultureSearchQuery = searchQuery;
  };

  getCulture = (id: string): ICulture => {
    return this._idToCulture.get(id);
  };

  setIsAllowMapAccess = (value: boolean): void => {
    this._isAllowMapAccess = value;
  };

  setIdToFwExpCultureZoneWithoutPolyId = (
    fwExpCultureZoneList: IFWExperimentCultureZone[]
  ): void => {
    const newIdToFwExpCultureZoneWithoutPolyId: Map<string, IFWExperimentCultureZone> = new Map<
      string,
      IFWExperimentCultureZone
    >();

    if (_.isArray(fwExpCultureZoneList)) {
      fwExpCultureZoneList.forEach(zone =>
        newIdToFwExpCultureZoneWithoutPolyId.set(zone.cultureZoneId, zone)
      );
    }

    this._idToFwExpCultureZoneWithoutPolyId = newIdToFwExpCultureZoneWithoutPolyId;
  };

  setIdToFwExpCultureZone = (fwExpCultureZoneList: IFWExperimentCultureZone[]): void => {
    const newIdToFwExpCultureZone: Map<string, IFWExperimentCultureZone> = new Map<
      string,
      IFWExperimentCultureZone
    >();

    if (_.isArray(fwExpCultureZoneList)) {
      fwExpCultureZoneList.forEach(zone => newIdToFwExpCultureZone.set(zone.cultureZoneId, zone));
    }

    this._idToFwExpCultureZone = newIdToFwExpCultureZone;
  };

  setIdToUnchangedFwExpCultureZone = (fwExpCultureZoneList: IFWExperimentCultureZone[]): void => {
    const newIdToUnchangedFwExpCultureZone: Map<string, IFWExperimentCultureZone> = new Map<
      string,
      IFWExperimentCultureZone
    >();

    if (_.isArray(fwExpCultureZoneList)) {
      fwExpCultureZoneList.forEach(fwExpCultureZone => {
        newIdToUnchangedFwExpCultureZone.set(fwExpCultureZone.cultureZoneId, fwExpCultureZone);
      });
    }

    this._idToUnchangedFwExpCultureZone = newIdToUnchangedFwExpCultureZone;
  };

  setFwExpCultureZone = (fwZone: IFWExperimentCultureZone): void => {
    this._idToFwExpCultureZone.set(fwZone.cultureZoneId, fwZone);
  };

  setCultureList = (cultureList: IDictionaryEntity[]): void => {
    const newIdToCulture: Map<string, ICulture> = new Map<string, ICulture>();

    if (_.isArray(cultureList)) {
      cultureList.forEach(culture => {
        newIdToCulture.set(culture.id, culture);
      });
    }

    this._idToCulture = newIdToCulture;
  };

  setTableHasChanged = (isChanged: boolean) => {
    this._tableHasChanged = isChanged;
  };

  clearIsAllowMapAccess = (): void => {
    this._isAllowMapAccess = false;
  };

  clearIdToCultureZone = (): void => {
    this._idToCultureZone.clear();
  };

  clearIdFwExpCultureZoneWithoutPolyId = (): void => {
    this._idToFwExpCultureZoneWithoutPolyId.clear();
  };

  clearIdFwExpCultureZone = (): void => {
    this._idToFwExpCultureZone.clear();
  };

  clearIdToUnchangedFwExpCultureZone = (): void => {
    this._idToUnchangedFwExpCultureZone.clear();
  };

  clearIdToCulture = (): void => {
    this._idToCulture.clear();
  };

  clearTableHasChanged = () => {
    this._tableHasChanged = false;
  };

  clearStore = (): void => {
    this.clearIsAllowMapAccess();
    this.clearEditableZoneId();

    this.clearIdToCultureZone();
    this.clearIdFwExpCultureZoneWithoutPolyId();
    this.clearIdFwExpCultureZone();
    this.clearIdToUnchangedFwExpCultureZone();
    this.clearIdToCulture();
    this.clearTableHasChanged();
  };
}

export default ZonesStore;
