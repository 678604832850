import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/helpers/mobx';
import { IChatInstance, IChatMessage } from '../../../models';

@provide.singleton()
class ChatJournalStore {
  private _idToChatInstance: Map<string, IChatInstance> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  getChatInstance = (id: string) => {
    return this._idToChatInstance.get(id);
  };

  setChatInstanceById = (chatInstance: IChatInstance) => {
    this._idToChatInstance.set(chatInstance.id, chatInstance);
  };

  addMessageListToChat = (chatId: string, messageList: IChatMessage[]) => {
    const isChatInstanceExist = this._idToChatInstance.has(chatId);

    if (isChatInstanceExist) {
      const chatInstance = this._idToChatInstance.get(chatId);
      chatInstance.messageList = [...chatInstance.messageList, ...messageList];
    } else {
      this.setChatInstanceById({ id: chatId, messageList });
    }
  };

  setMessageListByChatId = (chatId: string, messageList: IChatMessage[]) => {
    const isChatInstanceExist = this._idToChatInstance.has(chatId);

    if (isChatInstanceExist) {
      this._idToChatInstance.get(chatId).messageList = messageList;
    } else {
      this.setChatInstanceById({ id: chatId, messageList });
    }
  };

  deleteChatInstanceById = (chatInstanceId: string) => {
    this._idToChatInstance.delete(chatInstanceId);
  };

  clearChatInstanceMap = () => {
    this._idToChatInstance = new Map();
  };
}

export default ChatJournalStore;
